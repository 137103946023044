import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InputField } from "../../../../Components/formElements/InputField";
// import Spinner from "react-bootstrap/Spinner";
// import PrintInvoice from "./PrintInvoice";
import ItemServiceV2 from "../../../../services/customer_v2_appCommonService";

//API
import DataTable from "react-data-table-component";
// import { formatNumber } from "../../../../common/Validations";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
// import { useReactToPrint } from "react-to-print";

const InvoiceConfirmationModal = React.forwardRef((props, ref) => {
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  // eslint-disable-next-line no-unused-vars
  const [viewInvoiceDataStatus, setViewInvoiceDataStatus] = useState(false);
  const [lineItemData, setLineItemData] = useState([]);
  const [invoiceData, setInvoiceData] = useState();
  // console.log(">>invoiceData", invoiceData);
  const [getDropdownData, setDropdownData] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [totals, setTotals] = useState({
    totalBags: 0,
    totalAmount: 0,
    totalCgst: 0,
    totalSgst: 0,
    totalNetValue: 0,
  });
  // console.log(">>totals", totals);

  //send print data
  // const [printInvoiceData, setPrintInvoiceData] = useState();

  let dropdownOption = [
    {
      id: "1",
      name: "30 Days",
    },
    {
      id: "2",
      name: "60 Days",
    },
    {
      id: "3",
      name: "90 Days",
    },
  ];

  const lineItemCol = [
    {
      name: "Product",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.materialDescription} </p>
          <p className="m-0 p-0 ">{row?.materialCode} </p>
        </div>
      ),
      width: "130px",
    },
    {
      name: "Load confirm Qty",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.orderQtyLoadConfirm} </p>
          <p className="m-0 p-0 ">{row?.uOM} </p>
        </div>
      ),
      width: "130px",
    },
    {
      name: "Unit Price",
      cell: (row) => <span>{row?.unitPrice} </span>,
      width: "100px",
    },
    {
      name: "Basic Price",
      selector: (row) => row?.basicPrice || "--",
      cell: (row) => <span>{row?.basicPrice} </span>,

      sortable: true,
      width: "120px",
    },
    {
      name: "Basic Amt",
      selector: (row) => row.basicAmount || "--",
      cell: (row) => <span>{row.basicAmount} </span>,

      sortable: true,
      width: "120px",
    },
    {
      name: "CGST",
      selector: (row) => row?.cgstValue || "--",
      cell: (row) => <span>{row?.cgstValue} </span>,

      sortable: true,
      width: "100px",
    },
    {
      name: "SGST",
      selector: (row) => row?.sgstValue || "--",
      cell: (row) => <span>{row?.sgstValue} </span>,

      sortable: true,
      width: "100px",
    },
    {
      name: "GST",
      selector: (row) => row?.gstValue || "--",
      cell: (row) => <span>{row?.gstValue} </span>,

      sortable: true,
      width: "100px",
    },
    {
      name: "Net Value",
      selector: (row) => row?.netValue || "--",
      cell: (row) => <span>{row?.netValue} </span>,

      sortable: true,
      width: "120px",
    },

    {
      name: "CGST%",
      selector: (row) => row?.cgst || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "SGST%",
      selector: (row) => row?.sgst || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Driver",
      selector: (row) => row?.netValue || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.transporterDriverName} </p>
          <p className="m-0 p-0 ">{row?.transporterDriverContact} </p>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Truck No",
      selector: (row) => row?.netValue || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingTruckNo} </p>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
  ];

  useEffect(() => {
    setInvoiceData((invoiceData) => ({
      ...invoiceData,
      [getDropdownData?.textField]: getDropdownData?.name,
      [getDropdownData?.textCode]: getDropdownData?.code,
      [getDropdownData?.textDesc]: getDropdownData?.description,
    }));
  }, [getDropdownData]);

  // useEffect(() => {
  //   setInvoiceData(props?.invoiceinfo);
  //   setLineItemData(props?.invoiceinfo?.items);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.invoiceinfo]);

  // useEffect(() => {
  //   setInvoiceData(props?.invoiceinfo);

  //   if (props?.invoiceinfo) {
  //     const updatedLineItems = props.invoiceinfo.items.map((item) => {
  //       const orderQuantityLoad = parseFloat(item.orderQtyLoadConfirm) || 0;
  //       const unitPrice = parseFloat(item.unitPrice) || 0;
  //       const reversePercentage = parseFloat(item.reversePercentage) || 1; // Avoid division by zero
  //       const cgst = parseFloat(item.cgst) || 0;
  //       const sgst = parseFloat(item.sgst) || 0;
  //       const gst = cgst + sgst;

  //       // Basic Price Calculation
  //       const basicPrice = (unitPrice / reversePercentage).toFixed(2);

  //       // Basic Amount Calculation
  //       const basicAmount = (basicPrice * orderQuantityLoad).toFixed(2);

  //       // Rate Calculation
  //       const rate = (orderQuantityLoad * unitPrice).toFixed(2);

  //       // CGST & SGST Calculation
  //       const cgstValue = ((cgst * basicAmount) / 100).toFixed(2);
  //       const sgstValue = ((sgst * basicAmount) / 100).toFixed(2);

  //       // GST Calculation
  //       const gstValue = ((gst * basicAmount) / 100).toFixed(2);

  //       // Net Value Calculation
  //       const netValue = (parseFloat(gstValue) + parseFloat(basicAmount)).toFixed(2);

  //       return {
  //         ...item,
  //         basicPrice,
  //         basicAmount,
  //         rate,
  //         cgstValue,
  //         sgstValue,
  //         gstValue,
  //         netValue,
  //         gst, // Updating gst to sum of cgst + sgst
  //       };
  //     });

  //     setLineItemData(updatedLineItems);
  //   }
  // }, [props?.invoiceinfo]);

  useEffect(() => {
    if (props?.invoiceinfo) {
      const updatedLineItems = props.invoiceinfo.items.map((item) => {
        const orderQuantityLoad = parseFloat(item.orderQtyLoadConfirm) || 0;
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const reversePercentage = parseFloat(item.reversePercentage) || 1; // Avoid division by zero
        const cgst = parseFloat(item.cgst) || 0;
        const sgst = parseFloat(item.sgst) || 0;
        const gst = cgst + sgst;

        // Basic Price Calculation
        const basicPrice = (unitPrice / reversePercentage).toFixed(2);

        // Basic Amount Calculation
        const basicAmount = (basicPrice * orderQuantityLoad).toFixed(2);

        // Rate Calculation
        const rate = (orderQuantityLoad * unitPrice).toFixed(2);

        // CGST & SGST Calculation
        const cgstValue = ((cgst * basicAmount) / 100).toFixed(2);
        const sgstValue = ((sgst * basicAmount) / 100).toFixed(2);

        // GST Calculation
        const gstValue = ((gst * basicAmount) / 100).toFixed(2);

        // Net Value Calculation
        const netValue = (
          parseFloat(gstValue) + parseFloat(basicAmount)
        ).toFixed(2);

        return {
          ...item,
          basicPrice,
          basicAmount,
          rate,
          cgstValue,
          sgstValue,
          gstValue,
          netValue,
          gst, // Updating gst to sum of cgst + sgst
        };
      });

      setLineItemData(updatedLineItems);

      // Calculate Totals
      const totalBags = updatedLineItems.reduce(
        (sum, item) => sum + parseFloat(item.orderQtyLoadConfirm || 0),
        0
      );
      const totalAmount = updatedLineItems.reduce(
        (sum, item) => sum + parseFloat(item.basicAmount || 0),
        0
      );
      const totalCgst = updatedLineItems.reduce(
        (sum, item) => sum + parseFloat(item.cgstValue || 0),
        0
      );
      const totalSgst = updatedLineItems.reduce(
        (sum, item) => sum + parseFloat(item.sgstValue || 0),
        0
      );
      const totalNetValue = updatedLineItems.reduce(
        (sum, item) => sum + parseFloat(item.netValue || 0),
        0
      );

      const calculatedTotals = {
        totalBags,
        totalAmount: totalAmount.toFixed(2),
        totalCgst: totalCgst.toFixed(2),
        totalSgst: totalSgst.toFixed(2),
        totalNetValue: totalNetValue.toFixed(2),
      };

      setTotals(calculatedTotals);

      // Update invoiceData with totals
      setInvoiceData({
        ...props.invoiceinfo, // Preserve existing fields
        items: updatedLineItems, // Updated items with calculations
        totals: calculatedTotals, // Updated totals
      });
    }
  }, [props?.invoiceinfo]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  //handle cancel modal
  const handleCancel = () => {
    props.onHide();
  };

  const onInputChange = ({ target: { name, value } }) => {
    setInvoiceData((invoiceData) => ({ ...invoiceData, [name]: value }));
  };

  //print
  // useEffect(() => {
  //   setPrintInvoiceData("move props here");
  // }, []);

  // const contentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   contentRef,
  //   documentTitle: `LoadingAdvice_${Math.floor(Math.random() * 1000000)}`,
  // });

  //call api for paritcular order
  // const getMasterData = async () => {
  //   if (!invoiceData?.orderSchedulingNr) return;
  //   let obj = { documentNumber: invoiceData?.orderSchedulingNr };
  //   // setLineItems([]);
  //   await ItemServiceV2.getOrderBoogkingDetail(obj).then((item) => {
  //     // console.log(">> item! title", item);
  //     if (item?.status === 200) {
  //       // setLineItems(item?.data[0]?.items || []);
  //       setLineItemData(item?.data[0]?.items || []);
  //       // setFormInputs(item?.data[0]);
  //       // setTitle(item?.data);
  //     } else {
  //       console.log(">> Error: Loading getMasterDataSelf");
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getMasterData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [invoiceData?.orderSchedulingNr]);

  const handleSave = async () => {
    // console.log(">>invoiceData", invoiceData);
    const obj = {
      data: invoiceData,
    };
    // console.log(">>obj",obj)
    await ItemServiceV2.addInvoice(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        // handleSearchFuncApi();
        props.onHide();
      } else {
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  const documentTypeMap = {
    "101 - GST Registration": "GST",
    "100 - PAN Registration": "PAN",
    "131 - GST Registration": "GST",
  };

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Invoice Confirmation - {invoiceData?.orderSchedulingNr}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {/* 01 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">ID</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle"> Invoice Address</span>
                  <span className="subHeaderTitle">Invoice details</span>
                </Col>

                <Col xs={12} md={6} lg={6} className="headerButtonAlign">
                  {/* <Col
                    xs={6}
                    md={6}
                    lg={6}
                    className="d-flex justify-content-end mr-2 mt-1"
                  >
                    <button className="btnTable" onClick={handlePrint}>Print Invoice</button>
                    <div style={{ display: "none" }}>
                      <PrintInvoice ref={contentRef} data={printInvoiceData} />
                    </div>
                  </Col> */}
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Col xs={12} md={12} lg={12} className="p-2">
                        <p className="fw-bold mb-0">Company:</p>
                        <p className="fs-5 mb-0">
                          {invoiceData?.companyCodeDesc}
                        </p>
                      </Col>
                      <Col xs={12} md={12} lg={12} className="p-2">
                        <p className="fw-bold mb-0">Billing:</p>
                        <p className="fs-5 mb-0">{invoiceData?.customerName}</p>
                        <p className="fs-5 mb-0">
                          {invoiceData?.items
                            ? invoiceData?.items[0]?.customerAddress
                            : ""}{" "}
                        </p>

                        {invoiceData?.items &&
                          invoiceData.items[0]?.certifications
                            ?.filter((doc) =>
                              Object.keys(documentTypeMap).some((type) =>
                                doc.documentType.includes(type)
                              )
                            )
                            .map((doc, index) => {
                              const title =
                                documentTypeMap[doc.documentType] ||
                                doc.documentType; // Fallback to original if not found

                              return (
                                <p key={index} className="fs-5 mb-0">
                                  <b>{title}: </b> {doc.documentNumber}
                                </p>
                              );
                            })}
                      </Col>
                      <Col xs={12} md={12} lg={12} className="p-2">
                        <p className="fw-bold mb-0">Shipping:</p>
                        <p className="fs-5 mb-0">
                          {invoiceData?.items
                            ? invoiceData?.items[0]?.shippingAddress + " "
                            : ""}
                          {invoiceData?.items
                            ? invoiceData?.items[0]?.shippingPostalCode
                            : ""}
                        </p>
                      </Col>
                      <Col xs={12} md={12} lg={12} className="p-2">
                        <p className="fw-bold mb-0">Godown:</p>
                        <p className="fs-5 mb-0">
                          {invoiceData?.items
                            ? invoiceData?.items[0]?.godownAddress
                            : ""}
                        </p>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/*02 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible2 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility2}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">ID</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle">Items</span>
                  <span className="subHeaderTitle">Items details</span>
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible2 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <DataTable
                          title=""
                          columns={lineItemCol}
                          data={lineItemData}
                          // progressPending={orderSchedulingData?.status}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={orderSchedulingTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          // selectableRows
                        />
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/*03 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility3}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">ID</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle"> Invoice details</span>
                  <span className="subHeaderTitle">Invoice details</span>
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible3 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total Bags"
                            name="sumOfOrderQuantityLoad"
                            id="sumOfOrderQuantityLoad"
                            value={invoiceData?.totals?.totalBags}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total Amount"
                            name="sumOfRate"
                            id="sumOfRate"
                            value={invoiceData?.totals?.totalAmount}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total CGST"
                            name="totalCgst"
                            id="totalCgst"
                            value={invoiceData?.totals?.totalCgst}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total SGST"
                            name="totalSgst"
                            id="totalSgst"
                            value={invoiceData?.totals?.totalSgst}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Net Amount"
                            name="totalAmount"
                            id="totalAmount"
                            value={invoiceData?.totals?.totalNetValue}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Row>
                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Truck No"
                              name="loadingTruckNo"
                              id="loadingTruckNo"
                              value={invoiceData?.loadingTruckNo}
                              onChange={onInputChange}

                              // disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Driver Name"
                              name="transporterDriverName"
                              id="transporterDriverName"
                              value={invoiceData?.transporterDriverName}
                              onChange={onInputChange}

                              // disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Driver Contact"
                              name="transporterDriverContact"
                              id="transporterDriverContact"
                              value={invoiceData?.transporterDriverContact}
                              onChange={onInputChange}

                              // disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Sales Officer"
                              name="createdByName"
                              id="createdByName"
                              value={invoiceData?.createdByName}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="e-Way Bill Number"
                              name="ewayBillNumber"
                              id="ewayBillNumber"
                              value={invoiceData?.ewayBillNumber}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Dispatch Documnet Number"
                              name="dispatchDocumnetNr"
                              id="dispatchDocumnetNr"
                              value={invoiceData?.dispatchDocumnetNr}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Bill of Lading / LR-RR No."
                              name="billOfLading"
                              id="billOfLading"
                              value={invoiceData?.billOfLading}
                              onChange={onInputChange}
                              disabled={false}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Terms Of Payment"
                              extraLabel=""
                              name="termsOfPayment"
                              code="termsOfPaymentCode"
                              description="termsOfPaymentDesc"
                              data={dropdownOption}
                              id="termsOfPayment"
                              iconClassName="dropdownIcon"
                              setValue={invoiceData?.termsOfPayment}
                              getvalue={setDropdownData}
                              required={true}
                              disabled={false}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={6}
                            lg={6}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Terms of Delivery"
                              type="text"
                              name="termsOfDelivery"
                              id="termsOfDelivery"
                              value={invoiceData?.termsOfDelivery}
                              onChange={onInputChange}
                              required={false}
                            />
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {!viewInvoiceDataStatus && (
              <Row className="my-4 ">
                <Col
                  lg={{ span: 2, offset: 8 }}
                  className="d-flex justify-content-end mt-2"
                >
                  <button
                    className="btnTable btn-danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </Col>
                <Col lg={2} className="d-flex justify-content-end mt-2">
                  <button className="btnTable" onClick={handleSave}>
                    Save
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default InvoiceConfirmationModal;
