import React, { useEffect, useState /* useRef */ } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
// import { InputField } from "../../../../Components/formElements/InputField";
import { getTodayDate } from "../../../../common/Validations";
// import PrintLoadingAdvice from "./PrintLoadingAdvice";
// import TableDropdownMenu from "../../../../Components/TableDropdownMenu/TableDropdownMenu";

//API
import ItemServiceV2 from "../../../../services/customer_v2_appCommonService";
// import ItemService from "../../../../services/customer_appCommonService";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { getOrderSchedulingList } from "../../../../redux/feature/customer_app/customerV1Slice";
// import { useReactToPrint } from "react-to-print";

const LoadingAdviceOrderHistoryModal = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [isCardVisible1, setCardVisible1] = useState(true);
  // const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  //loading advice print out
  // const [loadingAdviceData, setLoadingAdviceData] = useState();
  //loadingAdviceData is not defind any where check once
  // console.log(">>loadingAdviceData", loadingAdviceData);
  // useEffect(() => {
  //   setLoadingAdviceData(props?.loadingadvicedata);
  // }, [props?.loadingadvicedata]);

  const [lineItmes, setLineItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [viewStatus, setViewStatus] = useState(false);
  const [propsData, setPropsData] = useState();
  const [formInputs, setFormInputs] = useState();
  // const [godownData, setGodownData] = useState([]);
  // const [truckData, setTruckData] = useState([]);


  const mergedOrderHistory = lineItmes.flatMap((item) => item?.orderHistory);

  // console.log(mergedOrderHistory);

  // console.log(">>mergedOrderHistory", mergedOrderHistory);
  // console.log(">>lineItmes", lineItmes);

  useEffect(() => {
  

    setPropsData(props?.loadingadvicedata);
  }, [props?.loadingadvicedata]);

  const orderHistoryCol = [
    {
      name: "Product",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.materialDescription} </p>
          <p className="m-0 p-0 ">{row?.materialCode} </p>
        </div>
      ),
      width: "250px",
    },
    {
      name: "Qty",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.orderQtyLoadConfirm} </p>
          <p className="m-0 p-0 ">{row?.uOM} </p>
        </div>
      ),
      width: "130px",
    },
  
    {
      name: "Date/time",
  
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingDate||"--"} </p>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingTime||"--"} </p>
        </div>
      ),
      width: "160px",
    },
    {
      name: "Godown",
    
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.godownAddress||"--"} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Truck No",
   
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingTruckNo||"--"} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    
    {
      name: "Driver Details",
  
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.transporterDriverName||"--"} </p>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.transporterDriverContact||"--"} </p>
        </div>
      ),
      width: "160px",
    },
    {
      name: "Shipping Address",
      cell: (row) => (
        <>
          <p>{row?.shippingAddress}</p>
        </>
      ),
      width: "130px",
    },
    {
      name: "Shipping Postal Code",
      cell: (row) => (
        <>
          <p>{row?.shippingPostalCode}</p>
        </>
      ),
      width: "160px",
    },
  ];

  // dropdownOptionGodown.map((item) => ({
  //   ...item,
  //   displayValue: `${item.id} - ${item.name}`, // Combine id and name
  // }));

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  //handle cancel modal
  const handleCancel = () => {
    props.onHide();
  };

  //handle save
  const handleSave = async () => {
    // console.log(">>obj", formInputs);

    // Filter out records where orderQtyLoadConfirm <= 0
    const validItems = lineItmes.filter(
      (updatedItem) => parseFloat(updatedItem.orderQtyLoadConfirm) > 0
    );

    // Ensure at least one valid record exists
    if (validItems.length === 0) {
      toast.error("At least one valid record is required!", {
        duration: 4000,
        position: "top-right",
      });
      return; // Stop execution if no valid records
    }

    // Check if any remaining item has orderQtyLoadConfirm > balanceQty
    const isInvalid = validItems.some((updatedItem) => {
      const orderQtyLoadConfirm = parseFloat(updatedItem.orderQtyLoadConfirm);
      const balanceQty =
        parseFloat(updatedItem.balanceQty) ||
        parseFloat(updatedItem.orderQuantityLoad);

      if (orderQtyLoadConfirm > balanceQty) {
        toast.error("Load Qty should not be greater than Balance Qty!", {
          duration: 4000,
          position: "top-right",
        });
        return true; // Stop checking further
      }

      return false;
    });

    if (isInvalid) return; // Stop execution if validation fails

    const updatedData = {
      ...formInputs, // Include existing form inputs
      items: validItems, // Merge latest line items
      updateLoadingStatus: true, // Update status
    };

    // console.log(">>obj", updatedData);
    await ItemServiceV2.updateLoading(updatedData).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        handleSearchFuncApi();
        toast.success(item?.message || "Updated Successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onHide();
      } else {
        // setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: "",
    };
    dispatch(getOrderSchedulingList(obj));
  };

  //print require
  // const contentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   contentRef,
  //   documentTitle: `LoadingAdvice_${Math.floor(Math.random() * 1000000)}`,
  // });

  //call api for paritcular order
  const getMasterData = async () => {
    if (!propsData?.orderSchedulingNr) return;
    let obj = { documentNumber: propsData?.orderSchedulingNr };
    setLineItems([]);
    await ItemServiceV2.getOrderBoogkingDetail(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        let itemData = item?.data[0]?.items;
        // Update existing records
        let updateItemData = itemData.map((item, index) => ({
          ...item,
          orderQtyLoadConfirm: 0,
          loadingDate: getTodayDate(""),
          loadingTime: getCurrentTime(),
        }));

        setLineItems(updateItemData || []);
        setFormInputs(item?.data[0]);
      } else {
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    // Get warehouse data
    // let obj1 = {
    //   codeType: "warehouse",
    // };
    // await ItemService.getMasterData(obj1).then((item) => {
    //   // console.log(">> item! title", item);
    //   if (item?.status === 200) {
    //     setGodownData(item.data || []);
    //   } else {
    //     console.log(">> Error: Loading getMasterDataSelf");
    //   }
    // });

    // Get warehouse data
    // let obj2 = {
    //   codeType: "truckCode",
    // };
    // await ItemService.getMasterData(obj2).then((item) => {
    //   // console.log(">> item! title", item);
    //   if (item?.status === 200) {
    //     setTruckData(item.data || []);
    //   } else {
    //     console.log(">> Error: Loading getMasterDataSelf");
    //   }
    // });
  };

  useEffect(() => {
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsData]);

  //get current time
  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().slice(0, 5); // Format HH:mm (24-hour)
  };
  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Loading Advice History - {propsData?.orderSchedulingNr}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {/* Line Items */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">PD</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle">Product </span>
                  <span className="subHeaderTitle">Product details</span>
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <DataTable
                          title=""
                          columns={orderHistoryCol}
                          // data={lineItmes}
                          // data={orderHistories}
                          data={mergedOrderHistory}
                          // data={getOrderlinItems}
                          // progressPending={orderSchedulingData?.status}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={orderSchedulingTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        {!viewStatus && (
          <Row className="my-4 ">
            <Col
              lg={{ span: 2, offset: 8 }}
              className="d-flex justify-content-end"
            >
              <button
                className="btnTable btn-danger mt-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </Col>
            <Col lg={2} className="d-flex justify-content-end">
              <button className="btnTable mt-2" onClick={handleSave}>
                Save
              </button>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
});

export default LoadingAdviceOrderHistoryModal;
