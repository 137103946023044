import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import DataTable from "react-data-table-component";
import { InputField } from "../../../Components/formElements/InputField";

const AdvancePaymentRequestDetails = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  const [getDisabledStatus] = useState(true);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  const data = [
    {
      bankName:'DANIELI AUTOMATION S.P.A.',
      accountNumber:"000002845289",
      bankCountry:"Bank Country",
      ifcCode:"",
      accountHolderNaem:"",
      BankKey:""
    }
  ]
  const columns = [
      {
        name: "Bank Name",
        cell: (row) => <>{row.bankName}</>,
        width: "170px",
      },
      {
        name: "Account Number",
        cell: (row) => <>{row.accountNumber}</>,
        width: "170px",
      },
      {
        name: "Bank Country",
        cell: (row) => <></>,
        width: "170px",
      },
      {
        name: "Bank Key",
        cell: (row) => <></>,
        width: "170px",
      },
      {
        name: "Bank Name",
        cell: (row) => <></>,
        width: "170px",
      },
      {
        name: "SWIFT/IFSC Code",
        cell: (row) => <></>,
        width: "170px",
      },
  ]
  const [formInputs] = useState(
    {
      id: "",
      purchasingDocumentNr: " ",
      vendorCode: "",
      vendorName: "", // Assuming you want to add the value here
      requestedBy: "",
      requestedName: "",
      companyCode: "",
      apStatusHeader: "",
      currency: "",
      advancePaymentCurrency: "",
      advancePaymentAmount: "",
      advancePaymentPercent: "",
      purchasingDocumentValue: "",
      purchasingDocumentValueAed: "",
      advancePaymentAmountAed: "",
      downPaymentDueDate: "",
      buyerName: "",
      freightValue: "",
      prevApprovedAvancePaymentAmount:"",
      prevApprovedpercent: "",
      contractDescription: "",
      requestDate: "",
      exchangeRate: "",
      bankGuarantee: "",
      paymentAgainst: "",
      validityEndDate: "",
      justificationDescription: "",
      bankGuaranteeAttm: "",
      invoice: "",
      poContract: ""
    }
    
  );
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Advance Payment Request </h2>
                <p className="subText">Advance Payment Request Detail </p>
              </Col>
              <hr />
            </Row>

            {/* Advance Payment Request details  -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Advance payment Header Details
                      </span>
                      <span className="subHeaderTitle">List of APR</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="purchasing Document Number"
                          name="purchasingDocumentNr"
                          id="purchasingDocumentNr"
                          placeholder=""
                          value={formInputs.purchasingDocumentNr || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Code"
                          id="vendorCode"
                          name="vendorCode"
                          placeholder=""
                          value={formInputs.vendorCode || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Requested By"
                          id="requestedBy"
                          name="requestedBy"
                          placeholder=""
                          value={formInputs.requestedBy || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Requested Name"
                          id="requestedName"
                          name="requestedName"
                          placeholder=""
                          value={formInputs.requestedName || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          id="companyCode"
                          name="companyCode"
                          placeholder=""
                          value={formInputs.companyCode || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="AP Status Header"
                          id="apStatusHeader"
                          name="apStatusHeader"
                          placeholder=""
                          value={formInputs.apStatusHeader || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          id="currency"
                          name="currency"
                          placeholder=""
                          value={formInputs.currency || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Advance Payment Currency"
                          id="advancePaymentCurrency"
                          name="advancePaymentCurrency"
                          placeholder=""
                          value={formInputs.advancePaymentCurrency || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Advance Payment Amount"
                          id="advancePaymentAmount"
                          name="advancePaymentAmount"
                          placeholder=""
                          value={formInputs.advancePaymentAmount || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Advance Payment Percent"
                          id="advancePaymentPercent"
                          name="advancePaymentPercent"
                          placeholder=""
                          value={formInputs.advancePaymentPercent || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchasing Document Value"
                          name="purchasingDocumentValue"
                          id="purchasingDocumentValue"
                          placeholder=""
                          value={formInputs.purchasingDocumentValue || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchasing Document Value AED"
                          name="purchasingDocumentValueAed"
                          id="purchasingDocumentValueAed"
                          placeholder=""
                          value={formInputs.purchasingDocumentValueAed || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Advance PaymentAmount AED"
                          name="advancePaymentAmountAed"
                          id="advancePaymentAmountAed"
                          placeholder=""
                          value={formInputs.advancePaymentAmountAed || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Down Payment Due Date"
                          name="downPaymentDueDate"
                          id="downPaymentDueDate"
                          placeholder=""
                          type="date"
                          value={formInputs.downPaymentDueDate || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="BuyerName"
                          name="buyerName"
                          id="buyerName"
                          placeholder=""
                          value={formInputs.buyerName || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Freight Value"
                          name="freightValue"
                          id="freightValue"
                          placeholder=""
                          value={formInputs.freightValue || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Prev Approved Avance Payment Amount"
                          name="prevApprovedAvancePaymentAmount"
                          id="prevApprovedAvancePaymentAmount"
                          placeholder=""
                          value={
                            formInputs.prevApprovedAvancePaymentAmount || ""
                          }
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="prev Approved Percent"
                          name="prevApprovedpercent"
                          id="prevApprovedpercent"
                          placeholder=""
                          value={formInputs.prevApprovedpercent || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contract Description"
                          name="contractDescription"
                          id="contractDescription"
                          placeholder=""
                          value={formInputs.contractDescription || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Request Date"
                          name="requestDate"
                          id="requestDate"
                          placeholder=""
                          type="date"
                          value={formInputs.requestDate || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Exchange Rate"
                          name="ExchangeRate"
                          id="ExchangeRate"
                          placeholder=""
                          value={formInputs.ExchangeRate || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* other details  -2*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Other Details</span>
                      <span className="subHeaderTitle">Other Details</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bank Guarantee"
                          name="bankGuarantee"
                          id="bankGuarantee"
                          placeholder=""
                          value={formInputs.bankGuarantee || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Against"
                          name="paymentAgainst"
                          id="paymentAgainst"
                          placeholder=""
                          value={formInputs.paymentAgainst || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Validity End Date"
                          name="validityEndDate"
                          id="validityEndDate"
                          placeholder=""
                          type="date"
                          value={formInputs.validityEndDate || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Justification Description"
                          name="justificationDescription"
                          id="justificationDescription"
                          placeholder=""
                          value={formInputs.justificationDescription || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bank GuaranteeAttm"
                          name="bankGuaranteeAttm"
                          id="bankGuaranteeAttm"
                          placeholder=""
                          value={formInputs.bankGuaranteeAttm || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice"
                          name="invoice"
                          id="invoice"
                          placeholder=""
                          value={formInputs.invoice || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Contract"
                          name="poContract"
                          id="poContract"
                          placeholder=""
                          value={formInputs.poContract || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Other Details</span>
                      <span className="subHeaderTitle">Other Details</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                             <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={columns}
                          data={data}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default AdvancePaymentRequestDetails;
