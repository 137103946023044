import React from 'react';

export default function CheckCircle({ className }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      fill="#dc0000"
      viewBox="0 0 20 20"  
      className={`w-5 h-5 ${className}`}
    >
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
    </svg>
  );
}
