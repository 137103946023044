import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
// import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/SupplierConstants";
import OrderSchedulingModal from "./OrderSchedulingModal";
import { getOrderSchedulingList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
// import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import {
  /*  formatDate, */
  formatDateTime,
  formatNumber,
} from "../../../../common/Validations";
import OrderCancelModal from "./OrderCancelModal";
import OrderCarryForwardModal from "./OrderCarryForwardModal";
import LoadingAdviceOrderHistoryModal from "./LoadingAdviceOrderHistoryModal";
// import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
// import { InputField } from "../../../../Components/formElements/InputField";

const OrderBookingList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Order Booking");

  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  let orderSchedulingData = useSelector(
    (state) => state?.customerV1?.order_scheduling
  ); // User Details from Store
  let orderSchedulingTable = useSelector(
    (state) => state?.customerV1?.order_scheduling.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  // const detailedPage = (number) => {};

  const [data, setData] = useState();
  // console.log(">>data",data)
  const [getOrderCancelModal, setOrderCancelModal] = useState(false);
  // const [getCancelSelectedData, setCancelSelectedData] = useState(null); // Store selected data

  const [getOrderCarryFrdModal, setOrderCarryFrdModal] = useState(false);
  const [getCarryFrdSelectedData, setCarryFrdSelectedData] = useState(null); // Store selected data

  // const [getModalLoadingAdvice, setModalLoadingAdvice] = useState(false);
  const [loadingAdviceData, setLoadingAdviceData] = useState(null);
    const [getModalLoadingAdviceHistory, setModalLoadingAdviceHistory] = useState(false);
    const [historyStatus, setHistoryStatus] = useState(false);
    
    //show histroy modal
    const showLoadingAdviceHistory = (data) => {
      setModalLoadingAdviceHistory(true);
      setLoadingAdviceData(data);
      setHistoryStatus(true)
    };

  // const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  // const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  // const [getModalTitle, setModalTitle] = useState("");
  // const [getItemLevelData, setItemLevelData] = useState([]);

  // const lineItemCol = [
  //   {
  //     name: "Product",
  //     cell: (row) => (
  //       <div>
  //         <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
  //         <p className="m-0 p-0 ">{row.materialCode} </p>
  //       </div>
  //     ),
  //     width: "250px",
  //   },
  //   {
  //     name: "Qty",
  //     cell: (row) => (
  //       <div>
  //         <p className="m-0 p-0 pt-1 fw-bold ">{row.orderQuantityLoad} </p>
  //         <p className="m-0 p-0 ">{row.uOM} </p>
  //       </div>
  //     ),
  //     width: "100px",
  //   },
  //   {
  //     name: "Unit Price",
  //     cell: (row) => <span>{row.unitPrice} </span>,
  //     width: "100px",
  //   },

  //   {
  //     name: "Basic Price",
  //     selector: (row) => row.basicPrice || "--",
  //     cell: (row) => <span>{row.basicPrice} </span>,

  //     sortable: true,
  //     width: "120px",
  //   },
  //   {
  //     name: "Basic Amt",
  //     selector: (row) => row.basicAmount || "--",
  //     cell: (row) => <span>{row.basicAmount} </span>,

  //     sortable: true,
  //     width: "120px",
  //   },
  //   {
  //     name: "CGST",
  //     selector: (row) => row.cgstValue || "--",
  //     cell: (row) => <span>{row.cgstValue} </span>,

  //     sortable: true,
  //     width: "100px",
  //   },
  //   {
  //     name: "SGST",
  //     selector: (row) => row.sgstValue || "--",
  //     cell: (row) => <span>{row.sgstValue} </span>,

  //     sortable: true,
  //     width: "100px",
  //   },
  //   {
  //     name: "GST",
  //     selector: (row) => row.gstValue || "--",
  //     cell: (row) => <span>{row.gstValue} </span>,

  //     sortable: true,
  //     width: "100px",
  //   },
  //   {
  //     name: "Net Value",
  //     selector: (row) => row.netValue || "--",
  //     cell: (row) => <span>{row.netValue} </span>,

  //     sortable: true,
  //     width: "120px",
  //   },
  //   {
  //     name: "CGST%",
  //     selector: (row) => row.cgst || "--",
  //     sortable: true,
  //     width: "100px",
  //   },
  //   {
  //     name: "SGST%",
  //     selector: (row) => row.sgst || "--",
  //     sortable: true,
  //     width: "100px",
  //   },
  // ];

  // const itemLevelHideModal = () => {
  //   setItemLevelModalShow(false);
  // };

  // const lineItemShowModal = (itemLevelData) => {
  //   setItemLevelData(itemLevelData);
  //   setItemLevelColumns(lineItemCol);
  //   setModalTitle("Line Items");
  //   setItemLevelModalShow(true);
  // };

  const userListColInfo = [
    {
      name: "Order",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">
            {row.documentNumber}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.orderStatus,
    //   cell: (row) => (
    //     <>
    //       <TableStatusField status={row.orderStatus} />
    //     </>
    //   ),
    //   sortable: true,
    //   width: "100px",
    // },
    // {
    //   name: "Dispatch Date",
    //   selector: (row) => row.dispatchDate,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {formatDate(row.dispatchDate)}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {formatDateTime(row.createdDate)}
          </span>
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Company",
      selector: (row) => row.companyCode,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {row.companyCodeDesc}
          </span>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Customer",
      selector: (row) => row.sapDocumentNumber,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
            <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Item Nr",
      selector: (row) => row.sapDocumentNumber,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row?.items?.local_id} </p>
            {/* <p className="m-0 p-0 ">{row.sapDocumentNumber} </p> */}
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Material",
      selector: (row) => row?.items?.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0  fw-bold">{row?.items?.materialDescription} </p>
            <p className="m-0 p-0 pt-1  ">{row?.items?.materialCode} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    // {
    //   name: "Qty",
    //   selector: (row) => row.sumOfOrderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {row.sumOfOrderQuantityLoad}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Qty",
      selector: (row) => row?.items?.orderQuantityLoad,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {row?.items?.orderQuantityLoad}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Net Value",
    //   selector: (row) => row.sumOfRate,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {formatNumber(row.sumOfRate, "comma")}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {formatNumber(row?.items?.unitPrice, "comma")}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Ship-to-Party",
      selector: (row) => row.projectAddress,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectAddress} </p>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectAddress2} </p>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectCity} </p>
          <p className="m-0 p-0 pt-1  ">{row.projectCityPostalCode} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Shipping Addr",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.items?.shippingAddress} </p>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.items?.shippingPostalCode} </p>
      
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Loading Advice",
      selector: (row) => row.updateLoadingStatus,
      cell: (row) => (

        // <>
        //   {row.updateLoadingStatus ? (
        //     <span
        //       style={{ marginLeft: "30px", fontSize: "30px" }}
        //       onClick={() => showLoadingAdvice(row)}
        //       className="iconTableNew"
        //     >
        //       {/* <i className="bi bi-receipt-cutoff"></i> */}
        //       <img
        //         src="assets/img/newIcons/delivery-truck.png"
        //         alt="attachments"
        //         style={{ width: "40px" }}
        //         className="mt-1"
        //       />
        //     </span>
        //   ) : (
        //     <button className="btnTable" onClick={() => showLoadingAdvice(row)}>
        //       Create
        //     </button>
        //   )}
        // </>
        <>
          {row.updateLoadingStatus ? (
            <div
              style={{ marginLeft: "30px", fontSize: "30px" }}
              onClick={() => showLoadingAdviceHistory(row)}
              className="iconTableNew"
            >
              <img
                src="assets/img/newIcons/delivery-truck.png"
                alt="attachments"
                style={{ width: "40px" }}
                className="mt-1"
              />
            </div>
          ) : (
            ""
          )}
        </>
      ),
      sortable: true,
      width: "150px",
    },
   
    // {
    //   name: "Customer",
    //   selector: (row) => row.sapDocumentNumber,
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
    //         <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Project",
    //   selector: (row) => row.projectName,
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Project Address",
    //   // selector: (row) => row.location,
    //   cell: (row) => (
    //     <>
    //       <span>{row.getProjectDetails?.projectAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Product",
    //   selector: (row) => row.materialCode,
    //   sortable: true,
    //   width: "150px",
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
    //       <p className="m-0 p-0 ">{row.materialCode} </p>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Line Items",
    //   cell: (row) => (
    //     <>
    //       <span
    //         style={{ marginLeft: "12px" }}
    //         onClick={() => lineItemShowModal(row.items)}
    //       >
    //         <i className="bi bi-calendar2-week iconTable"></i>
    //       </span>
    //       <span className="suppliersIcon"> {row.items?.length} </span>
    //     </>
    //   ),
    //   width: "120px",
    // },
    // {
    //   name: "Ord Qty(Bags)",
    //   selector: (row) => row.orderQuantityLoad,
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold text-success">{row.orderQuantityLoad} </p>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Bal Qty(Bags)",
    //   selector: (row) => row.balOrderQuantityLoad,
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold text-danger">{row.balOrderQuantityLoad} </p>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "CarryFrd(Bags)",
    //   selector: (row) => row.carryForwardOrderLoad,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">{row.carryForwardOrderLoad}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Action",
    //   selector: (row) => row.dispatchDate,
    //   cell: (row) => (
    //     <>
    //       {row.balOrderQuantityLoad === 0 ? (
    //         <button className="btnTable" disabled={true}>
    //           Carry Frd
    //         </button>
    //       ) : (
    //         <button className="btnTable" onClick={() => showCarryFrd(row)}>
    //           Carry Frd
    //         </button>
    //       )}
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Canl Qty(Bags)",
    //   selector: (row) => row.cancelledOrderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-warning">{row.cancelledOrderQuantityLoad}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },

    // {
    //   name: "Customer",
    //   selector: (row) => row.customerDetails,
    //   cell: (row) => (
    //     <>
    //       <span >{row.customerDetails}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Other Cust",
    //   selector: (row) => row.customerDetailsIfOther,
    //   cell: (row) => (
    //     <>
    //       <span>{row.customerDetailsIfOther}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Delivery",
    //   selector: (row) => row.deliveryAddress,
    //   cell: (row) => (
    //     <>
    //       <span >{row.deliveryAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Remarks",
    //   selector: (row) => row.deliveryAddress,
    //   cell: (row) => (
    //     <>
    //       <span >{row.deliveryAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "CanlQty(H)",
    //   cell: (row) => (
    //     <>
    //       <span
    //         style={{ marginLeft: "12px" }}
    //         // onClick={() => showCancelQtyList(row.cancelHostory)}
    //       >
    //         <i className="bi bi-calendar2-week iconTable"></i>
    //       </span>
    //       <span className="suppliersIcon">
    //         {" "}
    //         {row.cancelHostory?.length || 0}{" "}
    //       </span>
    //     </>
    //   ),
    //   width: "120px",
    // },
    // {
    //   name: "CarryFrd(H)",
    //   cell: (row) => (
    //     <>
    //       <span
    //         style={{ marginLeft: "12px" }}
    //         /*  onClick={() => showCarryForwardList(row.carryForwardHostory)} */
    //       >
    //         <i className="bi bi-calendar2-week iconTable"></i>
    //       </span>
    //       <span className="suppliersIcon">
    //         {" "}
    //         {row.carryForwardHostory?.length || 0}{" "}
    //       </span>
    //     </>
    //   ),
    //   width: "120px",
    // },
    {
      name: "Sales Officer",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.createdByName} </p>
            <p className="m-0 p-0 ">{row.createdBy} </p>
          </div>
        </>
      ),
      selector: (row) => row.createdByName || "--",
      sortable: true,
      width: "140px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 ">{row.createdByName} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Action",
      selector: (row) => row.dispatchDate,
      cell: (row) => (
        <>
          {row.balOrderQuantityLoad === 0 ? (
            <button className="btnTable btn-danger" disabled={true}>
              Cancel
            </button>
          ) : (
            <button
              className="btnTable btn-danger"
              // onClick={() => showCancel(row)}
            >
              Cancel
            </button>
          )}
        </>
      ),
      sortable: true,
      width: "120px",
    },
  ];
  

  const [getOrderSheduling, setOrderSheduling] = useState(false);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");
  const [getUserRoleStatus, setUserRoleStatus] = useState(true);

  const showOrderScheduling = () => {
    setOrderSheduling(true);
  };
  const hideOrderScheduling = () => {
    setOrderSheduling(false);

    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  const loadInitialSuuplierData = async () => {
    setData(orderSchedulingData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSchedulingData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getOrderScheduleBookingListV1",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;

    if (userRole === "CUSTOMER") {
      setUserRoleStatus(false);
    } else if (
      userRole === "SALES-COORDINATOR" ||
      userRole === "GENERAL-MNGR" ||
      userRole === "DISPATCH-OFFICER" ||
      userRole === "SALES-DIRECTOR"
    ) {
      setUserRoleStatus(false);
    } else {
      setUserRoleStatus(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  //cancel Modal
  // const showCancel = (item) => {
  //   setCancelSelectedData(item);
  //   setOrderCancelModal(true);
  // };
  const hideModal = () => {
    setOrderCancelModal(false);
    setModalLoadingAdviceHistory(false);
  };

  // showCarryFrd Modal
  // const showCarryFrd = (item) => {
  //   setCarryFrdSelectedData(item);
  //   setOrderCarryFrdModal(true);
  // };
  
  const hideshowCarryFrdModal = () => {
    setOrderCarryFrdModal(false);
    setCarryFrdSelectedData(null);
  };

  // const cancelQtyColInfo = [
  //   {
  //     name: "Cancelled Date",
  //     cell: (row) => <span>{formatDateTime(row.cancelDate)} </span>,
  //     width: "180px",
  //   },
  //   {
  //     name: "Quantity Load",
  //     cell: (row) => <span>{row.cancelledOrderQuantityLoad} </span>,
  //     width: "180px",
  //   },
  //   {
  //     name: "Remarks",
  //     cell: (row) => <span>{row.cancelledOrderRemarks} </span>,
  //     width: "180px",
  //   },
  // ];

  // const carryForwardColInfo = [
  //   {
  //     name: "Date",
  //     cell: (row) => <span>{formatDate(row.carryForwardDate)} </span>,
  //     width: "180px",
  //   },
  //   {
  //     name: "Dispatch Date",
  //     cell: (row) => <span>{formatDate(row.dispatchDate)} </span>,
  //     width: "180px",
  //   },
  //   {
  //     name: "Carry Forward Order Load",
  //     cell: (row) => <span>{row.carryForwardOrderLoad} </span>,
  //     width: "240px",
  //   },
  //   {
  //     name: "Remarks",
  //     cell: (row) => <span>{row.carryForwardOrderRemarks} </span>,
  //     width: "180px",
  //   },
  // ];

  // Item List
  // const itemLevelHideModal = () => {
  //   setItemLevelModalShow(false);
  // };
  // OnClick on categoryItem  Data
  // const showCancelQtyList = (itemLevelData) => {
  //   setItemLevelData(itemLevelData);
  //   setItemLevelColumns(cancelQtyColInfo);
  //   setModalTitle("Cancel Qty Details");
  //   setItemLevelModalShow(true);
  // };

  // const showCarryForwardList = (itemLevelData) => {
  //   setItemLevelData(itemLevelData);
  //   setItemLevelColumns(carryForwardColInfo);
  //   setModalTitle("Carry Forward Details");
  //   setItemLevelModalShow(true);
  // };

  //loading advice modal
  //modal show
  // const showLoadingAdvice = (data) => {
  //   // setModalLoadingAdvice(true);
  //   setLoadingAdviceData(data);
  //   setModalLoadingAdviceHistory(true)
  // };
  // const hideModal = () => {
  // };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Order Booking </h2>
                <p className="subText">Order Booking List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OS</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">
                        Order Booking Details{" "}
                      </span>
                      <span className="subHeaderTitle">
                        View of Customer Orders List{" "}
                      </span>
                    </Col>
                    {getUserRoleStatus && (
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={() => showOrderScheduling()}
                        >
                          Order Booking
                        </button>
                      </Col>
                    )}

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={orderSchedulingData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={orderSchedulingTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <OrderSchedulingModal
        show={getOrderSheduling}
        onHide={hideOrderScheduling}
        clear={true}
      />
      <OrderCancelModal
        show={getOrderCancelModal}
        onHide={() => hideModal(getOrderCancelModal)}
        // data={getCancelSelectedData}
        onSuccess={() => handleSearchFuncApi()}
      />
      <OrderCarryForwardModal
        show={getOrderCarryFrdModal}
        onHide={hideshowCarryFrdModal}
        data={getCarryFrdSelectedData}
        onSuccess={() => handleSearchFuncApi()}
      />
      {/* <LoadingAdviceModal
        show={getModalLoadingAdvice}
        onHide={() => hideModal()}
        loadingadvicedata={loadingAdviceData}
        // onsuccessdata={handleSuccessData}
      /> */}
      <LoadingAdviceOrderHistoryModal
        show={getModalLoadingAdviceHistory}
        onHide={() => hideModal()}
        loadingadvicedata={loadingAdviceData}
        historyStatus={historyStatus}
        // onsuccessdata={handleSuccessData}
      />
      {/* <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />   */}
    </main>
  );
};

export default OrderBookingList;
