
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
    data:[],
    status:true,
    singleSourceNr:'',
};

export const singleSourcingSlice = createSlice({
    name: "singleSource",
    initialState,
    reducers: {
        addSingleSourceRequestNr(state, action) {
            state.singleSourceNr = action.payload;
          },
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getSingleSourcingList.fulfilled, (state,action)=>{
            state.data = action.payload;
            state.status = true;
        })        
    }
  });

// export const { addGatepassDetailNumber } = singleSourcingSlice.actions;
export const { addSingleSourceRequestNr } = singleSourcingSlice.actions;
export default singleSourcingSlice.reducer;

export const getSingleSourcingList = createAsyncThunk('getSingleSourcingList', async () =>{
    console.log(">> loaded getSingleSourcingList");
    const res = await ItemService.getSingleSourcingList();
    return res.data;
})

