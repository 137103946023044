import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/customer_appCommonService";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch

//Components
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { ComboboxFieldSubArray } from "../../../../Components/formElements/ComboboxFieldSubArray";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/SupplierConstants";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import {
  validateName,
  // validateRequired,
  validPostalCode,
} from "../../../../common/Validations";
import CustomerConstants from "../../../../common/CustomerConstants";
import toast from "react-hot-toast";
import GoogleMapComponent from "../Common/GoogleMapComponent";
import FileUploadComponent from "../../../../Components/formElements/FileUploadComponent";
import Loader from "../../../../Components/Loader";
import Layout from "../../../../Layout/Layout";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import { getLoginUserData } from "../../../../redux/feature/userSlice";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import { useReactToPrint } from "react-to-print";
import PrintKYCRegistration from "../KYCForm/PrintKYCRegistration";
import FileViewerModal from "../../../../Components/FileViewer/FileViewerModal";
import { kycExistingCustomer } from "../../../../redux/feature/customer_app/customerV1Slice";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
};

const CustomerRegistrationV1Details = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Customer List");

  // let customerData = useSelector((state) => state?.customer?.data); // User Details from Store
  let customerID = useSelector((state) => state?.customer?.customerID); // User Details from Store
  let workflow = useSelector((state) => state?.customer?.customerWorkflow); // workflow from Store

  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];
  let routNumberDropdownOption = [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
  ];
  let productDocumentAttachment = [
    {
      id: "1",
      name: "Google Pin location",
    },
    {
      id: "2",
      name: "Project Photos",
    },
    {
      id: "3",
      name: "Blueprints or Layout Plans",
    },
    {
      id: "5",
      name: "Project Document",
    },
  ];
  const SalesOfficerData = [
    { id: 1, code: "30055", name: "V.Jaywant" },
    { id: 2, code: "30054", name: "B.K.Rangaswamy" },
    { id: 3, code: "30061", name: "T.Ramireddy" },
    { id: 4, code: "30060", name: "T.Sreekanth Reddy" },
    { id: 5, code: "30031", name: "P.Sony Pradeep Kumar" },
    { id: 6, code: "30062", name: "KK.Jamuna" },
  ];
  // const dropDowntypeOfCompany = [
  //   { id: 1, name: "Propriter" },
  //   { id: 2, name: "Partner" },
  //   { id: 3, name: "Director" },
  // ];
  const dropDowntypeOfCreditType = [
    { id: 1, name: "Credit" },
    { id: 2, name: "Advance Payment" },
  ];
  // const dropDowntypeCreditType = [
  //   { id: 1, name: "Days" },
  //   { id: 2, name: "Months" },
  // ];
  const securityType = [
    { id: 1, name: "PDC" },
    { id: 2, name: "BG" },
    { id: 3, name: "LC" },
  ];
  const dropDownLackCrore = [
    { id: 1, name: "Lakhs" },
    { id: 1, name: "Crore" },
  ];
  let dropDownYear = [
    {
      id: "1",
      name: "2024-2025",
    },
    {
      id: "2",
      name: "2023-2024",
    },
    {
      id: "3",
      name: "2021-2022",
    },
    {
      id: "4",
      name: "2019-2020",
    },
    {
      id: "5",
      name: "2017-2018",
    },
  ];
  const existingCustomer = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];
  const dropDownTypeOfFirm = [
    { id: 1, name: "Proprietorship" },
    { id: 2, name: "Partnership" },
    { id: 3, name: "Public Limited Company" },
    { id: 4, name: "Public Limited Company" },
    { id: 5, name: "Public Liability Company" },
  ];
  const dropDownCreditPeriodDays = [
    { id: 1, name: "30" },
    { id: 2, name: "60" },
    { id: 2, name: "90" },
    { id: 2, name: "120" },
    { id: 2, name: "150" },
  ];
  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  // eslint-disable-next-line no-unused-vars
  const [getPaymentTypeStatus, setPaymentTypeStatus] = useState("");

  const [editMode, setEditMode] = useState(true);

  // let requiredFields = [];

  const [getDropdownData, setDropdownData] = useState("");
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  // const [isCardVisible7, setCardVisible7] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible11, setCardVisible11] = useState(true);
  const [isCardVisible12, setCardVisible12] = useState(true);

  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields /* setErrorFields */] = useState([]);
  const [getcontactPosition, setcontactPosition] = useState([]);
  const [getTitle, setTitle] = useState([]);
  const [nextId, setNextId] = useState(1); // Start IDs from 1
  const [loading, setLoading] = useState(false);

  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [getAction, setAction] = useState("");

  const [editorContent, setEditorContent] = useState(""); //remarks
  const [paymentTermsApprovalData, setPaymentTermsApprovalData] = useState([]); //remarks

  const [getcustomerContactPerson, setcustomerContactPerson] = useState([
    {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    },
  ]);
  // console.log(">>getcustomerContactPerson", getcustomerContactPerson);
  const [getDocumentTypeData, setDocumentTypeData] = useState([
    {
      id: Date.now(),
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "29AADCN1012B1Z6",
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const [getProjectContactPerson, setProjectContactPerson] = useState([
    {
      id: Date.now(),
      projectContactPosition: "",
      projectContactTitle: "",
      projectContactName: "",
      projectContactNumber: "",
      projectContactEmail: "",
    },
  ]);
  const [getdocumentType, setdocumentType] = useState([]);

  const [getState, setState] = useState([]);
  const [getCountry, setCountry] = useState([]);

  const [validation, setValidation] = useState({
    organisationName: "",
    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
  });

  // ADD new projects
  const newProject = {
    projectCode: 1,
    projectCodeDesc: "PROJECT_1",
    projectName: "",
    projectAddress: "",
    projectAddress2: "",
    projectCity: "",
    projectCountry: "IN - India",
    projectState: "IN - Karanataka",
    projectCityPostalCode: "",
    getProjectContactPerson: [
      {
        id: Date.now(), // Unique ID for the first contact person
        projectContactPosition: "",
        projectContactTitle: "",
        projectContactName: "",
        projectContactNumber: "",
        projectContactEmail: "",
      },
    ],
    getProjectCertifications: [
      {
        id: Date.now(),
        name: "",
        documentType: "",
        certificateCode: "",
      },
    ],
    getLocations: [],
  };

  const [getProjectDetails, setProjectDetails] = useState([newProject]);
  // console.log(">>getProjectDetails", getProjectDetails);

  const [getRouteDetails, setRouteDetails] = useState([]);

  //Company Profile
  const [getCompanyProfile, setCompanyProfile] = useState({
    existingCustomer: "",
    customerCode: "",
    typeOfCompany: "",
    typeOfFirm: "",
    creditLimit: "",
    creditLimitINR: "",
    typeOfCustomerSegment: "",
    creditPeriodDays: "",
    numberOfYearsInBusiness: "",
    security: "",
    detailsOfSecurity: "",
    paymentType: "",
  });
  // console.log(">>getCompanyProfile", getCompanyProfile);
  const [getprojectCompletedData, setprojectCompletedData] = useState();

  const [getExisitingCustomer, setExisitingCustomer] = useState("");

  const [getCustomer, setCustomer] = useState([]);

  /* project completed data and col */
  let projectCompletedCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "80px",
    },
    {
      name: "Project Name",
      selector: (row) => row.projectNameCopleted || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="projectNameCopleted"
          placeholder=""
          value={row.projectNameCopleted || ""}
          onChange={(event) =>
            handleInputFieldChangeCompleted(row.local_id, event)
          }
          disabled={editMode}
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Project Tenure",
      selector: (row) => row.projectTenureCompleted || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="projectTenureCompleted"
          placeholder=""
          value={row.projectTenureCompleted || ""}
          disabled={editMode}
          onChange={(event) =>
            handleInputFieldChangeCompleted(row.local_id, event)
          }
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDateCompleted || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="startDateCompleted"
          placeholder=""
          type="date"
          value={row.startDateCompleted || ""}
          disabled={editMode}
          onChange={(event) =>
            handleInputFieldChangeCompleted(row.local_id, event)
          }
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "End Date",
      selector: (row) => row.endDateCompleted || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="endDateCompleted"
          type="date"
          placeholder=""
          value={row.endDateCompleted || ""}
          disabled={editMode}
          onChange={(event) =>
            handleInputFieldChangeCompleted(row.local_id, event)
          }
        />
      ),
      width: "220px",
    },

    {
      name: "Delete",
      omit: editMode ? true : false,
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button onClick={() => removeProjecetDocumentItemRow(row.local_id)}>
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];
  let projectCompletedData = [
    {
      local_id: 1,
      projectNameCopleted: "",
      projectTenureCompleted: "",
      startDateCopleted: "",
      endDateCopleted: "",
      statusCopleted: "",
    },
  ];

  // Project on Hand col and Data
  let projectOnHandCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "80px",
    },
    {
      name: "Project Name",
      selector: (row) => row.projectNameOnHand || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="projectNameOnHand"
          placeholder=""
          value={row.projectNameOnHand || ""}
          disabled={editMode}
          onChange={(event) => handleInputChangeOnHand(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Project Tenure",
      selector: (row) => row.projectTenureOnHand || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="projectTenureOnHand"
          placeholder=""
          value={row.projectTenureOnHand || ""}
          disabled={editMode}
          onChange={(event) => handleInputChangeOnHand(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDateOnHand || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="startDateOnHand"
          type="date"
          placeholder=""
          value={row.startDateOnHand || ""}
          disabled={editMode}
          onChange={(event) => handleInputChangeOnHand(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Tentative Date",
      selector: (row) => row.endDateOnHand || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="endDateOnHand"
          type="date"
          placeholder=""
          value={row.endDateOnHand || ""}
          disabled={editMode}
          onChange={(event) => handleInputChangeOnHand(row.local_id, event)}
        />
      ),
      width: "220px",
    },
    {
      name: "Delete",
      omit: editMode ? true : false,
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          onClick={() => removeprojectDetailContactPersonItemRow(row.local_id)}
        >
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];
  let projectOnHandData = [
    {
      local_id: 1,
      projectNameOnHand: "",
      projectTenureOnHand: "",
      startDateOnHand: "",
      endDateOnHand: "",
      statusOnHand: "",
    },
  ];
  const [getProjectOnHandData, setProjectOnHandData] = useState();

  const [getCustomerProfile, setCustomerProfile] = useState();

  // console.log(">>getRouteDetails",getRouteDetails)
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  // const toggleCardVisibility7 = () => {
  //   setCardVisible7(!isCardVisible7);
  // };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility11 = () => {
    setCardVisible11(!isCardVisible11);
  };
  const toggleCardVisibility12 = () => {
    setCardVisible12(!isCardVisible12);
  };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
    validateName(name === "organisationName" ? value : "")
      ? (errors.organisationName = "")
      : (errors.organisationName = message.VALID_CUSTOMER_NAME);
    validPostalCode(name === "cityPostalCode" ? value : "")
      ? (errors.cityPostalCode = "")
      : (errors.cityPostalCode = message.VALID_POSTAL_CODE);

    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  const [formInputs, setFormInputs] = useState({
    userId: customerID,
    organisationName: "",
    address: "",
    address2: "",
    city: "",
    country: "IN - India",
    state: "IN - Karnataka",
    cityPostalCode: "",

    version: "v0",
    status: "Non Invited",
    category: "VENDOR",

    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
    salesOfficerName: "",

    plantAddress:
      "Bharathi Rock Products India PVT Ltd Sy No.9, Kanivenarayanapura(V), Muddenahalli Post,Chikkabalapura-562101",
    projectLocation: "",

    customerTypeCode: "C03",
    customerTypeDesc: "Domestic",
  });
  // console.log(">>formInputs", formInputs);
  // console.log(">>KYCIndicator",formInputs.KYCIndicator)
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getMasterData();
  }, [dispatch]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.codeType === "country") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      getStateData(getDropdownData?.code);
    } else if (getDropdownData?.textCode === "paymentTypeCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      setCompanyProfile((prev) => ({
        ...prev,
        paymentType: getDropdownData?.name,
      }));
      setPaymentTypeStatus(getDropdownData?.name);
    } else if (getDropdownData?.textCode === "existingCustomerCode") {
      setCompanyProfile((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      setExisitingCustomer(getDropdownData?.name);
    } else if (getDropdownData?.textCode === "customerCodeCode") {
      setCompanyProfile((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      let obj = {
        customerID: getDropdownData.id,
        name: getDropdownData.name,
        sapDocumentNumber: getDropdownData.sapDocumentNumber,
      };
      dispatch(kycExistingCustomer(obj));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      setCompanyProfile((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    setValidation((prev) => ({
      ...prev,
      [getDropdownData?.textField]: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // console.log(">> formInputs", formInputs);

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "title",
    };
    await ItemService.getMasterDataSelf(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setTitle(item?.data);
      } else {
        setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj1 = {
      codeType: "documentType",
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setdocumentType(item?.data);
      } else {
        setdocumentType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj2 = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterDataSelf(obj2).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        setcontactPosition(item?.data);
      } else {
        setcontactPosition([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj3 = {
      codeType: "country",
    };
    await ItemService.getMasterDataSelf(obj3).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj4 = {
      type: "ALL",
    };
    await ItemService.getCustomers(obj4).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setCustomer(item?.data);
      } else {
        setCustomer([]);
        console.log(">> Error: Loading getCustomers");
      }
    });
  };
  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  // eslint-disable-next-line no-unused-vars
  const navigateToLogin = () => {
    navigate(Constants.LOGIN);
  };

  /* ---------------------Customer Contact Person -------- */

  const deleteCustomerContactPerosn = (id) => {
    const updatedData1 = getcustomerContactPerson.filter(
      (doc) => doc.id !== id
    );
    setcustomerContactPerson(updatedData1);
  };
  const addCustomerCantactPerson = () => {
    const newDocument = {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    };
    setcustomerContactPerson((prevData) => [...prevData, newDocument]);
  };
  const handleChangeCustomerContacts = (id, fieldName, value) => {
    setcustomerContactPerson((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------Document Type  03rd block-------- */

  const deleteDocumentType = (id) => {
    const updatedData1 = getDocumentTypeData.filter((doc) => doc.id !== id);
    setDocumentTypeData(updatedData1);
  };
  const addDocumentType = () => {
    const newDocument = {
      id: Date.now(),
      name: `Document ${getDocumentTypeData.length + 1}`,
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "",
    };
    setDocumentTypeData((prevData) => [...prevData, newDocument]);
  };
  const handleInputChange = (id, fieldName, value) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------project details block-------- */
  const addProjectDetails = () => {
    newProject.projectCode = nextId + 1;
    newProject.projectCodeDesc = "PROJECT_" + (nextId + 1);
    setProjectDetails((prevData) => [...prevData, newProject]);
    setNextId((prevId) => prevId + 1);
  };
  const removeProjectDetails = (indexToRemove) => {
    setProjectDetails(
      getProjectDetails.filter((_, idx) => idx !== indexToRemove)
    );
  };
  const handleProjectInputChange = (value, index, fieldName) => {
    // console.log(">>handleProjectInputChange", { index, fieldName, value });

    if (index === undefined || value === undefined) return;

    // Update the specific project detail
    setProjectDetails((prevDetails) =>
      prevDetails.map((item, idx) =>
        idx === index ? { ...item, [fieldName]: value } : item
      )
    );
  };

  /* --------------------- Contact Person Inside Project Details -------- */

  //Add Projects
  const addProjectCantactPersonNew = (projectIndex) => {
    const newContactPerson = {
      id: Date.now(), // Unique ID for each contact person
      projectContactPosition: "",
      projectContactTitle: "",
      projectContactName: "",
      projectContactNumber: "",
      projectContactEmail: "",
    };

    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: [
          ...updatedProjectDetails[projectIndex].getProjectContactPerson,
          newContactPerson,
        ],
      };
      return updatedProjectDetails;
    });
  };
  //Delete Project
  const deleteProjectContactPerosnNew = (projectIndex, id) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.filter((contact) => contact.id !== id),
      };
      return updatedProjectDetails;
    });
  };
  //Project Contact Inputs
  const handleChangeProjectContactsNew = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value } // Update the specific field
              : contact // Keep other contacts unchanged
        ),
      };
      return updatedProjectDetails;
    });
  };
  //Dropdown
  const handleChangeProjectContactsNewDropdown = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Update the specific contact person's field
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value.name } // Update the field with the full object
              : contact // Keep other contacts unchanged
        ),
      };

      return updatedProjectDetails;
    });
  };

  /* ---------------------project Certifications  -------- */

  const deleteProjectCertifications = (projectIndex, certificationId) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Filter out the certification with the specified id
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: updatedProjectDetails[
          projectIndex
        ].getProjectCertifications.filter(
          (certification) => certification.id !== certificationId
        ),
      };

      return updatedProjectDetails;
    });
  };
  const addProjectCertifications = (projectIndex) => {
    const newCertification = {
      id: Date.now(), // Unique ID for the new certification
      name: "Document 1",
      documentType: "",
      certificateCode: "29AADCN1012B1Z6",
    };

    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Add the new certification to the getProjectCertifications array
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: [
          ...updatedProjectDetails[projectIndex].getProjectCertifications,
          newCertification,
        ],
      };

      return updatedProjectDetails;
    });
  };

  /* ---------------------Payment Terms Approval details  -------- */

  const handleInputChangePaymentTerms = (slNo, event) => {
    const { name, value } = event.target;
    setPaymentTermsApprovalData((prevRows) =>
      prevRows.map((row) =>
        row.slNo === slNo ? { ...row, [name]: value } : row
      )
    );
  };

  const paymentTermsApprovalCol = [
    {
      name: "Sl.No",
      selector: (row) => row.slNo || "-",
      cell: (row) => <>{row.slNo}</>,
      width: "80px",
    },
    {
      name: "Payment Type",
      selector: (row) => row.paymentType || "-",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="paymentType"
            value={row.paymentType || ""}
            disabled={true}
          />
        </>
      ),
      width: "150px",
    },
    {
      name: "Credit Period (Days)",
      selector: (row) => row.creditPeriodDays || "-",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="creditPeriodDays"
            value={row.creditPeriodDays || ""}
            disabled={row.editStatus}
            onChange={(event) => handleInputChangePaymentTerms(row.slNo, event)}
          />
        </>
      ),
      width: "180px",
    },
    {
      name: "Credit Limit (Lakhs)",
      selector: (row) => row.creditLimitINR || "-",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="creditLimitINR"
            value={row.creditLimitINR || ""}
            disabled={row.editStatus}
            onChange={(event) => handleInputChangePaymentTerms(row.slNo, event)}
          />
        </>
      ),
      width: "180px",
    },
  ];

  /* ---------------------route and addition details  -------- */
  // eslint-disable-next-line no-unused-vars
  const routeTableCol = [
    {
      name: "Sl.No",
      selector: (row) => row.slNo || "--",
      cell: (row) => <>{row.slNo} </>,
      width: "150px",
    },
    {
      name: "RouteNr",
      selector: (row) => row.routeNumber || "--",
      cell: (row) => <>{row.routeNumber} </>,
      width: "220px",
    },
    {
      name: "Route",
      selector: (row) => row.routeDescription || "--",
      cell: (row) => <>{row.routeDescription} </>,
      width: "220px",
    },
    {
      name: "Toll",
      selector: (row) => row.tollNumber || "--",
      cell: (row) => <>{row.tollNumber} </>,
      width: "150px",
    },
    {
      name: "Rate",
      selector: (row) => row.tollRates || "--",
      cell: (row) => <>{row.tollRates} </>,
      width: "220px",
    },
    {
      name: "From",
      selector: (row) => row.noEntryTimeFromAm || "--",
      cell: (row) => <>{row.noEntryTimeFromAm} </>,
      width: "220px",
    },
    {
      name: "To",
      selector: (row) => row.noEntryTimeToAm || "--",
      cell: (row) => <>{row.noEntryTimeToAm} </>,
      width: "220px",
    },
    {
      name: "From",
      selector: (row) => row.noEntryTimeFromPm || "--",
      cell: (row) => <>{row.noEntryTimeFromPm} </>,
      width: "220px",
    },
    {
      name: "To",
      selector: (row) => row.noEntryTimeToPm || "--",
      cell: (row) => <>{row.noEntryTimeToPm} </>,
      width: "220px",
    },
  ];

  useEffect(() => {
    if (formInputs?.routeNumber) {
      const route1TableData = [
        {
          slNo: 1,
          routeNumber: 1,
          routeDescription: "Yalahanka",
          tollNumber: 750,
          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 25,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
      ];
      const route2TableData = [
        {
          slNo: 1,
          routeNumber: 2,
          routeDescription: "Melkote	",
          tollNumber: 180,
          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 6,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 2,
          routeNumber: 2,
          routeDescription: "Doddabalapura",
          tollNumber: 180,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 6,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 3,
          routeNumber: 2,
          routeDescription: "Doddabalapura	",
          tollNumber: 360,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 12,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 4,
          routeNumber: 2,
          routeDescription: "Budigere",
          tollNumber: 395,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 13,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
      ];
      const route3TableData = [
        {
          slNo: 1,
          routeNumber: 3,
          routeDescription: "Free zone",
          tollNumber: 0,

          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 0,
          noEntryTimeFromAm: "00:00AM",
          noEntryTimeToAm: "00:00AM",
          noEntryTimeFromPm: "00:00PM",
          noEntryTimeToPm: "00:00PM",
        },
      ];

      const newRouteDetails =
        formInputs.routeNumber === "1"
          ? route1TableData
          : formInputs.routeNumber === "2"
          ? route2TableData
          : formInputs.routeNumber === "3"
          ? route3TableData
          : "";

      setRouteDetails((prevRouteDetails) =>
        prevRouteDetails !== newRouteDetails
          ? newRouteDetails
          : prevRouteDetails
      );
    }
  }, [formInputs]);

  const onSuccessUploadDocumentType = (data) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === data.fieldId
          ? { ...doc, [data.fieldName]: data.attachmentId }
          : doc
      )
    );
  };

  //document upload for projects
  const onSuccessUploadProjectDocumentType = (
    projectIndex,
    docId,
    fieldName,
    attachmentId
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: updatedProjectDetails[
          projectIndex
        ].getProjectCertifications.map((cert) =>
          cert.id === docId ? { ...cert, [fieldName]: attachmentId } : cert
        ),
      };

      return updatedProjectDetails;
    });
  };
  const onHandleMapData = (data) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      // Update the getLocations array with the new data
      updatedProjectDetails[data?.projectIndex] = {
        ...updatedProjectDetails[data?.projectIndex],
        getLocations: [
          ...(updatedProjectDetails[data?.projectIndex].getLocations || []), // Ensure getLocations is an array
          {
            distance: data.distance,
            duration: data.duration,
            fromToCoords: data.fromToCoords,
            plantAddress: data.plantAddress,
            projectLocation: data.projectLocation,
          },
        ],
      };

      return updatedProjectDetails;
    });
  };

  const loadFirstLevelData = async (page) => {
    setLoading(true);
    // console.log(">> customerID", customerID);
    if (customerID !== "") {
      let obj = {
        documentNumber: customerID,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getCustomerDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item?.data[0]);
          setcustomerContactPerson(item?.data[0]?.contactDetails || []);
          setDocumentTypeData(item?.data[0]?.certifications || []);
          setProjectDetails(item?.data[0]?.projectDetails || newProject);
          setRouteDetails(item?.data[0]?.routeDetails || []);
          setCompanyProfile(item?.data[0]?.companyProfile || []);
          setprojectCompletedData(item?.data[0]?.projectCompleted || []);
          setProjectOnHandData(item?.data[0]?.porjectOnHand || []);
          setCustomerProfile(item?.data[0]?.customerProfile || []);
          setFinancialTurnover(item?.data[0]?.financialTurnover || []);

          if (item?.data[0]?.paymentTermsApproval) {
            setPaymentTermsApprovalData(
              item?.data[0]?.paymentTermsApproval || []
            );
          } else {
            let obj = [
              {
                slNo: 1,
                paymentType: item?.data[0]?.companyProfile?.paymentType,
                creditPeriodDays:
                  item?.data[0]?.companyProfile?.creditPeriodDays,
                creditLimitINR: item?.data[0]?.companyProfile?.creditLimitINR,
                editStatus: true,
              },
            ];
            console.log(">> setPaymentTermsApprovalData", obj);

            setPaymentTermsApprovalData(obj);
          }
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
      setLoading(false);
    } else {
      if (JSON.stringify(workflow) === "{}") {
        if (workflow?.page === "Inbox") {
          navigate(Constants.INBOX);
        } else {
          navigate(Constants.OUTBOX);
        }
      } else {
        navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
      }
    }
  };

  useEffect(() => {
    loadFirstLevelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editCustomer = () => {
    setEditMode(false);
  };
  const canelUpdate = () => {
    setEditMode(true);
  };

  // SAVE
  const handleUpdate = async () => {
    // const requiredFields = [];
    // const anyFieldNotEmpty = Object.values(validation).some(
    //   (value) => value !== null && value !== ""
    // );
    // if (anyFieldNotEmpty) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }
    // if (!validateRequired(formInputs.organisationName)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     organisationName: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.address)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     address: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.address2)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     address2: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.city)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     city: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.cityPostalCode)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     cityPostalCode: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // if (!validateRequired(formInputs.distance)) {
    //   requiredFields.push(`${message.GET_MAP_DISTANCE}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   // setValidation((prev) => ({
    //   //   ...prev,
    //   //   cityPostalCode: message.FILL_REQUIRED_FIELD,
    //   // }));
    //   return;
    // }
    // if (!validateRequired(formInputs.salesOfficerName)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     salesOfficerName: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // if (!validateRequired(formInputs.routeNumber)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     routeNumber: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // formInputs.userId = customerID;
    formInputs.contactDetails = getcustomerContactPerson;
    formInputs.certifications = getDocumentTypeData;
    formInputs.projectDetails = getProjectDetails;
    formInputs.routeDetails = getRouteDetails;

    formInputs.companyProfile = getCompanyProfile;
    formInputs.projectCompleted = getprojectCompletedData;
    formInputs.financialTurnover = getFinancialTurnover;
    formInputs.porjectOnHand = getProjectOnHandData;
    formInputs.customerProfile = editorContent;

    await ItemService.updateCustomerV1(formInputs).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "Updated successfully", {
          duration: 2000,
          position: "top-right",
        });
        navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
        // navigate(CustomerConstants.CUSTOMER_V1_COSTING_SHEET);
        // dispatch(saveCustomerRegData(item?.data));
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  // Approve
  const workflowApproveReject = async (remarks) => {

    const updatedPaymentTermsApprovalData = paymentTermsApprovalData.map(item => ({
      ...item,
      editStatus: true
    }));

    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {
      paymentTermsApproval: updatedPaymentTermsApprovalData,
    };
    let newObj = { ...bodyObj, ...obj };

    // Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        navigate(Constants.INBOX);
        dispatch(getLoginUserData());
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const showApproveModal = async (name) => {
    setModalShowApprove(true);
    setAction(name);
  };
  const hideModalApprove = () => {
    setModalShowApprove(false);
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const navigateToWorkflow = () => {
    if (workflow?.page === "Inbox") {
      navigate(Constants.INBOX);
    } else {
      navigate(Constants.OUTBOX);
    }
  };

  // console.log(">> workflow", workflow);

  //print
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `KYC_${Math.floor(Math.random() * 1000000)}`,
  });
  //Financial Turnover
  const [getFinancialTurnover, setFinancialTurnover] = useState([
    {
      id: 1,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
    {
      id: 2,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
    {
      id: 3,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
  ]);

  const handleChangeFinancialTurnover = (id, fieldName, value) => {
    setFinancialTurnover((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };
  // project completed
  const removeProjecetDocumentItemRow = (local_id) => {
    setprojectCompletedData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };
  const addProjecetDocumentItemRow = () => {
    setprojectCompletedData((prevRows) => {
      projectCompletedData[0].local_id = prevRows.length + 1;
      projectCompletedData[0].purchasingReqDocumentItemNr = prevRows.length + 1; // make it to 3 digit 001, 002
      return [...prevRows, ...projectCompletedData];
    });
  };

  const handleInputFieldChangeCompleted = (local_id, event) => {
    const { name, value } = event.target;

    setprojectCompletedData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === local_id ? { ...row, [name]: value } : row
      )
    );
  };
  /* Project on Hand  */
  const removeprojectDetailContactPersonItemRow = (local_id) => {
    setProjectOnHandData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };
  const addprojectDetailContactPersonItemRow = () => {
    setProjectOnHandData((prevRows) => {
      projectOnHandData[0].local_id = prevRows.length + 1;
      projectOnHandData[0].purchasingReqDocumentItemNr = prevRows.length + 1; // make it to 3 digit 001, 002
      return [...prevRows, ...projectOnHandData];
    });
  };

  const handleInputChangeOnHand = (local_id, event) => {
    const { name, value } = event.target;
    setProjectOnHandData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === local_id ? { ...row, [name]: value } : row
      )
    );
  };
  //remarks
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  // View attachment files
  const [getShowFileViewModal, setShowFileViewModal] = useState(false);
  const [getFileViewData, setFileViewData] = useState(null);

  const handleViewFile = (fileAttachmentId) => {
    setFileViewData(fileAttachmentId);
    setShowFileViewModal(true);
  };
  const handleViewFileHide = () => {
    setShowFileViewModal(false);
  };

  // eslint-disable-next-line no-unused-vars
  const navigateReferenceCustomerDetails = () => {
    navigate(CustomerConstants.CUSTOMER_V1_REFERENCE_CUSTOMER_DETAILS);
  };

  const addPaymentTermsApproval = () => {
    // Create a new document with some data (you can customize the content)
    setPaymentTermsApprovalData((prevData) => {
      // Check if any existing record has editStatus: true
      const hasEditableRecord = prevData.some(
        (item) => item.editStatus === false
      );

      if (hasEditableRecord) {
        toast.error("Only one record can add for Approval !!");
        return prevData; // Return the previous state without adding
      }

      const newId =
        prevData.length > 0 ? prevData[prevData.length - 1].slNo + 1 : 1;

      const newDocument = {
        slNo: newId,
        paymentType: prevData[0].paymentType,
        creditPeriodDays: "",
        creditLimitINR: "",
        editStatus: false,
      };

      return [...prevData, newDocument];
    });
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Customer Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {customerID}
              </span>
            </h2>
            <p className="subText">Customer Registration Information </p>
            <hr />

            <div className="pageFooter mb-4">
              <Row>
                {workflow?.workflow_record?.decision?.length > 0 && (
                  <Col xs={12} md={4} lg={4} className="text-left">
                    <button
                      className="btnBack"
                      onClick={() => navigateToWorkflow()}
                    >
                      <i className="bi bi-arrow-return-left"></i> Go Back
                    </button>
                  </Col>
                )}
                <Col xs={12} md={8} lg={8} className="text-right">
                  {workflow?.workflow_record?.decision?.length > 0 &&
                    workflow?.workflow_record?.decision?.map((item, index) => (
                      <button
                        className={
                          item === "Approve" || item === "Submit"
                            ? "btnCommon btn-approve"
                            : "btnCommon btn-danger"
                        }
                        onClick={() => showApproveModal(item)}
                        key={index}
                      >
                        <i
                          className={
                            item === "Approve" || item === "Submit"
                              ? "bi bi-check2-circle"
                              : "bi bi-x-circle"
                          }
                        ></i>{" "}
                        {item}
                      </button>
                    ))}
                </Col>
              </Row>
            </div>

            {/* {JSON.stringify(workflow) !== "{}" && ( */}
            {JSON.stringify(workflow) === "{}" && (
              <Row className="mb-4">
                {!editMode && (
                  <Col
                    xs={6}
                    md={{ span: 2, offset: 8 }}
                    lg={{ span: 2, offset: 8 }}
                    className="text-left "
                    onClick={handleUpdate}
                  >
                    <button className="btnInfo">Update</button>
                  </Col>
                )}
                {!editMode && (
                  <Col xs={6} md={2} lg={2} className="text-left">
                    <button
                      className="btnInfo btn-danger"
                      onClick={canelUpdate}
                      // disabled={disabled}
                    >
                      Cancel
                    </button>
                  </Col>
                )}
                {editMode && (
                  <Row>
                    <Col
                      xs={6}
                      md={{ span: 2, offset: 8 }}
                      lg={{ span: 2, offset: 8 }}
                      className="text-left"
                    >
                      {formInputs?.KYCIndicator === "KYC" && (
                        <>
                          <button className="btnInfo" onClick={handlePrint}>
                            <i className="bi bi-printer"></i> Print KYC
                          </button>
                          <div style={{ display: "none" }}>
                            <PrintKYCRegistration
                              ref={contentRef}
                              data={formInputs}
                            />
                          </div>
                        </>
                      )}
                    </Col>
                    <Col xs={6} md={2} lg={2} className="text-left">
                      <button className="btnInfo" onClick={editCustomer}>
                        Edit
                      </button>
                    </Col>
                  </Row>
                )}
              </Row>
            )}
            <Loader isLoading={loading} className={"text-center"} />
            {!loading && (
              <>
                {/* customer details */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BI</span>
                        </Col>
                        <Col xs={8} md={8} lg={8}>
                          <span className="headerTitle">Customer </span>
                          <span className="subHeaderTitle">
                            Customer Details
                          </span>
                        </Col>
                      </Row>
                    </h2>
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Customer Name"
                              extraLabel="As per the trade license"
                              name="organisationName"
                              placeholder=""
                              value={formInputs?.organisationName || ""}
                              onChange={onInputChange}
                              required={true}
                              error={validation.organisationName}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" Address Lines 1 / Street"
                              name="address"
                              placeholder=""
                              value={formInputs?.address || ""}
                              onChange={onInputChange}
                              error={validation.address}
                              required={true}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Address Line 2"
                              name="address2"
                              placeholder=""
                              value={formInputs?.address2 || ""}
                              onChange={onInputChange}
                              error={validation.address2}
                              required={true}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="City"
                              name="city"
                              placeholder=""
                              value={formInputs?.city || ""}
                              onChange={onInputChange}
                              error={validation.city}
                              required={true}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Country"
                              placeholder=""
                              data={getCountry}
                              id="country"
                              iconClassName="dropdownIcon"
                              name="country"
                              code="countryCode"
                              description="countryDesc"
                              setValue={formInputs?.country || ""}
                              getvalue={setDropdownData}
                              required={true}
                              // error={validation.country}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="State"
                              placeholder=""
                              data={getState}
                              id="state"
                              iconClassName="dropdownIcon"
                              name="state"
                              code="stateCode"
                              description="stateDesc"
                              setValue={formInputs?.state}
                              getvalue={setDropdownData}
                              required={true}
                              // error={validation.state}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Postal Code/PO Box"
                              name="cityPostalCode"
                              placeholder=""
                              value={formInputs?.cityPostalCode}
                              onChange={onInputChange}
                              error={validation.cityPostalCode}
                              required={true}
                              disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Sales Officer Name"
                              extraLabel=""
                              name="salesOfficerName"
                              code="salesOfficerCode"
                              description="salesOfficerDesc"
                              data={SalesOfficerData}
                              id="salesOfficerName"
                              iconClassName="dropdownIcon"
                              setValue={formInputs?.salesOfficerName}
                              error={validation.salesOfficerName}
                              getvalue={setDropdownData}
                              required={true}
                              // className="dropdownOption"
                              disabled={editMode}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="SAP Customer Nr"
                              name="sapDocumentNumber"
                              placeholder=""
                              value={formInputs?.sapDocumentNumber || ""}
                              onChange={onInputChange}
                              error={validation.sapDocumentNumber}
                              required={true}
                              disabled={editMode}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Customer Contact Person / Line Item */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CP</span>
                        </Col>
                        <Col xs={4} md={8} lg={8}>
                          <span className="headerTitle"> Contact Person</span>
                          <span className="subHeaderTitle">
                            Customer Contact Person
                          </span>
                        </Col>
                        {!editMode && (
                          <Col xs={4} md={2} lg={2}>
                            <div className="d-flex justify-content-end  p-2 me-3">
                              <button
                                className="btnTable"
                                onClick={addCustomerCantactPerson}
                              >
                                <i className="bi bi-plus-lg"></i> Add
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <div className="accordion-body">
                              <div className="d-flex-column justify-content-center">
                                <Row>
                                  <Col xs={12}>
                                    <div className="accordion-body">
                                      <div className="d-flex-column justify-content-center">
                                        <Row className="pt-4 d-none d-lg-flex ">
                                          <Col xs={12} md={2} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Job Title
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Contact Title
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Contact Person
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Contact Number
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Email-ID
                                            </p>
                                          </Col>
                                          {!editMode && (
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex">
                                                Action
                                              </p>
                                            </Col>
                                          )}
                                        </Row>
                                        {getcustomerContactPerson?.length > 0 &&
                                          getcustomerContactPerson.map(
                                            (doc, index) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxField
                                                    label=""
                                                    placeholder="Contact Position"
                                                    data={getcontactPosition}
                                                    id="contactPosition"
                                                    iconClassName="dropdownIcon"
                                                    name="contactPosition"
                                                    code="contactPositionCode"
                                                    description="contactPositionDesc"
                                                    setValue={
                                                      doc?.contactPosition
                                                    }
                                                    getvalue={setDropdownData}
                                                    index={doc.id}
                                                    onChangeValue={
                                                      handleChangeCustomerContacts
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxField
                                                    label=""
                                                    placeholder="Contact Title"
                                                    data={getTitle}
                                                    id="contactTitle"
                                                    iconClassName="dropdownIcon"
                                                    name="contactTitle"
                                                    code="contactTitleCode"
                                                    description="contactTitleDesc"
                                                    setValue={doc?.contactTitle}
                                                    getvalue={setDropdownData}
                                                    index={doc.id}
                                                    onChangeValue={
                                                      handleChangeCustomerContacts
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="contactName"
                                                    placeholder="Contact Person"
                                                    value={
                                                      doc.contactName || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeCustomerContacts(
                                                        doc.id,
                                                        "contactName",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="contactNumber"
                                                    placeholder="Contact Number"
                                                    value={
                                                      doc.contactNumber || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeCustomerContacts(
                                                        doc.id,
                                                        "contactNumber",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    // label="Please Enter the Remarks*"
                                                    name="contactEmail"
                                                    placeholder="Email"
                                                    value={
                                                      doc.contactEmail || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeCustomerContacts(
                                                        doc.id,
                                                        "contactEmail",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>

                                                {!editMode && (
                                                  <Col
                                                    xs={12}
                                                    md={4}
                                                    lg={1}
                                                    className="commTopButtonRightLeftPadding"
                                                  >
                                                    <i
                                                      onClick={() =>
                                                        deleteCustomerContactPerosn(
                                                          doc.id
                                                        )
                                                      }
                                                      className="bi bi-trash text-danger fs-4"
                                                    />
                                                  </Col>
                                                )}
                                              </Row>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* customer Doucment Type */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">DT</span>
                        </Col>
                        <Col xs={4} md={8} lg={8}>
                          <span className="headerTitle"> Doucment Type</span>
                          <span className="subHeaderTitle">Doucment Type</span>
                        </Col>
                        {!editMode && (
                          <Col xs={4} md={2} lg={2}>
                            <div className="d-flex justify-content-end  p-2 me-3">
                              <button
                                className="btnTable"
                                onClick={addDocumentType}
                              >
                                <i className="bi bi-plus-lg"></i> Add
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <div className="accordion-body">
                              <div className="d-flex-column justify-content-center">
                                <Row>
                                  <Col xs={12}>
                                    <div className="accordion-body">
                                      <div className="d-flex-column justify-content-center">
                                        <Row className="pt-4 d-none d-lg-flex ">
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Document Type
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Document Number
                                            </p>
                                          </Col>
                                          <Col xs={12} md={4} lg={2}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              Issue Date
                                            </p>
                                          </Col>

                                          <Col xs={12} md={4} lg={1}>
                                            <p className="fs-6 fw-medium d-flex ms-4">
                                              View Files
                                            </p>
                                          </Col>

                                          {!editMode && (
                                            <>
                                              <Col xs={12} md={4} lg={2}>
                                                <p className="fs-6 fw-medium d-flex ms-4">
                                                  Attachments
                                                </p>
                                              </Col>

                                              <Col xs={12} md={4} lg={2}>
                                                <p className="fs-6 fw-medium d-flex justify-content-center ms-4">
                                                  Action
                                                </p>
                                              </Col>
                                            </>
                                          )}
                                        </Row>
                                        {getDocumentTypeData?.length > 0 &&
                                          getDocumentTypeData.map(
                                            (doc, index) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxField
                                                    label=""
                                                    placeholder="Document type"
                                                    data={getdocumentType}
                                                    id="documentType"
                                                    iconClassName="dropdownIcon"
                                                    name="documentType"
                                                    code="documentTypeCode"
                                                    description="documentTypeDesc"
                                                    setValue={doc?.documentType}
                                                    getvalue={setDropdownData}
                                                    index={doc.id}
                                                    onChangeValue={
                                                      handleInputChange
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox"
                                                    placeholder="Document Number"
                                                    value={doc.documentNumber}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        doc.id,
                                                        "documentNumber",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox"
                                                    type="date"
                                                    value={doc.issueDate}
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        doc.id,
                                                        "issueDate",
                                                        e.target.value
                                                      )
                                                    }
                                                    disabled={editMode}
                                                  />
                                                </Col>

                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={1}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex justify-content-center">
                                                    {doc.iSOCertification && (
                                                      <img
                                                        src="assets/img/newIcons/attached-file.png"
                                                        className="mt-1 attachmentIcon"
                                                        alt="attachments"
                                                        onClick={() =>
                                                          handleViewFile(
                                                            doc.iSOCertification
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </Col>

                                                {!editMode && (
                                                  <>
                                                    <Col
                                                      xs={12}
                                                      md={4}
                                                      lg={3}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <FileUploadComponent
                                                        id={doc.id}
                                                        name="iSOCertification"
                                                        label=""
                                                        allowedTypes={[
                                                          "image/png",
                                                          "image/jpeg",
                                                          "application/pdf",
                                                        ]}
                                                        multiple={true} // Change to `false` for single file selection
                                                        required={true}
                                                        onSuccessUpload={
                                                          onSuccessUploadDocumentType
                                                        }
                                                        value={
                                                          formInputs.iSOCertification ||
                                                          ""
                                                        }
                                                        disabled={editMode}
                                                      />
                                                    </Col>

                                                    <Col
                                                      xs={12}
                                                      md={4}
                                                      lg={1}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <i
                                                        onClick={() =>
                                                          deleteDocumentType(
                                                            doc.id
                                                          )
                                                        }
                                                        className="bi bi-trash text-danger fs-4"
                                                      />
                                                    </Col>
                                                  </>
                                                )}
                                              </Row>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* KYC Updated details */}
                {formInputs?.KYCIndicator &&
                  formInputs?.KYCIndicator === "KYC" && (
                    <>
                      {/* Company Profile */}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible9
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility9}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">CP</span>
                              </Col>
                              <Col xs={8} md={8} lg={8}>
                                <span className="headerTitle">
                                  Company Profile{" "}
                                </span>
                                <span className="subHeaderTitle">
                                  Company Profile Details
                                </span>
                              </Col>
                            </Row>
                          </h2>
                          <div
                            className={`accordion-collapse collapse show ${
                              isCardVisible9 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Row className="pt-2 pb-4">
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Existing Customer"
                                    placeholder=""
                                    data={existingCustomer}
                                    id="existingCustomer"
                                    iconClassName="dropdownIcon"
                                    name="existingCustomer"
                                    code="existingCustomerCode"
                                    description="existingCustomerDesc"
                                    setValue={
                                      getCompanyProfile.existingCustomer
                                    }
                                    getvalue={setDropdownData}
                                    required={true}
                                    // error={validation.existingCustomer}
                                    disabled={editMode}
                                  />
                                </Col>
                                {(getExisitingCustomer === "Yes" ||
                                  getCompanyProfile.existingCustomer ===
                                    "Yes") && (
                                  <>
                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding"
                                    >
                                      <ComboboxField
                                        label="Customer Code"
                                        placeholder=""
                                        data={getCustomer}
                                        id="customerCode"
                                        iconClassName="dropdownIcon"
                                        name="customerCode"
                                        code="customerCodeCode"
                                        description="customerCodeDesc"
                                        setValue={
                                          getCompanyProfile.customerCode
                                        }
                                        getvalue={setDropdownData}
                                        required={true}
                                        // error={validation.customerCode}
                                        disabled={editMode}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding d-flex align-itmes-center"
                                    >
                                      <button
                                        className="btnTable w-50 mt-4"
                                        onClick={
                                          navigateReferenceCustomerDetails
                                        }
                                      >
                                        View
                                      </button>
                                    </Col>
                                  </>
                                )}
                                {/* <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Type of Company/Firm"
                              placeholder=""
                              data={dropDowntypeOfCompany}
                              id="typeOfCompany"
                              iconClassName="dropdownIcon"
                              name="typeOfCompany"
                              code="typeOfCompanyCode"
                              description="typeOfCompanyDesc"
                              setValue={getCompanyProfile.typeOfCompany}
                              getvalue={setDropdownData}
                              required={true}
                              error={validation.typeOfCompany}
                              disabled={false}
                            />
                          </Col> */}

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Type Of Customer Segment"
                                    name="typeOfCustomerSegment"
                                    placeholder=""
                                    value={
                                      getCompanyProfile.typeOfCustomerSegment ||
                                      ""
                                    }
                                    onChange={onInputChange}
                                    error={validation.typeOfCustomerSegment}
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Type of Firm"
                                    placeholder=""
                                    data={dropDownTypeOfFirm}
                                    id="typeOfFirm"
                                    iconClassName="dropdownIcon"
                                    name="typeOfFirm"
                                    code="typeOfFirmCode"
                                    description="typeOfFirmDesc"
                                    setValue={getCompanyProfile.typeOfFirm}
                                    getvalue={setDropdownData}
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Number Of Years in Business"
                                    name="numberOfYearsInBusiness"
                                    placeholder=""
                                    type="number"
                                    value={
                                      getCompanyProfile.numberOfYearsInBusiness ||
                                      ""
                                    }
                                    onChange={onInputChange}
                                    // error={validation.numberOfYearsInBusiness}
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Payment Type"
                                    placeholder=""
                                    data={dropDowntypeOfCreditType}
                                    id="paymentType"
                                    iconClassName="dropdownIcon"
                                    name="paymentType"
                                    code="paymentTypeCode"
                                    description="paymentTypeDesc"
                                    setValue={getCompanyProfile.paymentType}
                                    getvalue={setDropdownData}
                                    required={true}
                                    // error={validation.paymentType}
                                    disabled={editMode}
                                  />
                                </Col>

                                {/* <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Credit Period (Days)"
                              name="creditPeriodDays"
                              placeholder=""
                              type="number"
                              value={getCompanyProfile.creditPeriodDays || ""}
                              onChange={onInputChange}
                              // error={validation.creditPeriodDays}
                              required={true}
                              disabled={
                                getPaymentTypeStatus === "" ||
                                getPaymentTypeStatus !== "Credit"
                                  ? true
                                  : false
                              }
                            />
                          </Col> */}
                                {getCompanyProfile.paymentType === "Credit" && (
                                  <>
                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding"
                                    >
                                      <ComboboxField
                                        label="Credit Period (Days)"
                                        placeholder=""
                                        data={dropDownCreditPeriodDays}
                                        id="creditPeriodDays"
                                        iconClassName="dropdownIcon"
                                        name="creditPeriodDays"
                                        code="creditPeriodDaysCode"
                                        description="creditPeriodDaysDesc"
                                        setValue={
                                          getCompanyProfile.creditPeriodDays
                                        }
                                        getvalue={setDropdownData}
                                        required={true}
                                        error={validation.creditPeriodDays}
                                        disabled={editMode}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding"
                                    >
                                      <InputField
                                        className="inputBox"
                                        label="Credit Limit (Lakhs)"
                                        name="creditLimitINR"
                                        placeholder=""
                                        type="number"
                                        value={
                                          getCompanyProfile.creditLimitINR || ""
                                        }
                                        onChange={onInputChange}
                                        // error={validation.creditLimitINR}
                                        required={true}
                                        disabled={editMode}
                                      />
                                    </Col>

                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding"
                                    >
                                      <ComboboxField
                                        label="Security"
                                        placeholder=""
                                        data={securityType}
                                        id="security"
                                        iconClassName="dropdownIcon"
                                        name="security"
                                        code="securityCode"
                                        description="securityDesc"
                                        setValue={
                                          getCompanyProfile.security || ""
                                        }
                                        getvalue={setDropdownData}
                                        required={true}
                                        // error={validation.security}
                                        disabled={editMode}
                                      />
                                    </Col>
                                    <Col
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className="commTopButtonRightLeftPadding"
                                    >
                                      <InputField
                                        className="inputBox"
                                        label="Details Of Security"
                                        name="detailsOfSecurity"
                                        placeholder=""
                                        value={
                                          getCompanyProfile.detailsOfSecurity ||
                                          ""
                                        }
                                        onChange={onInputChange}
                                        // error={validation.detailsOfSecurity}
                                        required={true}
                                        disabled={editMode}
                                      />
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Payment Terms Approval */}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible8
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility8}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">PT</span>
                              </Col>
                              <Col xs={8} md={3} lg={3}>
                                <span className="headerTitle">
                                  Payment Terms Approval
                                </span>
                                <span className="subHeaderTitle">
                                  List of Payment Terms Approval
                                </span>
                              </Col>

                              {workflow?.page === "Inbox" && (
                                <Col
                                  xs={12}
                                  md={7}
                                  lg={7}
                                  className="headerButtonAlign"
                                >
                                  <Col xs={4} md={4} lg={4} className="">
                                    <button
                                      className="btnTable"
                                      onClick={addPaymentTermsApproval}
                                    >
                                      <i class="bi bi-receipt-cutoff"></i> Add
                                      Credit Limit
                                    </button>
                                  </Col>
                                </Col>
                              )}
                            </Row>
                          </h2>

                          <div
                            className={`accordion-collapse collapse show ${
                              isCardVisible8 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Row className="pt-2 pb-4">
                                <DataTable
                                  title=""
                                  columns={paymentTermsApprovalCol}
                                  data={paymentTermsApprovalData}
                                  // progressPending={loading}
                                  // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                                  // pagination
                                  // paginationServer
                                  // paginationTotalRows={totalRows}
                                  // onChangeRowsPerPage={handlePerRowsChange}
                                  // onChangePage={handlePageChange}
                                  // selectableRows
                                  // onSelectedRowsChange={handleChange}
                                />
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Financial Turnover */}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible5
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility5}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">DT</span>
                              </Col>
                              <Col xs={4} md={8} lg={8}>
                                <span className="headerTitle">
                                  {" "}
                                  Financial Turnover
                                </span>
                                <span className="subHeaderTitle">
                                  Please provide your company's turnover for the
                                  previous years, supported by documentary
                                  evidence
                                </span>
                              </Col>
                              {/* {!editMode && (
                                <Col xs={4} md={2} lg={2}>
                                  <div className="d-flex justify-content-end  p-2 me-3">
                                    <button
                                      className="btnTable"
                                      onClick={addFinancialTurnover}
                                    >
                                      <i className="bi bi-plus-lg"></i> Add
                                    </button>
                                  </div>
                                </Col>
                              )} */}
                            </Row>
                          </h2>
                          <div
                            // id="acc"
                            className={`accordion-collapse collapse show ${
                              isCardVisible5 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  <div className="accordion-body">
                                    <div className="d-flex-column justify-content-center">
                                      <Row>
                                        <Col xs={12}>
                                          <div className="accordion-body">
                                            <div className="d-flex-column justify-content-center">
                                              <Row className="pt-4 d-none d-lg-flex ">
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Financial Year
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Turnover Value
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Lakhs/Crore
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    File View
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={4} lg={4}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Attachments
                                                  </p>
                                                </Col>
                                                {/* {!editMode && (
                                                  <Col xs={12} md={3} lg={3}>
                                                    <p className="fs-6 fw-medium d-flex justify-content-start ms-4">
                                                      Delete
                                                    </p>
                                                  </Col>
                                                )} */}
                                              </Row>
                                              {getFinancialTurnover.map(
                                                (doc, index) => (
                                                  <Row key={doc.id}>
                                                    <Col xs={12}>
                                                      <hr className="mx-4 my-0 text-color-black" />
                                                    </Col>
                                                    <Col
                                                      xs={12}
                                                      md={2}
                                                      lg={2}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <ComboboxField
                                                        label=""
                                                        placeholder="Financial Year"
                                                        data={dropDownYear}
                                                        id="turnoverYear"
                                                        iconClassName="dropdownIcon"
                                                        name="turnoverYear"
                                                        code="turnoverYearCode"
                                                        description="turnoverYearDesc"
                                                        setValue={
                                                          doc?.turnoverYear
                                                        }
                                                        getvalue={
                                                          setDropdownData
                                                        }
                                                        index={doc.id}
                                                        onChangeValue={
                                                          handleChangeFinancialTurnover
                                                        }
                                                        disabled={editMode}
                                                      />
                                                    </Col>
                                                    <Col
                                                      xs={12}
                                                      md={2}
                                                      lg={2}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <InputField
                                                        className="inputBox mt-1"
                                                        name="valueTurnover"
                                                        placeholder="Turnover Value"
                                                        value={
                                                          doc.valueTurnover ||
                                                          ""
                                                        }
                                                        onChange={(e) =>
                                                          handleChangeFinancialTurnover(
                                                            doc.id,
                                                            "valueTurnover",
                                                            e.target.value
                                                          )
                                                        }
                                                        disabled={editMode}
                                                      />
                                                    </Col>
                                                    <Col
                                                      xs={12}
                                                      md={2}
                                                      lg={2}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <ComboboxField
                                                        label=""
                                                        placeholder="lakhs/Crore"
                                                        data={dropDownLackCrore}
                                                        id="lakhsCroreTurnover"
                                                        iconClassName="dropdownIcon"
                                                        name="lakhsCroreTurnover"
                                                        code="lakhsCroreTurnoverCode"
                                                        description="lakhsCroreTurnoverDesc"
                                                        setValue={
                                                          doc?.lakhsCroreTurnover
                                                        }
                                                        getvalue={
                                                          setDropdownData
                                                        }
                                                        index={doc.id}
                                                        onChangeValue={
                                                          handleChangeFinancialTurnover
                                                        }
                                                        disabled={editMode}
                                                      />
                                                    </Col>
                                                    {/* {!editMode && (
                                                      <Col
                                                        xs={12}
                                                        md={2}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding "
                                                      >
                                                        <i
                                                          onClick={() =>
                                                            deleteFinancialTurnover(
                                                              doc.id
                                                            )
                                                          }
                                                          className="bi bi-trash text-danger fs-4"
                                                        />
                                                      </Col>
                                                    )} */}
                                                    <Col
                                                      xs={12}
                                                      md={2}
                                                      lg={2}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <img
                                                        src="assets/img/newIcons/attached-file.png"
                                                        className="mt-1 attachmentIcon"
                                                        alt="attachments"
                                                        onClick={() =>
                                                          handleViewFile(
                                                            doc.financialTurnoverFileAttachment
                                                          )
                                                        }
                                                      />
                                                    </Col>
                                                    <Col
                                                      xs={12}
                                                      md={4}
                                                      lg={4}
                                                      className="commTopButtonRightLeftPadding"
                                                    >
                                                      <FileUploadComponent
                                                        id="financialTurnoverFileAttachment"
                                                        name="financialTurnoverFileAttachment"
                                                        label=""
                                                        allowedTypes={[
                                                          "image/png",
                                                          "image/jpeg",
                                                          "application/pdf",
                                                        ]}
                                                        multiple={true} // Change to false for single file selection
                                                        required={true}
                                                        onSuccessUpload={
                                                          onSuccessUploadDocumentType
                                                        }
                                                        value={
                                                          doc.financialTurnoverFileAttachment ||
                                                          ""
                                                        }
                                                      />
                                                    </Col>
                                                  </Row>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Project Completed*/}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible11
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility11}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">FI</span>
                              </Col>
                              <Col xs={12} md={9} lg={9}>
                                <span className="headerTitle">
                                  Project Completed
                                </span>
                                <span className="subHeaderTitle">
                                  Project Completed
                                </span>
                              </Col>
                              {!editMode && (
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="headerButtonAlign"
                                >
                                  <button
                                    className="btnTable"
                                    onClick={addProjecetDocumentItemRow}
                                  >
                                    <i className="bi bi-plus-lg"></i> Add
                                  </button>
                                </Col>
                              )}

                              {/* <Col xs={2} md={1} lg={1} className="">
                      <CommonFillter />
                    </Col> */}
                            </Row>
                          </h2>

                          <div
                            className={`accordion-collapse collapse show ${
                              isCardVisible11 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Col xs={12} md={12} lg={12}>
                                <DataTable
                                  title=""
                                  columns={projectCompletedCol}
                                  data={getprojectCompletedData}
                                  // progressPending={loading}
                                  // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                                  pagination
                                  // paginationServer
                                  // paginationTotalRows={totalRows}
                                  // onChangeRowsPerPage={handlePerRowsChange}
                                  // onChangePage={handlePageChange}
                                  // selectableRows
                                  // onSelectedRowsChange={handleChange}
                                />
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Project on Hand*/}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible12
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility12}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">FI</span>
                              </Col>
                              <Col xs={12} md={9} lg={9}>
                                <span className="headerTitle">
                                  Project on Hand
                                </span>
                                <span className="subHeaderTitle">
                                  Project on Hand
                                </span>
                              </Col>
                              {!editMode && (
                                <Col
                                  xs={12}
                                  md={2}
                                  lg={2}
                                  className="headerButtonAlign"
                                >
                                  <button
                                    className="btnTable"
                                    onClick={
                                      addprojectDetailContactPersonItemRow
                                    }
                                  >
                                    <i className="bi bi-plus-lg"></i> Add
                                  </button>
                                </Col>
                              )}

                              {/* <Col xs={2} md={1} lg={1} className="">
                          <CommonFillter />
                        </Col> */}
                            </Row>
                          </h2>

                          <div
                            className={`accordion-collapse collapse show ${
                              isCardVisible12 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Row>
                                <Col xs={12} md={12} lg={12}>
                                  <DataTable
                                    title=""
                                    columns={projectOnHandCol}
                                    data={getProjectOnHandData}
                                    // progressPending={loading}
                                    // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                                    pagination
                                    // paginationServer
                                    // paginationTotalRows={totalRows}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    // onChangePage={handlePageChange}
                                    // selectableRows
                                    // onSelectedRowsChange={handleChange}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* RichTextEditor */}
                      <div className="accordion mb-4" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <Row className="accordion-button">
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-left arrowBtn"
                              >
                                <i
                                  className={`bi ${
                                    isCardVisible6
                                      ? "bi-chevron-right"
                                      : "bi-chevron-down"
                                  }`}
                                  onClick={toggleCardVisibility6}
                                ></i>
                              </Col>
                              <Col
                                xs={1}
                                md={1}
                                lg={1}
                                className="text-center logoBtn"
                              >
                                <span className="iconLogo">RE</span>
                              </Col>
                              <Col xs={8} md={9} lg={9}>
                                <span className="headerTitle">Remarks</span>
                                <span className="subHeaderTitle">Remarks</span>
                              </Col>
                            </Row>
                          </h2>

                          <div
                            className={`accordion-collapse collapse show ${
                              isCardVisible6 ? "visible" : ""
                            }`}
                          >
                            <div className="accordion-body">
                              <Row className="p-4">
                                <Col xs={12} md={12} lg={12}>
                                  <RichTextEditor
                                    value={getCustomerProfile}
                                    onChange={handleEditorChange}
                                    readOnly={editMode}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                {/* Project Details*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PD</span>
                        </Col>
                        <Col xs={8} md={8} lg={8}>
                          <span className="headerTitle">Project Details </span>
                          <span className="subHeaderTitle">
                            Project Details
                          </span>
                        </Col>
                        {!editMode && (
                          <Col xs={4} md={2} lg={2}>
                            <div className="d-flex justify-content-end  p-2 me-3">
                              <button
                                className="btnTable"
                                onClick={() => addProjectDetails()}
                              >
                                <i className="bi bi-plus-lg"></i> Add Project
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </h2>
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        {getProjectDetails?.length > 0 &&
                          getProjectDetails?.map((item, index) => (
                            <div key={index}>
                              <Row className="pt-2 pb-4">
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <h5 className="bg-table-status bg-table-new-status mt-3 p-3">
                                    Project Details {index + 1}
                                  </h5>
                                  <span
                                    className="deleteIconAccordion"
                                    onClick={() => removeProjectDetails(index)}
                                  >
                                    {" "}
                                    <i className="bi bi-x-circle"></i>{" "}
                                  </span>
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Project Name"
                                    extraLabel="As per the trade license"
                                    name="projectName"
                                    value={item?.projectName || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "projectName"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Address Lines 1 / Street"
                                    extraLabel="As per the trade license"
                                    name="projectAddress"
                                    value={item?.projectAddress || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "projectAddress"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Address Line 2"
                                    extraLabel="As per the trade license"
                                    name="projectAddress2"
                                    value={item?.projectAddress2 || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "projectAddress2"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="City"
                                    extraLabel="As per the trade license"
                                    name="projectCity"
                                    value={item?.projectCity || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "projectCity"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Country"
                                    placeholder=""
                                    data={getCountry}
                                    id="projectCountry"
                                    iconClassName="dropdownIcon"
                                    name="projectCountry"
                                    code="projectCountryCode"
                                    description="projectCountryDesc"
                                    setValue={item?.projectCountry}
                                    getvalue={setDropdownData}
                                    index={item?.id}
                                    onChangeValue={(value) =>
                                      handleProjectInputChange(
                                        value,
                                        index,
                                        "projectCountry"
                                      )
                                    }
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="State"
                                    placeholder=""
                                    data={getState}
                                    id="projectState"
                                    iconClassName="dropdownIcon"
                                    name="projectState"
                                    code="projectStateCode"
                                    description="projectStateDesc"
                                    setValue={item?.projectState}
                                    getvalue={setDropdownData}
                                    index={item?.id}
                                    onChangeValue={(value) =>
                                      handleProjectInputChange(
                                        value,
                                        index,
                                        "projectState"
                                      )
                                    }
                                    disabled={editMode}
                                  />
                                </Col>
                                {/* 
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Route Number"
                                    name="routeNumber"
                                    value={item.routeNumber || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "routeNumber"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col> */}

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Postal Code/PO Box"
                                    extraLabel="As per the trade license"
                                    name="projectCityPostalCode"
                                    value={item?.projectCityPostalCode || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "projectCityPostalCode"
                                      )
                                    }
                                    required={true}
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Route Number"
                                    placeholder=""
                                    data={routNumberDropdownOption}
                                    id="routeNumber"
                                    iconClassName="dropdownIcon"
                                    name="routeNumber"
                                    code="routeNumberCode"
                                    description="routeNumberDesc"
                                    setValue={item?.routeNumber}
                                    getvalue={setDropdownData}
                                    index={item?.id}
                                    onChangeValue={(value) =>
                                      handleProjectInputChange(
                                        value,
                                        index,
                                        "routeNumber"
                                      )
                                    }
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label="Commission"
                                    placeholder=""
                                    data={dropdownOption}
                                    id="commission"
                                    iconClassName="dropdownIcon"
                                    name="commission"
                                    code="commissionCode"
                                    description="commissionDesc"
                                    setValue={item?.commission}
                                    getvalue={setDropdownData}
                                    index={item?.id}
                                    onChangeValue={(value) =>
                                      handleProjectInputChange(
                                        value,
                                        index,
                                        "commission"
                                      )
                                    }
                                    disabled={editMode}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="Commission Amount"
                                    name="commissionRate"
                                    placeholder=""
                                    // value={formInputs.commissionRate || ""}
                                    // onChange={onInputChange}
                                    // error={validation.commissionRate}
                                    required={true}
                                    value={item?.commissionRate || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "commissionRate"
                                      )
                                    }
                                    disabled={editMode}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    label="SAP Project Code "
                                    name="sapProjectCode"
                                    placeholder=""
                                    value={item?.sapProjectCode || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e.target.value,
                                        index,
                                        "sapProjectCode"
                                      )
                                    }
                                    disabled={editMode}
                                    required={true}
                                  />
                                </Col>
                              </Row>

                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <div>
                                    <div className="d-flex-column justify-content-center">
                                      <Row>
                                        <Col xs={12} md={5} lg={5}>
                                          <h5 className="bg-table-status bg-table-warning-status mt-1">
                                            Project Contacts
                                          </h5>
                                        </Col>

                                        <Col xs={12}>
                                          <div className="accordion-body">
                                            <div className="d-flex-column justify-content-center">
                                              <Row className="pt-4 d-none d-lg-flex ">
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Job Title
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={4} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Contact Title
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={4} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Contact Person
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={4} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Contact Number
                                                  </p>
                                                </Col>
                                                <Col xs={12} md={4} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Email-ID
                                                  </p>
                                                </Col>
                                                {!editMode && (
                                                  <Col xs={12} md={4} lg={2}>
                                                    <p className="fs-6 fw-medium d-flex">
                                                      Action
                                                    </p>
                                                  </Col>
                                                )}
                                              </Row>
                                              {item?.getProjectContactPerson &&
                                                item?.getProjectContactPerson
                                                  .length > 0 &&
                                                item?.getProjectContactPerson.map(
                                                  (doc, itemIndex) => (
                                                    <Row key={doc?.id}>
                                                      <Col xs={12}>
                                                        <hr className="mx-4 my-0 text-color-black" />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <ComboboxFieldSubArray
                                                          label=""
                                                          placeholder="Select a position"
                                                          data={
                                                            getcontactPosition
                                                          }
                                                          id="projectContactPosition"
                                                          name="projectContactPosition"
                                                          code="projectContactPositionCode"
                                                          description="projectContactPositionDesc"
                                                          setValue={
                                                            doc?.projectContactPosition
                                                          }
                                                          index={index} // Pass projectIndex instead of doc.id
                                                          onChangeValue={(
                                                            projectIndex,
                                                            contactId,
                                                            fieldName,
                                                            value
                                                          ) =>
                                                            handleChangeProjectContactsNewDropdown(
                                                              projectIndex,
                                                              doc?.id,
                                                              fieldName,
                                                              value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <ComboboxFieldSubArray
                                                          label=""
                                                          placeholder="Document type"
                                                          data={getTitle}
                                                          id="projectContactTitle"
                                                          iconClassName="dropdownIcon"
                                                          name="projectContactTitle"
                                                          code="projectContactTitleCode"
                                                          description="projectContactTitleDesc"
                                                          setValue={
                                                            doc?.projectContactTitle
                                                          }
                                                          index={index} // Pass projectIndex instead of doc.id
                                                          onChangeValue={(
                                                            projectIndex,
                                                            contactId,
                                                            fieldName,
                                                            value
                                                          ) =>
                                                            handleChangeProjectContactsNewDropdown(
                                                              projectIndex,
                                                              doc?.id,
                                                              fieldName,
                                                              value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <InputField
                                                          className="inputBox mt-1"
                                                          name="projectContactName"
                                                          placeholder="Contact Person"
                                                          value={
                                                            doc?.projectContactName ||
                                                            ""
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeProjectContactsNew(
                                                              index,
                                                              doc?.id,
                                                              "projectContactName",
                                                              e.target.value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <InputField
                                                          className="inputBox mt-1"
                                                          name="projectContactNumber"
                                                          placeholder="Contact Number"
                                                          value={
                                                            doc?.projectContactNumber ||
                                                            ""
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeProjectContactsNew(
                                                              index,
                                                              doc?.id,
                                                              "projectContactNumber",
                                                              e.target.value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <InputField
                                                          className="inputBox mt-1"
                                                          name="projectContactEmail"
                                                          placeholder="Email"
                                                          value={
                                                            doc?.projectContactEmail ||
                                                            ""
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeProjectContactsNew(
                                                              index,
                                                              doc?.id,
                                                              "projectContactEmail",
                                                              e.target.value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>
                                                      {!editMode && (
                                                        <Col
                                                          xs={12}
                                                          md={4}
                                                          lg={2}
                                                          className="commTopButtonRightLeftPadding"
                                                        >
                                                          <div className="d-flex">
                                                            {item
                                                              ?.getProjectContactPerson
                                                              .length !== 1 && (
                                                              <i
                                                                onClick={() =>
                                                                  deleteProjectContactPerosnNew(
                                                                    index,
                                                                    doc?.id
                                                                  )
                                                                }
                                                                className="bi bi-trash text-danger fs-4"
                                                              />
                                                            )}

                                                            {itemIndex ===
                                                              item
                                                                ?.getProjectContactPerson
                                                                .length -
                                                                1 && (
                                                              <button
                                                                className="btnTable width-40 ml-6 mt-1"
                                                                onClick={() =>
                                                                  addProjectCantactPersonNew(
                                                                    index
                                                                  )
                                                                } // Fixed: Pass as a callback
                                                              >
                                                                <i className="bi bi-plus-lg"></i>{" "}
                                                                Add
                                                              </button>
                                                            )}
                                                          </div>
                                                        </Col>
                                                      )}
                                                    </Row>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <div>
                                    <div className="d-flex-column justify-content-center">
                                      <Row>
                                        <Col xs={12} md={5} lg={5}>
                                          <h5 className="bg-table-status bg-table-warning-status mt-4">
                                            Project Stages
                                          </h5>
                                        </Col>
                                        <Col xs={12}>
                                          <div className="accordion-body">
                                            <div className="d-flex-column justify-content-center">
                                              <Row className="pt-4 d-none d-lg-flex ">
                                                <Col xs={12} md={2} lg={2}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    Stages
                                                  </p>
                                                </Col>

                                                <Col xs={12} md={4} lg={1}>
                                                  <p className="fs-6 fw-medium d-flex ms-4">
                                                    View Files
                                                  </p>
                                                </Col>

                                                {!editMode && (
                                                  <>
                                                    <Col xs={12} md={4} lg={3}>
                                                      <p className="fs-6 fw-medium d-flex ms-4">
                                                        Attachments
                                                      </p>
                                                    </Col>

                                                    <Col xs={12} md={4} lg={3}>
                                                      <p className="fs-6 fw-medium d-flex">
                                                        Action
                                                      </p>
                                                    </Col>
                                                  </>
                                                )}
                                              </Row>
                                              {item?.getProjectCertifications &&
                                                item?.getProjectCertifications
                                                  .length > 0 &&
                                                item?.getProjectCertifications.map(
                                                  (doc, itemIndex) => (
                                                    <Row key={doc.id}>
                                                      <Col xs={12}>
                                                        <hr className="mx-4 my-0 text-color-black" />
                                                      </Col>
                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={2}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <ComboboxFieldSubArray
                                                          label=""
                                                          placeholder="Stages"
                                                          data={
                                                            productDocumentAttachment
                                                          }
                                                          id="documentType"
                                                          iconClassName="dropdownIcon"
                                                          name="documentType"
                                                          code="documentTypeCode"
                                                          description="documentTypeDesc"
                                                          setValue={
                                                            doc.documentType
                                                          }
                                                          index={index} // Pass projectIndex instead of doc.id
                                                          onChangeValue={(
                                                            projectIndex,
                                                            fileId,
                                                            fieldName,
                                                            value
                                                          ) =>
                                                            handleChangeProjectContactsNewDropdown(
                                                              projectIndex,
                                                              doc.id,
                                                              fieldName,
                                                              value
                                                            )
                                                          }
                                                          disabled={editMode}
                                                        />
                                                      </Col>

                                                      <Col
                                                        xs={12}
                                                        md={4}
                                                        lg={1}
                                                        className="commTopButtonRightLeftPadding"
                                                      >
                                                        <div className="d-flex justify-content-center">
                                                          {doc.stageDocument && (
                                                            <img
                                                              src="assets/img/newIcons/attached-file.png"
                                                              className="mt-1 attachmentIcon"
                                                              alt="attachments"
                                                              onClick={() =>
                                                                handleViewFile(
                                                                  doc.stageDocument
                                                                )
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      </Col>

                                                      {!editMode && (
                                                        <>
                                                          <Col
                                                            xs={12}
                                                            md={4}
                                                            lg={3}
                                                            className="commTopButtonRightLeftPadding"
                                                          >
                                                            <FileUploadComponent
                                                              id={doc.id}
                                                              name="stageDocument"
                                                              label=""
                                                              allowedTypes={[
                                                                "image/png",
                                                                "image/jpeg",
                                                                "application/pdf",
                                                              ]}
                                                              multiple={true} // Change to `false` for single file selection
                                                              required={true}
                                                              onSuccessUpload={(
                                                                data
                                                              ) =>
                                                                onSuccessUploadProjectDocumentType(
                                                                  index,
                                                                  doc.id,
                                                                  data.fieldName,
                                                                  data.attachmentId
                                                                )
                                                              }
                                                              value={
                                                                formInputs.stageDocument ||
                                                                ""
                                                              }
                                                              disabled={
                                                                editMode
                                                              }
                                                            />
                                                          </Col>

                                                          <Col
                                                            xs={12}
                                                            md={4}
                                                            lg={2}
                                                            className="commTopButtonRightLeftPadding"
                                                          >
                                                            <div className="d-flex">
                                                              {item
                                                                ?.getProjectCertifications
                                                                .length !==
                                                                1 && (
                                                                <i
                                                                  onClick={() =>
                                                                    deleteProjectCertifications(
                                                                      index,
                                                                      doc.id
                                                                    )
                                                                  }
                                                                  className="bi bi-trash text-danger fs-4"
                                                                />
                                                              )}

                                                              {itemIndex ===
                                                                item
                                                                  ?.getProjectCertifications
                                                                  .length -
                                                                  1 && (
                                                                <button
                                                                  className="btnTable width-40 ml-6 mt-1"
                                                                  onClick={() =>
                                                                    addProjectCertifications(
                                                                      index
                                                                    )
                                                                  } // Fixed: Pass as a callback
                                                                >
                                                                  <i className="bi bi-plus-lg"></i>{" "}
                                                                  Add
                                                                </button>
                                                              )}
                                                            </div>
                                                          </Col>
                                                        </>
                                                      )}
                                                    </Row>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <div>
                                    <div className="d-flex-column justify-content-center">
                                      <Row>
                                        <Col xs={12} md={5} lg={5}>
                                          <h5 className="bg-table-status bg-table-warning-status mt-4">
                                            Locations
                                          </h5>
                                        </Col>
                                        <Col xs={12}>
                                          <div className="accordion-body">
                                            <div className="d-flex-column justify-content-center">
                                              <GoogleMapComponent
                                                projectIndex={index}
                                                getmapdata={onHandleMapData}
                                                passmapdata={
                                                  item?.getLocations || []
                                                }
                                                editstatus={editMode}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <hr />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Route details */}
                {/* <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible8
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility8}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">AD</span>
                        </Col>
                        <Col xs={8} md={8} lg={8}>
                          <span className="headerTitle">Route Detail</span>
                          <span className="subHeaderTitle">Route Details</span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible8 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <DataTable
                            title=""
                            columns={routeTableCol}
                            data={getRouteDetails}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            // selectableRows
                            // onSelectedRowsChange={handleChange}
                          />
                        </Row>
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </Col>
      </Row>

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
      <ApproveRemarksModal
        show={modalShowApprove}
        onHide={() => hideModalApprove()}
        onvaluechange={handleValueChange}
      />

      <FileViewerModal
        show={getShowFileViewModal}
        onHide={handleViewFileHide}
        fileid={getFileViewData}
      />
    </main>
  );
};

export default CustomerRegistrationV1Details;
