import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../../Layout/Layout";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Compomnent
import { InputField } from "../../../../Components/formElements/InputField";
import DataTable from "react-data-table-component";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import Constants from "../../../../common/CustomerConstants";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { getLoginUserData } from "../../../../redux/feature/userSlice";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import ShowHorizontalStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowHorizontalStatus";
import Loader from "../../../../Components/Loader";
import { useReactToPrint } from "react-to-print";
import PrintCostingSheet from "./PrintCostingSheet";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";

const CostingSheetDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Costing Sheet");

  let costingSheetID = useSelector(
    (state) => state?.customerV1?.costingSheet?.costingSheetID
  ); // User Details from Store
  let workflow = useSelector((state) => state?.customer?.customerWorkflow); // workflow from Store

  const [formInputs, setFormInputs] = useState({
    termsConditions: [],
  });
  const [getProjectDetails, setProjectDetails] = useState([]);

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [getAction, setAction] = useState("");

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  let itemDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "80px",
    },
    {
      name: "Product",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
            <p className="m-0 p-0 ">{row.materialCode} </p>
          </div>
        </>
        // <InputField
        //   className="inputBox mt-1"
        //   // label="Please Enter the Remarks*"
        //   name="materialCode"
        //   placeholder=""
        //   value={row.materialCode || ""}
        //   disabled={true}
        //   onChange={(event) => handleInputChange(row.local_id, event)}
        // />
      ),
      sortable: true,
      width: "220px",
    },
    // {
    //   name: "Product",
    //   selector: (row) => row.materialDescription || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       // label="Please Enter the Remarks*"
    //       name="materialDescription"
    //       placeholder=""
    //       value={row.materialDescription || ""}
    //       disabled={true}
    //       onChange={(event) => handleInputChange(row.local_id, event)}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Ex-plant Rate",
      selector: (row) => row.explantRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          // label="Please Enter the Remarks*"
          name="explantRate"
          placeholder=""
          value={row.explantRate || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Freight Rate",
      selector: (row) => row.freightRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          name="freightRate"
          placeholder=""
          value={row.freightRate || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Toll Rate",
      selector: (row) => row.tollRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="tollRate"
          placeholder=""
          value={row.tollRate || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "120px",
    },
    {
      name: "Route Nr",
      selector: (row) => row.routeNumber || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="routeNumber"
          placeholder=""
          value={row.routeNumber || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "140px",
    },
    {
      name: "Commission Rate",
      selector: (row) => row.commissionRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="commissionRate"
          placeholder=""
          value={row.commissionRate || ""}
          disabled={workflow?.workflow_record ? false : true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "180px",
    },
    {
      name: "Quote Price (INR)",
      selector: (row) => row.quotePrice || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          // label="Please Enter the Remarks*"
          name="quotePrice"
          placeholder=""
          value={row.quotePrice || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "180px",
    },
    // {
    //   name: "Currency",
    //   selector: (row) => row.currency || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="currency"
    //       placeholder=""
    //       value={row.currency || ""}
    //       disabled={true}
    //       onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Delete",
    //   selector: (row) => row.local_id || "--",
    //   sortable: true,
    //   cell: (row) => (
    //     <button
    //       className=""
    //       onClick={() => removecustomerContactPersonItemRow(row.local_id)}
    //     >
    //       <i className="bi bi-trash text-danger fs-4" />
    //     </button>
    //   ),
    //   width: "120px",
    // },
  ];

  // const [getitemDetailsData, setitemDetailsData] = useState(itemDetailsData);

  const handleInputChange = (local_id, event) => {
    const { name, value } = event.target;
    setData((prevRows) =>
      prevRows.map((row) => {
        if (row.local_id === local_id) {
          const updatedRow = { ...row, [name]: value };

          // Recalculate `quotePrice` if `commissionRate` is changed
          if (name === "commissionRate") {
            const explantRate = parseFloat(updatedRow.explantRate || 0);
            const freightRate = parseFloat(updatedRow.freightRate || 0);
            const tollRate = parseFloat(updatedRow.tollRate || 0);
            const commissionRate = parseFloat(value || 0); // Use updated commissionRate value

            // Update `quotePrice`
            updatedRow.quotePrice = (
              +explantRate +
              +freightRate +
              +tollRate +
              +commissionRate
            ).toFixed(2); // Ensures 2 decimal places
          }

          return updatedRow;
        }
        return row;
      })
    );
  };

  const loadInitialPageData = async () => {
    setLoading(true);
    if (costingSheetID) {
      let obj = {
        documentNumber: costingSheetID,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getCostingSheetDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item?.data[0]);
          setProjectDetails(item?.data[0]?.projectDetails);
          setData(item?.data[0]?.items);
          // dispatch(addPoSelectedDetails(item?.data));
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      if (JSON.stringify(workflow?.workflow_record) !== "{}") {
        if (workflow?.page === "Inbox") {
          navigate(Constants.INBOX);
        } else {
          navigate(Constants.OUTBOX);
        }
      } else {
        navigate(Constants.CUSTOMER_V1_COSTING_SHEET_LIST);
      }
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costingSheetID]);

  // Approve
  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {
      items: data,
    };
    let newObj = { ...bodyObj, ...obj };

    // console.log(">> obj", obj);

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        navigate(Constants.INBOX);
        dispatch(getLoginUserData());
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const showApproveModal = async (name) => {
    setModalShowApprove(true);
    setAction(name);
  };
  const hideModalApprove = () => {
    setModalShowApprove(false);
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const navigateToWorkflow = () => {
    if (workflow?.page === "Inbox") {
      navigate(Constants.INBOX);
    } else {
      navigate(Constants.OUTBOX);
    }
  };

  // console.log(">> workflow", workflow);

  //print
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `KYC_${Math.floor(Math.random() * 1000000)}`,
  });

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Costing Sheet Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {costingSheetID}
              </span>
              <span>
                {formInputs?.csStatusHeader === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.csStatusHeader === "Non Invited" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.csStatusHeader === "Approved" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.csStatusHeader === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.csStatusHeader === "Pending with L1" ? (
                  <span className="bg-table-status bg-table-warning-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : formInputs?.csStatusHeader === "Pending with L2" ? (
                  <span className="bg-table-status bg-table-warning-status headerStatusDetailedPage">
                    {formInputs?.csStatusHeader}
                  </span>
                ) : (
                  formInputs?.csStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">Costing Sheet Information </p>
            <hr />
            {!workflow?.workflow_record && (
              <Col
                xs={6}
                md={{ span: 2, offset: 10 }}
                lg={{ span: 2, offset: 10 }}
                className="text-left"
              >
                <button
                  className="btnInfo"
                  onClick={handlePrint}
                  // disabled={}
                >
                  <i class="bi bi-printer"></i> Print Costing Sheet
                </button>
                <div style={{ display: "none" }}>
                  <PrintCostingSheet ref={contentRef} data={formInputs} />
                </div>
              </Col>
            )}

            {!workflow?.workflow_record && (
              <ShowHorizontalStatus rowdatamodal={workflow} />
            )}

            <div className="pageFooter mb-4">
              <Row>
                {workflow?.workflow_record?.decision?.length > 0 && (
                  <Col xs={12} md={4} lg={4} className="text-left">
                    <button
                      className="btnBack"
                      onClick={() => navigateToWorkflow()}
                    >
                      <i className="bi bi-arrow-return-left"></i> Go Back
                    </button>
                  </Col>
                )}
                <Col xs={12} md={8} lg={8} className="text-right">
                  {workflow?.workflow_record?.decision?.length > 0 &&
                    workflow?.workflow_record?.decision?.map((item, index) => (
                      <button
                        className={
                          item === "Approve" || item === "Submit"
                            ? "btnCommon btn-approve"
                            : "btnCommon btn-danger"
                        }
                        onClick={() => showApproveModal(item)}
                        key={index}
                      >
                        <i
                          className={
                            item === "Approve" || item === "Submit"
                              ? "bi bi-check2-circle"
                              : "bi bi-x-circle"
                          }
                        ></i>{" "}
                        {item}
                      </button>
                    ))}
                </Col>
              </Row>
            </div>

            <Loader isLoading={loading} className={"text-center"} />

            {!loading && (
              <>
                {/* 01 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BI</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">
                            Costing Sheet Details{" "}
                          </span>
                          <span className="subHeaderTitle">
                            Costing Sheet Details
                          </span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Sales Officer Name"
                              name="salesOfficerName"
                              value={formInputs.salesOfficerName || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Customer Name"
                              name="organisationName"
                              value={formInputs.organisationName || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Project Name"
                              name="projectName"
                              value={getProjectDetails?.projectName}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Plant Location"
                              name="plantAddress"
                              value={formInputs.plantAddress || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Project/Site Location"
                              name="projectLocation"
                              value={
                                getProjectDetails?.getLocations &&
                                getProjectDetails?.getLocations[0]
                                  ?.projectLocation
                              }
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Project Address"
                              name="projectAddress"
                              value={
                                getProjectDetails?.projectAddress +
                                getProjectDetails?.projectAddress2
                              }
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Kilo Meter"
                              name="distance"
                              value={
                                getProjectDetails?.getLocations &&
                                getProjectDetails?.getLocations[0]?.distance
                              }
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Duration"
                              name="duration"
                              value={
                                getProjectDetails?.getLocations &&
                                getProjectDetails?.getLocations[0]?.duration
                              }
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Transport Charges"
                              name="transportCharges"
                              value={formInputs.transportCharges || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 02 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CP</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Item Details</span>
                          <span className="subHeaderTitle">Item Details</span>
                        </Col>
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={itemDetailsCol}
                              data={data}
                              // progressPending={loading}
                              // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              // selectableRows
                              // onSelectedRowsChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 03 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">RE</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">
                            Observations/Feedback
                          </span>
                          <span className="subHeaderTitle">
                            Observations/Feedback Section
                          </span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="p-4">
                          <Col xs={12} md={12} lg={12}>
                            <RichTextEditor
                              value={formInputs?.observationsFeedback}
                              // onChange={handleEditorChange}
                              readOnly={true}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Documents 04 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">TD</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">
                            Terms & Conditions
                          </span>
                          <span className="subHeaderTitle">
                            Terms & Conditions
                          </span>
                        </Col>
                        {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={12}
                            lg={12}
                            className="commTopButtonRightLeftPadding"
                          >
                            <Row>
                              <Col
                                xs={12}
                                md={12}
                                lg={8}
                                className="commTopButtonRightLeftPadding"
                              >
                                {formInputs?.termsConditions.map((item, i) => (
                                  <div className="form-check" key={item.id}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`gridCheck${item.id}`}
                                      checked={item.status}
                                      // onChange={() =>
                                      //   handleTermsConditionCheckBox(item.id)
                                      // }
                                      disabled={item?.disabled}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`gridCheck${item.id}`}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                ))}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      <ApproveRemarksModal
        show={modalShowApprove}
        onHide={() => hideModalApprove()}
        onvaluechange={handleValueChange}
      />
    </main>
  );
};

export default CostingSheetDetails;
