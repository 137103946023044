// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-box {
  height: 180px; /* Set fixed height */
  background-color: #ffffff; /* White background */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 8px; /* Rounded corners */
  padding: 8px;
  overflow: hidden;
}

.image-box img {
  width: 100%; /* Make sure the image fits the container */
  height: 100%; /* Maintain the height */
  object-fit: cover; /* Ensure image covers the container without distortion */
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/customer_app/HomeIntro/homeIntro.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA,EAAA,qBAAA;EACA,yBAAA,EAAA,qBAAA;EACA,sBAAA,EAAA,sBAAA;EACA,kBAAA,EAAA,oBAAA;EACA,YAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,WAAA,EAAA,2CAAA;EACA,YAAA,EAAA,wBAAA;EACA,iBAAA,EAAA,yDAAA;EACA,kBAAA;AAAJ","sourcesContent":[".image-box {\n    // width: 180px; /* Set fixed width */\n    height: 180px; /* Set fixed height */\n    background-color: #ffffff; /* White background */\n    border: 1px solid #ddd; /* Light grey border */\n    border-radius: 8px; /* Rounded corners */\n    padding: 8px;\n    overflow: hidden;\n  }\n  \n  .image-box img {\n    width: 100%; /* Make sure the image fits the container */\n    height: 100%; /* Maintain the height */\n    object-fit: cover; /* Ensure image covers the container without distortion */\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
