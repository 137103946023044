import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InputField } from "../../../../Components/formElements/InputField";
import Spinner from "react-bootstrap/Spinner";

//API
import ItemService from "../../../../services/supplier_appCommonService";

const InvoiceConfirmationModal = React.forwardRef((props, ref) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [parentInfo, setParentInfo] = useState();
  const [uploadStatus, setUploadStatus] = useState(false);
  const [viewInvoiceDataStatus, setViewInvoiceDataStatus] = useState(false);

  const [invoiceData, setInvoiceData] = useState({
    dispInvoiceNumber: "",
    dispInvoiceDate: "",
    dispTotalAmount: "",
    dispDescription: "",
    dispTotalQty: "",
    dispBasicPricePerUnit: "",
    dispBasicPrice: "",
    dispCGST: "",
    dispSGST: "",
    dispTransporterCode: "",
    dispTransporterName: "",
    dispVehieleRegNo: "",
    dispCustomerCode: "",
  });

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };

    setParentInfo(props?.invoiceinfo);

    if (props?.viewInvoiceData) {
      setInvoiceData(props?.viewInvoiceData);
      setViewInvoiceDataStatus(true);
    } else {
      setInvoiceData({
        dispInvoiceNumber: "",
        dispInvoiceDate: "",
        dispTotalAmount: "",
        dispDescription: "",
        dispTotalQty: "",
        dispBasicPricePerUnit: "",
        dispBasicPrice: "",
        dispCGST: "",
        dispSGST: "",
        dispTransporterCode: "",
        dispTransporterName: "",
        dispVehieleRegNo: "",
        dispCustomerCode: "",
      });
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const handleFileChange = (event) => {
    // const selectedFile = event.target.files[0];
    setFile(event.target.files[0]);
  };

  const selectFile = () => {
    fileInputRef.current.click();
  };
  const cancelInvoice = () => {
    setFile(null);
  };

  const uploadInvoice = async () => {
    setUploadStatus(true);
    if (file) {
      // Create FormData and append the file
      const formData = new FormData();
      formData.append("file", file);

      await ItemService.uploadOCRFile(formData).then((item) => {
        console.log(">> uploadOCRFile data ", item);

        // setInvoiceData(item?.data);
        setInvoiceData({
          dispInvoiceNumber: item?.data?.invoiceNumber,
          dispInvoiceDate: item?.data?.invoiceDate,
          dispTotalAmount: item?.data?.totalAmount,
          dispDescription: item?.data?.description,
          dispTotalQty: item?.data?.totalQty,
          dispBasicPricePerUnit: item?.data?.basicPricePerUnit,
          dispBasicPrice: item?.data?.basicPrice,
          dispCGST: item?.data?.cgst,
          dispSGST: item?.data?.sgst,
          dispTransporterCode: item?.data?.transporterCode,
          dispTransporterName: item?.data?.transporterName,
          dispVehieleRegNo: item?.data?.vehieleRegNo,
          dispCustomerCode: item?.data?.customerCode,
        });

        setFile(null);

        // if (item?.status === 200) {
        //   setInvoiceData(item?.data);
        // } else {
        //   setInvoiceData([]);
        //   console.log(">> Error: Loading getSupplierDatail");
        // }
      });
      setUploadStatus(false);
    } else {
      // alert('Please select a file first.');
    }
  };

  //handle cancel modal
  const handleCancel = () => {
    props.onHide();
  };

  const handleSave = () => {
    let obj = {
      ...invoiceData,
      ...parentInfo,
    };
    props.onsuccessdata(obj);
    props.onHide();
  };

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Invoice Confirmation - {parentInfo?.dispatchDocNr} - INV:{" "}
          {parentInfo?.dispatchInvoiceNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {/* Upload invoice details */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">ID</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle"> Upload Invoice details</span>
                  <span className="subHeaderTitle">Invoice details</span>
                </Col>
                {!viewInvoiceDataStatus && (
                  <Col xs={12} md={6} lg={6} className="headerButtonAlign">
                    <Col
                      xs={6}
                      md={6}
                      lg={6}
                      className="d-flex justify-content-end mr-2 mt-1"
                    >
                      {file && (
                        <p>
                          File: <b>{file.name}</b>{" "}
                        </p>
                      )}
                    </Col>
                    <Col xs={6} md={3} lg={3} className="">
                      {!file && (
                        <button onClick={selectFile} className="btnTable">
                          <i className="bi bi-upload mr-2"></i>
                          Select Invoice
                        </button>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />

                      {file && (
                        <>
                          {!uploadStatus && (
                            <button
                              className="btnTable"
                              onClick={uploadInvoice}
                            >
                              <i className="bi bi-upload mr-2"></i>
                              Upload
                            </button>
                          )}

                          {uploadStatus && (
                            <button className="btnTable" disabled={true}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </button>
                          )}
                        </>
                      )}
                    </Col>
                    {file && (
                      <Col xs={6} md={1} lg={1} className="">
                        <button className="btnDelete" onClick={cancelInvoice}>
                          <i className="bi bi-x-circle"></i>
                        </button>
                      </Col>
                    )}
                  </Col>
                )}
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice Number"
                            name="dispInvoiceNumber"
                            id="dispInvoiceNumber"
                            value={invoiceData?.dispInvoiceNumber}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice Date"
                            name="dispInvoiceDate"
                            id="dispInvoiceDate"
                            value={invoiceData?.dispInvoiceDate}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Description"
                            name="dispDescription"
                            id="dispDescription"
                            value={invoiceData?.dispDescription}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total Qty"
                            name="dispTotalQty"
                            id="dispTotalQty"
                            value={invoiceData?.dispTotalQty}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Basic Price Per Unit"
                            name="dispBasicPricePerUnit"
                            id="dispBasicPricePerUnit"
                            value={invoiceData?.dispBasicPricePerUnit}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Basic Price"
                            name="dispBasicPrice"
                            id="dispBasicPrice"
                            value={invoiceData?.dispBasicPrice}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="CGST"
                            name="dispCGST"
                            id="dispCGST"
                            value={invoiceData?.dispCGST}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="SGST"
                            name="dispSGST"
                            id="dispSGST"
                            value={invoiceData?.dispSGST}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Total Amount"
                            name="vendorBasicValue"
                            placeholder="0.00"
                            id="vendorBasicValue"
                            value={invoiceData?.dispTotalAmount}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        ></Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        ></Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        ></Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Transporter Code"
                            name="dispTransporterCode"
                            id="dispTransporterCode"
                            value={invoiceData?.dispTransporterCode}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Transporter Name"
                            name="dispTransporterName"
                            id="dispTransporterName"
                            value={invoiceData?.dispTransporterName}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Vehiele RegNo"
                            name="dispVehieleRegNo"
                            id="dispVehieleRegNo"
                            value={invoiceData?.dispVehieleRegNo}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Customer Code"
                            name="dispCustomerCode"
                            id="dispCustomerCode"
                            value={invoiceData?.dispCustomerCode}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {!viewInvoiceDataStatus && (
              <Row className="my-4 ">
                <Col
                  lg={{ span: 2, offset: 8 }}
                  className="d-flex justify-content-end"
                >
                  <button
                    className="btnTable btn-danger"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </Col>
                <Col lg={2} className="d-flex justify-content-end">
                  <button className="btnTable" onClick={handleSave}>
                    Save
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default InvoiceConfirmationModal;
