import { configureStore } from "@reduxjs/toolkit";
// import { useDispatch, useSelector } from "react-redux";

import  userSlice  from "./feature/userSlice";
import  appSlice from "./feature/appSlice";
import  reportSlice from "./feature/reportSlice";

import  commonSlice  from "./feature/supplier_app/commonSlice";
import  menuSlice  from "./feature/supplier_app/menuSlice";
import  workflowSlice  from "./feature/supplier_app/workflowSlice";
import  sourcingSlice  from "./feature/supplier_app/sourcingSlice";
import  supplierSlice  from "./feature/supplier_app/supplierSlice";
import  purchasingSlice  from "./feature/supplier_app/purchasingSlice";
import  gatepassSlice  from "./feature/supplier_app/gatepassSlice";
import  singleSourcingSlice  from "./feature/supplier_app/singleSourcingSlice";

import  customerSlice  from "./feature/customer_app/customerSlice";
import  customerWorkflow  from "./feature/customer_app/customerWorkflowSlice";
import  preSales  from "./feature/customer_app/preSalesSlice";

import  customerV1Slice  from "./feature/customer_app/customerV1Slice";

const store = configureStore({
  reducer: {
    user : userSlice,
    app : appSlice,
    report : reportSlice,

    common : commonSlice,
    menu : menuSlice,
    workflow : workflowSlice,
    sourcing : sourcingSlice,
    supplier : supplierSlice,
    purchasing : purchasingSlice,
    gatepass : gatepassSlice,
    singleSourcing : singleSourcingSlice,

    customer : customerSlice,
    customerWorkflow : customerWorkflow,
    preSales : preSales,

    customerV1:customerV1Slice,
  }
});

export default store;

// export const useAppDispatch = useDispatch;
// export const useAppSelector = useSelector;
