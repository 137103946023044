import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import {
  addCreditPolicyID,
  getPostDispatchDocList,
} from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { formatDateTime } from "../../../../common/Validations";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import { useNavigate } from "react-router-dom";
import CustomerConstants from "../../../../common/CustomerConstants";
import { workflow } from "../../../../redux/feature/customer_app/customerSlice";

const CreditPolicyRequestList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "");

  let quotationDocData = useSelector(
    (state) => state?.customerV1?.postDispatchDoc
  ); // User Details from Store
  let quotationDocDataTable = useSelector(
    (state) => state?.customerV1?.postDispatchDoc.pagination
  ); // User Details from Store

  //workflow
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const [data, setData] = useState();

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };
  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const detailedPage = (creditPolicyID, workflowActionLogs) => {
    dispatch(addCreditPolicyID(creditPolicyID));
    dispatch(workflow(workflowActionLogs));
    // navigate(CustomerConstants.CUSTOMER_V1_QUOTATION_DETAILS);
    navigate(CustomerConstants.CUSTOMER_V1_CREDIT_POLICY_REQUEST_FORM_DETAILS);
  };
  const userListColInfo = [
    {
      name: "Credit Policy Req",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <button
            className="btnInfo"
            onClick={() =>
              detailedPage(row.documentNumber, row.workflowActionLogs)
            }
          >
            {row.documentNumber || "-"}{" "}
          </button>
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Workflow log",
      selector: (row) => row.workflowActionLogs,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center iconTable"
        >
          <img
            src="assets/img/newIcons/workflow.png"
            alt="attachments"
            style={{ width: "35px" }}
            className="mt-1"
            onClick={() => showStatusInfo(row.workflowActionLogs)}
          />
        </div>
      ),
      sortable: true,
      width: "132px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <TableStatusField status={row.cpHeaderStatus} />
        </>
      ),

      width: "180px",
    },
    {
      name: "Customer",
      selector: (row) => row.sapDocumentNumber,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
          <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Outstanding Amt",
      selector: (row) => row.currentOutstandingAmount,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.currentOutstandingAmount} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Trunover Amt",
      selector: (row) => row.totalTrunover,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.totalTrunover} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },


    {
      name: "Sales Officer",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.salesOfficerName} </p>
          <p className="m-0 p-0 ">{row.salesOfficerCode} </p>
        </div>
      ),
      selector: (row) => row.salesOfficerName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Create Date",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "190px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      cell: (row) => (
        <>
          <span>{row.createdByName}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const loadInitialSuuplierData = async () => {
    setData(quotationDocData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      ueryName: "getCreditPolicyRequestList",
      module: "CUSTOMER_CREDIT_POLICY",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getPostDispatchDocList(obj));
    setData(quotationDocData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCreditPolicyRequestList",
      module: "CUSTOMER_CREDIT_POLICY",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getPostDispatchDocList(obj));
    setData(quotationDocData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCreditPolicyRequestList",
      module: "CUSTOMER_CREDIT_POLICY",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getPostDispatchDocList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationDocData]);

  
  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCreditPolicyRequestList",
      module: "CUSTOMER_CREDIT_POLICY",
      page: 1,
      per_page: 10,
    };
    dispatch(getPostDispatchDocList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Credit Policy Request List</h2>
                <p className="subText">Credit Policy Request List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OS</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">
                        {" "}
                        Credit Policy Request List
                      </span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => introVideoShow()}
                      >
                        Order Booking
                      </button>
                    </Col> */}
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={quotationDocData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={quotationDocDataTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default CreditPolicyRequestList;
