import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputField } from "../../formElements/InputField";
import { validateEmail } from "../../../common/Validations";
import FailureModal from "./FailureModal";

const CreateInviteSupplierModal = React.forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    firstName: "",
    companyName: "",
    email: "",
  });


  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getErrorFields, setErrorFields] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [getFailureModal, setFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    let requiredFields = [];

    if (formData.firstName && formData.companyName && formData.email) {
      // Ensure all fields are filled
      if (!validateEmail(formData?.email)) {
        requiredFields.push("* Invalid Email address");
        setErrorFields(requiredFields);
        setShowFailureModal(true);
        return;
      }

      props?.onSubmit(formData);
      props?.onHide();
    } else {
      // alert("All fields are required.");
      if (!validateEmail(formData?.email)) {
        requiredFields.push("* All fields are required.");
        setErrorFields(requiredFields);
        setShowFailureModal(true);
        return;
      }
    }
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  useEffect(() => {
    // Reset form when modal is closed
    if (!props.show) {
      setFormData({
        firstName: "",
        companyName: "",
        email: "",
      });
    }
  }, [props.show, setFormData]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Invitation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <InputField
              className="inputBox"
              label="First Name"
              name="firstName"
              id="firstName"
              extraLabel=""
              placeholder="First Name"
              value={formData.firstName || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <InputField
              className="inputBox"
              label="Company Name"
              name="companyName"
              id="companyName"
              extraLabel=""
              placeholder="Company Name"
              value={formData.companyName || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <InputField
              className="inputBox"
              label="Email"
              name="email"
              id="email"
              extraLabel=""
              placeholder="test@gmail.com"
              value={formData.email || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleSubmit} className="btn btn-primary">
          Submit
        </button>
        <button onClick={props?.onHide} className="btn btn-danger">
          Cancel
        </button>
      </Modal.Footer>

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </Modal>
  );
});

export default CreateInviteSupplierModal;
