
const LoaderSmall = ({ isLoading, className }) => {
  return (
    <>
      <div className={`loader-small ${className}`}>
        {isLoading && (
          <div className="loader">
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
            <div className="loader__circle"></div>
          </div>        
        )}
      </div>
    </>
  );
};

export default LoaderSmall;
