import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

// import ConstantsList from '../../../common/Constants';

//Components
import Layout from "../../../../Layout/Layout";
import CreateSupplierMasterModal from "../../../../Components/Modal/supplier_app/CreateSupplierMasterModal";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { useNavigate } from "react-router-dom";
import { addPoDetailNumber } from "../../../../redux/feature/supplier_app/purchasingSlice";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import { currencyFormat } from "../../../../common/Validations";
import Moment from "react-moment";
import configFromEnv from "../../../../services/configuration";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";

const ServicePurchaseOrderList = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Service Order");
  let purchase_orderData = useSelector(
    (state) => state?.purchasing?.purchase_order?.service_po?.data
  ); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  // /* Modal Function */
  // const createRFQ = () => {
  //   setModalShow(true);
  // };

  const hideModal = () => {
    setModalShow(false);
  };

  const detailedPage = (poNumber) => {
    dispatch(addPoDetailNumber(poNumber));
    navigate(Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE);
  };
  const fetchUsers = async (page) => {
    setLoading(true);
    // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);

    setData(purchase_orderData);
    // setTotalRows(purchase_orderData?.length);
    setLoading(false);
  };

  // const handlePageChange = (page) => {
  //   fetchUsers(page);
  // };

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   setLoading(true);
  //   // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);

  //   setData(purchase_orderData);
  //   setPerPage(perPage);
  //   setLoading(false);
  // };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_orderData]);

  // Show Status Modal
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Document Type",
      selector: (row) => row.purchasingDocumentCode,
      sortable: true,
      width: "220px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.purchasingDocumentType} </p>
        </div>
      ),
    },
    {
      name: "Purchase Type",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.purchaseType} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Document",
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.purchasingDocumentNr)}
        >
          {row.purchasingDocumentNr}{" "}
        </button>
      ),
      width: "150px",
    },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            selector: (row) => row.workflowActionLogs,
            cell: (row) => (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center iconTable"
              >
                <img
                  src="assets/img/newIcons/workflow.png"
                  alt="attachments"
                  style={{ width: "35px" }}
                  className="mt-1"
                  onClick={() => showStatusInfo(row.workflowActionLogs)}
                />
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Attachments",
      selector: (row) => row.attachments,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Version",
      selector: (row) => row.version || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.statusHeader === "In Release" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.statusHeader}
            </span>
          ) : row.statusHeader === "New" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.statusHeader}
            </span>
          ) : row.statusHeader === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.statusHeader}
            </span>
          ) : (
            row.statusHeader
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Date",
      selector: (row) => row.purchasingDocumentDate,
      sortable: true,
      width: "110px",
      style: {
        textAlign: "right",
      },
      headerStyle: {
        textAlign: "right",
      },
    },
    {
      name: "PO Value",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.purchasingDocumentValue).toFixed(2))}
        </span>
      ),
      selector: (row) => row.purchasingDocumentValue || "--",
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency ",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.currency}</span>
        </>
      ),
      selector: (row) => row.currency || "--",
      sortable: true,
      width: "110px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    // {
    //   name: "Payment Terms",
    //   cell: (row) => <>{row.paymentTerms}</>,
    //   selector: (row) => row.paymentTerms,
    //   sortable: true,
    //   width: "180px",
    // },
    // {
    //   name: "Inco Terms",
    //   selector: (row) => row.incoTerms,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Payment Terms",
      selector: (row) => row.paymentTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.paymentTerms} </p>
        </div>
      ),
    },
    {
      name: "Inco Terms",
      selector: (row) => row.incoTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 ">{row.incoTerms} </p>
        </div>
      ),
    },
    // {
    //   name: "Payment Terms",
    //   selector: (row) => row.paymentTermsCode,
    //   sortable: true,
    //   width: "180px",
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold">{row.paymentTermsCode} </p>
    //       <p className="m-0 p-0">{row.paymentTerms} </p>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Inco Terms",
    //   selector: (row) => row.incoTermsCode,
    //   sortable: true,
    //   width: "180px",
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold">{row.incoTermsCode} </p>
    //       <p className="m-0 p-0">{row.incoTerms} </p>
    //     </div>
    //   ),
    // },
    {
      name: "Created Date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>
          {row.createdAt}
        </Moment>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyCodeCode} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "PR Number",
      selector: (row) => row.item?.requestNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "RFQ",
      cell: (row) => (
        <button className="btnInfo">{row.rfxCollectiveNr || "--"} </button>
      ),
      width: "150px",
    },
    {
      name: "Quotation",
      cell: (row) => (
        <button className="btnInfo">{row.quotationNumber || "--"}</button>
      ),
      width: "150px",
    },
    {
      name: "Quotation Version",
      selector: (row) => row.rfqTechFocalPerson,
      sortable: true,
      width: "160px",
    },
    
    // {
    //   name: "Delivery Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.deliveredStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Invoice Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.invoicedStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Payment Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.paymentStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
  ];

  const lineItemColInfo = [
    {
      name: "Item Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    // {
    //   name: "Schedule Nr",
    //   cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
    //   width: "180px",
    // },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialGroup} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },
    {
      name: "Net Value",
      cell: (row) => <span>{row.netValue} </span>,
      width: "220px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "220px",
    },
    {
      name: "Purchase Req.",
      cell: (row) => <span>{row.requestNumber} </span>,
      width: "220px",
    },
    {
      name: "Purchase Req. Item Nr",
      cell: (row) => <span>{row.prItemNr} </span>,
      width: "220px",
    },
    // {
    //   name: "Item Number",
    //   cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
    //   width: "180px",
    // },
    // {
    //   name: "Material",
    //   cell: (row) => <span>{row.materialCode} </span>,
    //   width: "180px",
    // },
    // {
    //   name: "Material Desc",
    //   cell: (row) => <span>{row.materialDescription} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "Material Group",
    //   cell: (row) => <span>{row.materialGroup} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "Quantity",
    //   cell: (row) => <span>{row.purchaseQuantity} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "UOM",
    //   cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "Delivery Date",
    //   cell: (row) => <span>{row.deliveryDate} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "PR",
    //   cell: (row) => <span>{row.requestNumber || "-"} </span>,
    //   width: "220px",
    // },
    // {
    //   name: "PR Item",
    //   cell: (row) => <span>{row.prItemNr || "-"} </span>,
    //   width: "220px",
    // },
  ];

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Service Purchase Order</h2>
            <p className="subText">
              Lists of Service Purchase Order and details
            </p>
            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SR</span>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                      <span className="headerTitle">Service PO List</span>
                      <span className="subHeaderTitle">
                        View of Service PO and Status{" "}
                      </span>
                    </Col>

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-plus-lg"></i> Change Buyer
                      </button>
                    </Col> */}
                    <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                      {/* <Col
                        xs={5}
                        md={5}
                        lg={5}
                        className="d-flex justify-content-end"
                      >
                        <button
                          className="btnTable"
                          onClick={() => createRFQ()}
                        >
                          <i className="bi bi-plus-lg"></i> Request Supplier for
                          ACK
                        </button>
                      </Col> */}
                      <Col
                        xs={5}
                        md={5}
                        lg={5}
                        className="d-flex justify-content-end"
                      >
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("asd");
                          }}
                        >
                          <i className="bi bi-x-circle"></i> Deletion
                        </button>
                      </Col>
                      <Col xs={2} md={1} lg={1}>
                        <POCollabrationCommonFillter />
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateSupplierMasterModal show={modalShow} onHide={() => hideModal()} />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default ServicePurchaseOrderList;
