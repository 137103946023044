// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subContent {
  padding: 20px;
}

.cardApp {
  display: flex;
  flex-direction: row;
  /* Display content in a row */
  align-items: center;
  color: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border-radius: 8px;
  cursor: pointer;
}
.cardApp:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.17);
}
.cardApp h3 {
  margin: 0;
}
.cardApp p {
  font-size: 0.9em;
  margin-top: 5px;
}

/* Styling for the icon or image container */
.icon-container {
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* Slightly transparent background for icon container */
}`, "",{"version":3,"sources":["webpack://./src/Pages/customer_app_css/AppLandingPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,YAAA;EACA,qDAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACI,2BAAA;EACA,+CAAA;AACR;AAEI;EACI,SAAA;AAAR;AAGI;EACI,gBAAA;EACA,eAAA;AADR;;AAKA,4CAAA;AACA;EACI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EAEA,uDAAA;AAHJ","sourcesContent":[".subContent {\n    padding: 20px;\n}\n\n.cardApp {\n    display: flex;\n    flex-direction: row;\n    /* Display content in a row */\n    align-items: center;\n    color: white;\n    transition: transform 0.2s ease, box-shadow 0.2s ease;\n    border-radius: 8px;\n    cursor: pointer;\n\n    &:hover {\n        transform: translateY(-5px);\n        box-shadow: 0px 4px 6px 3px rgb(0 0 0 / 17%);\n    }\n\n    h3 {\n        margin: 0;\n    }\n\n    p {\n        font-size: 0.9em;\n        margin-top: 5px;\n    }\n}\n\n/* Styling for the icon or image container */\n.icon-container {\n    font-size: 2em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    // background-color: rgba(255, 255, 255, 0.15);\n    /* Slightly transparent background for icon container */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
