

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";

//Redux
import { useDispatch } from "react-redux";

//Serivce
import ItemService from "../../../services/supplier_appCommonService";
import toast from "react-hot-toast";
import SuccessRegisterModal from "../../../Components/SuccessRegisterModal";
// import Constants from "../../../common/Constants";
import { getSingleSourcingList } from "../../../redux/feature/supplier_app/singleSourcingSlice";
import Constants from "../../../common/SupplierConstants";

const CreateSingleSource = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Single Source");
  localStorage.setItem("submenu", "Create Single Source");

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const [formInputs, setFormInputs] = useState({
    requestedBy: "",
    requesterEmail: "",
    singleSourceType: "",
    purchaseReqNr: "",
    purchaseReqValue: "",
    requestDate: "",
    sSEndUser: "",
    sDEndUserID: "",
    managertype: "",
    deptManager: "",
    deptUserID: "",
    lineManager: "",
    lineManagerUserId: "",
    lineVP: "",
    lineVPUserID: "",
    requesterId: "",
    companyCode: "",
    requester: "",
    requesterDepartment: "",
    requesterDesignation: "",
    OEMSupplier: "",
    purchaseReqBuyer: "",
    buyerID: "",
    scmCategory: "",
    categoryManager: "",
    categoryManagerID: "",
    singleSourceArticle: "",
    benefit: "",
    justification: "",
    reason: "",
    cfoUserId: "",
    ssStatusHeader: "",
    ssRequestNr: "",
    createdAt: "",
    PendingApprover: "",
    PendingPeriodDate: "",
    PendingStatus: "",
    updatedAt: "",
  });
  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const [getDropdownData, setDropdownData] = useState("");
  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  /* submit button */
  const handleCreateSSF = async () => {
    // let requiredFields = [];

    /* if (!validateEmail(formInputs?.email)) {
        requiredFields.push("*Invalid Contact Email address");
        setErrorFields(requiredFields);
        setShowFailureModal(true);
        return;
      } */

    /*   if (!validateContactNumber(formInputs?.contactNumber)) {
        requiredFields.push('*Invalid contact number (10 digits)');
        setErrorFields(requiredFields);      
        setShowFailureModal(true);
        return;
      }
   */
    // formInputs.items = setFormInputs;

    // formInputs.gpStatusHeader = "Gatepass Created";

    console.log(">> handleCreateSSF", formInputs);

    /*   const toastId = toast.loading("Loading...", {
        position: "top-right",
      }); */
    let obj = {
      data: formInputs,
    };
    setDisabled(true);
    await ItemService.addSingleSourceRequest(obj).then((item) => {
      console.log(">> item! ", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        dispatch(getSingleSourcingList()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    /*  toast.dismiss(toastId); */
    setDisabled(false);
  };

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const modalData = {
    title: "User Creation",
    successMsg: "",
  };
  const [getModalInfo, setModalInfo] = useState(modalData);

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const [getShowSuccessModal, setShowSuccessModal] = useState(false);

  const [disabled, setDisabled] = useState(false);
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Single Source create</h2>
                <p className="subText">Single Source Create</p>
              </Col>
              <Col xs={12} md={2} lg={2} className="mb-2">
                <button disabled={disabled} className="btnTable" onClick={handleCreateSSF}>
                  Create SSF
                </button>
              </Col>
              <hr />
            </Row>

            {/* single source type - 1 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ST</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Single Source Type</span>
                      <span className="subHeaderTitle">
                        SingleSourceType Selection
                      </span>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Single Source Form"
                          placeholder=""
                          data={dropdownOption}
                          id="singleSourceForm"
                          iconClassName="singleSourceForm"
                          name="singleSourceForm"
                          setValue={formInputs.singleSourceForm || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 4, offset: 4 }}
                        lg={{ span: 4, offset: 4 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Employee Name"
                          id="employeeName"
                          name="employeeName"
                          placeholder=""
                          value={formInputs.employeeName || ""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Single Source Header - 2 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ST</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Single Source Header</span>
                      <span className="subHeaderTitle">
                        Single Source Header
                      </span>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Single Source Form"
                          id="singleSourceType"
                          name="singleSourceType"
                          placeholder=""
                          value={formInputs.singleSourceType || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Department "
                          id="requesterDepartment"
                          name="requesterDepartment"
                          placeholder=""
                          value={formInputs.requesterDepartment || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="VP/Chief Officer"
                          placeholder=""
                          data={dropdownOption}
                          id="lineVP"
                          iconClassName="lineVP"
                          name="lineVP"
                          setValue={formInputs.lineVP || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SSF Number"
                          id="ssRequestNr"
                          name="ssRequestNr"
                          placeholder=""
                          value={formInputs.ssRequestNr || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Designation"
                          id="requesterDesignation"
                          name="requesterDesignation"
                          placeholder=""
                          value={formInputs.requesterDesignation || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Reference PR"
                          id="purchaseReqNr"
                          name="purchaseReqNr"
                          placeholder=""
                          value={formInputs.purchaseReqNr || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Employee ID"
                          id="requesterId"
                          name="requesterId"
                          placeholder=""
                          value={formInputs.requesterId || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {/* <InputField
                          className="inputBox"
                          label="End User"
                          id="sSEndUser"
                          name="sSEndUser"
                          placeholder=""
                          value={formInputs.sSEndUser || ""}
                          onChange={onInputChange}
                          
                        /> */}
                        <ComboboxField
                          label="End User"
                          placeholder=""
                          data={dropdownOption}
                          id="sSEndUser"
                          iconClassName="sSEndUser"
                          name="sSEndUser"
                          setValue={formInputs.sSEndUser || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PR Value (AED)"
                          id="purchaseReqValue"
                          name="purchaseReqValue"
                          placeholder=""
                          value={formInputs.purchaseReqValue || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Employee Name"
                          id="requester"
                          name="requester"
                          placeholder=""
                          value={formInputs.requester || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {/*  <InputField
                          className="inputBox"
                          label="Manager Type"
                          id="managertype"
                          name="managertype"
                          placeholder=""
                          value={formInputs.managertype || ""}
                          onChange={onInputChange}
                          
                        /> */}
                        <ComboboxField
                          label="Manager Type"
                          placeholder=""
                          data={dropdownOption}
                          id="managertype"
                          iconClassName="managertype"
                          name="managertype"
                          setValue={formInputs.managertype || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">Supplier</p>
                        <span className="me-2">Yes</span>
                        <input
                          className="me-2"
                          name="OEMSupplier"
                          id="OEMSupplier"
                          value="Yes"
                          checked={formInputs.ssStatusHeader === "Yes"}
                          type="radio"
                          onChange={onInputChange}
                        />
                        <span className="me-2">No</span>
                        <input
                          className="me-2"
                          name="OEMSupplier"
                          id="OEMSupplier"
                          value="No"
                          checked={formInputs.ssStatusHeader === "No"}
                          type="radio"
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          id="ssStatusHeader"
                          name="ssStatusHeader"
                          placeholder=""
                          value={formInputs.ssStatusHeader || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {/*  <InputField
                          className="inputBox"
                          label="Line Manager"
                          id="lineManager"
                          name="lineManager"
                          placeholder=""
                          value={formInputs.lineManager || ""}
                          onChange={onInputChange}
                          
                        /> */}
                        <ComboboxField
                          label="Line Manager"
                          placeholder=""
                          data={dropdownOption}
                          id="lineManager"
                          iconClassName="lineManager"
                          name="lineManager"
                          setValue={formInputs.lineManager || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="OEM Supplier"
                          id="OEMSupplier"
                          name="OEMSupplier"
                          placeholder=""
                          value={formInputs.OEMSupplier || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*Category Assignment - 3 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CA</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Category Assignment</span>
                      <span className="subHeaderTitle">
                        Details of Category Assignment
                      </span>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="SCM Buyer"
                          placeholder=""
                          data={dropdownOption}
                          id="purchaseReqBuyer"
                          iconClassName="purchaseReqBuyer"
                          name="purchaseReqBuyer"
                          setValue={formInputs.purchaseReqBuyer || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="SCM Category"
                          placeholder=""
                          data={dropdownOption}
                          id="scmCategory"
                          iconClassName="scmCategory"
                          name="scmCategory"
                          setValue={formInputs.scmCategory || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Procurement Manager"
                          placeholder=""
                          data={dropdownOption}
                          id="categoryManager"
                          iconClassName="categoryManager"
                          name="categoryManager"
                          setValue={formInputs.categoryManager || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*Reason For Single Source- 4*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RS</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Reason For Single Source
                      </span>
                      <span className="subHeaderTitle">
                        Reason For Single Source
                      </span>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Reason for Single Source"
                          id="reasonforSingleSource"
                          name="reasonforSingleSource"
                          placeholder=""
                          value={formInputs.reasonforSingleSource || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Justify the Goods or Services required in detail"
                          id="JustifytheGoods"
                          name="JustifytheGoods"
                          placeholder=""
                          value={formInputs.JustifytheGoods || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Benefit or Specialization from Supplier"
                          id="Benefit"
                          name="Benefit"
                          placeholder=""
                          value={formInputs.Benefit || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SUPPORTING DOCUMENT"
                          id="SUPPORTINGDOCUMENT"
                          name="SUPPORTINGDOCUMENT"
                          placeholder=""
                          type="file"
                          value={formInputs.SUPPORTINGDOCUMENT || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SINGLESOURCING_SINGLE_SOURCING_LIST}
      />
    </main>
  );
};

export default CreateSingleSource;
