import React, { useEffect, useState } from "react";
// import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
// import { TextAreaField } from "../../../../Components/formElements/TextAreaField";

// import ItemService from "../../../../services/customer_appCommonService";

//Redux
// import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
// import SupplierConstants from "../../../../common/SupplierConstants";
import { validateRequired } from "../../../../common/Validations";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";

const CreateTransportation = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Material");

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const message = {
    VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
    VALID_MAIL_ID: "Please enter a valid email address.",
    VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
    VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
    VALID_ALTERNATE_CONTACT_NUMBER:
      "Please enter a valid alternate contact number.",
    VALID_PURPOSE_JUSTIFICATION:
      "Please ensure the justification is under 500 characters.",
    VALID_POSTAL_CODE:
      "Enter a 6-character postal code with uppercase letters and numbers only",
    VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
    VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
    EXIStS_MAIL_ID: "Email already exists",
    EXIStS_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
    FILL_REQUIRED_FIELD: "Please fill the required field",
    ALTERNATE_MAIL_ID: "Email ID is same",
    ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  };

  const [formInputs, setFormInputs] = useState({
    vehicleNumber: "",
    ownerName: "",
    driverName: "",
    driverContact: "",
    // vehicleType:"",
    capacity: "",
    registrationDate: "",
    insuranceExpiryDate: "",

    currentLoadStatus: "",
    sourceLocation: "",
    destinationLocation: "",
    tripStartDate: "",
    tripStartTime: "",
    estimatedArrivalTime: "",

    rcBookStatus: "",
    pollutionCertificateStatus: "",
    permitValidity: "",
    insuranceDocuments: "",
    driverLicenseStatus: "",

    gpsLocation: "",
    lastTripDetails: "",
    numberOfTripsCompleted: "",

    maintenanceRecords: "",
  });

  /* pop failure modal data*/
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);
  const [validation, setValidation] = useState({
    vehicleNumber: "",
    ownerName: "",
    driverName: "",
    driverContact: "",
    // vehicleType:"",
    capacity: "",
    registrationDate: "",
    insuranceExpiryDate: "",
  });
  const getDropdownOption = [
    { id: 1, name: "Truck" },
    { id: 2, name: "Trailer" },
  ];
  const loadStatusDropdownOption = [
    { id: 1, name: "Loded" },
    { id: 2, name: "Empty" },
  ];
  const rcBookStatusDropDown = [
    { id: 1, name: "Valid" },
    { id: 2, name: "Expired" },
    { id: 3, name: "Pending" },
  ];
  const driverLicenseDropDown = [
    { id: 1, name: "Valid" },
    { id: 2, name: "Expired" },
    { id: 3, name: "Suspended" },
  ];
  //   const modalData = {
  //     title: "Material Creation",
  //     successMsg: "",
  //   };
  //   const [getModalInfo, setModalInfo] = useState(modalData);
  //   const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  //   const [disabled, setDisabled] = useState(false);
  const [getDropdownData, setDropdownData] = useState("");

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  //hide  failur modal
  const hideFailureModal = () => {
    setShowFailureModal(false);
  };
  useEffect(() => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      [getDropdownData.textField]: getDropdownData.name,
      // Uncomment these lines if needed
      //   [getDropdownData.textCode]: getDropdownData.code,
      //   [getDropdownData.textDesc]: getDropdownData.description,
    }));
  }, [getDropdownData]);

  /* handle create */
  const handleCreate = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.vehicleNumber)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        vehicleNumber: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.ownerName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        ownerName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.driverName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        driverName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.driverContact)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        driverContact: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    // if (!validateRequired(formInputs.vehicleType)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     vehicleType: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    if (!validateRequired(formInputs.capacity)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        capacity: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.registrationDate)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        registrationDate: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.insuranceExpiryDate)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        insuranceExpiryDate: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    console.log(">>formInputs", formInputs);
    // let obj = {
    //   data: formInputs,
    // };
    // console.log(">>obj",obj)
    // const toastId = toast.loading("Loading...", {
    //   position: "top-right",
    // });
    // setDisabled(true);
    // await ItemService.addMaterial(obj).then((item) => {
    //   if (item?.status === 200) {
    //     modalData.successMsg = item?.message;
    //     setModalInfo(modalData);
    //     onSuccessfullyRegistered();
    //   } else {
    //     toast.error(item?.message || "Please try again !!", {
    //       duration: 4000,
    //       position: "top-right",
    //     });
    //   }
    // });
    // toast.dismiss(toastId);
    // setDisabled(false);
  };

  /* Show Success Modal */
  //   const onSuccessfullyRegistered = () => {
  //     setShowSuccessModal(true);
  //   };
  //   const hideSuccessModal = () => {
  //     setShowSuccessModal(false);
  //   };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Transportation</h2>
            <p className="subText">Create Transportation</p>
            <hr />
            <Row className="mb-2">
              <Col
                xs={2}
                md={{ offset: 10, span: 1 }}
                lg={{ offset: 10, span: 1 }}
                className="text-right"
              >
                <span>
                  <button
                    className="btnTable"
                    // disabled={disabled}
                    onClick={handleCreate}
                  >
                    Create
                  </button>
                </span>
              </Col>
            </Row>
            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Basic Information</span>
                      <span className="subHeaderTitle">Basic Information</span>
                    </Col>
                    {/* 
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button
                          className="btnTable"
                          disabled={disabled}
                          onClick={handleCreate}
                        >
                          Create
                        </button>
                      </span>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vehicle Number"
                          name="vehicleNumber"
                          id="vehicleNumber"
                          extraLabel=""
                          value={formInputs.vehicleNumber || ""}
                          onChange={onInputChange}
                          error={validation.vehicleNumber || ""}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Owner Name"
                          name="ownerName"
                          id="ownerName"
                          value={formInputs.ownerName || ""}
                          onChange={onInputChange}
                          error={validation.ownerName || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Driver Name "
                          name="driverName"
                          id="driverName"
                          extraLabel=""
                          value={formInputs.driverName || ""}
                          onChange={onInputChange}
                          error={validation.driverName || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Diver Contact"
                          name="driverContact"
                          id="driverContact"
                          value={formInputs.driverContact || ""}
                          onChange={onInputChange}
                          error={validation.driverContact || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Vehicle Type (Truck, Trailer, etc.)"
                          //   placeholder="please select the PR Document Type"
                          data={getDropdownOption}
                          id="vehicleType"
                          iconClassName="dropdownIcon"
                          name="vehicleType"
                          setValue={formInputs?.vehicleType || []}
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Capacity (in Tons)"
                          name="capacity"
                          id="capacity"
                          value={formInputs.capacity || ""}
                          onChange={onInputChange}
                          error={validation.capacity || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Registration Date"
                          name="registrationDate"
                          id="registrationDate"
                          type="date"
                          value={formInputs.registrationDate || ""}
                          onChange={onInputChange}
                          error={validation.registrationDate || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Insurance Expiry Date"
                          name="insuranceExpiryDate"
                          id="insuranceExpiryDate"
                          type="date"
                          value={formInputs.insuranceExpiryDate || ""}
                          onChange={onInputChange}
                          error={validation.insuranceExpiryDate || ""}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Trip & Load Details</span>
                      <span className="subHeaderTitle">
                        Trip & Load Details
                      </span>
                    </Col>
                    {/* 
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button
                          className="btnTable"
                          disabled={disabled}
                          onClick={handleCreate}
                        >
                          Create
                        </button>
                      </span>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Current Load Status (Loaded/Empty)"
                          //   placeholder="please select the PR Document Type"
                          data={loadStatusDropdownOption}
                          id="currentLoadStatus"
                          iconClassName="dropdownIcon"
                          name="currentLoadStatus"
                          setValue={formInputs?.currentLoadStatus || []}
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Source Location"
                          name="sourceLocation"
                          id="sourceLocation"
                          value={formInputs.sourceLocation || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Destination Location"
                          name="destinationLocation"
                          id="destinationLocation"
                          extraLabel=""
                          value={formInputs.destinationLocation || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Trip Start Date"
                          name="tripStartDate"
                          id="tripStartDate"
                          type="date"
                          value={formInputs.tripStartDate || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Trip Start time"
                          name="tripStartTime"
                          id="tripStartTime"
                          type="time"
                          value={formInputs.tripStartTime || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Estimated Arrival Time"
                          name="estimatedArrivalTime"
                          id="estimatedArrivalTime"
                          type="time"
                          value={formInputs.estimatedArrivalTime || ""}
                          onChange={onInputChange}
                          error={validation.estimatedArrivalTime || ""}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 03 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DC</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Documents & Compliance
                      </span>
                      <span className="subHeaderTitle">
                        Documents & Compliance:
                      </span>
                    </Col>
                    {/* 
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button
                          className="btnTable"
                          disabled={disabled}
                          onClick={handleCreate}
                        >
                          Create
                        </button>
                      </span>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {/* <InputField
                          className="inputBox"
                          label="RC Book Status"
                          name="rcBookStatus"
                          id="rcBookStatus"
                          value={formInputs.rcBookStatus || ""}
                          onChange={onInputChange}
                          required={true}
                        /> */}
                        <ComboboxField
                          label="RC Book Status"
                          //   placeholder="please select the PR Document Type"
                          data={rcBookStatusDropDown}
                          id="rcBookStatus"
                          iconClassName="dropdownIcon"
                          name="rcBookStatus"
                          setValue={formInputs?.rcBookStatus || []}
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Pollution Certificate Status"
                          //   placeholder="please select the PR Document Type"
                          data={rcBookStatusDropDown}
                          id="pollutionCertificateStatus"
                          iconClassName="dropdownIcon"
                          name="pollutionCertificateStatus"
                          setValue={
                            formInputs?.pollutionCertificateStatus || []
                          }
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Permit Validity"
                          name="permitValidity"
                          id="permitValidity"
                          type="date"
                          value={formInputs.permitValidity || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Driver License Status"
                          //   placeholder="please select the PR Document Type"
                          data={driverLicenseDropDown}
                          id="driverLicenseStatus"
                          iconClassName="dropdownIcon"
                          name="driverLicenseStatus"
                          setValue={formInputs?.driverLicenseStatus || []}
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Insurance Documents"
                          name="insuranceDocuments"
                          id="insuranceDocuments"
                          value={formInputs.insuranceDocuments || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 04 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DC</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Tracking & History</span>
                      <span className="subHeaderTitle">Tracking & History</span>
                    </Col>
                    {/* 
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button
                          className="btnTable"
                          disabled={disabled}
                          onClick={handleCreate}
                        >
                          Create
                        </button>
                      </span>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="GPS Location"
                          name="gpsLocation"
                          id="gpsLocation"
                          value={formInputs.gpsLocation || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Last Trip Details"
                          name="lastTripDetails"
                          id="lastTripDetails"
                          value={formInputs.lastTripDetails || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number of Trips Completed"
                          name="numberOfTripsCompleted"
                          id="numberOfTripsCompleted"
                          value={formInputs.numberOfTripsCompleted || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Maintenance Records"
                          name="maintenanceRecords"
                          id="maintenanceRecords"
                          value={formInputs.maintenanceRecords || ""}
                          onChange={onInputChange}
                          error={validation.maintenanceRecords || ""}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      /> */}
      {/* <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={SupplierConstants.MATERIAL_LIST}
      /> */}
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CreateTransportation;
