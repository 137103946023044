import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
// import { currencyFormat, formatDateTime } from "../../../../common/Validations";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useSelector
import CustomerConstants from "../../../../common/CustomerConstants";
import {
  addCostingSheetID,
  getCostingSheetList,
} from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import { useNavigate } from "react-router-dom";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { formatDateTime } from "../../../../common/Validations";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import { workflow } from "../../../../redux/feature/customer_app/customerSlice";

const CostingSheetList = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Costing Sheet");

  let costSheetData = useSelector((state) => state?.customerV1?.costingSheet); // User Details from Store
  let costSheetTable = useSelector(
    (state) => state?.customerV1?.costingSheet?.pagination
  ); // User Details from StoreS

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  //workflow
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const lineItemColInfo = [
    {
      name: "Sl Nr",
      cell: (row) => <span>{row.local_id} </span>,
      width: "80px",
    },
    {
      name: "Product",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Ex-Plant Rate",
      cell: (row) => <span>{row.explantRate} </span>,
      width: "120px",
    },
    {
      name: "Freight Rate",
      cell: (row) => <span>{row.freightRate} </span>,
      width: "120px",
    },
    {
      name: "Route Nr",
      cell: (row) => <span>{row.routeNumber} </span>,
      width: "120px",
    },
    {
      name: "Toll",
      cell: (row) => <span>{row.tollRate} </span>,
      width: "120px",
    },
    {
      name: "Commission",
      cell: (row) => <span>{row.commissionRate} </span>,
      width: "120px",
    },
    {
      name: "Quote Price(INR)",
      cell: (row) => <span>{row.quotePrice} </span>,
      width: "220px",
    },
  ];

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const detailedPage = (costingSheetID, workflowActionLogs) => {
    dispatch(addCostingSheetID(costingSheetID));
    dispatch(workflow(workflowActionLogs));
    navigate(CustomerConstants.CUSTOMER_V1_COSTING_SHEET_DETAILS);
  };
  const generateQuotation = (costingSheetID) => {
    dispatch(addCostingSheetID(costingSheetID));
    navigate(CustomerConstants.CUSTOMER_V1_QUOTATION_CREATION);
  };

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const columns = [
    {
      name: "Costing Sheet",
      selector: (row) => row.costingSheetDocumentNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() =>
            detailedPage(row.costingSheetDocumentNr, row.workflowActionLogs)
          }
        >
          {row.costingSheetDocumentNr || "-"}{" "}
        </button>
      ),
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Workflow log",
      selector: (row) => row.workflowActionLogs,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center iconTable"
        >
          <img
            src="assets/img/newIcons/workflow.png"
            alt="attachments"
            style={{ width: "35px" }}
            className="mt-1"
            onClick={() => showStatusInfo(row.workflowActionLogs)}
          />
        </div>
      ),
      sortable: true,
      width: "132px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <TableStatusField status={row.csStatusHeader} />
        </>
      ),

      width: "160px",
    },
    {
      name: "Customer Name",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.organisationName} </p>
          <p className="m-0 p-0 ">{row.AssociateNumber} </p>
        </div>
      ),
      selector: (row) => row.organisationName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Project Name",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
        </div>
      ),
      selector: (row) => row.organisationName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Quotation",
      cell: (row) => (
        <>
          {row.csStatusHeader === "Approved" ? (
            row.quotationNr ? (
              <span className="bg-table-status bg-table-success-status">
                {row.quotationNr}
              </span>
            ) : (
              <button
                className="btnTable"
                onClick={() => generateQuotation(row.costingSheetDocumentNr)}
              >
                {" "}
                Generate {row.publish}
              </button>
            )
          ) : (
            <button className="btnTable" disabled={true}>
              {" "}
              Generate {row.publish}
            </button>
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Sales Officer",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.salesOfficerName} </p>
          <p className="m-0 p-0 ">{row.salesOfficerCode} </p>
        </div>
      ),
      selector: (row) => row.salesOfficerName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Create Data",
      selector: (row) => formatDateTime(row.createdAt) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      cell: (row) => (
        <>
          <span>{row.createdByName}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const loadInitialSuuplierData = async () => {
    setData(costSheetData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getCostingSheetList",
      module: "COSTING_SHEET",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getCostingSheetList(obj));
    setData(costSheetData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCostingSheetList",
      module: "COSTING_SHEET",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getCostingSheetList(obj));
    setData(costSheetData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCostingSheetList",
      module: "COSTING_SHEET",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getCostingSheetList(obj));
  };

  // useEffect(() => {
  //   loadInitialSuuplierData(); // fetch page 1 of users
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [costSheetData]);

  useEffect(() => {
    const fetchData = async () => {
      await loadInitialSuuplierData();
      dispatch(workflow({}));
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costSheetData?.data]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCostingSheetList",
      module: "COSTING_SHEET",
      page: 1,
      per_page: 10,
    };
    dispatch(getCostingSheetList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Costing Sheet</h2>
                <p className="subText">Cost Comparison Details and Overview</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CS</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Costing Sheet List</span>
                      <span className="subHeaderTitle">
                        Costing Sheet Statement{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={costSheetData?.status}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={costSheetTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default CostingSheetList;
