import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { TextAreaField } from "../../../formElements/TextAreaField";

// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/supplier_appCommonService";
import SuccessModal from "../SuccessModal";

const CancelModal = React.forwardRef((props, ref) => {
  // const dispatch = useDispatch();
  const { type, selectedrow, title } = props;
  const [formInputs, setFormInputs] = useState({
    documentNumbers: [],
    statusHeader: "Cancel",
    cancellationRemark: "",
  });
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setFormInputs((prevFormInputs) => ({
      ...prevFormInputs,
      documentNumbers: selectedrow?.map((item) => item?.documentNumber) || [],
    }));
  }, [selectedrow]);

  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);

  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    if (formInputs.cancellationRemark !== "") {
      props.onHide();
    } else {
      setErrorMsg("*Please enter the remarks");
    }

    let obj = {
      module: type,
      documentNumber: formInputs.documentNumbers,
      status: "Cancelled",
      cancellationRemark: formInputs.cancellationRemark,
    };
    await ItemService.cancelDocument(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        successModalData.successMsg = item.message;
        setsucceModalData(successModalData);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
      // setDisabled(false);
    });
    // toast.dismiss(toastId);
  };
  // useEffect(() => {
  //   // console.log(">> props?.dropdownData", props?.dropdownData);
  //   setDropdownOption(props?.dropdowndata);

  //   const handleEscapeKey = (event) => {
  //     if (event.key === "Escape" || event.keyCode === 27) {
  //       props.onHide();
  //     }
  //   };
  //   document.addEventListener("keydown", handleEscapeKey);
  //   return () => {
  //     document.removeEventListener("keydown", handleEscapeKey);
  //   };

  // }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        size="lg"
        ref={ref}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <i className="bi bi-plus-lg"></i> {title}
            {/* <p className="subText">
          At supplier_app, every person has a role, each with its own level of
          access.
        </p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <form className="space-y-4">
            <Row>
              <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
                <div className="fw-medium mb-4">
                  <p> Do you want to cancel the {type} Number? </p>{" "}
                  <Row>
                    {formInputs?.documentNumbers.map((item, index) => (
                      <Col xs={5} lg={2} className="me-2" key={index}>
                        <p className="bg-table-status bg-table-new-status mb-1 px-2">
                          {item}
                        </p>
                      </Col>
                    ))}
                  </Row>
                </div>
                <TextAreaField
                  className="inputBox"
                  label=""
                  name="cancellationRemark"
                  placeholder={`Please enter the ${type} remark`}
                  value={formInputs.cancellationRemark}
                  onChange={onInputChange}
                />
              </Col>
            </Row>

            <Row className="justify-end">
              <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                <button
                  type="button"
                  onClick={() => props.onHide()}
                  className="btn btn-danger mt-4 w-full justify-center rounded-md"
                >
                  No
                </button>
              </Col>
              <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-primary mt-4 w-full justify-center rounded-md"
                >
                  Yes
                </button>
              </Col>
              <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
                <p className="errorMsg"> {errorMsg} </p>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
      />
    </>
  );
});

export default CancelModal;
