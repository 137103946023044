import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { TextAreaField } from "../../formElements/TextAreaField";

// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

const SupplierDeactivateModel = React.forwardRef((props, ref) => {
  // const dispatch = useDispatch();
  const { selectedRows } = props;
  const selectedRowsFlag = props.selectedRows.length > 0;
  const [formInputs, setFormInputs] = useState({
    supplierIds: [],
    remark: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (props.show) {
      setFormInputs({
        supplierIds: selectedRows?.map((item) => item?.userId) || [],
        remark: "", // Clear the TextAreaField when modal opens
      });
      setErrorMsg(""); // Clear error message if needed
    }
  }, [props.show, selectedRows]);

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    if (formInputs.remark !== "") {
      props.onHide();
    } else {
      setErrorMsg("*Please enter the remarks");
    }
    let payload = {
      documentNumber: formInputs.userId,
      remark: formInputs.remark,
    };
    props.onSubmit(payload);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        size="lg"
        ref={ref}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedRowsFlag ? (
              <i class="bi bi-person-x"></i>
            ) : (
              <i className="bi bi-exclamation-triangle"></i>
            )}
            {/* <p className="subText">
          At supplier_app, every person has a role, each with its own level of
          access.
        </p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <form className="space-y-4">
            {!selectedRowsFlag ? (
              // Display message if no rows are selected
              <Row className="text-center">
                <Col xs={12} className="mt-3">
                  <h5>Please select at least one row.</h5>
                </Col>
                <Col xs={12} className="d-flex justify-content-end mt-3">
                  <button
                    type="button"
                    onClick={props.onHide}
                    className="btn btn-primary"
                  >
                    OK
                  </button>
                </Col>
              </Row>
            ) : (
              // Display remarks  if rows are selected
              <>
                <Row>
                  <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
                    <div className="fw-medium mb-4">
                      <p> Do you want to deactivate the supplier? </p>{" "}
                      <Row>
                        {formInputs?.supplierIds.map((item, index) => (
                          <Col xs={5} lg={2} className="me-2" key={index}>
                            <p className="bg-table-status bg-table-new-status mb-1 px-2">
                              {item}
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <TextAreaField
                      className="inputBox"
                      label=""
                      name="remark"
                      placeholder={`Please enter the  remark`}
                      value={formInputs.remark}
                      onChange={onInputChange}
                    />
                  </Col>
                </Row>

                <Row className="justify-end">
                  <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                    <button
                      type="button"
                      onClick={() => props.onHide()}
                      className="btn btn-danger mt-4 w-full justify-center rounded-md"
                    >
                      No
                    </button>
                  </Col>
                  <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary mt-4 w-full justify-center rounded-md"
                    >
                      Yes
                    </button>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
                    <p className="errorMsg"> {errorMsg} </p>
                  </Col>
                </Row>
              </>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default SupplierDeactivateModel;
