import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import { getTodayDate } from "../../../../common/Validations";
import toast from "react-hot-toast";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";

const CreditPolicyRequestFormModal = React.forwardRef((props, ref) => {
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [getDropdownData, setDropdownData] = useState("");

  // const creditLimitExceedCustomerDropdown = [
  //   { id: 1, name: "Limit" },
  //   { id: 1, name: "Period" },
  // ];
  const availabilityOfSecurityChequeDropDown = [
    { id: 1, name: "Yes" },
    { id: 1, name: "No" },
  ];
  const PDCdetailsDropdown = [
    { id: 1, name: "Yes" },
    { id: 1, name: "No" },
  ];
  const [getData, setData] = useState();

  const [formInputs, setFormInputs] = useState({
    creditLimitExceedCustomer: "",
    nextPaymentScheduleDate: "",
    nextPaymentScheduleAmount: "",
    PDCdetails: "",
    limitToExtendedDays: "",
    periodToExtendedAmount: "",
    currentOutstandingAmount: "",
    creditLimitAmount: "",
    currentPeriodDays: "",
    creditPeriodDays: "",
    availabilityOfSecurityCheque: "",
    totalTurnover: "",
  });
  const [creditLimitData, setcreditLimitData] = useState([
    {
      id: 1,
      name: "Limit",
      status: true,
    },
    {
      id: 2,
      name: "Period",
      status: false,
    },
  ]);

  const [editorContent, setEditorContent] = useState("");

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  useEffect(() => {
    setFormInputs({
      sapDocumentNumber: props?.data?.sapDocumentNumber || "",
      salesOfficerName: props?.data?.createdByName,
      salesOfficerCode: props?.data?.createdBy,
      customerCode: props?.data?.customerCode,
      customerName: props?.data?.customerName,
      nextPaymentScheduleDate: getTodayDate(),
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    setData(props?.data);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };
  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    setFormInputs((formInputs) => ({
      ...formInputs,
      [getDropdownData?.textField]: getDropdownData?.name,
      [getDropdownData?.textCode]: getDropdownData?.code,
      [getDropdownData?.textDesc]: getDropdownData?.description,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  //handle save
  const handleSave = async () => {
    formInputs.instruction = editorContent;
    formInputs.cpHeaderStatus = "Pending with L1";
    // console.log(">>formInputs modal", formInputs);
    let obj = {
      data: formInputs,
    };

    await ItemService.addCreditPolicy(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "addCreditPolicy Order successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onSuccess();
        props.onHide();
      } else {
        console.log(">> Error: Loading addCreditPolicy");
      }
      // setDisabled(false);
    });
  };

  // This is for Text Editor
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  //handle cancel modal
  const handleCancel = () => {
    props.onHide();
  };
  //handle check box
  const handleTermsConditionCheckBox = (id) => {
    // Update the status of the item based on its id
    const updatedData = creditLimitData.map((item) => {
      if (item.id === id) {
        return { ...item, status: !item.status };
      }
      return item;
    });
    // Update the state with the modified data
    setcreditLimitData(updatedData);
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Credit Policy Request - {getData?.documentNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div
          // id="acc"
          className={`accordion-collapse collapse show ${
            isCardVisible1 ? "visible" : ""
          }`}
        >
          {/* 01 Basic Information */}
          <div className="accordion mb-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <Row className="accordion-button">
                  <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                    <i
                      className={`bi ${
                        isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                      }`}
                      onClick={toggleCardVisibility1}
                    ></i>
                  </Col>
                  <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                    <span className="iconLogo">CA</span>
                  </Col>
                  <Col xs={12} md={9} lg={9}>
                    <span className="headerTitle">Credit Policy Form</span>
                    <span className="subHeaderTitle">Credit Policy Form</span>
                  </Col>

                  {/* <Col xs={2} md={1} lg={1} className="">
                      <CommonFillter />
                    </Col> */}
                </Row>
              </h2>

              <div
                className={`accordion-collapse collapse show ${
                  isCardVisible1 ? "visible" : ""
                }`}
              >
                <div className="accordion-body">
                  <Row className="pt-2 pb-4">
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Customer Code"
                        name="sapDocumentNumber"
                        placeholder=""
                        value={getData?.sapDocumentNumber || ""}
                        onChange={onInputChange}
                        disabled={true}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Customer Name"
                        name="customerName"
                        placeholder=""
                        value={getData?.customerName || ""}
                        onChange={onInputChange}
                        disabled={true}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Sales Officer Name"
                        name="createdByName"
                        placeholder=""
                        value={getData?.createdByName || ""}
                        onChange={onInputChange}
                        disabled={true}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding d-flex gap-1 align-items-center"
                    >
                      {/* <ComboboxField
                        label=" Credit Check Exceed"
                        placeholder=""
                        data={creditLimitExceedCustomerDropdown}
                        id="creditLimitExceedCustomer"
                        iconClassName="dropdownIcon"
                        name="creditLimitExceedCustomer"
                        // code="creditLimitExceedCustomerCode"
                        // description="creditLimitExceedCustomerDesc"
                        setValue={formInputs?.creditLimitExceedCustomer}
                        getvalue={setDropdownData}
                        required={true}
                        // error={validation.country}
                      /> */}
                      {creditLimitData.map((item, i) => (
                        <div className="form-check" key={item.id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`gridCheck${item.id}`}
                            checked={item.status}
                            onChange={() =>
                              handleTermsConditionCheckBox(item.id)
                            }
                            disabled={item?.disabled}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`gridCheck${item.id}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <ComboboxField
                        label="Availability Of Security Cheque"
                        placeholder=""
                        data={availabilityOfSecurityChequeDropDown}
                        id="availabilityOfSecurityCheque"
                        iconClassName="dropdownIcon"
                        name="availabilityOfSecurityCheque"
                        // code="availabilityOfSecurityChequeCode"
                        // description="availabilityOfSecurityChequeDesc"
                        setValue={formInputs.availabilityOfSecurityCheque}
                        getvalue={setDropdownData}
                        required={true}
                        // error={validation.country}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Security Cheque Amount"
                        type="text"
                        name="securityChequeAmount"
                        placeholder=""
                        value={formInputs.securityChequeAmount || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <ComboboxField
                        label="PDC Details"
                        placeholder=""
                        data={PDCdetailsDropdown}
                        id="PDCdetails"
                        iconClassName="dropdownIcon"
                        name="PDCdetails"
                        // code="PDCdetailsCode"
                        // description="PDCdetailsDesc"
                        setValue={formInputs.PDCdetails}
                        getvalue={setDropdownData}
                        required={true}
                        // error={validation.country}
                      />
                    </Col>
                   
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Next Payment Schedule Amount"
                        type="text"
                        name="nextPaymentScheduleAmount"
                        placeholder=""
                        value={formInputs.nextPaymentScheduleAmount || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Next Payment Schedule Date"
                        name="nextPaymentScheduleDate"
                        type="date"
                        placeholder=""
                        value={formInputs.nextPaymentScheduleDate}
                        onChange={onInputChange}
                      />
                    </Col>

                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Limit to be Extended (Amount)"
                        name="limitToExtendedDays"
                        placeholder=""
                        value={formInputs.limitToExtendedDays || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Period to be Extended (Days)"
                        name="periodToExtendedAmount"
                        placeholder=""
                        value={formInputs.periodToExtendedAmount || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    ></Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Current Out Standing Amount"
                        name="currentOutstandingAmount"
                        placeholder=""
                        value={formInputs.currentOutstandingAmount || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Credit Limit Amount"
                        name="creditLimitAmount"
                        placeholder=""
                        value={formInputs.creditLimitAmount || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    ></Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Current Period Days"
                        name="currentPeriodDays"
                        placeholder=""
                        value={formInputs.currentPeriodDays || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Credit Period Days"
                        name="creditPeriodDays"
                        placeholder=""
                        value={formInputs.creditPeriodDays || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="commTopButtonRightLeftPadding"
                    >
                      <InputField
                        className="inputBox"
                        label="Total Turnover"
                        name="totalTurnover"
                        placeholder=""
                        value={formInputs.totalTurnover || ""}
                        onChange={onInputChange}
                        // disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      lg={12}
                      className="commTopButtonRightLeftPadding d-flex"
                    >
                      Credit
                      <p>
                      <span className="fw-bold mx-2">{creditLimitData[0]?.status && (<>{creditLimitData[0]?.name}</>)} {creditLimitData[1]?.status && (<> & {creditLimitData[1]?.name}</>)}</span>
                        {/* {creditLimitData
                          .filter((item) => item.status) // Filter items where status is true
                          .map((item) => (
                            <span className="fw-bold mx-2" key={item.id}>
                              {item.name},
                            </span> // Display only name of matched items
                          ))}                         */}
                        Exceed for the customer{" "}
                        <span className="fw-bold me-1">
                          {formInputs.customerName}
                        </span> 
                        (
                        <span className="fw-bold">
                          {formInputs.sapDocumentNumber}
                        </span>)
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          {/*  02 */}
          {/* Instructions 03*/}
          <div className="accordion mb-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <Row className="accordion-button">
                  <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                    <i
                      className={`bi ${
                        isCardVisible2 ? "bi-chevron-right" : "bi-chevron-down"
                      }`}
                      onClick={toggleCardVisibility2}
                    ></i>
                  </Col>
                  <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                    <span className="iconLogo">RE</span>
                  </Col>
                  <Col xs={8} md={9} lg={9}>
                    <span className="headerTitle">Instructions</span>
                    <span className="subHeaderTitle">Instructions Details</span>
                  </Col>
                </Row>
              </h2>

              <div
                className={`accordion-collapse collapse show ${
                  isCardVisible2 ? "visible" : ""
                }`}
              >
                <div className="accordion-body">
                  <Row className="p-4">
                    <Col xs={12} md={12} lg={12}>
                      <RichTextEditor
                        value={editorContent}
                        onChange={handleEditorChange}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <Row className="mt-4">
            <Col
              lg={{ span: 2, offset: 8 }}
              className="d-flex justify-content-end"
            >
              <button className="btnTable btn-danger" onClick={handleCancel}>
                Cancel
              </button>
            </Col>
            <Col lg={2} className="d-flex justify-content-end">
              <button className="btnTable" onClick={handleSave}>
                Save
              </button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default CreditPolicyRequestFormModal;
