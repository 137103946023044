import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputField } from "../../../../Components/formElements/InputField";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Service
import ItemService from "../../../../services/customer_appCommonService";
import ItemServiceV2 from "../../../../services/customer_v2_appCommonService";
import {
  getTodayDate,
  // validateRequired,
  // validPostalCode,
} from "../../../../common/Validations";
import MaterialSelectionModal from "./MaterialSelectionModal";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";

// const message = {
//   VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
//   VALID_MAIL_ID: "Please enter a valid email address.",
//   VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
//   VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
//   VALID_ALTERNATE_CONTACT_NUMBER:
//     "Please enter a valid alternate contact number.",
//   VALID_PURPOSE_JUSTIFICATION:
//     "Please ensure the justification is under 500 characters.",
//   VALID_POSTAL_CODE:
//     "Enter a 6-character postal code with uppercase letters and numbers only",
//   VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
//   VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
//   EXIStS_MAIL_ID: "Email already exists",
//   EXIStS_CONTACT_NUMBER: "Contact Number already exists",
//   EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
//   EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
//   FILL_REQUIRED_FIELD: "Please fill the required field",
//   ALTERNATE_MAIL_ID: "Email ID is same",
//   ALTERNATE_CONTACT_NUMBER: "Contact number is same",
//   GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
// };

const OrderSchedulingModal = React.forwardRef((props, ref) => {
  let userData = useSelector((state) => state?.user?.data);

  let productDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "120px",
    },
    {
      name: "Material",
      selector: (row) => row.materialLongText,
      sortable: true,
      width: "240px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialLongText} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "UOM",
      selector: (row) => row.uOM || "--",
      sortable: true,
      width: "100px",
    },

    {
      name: "Qty",
      selector: (row) => row.orderQuantityLoad || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderQuantityLoad"
          placeholder=""
          value={row.orderQuantityLoad || ""}
          // disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="unitPrice"
          placeholder=""
          value={row.unitPrice || ""}
          // disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Remarks",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="dispatchRemarks"
          placeholder=""
          value={row.dispatchRemarks || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Text")}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Dispatch Date",
      selector: (row) => row.dispatchDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="dispatchDate"
          type="date"
          placeholder=""
          value={row.dispatchDate || getTodayDate("")}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    // {
    //   name: "Basic Price",
    //   selector: (row) => row.basicPrice || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="rate"
    //       placeholder=""
    //       value={row.basicPrice || ""}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },
    // {
    //   name: "Basic Amt",
    //   selector: (row) => row.basicAmount || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="rate"
    //       placeholder=""
    //       value={row.basicAmount || ""}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },
    // {
    //   name: "CGST",
    //   selector: (row) => row.cgstValue || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="rate"
    //       placeholder=""
    //       value={row.cgstValue || ""}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },
    // {
    //   name: "SGST",
    //   selector: (row) => row.sgstValue || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="rate"
    //       placeholder=""
    //       value={row.sgstValue || ""}
    //       // disabled={true}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },
    // {
    //   name: "GST",
    //   selector: (row) => row.gstValue || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="rate"
    //       placeholder=""
    //       value={row.gstValue || ""}
    //       // disabled={true}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },
    // {
    //   name: "Net Value",
    //   selector: (row) => row.netValue || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="netValue"
    //       placeholder=""
    //       value={row.rate || ""}
    //       // disabled={true}
    //       // onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //       disabled={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "130px",
    // },

    // {
    //   name: "CGST%",
    //   selector: (row) => row.cgst || "--",
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Shipping Addr",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <TextAreaField
          className="inputBox mt-1"
          name="shippingAddress"
          placeholder=""
          value={row.shippingAddress || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
          disabled={false}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Shipping Postal Code",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="shippingPostalCode"
          placeholder=""
          value={row.shippingPostalCode || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
          disabled={false}
        />
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "CGST%",
    //   selector: (row) => row.cgst || "--",
    //   sortable: true,
    //   width: "100px",
    // },
    // {
    //   name: "SGST%",
    //   selector: (row) => row.sgst || "--",
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button className="" onClick={() => removeProductDetails(row.local_id)}>
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];

  const [modalShow, setModalShow] = useState(false);
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [getDropdownData, setDropdownData] = useState("");
  const [getProductDetailsData, setProductDetailsData] = useState([]); //productDetailsData
  const [getProductDetailsCol] = useState(productDetailsCol);

  const [disable, setDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [getCustomer, setCustomer] = useState([]);
  const [getCompanyCode, setCompanyCode] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [getProjects, setProjects] = useState([]);
  const [getCustomerDetails, setCustomerDetails] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [getProjectDetails, setProjectDetails] = useState([]);
  const [getProjectDetailsSave, setProjectDetailsSave] = useState([]);
  const [customerID, setCustomerID] = useState("");

  /* Modal Function */
  const addProductDetails = () => {
    setModalShow(true);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [formInputs, setFormInputs] = useState({
    orderStatus: "Pending",
    orderPlacedBy: "",
    customerDetails: "",
    emailAddress: "",
    deliveryAddress: "",
    customerDetailsIfOther: "",
    createdDate: new Date(),
    createdBy: userData?.userId,
    dispatchDate: getTodayDate(""),
  });

  /* pop failure modal */
  // const [validation, setValidation] = useState({
  //   shippingPostalCode: "",
  // });

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  useEffect(() => {
    getMasterData();

    setFormInputs({
      orderStatus: "Pending",
      orderPlacedBy: "",
      customerDetails: "",
      emailAddress: "",
      deliveryAddress: "",
      customerDetailsIfOther: "",
      createdDate: new Date(),
      createdBy: userData?.userId,
      dispatchDate: getTodayDate(""),
      // shippingAddress: "",
    });

    setProductDetailsData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);

    if (getDropdownData?.textCode === "customerCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapDocumentNumber: getDropdownData?.sapDocumentNumber,
      }));
      getProjectNameData(getDropdownData?.id);
      getCustomerDetail(getDropdownData?.id);
      setCustomerID(getDropdownData?.id);
      setFormInputs((formInputs) => ({ ...formInputs, projectName: "" }));
      setProjectDetails([]);
      setProjectDetailsSave([]);
    } else if (getDropdownData?.textCode === "projectNameCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapProjectCode: getDropdownData?.sapProjectCode,
      }));
      // getProjectDetailsByCode(getDropdownData?.id);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const removeProductDetails = (local_id) => {
    setProductDetailsData((prevPrograms) => {
      const filteredData = prevPrograms.filter(
        (program) => program.local_id !== local_id
      );
      // Reset and update `local_id` sequentially
      const updatedData = filteredData.map((item, index) => ({
        ...item,
        local_id: index + 1, // Reset local_id after removal
        orderQuantityLoad: "",
      }));
      return updatedData;
    });
  };

  const handleInputChange = (local_id, event, type) => {
    const { name, value } = event.target;

    if (type === "Number" && isNaN(value)) {
      toast.error("Please enter a valid number");
      return;
    }

    setProductDetailsData((prevRows) =>
      prevRows.map((row) => {
        if (row.local_id === local_id) {
          const updatedRow = { ...row, [name]: value };

          // console.log(">> orderQuantityLoad", updatedRow);

          // Ensure orderQuantityLoad, unitPrice, reversePercentage, basicPrice, and basicAmount are valid numbers
          const orderQuantityLoad =
            parseFloat(updatedRow?.orderQuantityLoad) || 0;
          const unitPrice = parseFloat(updatedRow?.unitPrice) || 0;

          const reversePercentage = parseFloat(updatedRow.reversePercentage);
          const cgst = parseFloat(updatedRow.cgst);
          const sgst = parseFloat(updatedRow.sgst);
          const gst = parseFloat(cgst) + parseFloat(sgst);
          updatedRow.gst = gst;

          updatedRow.basicPrice =
            parseFloat(unitPrice) / parseFloat(reversePercentage);

          // Calculate basicAmount if basicPrice & orderQuantityLoad are valid
          if (!isNaN(updatedRow.basicPrice) && !isNaN(orderQuantityLoad)) {
            updatedRow.basicAmount = (
              updatedRow.basicPrice * orderQuantityLoad
            ).toFixed(2);
          }

          // Calculate rate if orderQuantityLoad & unitPrice are valid
          if (!isNaN(orderQuantityLoad) && !isNaN(unitPrice)) {
            updatedRow.rate = (orderQuantityLoad * unitPrice).toFixed(2);
          }

          // Calculate basicPrice if reversePercentage & unitPrice are valid and reversePercentage is not zero
          if (
            !isNaN(reversePercentage) &&
            !isNaN(unitPrice) &&
            reversePercentage !== 0
          ) {
            updatedRow.basicPrice = (unitPrice / reversePercentage).toFixed(2);
          }

          if (!isNaN(updatedRow.basicAmount)) {
            updatedRow.cgstValue = (
              (cgst * updatedRow.basicAmount) /
              100
            ).toFixed(2);
          }

          if (!isNaN(sgst) && !isNaN(updatedRow.basicAmount)) {
            updatedRow.sgstValue = (
              (sgst * updatedRow.basicAmount) /
              100
            ).toFixed(2);
          }

          updatedRow.gstValue = ((gst * updatedRow.basicAmount) / 100).toFixed(
            2
          );
          updatedRow.netValue = parseFloat(
            parseFloat(updatedRow.gstValue) + parseFloat(updatedRow.basicAmount)
          ).toFixed(2);

          // console.log(">> updatedRow", updatedRow);

          return updatedRow;
        }
        return row;
      })
    );
  };

  // const onInputChange = ({ target: { name, value } }) => {
  //   const errors = {};
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  //   // console.log("onInputChange => formInputs", formInputs);
  //   validPostalCode(name === "shippingPostalCode" ? value : "")
  //     ? (errors.shippingPostalCode = "")
  //     : (errors.shippingPostalCode = message.VALID_POSTAL_CODE);
  //   setValidation((prev) => ({
  //     ...prev,
  //     [name]: value === "" ? "" : errors[name] || "",
  //   }));
  // };

  /* handle save */
  const handleSave = async () => {
    // const requiredFields = [];
    // const anyFieldNotEmpty = Object.values(validation).some(
    //   (value) => value !== null && value !== ""
    // );
    // if (anyFieldNotEmpty) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   return;
    // }

    // if (!validateRequired(formInputs.shippingPostalCode)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setValidation((prev) => ({
    //     ...prev,
    //     shippingPostalCode: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // if (!validPostalCode(formInputs.shippingPostalCode)) {
    //   requiredFields.push(`${message.VALID_POSTAL_CODE}`);
    //   setValidation((prev) => ({
    //     ...prev,
    //     shippingPostalCode: message.VALID_POSTAL_CODE,
    //   }));
    //   return;
    // }

    if (getProductDetailsData.length === 0) {
      toast.error("Please add atleast one record!", {
        duration: 4000,
        position: "top-right",
      });
      return;
    }

    const finalobj = getProductDetailsData.map((item) => ({
      ...item,
      ...formInputs,
    }));

    const filteredData = finalobj
      .map(({ _id, ...rest }) => rest) // Remove _id field
      .filter((item) => item.orderQuantityLoad?.trim() !== "");

    if (filteredData.length === 0) {
      toast.error("Bag value should not be empty !!", {
        duration: 4000,
        position: "top-right",
      });
      return;
    }

    const obj = {
      data: {
        companyCode: filteredData[0].companyCode,
        companyCodeDesc: filteredData[0].companyCodeDesc,
        customerCode: filteredData[0].customerCode,
        customerName: filteredData[0].customerName,
        deliveryName: filteredData[0].projectName,
       
        address: getCustomerDetails?.address,
        address2: getCustomerDetails?.address2,
        address3: getCustomerDetails?.address3,
        state: getCustomerDetails?.state,
        cityPostalCode: getCustomerDetails?.cityPostalCode,
        certifications: getCustomerDetails?.certifications,

        // shippingAddress: filteredData[0].shippingAddress,
        // shippingPostalCode: filteredData[0].shippingPostalCode,
        projectAddress: getProjectDetailsSave?.projectAddress,
        projectAddress2: getProjectDetailsSave?.projectAddress2,
        projectCity: getProjectDetailsSave?.projectCity,
        projectCityPostalCode: getProjectDetailsSave?.projectCityPostalCode,
        projectCode: getProjectDetailsSave?.projectCode,
        projectCodeDesc: getProjectDetailsSave?.projectCodeDesc,
        projectCountry: getProjectDetailsSave?.projectCountry,
        projectName: getProjectDetailsSave?.projectName,
        projectState: getProjectDetailsSave?.projectState,
        items: filteredData,
      },
    };

    // console.log(">>obj",obj)

    setDisabled(true);
    await ItemServiceV2.addOrderScheduleBookingV2(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onHide();
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {};
    await ItemService.getCustomers(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setCustomer(item?.data);
      } else {
        setCustomer([]);
        console.log(">> Error: Loading getCustomers");
      }
    });

    let obj1 = {
      codeType: "companyCode",
    };
    await ItemService.getMasterData(obj1).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setCompanyCode(item?.data);
      } else {
        setCompanyCode([]);
        console.log(">> Error: Loading Company Code");
      }
    });
  };

  // Onselect of Country state will load
  const getCustomerDetail = async (userId) => {
    let obj1 = {
      documentNumber: userId,
    };
    await ItemService.getCustomerDetail(obj1).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        // const updatedData = item?.data?.map((item) => ({
        //   ...item,
        //   name: `${item.id} - ${item.projectAddress}`, // Combine id and name
        // }));

        setCustomerDetails(item?.data[0] || null);
      } else {
        setCustomerDetails([]);
        console.log(">> Error: Loading getProjectName");
      }
    });
  };

  const getProjectNameData = async (userId) => {
    let obj1 = {
      userId: userId,
    };
    await ItemService.getProjectName(obj1).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        const updatedData = item?.data?.map((item) => ({
          ...item,
          name: `${item.id} - ${item.projectAddress}`, // Combine id and name
        }));

        setProjects(updatedData || []);
      } else {
        setProjects([]);
        console.log(">> Error: Loading getProjectName");
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const getProjectDetailsByCode = async (code) => {
    let obj = {
      userId: customerID,
      projectCode: code,
    };
    await ItemService.getProjectDetail(obj).then((item) => {
      // console.log(">> getProjectDetail!", item);
      if (item?.status === 200) {
        if (item?.data.length > 0) {
          setProjectDetails(
            item?.data[0]?.getLocations ? item?.data[0]?.getLocations[0] : []
          );
          setProjectDetailsSave(item?.data[0]);
        } else {
          setProjectDetails([]);
        }
      } else {
        setProjectDetails([]);
        console.log(">> Error: Loading getProjectDetail");
      }
    });
  };

  const handleGetSelectedMaterial = (selectedData) => {
    setProductDetailsData((prevData) => {
      const updatedData = [
        ...prevData,
        ...selectedData.map((item, index) => ({
          ...item,
          local_id: index + 1, // Continue local_id from previous data
          dispatchDate: getTodayDate(""),
          orderStatus: "Pending",
          orderQuantityLoad: "",
          shippingAddress: [
            getCustomerDetails?.address,
            getCustomerDetails?.address2,
            getCustomerDetails?.address3,
            getCustomerDetails?.state,
          ]
            .filter(Boolean) // Removes falsy values (null, undefined, empty strings)
            .join(" "),
          shippingPostalCode: getCustomerDetails?.cityPostalCode,

          customerAddress: [
            getCustomerDetails.address,
            getCustomerDetails.address2,
            getCustomerDetails.address3,
            getCustomerDetails.state,
          ]
            .filter(Boolean) // Removes falsy values (null, undefined, empty strings)
            .join(" "),
          customerPostalCode: getCustomerDetails.cityPostalCode,          
          certifications:getCustomerDetails?.certifications,
  
        })),
      ];

      // Remove duplicates based on _id
      const uniqueData = Array.from(
        new Map(updatedData.map((item) => [item._id, item])).values()
      );

      // Sort based on `local_id`
      const sortedData = uniqueData
        .map((item, index) => ({
          ...item,
          local_id: index + 1, // Reset local_id sequentially
        }))
        .sort((a, b) => a.local_id - b.local_id);

      return sortedData;
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Order Booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col xs={12} md={2} lg={2} className="headerButtonAlign">
            <button className="btnInfo" onClick={handleSave} disabled={disable}>
              Save
            </button>
          </Col>
        </Row>
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CD</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <span className="headerTitle">Customer Details</span>
                  <span className="subHeaderTitle">Customer Details</span>
                </Col>
              </Row>
            </h2>

            <div
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Company Name"
                      placeholder=""
                      data={getCompanyCode}
                      id="companyCode"
                      iconClassName="dropdownIcon"
                      name="company"
                      code="companyCode"
                      description="companyCodeDesc"
                      setValue={formInputs.companyCode || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Customer Name"
                      placeholder=""
                      data={getCustomer}
                      id="customerName"
                      iconClassName="dropdownIcon"
                      name="customerName"
                      code="customerCode"
                      description="customerNameDesc"
                      setValue={formInputs.customerName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  {/* <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Delivery Name"
                      placeholder=""
                      data={getProjects}
                      id="projectName"
                      iconClassName="dropdownIcon"
                      name="projectName"
                      code="projectNameCode"
                      description="projectNameDesc"
                      setValue={formInputs.projectName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col> */}

                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <TextAreaField
                      className="inputBox"
                      label="Delivery Address"
                      name="projectAddress"
                      value={[
                        getCustomerDetails?.address,
                        getCustomerDetails?.address2,
                        getCustomerDetails?.address3,
                        getCustomerDetails?.state,
                        getCustomerDetails?.cityPostalCode,
                      ]
                        .filter(Boolean) // Removes falsy values (null, undefined, empty strings)
                        .join(" ")}
                      // onChange={onInputChange}
                      disabled={true}
                    />
                  </Col>
                  {/* <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <TextAreaField
                      className="inputBox"
                      label="Shipping Address"
                      id="shippingAddress"
                      name="shippingAddress"
                      type="text"
                      // value={getProjectDetails?.projectLocation || "" || ""}
                      onChange={onInputChange}
                      disabled={false}
                    />
                  </Col> */}
                  {/* <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <InputField
                      className="inputBox"
                      label="Shipping Postal Code"
                      id="shippingPostalCode"
                      name="shippingPostalCode"
                      type="text"
                      // value={getProjectDetails?.projectLocation || "" || ""}
                      onChange={onInputChange}
                      disabled={false}
                      error={validation.shippingPostalCode}
                    />
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible2 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility2}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CP</span>
                </Col>
                <Col xs={12} md={9} lg={9}>
                  <span className="headerTitle">Product Details</span>
                  <span className="subHeaderTitle">Product Details</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                  <button className="btnTable" onClick={addProductDetails}>
                    <i className="bi bi-plus-lg"></i> Add
                  </button>
                </Col>
              </Row>
            </h2>
            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible2 ? "visible" : ""
              }`}
              // aria-labelledby="headingOne"
              // data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <DataTable
                      title=""
                      columns={getProductDetailsCol}
                      data={getProductDetailsData}
                      // progressPending={loading}
                      // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalRows}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // selectableRows
                      // onSelectedRowsChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <MaterialSelectionModal
          show={modalShow}
          onHide={() => hideModal()}
          onselectedcatdata={handleGetSelectedMaterial}
        />
      </Modal.Body>
    </Modal>
  );
});

export default OrderSchedulingModal;
