/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import ItemService from "../../services/supplier_appCommonService";
import toast from "react-hot-toast";
import ConstantsList from "../../common/SupplierConstants";
import CustomerConstantsList from "../../common/CustomerConstants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../common/Validations";
import { InputField } from "../../Components/formElements/InputField";
import SupplierConstants from "../../common/SupplierConstants";
import SuccessModal from "../../Components/Modal/supplier_app/SuccessModal";
// import { appConfigFunc } from "../../redux/feature/appSlice";

//Redux
import { useSelector } from "react-redux";

const Login = () => {
  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // let [email, setEmail] = useState("");
  let [forgotEmail, setForgotEmail] = useState("");
  // let [password, setPassword] = useState("");
  // let [headerName, setHeaderName] = useState();
  // let [headerLogo, setHeaderLogo] = useState();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [button, setButton] = useState(true);

  const [formInputs, setFormInputs] = useState({
    email: "",
    password: "",
  });


  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");

  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // USER LOGIN
  const handleLogin = () => {
    // event.preventDefault();
    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    console.log(">> formInputs", formInputs);

    let obj = {
      email: formInputs.email,
      password: formInputs.password,
    };

    if (formInputs.email === "" || formInputs.password === "") {
      toast.error("Email and Password not be Empty !!", {
        duration: 3000,
        position: "top-right",
      });
    } else {
      ItemService.login(obj).then((item) => {
        console.log(">> item >>", item);
        if (item?.status === 200) {
          toast.success(item?.message || "User registered successfully", {
            duration: 2000,
            position: "top-right",
          });
          if (item?.data?.defaultAppCode === "ALL") {
            window.location = ConstantsList.APP_HOME;
          } else if (item?.data?.defaultAppCode === "CUSTOMER") {
            window.location = CustomerConstantsList.HOME_INTRO;
          } else {
            window.location = ConstantsList.HOME_INTRO;
          }
        } else {
          toast.error(item?.message || "Invalid input", {
            duration: 4000,
            position: "top-right",
          });
        }
      });
    }

    toast.dismiss(toastId);
  };

  const handleForgotPass = async () => {
    // await new Promise(r => setTimeout(r, 1000))
    setForgotPassword(!forgotPassword);
    setForgotEmail("");
    setSuccessMsg("");
    setErrorMsg("");
  };

  const confirmForgotPassword = async () => {
    if (validateEmail(forgotEmail)) {
      let obj = { queryName: "ValidateEmail", validate: forgotEmail };
      await ItemService.checkValidation(obj).then(async (item) => {
        // console.log(">> item", item);
        if (item.status === 200) {
          setButton(false);
          setErrorMsg("Email ID Not Found");
          setSuccessMsg("");
        } else {
          setButton(true);
          setSuccessMsg("Email ID Verified");
          setErrorMsg("");
          await new Promise((r) => setTimeout(r, 1000));
          sendMail();
        }
      });
      return;
    }

    // setForgotPassword(!forgotPassword);
  };

  const sendMail = async () => {
    let obj = { email: forgotEmail };
    await ItemService.forgotPassword(obj).then(async (item) => {
      // console.log(">> item", item);
      if (item.status === 200) {
        setErrorMsg("");
        setSuccessMsg("Mail Sent");
        successModalData.successMsg = "Mail Sent, Please check your inbox!";
        setsucceModalData(successModalData);
        setNextNavigate(SupplierConstants.LOGIN);
        onSuccessmodel();
        handleForgotPass();
      } else {
        setSuccessMsg("");
        setErrorMsg("Something went wrong !!");
      }
    });
  };

  const onInputChange = ({ target: { name, value } }) => {
    setForgotEmail(value);

    if (validateEmail(name === "forgotEmail" ? value : "")) {
      setErrorMsg("");
      setSuccessMsg("");
      setButton(false);
    } else {
      setErrorMsg("Please enter a valid email address.");
      setSuccessMsg("");
      setButton(true);
    }
  };

  const onInputChangeLogin = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  const handleCheckValidation = async ({ target: { name, value } }) => {
    let obj = {};
    if (value) {
      if (validateEmail(value)) {
        obj = { queryName: "ValidateEmail", validate: value };
        await ItemService.checkValidation(obj).then((item) => {
          // console.log(">> item", item);

          if (item.status === 200) {
            setErrorMsg("Email ID Not Found");
            setSuccessMsg("");
          } else {
            setSuccessMsg("Email ID Verified");
            setErrorMsg("");
          }
        });
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // console.log("Enter key pressed!");
        handleLogin(event);
      }
    };

    // Attach event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInputs]);

  useEffect(() => {
    console.log(">> Login Page");
    const token = localStorage.getItem("token");
    if (token) {
      window.location = "/home";
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isEmpty = Object.keys(appConfig).length === 0;

    if (isEmpty) {
      console.log("appConfig is empty");
      // toast.error("Please configured the application !!", {
      //   duration: 10000,
      //   position: "top-center",
      // });
    } else {
      console.log("appConfig is not empty");
    }

    // eslint-disable-next-line
  }, [appConfig]);

  const handleRegister = () => {
    navigate(ConstantsList.SELF_REGISTRATION);
  };
  const handleCustomerRegister = () => {
    // navigate(CustomerConstantsList.CUSTOMER_V1_REGISTER);
    navigate(CustomerConstantsList.CUSTOMER_V1_REGISTER_LOGIN);
  };

  const appStyle = {
    backgroundImage: `url(${process.env.REACT_APP_LOGIN_BG_IMG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    height: "124vh",
    margin: "0",
    position: "relative" /* Required for positioning the overlay */,
  };

  return (
    <div className="loginBackground" style={appStyle}>
      <div className="overlay"></div>
      <main>
        <div className="">
          <section className="section register d-flex flex-column align-items-center justify-content-center pt-2">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-12 col-sm-12 d-flex flex-column">
                  <h1 className=" pt-sm-0 d-flex justify-content-center align-items-end">
                    {process.env.REACT_APP_LOGIN_HEADER_TITLE_STATUS ===
                      "1" && (
                      <>
                        <img
                          className="img-fluid headerLogo"
                          src={appConfig?.productLogo}
                          alt="productLogo"
                        />
                        <div className="d-flex-column ms-2 mb-2 text-left">
                          <h1 className="m-0 title">{appConfig?.productTitle}</h1>
                          <p className="m-0 fs-5 ">
                            {appConfig?.productDescription}
                          </p>
                        </div>
                      </>
                    )}

                    {process.env.REACT_APP_LOGIN_HEADER_TITLE_STATUS ===
                      "0" && (
                      <>
                        <div className="mt-6">&nbsp;</div>
                      </>
                    )}
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-9 col-sm-8 d-flex flex-column align-items-center justify-content-center pt-lg-5 pt-sm-0"></div>
                <div className="col-lg-4 col-md-3 col-sm-4 d-flex flex-column innerLoginBox">
                  {!forgotPassword && (
                    <div className="card">
                      <div className="card-body">
                        <div
                          className="col-12"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {process.env.REACT_APP_LOGIN_HEADER_TITLE_STATUS ===
                          "1" ? (
                            <>
                              <img
                                src={appConfig?.customerLogo1}
                                alt=""
                                className="loginLogo"
                                style={{ width: "150px" }}
                              />
                              {/* <div className="d-flex-column ms-2">
                                <p className="m-0 fs-3 fw-medium text-white">
                                  ATILA
                                </p>
                                <p className="m-0 fw-medium text-white">
                                  Revolutionizing Your Supply Chain
                                </p>
                              </div> */}
                            </>
                          ) : (
                            <>
                              <img
                                src={appConfig?.productLogo}
                                alt=""
                                className="loginLogo"
                                style={{ width: "270px" }}
                              />
                            </>
                          )}
                        </div>

                        <div className="pb-2">
                          <div className="card-title text-left">
                            {t("msg_login.msg_title1")}
                            <h6>{t("msg_login.msg_title2")}</h6>
                          </div>
                        </div>

                        <div className="row g-3 needs-validation">
                          <div className="col-12 leftAl">
                            <label className="form-label loingFont">
                              {t("msg_login.msg_username")}
                            </label>
                            <div className="input-group has-validation">
                              <input
                                type="text"
                                name="email"
                                value={formInputs.email || ""}
                                className="form-control"
                                id="email"
                                onChange={onInputChangeLogin}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12 leftAl">
                            <label className="form-label loingFont">
                              {t("msg_login.msg_password")}
                            </label>
                            <div className="forgot-pass">
                              <button
                                type="button"
                                className="form-check-label text-secondary"
                                onClick={handleForgotPass}
                              >
                                {" "}
                                {t("msg_login.msg_password_forgot")}
                              </button>
                            </div>
                            <div className="input-group has-validation">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={formInputs.password || ""}
                                className="form-control"
                                id="password"
                                onChange={onInputChangeLogin}
                                required
                              />
                              <button
                                type="button"
                                aria-label="Toggle password visibility"
                                className="eyeCss absolute inset-y-0 right-0 flex items-center justify-center pr-3 h-full"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <i className="bi bi-eye-fill"></i>
                                ) : (
                                  <i className="bi bi-eye-slash-fill"></i>
                                )}
                              </button>
                            </div>
                          </div>

                          {/* <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                <label className="form-check-label" htmlFor="rememberMe">Stay signed in</label>
                            </div>
                          </div> */}
                          <div className="col-12 mt-5">
                            <button
                              className="btn btn-primary w-100"
                              onClick={handleLogin}
                            >
                              {t("msg_login.msg_login")}
                            </button>
                          </div>

                          {process.env.REACT_APP_CUSTOMER_V1 === "0" ? (
                            <div className="col-12 mt-2 mb-2">
                              <button
                                className="btn btn-primary w-100"
                                onClick={handleRegister}
                              >
                                {t("msg_login.msg_self_register")}
                              </button>
                            </div>
                          ) : (
                            <div className="col-12 mt-2 mb-2">
                              <button
                                className="btn btn-primary w-100"
                                onClick={handleCustomerRegister}
                              >
                                Registration
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {forgotPassword && (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pb-2">
                          <h5 className="card-title text-left">
                            Reset your password
                          </h5>
                          <p className="subText">
                            {" "}
                            Enter the email address associated with your account
                            and we'll send you a link to reset your password.{" "}
                          </p>
                        </div>

                        <div className="row g-3 needs-validation">
                          <div className="col-12 leftAl">
                            <InputField
                              className="inputBox"
                              label="Email"
                              name="forgotEmail"
                              placeholder=""
                              value={forgotEmail || ""}
                              onChange={onInputChange}
                              onBlur={handleCheckValidation}
                              required={true}
                              success={successMsg}
                              error={errorMsg}
                            />
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-primary w-100"
                              onClick={confirmForgotPassword}
                              disabled={button}
                            >
                              Continue
                            </button>
                            <div className="form-signin">
                              <a
                                href="#"
                                className="form-signin-lable"
                                onClick={handleForgotPass}
                              >
                                {" "}
                                Return to sign in
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-12">
                    {/* <p className="small mb-0">
                      <a
                        href={ConstantsList.SUPPLIER_MASTER_CREATE}
                        className="form-check-label"
                      >
                        Self Registration
                      </a>
                    </p> */}

                    {/* <div className="footer pt-2">
                      <a href="/" className='footerLink'> 
                        &copy; supplier_app
                      </a>
                      <a href="/" className='footerLink'> 
                        Privacy & terms
                      </a> 
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {process.env.REACT_APP_LOGIN_HEADER_TITLE_STATUS === "1" ? (
              <div className="w-100 text-end mr-3">
                <p className="copyright">{appConfig?.companyTitle}</p>
              </div>
            ) : (
              <></>
            )}
          </section>
        </div>
      </main>

      {/* Success Modal */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />
    </div>
  );
};

export default Login;
