
/* APPLICATION ROUTE CONSTANTS */

module.exports = Object.freeze({
  
    ROW_PER_PAGE : [10, 50, 100, 300, 500],

    // Status
    STATUS_REGISTER:'Register',

    /* Routers Link supplier_app */
    APP_HOME: '/applications',
    HOME: '/home',
    
    HOME_INTRO:'/customer-intro',
    DASHBOARD: '/customer-dashboard',
    INBOX: '/customer-inbox',
    OUTBOX: '/customer-outbox',

    /*Customer*/
    CUSTOMER_REGISTRATION: '/customer-registration',
    CUSTOMER_LIST: '/customer-list',
    CUSTOMER_MASS_UPLOAD: '/customer-mass-upload',
    CUSTOMER_MASTER_CREATE: '/customer-master-creation',
    CUSTOMER_SELF_REGISTRATION: '/customer-self-registration',
    CUSTOMER_DETAILED_PAGE_CREATE:'/customer-details',
    CUSTOMER_DETAILED_PAGE_WORKFLOW:'/customer-details-workflow',

    /*PreSales*/

    PRESALES_INQUIRY_CREATION:'/create-inquiry', /*  SOURCING_CREATE_RFQ: '/create-request-for-quotation',*/
    PRESALES_INQUIRY_LIST:'/inquiry',  /*SOURCING_BUYER_REQUEST_FOR_QUOTATION: '/buyer-request-for-quotation',*/
    PRESALES_INQUIRY_DETAILS:'/inquiry-details', /*SOURCING_BUYER_RFQ_DETAILS: '/buyer-rfq-details', */
    PRESALES_CUSTOMER_CREATE_QUOTATION:'/customer-create-quotation', /*SOURCING_CREATE_QUOTATION:'/create-quotation',*/
    PRESALES_CUSTOMER_QUOTATION_DETAILS:'/customer-quotation-details', /*SOURCING_QUOTATION_DETAILS:'/quotation-details',*/
    PRESALES_CUSTOMER_QUOTATION_LIST: '/customer-quotation', /*SOURCING_VENDOR_QUOTATION_LIST: '/supplier-quotation'*/


})

