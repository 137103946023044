import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import DataTable from "react-data-table-component";

const AdvancePaymentRequestCreate = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const [formInputs, setFormInputs] = useState({
    PoNumber: "",
  });

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const data = [
    {
      poNumber: "Po Number",
      poDate: "Po Date",
      poValue: "Po Value",
      currency: "Currency",
      advanceAmount: "Advance Amount",
      advancePercentage: "Advance Percentage",
      downPaymentFlag: "Down Payment Flag",
      dPRStatus: "DPR Status",
    },
  ];
  const columns = [
    {
      name: "icon",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Po Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Po Date",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Po Value",
      cell: (row) => <>\ </>,
      width: "150px",
    },
    {
      name: "Currency",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Amount",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Percentage",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Down Payment Flag",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "DPR Status",
      cell: (row) => <></>,
      width: "150px",
    },
  ];
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">AdvancePaymentRequestCreate </h2>
                <p className="subText">AdvancePaymentRequestCreate </p>
              </Col>
              <Col xs={12} md={2} lg={2} className="mb-2">
                <button className="btnTable">Create</button>
              </Col>
              <hr />
            </Row>

            {/* Advance PaymentRequest Create -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CR</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Advance PaymentRequest Create
                      </span>
                      <span className="subHeaderTitle">
                        Advance PaymentRequest Create
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Po Number"
                          name="PoNumber"
                          placeholder=""
                          value={formInputs.PoNumber || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable w-25">get Details</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Advance PaymentRequest Create -2*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">APR Details</span>
                      <span className="subHeaderTitle">
                        Advance PaymentRequest Create
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={columns}
                          data={data}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default AdvancePaymentRequestCreate;
