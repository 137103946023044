import React from "react";

export const SearchField = ({
  name,
  id,
  placeholder,
  className,
  value,
  onClick,
  onChange,
}) => {
  return (
    <>
      <div className="search-bar">
        <div className="search-form d-flex align-items-center">
          <input
            id={id}
            type="text"
            className={className}
            name={name}
            value={value}
            placeholder={"Search"}
            title="Enter search keyword"
            onChange={onChange}
          />
          <button
            type="submit"
            title="Search"
            onClick={onClick}
          >
            <i className="bi bi-search"></i>
          </button>
        </div>
      </div>
    </>
  );
};
