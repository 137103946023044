import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
// import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/SupplierConstants";
// import CustomerConstants from "../../../../common/CustomerConstants";
//import { formatDateTime } from "../../../../common/Validations";
// import { addUserID } from "../../../../redux/feature/userSlice";
//import configFromEnv from "../../../../services/configuration";
// import Moment from "react-moment";

const TransportationList = () => {
//   const navigate = useNavigate();
  // const dispatch = useDispatch();

  localStorage.setItem("menu", "");
  localStorage.setItem("submenu", "");

  let userListData = useSelector((state) => state?.user.userList); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState([]);

  const materialMasterData = [
    {
        sNo:1,
        materialCode: "FG005",
        materialDescription:"Manufactured Sand(U) 0-4.76mm",
        materialGroup:"RM",
        uoM:"Ton",
        plantName:"Barathi",
        
    },
    {
        sNo:2,
        materialCode: "FG002",
        materialDescription:"Aggregates 20mm",
        materialGroup:"RM",
        uoM:"Ton",
        plantName:"Barathi",

    },
]

//   const navigateCreateMaterial = () => {
//     navigate(CustomerConstants.CUSTOMER_V1_MATERIAL_MASTER_CREATION);
//   };

  // const detailedPage = () => {
  //   //dispatch(addUserID(userId));
  //   navigate(Constants.CUSTOMER_V1_MATERIAL_MASTER_DETAILS);
  // };

  const userListColInfo = [
    {
        name: "S no",
        selector: (row) => row.sNo,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.sNo}</span>
        ),
        width: "120px",
    },
    {
        name: "Material Code",
        selector: (row) => row.materialCode,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.materialCode}</span>
        ),
        width: "150px",
    },
    {
        name: "Material Description",
        selector: (row) => row.materialDescription,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.materialDescription}</span>
        ),
        width: "160px",
    },
    {
        name: "Material Group",
        selector: (row) => row.materialGroup,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.materialGroup}</span>
        ),
        width: "150px",
    },
    {
        name: "Unit Of Measure",
        selector: (row) => row.uoM,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.uoM}</span>
        ),
        width: "130px",
    },
    {
        name: "Plant",
        selector: (row) => row.plantName,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.plantName}</span>
        ),
        width: "130px",
    },    
    // {
    //   name: "Delete",
    //   selector: (row) => row.local_id || "--",
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //       <button
    //         className="btn btn-danger"
    //         // onClick={() => removePOItemRow(row.local_id)}
    //       >
    //         <i className="bi bi-trash3"></i>
    //       </button>
    //     </>
    //   ),
    //   width: "200px",
    // },
  
  ];

  const loadInitialSuuplierData = async () => {
    setData(materialMasterData);
};

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={8} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Transportation List</h2>
                <p className="subText">Transportation</p>
              </Col>
              {/* <Col xs={4} md={2} lg={2} className="mb-2">
                <button className="btn w-full" onClick={navigateCreateMaterial}>
                  Add Material
                </button>
              </Col>   */}
              <hr />
            </Row>

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Transportation List</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={userListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default TransportationList;
