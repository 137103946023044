import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

const ConfirmSendInvitationModal = React.forwardRef((props, ref) => {
  const selectedRowsFlag = props.selectedRows.length > 0;
  const { selectedRows } = props;
  const [formInputs, setFormInputs] = useState({
    supplierIds: []
  });

  // Handle Submit
  const handleSubmit = async () => {
    try {
      // await props.onSubmit(); // Call the submit function and wait for it to complete
      props.onSubmit(); // Call the submit function and don't wait for it to complete
      props.onHide(); // Close the modal only if submission is successful
    } catch (error) {
      console.error("Error submitting invitation:", error);
    }
  };

  useEffect(() => {
    if (props.show) {
      setFormInputs({
        supplierIds: selectedRows?.map((item) => item?.userId) || []
      });
    }
  }, [props.show, selectedRows]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedRowsFlag ? (
            <i className="bi bi-envelope-at"></i>
          ) : (
            <i className="bi bi-exclamation-triangle"></i>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="space-y-4">
          {!selectedRowsFlag ? (
            // Display message if no rows are selected
            <Row className="text-center">
              <Col xs={12} className="mt-3">
                <h5>Please select at least one row.</h5>
              </Col>
              <Col xs={12} className="d-flex justify-content-end mt-3">
                <button
                  type="button"
                  onClick={props.onHide}
                  className="btn btn-primary"
                >
                  OK
                </button>
              </Col>
            </Row>
          ) : (
            // Display remarks  if rows are selected
            <>
              <Row>
                <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
                  <div className="fw-medium mb-4">
                    <p> Do you want to send Invitation? </p>{" "}
                    <Row>
                      {formInputs?.supplierIds.map((item, index) => (
                        <Col xs={5} lg={2} className="me-2" key={index}>
                          <p className="bg-table-status bg-table-new-status mb-1 px-2">
                            {item}
                          </p>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="justify-end">
                <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                  <button
                    type="button"
                    onClick={() => props.onHide()}
                    className="btn btn-danger mt-4 w-full justify-center rounded-md"
                  >
                    No
                  </button>
                </Col>
                <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary mt-4 w-full justify-center rounded-md"
                  >
                    Yes
                  </button>
                </Col>
              </Row>
            </>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default ConfirmSendInvitationModal;
