import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import DataTable from "react-data-table-component";

const AdvancePaymentRequestList = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const data = [
    {
      dpNumber: "DP Number",
      collaboration: "Collaboration",
      status: "Status",
      dpRequestDate: "DP Request Date",
      poNumber: "PO Number",
      poValue: "PO Value",
      currency: "Currency",
      advanceValuePOCurrency: "Advance Value(PO Currency)",
      supplierName: "Supplier Name",
      buyerName: "Buyer Name",
      attachmentStatus: "Attachment Status",
      syncAttachments: "Sync Attachments",
      advancePaymentPercent: "Advance Payment Percent",
      advancePaymentPercentIncFreight: "Advance Payment % Inc. Freight",
      freightValue: "Freight Value",
      poValueAED: "PO Value (AED)",
      advanceValueAED: "Advance Value (AED)",
      contractDescription: "Contract Description",
      exchangeRate: "Exchange Rate",
      payByDate: "Pay By Date",
      bankGuarantee: "Bank Guarantee",
      validityEndDate: "Validity End Date",
      paymentAgainst: "Payment Against",
      justificationDescription: "Justification Description",
      bankGuaranteeAttachment: "Bank Guarantee Attachment",
      invoiceAttachment: "Invoice Attachment",
      poContractAttachment: "PO/Contract Attachment",
      otherSupportingDocuments: "Other Supporting Documents",
      companyCode: "Company Code",
      supplierBankDetails: "Supplier Bank Details"
    }
    
  ];
  const columns = [
    {
      name: "DP Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Collaboration",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "DP Request Date",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PO Number",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PO Value",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Currency",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Value (PO Currency)",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier Name",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Buyer Name",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Attachment Status",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Sync Attachments",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Payment Percent",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Payment Percent Inc. Freight",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Freight Value",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PO Value (AED)",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Advance Value (AED)",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Contract Description",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Exchange Rate",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Pay By Date",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Bank Guarantee",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Validity End Date",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Payment Against",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Justification Description",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Bank Guarantee Attachment",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Invoice Attachment",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "PO/Contract Attachment",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Other Supporting Documents",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Company Code",
      cell: (row) => <></>,
      width: "150px",
    },
    {
      name: "Supplier Bank Details",
      cell: (row) => <></>,
      width: "150px",
    }
  ]
  
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Advance Payment Request </h2>
                <p className="subText">Advance Payment Request Detail </p>
              </Col>
              <hr />
            </Row>

            {/* Advance Payment Request details  -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AR</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Advance Payment Request Details
                      </span>
                      <span className="subHeaderTitle">List of APR</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={columns}
                          data={data}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default AdvancePaymentRequestList;
