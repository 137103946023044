import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
// import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/SupplierConstants";
import OrderSchedulingModal from "./OrderSchedulingModal";
import { getOrderSchedulingList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
// import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import { formatDateTime } from "../../../../common/Validations";

//Serivce
// import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import LoadingAdviceModal from "./LoadingAdviceModal";
import CreditPolicyRequestFormModal from "../CreditPolicy/CreditPolicyRequestFormModal";
// import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import InvoiceConfirmationModal from "./InvoiceConfirmationModal";
import LoadingAdviceOrderHistoryModal from "./LoadingAdviceOrderHistoryModal";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

const LoadingAdviceList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Loading Advice");

  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  let orderSchedulingData = useSelector(
    (state) => state?.customerV1?.order_scheduling
  ); // User Details from Store
  let orderSchedulingTable = useSelector(
    (state) => state?.customerV1?.order_scheduling.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  // const detailedPage = (number) => {};

  const [data, setData] = useState();
  const [filteredUserListColInfo, setFilteredUserListColInfo] = useState([]);

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [getItemLevelData, setItemLevelData] = useState([]);

  const lineItemCol = [
    {
      name: "Product",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.materialDescription} </p>
          <p className="m-0 p-0 ">{row?.materialCode} </p>
        </div>
      ),
      width: "250px",
    },
    {
      name: "Load Qty",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.orderQtyLoadConfirm} </p>
          <p className="m-0 p-0 ">{row?.uOM} </p>
        </div>
      ),
      width: "120px",
    },
    {
      name: "Date/time",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingDate} </p>
          <p className="m-0 p-0 ">{row?.loadingTime} </p>
        </div>
      ),
      width: "160px",
    },
   
    {
      name: "Godown",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.godownAddress} </p>
          {/* <p className="m-0 p-0 ">{row?.materialCode} </p> */}
        </div>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Truck No",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.loadingTruckNoCode} </p>
          {/* <p className="m-0 p-0 ">{row?.materialCode} </p> */}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Driver",
     
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row?.transporterDriverName} </p>
          <p className="m-0 p-0 ">{row?.transporterDriverContact} </p>
        </div>
      ),
      width: "160px",
    },
    {
      name: "Shipping Addr",
      cell: (row) => (
        <>
          <p>{row?.shippingAddress}</p>
        </>
      ),
      width: "130px",
    },
    {
      name: "Shipping Postal Code",
      cell: (row) => (
        <>
          <p>{row?.shippingPostalCode}</p>
        </>
      ),
      width: "160px",
    },
    
  ];

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemCol);
    setModalTitle("Line Items");
    setItemLevelModalShow(true);
  };

  const orderSchedulingListColInfo = [
    {
      name: "Order",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">
            {row.documentNumber}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.orderStatus,
    //   cell: (row) => (
    //     <>
    //       <TableStatusField status={row.orderStatus} />
    //     </>
    //   ),
    //   sortable: true,
    //   width: "100px",
    // },
    // {
    //   name: "Dispatch Date",
    //   selector: (row) => row.dispatchDate,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {formatDate(row.dispatchDate)}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {formatDateTime(row.createdDate)}
          </span>
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Company Code",
      selector: (row) => row.companyCodeDesc,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">{row.companyCodeDesc}</span>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Customer",
      selector: (row) => row.sapDocumentNumber,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
            <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    // {
    //   name: "Item Nr",
    //   selector: (row) => row.sapDocumentNumber,
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <p className="m-0 p-0 pt-1 fw-bold ">{row?.items?.local_id} </p>
    //         {/* <p className="m-0 p-0 ">{row.sapDocumentNumber} </p> */}
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Material",
    //   selector: (row) => row?.items?.materialCode,
    //   cell: (row) => (
    //     <>
    //       <div>
    //         <p className="m-0 p-0  fw-bold">
    //           {row?.items?.materialDescription}{" "}
    //         </p>
    //         <p className="m-0 p-0 pt-1  ">{row?.items?.materialCode} </p>
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "140px",
    // },
    // {
    //   name: "Qty",
    //   selector: (row) => row?.items?.orderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {row?.items?.orderQuantityLoad}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Qty",
    //   selector: (row) => row.sumOfOrderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {row.sumOfOrderQuantityLoad}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Net Value",
    //   selector: (row) => row.sumOfRate,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {formatNumber(row.sumOfRate, "comma")}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Unit Price",
    //   selector: (row) => row.unitPrice,
    //   cell: (row) => (
    //     <>
    //       <span className="fw-bold text-primary">
    //         {formatNumber(row?.items?.unitPrice, "comma")}
    //       </span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Ship-to-Party",
    //   selector: (row) => row.projectAddress,
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.projectAddress} </p>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.projectAddress2} </p>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.projectCity} </p>
    //       <p className="m-0 p-0 pt-1  ">{row.projectCityPostalCode} </p>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Shiping Address",
    //   selector: (row) => row.shippingAddress,
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.shippingAddress} </p>
    //       <p className="m-0 p-0 pt-1  ">{row.shippingPostalCode} </p>
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },

    // {
    //   name: "Line Items",
    //   cell: (row) => (
    //     <>
    //       <span
    //         style={{ marginLeft: "12px" }}
    //         onClick={() => lineItemShowModal(row.items)}
    //       >
    //         <i className="bi bi-calendar2-week iconTable"></i>
    //       </span>
    //       <span className="suppliersIcon"> {row.items?.length} </span>
    //     </>
    //   ),
    //   width: "120px",
    // },
    // {
    //   name: "Loading Advice",
    //   cell: (row) => (
    //     // <>
    //     //   <button className="btnTable" onClick={() => showLoadingAdvice(row)}>
    //     //     Create
    //     //   </button>
    //     // </>
    //     <>

    //         <button className="btnTable" onClick={() => showLoadingAdvice(row)}>
    //           Create
    //         </button>

    //         {
    //           row.items?.orderHistory && (
    //             <span
    //               style={{ marginLeft: "4px", fontSize: "0px" }}
    //               onClick={() => showLoadingAdviceHistory(row)}
    //               className="iconTableNew"
    //             >
    //               {/* <i className="bi bi-receipt-cutoff"></i> */}
    //               <img
    //                 src="assets/img/newIcons/delivery-truck.png"
    //                 alt="attachments"
    //                 style={{ width: "60px" }}
    //                 className="mt-1"
    //               />
    //             </span>
    //           )
    //         }

    //     </>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Invoice",
      // cell: (row) => (
      //   <>
      //     {row.updateLoadingStatus ? (
      //       <button className="btnTable" onClick={() => showEditInvoice(row)}>
      //         Generate
      //       </button>
      //     ) : (
      //       <button className="btnTable" disabled={true}>
      //         Generate
      //       </button>
      //     )}
      //   </>
      // ),
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => showEditInvoice(row)}>
            Generate
          </button>
        </>
      ),
      width: "150px",
    },
    // {
    //   name: "Credit Policy",
    //   cell: (row) => (
    //     <>
    //       <button
    //         className="btnTable"
    //         onClick={() => showCreditPolicyRequestForm(row)}
    //       >
    //         Request
    //       </button>
    //     </>
    //   ),
    //   width: "150px",
    // },

    {
      name: "Sales Officer",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.createdByName} </p>
            <p className="m-0 p-0 ">{row.createdBy} </p>
          </div>
        </>
      ),
      selector: (row) => row.createdByName || "--",
      sortable: true,
      width: "140px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 ">{row.createdByName} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    // {
    //   name: "Remarks",
    //   selector: (row) => row.soRemarks,
    //   cell: (row) => (
    //     <>
    //       <TextAreaField
    //         className="inputBox"
    //         id="soRemarks"
    //         name="soRemarks"
    //         type="text"
    //         value={
    //           row.soRemarks
    //             ? row.soRemarks === null
    //               ? " "
    //               : row.soRemarks
    //             : (row.dispatchRemarks || "") + " " + (row.royalityName || "")
    //         }
    //         onChange={(event) => handleInputChange(row.documentNumber, event)}
    //         // onBlur={(event) => handleInputChangeData(row.documentNumber, event)}
    //         disabled={row.updateSoStatus ? true : false}
    //       />
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    //required
    // {
    //   name: "Rescheduling",
    //   cell: (row) => (
    //     <>
    //       <button
    //         className="btnTable"
    //         // onClick={() => changeOrder(row)}
    //       >
    //         Edit{row.publish}
    //       </button>
    //     </>
    //   ),
    //   width: "150px",
    // },
  ];

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;
    const restrictedColumns = {
      "SALES-MANAGER": ["Process"],
      "SALES-OFFICER": ["Process"],
      "SALES-COORDINATOR": [],
      "GENERAL-MNGR": ["Process"],
      "SALES-DIRECTOR": ["Process"],
      "DISPATCH-SNR-OFFICER": ["Process"],
      "DISPATCH-OFFICER": ["Process"],
    };

    const filteredCol = orderSchedulingListColInfo.filter(
      (col) => !restrictedColumns[userRole]?.includes(col.name)
    );

    setFilteredUserListColInfo(filteredCol);
    // console.log(">> filteredCol", filteredCol);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = (documentNumber, event) => {
    const { name, value } = event.target;
    setData((prevRows) =>
      prevRows.map((row) =>
        row.documentNumber === documentNumber ? { ...row, [name]: value } : row
      )
    );
  };

  const [getOrderSheduling, setOrderSheduling] = useState(false);

  const [getCreditPolicyFormModal, setCreditPolicyFormModal] = useState(false);

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");
  const [getCreditPolicyFormData, setCreditPolicyFormData] = useState();

  const hideOrderScheduling = () => {
    setOrderSheduling(false);

    let obj = {
      queryName: "getLoadingAdviceList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  const loadInitialSuuplierData = async () => {
    setData(orderSchedulingData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getLoadingAdviceList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getLoadingAdviceList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getLoadingAdviceList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSchedulingData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getLoadingAdviceList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //CreditPolicyRequestFormModal
  // eslint-disable-next-line no-unused-vars
  const showCreditPolicyRequestForm = (items) => {
    setCreditPolicyFormData(items);
    setCreditPolicyFormModal(true);
  };

  const hideCreditPolicyRequestForm = () => {
    setCreditPolicyFormModal(false);
  };

  //modal show
  const [getModalLoadingAdvice, setModalLoadingAdvice] = useState(false);
  const [getModalLoadingAdviceHistory, setModalLoadingAdviceHistory] =
    useState(false);
  const [loadingAdviceData, setLoadingAdviceData] = useState(null);
  const [historyStatus, setHistoryStatus] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const showLoadingAdviceHistory = (data) => {
    setModalLoadingAdviceHistory(true);
    setLoadingAdviceData(data);
    setHistoryStatus(true);
  };
  // eslint-disable-next-line no-unused-vars
  const showLoadingAdvice = (data) => {
    setModalLoadingAdvice(true);
    setLoadingAdviceData(data);
    setHistoryStatus(false);
  };
  const hideModal = () => {
    setModalLoadingAdvice(false);
    setModalEditInvoice(false); //hide invoice
    setModalLoadingAdviceHistory(false);
  };

  //invoice
  const [getModalEditInvoice, setModalEditInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [viewInvoiceData, setViewInvoiceData] = useState(null);

  const showEditInvoice = (rowData) => {
    setModalEditInvoice(true);
    setInvoiceData(rowData);
  };

  useEffect(() => {
    setViewInvoiceData(data);
  }, [data]);

  // eslint-disable-next-line no-unused-vars
  const handleSuccessData = async (data) => {
    // console.log(">> data", data);
    // await ItemService.updateDispatchInvoice(data).then((item) => {
    //   // console.log(">> item! title", item);
    //   if (item?.status === 200) {
    //     handleSearchFuncApi();
    //   } else {
    //     console.log(">> Error: Loading getMasterDataSelf");
    //   }
    // });
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Loading Advice</h2>
                <p className="subText">Loading Advice List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LA</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Loading Advice</span>
                      <span className="subHeaderTitle">
                        Loading Advice List{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={filteredUserListColInfo}
                          data={data}
                          progressPending={orderSchedulingData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={orderSchedulingTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CreditPolicyRequestFormModal
        show={getCreditPolicyFormModal}
        onHide={() => hideCreditPolicyRequestForm(getCreditPolicyFormModal)}
        data={getCreditPolicyFormData}
        onSuccess={() => handleSearchFuncApi()}
      />
      <OrderSchedulingModal
        show={getOrderSheduling}
        onHide={hideOrderScheduling}
        clear={true}
      />
      <LoadingAdviceModal
        show={getModalLoadingAdvice}
        onHide={() => hideModal()}
        loadingadvicedata={loadingAdviceData}
        historyStatus={historyStatus}
        // onsuccessdata={handleSuccessData}
      />
      <LoadingAdviceOrderHistoryModal
        show={getModalLoadingAdviceHistory}
        onHide={() => hideModal()}
        loadingadvicedata={loadingAdviceData}
        historyStatus={historyStatus}
        // onsuccessdata={handleSuccessData}
      />
      {/* <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      /> */}

      <InvoiceConfirmationModal
        show={getModalEditInvoice}
        onHide={() => hideModal()}
        invoiceinfo={invoiceData}
        // onsuccessdata={handleSuccessData}
        // viewInvoiceData={viewInvoiceData}
      />
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
      {/* <ChangeOrderModal
        show={getOrderCarryFrdModal}
        onHide={hideshowchangeOrder}
        data={getCarryFrdSelectedData}
        onSuccess={() => handleSearchFuncApi()}
      /> */}
    </main>
  );
};

export default LoadingAdviceList;
