import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
// import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/SupplierConstants";
// import OrderSchedulingModal from "./OrderSchedulingModal";
import { getOrderSchedulingList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import PreDispatchModal from "./PreDispatchModal";
import {
  formatDate,
  formatDateTime,
  // getLocationData,
} from "../../../../common/Validations";
import { InputField } from "../../../../Components/formElements/InputField";
// import LoadingAdviceModalView from "./LoadingAdviceModalView";


const PreDispatchList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Pre Dispatch");

  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  let orderSchedulingData = useSelector(
    (state) => state?.customerV1?.order_scheduling
  ); // User Details from Store
  let orderSchedulingTable = useSelector(
    (state) => state?.customerV1?.order_scheduling.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState();
  const [filteredUserListColInfo, setFilteredUserListColInfo] = useState([]);
  // console.log(">>data",data)

  const userListColInfo = [
    {
      name: "Order",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">
            {row.documentNumber}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.orderStatus,
      cell: (row) => (
        <>
          <TableStatusField status={row.orderStatus} />
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Dispatch Date",
      selector: (row) => row.dispatchDate,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">
            {formatDate(row.dispatchDate)}
          </span>
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Customer",
      selector: (row) => row.customerCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
            <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Project Address",
    //   cell: (row) => (
    //     <>
    //       <span>{row.getProjectDetails?.projectAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Project",
      selector: (row) => row.projectName,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
        </div>
      ),
      sortable: true,
      width: "170px",
    },
    {
      name: "Process",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => showPreDispatch(row)}>
            Dispatch
          </button>
        </>
      ),
      width: "120px",
    },
    // {
    //   name: "Loading Advice",
    //   cell: (row) => (
    //     <>
    //        <span
    //           style={{ marginLeft: "30px", fontSize: "30px" }}
    //           // onClick={() => showLoadingAdvice(row)}
    //           className="iconTableNew"
    //         >
    //           <i className="bi bi-receipt-cutoff"></i>
    //         </span>
    //     </>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Product",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "230px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "Qty(Bags)",
      selector: (row) => row.orderQuantityLoad,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-success">
            {row.orderQuantityLoad}{" "}
          </p>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Bal Qty(Bags)",
      selector: (row) => row.balOrderQuantityLoad,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-danger">
            {row.balOrderQuantityLoad}{" "}
          </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    /*  {
      name: "Todays (Load)",
      // selector: (row) => row.carryForwardOrderLoad,
      cell: (row) => (
        <>
           <p className="m-0 p-0 pt-1 fw-bold ">{Number(row.balOrderQuantityLoad - row.carryForwardOrderLoad) || ""}</p>
        </>
      ),
      sortable: true,
      width: "140px",
    }, */
    {
      name: "CarryFrd (Bags)",
      selector: (row) => row.carryForwardOrderLoad,
      cell: (row) => (
        <>
          <p className="m-0 p-0 pt-1 fw-bold text-primary">
            {row.carryForwardOrderLoad}
          </p>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Dispatch Info",
      selector: (row) => row.soRemarks,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-info">{row.soRemarks} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Sales Officer",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.createdByName} </p>
            <p className="m-0 p-0 ">{row.createdBy} </p>
          </div>
        </>
      ),
      selector: (row) => row.createdByName || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Transport",
      selector: (row) => row.salesDocumentNr,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-primary"
            name="salesDocumentNr"
            id="salesDocumentNr"
            type="text"
            value={row.salesDocumentNr}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "140px",
    },
    // {
    //   name: "Project Loc",
    //   selector: (row) => row.location,
    //   cell: (row) => (
    //     <>
    //       <span>{getLocationData(row.getProjectDetails)}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "Disp Zone",
    //   selector: (row) => row.dispatchZone,
    //   cell: (row) => (
    //     <>
    //       <span>{row.dispatchZone}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Date",
      selector: (row) => row.createdDate,
      cell: (row) => <>{formatDateTime(row.createdDate)}</>,
      sortable: true,
      width: "120px",
    },

    // {
    //   name: "Rescheduling",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Edit{row.publish}</button>
    //     </>
    //   ),
    //   width: "150px",
    // },
  ];

  const [getPreDispatchShow, setPreDispatchShow] = useState(false);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const loadInitialSuuplierData = async () => {
    // setData(orderSchedulingData?.data);
    modifyData(orderSchedulingData?.data);
  };

  //modal show
  // const [getModalLoadingAdvice, setModalLoadingAdvice] = useState(false);
  // const [loadingAdviceData, setLoadingAdviceData] = useState(null);

  // const showLoadingAdvice = (data) => {
  //   setModalLoadingAdvice(true);
  //   setLoadingAdviceData(data);
  // };
  // const hideModal = () => {
  //   setModalLoadingAdvice(false);
  // };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getOrderScheduleSOList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getOrderSchedulingList(obj));
    // setData(orderSchedulingData?.data);
    modifyData(orderSchedulingData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getOrderScheduleSOList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getOrderSchedulingList(obj));
    // setData(orderSchedulingData?.data);
    modifyData(orderSchedulingData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getOrderScheduleSOList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSchedulingData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getOrderScheduleSOList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifyData = async (responseData) => {
    // const filteredData = responseData.filter(
    //   (item) => item.orderStatus !== "Fully Dispatched"
    // );
    setData(responseData);
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const showPreDispatch = (rowData) => {
    // console.log("Selected Row Data:", rowData);
    setPreDispatchShow(true);
    setSelectedRow(rowData); // Store the selected row in state
  };

  // console.log(">>selectedRow",selectedRow)

  const HidePreDispatch = () => {
    setPreDispatchShow(false);

    let obj = {
      queryName: "getOrderScheduleSOList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;
    const restrictedColumns = {
      "SALES-MANAGER": ["Process"],
      "SALES-OFFICER": ["Process"],
      "SALES-COORDINATOR": ["Process"],
      "GENERAL-MNGR": ["Process"],
      "SALES-DIRECTOR": ["Process"],
      "DISPATCH-SNR-OFFICER": [],
      "DISPATCH-OFFICER": [],
      CUSTOMER: ["Process"],
    };

    const filteredCol = userListColInfo.filter(
      (col) => !restrictedColumns[userRole]?.includes(col.name)
    );

    setFilteredUserListColInfo(filteredCol);
    // console.log(">> filteredCol", filteredCol);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle"> Pre Dispatch</h2>
                <p className="subText">Order scheduled List for dispatch</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OS</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle"> Pre Dispatch</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => introVideoShow()}
                      >
                        Order Booking
                      </button>
                    </Col> */}
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={filteredUserListColInfo}
                          data={data}
                          progressPending={orderSchedulingData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={orderSchedulingTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <PreDispatchModal
        show={getPreDispatchShow}
        onHide={HidePreDispatch}
        preDistachdata={selectedRow}
      />
      {/* <LoadingAdviceModalView
        show={getModalLoadingAdvice}  
        onHide={() => hideModal()}
        loadingadvicedata={loadingAdviceData}
        // onsuccessdata={handleSuccessData}
      /> */}
    </main>
  );
};

export default PreDispatchList;
