import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import CreateDocTypeModal from "../../../../Components/Modal/supplier_app/CreateDocTypeModal";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { addRfxNr } from "../../../../redux/feature/supplier_app/sourcingSlice";
import { formatDateTime } from "../../../../common/Validations";
import ReverseTimer from "../../../../Components/TableReverseTimer/ReverseTimer";

const VendorRequestForQuotationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");
  let rfqData = useSelector((state) => state?.sourcing?.rfq?.data); // User Details from Store

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Buyer Responsible",
      selector: (row) => row.buyerResponsible,
      sortable: true,
      width: "160px",
    },
    
    {
      name: "Buyer Contact",
      selector: (row) => row.buyerEmail,
      sortable: true,
      width: "250px",
    },
    {
      name: "RFQ Details",
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageVendor(row.rfxNr)}
        >
          {row.rfxNr}{" "}
        </button>
      ),
      width: "160px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemLevelShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },

    {
      name: "Version",
      selector: (row) => row.rfxVersion,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {row.rfxHeaderStatus === "Published" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "QTN Received" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "RFQ Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxHeaderStatus}
            </span>
          ) : (
            row.rfxHeaderStatus
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Title",
      selector: (row) => row.rfxTitle,
      sortable: true,
      width: "200px",
    },
    {
      name: "Open Date ",
      selector: (row) => formatDateTime(row.openingDateTime) || "--",
      sortable: true,
      width: "160px",
    },
    {
      name: "Close Date",
      selector: (row) => formatDateTime(row.closingDateTime) || "--",
      sortable: true,
      width: "160px",
    },
    {
      name: "Countdown Timer",
      cell: (row) => (
        <>
          <ReverseTimer
            closingDateTime={row.closingDateTime}
            openingDateTime={row.openingDateTime}
          />
        </>
      ),
      sortable: true,
      width: "270px",
    }, 
    {
      name: "Supplier Status",
      selector: (row) => row.supplierList[0].rfxSupplierStatus,
      cell: (row) => (
        <>
          { row.supplierList[0].rfxSupplierStatus === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.supplierList[0].rfxSupplierStatus}
            </span>
          ) : row.supplierList[0].rfxSupplierStatus === "Regret" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.supplierList[0].rfxSupplierStatus}
            </span>
          ) : (
            row.supplierList[0].rfxSupplierStatus || "--" )}
        </>
      ),
      width: "170px",     
      sortable: true,
     
    },
    {
      name: "Supplier ID",
      selector: (row) => row.supplierList[0]?.AssociateNumber,
      sortable: true,
      width: "150px",
    }, 
    {
      name: "Supplier Name",
      selector: (row) => row.supplierList[0]?.organisationName,
      sortable: true,
      width: "150px",
    }, 
    {
      name: "Supplier Details",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => supplierListShowModal(row.supplierList)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.supplierList?.length} </span>
        </>
      ),
      width: "150px",
    },    
    {
      name: "Procurment Category",
      selector: (row) => row.rfxCategoryDesc,
      sortable: true,
      width: "200px",
    },    
    {
      name: "Extension Count",
      selector: (row) => row.extensionCount,
      sortable: true,
      width: "160px",
    },
    {
      name: "Extension Date",
      selector: (row) => row.extensionCount,
      sortable: true,
      width: "160px",
    },    
    {
      name: "SAP RFQ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP Requisition",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " SAP Order ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },   
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyAddressDesc} </span>
        </>
      ),
      width: "120px",
    },    
    // {
    //   name: "Envelope Step",
    //   selector: (row) => row.rfqEnvelopType,
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Envelope Type",
      selector: (row) => row.rfxTypeDesc,
      sortable: true,    
    },
    {
      name: "Product",
      selector: (row) => row.productType,
      sortable: true,
      width: "150px",
    },
  ];

  const supplierListColInfo = [
    {
      name: "Supplier Name",
      selector: (row) => row.AssociateNumber,
      sortable: true,
      width: "180px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Supplier Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Quotation Status",
      cell: (row) => (
        <>
          <span className="badge rounded-pill bg-info text-dark">
            {row.quotationStatus}
          </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Mail",
      selector: (row) => row.email || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Contact",
      selector: (row) => row.contactNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      width: "140px",
    },
    {
      name: "ICV Validity",
      selector: (row) => row.icvValidity,
      sortable: true,
      width: "160px",
    },
    {
      name: "ICV Value",
      selector: (row) => row.icvValue,
      sortable: true,
      width: "150px",
    },
  ];

  const LineItemsColInfo = [
    {
      name: "RFQ Item Number",
      selector: (row) => row.rfxItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "PR Number",
      cell: (row) => (
        <>
          <span className="badge bg-warning text-dark">
            {row.purchaseReqDocumentNr}
          </span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Material Code",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Desc",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "250px",
    },
    {
      name: "Quantity Unit",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      width: "250px",
    },
    {
      name: "Item Rate",
      selector: (row) => row.internalRate,
      sortable: true,
      width: "250px",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getPurchaseDocType, setPurchaseDocType] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on Line Item Level Data
  const lineItemLevelShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(LineItemsColInfo);
    setModalTitle("Line Item Level Details");
    setItemLevelModalShow(true);
  };

  // OnClick on Supplier List Data
  const supplierListShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(supplierListColInfo);
    setModalTitle("Supplier List Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  let obj = {
    modalTitle: "Create Manual RFQ",
    navigate: Constants.SOURCING_CREATE_RFQ,
  };
  // eslint-disable-next-line no-unused-vars
  const createRFQ = () => {
    setModalData(obj);
    setModalShow(true);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const detailedPageVendor = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_VENDOR_RFQ_DETAILS);
  };
  // eslint-disable-next-line no-unused-vars
  const detailedPageBuyer = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_BUYER_RFQ_DETAILS);
  };

  const fetchUsers = async (page) => {
    setLoading(true);
    // console.log(">> Here is printing > ", page);
    // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);

    setData(rfqData);
    // setTotalRows(rfqData?.length);
    setLoading(false);
  };

 

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqData]);

  const getMasterData = async () => {
    let obj = {
      codeType: "RFQCategory",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseDocType(item?.data);
      } else {
        setPurchaseDocType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  //on page load
  useEffect(() => {
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Request For Quotation</h2>
            <p className="subText">Lists of Request For Quotation and details</p>
            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RQ</span>
                    </Col>
                    <Col xs={12} md={5} lg={5}>
                      <span className="headerTitle">
                      RFQ List
                      </span>
                      <span className="subHeaderTitle">
                      View of Request For Quotation and Status{" "}
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateDocTypeModal
        show={modalShow}
        onHide={() => hideModal()}
        modaldata={modalData}
        dropdowndata={getPurchaseDocType}
        navigate={Constants.SOURCING_CREATE_RFQ}
      />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default VendorRequestForQuotationList;
