import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import ItemService from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import toast from "react-hot-toast";
import HeaderText from "../../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { formatDateTime, getCurrentDate } from "../../../../common/Validations";
import Loader from "../../../../Components/Loader";

const QuotationDetailPage = () => {
  // eslint-disable-next-line no-unused-vars
  // const dispatch = useDispatch();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Quotation");

  let quotationListInfo = useSelector(
    (state) => state?.sourcing?.quotation?.quotationListInfo
  ); /* quotationListInfo from store*/

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);

  const [itemTableData, setItemTableData] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true);
  const [userId, setUserId] = useState(true);
  const [userName, setUserName] = useState(true);
  const [getLoader, setLoader] = useState(true);

  const [formInputs, setFormInputs] = useState({
    quotationNr: quotationListInfo?.quotationNr,
    qtnVersion: quotationListInfo?.qtnVersion,
    rfxNr: "",
    quotationSubmittedBy: "",
    qtnSubmittedID: "",
    vendorCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxDescription: "",
    rfxVersion: "",
    rfxTitle: "",
    openingrfxType: "",
    productType: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    qtnPaymentTerms: "",
    qtnBidderCurrency: "",
    technicalQuoteArticle: "",
    qtnPricingNotes: "",
    unpricedCommercia: "",
    lQuoteArticle: "",
    quotationNotes: "",
    commercialQuoteArticle: "",
    quotationStatus: "",
    buyerResponsible: "",
    companyAddress: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCateDate: "",
    openingTime: "",
    closingDate: "",
    closingTime: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    openingDateTime: "",
    closingDateTime: "",
    rfxTypeDesc: "",
    productTypeDesc: "",
    rfxbidTypeDesc: "",
    rfxNoOfEvaluationTypeDesc: "",
    rfxCurrencyCode: "",
    rfxCurrencyDesc: "",
    technicalApprovalTypeDesc: "",
    companyAddressDesc: "",
    plantDesc: "",
    billingAddressDesc: "",
    unpricedCommercialQuoteArticle: "",
    pricingNotes: "",
    qtnIncoTerms: "",
    quotationReference: "",
    acceptanceTermsConditions: "",

    items: [
      {
        qtnItem: 1,
        nstatusItem: "",
        qtnQuantity: 10,
        qtnQuantityUnit: "",
        purchaseDocumentNr: "",
        purchaseDocumentItem: "",
        quotationNr: "",
      },
    ],
    formCOMM: [],
    formTECH: [],
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getINCOTerms, setINCOTerms] = useState([]);
  const [getcurrency, setcurrency] = useState([]);
  const [getpaymentTerm, setpaymentTerm] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState("Yes");

  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Quantity ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="qtnQuantity"
            name="qtnQuantity"
            value={row.qtnQuantity || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "160px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "200px",
    },
    {
      name: "Unit Price ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="quotationUnitPrice"
            name="quotationUnitPrice"
            value={row.quotationUnitPrice || 0}
            disabled={disabled}
          />
        </>
      ),
    },
    {
      name: "Item Value ", // calculation
      selector: (row) => row.qtnitemValue || "--",
      width: "200px",
    },
    {
      name: "Delivery Lead Time (Days)", // input
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="deliveryLeadTime"
            name="deliveryLeadTime"
            value={row.deliveryLeadTime || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Service Start Date", // input
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceStartDate"
            name="serviceStartDate"
            value={row.serviceStartDate || getCurrentDate("")}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },

    {
      name: "Service End Date", // input
      selector: (row) => row.serviceEndDate || "--",
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceEndDate"
            name="serviceEndDate"
            value={row.serviceEndDate || getCurrentDate("")}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation", // input yes/NO
      selector: (row) => row.deviation || "--",
      cell: (row) => (
        <>
          <ComboboxField
            placeholder=""
            data={dropdownOption}
            id="deviation"
            name="deviation"
            value={row.deviation || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation Remarks", // input
      selector: (row) => row.deviationRemark || "--",
      cell: (row) => (
        <>
          <TextAreaField
            className="inputBox mt-1"
            id="deviation"
            name="deviation"
            value={row.deviation}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
  ];

  // Other Charges
  const [getOtherChargesValue, setOtherChargesValue] = useState(0);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);

  /* Other Charges selected data */
  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);
  const handleHeaderTextSelectedData = (data) => {
    setHeaderTextSelectedData(data);
  };

  useEffect(() => {
    const getTotal = getHeaderTextSelectedData.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue?.documentText || 0),
      0
    );
    setOtherChargesValue(getTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeaderTextSelectedData]);

  /* Create Other Charges Modal Function */
  // eslint-disable-next-line no-unused-vars
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
    // {
    //   name: "Delete",
    //   cell: (row, index) => (
    //     <>
    //       <span>
    //         <i
    //           onClick={() => handleDelete(index)}
    //           className="bi bi-trash text-danger fs-3"
    //         ></i>
    //       </span>
    //     </>
    //   ),
    //   width: "10rem",
    // },
  ];

  const loadInitialPageData = async () => {
    // setLoading(true);
    if (quotationListInfo) {
      let obj = {
        documentNumber: quotationListInfo?.quotationNr,
        qtnVersion: quotationListInfo?.qtnVersion,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getQuotationDatail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setItemTableData(item?.data?.items);
          setFormInputs(item.data);

          setUserId(item.data.supplierList[0]?.AssociateNumber);
          setUserName(item.data.supplierList[0]?.organisationName);
          setHeaderTextSelectedData(item.data.othersCharge);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitialPageData();
    getMasterDataFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationListInfo]);

  //Load Master Data
  const getMasterDataFunc = async () => {
    // 1

    let obj4 = {
      codeType: "INCOTerms",
    };
    await ItemService.getMasterData(obj4).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setINCOTerms(item?.data);
      } else {
        setINCOTerms([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj5 = {
      codeType: "currency",
    };
    await ItemService.getMasterData(obj5).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setcurrency(item?.data);
      } else {
        setcurrency([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj6 = {
      codeType: "paymentTerm",
    };
    await ItemService.getMasterData(obj6).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setpaymentTerm(item?.data);
      } else {
        setpaymentTerm([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    setLoader(false);
  };

  // Get Dropdown data select
  useEffect(() => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      [getDropdownData?.textField]: getDropdownData?.name,
      [getDropdownData?.textCode]: getDropdownData?.code,
      [getDropdownData?.textDesc]: getDropdownData?.description,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const handleShipmentChange = (event) => {
    setSelectedShipment(event.target.value);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Quotation:<span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {formInputs?.quotationNr}
                </span>
              <span>
                {formInputs?.quotationStatus === "Published" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "QTN Received" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "RFQ Created" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Submitted" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : (
                  formInputs?.quotationStatus
                )}
              </span>
              <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                {userId} - {userName}
              </span>
            </h2>
            <p className="subText">Quotatoin Details</p>
            <hr />

            <Loader isLoading={getLoader} className={"text-center"} />

            {!getLoader && (
              <>
                {/*quotation Header */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">QH</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Quotation Header</span>
                          <span className="subHeaderTitle">
                            Header Description
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Company Address"
                              name="companyAddressDesc"
                              id="companyAddressDesc"
                              placeholder=""
                              value={formInputs?.companyAddressDesc}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="RFQ ID"
                              name="rfxNr"
                              id="rfxNr"
                              placeholder=""
                              value={formInputs?.rfxNr}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationNr9"
                          className="inputBox"
                          label="Quotation Number"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Delivery Address"
                              name="billingAddressDesc"
                              id="billingAddressDesc"
                              value={formInputs?.billingAddressDesc}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="rfqVersion"
                              className="inputBox"
                              label="RFQ Version"
                              name="rfxVersion"
                              placeholder=""
                              value={formInputs?.rfxVersion}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="quotationCreatedOn"
                              className="inputBox"
                              label="Quotation Created Date"
                              name="quotationCreatedOn"
                              placeholder=""
                              value={getCurrentDate("default")}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Billing Address"
                              name="billingAddressDesc"
                              id="billingAddressDesc"
                              value={formInputs?.billingAddressDesc}
                              // onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="RFQ Type"
                              name="rfxbidTypeDesc"
                              id="rfxbidTypeDesc"
                              value={formInputs?.rfxbidTypeDesc}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Quotation Version"
                              name="qtnVersion"
                              id="qtnVersion"
                              placeholder=""
                              value={" v1"}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Buyer Responsible"
                              name="buyerResponsible"
                              id="buyerResponsible"
                              value={formInputs?.buyerResponsible}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Product Type"
                              name="productType"
                              id="productType"
                              value={formInputs?.productType}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmittedBy"
                          className="inputBox"
                          label="Quotation Submitted By"
                          name="quotationSubmittedBy"
                          placeholder=""
                          value={" "}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Buyer Contact"
                              name="buyerConact"
                              id="buyerConact"
                              value={formInputs?.buyerConact}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Number Of Evaluation"
                              name="rfxNoOfEvaluationType"
                              id="rfxNoOfEvaluationType"
                              value={formInputs?.rfxNoOfEvaluationType}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="quotationSubmissionDate"
                              className="inputBox"
                              label="Quotation Submitted On"
                              name="quotationSubmissionDate"
                              placeholder=""
                              value={formInputs?.quotationSubmissionDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Bid Type"
                              name="rfxbidType"
                              id="rfxbidType"
                              value={formInputs?.rfxbidType}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="RFQ Currency"
                              name="rfxCurrency"
                              id="rfxCurrency"
                              value={formInputs?.rfxCurrency}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="quotationStatus"
                              className="inputBox"
                              label="Quotation Status"
                              name="quotationStatus"
                              placeholder=""
                              value={"New"}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Quotation overview */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">QO</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Quotation Overview
                          </span>
                          <span className="subHeaderTitle">
                            Quotation Overview
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Title:"
                              name="rfxTitle"
                              id="rfxTitle"
                              value={formInputs?.rfxTitle}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Opening Date:"
                              name="openingDateTime"
                              id="openingDateTime"
                              value={formatDateTime(
                                formInputs?.openingDateTime
                              )}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Instructions Attchements:"
                              name="rfxCurrency"
                              id="rfxCurrency"
                              value={formInputs?.rfxCurrency}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Description:"
                              name="rfxDescription"
                              id="rfxDescription"
                              value={formInputs?.rfxDescription}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Closing Date:"
                              name="closingDateTime"
                              id="closingDateTime"
                              value={formatDateTime(
                                formInputs?.closingDateTime
                              )}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Quotation Header Info */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible3
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility3}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">QI</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Quotation Header Info
                          </span>
                          <span className="subHeaderTitle">
                            Quotation Header Information
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible3 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="quotationReference"
                              className="inputBox"
                              label="Quotation Reference"
                              name="quotationReference"
                              placeholder=""
                              value={formInputs?.quotationReference}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="quotationSubmissionDate"
                              className="inputBox"
                              type="date"
                              label="Quotation Date"
                              name="quotationSubmissionDate"
                              placeholder=""
                              value={formInputs?.quotationSubmissionDate}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Payment term"
                              placeholder=""
                              data={getpaymentTerm}
                              id="qtnPaymentTerms"
                              iconClassName="dropdownIcon"
                              name="qtnPaymentTerms"
                              code="qtnPaymentTermsCode"
                              description="qtnPaymentTermsDesc"
                              setValue={formInputs?.qtnPaymentTerms}
                              getvalue={setDropdownData}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Inco Terms"
                              placeholder=""
                              data={getINCOTerms}
                              id="qtnIncoTerms"
                              iconClassName="dropdownIcon"
                              name="qtnIncoTerms"
                              code="qtnIncoTermsCode"
                              description="qtnIncoTermsDesc"
                              setValue={formInputs?.qtnIncoTerms}
                              getvalue={setDropdownData}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Bid Currency"
                              placeholder=""
                              data={getcurrency}
                              id="qtnBidderCurrency"
                              iconClassName="dropdownIcon"
                              name="qtnBidderCurrency"
                              code="qtnBidderCurrencyCode"
                              description="qtnBidderCurrencyDesc"
                              setValue={formInputs?.qtnBidderCurrency}
                              getvalue={setDropdownData}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="technicalQuoteArticle"
                              className="inputBox"
                              label="Milestone / Scheduled payments"
                              type="text"
                              name="technicalQuoteArticle"
                              placeholder=""
                              value="No"
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="technicalQuoteArticle"
                              className="inputBox"
                              label="TECHNICAL PROPOSAL"
                              type="file"
                              name="technicalQuoteArticle"
                              placeholder=""
                              value={formInputs?.technicalQuoteArticle || ""}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Pricing Notes"
                              type="text"
                              name="qtnPricingNotes"
                              id="qtnPricingNotes"
                              placeholder=""
                              value={formInputs?.qtnPricingNotes || ""}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="unpricedCommercialQuoteArticle"
                              className="inputBox"
                              label="UNPRICED COMMERCIAL PROPOSAL"
                              type="file"
                              name="unpricedCommercialQuoteArticle"
                              placeholder=""
                              value={
                                formInputs?.unpricedCommercialQuoteArticle || ""
                              }
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <p className="fw-medium text-dark">
                              Acceptance to Terms & Conditions
                            </p>
                            <div>
                              <input
                                type="radio"
                                name="gender"
                                value="Yes"
                                className="form-check-input me-2"
                                checked={selectedShipment === "Yes"}
                                onChange={handleShipmentChange}
                                disabled={disabled}
                              />
                              <span className="me-2">Yes</span>
                              <input
                                type="radio"
                                name="gender"
                                value="No"
                                className="form-check-input me-2"
                                checked={selectedShipment === "No"}
                                onChange={handleShipmentChange}
                                disabled={disabled}
                              />
                              <span className="me-2">No</span>
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Quotation Notes"
                              type="text"
                              name="quotationNotes"
                              id="quotationNotes"
                              placeholder=""
                              value={formInputs?.quotationNotes || ""}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="commercialQuoteArticle"
                              className="inputBox"
                              label="COMMERIAL PROPOSAL"
                              type="file"
                              name="commercialQuoteArticle"
                              placeholder=""
                              value={formInputs?.commercialQuoteArticle}
                              onChange={onInputChange}
                              disabled={disabled}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Price Shedule / Line Item */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PI</span>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                          <span className="headerTitle">
                            Price Shedule / Line Item
                          </span>
                          <span className="subHeaderTitle">
                            Price Shedule / Line Item
                          </span>
                        </Col>
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              // title=""
                              columns={PRLineItemInitialCol}
                              data={itemTableData}
                              // progressPending={loading}
                              // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                              // pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* other Charges table */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible5
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility5}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">OC</span>
                        </Col>

                        <Col xs={4} md={6} lg={6}>
                          <span className="headerTitle">Other Charges</span>
                          <span className="subHeaderTitle">
                            Other Charges Table{" "}
                          </span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible5 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              // title=""
                              columns={HeaderTextColumns}
                              data={getHeaderTextSelectedData}
                              // progressPending={loading}
                              // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                              // pagination

                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pricing Summary */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible6
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility6}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PS</span>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                          <span className="headerTitle">Pricing Summary</span>
                          <span className="subHeaderTitle">
                            Pricing Summary{" "}
                          </span>
                        </Col>
                        <Col xs={2} md={1} lg={1} className="">
                          <SourcingCommonFilter />
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible6 ? "visible" : ""
                      }`}
                    >
                      <Row className="my-4 ms-3 ">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="d-flex mb-2 jsutify-content-center align-items-center"
                        >
                          <p className="fw-medium">
                            SubTotal Pricing Schedule / Line Items (A){" "}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          className="d-flex mb-2 jsutify-content-center align-items-center"
                        >
                          <InputField
                            id="subTotalValue"
                            className="inputBox"
                            name="subTotalValue"
                            placeholder=""
                            value={formInputs?.qtnSubTotalLineItem}
                            disabled={getDisabledStatus}
                          />
                          <p className="ms-4 fw-medium">
                            {" "}
                            {formInputs?.rfxCurrency}
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="d-flex mb-2 jsutify-content-center align-items-center "
                        >
                          <p className="fw-medium">
                            {" "}
                            SubTotal Other Charges (B)
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          md={7}
                          lg={7}
                          className="d-flex mb-2 jsutify-content-center align-items-center "
                        >
                          <InputField
                            id="getOtherChargesValue"
                            className="inputBox"
                            name="getOtherChargesValue"
                            placeholder=""
                            value={getOtherChargesValue}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="d-flex mb-2 jsutify-content-center align-items-center"
                        >
                          <p className="fw-medium">
                            Quotation Gross Total (C = A + B)
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          md={7}
                          lg={7}
                          className="d-flex mb-2 jsutify-content-center align-items-center"
                        >
                          <InputField
                            id="Closing"
                            className="inputBox"
                            name="qtnTotal"
                            placeholder=""
                            value={formInputs?.qtnTotal}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                {/* COMM - Spares & Operation Maintenance 08*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible8
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility8}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">OC</span>
                        </Col>

                        <Col xs={4} md={8} lg={7}>
                          <span className="headerTitle">
                            Form: COMM- Spares & Operation Maintenance
                          </span>
                          {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible8 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="commTopButtonRightLeftPadding">
                          {formInputs?.formCOMM?.length > 0 &&
                            formInputs?.formCOMM?.map((formJsonData, index) => (
                              <Col
                                xs={12}
                                md={4}
                                lg={4}
                                key={index}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  id="question_value"
                                  label={formJsonData?.question_name}
                                  className="inputBox"
                                  name="question_value"
                                  placeholder=""
                                  value={formJsonData?.question_value}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Form :TECH - Spares & Operation Maintenance09*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible9
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility9}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">TS</span>
                        </Col>

                        <Col xs={4} md={8} lg={7}>
                          <span className="headerTitle">
                            Form : TECH - Spares & Operation Maintenance
                          </span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible9 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="commTopButtonRightLeftPadding">
                          {formInputs?.formTECH?.length > 0 &&
                            formInputs?.formTECH?.map((formJsonData, index) => (
                              <Col
                                xs={12}
                                md={4}
                                lg={4}
                                key={index}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  id="question_value"
                                  label={formJsonData?.question_name}
                                  className="inputBox"
                                  name="question_value"
                                  placeholder=""
                                  value={formJsonData?.question_value}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* <CreateOtherChanges
        show={otherchangesmodelshow}
        onHide={hideOtherChangesModel}
        onData={handleDataFromCreateOtherChanges}
      /> */}
      <HeaderText
        index={0}
        headername="Add Other Charges"
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hedertextdata={handleHeaderTextSelectedData}
        showcurrentdata={getHeaderTextSelectedData}
        masterdata="OtherChargesID"
        type="Number"
      />

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default QuotationDetailPage;
