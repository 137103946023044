import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";

import ItemService from "../../../../services/customer_appCommonService";
import toast from "react-hot-toast";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import { validateRequired } from "../../../../common/Validations";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import { useNavigate } from "react-router-dom";
import CustomerConstants from "../../../../common/CustomerConstants";

const CreateToll = () => {
  const navigate = useNavigate();

  // localStorage.setItem("menu", "Admin");
  // localStorage.setItem("submenu", "Create Toll");
  
  const modalData = {
    title: "Toll Updated",
    successMsg: "",
  };

  const [isCardVisible, setCardVisible] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);

  const message = {
    VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
    VALID_MAIL_ID: "Please enter a valid email address.",
    VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
    VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
    VALID_ALTERNATE_CONTACT_NUMBER:
      "Please enter a valid alternate contact number.",
    VALID_PURPOSE_JUSTIFICATION:
      "Please ensure the justification is under 500 characters.",
    VALID_POSTAL_CODE:
      "Enter a 6-character postal code with uppercase letters and numbers only",
    VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
    VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
    EXIStS_MAIL_ID: "Email already exists",
    EXIStS_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
    FILL_REQUIRED_FIELD: "Please fill the required field",
    ALTERNATE_MAIL_ID: "Email ID is same",
    ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  };
  const [formInputs, setFormInputs] = useState({
    slNo: "",
    routeName: "",
    tollRates: "",
    noEntryTimeFromAm: "",
    noEntryTimeToAm: "",
    noEntryTimeFromPm: "",
    noEntryTimeToPm: "",
  });
  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);
  const [validation, setValidation] = useState({
    slNo: "",
    routeName: "",
    tollRates: "",
    noEntryTimeFromAm: "",
    noEntryTimeToAm: "",
    noEntryTimeFromPm: "",
    noEntryTimeToPm: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const onInputChange = ({ target: { name, value } }) => {
    let errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  const handleCreate = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.slNo)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        slNo: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.routeName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        routeName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.routeName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        routeName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.tollNumber)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        tollNumber: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.tollRates)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        tollRates: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.noEntryTimeFromAm)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        noEntryTimeFromAm: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.noEntryTimeToAm)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        noEntryTimeToAm: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.noEntryTimeFromPm)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        noEntryTimeFromPm: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.noEntryTimeToPm)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        noEntryTimeToPm: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    const obj = {
      data:formInputs ,
    };
    // console.log(">>obj", obj);
    setDisabled(true);
    await ItemService.addTollMaster(obj).then((item) => {
      console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        navigate(CustomerConstants.CUSTOMER_V1_TOLL_MASTER_LIST)
        // Update response value in Redux Store
        // dispatch(getMaterialListData()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };
  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };
  //hide  failur modal
  const hideFailureModal = () => {
    setShowFailureModal(false);
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Toll</h2>
            <p className="subText">Create Toll</p>
            <hr />
            <Col
              xs={1}
              md={{ offset: 10, span: 1 }}
              lg={{ offset: 10, span: 1 }}
              className="text-right mb-2"
            >
              <span>
                <button
                  className="btnTable"
                  onClick={handleCreate}
                  disabled={disabled}
                >
                  Create{" "}
                </button>
              </span>
            </Col>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Create Toll</span>
                      <span className="subHeaderTitle">Create Toll</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SlNo"
                          name="slNo"
                          id="slNo"
                          value={formInputs.slNo || ""}
                          onChange={onInputChange}
                          error={validation.slNo || ""}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Route Name"
                          name="routeName"
                          id="routeName"
                          value={formInputs?.routeName || ""}
                          onChange={onInputChange}
                          error={validation?.routeName || ""}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Toll Number"
                          name="tollNumber"
                          id="tollNumber"
                          value={formInputs.tollNumber || ""}
                          onChange={onInputChange}
                          error={validation?.tollNumber || ""}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Toll Rates"
                          name="tollRates"
                          id="tollRates"
                          value={formInputs.tollRates || ""}
                          onChange={onInputChange}
                          error={validation?.tollRates || ""}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="From No Entry Time"
                          name="noEntryTimeFromAm"
                          id="noEntryTimeFromAm"
                          type="time"
                          value={formInputs.noEntryTimeFromAm || ""}
                          onChange={onInputChange}
                          error={validation?.noEntryTimeFromAm || ""}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="To No Entry Time"
                          name="noEntryTimeToAm"
                          id="noEntryTimeToAm"
                          type="time"
                          value={formInputs.noEntryTimeToAm || ""}
                          onChange={onInputChange}
                          error={validation?.noEntryTimeToAm || ""}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="From No Entry Time"
                          name="noEntryTimeFromPm"
                          id="noEntryTimeFromPm"
                          type="time"
                          value={formInputs.noEntryTimeFromPm || ""}
                          onChange={onInputChange}
                          error={validation?.noEntryTimeFromPm || ""}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="To No Entry Time"
                          name="noEntryTimeToPm"
                          id="noEntryTimeToPm"
                          type="time"
                          value={formInputs.noEntryTimeToPm || ""}
                          onChange={onInputChange}
                          error={validation?.noEntryTimeToPm || ""}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        // aftersuccess={CustomerConstants.TOLL_MASTER_DETAILS}
      />
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CreateToll;
