
/* APPLICATION ROUTE CONSTANTS */

module.exports = Object.freeze({
  
    ROW_PER_PAGE : [10, 50, 100, 300, 500],

    // Status
    STATUS_REGISTER:'Register',

    /* Routers Link supplier_app */
    APP_HOME: '/applications',
    HOME: '/home',
    
    HOME_INTRO:'/customer-intro',
    DASHBOARD: '/customer-dashboard',
    INBOX: '/customer-inbox',
    OUTBOX: '/customer-outbox',

    /*Customer*/
    CUSTOMER_REGISTRATION: '/customer-registration',
    CUSTOMER_LIST: '/customer-list',
    CUSTOMER_MASS_UPLOAD: '/customer-mass-upload',
    CUSTOMER_MASTER_CREATE: '/customer-master-creation',
    CUSTOMER_SELF_REGISTRATION: '/customer-self-registration',
    CUSTOMER_DETAILED_PAGE_CREATE:'/customer-details',
    CUSTOMER_DETAILED_PAGE_WORKFLOW:'/customer-details-workflow',

    /*PreSales*/
    PRESALES_INQUIRY_CREATION:'/customer-inquiry-create',
    PRESALES_INQUIRY_LIST:'/customer-inquiry',
    PRESALES_INQUIRY_DETAILS:'/customer-inquiry-details',

    PRESALES_CUSTOMER_CREATE_QUOTATION:'/customer-create-quotation',
    PRESALES_CUSTOMER_QUOTATION_DETAILS:'/customer-quotation-details',
    PRESALES_CUSTOMER_QUOTATION_LIST: '/customer-quotation',
    PRESALES_CUSTOMER_QUOTATION_CREATE_PO: '/create-po-quotation',

    /*Sales*/
    SALES_PURCHASE_ORDER_LIST:'/customer-purchase-order',
    SALES_PURCHASE_ORDER_CREATION:'/customer-create-purchase-order',
    SALES_PURCHASE_ORDER_DETAILS:'/customer-purchase-order-detail',

    SALES_SALES_ORDER_LIST:'/sales-order',
    SALES_SALES_ORDER_CREATION:'/create-sales-order',
    SALES_SALES_ORDER_DETAILS:'/sales-order-detail',
    
    SALES_DELIVERY_CREATION:'/create-delivery',
    SALES_DELIVERY_LIST:'/delivery',
    SALES_DELIVERY_DETAILS:'/delivery-detail',

    /* UserManual */
    USER_MANUAL_CUSTOMER_MANUAL:'/customer-manual',
    USER_MANUAL_MANAGER_MANUAL:'/manager-manual',

    //REPORTS
    CUSTOMER_REPORTS:'/customer-reports',
    CUSTOMER_REPORT_DETAILS:'/customer-report-details',

    /* catalog */
    PRODUCT_CATALOG_LIST:'/product-catalog',
    PRODUCT_CATALOG_DETAILS:'/product-catalog-details',
    PRODUCT_CATALOG_MASTER:'/product-catalog-master',

    /* Product Catalog  Shopwise */
    PRODUCT_CATALOG_SHOPWISE:'/product-catalog-shopwise',

    /* CSTOMER INVOICE */
    CUSTOMER_INVOICE_DETAILS:"/customer-invoice-details",
    CUSTOMER_INVOICE_LIST:"/customer-invoice-list",

    /*BILLING */
    CUSTOMER_BILLING:"/billing",
    CUSTOMER_BILLING_DETAILS:"/billing-detials",

    /* customer FEEDBACK */
    CUSTOMER_FEEDBACK:"/customer-feedback",
    CUSTOMER_FEEDBACK_LIST:"/customer-feedback-list",

    
    // -----------------------------------------  CUSTOMER_V1  --------------------------------------------

    
    
    /* Customer Version-1 Self  Registration */
    CUSTOMER_V1_REGISTER: '/customer-register',
    CUSTOMER_V1_REGISTER_LOGIN: '/customerRegister',
    CUSTOMER_V1_CUSTOMER_MASTER_LIST: '/customer-master-list',
    CUSTOMER_V1_CUSTOMER_MASTER: '/customer-master',

    /* Customer Version-1 Self  Registration */
    CUSTOMER_V1_REGISTER_DETAIL: '/customer-register-detail',

    /* Costing Sheet */
    CUSTOMER_V1_COSTING_SHEET:"/costing-sheet",
    CUSTOMER_V1_COSTING_SHEET_LOGIN:"/costingSheet",
    CUSTOMER_V1_COSTING_SHEET_LIST:"/costing-sheet-list",
    CUSTOMER_V1_COSTING_SHEET_DETAILS:"/costing-sheet-details",

    /* Customer Quotation List */
    CUSTOMER_V1_QUOTATION_LIST:"/customer-quotation-list",
    CUSTOMER_V1_QUOTATION_DETAILS:"/customers-quotation-detail",
    CUSTOMER_V1_QUOTATION_CREATION:"/customers-quotation-creation",

    /* KYC Registration form*/
    CUSTOMER_V1_KYC_REGISTRATION_FORM:"/kyc-registration-form",
    CUSTOMER_V1_REFERENCE_CUSTOMER_DETAILS:"/reference-customer-details",

    /* Scheduling */
    CUSTOMER_V1_ORDER_BOOKING_LIST:'/order-booking',
    CUSTOMER_V1_ORDER_SCHEDULING_LIST:'/order-scheduling',
    CUSTOMER_V1_ORDER_COMPLETE_SCHEDULING_LIST:"/order-complete",
    CUSTOMER_V1_ORDER_CANCELLED_SCHEDULING_LIST:"/order-cancelled",
    CUSTOMER_V1_PRE_DISPATCH_LIST:'/pre-dispatch-List',
    CUSTOMER_V1_POST_DISPATCH_LIST:'/post-dispatch-List',
    CUSTOMER_V1_DISPATCH_CONFIRMATION_LIST:'/dispatch-confirmation-List',
    CUSTOMER_V1_DISPATCHED_CUSTOMER_CONFIRM_LIST:'/dispatch-customer-confirm-List',
    CUSTOMER_V1_IOM_CREDIT_NOTE_LIST:'/credit-note-list',

   /* MaterialMaster*/
    CUSTOMER_V1_MATERIAL_MASTER_LIST:'/material-master-list',
    CUSTOMER_V1_MATERIAL_MASTER_CREATION:'/material-master-create',
    CUSTOMER_V1_MATERIAL_MASTER_DETAILS:'/material-master-detail',

    /* PlantMaster*/
    CUSTOMER_V1_PLANT_MASTER_LIST:'/plant-master-list',
    CUSTOMER_V1_PLANT_MASTER_CREATION:'/plant-master-create',
    CUSTOMER_V1_PLANT_MASTER_DETAILS:'/plant-master-detail',

    /* TollMaster*/
    CUSTOMER_V1_TOLL_MASTER_LIST:'/toll-master-list',
    CUSTOMER_V1_TOLL_MASTER_CREATION:'/toll-master-create',
    CUSTOMER_V1_TOLL_MASTER_DETAILS:'/toll-master-detail',

    /*Credit Approval Request Form*/    
    CUSTOMER_V1_CREDIT_POLICY_REQUEST_FORM_DETAILS:'/credit-policy-request-form-details',
    CUSTOMER_V1_CREDIT_POLICY_REQUEST_LIST:'/credit-policy-request-list',

    /* Purchase Order */
    CUSTOMER_V1_PURCHASE_ORDER_LIST:'/purchase-order-list',
    CUSTOMER_V1_PURCHASE_ORDER_CREATION:'/purchase-order-creation',
    CUSTOMER_V1_PURCHASE_ORDER_DETAILS:'/purchase-order-details',

    /* Transportation*/
    CUSTOMER_V1_TRANSPORTAION_CREATION:"/transportation-creation",
    CUSTOMER_V1_TRANSPORTAION_LIST:"/transportation-list",

      // -----------------------------------------  CUSTOMER_V2  --------------------------------------------
      
    /* Customer Version-2 Self  Registration */
    CUSTOMER_V2_REGISTER: '/sv-customer-register',
    CUSTOMER_V2_REGISTER_LOGIN: '/sv-customerRegister',
    CUSTOMER_V2_CUSTOMER_MASTER_LIST: '/sv-customer-master-list',
    CUSTOMER_V2_CUSTOMER_MASTER: '/sv-customer-master',

    /* Customer Version-2 Self  Registration */
    CUSTOMER_V2_REGISTER_DETAIL: '/sv-customer-register-detail',

    /* Scheduling */
    CUSTOMER_V2_ORDER_BOOKING_LIST:'/sva-order-booking',//orderbooking one order multiple line items
    CUSTOMER_V2_ORDER_BOOKING_LIST_LINE_ITEM:'/sv-order-booking',
    CUSTOMER_V2_ORDER_SCHEDULING_LIST:'/sv-order-scheduling',
    CUSTOMER_V2_PRE_DISPATCH_LIST:'/sv-pre-dispatch-list',
    CUSTOMER_V2_POST_DISPATCH_LIST:'/sv-post-dispatch-list',
    CUSTOMER_V2_LOADING_ADVICE_LIST:'/sv-loading-advice-list',
    
    //cashrequest
    CUSTOMER_V2_CASH_REQUEST_LIST:'/sv-cash-request-list',

    /* CSTOMER INVOICE */
    CUSTOMER_V2_INVOICE_DETAILS:"/sv-customer-invoice-details",
    CUSTOMER_V2_INVOICE_LIST:"/sv-customer-invoice-list",
    

})  

