import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import { getPostDispatchDocList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { formatDateTime } from "../../../../common/Validations";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { InputField } from "../../../../Components/formElements/InputField";
// import toast from "react-hot-toast";
import InvoiceConfirmationModal from "./InvoiceConfirmationModal";
import IOMCreditNotePrint from "./IOMCreditNotePrint";
import { useReactToPrint } from "react-to-print";
import DatePickerComponent from "../../Charts/FilterComponent/DatePickerComponent";

const IOMCreditNoteList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Credit Note");

  let userData = useSelector((state) => state?.user?.data); // User Details from Store
  let postDispatchDocData = useSelector(
    (state) => state?.customerV1?.postDispatchDoc
  ); // User Details from Store
  let postDispatchDocTable = useSelector(
    (state) => state?.customerV1?.postDispatchDoc.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState();
  // console.log(">>data",data)
  const [getModalEditInvoice, setModalEditInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [viewInvoiceData, setViewInvoiceData] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const [filterShow, setFilterShow] = useState(false);

  const showEditInvoice = (invoiceNumber, dispatchDocNr) => {
    let obj = {
      dispatchInvoiceNumber: invoiceNumber,
      dispatchDocNr,
    };
    setModalEditInvoice(true);
    setInvoiceData(obj);
  };
  const hideModal = () => {
    setModalEditInvoice(false);
  };

  const viewInvoice = (data) => {
    let obj = {
      dispatchInvoiceNumber: data.invoiceNumber,
      dispatchDocNr: data.dispatchDocNr,
    };
    setModalEditInvoice(true);
    setViewInvoiceData(data);
    setInvoiceData(obj);
  };
  const userListColInfo = [
    {
      name: "Dispatch",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">
            {row.documentNumber}
          </span>
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Product",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Customer",
      selector: (row) => row.dispCustomerCode,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.dispCustomerCode} </p>
          <p className="m-0 p-0 ">{row.customerName} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "INV Date",
      selector: (row) => row.dispInvoiceDate,
      cell: (row) => <>{formatDateTime(row.dispInvoiceDate)}</>,
      sortable: true,
      width: "120px",
    },
    {
      name: "INV Nr",
      selector: (row) => row.dispInvoiceNumber,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-primary">
            {row.dispInvoiceNumber}{" "}
          </p>
          {/* <p className="m-0 p-0 ">{row.sapDocumentNumber} </p> */}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Confirm",
      // selector: (row) => row.orderStatus,
      cell: (row) => (
        <>
          {row.dispInvoiceNumber ? (
            <span
              style={{ marginLeft: "30px", fontSize: "30px" }}
              onClick={() => viewInvoice(row)}
              className="iconTableNew"
            >
              <i className="bi bi-receipt-cutoff"></i>
            </span>
          ) : (
            <button
              className="btnTable"
              onClick={() =>
                showEditInvoice(row.invoiceNumber, row.dispatchDocNr)
              }
            >
              Invoice
            </button>
          )}
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.dispBasicPricePerUnit,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-primary"
            id="dispBasicPricePerUnit"
            name="dispBasicPricePerUnit"
            type="text"
            value={row.dispBasicPricePerUnit}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "INV Qty(MT)",
      selector: (row) => row.dispTotalQty,
      cell: (row) => (
        <>
          <span className="text-primary fw-bold">{row.dispTotalQty}</span>
        </>
      ),
      sortable: true,
      width: "130px",
    },

    {
      name: "REC Qty(MT)",
      selector: (row) => row.acceptedQtyMT,
      cell: (row) => (
        <>
          <span className="text-success fw-bold">{row.acceptedQtyMT}</span>
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "DIFF Qty(MT)",
      selector: (row) => row.differenceQtyMT,
      cell: (row) => (
        <>
          <span className="text-success fw-bold">{row.differenceQtyMT}</span>
        </>
      ),
      sortable: true,
      width: "130px",
    },

    {
      name: "DIFF Amount",
      selector: (row) => row.dispDifferenceAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-danger"
            id="dispDifferenceAmount"
            name="dispDifferenceAmount"
            type="text"
            value={row.dispDifferenceAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "DIFF CGST(2.5)",
      selector: (row) => row.dispDiffCGSTAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-danger"
            id="dispDiffCGSTAmount"
            name="dispDiffCGSTAmount"
            type="text"
            value={row.dispDiffCGSTAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "DIFF SGST(2.5)",
      selector: (row) => row.dispDiffSGSTAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-danger"
            id="dispDiffSGSTAmount"
            name="dispDiffSGSTAmount"
            type="text"
            value={row.dispDiffSGSTAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Total DIFF Amnt",
      selector: (row) => row.dispTotalDiffAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-danger"
            id="dispTotalDiffAmount"
            name="dispTotalDiffAmount"
            type="text"
            value={row.dispTotalDiffAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "REC Amnt",
      selector: (row) => row.dispReceivedAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-success"
            id="dispReceivedAmount"
            name="dispReceivedAmount"
            type="text"
            value={row.dispReceivedAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "REC SGST(2.5)",
      selector: (row) => row.dispRecivedDiffSGSTAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-success"
            id="dispRecivedDiffSGSTAmount"
            name="dispRecivedDiffSGSTAmount"
            type="text"
            value={row.dispRecivedDiffSGSTAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "REC Total Amnt",
      selector: (row) => row.dispRecivedTotalDiffAmount,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-success"
            id="dispRecivedTotalDiffAmount"
            name="dispRecivedTotalDiffAmount"
            type="text"
            value={row.dispRecivedTotalDiffAmount}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },

    {
      name: "Sales Officer",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.salesOfficerName} </p>
          <p className="m-0 p-0 ">{row.salesOfficerCode} </p>
        </div>
      ),
      selector: (row) => row.salesOfficerName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Transporter",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.dispTransporterCode} </p>
          <p className="m-0 p-0 fw-bold">{row.dispTransporterName} </p>
          <p className="m-0 p-0 ">{row.dispVehieleRegNo} </p>
        </div>
      ),
      selector: (row) => row.salesOfficerName,
      sortable: true,
      width: "180px",
    },

    // {
    //   name: "Order Booking Nr",
    //   selector: (row) => row.orderSchedulingNr,
    //   cell: (row) => (
    //     <>
    //       <span className="m-0 p-0 pt-1 fw-bold">{row.orderSchedulingNr}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "180px",
    // },

    {
      name: "Project Address",
      selector: (row) => row.projectName,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      // selector: (row) => row.orderStatus,
      cell: (row) => (
        <>
          <TableStatusField status={row.orderStatus} />
        </>
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Project Location",
    //   selector: (row) => row.location,
    //   cell: (row) => (
    //     <>
    //       <span>{row.location}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Product",
    //   selector: (row) => row.materialCode,
    //   sortable: true,
    //   width: "200px",
    //   cell: (row) => (
    //     <div>
    //       <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
    //       <p className="m-0 p-0 ">{row.materialCode} </p>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Created By",
    //   selector: (row) => row.createdByName,
    //   cell: (row) => (
    //     <>
    //       <span>{row.createdByName}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
  ];

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");
  const [filteredUserListColInfo, setFilteredUserListColInfo] = useState([]);

  const loadInitialSuuplierData = async () => {
    setData(postDispatchDocData?.data);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const handleInputChange = (dispatchDocNr, event) => {
  //   const { name, value } = event.target;

  //   setData((prevRows) =>
  //     prevRows.map((row) => {
  //       if (row.dispatchDocNr === dispatchDocNr) {
  //         if (Number(value) > Number(row.disptachQty)) {
  //           toast.error("Entered value cannot exceed INV Qty!", {
  //             duration: 4000,
  //             position: "top-right",
  //           });
  //           return row; // Return the row unchanged
  //         }
  //         return {
  //           ...row,
  //           [name]: value,
  //           differenceQtyMT:
  //             (Number(row.disptachQty) - Number(value)).toFixed(2) || 0,
  //         };
  //       }
  //       return row;
  //     })
  //   );
  // };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getPostDispatchDocList(obj));
    setData(postDispatchDocData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getPostDispatchDocList(obj));
    setData(postDispatchDocData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getPostDispatchDocList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDispatchDocData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "IOMCreditNote",
      module: "",
      page: 1,
      per_page: 10,
    };
    dispatch(getPostDispatchDocList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const confirmDispatch = async (
  //   dispatchDocNr,
  //   acceptedQtyMT,
  //   differenceQtyMT,
  //   disptachQty
  // ) => {
  //   let obj = {
  //     dispatchDocNr: dispatchDocNr,
  //     dispatchStatus: "Confirmed",
  //     acceptedQtyMT,
  //     differenceQtyMT,
  //   };

  //   if (acceptedQtyMT) {
  //     if (Number(acceptedQtyMT) > Number(disptachQty)) {
  //       toast.error("Entered value cannot exceed INV Qty!", {
  //         duration: 4000,
  //         position: "top-right",
  //       });
  //       return; // Return the row unchanged
  //     }
  //   } else {
  //     toast.error("Accepted Qty not be empty!", {
  //       duration: 4000,
  //       position: "top-right",
  //     });
  //     return;
  //   }

  //   await ItemService.updateDispatchConfirm(obj).then((item) => {
  //     // console.log(">> item! title", item);
  //     if (item?.status === 200) {
  //       // setTitle(item?.data);
  //       handleSearchFuncApi();
  //     } else {
  //       // setTitle([]);
  //       console.log(">> Error: Loading getMasterDataSelf");
  //     }
  //   });
  // };

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;
    const restrictedColumns = {
      "SALES-MANAGER": ["Qty Confirm", "Invoice Confirm"],
      "SALES-OFFICER": ["Qty Confirm", "Invoice Confirm"],
      "SALES-COORDINATOR": ["Qty Confirm", "Invoice Confirm"],
      "GENERAL-MNGR": ["Qty Confirm", "Invoice Confirm"],
      "SALES-DIRECTOR": ["Qty Confirm", "Invoice Confirm"],
      "DISPATCH-SNR-OFFICER": ["Qty Confirm", "Invoice Confirm"],
      "DISPATCH-OFFICER": ["Qty Confirm", "Invoice Confirm"],
      ADMIN: ["Qty Confirm", "Invoice Confirm"],
      CUSTOMER: [],
    };

    const filteredCol = userListColInfo.filter(
      (col) => !restrictedColumns[userRole]?.includes(col.name)
    );

    setFilteredUserListColInfo(filteredCol);
    // console.log(">> filteredCol", filteredCol);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleSuccessData = async (data) => {
    // console.log(">> data", data);

    await ItemService.updateDispatchInvoice(data).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        handleSearchFuncApi();
      } else {
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  //print
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `KYC_${Math.floor(Math.random() * 1000000)}`,
  });

  //filter
  const handleFilterData = async (filter) => {
    console.log(">> filter", filter);

    // await suppliersChart(filter);
    // await poChart(filter);
  };

//selected row table
const handleRowSelect = ({ selectedRows }) => {
  // console.log("Selected Rows:", selectedRows);
  setSelectedRows(selectedRows);  // Store only selected rows in state
};


  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={8} lg={8}>
                <h2 className="mainTitle"> IOM Credit Note</h2>
                <p className="subText">IOM Credit Note</p>
              </Col>
              <Col xs={10} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
              <Col xs={2} md={1} lg={1}>
                <span
                  className="filterIcon"
                  onClick={() => setFilterShow(!filterShow)}
                >
                  <i class="bi bi-filter"></i>
                </span>
              </Col>
              {filterShow && (
                <>
                  <Col lg={12} className="mt-2">
                    <DatePickerComponent
                      getfiltereddata={handleFilterData}
                      cancelAction={() => setFilterShow(!filterShow)}
                    />
                  </Col>
                </>
              )}
            </Row>
            <hr />
            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CN</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle"> IOM Credit Note</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        // onClick={() => introVideoShow()}
                      >
                        Generate IOM
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={handlePrint}>
                        Print
                      </button>
                      <div style={{ display: "none" }}>
                        <IOMCreditNotePrint
                          ref={contentRef}                          
                          data={selectedRows}
                        />
                      </div>
                    </Col>
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={filteredUserListColInfo}
                          data={data}
                          progressPending={postDispatchDocData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={postDispatchDocTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <InvoiceConfirmationModal
        show={getModalEditInvoice}
        onHide={() => hideModal()}
        invoiceinfo={invoiceData}
        onsuccessdata={handleSuccessData}
        viewInvoiceData={viewInvoiceData}
      />
    </main>
  );
};

export default IOMCreditNoteList;
