/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
// import toast from "react-hot-toast";
// import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

// Components
import { TextAreaField } from "../../formElements/TextAreaField";
// import Constants from "../../../common/Constants";

//Serivce
// import ItemService from "../../../services/supplier_appCommonService";

const ApproveRemarksModal = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [getRemarks, setRemarks] = useState('');

  const handleInputChange = (event) => {    
    setErrorMsg('');
    setRemarks(event.target.value);
  };

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    if(getRemarks !== ''){
      props.onvaluechange(getRemarks);
      props.onHide();
    }else{
      setErrorMsg("*Please enter the remarks")
    }
  };

  useEffect(() => {
    setRemarks('')
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> Remarks
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <TextAreaField
                className="inputBox"
                label="Please Enter the Remarks*"
                name="getRemarks"
                placeholder=""
                value={getRemarks}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Confirm
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default ApproveRemarksModal;
