import React, { useEffect, useState } from "react";
// import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
// import { InputField } from "../../../../Components/formElements/InputField";
// import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
// import { validateRequired } from "../../../../common/Validations";
// import toast from "react-hot-toast";
// import Spinner from "react-bootstrap/Spinner";

//Serivce
import ItemService from "../../services/supplier_appCommonService";
import FileThumbnail from "./FileThumbnail";
import Loader from "../Loader";

const FileViewerModal = React.forwardRef((props, ref) => {
  const [files, setFiles] = useState();
  const [status, setStatus] = useState(true);
  const [propsLoaded, setPropsLoaded] = useState(true);

  const loadUploadedFiles = async (attachmentId) => {
    if (attachmentId) {
      let obj = {
        attachmentId,
      };
      setStatus(true);
      await ItemService.getUploadedFiles(obj).then((item) => {
        // console.log(">> getUploadedFiles ", item.data);
        setFiles(item.data);
      });
      setStatus(false);
    } else {
      // alert('Please select a file first.');
    }
  };

  useEffect(() => {
    if (propsLoaded) {
      // console.log(">>props", props);
      loadUploadedFiles(props?.fileid);
      setPropsLoaded(true);
    }

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fileid]);

  return (
    <Modal
      {...props}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Attachment Files
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Row>
          <Loader isLoading={status} className={"mt-4"} />
          {!status && <FileThumbnail files={files} />}
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default FileViewerModal;
