import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

// Components
import Constants from "../../../common/SupplierConstants";
import ModalCommonFilter from "../../Filter/Modal/ModalCommonFilter";

//Redux
import { useSelector} from "react-redux";
import DataTable from "react-data-table-component";

const CategorySelectionModal = React.forwardRef((props, ref) => {

  let categorySelectionData = useSelector(
    (state) => state?.supplier?.category_selection?.data
  );

  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [getSelectedData, setSelectedData] = useState([]);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const handleChangeCat = ({ selectedRows }) => {
    setSelectedData(selectedRows);

    if (selectedRows.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    setData(categorySelectionData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelectionData]);

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    props.onselectedcatdata(getSelectedData)
    props.onHide();
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const columns = [
    {
      name: "Category Code",
      selector: (row) => row.vendorCategoryCode || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Category",
      selector: (row) => row.vendorCategoryText || "--",
      sortable: true,
      width: "280px",
    },

    {
      name: "SubCategory",
      selector: (row) => row.vendorSubCategoryText || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Code",
      selector: (row) => row.vendorSubCategoryCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Description",
      selector: (row) => row.vendorSubCategoryLongText || "--",
      sortable: true,
      width: "400px",
    },
   
  ];

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      dialogClassName="modal-90w"
      ref={ref}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Category Selection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={() => toggleCardVisibility()}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">CS</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Category Selection</span>
                        <span className="subHeaderTitle">
                          View of Category Selection{" "}
                        </span>
                      </Col>

                      <Col xs={2} md={1} lg={1} className="">
                      <ModalCommonFilter/>
                     
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            title=""
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            paginationRowsPerPageOptions={
                              Constants.ROW_PER_PAGE
                            }
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                            onSelectedRowsChange={handleChangeCat}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={2} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={2} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Select
              </button>
            </Col>
            {/* <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col> */}
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CategorySelectionModal;
