import React from "react";

const PrintKYCRegistration = React.forwardRef((props, ref) => {
  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "4px",
  };
  const printbody = {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  };
  const { data } = props;
  // console.log(">>printKyc", data);
  const projectCompleted = data?.projectCompleted;
  const porjectOnHand = data?.porjectOnHand;

  const addressProofTypes = {
    "Aadhar Card": "101 - GST Registration ",
    DL: "Driving License",
  };
  // Check if any document does not match the mapped types
  const isOtherSelected = data?.certifications?.some(
    (doc) => !Object.values(addressProofTypes).includes(doc.documentType)
  );

  return (
    <div ref={ref} style={printbody} className="m-4">
      <table style={tableStyle}>
        <tr style={tableStyle}>
          <td colSpan="4">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="./assets/img/BharthiCementLogo/BharthiCementLogo.png"
                alt="Bharthi_Cement_logo"
              />
              <h4 className="m-0">KYC/CREDIT APPROVAL FORM</h4>
              <img
                src="./assets/img/BharthiCementLogo/vicatLogo.png"
                alt="vicatlogo"
              />
            </div>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" className="w-25">
            Company Name
          </td>

          <td colSpan="2" style={tableStyle}>
            {" "}
            {data?.organisationName}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Company/Firm Address
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.address + data?.address2}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Email ID
          </td>

          <td colSpan="2" style={tableStyle}>
            {data?.contactDetails?.[0]?.contactEmail || "N/A"}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Contact Person Name
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.contactDetails?.[0]?.contactName || ""}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Contact Person Mobile No.
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.contactDetails?.[0]?.contactNumber || ""}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Address Proof
          </td>
          <td colSpan="2" style={tableStyle}>
            {Object.entries(addressProofTypes).map(([label, type]) => (
              <label key={label} className="me-3">
                <input
                  type="checkbox"
                  checked={data?.certifications?.some(
                    (doc) => doc.documentType === type
                  )}
                  readOnly
                />{" "}
                {label}
              </label>
            ))}
            <label>
              <input type="checkbox" checked={isOtherSelected} readOnly /> Other
            </label>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            GST Number
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.certifications?.[0].documentNumber}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Credit Limit
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.companyProfile?.creditLimit}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Credit Period
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.companyProfile?.creditPeriodDays}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Security Mode
          </td>
          <td colSpan="2" style={tableStyle}>
            <label className="me-3">
              <input type="checkbox" checked /> Cheque
            </label>
            <label className="me-3">
              <input type="checkbox" /> DD
            </label>
            <label className="me-3">
              <input type="checkbox" /> Other
            </label>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Details Of Security
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.companyProfile?.detailsOfSecurity}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Sales Officer
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.salesOfficerName}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            <label>
              <input type="checkbox" checked /> Proprietor
            </label>
            <label>
              <input type="checkbox" /> Partner
            </label>
            <label>
              <input type="checkbox" /> Director
            </label>
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.salesOfficerName}
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Type of Company/Firm
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.companyProfile?.typeOfCompany}
          </td>
        </tr>
        <tr rowSpan={2} style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Type Of Customer Segment
          </td>
          <td colSpan="2" style={tableStyle}>
            {data?.companyProfile?.typeOfCustomerSegment}
          </td>
        </tr>
        {/* loop this financial year */}
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Company Annual Turnover
          </td>
          {data?.financialTurnover?.map((turnover, index) => (
            <tr key={index}>
              <td>{turnover?.turnoverYear}:</td>
              <td>
                {turnover?.valueTurnover} {turnover?.lakhsCroreTurnover},
              </td>
            </tr>
          ))}
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            No. Of Years in Business
          </td>
          <td colSpan="2" style={{ ...tableStyle, width: "34%" }}>
            {data?.companyProfile?.numberOfYearsInBusiness}Years
          </td>
        </tr>
        {/* loop this project Completed */}
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Projects Completed
          </td>
          <td style={tableStyle}>Project Name </td>
          <td style={tableStyle}>Project Tenure </td>
        </tr>
        {/* loop  from here this project Completed */}
        {projectCompleted &&
          projectCompleted.map((items, i) => (
            <>
              <tr style={tableStyle} key={i}>
                <td colSpan="2" style={tableStyle}>
                  {items.local_id}
                </td>
                <td style={tableStyle}> {items.projectNameCopleted} </td>
                <td style={tableStyle}> {items.endDateCompleted} </td>
              </tr>
            </>
          ))}
        {/* loop this project on Hand */}
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Projects On Hand
          </td>
          <td style={tableStyle}>Project Name </td>
          <td style={tableStyle}>Project Tenure </td>
        </tr>
        {/* loop  from here this project on Hand */}
        {porjectOnHand &&
          porjectOnHand.map((items, i) => (
            <>
              <tr style={tableStyle} key={i}>
                <td colSpan="2" style={tableStyle}>
                  {items.local_id}
                </td>
                <td style={tableStyle}> {items.projectNameOnHand} </td>
                <td style={tableStyle}> {items.endDateOnHand} </td>
              </tr>
            </>
          ))}

        <tr style={tableStyle}>
          <td colSpan="4" style={tableStyle}>
            <span>Company Profile:</span>
            <div dangerouslySetInnerHTML={{ __html: data?.customerProfile }} />
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Attachments:
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="1" style={tableStyle}>
            1
          </td>
          <td colSpan="1" style={tableStyle}>
            GST Certificate
          </td>
          <td colSpan="2" style={tableStyle}>
            <label>
              <input type="checkbox" checked={true} />
            </label>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="1" style={tableStyle}>
            2
          </td>
          <td colSpan="1" style={tableStyle}>
            Address Proof
          </td>
          <td colSpan="2" style={tableStyle}>
            <label>
              <input type="checkbox" checked={true} />
            </label>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="1" style={tableStyle}>
            3
          </td>
          <td colSpan="1" style={tableStyle}>
            Cheque Copy
          </td>
          <td colSpan="2" style={tableStyle}>
            <label>
              <input type="checkbox" checked={true} />
            </label>
          </td>
        </tr>
      </table>

      {/* signature  */}
      <table style={tableStyle} className="mt-4">
        <thead style={tableStyle}>
          <tr>
            <th style={tableStyle} className="text-center">
              Verified By
            </th>
            <th style={tableStyle} colSpan={2} className="text-center">
              Approved By
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableStyle}>
              <span className="fw-bold d-flex justify-content-center">
                SRIVALLABHA
              </span>
              <br />
              <br />
              <br />
              <span className="d-flex justify-content-center">DGM</span>
            </td>
            <td>
              <span className="fw-bold d-flex justify-content-center">
                DEEPAK RAJENDRAN
              </span>
              <br />
              <br />
              <br />
              <span className="d-flex justify-content-center">DIRECTOR</span>
            </td>
            <td style={tableStyle}>
              <spanc className="fw-bold d-flex justify-content-center">
                HARISH KAMARTHY
              </spanc>
              <br />
              <br />
              <br />
              <span className="d-flex justify-content-center">DIRECTOR</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default PrintKYCRegistration;
