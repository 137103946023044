import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";

//component
import Layout from "../../../../Layout/Layout";
import HomeIntorVideo from "../../../../Components/Modal/supplier_app/HomeIntro/HomeIntorVideo";

const ManagerManual = () => {
  localStorage.setItem("menu", "Contract");
  localStorage.setItem("submenu", "Manager Manual");

  const files = [
    { name: "1.1.  Customer Portal Introduction", url: "" },
    { name: "1.2.  Dashboard Guide", url: "" },
    { name: "1.3.  Request customer for Registration", url: "" },
    { name: "1.4.  PR RFQ and Manual RFQ Creation", url: "" },
    { name: "1.5.  Manual PR Creation", url: "" },
    // { name: "1.6.  Manual Purchase-Order Creation", url: "" },
    // { name: "1.7.  Gatepass Creation ", url: "" },
    // { name: "1.8.  Single Source Creation", url: "" },
    { name: "1.6.  Commercial Evaluation Preparation", url: "" },
    { name: "1.7. Purchase-Order Creation", url: "" },
    // { name: "1.11. Auction Creation", url: "" },
    // { name: "1.12. Delegation Creation", url: "" },
    { name: "1.8. User Manual Guide", url: "" },
  ];
  const videos = [
    {
      name: "Supplier Management",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-people-fill",
    },
    {
      name: "Sourcing",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-file-earmark-check",
    },
    {
      name: "Procurement",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-card-list",
    },
    // {
    //   name: "Auction",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "Contract",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "Spend Analysis",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement34",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement3",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement4",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement3432",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement423432",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
  ];

  const Attachments = ({ files }) => {
    return (
      <div className="attachments-list">
        <Row>
          {files.map((file, index) => (
            <Col key={index} md={4} className="commLeftRightPadding">
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <i
                        className="bi bi-file-earmark-text"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </Col>
                    <Col xs={6}>
                      <p
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className="pt-2"
                      >
                        {file.name}
                      </p>
                    </Col>
                    <Col xs={4} className="text-end">
                      <button className="btn btnTable" href={file.url} download>
                        <i className="bi bi-download"></i>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const [getIntroVideo, setIntroVideo] = useState(false);
  const [getVideoPath, setVideoPath] = useState();

  const introVideoShow = (getVideoPath) => {
    setIntroVideo(true);
    setVideoPath(getVideoPath);
  };

  const introVideoHide = () => {
    setIntroVideo(false);
  };

  const VideoFiels = ({ videos }) => {
    return (
      <>
        {videos.map((item, index) => (
          <>
            <div key={index} className="col-lg-2 commTopButtonRightLeftPadding">
              <div
                onClick={() => introVideoShow(item.videoUrl)}
                className="m-0 card info-card sales-card"
              >
                <div className="card-body">
                  <h5 className="card-title">{item.name}</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className={item.icons}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    );
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Manager User Manual </h2>
                <p className="subText">
                  User Manual Documents and Process Video
                </p>
              </Col>
              <hr />
              {/* downloads */}
              <Col
                xs={12}
                md={12}
                lg={12}
                className="commTopButtonRightLeftPadding"
              >
                <h1 className="card-title">User Manual Documents :</h1>
                <Attachments files={files} />
              </Col>
              {/* videos */}
              <Col xs={12} md={12} lg={12} className="headers mb-2">
                <h1 className="card-title">Process :</h1>
                <div className="row d-flex justify-content-start">
                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        <h5 className="card-title">
                          Supplier Portal Introduction
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <VideoFiels videos={videos} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <HomeIntorVideo
        show={getIntroVideo}
        onHide={introVideoHide}
        videopath={getVideoPath}
      />
    </main>
  );
};

export default ManagerManual;

// import React from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Layout from "../../../Layout/Layout";

// const BuyerManual = () => {
//   localStorage.setItem("menu", "Admin");
//   localStorage.setItem("submenu", "");

//   return (
//     <main className="dashboard main" id="main">
//       <Layout />
//       <Row className="justify-center subContent">
//         <Col xs={12} md={12} className="rightSide">
//           <div className="rightSideInner PageCommonCSS">
//             <Row>
//               <Col xs={12} md={10} lg={10} className="mb-2">
//                 <h2 className="mainTitle">BuyerManual </h2>
//                 <p className="subText">BuyerManual</p>
//               </Col>
//               <hr />
//             </Row>
//           </div>
//         </Col>
//       </Row>
//     </main>
//   );
// };

// export default BuyerManual;
