import React, { Suspense } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./common.scss";
import "./dashboard.scss";
import "./customer.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-widgets/scss/styles.scss";
import AppRouters from "./AppRouters";
import { Providers } from "./redux/provider";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  // Initialize with your GA4 Measurement ID
  ReactGA.initialize(process.env.REACT_APP_GA_ID); // Replace with your Measurement ID

  // Optionally, send a pageview for the initial page load
  ReactGA.send("pageview");
  

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider i18n={i18n}>
        <Providers>
          <BrowserRouter future={{ v7_startTransition: true }}>
            <AppRouters />
          </BrowserRouter>
        </Providers>
      </I18nextProvider>
    </Suspense>
  );
}
export default App;
