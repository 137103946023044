import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:{}
};

export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        reportInfo(state,action){
            console.log(">> Data ", action.payload);
            
            state.data = action.payload
        },
    },
  });

export const { reportInfo } = reportSlice.actions;
export default reportSlice.reducer;
