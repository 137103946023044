/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../Layout/Layout";
import LineChart from "./Charts/LineChart";
import AreaChart from "./Charts/AreaChart";
import ColumnChart from "./Charts/ColumnChart";
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import DonutChart from "./Charts/DonutChart";

const Dashboard = () => {
  localStorage.setItem("menu", "Dashboard");
  localStorage.setItem("submenu", "-");

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">Customer Dashboard</h2>
            <p className="subText">Dashboard detailed page</p>
            <hr />

            <section className="section">
              <div className="row">
                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">
                      Sales Order <span>| Today</span>
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-cart"></i>
                        </div>
                        <div className="ps-3">
                          <h6>145</h6>
                          <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "}
                          <span className="text-muted small pt-2 ps-1">
                            increase
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Revenue <span>| This Month</span>
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-dollar"></i>
                        </div>
                        <div className="ps-3">
                          <h6>$3,264</h6>
                          <span className="text-success small pt-1 fw-bold">
                            8%
                          </span>{" "}
                          <span className="text-muted small pt-2 ps-1">
                            increase
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Customer <span>| This Year</span>
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>1244</h6>
                          <span className="text-danger small pt-1 fw-bold">
                            12%
                          </span>{" "}
                          <span className="text-muted small pt-2 ps-1">
                            decrease
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Total Users <span>| This Year</span>
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>254</h6>
                          <span className="text-danger small pt-1 fw-bold">
                            10%
                          </span>{" "}
                          <span className="text-muted small pt-2 ps-1">
                            decrease
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Line Chart - Sales Order </h5>

                      <div id="lineChart"></div>
                      <LineChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Area Chart - Revenue</h5>

                      <div id="areaChart"></div>
                      <AreaChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Column Chart</h5>

                      <div id="columnChart"></div>
                      <ColumnChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Bar Chart</h5>

                      <div id="barChart"></div>
                      <BarChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Pie Chart</h5>

                      <div id="pieChart"></div>
                      <PieChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Donut Chart</h5>

                      <div id="donutChart"></div>
                      <DonutChart />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default Dashboard;
