import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";

// Components
import { ComboboxField } from "../../formElements/ComboboxField";

//Serivce
// import ItemService from "../../../services/supplier_appCommonService";

//Redux
import { useDispatch } from "react-redux";
import { addPurchaseDocType } from "../../../redux/feature/supplier_app/sourcingSlice";

const CreateDocTypeModal = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");
  const [getDropdownOption, setDropdownOption] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");

  // Handle Submit IviteTeamMembers
  const handleSubmit = async () => {
    // console.log(">> getDropdownData", getDropdownData);    
    if (!getDropdownData?.status) {
      setErrorMsg("* Fields are mandatory");
      return;
    } else {
      setErrorMsg("");
    }
    dispatch(addPurchaseDocType(getDropdownData));
    navigate(props?.modaldata?.navigate);
  };

  useEffect(() => {
    setDropdownOption(props?.dropdowndata);

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> {props?.modaldata?.modalTitle}
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            {props?.modaldata?.documentNumbers && props?.modaldata?.documentNumbers.map((item, index) => (
              <Col xs={5} lg={2} className="me-2" key={index}>
                <p className="bg-table-status bg-table-new-status mb-1 px-2">
                  {item}
                </p>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <ComboboxField
                label="RFQ Category"
                placeholder="please select the RFQ Category Type"
                data={getDropdownOption}
                id="rfqCategoryType"
                iconClassName="dropdownIcon"
                name="rfqCategoryType"
                setValue={getDropdownData?.name || []}
                getvalue={setDropdownData}
                className="dropdownOption"
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreateDocTypeModal;
