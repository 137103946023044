import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../Layout/Layout";
// import LineChart from "./Charts/LineChart";
// import AreaChart from "./Charts/AreaChart";
import OrdersColumnChart from "./Charts/OrdersColumnChart";
// import POColumnChart from "./Charts/POColumnChart";
// import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import DonutChart from "./Charts/DonutChart";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
// import ItemService from "../../services/supplier_appCommonService";
import customerItemService from "../../services/customer_appCommonService";
import LoaderSmall from "../../Components/LoaderSmall";

import {
  // formatNumberShort,
  formatToTwoDigits,
  getCurrentDate,
} from "../../common/Validations";
import DatePickerComponent from "./Charts/FilterComponent/DatePickerComponent";
// import SupplierConstants from "../../common/SupplierConstants";
import CustomerConstants from "../../common/CustomerConstants";

const Dashboard = () => {
  localStorage.setItem("menu", "Dashboard");
  localStorage.setItem("submenu", "-");
  const navigate = useNavigate();

  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  const navigateToMainPage = (page) => {
    if (page === "totalOrderCount") {
      navigate(CustomerConstants.CUSTOMER_V1_ORDER_BOOKING_LIST);
    } else if (page === "orderCompletedCount") {
      navigate(CustomerConstants.CUSTOMER_V1_ORDER_COMPLETE_SCHEDULING_LIST);
    } else if (page === "confirmedDispatchCount") {
      navigate(CustomerConstants.CUSTOMER_V1_DISPATCH_CONFIRMATION_LIST);
    } else if (page === "totalUserCount") {
      navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
    }
  };

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0");

  const [filterShow, setFilterShow] = useState(false);

  const [totalUserCount, setTotalUserCount] = useState("");
  const [totalUserCountStatus, setTotalUserCountStatus] = useState(false);

  const [confirmedDispatchCount, setConfirmedDispatchCount] = useState("");
  const [confirmedDispatchStatus, setConfirmedDispatchStatus] = useState(false);

  const [orderCompletedCount, setOrderCompletedCount] = useState("");
  const [orderPendingCount, setPendingCount] = useState("");
  const [orderCompletedStatus, setOrderCompletedStatus] = useState(false);

  const [totalOrderCount, setTotalOrderCount] = useState("");
  const [totalOrderCountStatus, setTotalOrderStatus] = useState(false);

  const [supplierStatusColChart, setSupplierStatusColChart] = useState("");
  const [supplierStatusColChartStatus, setSupplierStatusColChartStatus] =
    useState(false);

  const [totalPostDispatchCount, setTotalPostDispatchCount] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [totalPostDispatchCountStatus, setTotalPostDispatchCountStatus] =
    useState(false);

  const [hideItem, setHideItem] = useState(false);

  // loadOrderCount
  const loadOrderCount = async () => {
    setTotalOrderStatus(true);
    let obj = {
      queryName: "GetTotalOrderCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalOrderCount(item.data);

        loadOrderCompletedCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalOrderStatus(false);
  };

  // loadOrderCompletedCount
  const loadOrderCompletedCount = async (totalOrderCountVal) => {
    setOrderCompletedStatus(true);
    let obj = {
      queryName: "totalOrderCompletedCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setOrderCompletedCount(item.data);
        let orderComplete = item.data;
        setPendingCount(totalOrderCountVal - orderComplete);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setOrderCompletedStatus(false);
  };

  // loadConfirmedDispatchCount
  const loadConfirmedDispatchCount = async () => {
    setConfirmedDispatchStatus(true);
    let obj = {
      queryName: "totalConfirmedDispatchCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setConfirmedDispatchCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setConfirmedDispatchStatus(false);
  };

  // loadCustomerCount
  const loadCustomerCount = async () => {
    setTotalUserCountStatus(true);
    let obj = {
      queryName: "totalCustomerCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalUserCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalUserCountStatus(false);
  };

  const loadPostDispatchCount = async () => {
    setTotalPostDispatchCountStatus(true);
    let obj = {
      queryName: "totalPostDispatchCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalPostDispatchCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalPostDispatchCountStatus(false);
  };

  // Suppliers Chart
  const suppliersChart = async (filterData) => {
    setSupplierStatusColChartStatus(true);
    filterData.module = "order";
    filterData.chartType = "column";

    await customerItemService.dashboard(filterData).then((item) => {
      // console.log(">> dashboard res!", item);
      if (item?.status === 200) {
        item.data.module = "order";
        setSupplierStatusColChart(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setSupplierStatusColChartStatus(false);
  };

  // PO Chart
  // const poChart = async (filterData) => {
  //   setPOStatusColChartStatus(true);
  //   filterData.module = "po";
  //   filterData.chartType = "pie";

  //   await ItemService.dashboard(filterData).then((item) => {
  //     // console.log(">> dashboard res!", item);
  //     if (item?.status === 200) {
  //       item.data.module = "po";
  //       setPOStatusColChart(item.data);
  //     } else {
  //       toast.error(item?.message || "Please try again for Total User Count", {
  //         duration: 4000,
  //         position: "top-right",
  //       });
  //     }
  //   });
  //   setPOStatusColChartStatus(false);
  // };

  // On Filtered Data
  const handleFilterData = async (filter) => {
    // console.log(">> filter", filter);

    await suppliersChart(filter);
    // await poChart(filter);
  };

  useEffect(() => {
    let defaultFilter = {
      action: "Monthly",
      month: currentMonth,
      year: currentYear,
    };

    loadOrderCount();
    // loadOrderCompletedCount();
    loadPostDispatchCount();
    loadConfirmedDispatchCount();
    loadCustomerCount();


    suppliersChart(defaultFilter);
    // poChart(defaultFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;

    if (userRole === "CUSTOMER") {
      setHideItem(true);
    } else {
      setHideItem(false);
    }
    // console.log(">> userRole", userRole);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={10} md={10}>
                <h2 className="mainTitle">
                  Dashboard -
                  <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                    {getCurrentDate()}
                  </span>
                </h2>
                <p className="subText my-0">Dashboard detailed page</p>
              </Col>
              <Col xs={2} md={2}>
                <span
                  className="filterIcon"
                  onClick={() => setFilterShow(!filterShow)}
                >
                  <i class="bi bi-filter"></i>
                </span>
              </Col>
            </Row>
            <hr />

            <section className="section">
              <div className="row">
                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Orders
                        {/* <span>| Today</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-boxes"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={totalOrderCountStatus} />
                          <h6>
                            {!totalOrderCountStatus
                              ? formatToTwoDigits(totalOrderCount || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Total Count
                          </span>
                          <span
                            className="text-muted small pt-2 ps-1"
                            onClick={() =>
                              navigateToMainPage("totalOrderCount")
                            }
                          >
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Completed / Pending
                        {/* <span>| This Month</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-box-seam"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={orderCompletedStatus} />
                          <h6>
                            {!orderCompletedStatus
                              ? formatToTwoDigits(orderCompletedCount || 0) +
                                " / " +
                                formatToTwoDigits(orderPendingCount || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Order Counts
                          </span>
                          <span
                            className="text-muted small pt-2 ps-1"
                            onClick={() =>
                              navigateToMainPage("orderCompletedCount")
                            }
                          >
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Dispatch / Confimed
                        {/* <span>| This Year</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-truck"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={confirmedDispatchStatus} />
                          <h6>
                            {!confirmedDispatchStatus
                              ? formatToTwoDigits(totalPostDispatchCount || 0) + " / " +
                                formatToTwoDigits(confirmedDispatchCount || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Count
                          </span>
                          <span
                            className="text-muted small pt-2 ps-1"
                            onClick={() =>
                              navigateToMainPage("confirmedDispatchCount")
                            }
                          >
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!hideItem && (
                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card customers-card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Customers
                          {/* <span>| This Year</span> */}
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                          </div>
                          <div className="ps-3">
                            <LoaderSmall isLoading={totalUserCountStatus} />
                            <h6>
                              {!totalUserCountStatus
                                ? formatToTwoDigits(totalUserCount || 0)
                                : ""}
                            </h6>
                            {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                            <span className="text-muted small pt-2 ps-1">
                              Total Count
                            </span>
                            <span
                              className="text-muted small pt-2 ps-1"
                              onClick={() =>
                                navigateToMainPage("totalUserCount")
                              }
                            >
                              <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
              {filterShow && (
                <DatePickerComponent
                  getfiltereddata={handleFilterData}
                  cancelAction={() => setFilterShow(!filterShow)}
                />
              )}
              <div className="row">
                {/* Orders */}
                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Orders</h5>

                      {/* <div id="columnChart"></div> */}
                      <LoaderSmall isLoading={supplierStatusColChartStatus} />
                      <h6>
                        {!supplierStatusColChartStatus ? (
                          <OrdersColumnChart
                            key="order"
                            chartdata={supplierStatusColChart}
                          />
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </div>
                </div>

                {/* PO */}
                {/* <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Purchase Order</h5>
                      <LoaderSmall isLoading={poStatusColChartStatus} />
                      <h6>
                        {!poStatusColChartStatus ? (
                          <POColumnChart
                            key="po"
                            chartdata={poStatusColChart}
                          />
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Procurement</h5>

                      <div id="lineChart"></div>
                      <LineChart />
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Revenue</h5>

                      <div id="areaChart"></div>
                      <AreaChart />
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Country</h5>

                      <div id="barChart"></div>
                      <BarChart />
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Sales Team</h5>

                      <div id="pieChart"></div>
                      <PieChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Marketing Team</h5>

                      <div id="donutChart"></div>
                      <DonutChart />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default Dashboard;
