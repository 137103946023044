import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
// import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import PageLevelRiverseTimer from "../../../../Components/PageLevelRiverseTimer";
import DataTable from "react-data-table-component";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import {
  formatDateTime,
  validateRequired,
} from "../../../../common/Validations";
import { Card } from "react-bootstrap";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import moment from "moment";
import ShowHorizontalStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowHorizontalStatus";
import Loader from "../../../../Components/Loader";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import { getRfqQuotation } from "../../../../redux/feature/supplier_app/sourcingSlice";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";

const BuyerRFQDetails = () => {
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  let rfxNr = useSelector((state) => state?.sourcing?.rfq?.rfxNr); // User Details from Store
  let config = useSelector((state) => state?.common?.data?.workflow); // config Details from Store
  let sourcingConfig = useSelector((state) => state?.common?.data?.sourcing); // Common Details from Store

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getTechnicalUserList, setTechnicalUserList] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  // eslint-disable-next-line no-unused-vars
  const [getFailureModal, setFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible10, setCardVisible10] = useState(true);

  const [finalTimerValue, setFinalTimerValue] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const modalDataTeach = {
    title: "Tech Open",
    successMsg: "",
  };
  const modalDataComm = {
    title: "Commerial Open",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalDataTeach);

  const [formInputs, setFormInputs] = useState({
    rfxType: "",
    productType: "",
    rfxValue: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    buyerResponsible: "",
    companyAddress: "",
    rfxVersion: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    billingAddressCodeDesc: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCategoryCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxTitle: "",
    openingdate: "",
    openingtime: "",
    closingdate: "",
    closingtime: "",
    rfxDescription: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    items: "",
    formCOMM: "",
    formTECH: "",
    termsConditions: [],
    supplierList: [],

    openingDateTime: "",
    closingDateTime: "",
    totalHour: "",
    rfxTechFocalPersonIdCode: "",
  });

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility10 = () => {
    setCardVisible10(!isCardVisible10);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "200px",
    },
    {
      name: "Unit Price ",
      selector: (row) => row.orderPriceUnit || "--",
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      width: "120px",
    },
  ];

  const suppliersColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "170px",
    },

    {
      name: "Name",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactfirstName || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Contact Number",
      selector: (row) => row.contactNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "EMail",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "RFX Status",
      selector: (row) => row.rfxSupplierStatus,
      cell: (row) => (
        <>
          { row.rfxSupplierStatus === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxSupplierStatus}
            </span>
          ) : row.rfxSupplierStatus === "Regret" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.rfxSupplierStatus}
            </span>
          ) : (
            row.rfxSupplierStatus || "--" )}
        </>
      ),
      width: "170px",     
      sortable: true,
     
    },
    {
      name: "Supplier Status",
      cell: (row) => (
        <>
          {row.qtnSupplierStatus === "QTN Received" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.qtnSupplierStatus}
            </span>
          ) : (
            "--"
          )}
        </>
      ),
      selector: (row) => row.qtnSupplierStatus,
      sortable: true,
      width: "150px",
    },
    {
      name: "Quotation Number",
      cell: (row) => (
        <>
          {row.qtnSupplierStatus === "QTN Received" ? (
            <button className="btnInfo">{row.quotationNr}</button>
          ) : (
            "--"
          )}
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Quotation Version",
      selector: (row) => row.qtnVersion|| "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Category",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            // onClick={() => categoryItemShowModal(row.vendorCategory)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.vendorCategory?.length || 0}{" "}
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Company Code",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      width: "150px",
    },
  ];

  const loadInitialPageData = async () => {
    if (rfxNr) {
      let obj = {
        documentNumber: rfxNr,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getRfxDocumentDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item.data);
          setData(item?.data?.items);

          updateTimerValue(item?.data?.closingDateTime);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
      setLoading(false);
    } else {
      window.history.back();
    }
  };

  // PO items columns Details
  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // Show Item level data
  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Item Text Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> Check data", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const loadTechUser = async () => {
    let obj = {
      roleId: "TECHNICAL",
    };
    await ItemService.getTechnicalUser(obj).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setTechnicalUserList(item?.data);
      } else {
        setTechnicalUserList([]);
        console.log(">> Error: Loading loadTechUser");
      }
    });
  };

  useEffect(() => {
    loadInitialPageData();
    loadTechUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxNr]);

  // Render Questions
  const RenderInputField = ({ questionData }) => {
    const { componentId, mandatory, question, answerDefinition, serialNo } =
      questionData;

    switch (answerDefinition?.type) {
      case "textarea":
        return (
          <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
            <TextAreaField
              label={serialNo + ". " + question?.label}
              id={componentId}
              name={componentId}
              className="inputBox"
              placeholder=""
              // value={getRemarks}
              // onChange={handleInputChange}
              required={mandatory}
            />
          </Col>
        );
      case "select":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <ComboboxField
              label={serialNo + ". " + question?.label}
              placeholder=""
              data={answerDefinition?.options}
              id={componentId}
              iconClassName="dropdownIcon"
              name={componentId}
              code={componentId}
              required={mandatory}
              // setValue={formData.type || ""}
              // getvalue={setDropdownData}
            />
          </Col>
        );
      case "radio":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="radio"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "checkbox":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="checkbox"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "text":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="text"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      case "number":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="number"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      default:
        return "";
    }
  };

  const files = [
    { name: "1.1. SCOPE OF WORK", url: "" },
    { name: "1.2. DRAWING", url: "" },
    { name: "1.3. ANNEXURES", url: "" },
    { name: "1.4. OTHERS", url: "" },
  ];

  // Attachments
  const Attachments = ({ files }) => {
    return (
      <div className="attachments-list">
        <Row>
          {files.map((file, index) => (
            <Col key={index} md={4} className="commLeftRightPadding">
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <i
                        className="bi bi-file-earmark-text"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </Col>
                    <Col xs={6}>
                      <p
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className="pt-2"
                      >
                        {file.name}
                      </p>
                    </Col>
                    <Col xs={4} className="text-end">
                      <button className="btn btnTable" href={file.url} download>
                        <i className="bi bi-download"></i>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const updateTimerValue = (closingDateTime) => {
    let timerData = {};
    const openingMoment = moment(); // This is the current date-time
    const closingMoment = moment(closingDateTime, "YYYY-MM-DD HH:mm");
    const diffInMilliseconds = closingMoment.diff(openingMoment);
    const duration = moment.duration(diffInMilliseconds);

    // If the time is already past, return 0 and set timeExpired flag
    if (diffInMilliseconds <= 0) {
      setFinalTimerValue({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      // Extract days, hours, minutes, and seconds correctly
      timerData.days = Math.floor(duration.asDays()) || 0;
      timerData.hours = duration.hours() || 0; // Remaining hours after days
      timerData.minutes = duration.minutes() || 0; // Remaining minutes after hours
      timerData.seconds = duration.seconds() || 0; // Remaining seconds after minutes
      setFinalTimerValue(timerData);
    }
  };

  const handleTextChange = (data) => {
    // console.log(">> data", data);
  };

  const [getTechOpenDisabled, setTechOpenDisabled] = useState(false);
  const handleTechOpen = async () => {
    let requiredFields = [];

    if (!validateRequired(formInputs?.rfxTechFocalPersonIdCode)) {
      requiredFields.push("*Please select the Tech Focal Point");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    let obj = {
      rfxNr: formInputs.rfxNr,
      techUserId: formInputs?.rfxTechFocalPersonIdCode,
    };
    setTechOpenDisabled(false);
    await ItemService.rfxTechOpen(obj).then((item) => {
      // console.log(">> item! ", item);
      if (item?.status === 200) {
        modalDataTeach.successMsg = item?.message;
        setModalInfo(modalDataTeach);
        onSuccessfullyRegistered();
        dispatch(getRfqQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTechOpenDisabled(true);
  };

  const handleCommOpen = async () => {
    // let requiredFields = [];

    // if (!validateRequired(formInputs?.rfxTechFocalPersonIdCode)) {
    //   requiredFields.push("*Please select the Tech Focal Point");
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    let obj = {
      rfxNr: formInputs.rfxNr,
      commUserId: "" // Add user in future
    };
    setTechOpenDisabled(false);
    await ItemService.rfxCommOpen(obj).then((item) => {
      // console.log(">> item! ", item);
      if (item?.status === 200) {
        modalDataComm.successMsg = item?.message;
        setModalInfo(modalDataComm);
        onSuccessfullyRegistered();
        dispatch(getRfqQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTechOpenDisabled(true);
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <Loader isLoading={loading} className={"text-center"} />
        </Col>
        {!loading && (
          <Col xs={12} md={12} className="rightSide">
            <div className="rightSideInner PageCommonCSS">
              <h2 className="mainTitle">
                Buyer RFQ: <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {formInputs?.rfxNr}
                </span>
                <span>
                  {formInputs?.rfxHeaderStatus === "Published" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "QTN Received" ? (
                    <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Cancelled" ? (
                    <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "RFQ Created" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Acknowledged" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Tech Approved" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Technical Open" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Commercial Open" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : (
                    formInputs?.rfxHeaderStatus
                  )}
                </span>
              </h2>
              <p className="subText">Buyer RFQ Details</p>
              <hr />

              {config?.showInDetailPage &&
                formInputs?.workflowActionLogs?.length > 0 && (
                  <ShowHorizontalStatus
                    rowdatamodal={formInputs?.workflowActionLogs}
                  />
                )}

              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={12} md={5} lg={5} className="">
                        <p className="headerTitle fs-4">
                          Remaining Time for submission
                        </p>
                      </Col>
                      <Col xs={12} md={7} lg={7} className="flex justify-end">
                        <img
                          src="assets/img/stopwatch.png"
                          alt="stopwatch"
                          style={{ width: "60px", height: "58px" }}
                          className="mr-6 mt-1"
                        />
                        <PageLevelRiverseTimer timerData={finalTimerValue} />
                      </Col>
                    </Row>
                  </h2>
                </div>
              </div>

              {/*RFQ Type  */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible1
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility1}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RT</span>
                      </Col>
                      <Col xs={12} md={4} lg={4}>
                        <span className="headerTitle">RFQ Type </span>
                        <span className="subHeaderTitle">
                          RFQ Type Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible1 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Category"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCategoryDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Type"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        {/* <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Header User id"
                            name="UnitPrice"
                            // placeholder=""
                            // value={PriceSheduleModuledata.UnitPrice || ""}

                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col> */}
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Number Of Evaluation"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxNoOfEvaluationTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Product Type"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.productTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Tech Focal Point"
                            placeholder=""
                            data={getTechnicalUserList}
                            id="rfxTechFocalPersonId"
                            name="rfxTechFocalPersonId"
                            code="rfxTechFocalPersonIdCode"
                            description="rfxTechFocalPersonIdDesc"
                            iconClassName="dropdownIcon"
                            setValue={formInputs.rfxTechFocalPersonId || ""}
                            getvalue={setDropdownData}
                            // className="dropdownOption"
                            disabled={formInputs.rfxHeaderStatus === 'Tech Approved' ? true : false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/*RFQ Type  */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RH</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">RFQ Header </span>
                        <span className="subHeaderTitle">
                          RFQ Header Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ ID"
                            name="rfxNr"
                            // placeholder=""
                            value={formInputs?.rfxNr || ""}
                            // onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Currency"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCurrencyCode || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Contact"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCurrencyCode || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Responsible"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.initiatorName || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Version"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.rfxVersion || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Billing Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.billingAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Company Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Delivery Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/*Header Info */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible3
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility3}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">HI</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Header Info</span>
                        <span className="subHeaderTitle">
                          RFQ Header Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible3 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Title"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTitle || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Description"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxDescription || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Opening Date"
                            name="UnitPrice"
                            // placeholder=""
                            value={
                              formatDateTime(formInputs?.openingDateTime) || ""
                            }
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Closing Date"
                            name="UnitPrice"
                            // placeholder=""
                            value={
                              formatDateTime(formInputs?.closingDateTime) || ""
                            }
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.INCOTermslocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms Location"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.incoTermsLocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Payment Terms"
                            name="firstName"
                            placeholder=""
                            // value={"400000 - Vasanthraman"}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Other Currencies"
                            name="firstName"
                            placeholder=""
                            // value={"400000 - Vasanthraman"}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        {/* <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Milestone Payments"
                            name="firstName"
                            placeholder=""
                            // value={"400000 - Vasanthraman"}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col> */}
                        {/* <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Instruction Attachments"
                            name="firstName"
                            placeholder=""
                            // value={"400000 - Vasanthraman"}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Instructions 10*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible10
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility10}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RE</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">Instructions</span>
                        <span className="subHeaderTitle">
                          Instructions Section
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible10 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="p-4">
                        <Col xs={12} md={12} lg={12}>
                          {formInputs?.instructions && (
                            <RichTextEditor
                              value={formInputs?.instructions}
                              readOnly={true}
                              onChange={handleTextChange}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Documents */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible4
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility4}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">TD</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Documents</span>
                        <span className="subHeaderTitle">
                          Documents Form
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible4 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          className="commTopButtonRightLeftPadding"
                        >
                          <h1 className="card-title">Documents :</h1>

                          <Attachments files={files} />
                          <h5 className="card-title"> Terms & Conditions</h5>
                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={8}
                              className="commTopButtonRightLeftPadding"
                            >
                              {formInputs?.termsConditions.map((item, i) => (
                                <div className="form-check" key={item.id}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`gridCheck${item.id}`}
                                    checked={item.status}
                                    // onChange={() =>
                                    //   handleTermsConditionCheckBox(item.id)
                                    // }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`gridCheck${item.id}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                              <ComboboxField
                                label="Acceptance To Terms & Conditions"
                                placeholder=""
                                data={dropdownOption}
                                id=""
                                iconClassName="dropdownIcon"
                                name=""
                                // setValue={formInputs.INCOTermslocation || ""}
                                disabled={true}
                                // getvalue={setDropdownData}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* 5 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible5
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility5}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">SD</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Suppliers</span>
                        <span className="subHeaderTitle">
                          Selected Suppliers Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible5 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={suppliersColumns}
                            data={formInputs?.supplierList}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* 6 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible6
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility6}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">LI</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Line Items</span>
                        <span className="subHeaderTitle">
                          Material/ Service Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible6 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={PRLineItemInitialCol}
                            data={data}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* COMM - Spares & Operation Maintenance 08*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible8
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility8}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">OC</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">
                          Form: COMMERCIAL - Questionnaire
                        </span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible8 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="commTopButtonRightLeftPadding">
                        {formInputs?.formCOMM?.length > 0 &&
                          formInputs?.formCOMM.map((formJsonData, index) => {
                            return (
                              <RenderInputField
                                questionData={formJsonData}
                                key={index}
                              />
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Form :TECH - Spares & Operation Maintenance09*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible9
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility9}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">TS</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">
                          Form : TECHNICAL - Questionnaire
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible9 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="commTopButtonRightLeftPadding">
                        {formInputs?.formTECH?.length > 0 &&
                          formInputs?.formTECH.map((formJsonData, index) => {
                            return (
                              <RenderInputField
                                questionData={formJsonData}
                                key={index}
                              />
                            );
                          })}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Single Source Justification*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible8
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility8}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">OC</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">
                          Single Source Justification
                        </span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>

                      <Col xs={1} md={1} lg={1} className="">
                        {/* <SourcingCommonFilter /> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible8 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      {/* Form: INTRO-Spares & Operations Maintenance */}
                      <Row>
                        <Col
                          xs={12}
                          md={12}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Single Source Justification"
                            name=""
                            // placeholder=""
                            // value={PriceSheduleModuledata.UnitPrice || ""}
                            onChange={onInputChange}
                            // disabled={getInputFields}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pageFooter">
                <Row className="rowDirection">
                  {sourcingConfig?.RFX?.pageBtn?.preClose &&
                    (formInputs?.rfxHeaderStatus === "Published" ||
                      formInputs?.rfxHeaderStatus === "QTN Received") && (
                      <Col xs={12} md={2} lg={1} className="">
                        <button
                          className="btnTable"
                          //  onClick={handleModify}
                        >
                          Pre Close
                        </button>
                      </Col>
                    )}

                  {sourcingConfig?.RFX?.pageBtn?.rFQCancel &&
                    (formInputs?.rfxHeaderStatus === "Published" ||
                      formInputs?.rfxHeaderStatus === "Technical Open") && (
                      <Col xs={12} md={2} lg={1} className="">
                        <button
                          className="btnTable"
                          //  onClick={handleModify}
                        >
                          RFQ Cancel
                        </button>
                      </Col>
                    )}

                  {sourcingConfig?.RFX?.pageBtn?.techOpen &&
                    formInputs?.rfxHeaderStatus === "QTN Received" && (
                      <Col xs={12} md={2} lg={1} className="">
                        <button
                          className="btnTable"
                          onClick={handleTechOpen}
                          disabled={getTechOpenDisabled}
                        >
                          Tech Open
                        </button>
                      </Col>
                    )}

                  {sourcingConfig?.RFX?.pageBtn?.commOpen &&
                    (formInputs?.rfxHeaderStatus === "QTN Received" ||
                      formInputs?.rfxHeaderStatus === "Tech Approved") && (
                      <Col xs={12} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                           onClick={handleCommOpen}
                        >
                          Comm Open
                        </button>
                      </Col>
                    )}

                  {sourcingConfig?.RFX?.pageBtn?.rFQModify &&
                    (formInputs?.rfxHeaderStatus === "Published" ||
                      formInputs?.rfxHeaderStatus === "QTN Received" ||
                      formInputs?.rfxHeaderStatus === "Tech Approved" ||
                      formInputs?.rfxHeaderStatus === "Technical Open") && (
                      <Col xs={12} md={2} lg={1} className="">
                        <button
                          className="btnTable"
                          //  onClick={handleModify}
                        >
                          RFQ Modify
                        </button>
                      </Col>
                    )}

                  {sourcingConfig?.RFX?.pageBtn?.excelDownload && (
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        //  onClick={handleModify}
                      >
                        Excel Download
                      </button>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </Col>
        )}
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_BUYER_REQUEST_FOR_QUOTATION}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default BuyerRFQDetails;
