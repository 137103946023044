import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ItemService from "../../services/supplier_appCommonService";

const initialState = {
  appConfig:{},
  info: {
    applications: [],
    defaultAppCode: "",
  },

};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addAppInfo(state, action) {
      state.info = action.payload.data;
    },
    updateAppCode(state, action) {
      state.info.defaultAppCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appConfigFunc.fulfilled, (state, action) => {
      state.appConfig = action.payload;
    });
  },
});

export const { addAppInfo, updateAppCode } = appSlice.actions;
export default appSlice.reducer;

export const appConfigFunc = createAsyncThunk("appConfigFunc", async (param) => {
  console.log(">> loaded appConfigFunc");
  const res = await ItemService.appConfigFunc(param);
  return res.data;
});
