import React from "react";
import { FaFilePdf, FaFileAlt, FaEye } from "react-icons/fa";
import "./FileThumbnail.css"; // Import CSS file

const FileThumbnail = ({ files }) => {
  if (!files || files.length === 0) return null;
  
  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(extension)) return "image";
    if (extension === "pdf") return "pdf";
    return "other";
  };

  // const handleDownload = (fileUrl) => {
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = fileUrl.split("/").pop() || "download"; // Ensure correct filename
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  
  return (
    <div className="file-thumbnails">
      {files.map((fileUrl, index) => {
        const fileType = getFileType(fileUrl.Location);
        return (
          <div key={index} className="file-thumbnail">
            {fileType === "image" ? (
              <img src={fileUrl.Location} alt="file preview" className="thumbnail-image" />
            ) : fileType === "pdf" ? (
              <FaFilePdf className="thumbnail-icon pdf" />
            ) : (
              <FaFileAlt className="thumbnail-icon other" />
            )}
            <div className="file-actions">
              <p></p>
              <a href={fileUrl.Location} target="_blank" rel="noopener noreferrer" className="action-button">
                <FaEye /> View
              </a>
              
              {/* <button onClick={() => handleDownload(fileUrl.Location)} className="action-button">
                <FaDownload /> Download
              </button> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileThumbnail;
