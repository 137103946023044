import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/SupplierConstants";
import CustomerConstants from "../../../../common/CustomerConstants";
//import { formatDateTime } from "../../../../common/Validations";
// import { addUserID } from "../../../../redux/feature/userSlice";
//import configFromEnv from "../../../../services/configuration";
// import Moment from "react-moment";

const PlantList = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  localStorage.setItem("menu", "Master");
  localStorage.setItem("submenu", "Plant Master");

  let userListData = useSelector((state) => state?.user.userList); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState([]);

  const plantMasterData = [
    {
        companyCode: "05000112",
        plantCode:"Aluminium wire",
        plantDescription:"ZROH",
        address:"ZROH",
        name1:"Barathi",
        name2:"8:00 AM ",
        city:"Bengaluru",
        country:"India",
        currency:"160.00",
        language:"English",
        street:"1",
        postalCode:"",
        googleMapLocation:"",

    },
    {
        companyCode: "05000113",
        plantCode:"Aluminium wire",
        plantDescription:"ZROH",
        address:"ZROH",
        name1:"Barathi",
        name2:"8:00 AM ",
        city:"Bengaluru",
        country:"India",
        currency:"160.00",
        language:"English",
        street:"1",
        postalCode:"",
        googleMapLocation:"",

    },
]

  const navigateCreatePlant = () => {
    navigate(CustomerConstants.CUSTOMER_V1_PLANT_MASTER_CREATION);
  };

  // const detailedPage = (userId) => {
  //   dispatch(addUserID(userId));
  //   navigate(Constants.CREATE_USER_DETAILS);
  // };

  const userListColInfo = [
    {
        name: "Company Code",
        selector: (row) => row.companyCode,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.companyCode}</span>
        ),
        width: "150px",
    },
    {
        name: "Plant Code",
        selector: (row) => row.plantCode,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.plantCode}</span>
        ),
        width: "150px",
    },
    {
        name: "Plant description",
        selector: (row) => row.plantDescription,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.plantDescription}</span>
        ),
        width: "160px",
    },
    {
        name: "Address",
        selector: (row) => row.address,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.address}</span>
        ),
        width: "150px",
    },
    {
        name: "Name1",
        selector: (row) => row.name1,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.name1}</span>
        ),
        width: "130px",
    },
    {
        name: "Name2",
        selector: (row) => row.name2,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.name2}</span>
        ),
        width: "130px",
    },
    {
        name: "City",
        selector: (row) => row.city,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.city}</span>
        ),
        width: "150px",
    },
    {
        name: "Country",
        selector: (row) => row.country,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.country}</span>
        ),
        width: "150px",
    },
    {
        name: "Currency",
        selector: (row) => row.currency,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.currency}</span>
        ),
        width: "150px",
    },
    {
        name: "Language",
        selector: (row) => row.language,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.language}</span>
        ),
        width: "150px",
    },
    {
        name: "Street",
        selector: (row) => row.street,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.street}</span>
        ),
        width: "150px",
    },
    {
        name: "Postal Code",
        selector: (row) => row.postalCode,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.postalCode}</span>
        ),
        width: "150px",
    },
    {
        name: "GoogleMap Location",
        selector: (row) => row.googleMapLocation,
        sortable: true,
        cell: (row) => (
            <span className="m-0 p-0 pt-1 fw-bold">{row.googleMapLocation}</span>
        ),
        width: "180px",
    },   
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-danger"
            // onClick={() => removePOItemRow(row.local_id)}
          >
            <i className="bi bi-trash3"></i>
          </button>
        </>
      ),
      width: "200px",
    },
  
  ];

  const loadInitialSuuplierData = async () => {
    setData(plantMasterData);
};

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={8} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Plant List</h2>
                <p className="subText">List of Plant</p>
              </Col>
              <Col xs={4} md={2} lg={2} className="mb-2">
                <button className="btn w-full" onClick={navigateCreatePlant}>
                  Add Plant
                </button>
              </Col>  
              <hr />
            </Row>

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Plant List</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={userListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default PlantList;
