import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
// import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/SupplierConstants";
import OrderSchedulingModal from "./OrderSchedulingModal";
import { getOrderSchedulingList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import {
  formatDate,
  formatDateTime,
  getLocationData,
  validateRequired,
} from "../../../../common/Validations";
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";

const OrderCancelledScheduleList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Cancelled Schedules");

  // let userListData = useSelector((state) => state?.user.userList); // User Details from Store
  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  let orderSchedulingData = useSelector(
    (state) => state?.customerV1?.order_scheduling
  ); // User Details from Store
  let orderSchedulingTable = useSelector(
    (state) => state?.customerV1?.order_scheduling.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  // const detailedPage = (number) => {};

  const [data, setData] = useState();
  const [filteredUserListColInfo, setFilteredUserListColInfo] = useState([]);

  const userListColInfo = [
    {
      name: "Order",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">
            {row.documentNumber}
          </span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.orderStatus,
      cell: (row) => (
        <>
          <TableStatusField status={row.orderStatus} />
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Dispatch Date",
      selector: (row) => row.dispatchDate,
      cell: (row) => (
        <>
          <span className="fw-bold text-primary">{formatDate(row.dispatchDate)}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Customer",
      selector: (row) => row.customerCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
            <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Project",
      selector: (row) => row.projectName,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Project Address",
    //   cell: (row) => (
    //     <>
    //       <span>{row.getProjectDetails?.projectAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Sales Order",
      selector: (row) => row.salesDocumentNr,
      cell: (row) => (
        <>
          <InputField
            className="inputBox text-primary"
            id="salesDocumentNr"
            name="salesDocumentNr"
            type="text"
            value={row.salesDocumentNr || ""}
            onChange={(event) => handleInputChange(row.documentNumber, event)}
            onBlur={(event) => handleInputChangeData(row.documentNumber, event)}
            disabled={row.updateSoStatus ? true : false}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Process",
      cell: (row) => (
        <>
          <button
            className="btnTable"
            onClick={() => updateSO(row.documentNumber, row.salesDocumentNr)}
            disabled={row.updateSoStatus ? true : false}
          >
            SO Update
          </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Product",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "Order Qty(L)",
      selector: (row) => row.orderQuantityLoad,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-success">{row.orderQuantityLoad} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Bal Qty(L)",
      selector: (row) => row.balOrderQuantityLoad,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold text-danger">{row.balOrderQuantityLoad} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Canl Qty(L)",
      selector: (row) => row.cancelledOrderQuantityLoad,
      cell: (row) => (
        <>
          <span className="fw-bold text-warning">{row.cancelledOrderQuantityLoad}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Canl Remark",
      selector: (row) => row.cancelledOrderRemarks,
      cell: (row) => (
        <>
          <span>{row.cancelledOrderRemarks}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Sales Officer",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.createdByName} </p>
            <p className="m-0 p-0 ">{row.createdBy} </p>
          </div>
        </>
      ),
      selector: (row) => row.createdByName || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Location",
      cell: (row) => (
        <>
          <span>{getLocationData(row.getProjectDetails)}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Zone",
      selector: (row) => row.getProjectDetails?.routeNumber,
      cell: (row) => (
        <>
          <span>{row.getProjectDetails?.routeNumber}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Date",
      selector: (row) => row.createdDate,
      cell: (row) => <>{formatDateTime(row.createdDate)}</>,
      sortable: true,
      width: "130px",
    },
    // {
    //   name: "Rescheduling",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Edit{row.publish}</button>
    //     </>
    //   ),
    //   width: "150px",
    // },
  ];

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;
    const restrictedColumns = {
      "SALES-MANAGER": ["Process"],
      "SALES-OFFICER": ["Process"],
      "SALES-COORDINATOR": [],
      "GENERAL-MNGR": ["Process"],
      "SALES-DIRECTOR": ["Process"],
      "DISPATCH-SNR-OFFICER": ["Process"],
      "DISPATCH-OFFICER": ["Process"],
    };

    const filteredCol = userListColInfo.filter(
      (col) => !restrictedColumns[userRole]?.includes(col.name)
    );

    setFilteredUserListColInfo(filteredCol);
    // console.log(">> filteredCol", filteredCol);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleInputChange = (documentNumber, event) => {
    const { name, value } = event.target;
    setData((prevRows) =>
      prevRows.map((row) =>
        row.documentNumber === documentNumber ? { ...row, [name]: value } : row
      )
    );
  };
  const handleInputChangeData = (documentNumber, event) => {
    const { name, value } = event.target;

    if (value.length <= 10) {
      setData((prevRows) =>
        prevRows.map((row) =>
          row.documentNumber === documentNumber
            ? { ...row, [name]: value }
            : row
        )
      );
    } else {
      alert("Please enter a valid 10-digit OR less than number.");
    }
  };

  const updateSO = async (order, value) => {
    let obj = {
      orderSchedulingNr: order,
      salesDocumentNr: value,
      updateSoStatus: true,
    };

    if (validateRequired(value)) {
      if (value.length <= 10) {
        await ItemService.updateSO(obj).then((item) => {
          console.log(">> item! title", item);
          if (item?.status === 200) {
            // setTitle(item?.data);
            handleSearchFuncApi();
          } else {
            // setTitle([]);
            console.log(">> Error: Loading getMasterDataSelf");
          }
        });
      } else {
        alert("Please enter a valid 10-digit OR less than number.");
      }
    } else {
      alert("value should not be empty");
    }
  };

  const [getOrderSheduling, setOrderSheduling] = useState(false);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const hideOrderScheduling = () => {
    setOrderSheduling(false);

    let obj = {
      queryName: "getCancelledOrderScheduleBookingList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  const loadInitialSuuplierData = async () => {
    setData(orderSchedulingData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getCancelledOrderScheduleBookingList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCancelledOrderScheduleBookingList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getOrderSchedulingList(obj));
    setData(orderSchedulingData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCancelledOrderScheduleBookingList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getOrderSchedulingList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSchedulingData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCancelledOrderScheduleBookingList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getOrderSchedulingList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Order Cancelled</h2>
                <p className="subText">Cancelled Order List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CO</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Cancelled Orders</span>
                      <span className="subHeaderTitle">
                        View of Cancelled Orders List{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={filteredUserListColInfo}
                          data={data}
                          progressPending={orderSchedulingData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={orderSchedulingTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <OrderSchedulingModal
        show={getOrderSheduling}
        onHide={hideOrderScheduling}
        clear={true}
      />
    </main>
  );
};

export default OrderCancelledScheduleList;
