import React, { useState, useEffect } from "react";

const PageLevelRiverseTimer = ({ timerData }) => {
  // State to hold the total seconds remaining
  const [totalSeconds, setTotalSeconds] = useState(0);

  // Calculate days, hours, minutes, and seconds from total seconds
  const daysRemaining = Math.floor(totalSeconds / (24 * 60 * 60));
  const hoursRemaining = Math.floor(
    (totalSeconds % (24 * 60 * 60)) / (60 * 60)
  );
  const minutesRemaining = Math.floor((totalSeconds % (60 * 60)) / 60);
  const secondsRemaining = totalSeconds % 60;

  // Helper function to pad numbers with leading zeros
  const padNumber = (num) => String(num).padStart(2, "0");

  // Pad the numbers
  const paddedDays = padNumber(daysRemaining);
  const paddedHours = padNumber(hoursRemaining);
  const paddedMinutes = padNumber(minutesRemaining);
  const paddedSeconds = padNumber(secondsRemaining);

  // Function to convert the object data into total seconds
  const calculateTotalSeconds = ({
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  }) => {
    const totalSeconds =
      days * 24 * 60 * 60 + // Convert days to seconds
      hours * 60 * 60 + // Convert hours to seconds
      minutes * 60 + // Convert minutes to seconds
      seconds; // Add remaining seconds
    return totalSeconds;
  };

  // Update the timer every second
  useEffect(() => {
    if (totalSeconds <= 0){
      // console.log(">> Total Seconds is ", totalSeconds);            
      return; // Stop the countdown when totalSeconds reaches 0
    } 

    const countdown = setInterval(() => {
      setTotalSeconds((prevTotalSeconds) => {
        if (prevTotalSeconds <= 0) {
          clearInterval(countdown);
          return 0;
        }
        return prevTotalSeconds - 1;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(countdown);
  }, [totalSeconds]);

  // Set initial total seconds based on timerData
  useEffect(() => {
    if (timerData) {
      const total = calculateTotalSeconds(timerData);
      setTotalSeconds(total);
    } else {
      setTotalSeconds(0); // If no timer data, default to 0
    }
  }, [timerData]);

  return (
    <>
      <div className="d-flex rivers-timer">
        <div className="d-flex-column  align-items-center">
          <div className="d-flex align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedDays[0]}</p>
            <p className="border  p-2 m-1">{paddedDays[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Days</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedHours[0]}</p>
            <p className="border  p-2 m-1">{paddedHours[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Hours</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex  align-items-center fs-4 timer-box">
            <p className="border  p-2 m-1">{paddedMinutes[0]}</p>
            <p className="border  p-2 m-1">{paddedMinutes[1]}</p>
            <span className="mx-2">:</span>
          </div>
          <span className="me-3 d-flex justify-content-center">Mins</span>
        </div>

        <div className="d-flex-column align-items-center">
          <div className="d-flex justify-content-center align-items-center fs-4 timer-box">
            <p className="border p-2 m-1">{paddedSeconds[0]}</p>
            <p className="border p-2 m-1">{paddedSeconds[1]}</p>
          </div>
          <span className="d-flex justify-content-center">Secs</span>
        </div>
      </div>
    </>
  );
};
export default PageLevelRiverseTimer;
