/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/customer_appCommonService";


const initialState = {
    workflowCounts:{
        data:[],
        status:false,
    },
};

export const menuSlice = createSlice({
    name: "customerWorkflow",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getWorkflowInboxCards.fulfilled, (state,action)=>{
            state.workflowCounts.data = action.payload;
            state.workflowCounts.status = true;
        })
        .addCase(getWorkflowOutboxCards.fulfilled, (state,action)=>{
            state.workflowCounts.data = action.payload;
            state.workflowCounts.status = true;
        })
        
    }
  });

// export const { addCategorySelectedData } = menuSlice.actions;
export default menuSlice.reducer;

export const getWorkflowInboxCards = createAsyncThunk('getWorkflowInboxCards', async () =>{
    console.log(">> loaded getWorkflowInboxCards");
    const res = await ItemService.getWorkflowInboxCards({"workflowStatus": "Open"});
    return res.data;
})

export const getWorkflowOutboxCards = createAsyncThunk('getWorkflowOutboxCards', async () =>{
    console.log(">> loaded getWorkflowOutboxCards");
    const res = await ItemService.getWorkflowInboxCards({"workflowStatus": "Closed"});
    return res.data;
})

