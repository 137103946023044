import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { validateRequired } from "../../../../common/Validations";

import ItemService from "../../../../services/supplier_appCommonService";
//import { getCategoryListData } from "../../../redux/feature/userSlice";

//Redux
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
//import SupplierConstants from "../../../../common/SupplierConstants";
import CustomerConstants from "../../../../common/CustomerConstants";

const TollDetails = () => {
    const navigate = useNavigate();
    

    // localStorage.setItem("menu", "Admin");
    // localStorage.setItem("submenu", "Toll Master");

    let tollDetailsData = useSelector(
        (state) => state?.user?.tollList?.tollDetails
    );

    const [isCardVisible, setCardVisible] = useState(true);
    const toggleCardVisibility = () => {
        setCardVisible(!isCardVisible);
    };

    const [formInputs, setFormInputs] = useState({
        sNo: "",
        routeName: "",
        tollNumber: "",
        tollRates: "",
        fromNoEntryTime: "",
        toNoEntryTime: "",
        fromNoEntryTime1: "",
        toNoEntryTime1: "",
    });
    const modalData = {
        title: "Toll Updated",
        successMsg: "",
    };
    const [getModalInfo, setModalInfo] = useState(modalData);
    const [getShowSuccessModal, setShowSuccessModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [editMode, setEditMode] = useState(true);

    const onInputChange = ({ target: { name, value } }) => {
        setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    };

    const failureModalData = {
        title: "Warning",
        ErrorMsg: "",
    };
    const [getFailureModal] = useState(failureModalData);

    const hideFailureModal = () => {
        setShowFailureModal(false);
    };

    const [getShowFailureModal, setShowFailureModal] = useState(false);
    const [getErrorFields, setErrorFields] = useState([]);

    /* Edit Button */
    const handleEdit = () => {
        setEditMode(false);
    };
    /* Edit Button */
    const handleEditToll = async () => {
        let requiredFields = [];
        if (
            !validateRequired(formInputs?.sNo) ||
            !validateRequired(formInputs?.routeName) ||
            !validateRequired(formInputs?.tollNumber) ||
            !validateRequired(formInputs?.tollRates) ||
            !validateRequired(formInputs?.fromNoEntryTime) ||
            !validateRequired(formInputs?.toNoEntryTime) ||
            !validateRequired(formInputs?.fromNoEntryTime1) ||
            !validateRequired(formInputs?.toNoEntryTime1) 
        ) {
            requiredFields.push("All fields are required..!");
            setErrorFields(requiredFields);
            setShowFailureModal(true);
            return;
        }

        const toastId = toast.loading("Loading...", {
            position: "top-right",
        });

        setDisabled(true);
        await ItemService.updatePlant(formInputs).then((item) => {
            // console.log(">> item!", item);
            if (item?.status === 200) {
                modalData.successMsg = item?.message;
                setModalInfo(modalData);
                onSuccessfullyRegistered();
                // Update response value in Redux Store
                //dispatch(getMaterialListData()); // event store
            } else {
                toast.error(item?.message || "Please try again !!", {
                    duration: 4000,
                    position: "top-right",
                });
            }
        });
        toast.dismiss(toastId);
        setDisabled(false);
    };

    /* Show Success Modal */
    const onSuccessfullyRegistered = () => {
        setShowSuccessModal(true);
    };
    const hideSuccessModal = () => {
        setShowSuccessModal(false);
    };

    useEffect(() => {
        if (tollDetailsData) {
            setFormInputs(tollDetailsData);
        } else {
            navigate(CustomerConstants.TOLL_MASTER_DETAILS);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="dashboard main" id="main">
            <Layout />
            <Row className="justify-center subContent">
                <Col xs={12} md={12} className="rightSide">
                    <div className="rightSideInner PageCommonCSS">
                        <h2 className="mainTitle">Create Toll</h2>
                        <p className="subText">Toll Master Details</p>
                        <hr />

                        <div className="accordion mb-4" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <Row className="accordion-button">
                                        <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                                            <i
                                                className={`bi ${isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                                                    }`}
                                                onClick={toggleCardVisibility}
                                            ></i>
                                        </Col>
                                        <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                                            <span className="iconLogo">TD</span>
                                        </Col>
                                        <Col xs={12} md={8} lg={8}>
                                            <span className="headerTitle">Create Toll</span>
                                            <span className="subHeaderTitle">Create</span>
                                        </Col>

                                        <Col xs={4} md={2} lg={2} className="text-right">
                                            {editMode && (
                                                <span>
                                                    <button className="btnTable" onClick={handleEdit}>
                                                        Edit
                                                    </button>
                                                </span>
                                            )}
                                            {!editMode && (
                                                <span>
                                                    <button
                                                        className="btnTable"
                                                        disabled={disabled}
                                                        onClick={handleEditToll}
                                                    >
                                                        Update Toll
                                                    </button>
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </h2>

                                <div
                                    className={`accordion-collapse collapse show ${isCardVisible ? "visible" : ""
                                        }`}
                                >
                                    <div className="accordion-body">
                                        <Row className="pt-2 pb-4">

                                        <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="S No"
                                                    name="sNo"
                                                    id="sNo"
                                                    extraLabel=""
                                                    placeholder="S No"
                                                    value={formInputs.sNo || ""}
                                                    onChange={onInputChange}
                                                    required={true}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="Route Name"
                                                    name="routeName"
                                                    id="routeName"
                                                    extraLabel=""
                                                    placeholder="Route Name"
                                                    value={formInputs.routeName || ""}
                                                    onChange={onInputChange}
                                                    required={true}
                                                />
                                            </Col>


                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="Toll Number"
                                                    name="tollNumber"
                                                    id="tollNumber"
                                                    extraLabel=""
                                                    placeholder="Toll Number"
                                                    value={formInputs.tollNumber || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="Toll Rates"
                                                    name="tollRates"
                                                    id="tollRates"
                                                    extraLabel=""
                                                    placeholder="Toll Rates"
                                                    value={formInputs.tollRates || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="From No Entry Time"
                                                    name="fromNoEntryTime"
                                                    id="fromNoEntryTime"
                                                    extraLabel=""
                                                    placeholder="From No Entry Time"
                                                    value={formInputs.fromNoEntryTime || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="To No Entry Time"
                                                    name="toNoEntryTime"
                                                    id="toNoEntryTime"
                                                    extraLabel=""
                                                    placeholder="To No Entry Time"
                                                    value={formInputs.toNoEntryTime || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="From No Entry Time"
                                                    name="fromNoEntryTime1"
                                                    id="fromNoEntryTime1"
                                                    extraLabel=""
                                                    placeholder="From No Entry Time"
                                                    value={formInputs.fromNoEntryTime1 || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>

                                            <Col
                                                xs={12}
                                                md={4}
                                                lg={4}
                                                className="commTopButtonRightLeftPadding"
                                            >
                                                <InputField
                                                    className="inputBox"
                                                    label="To No Entry Time"
                                                    name="toNoEntryTime1"
                                                    id="toNoEntryTime1"
                                                    extraLabel=""
                                                    placeholder="To No Entry Time"
                                                    value={formInputs.toNoEntryTime1 || ""}
                                                    onChange={onInputChange}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <FailureModal
                show={getShowFailureModal}
                onHide={() => hideFailureModal()}
                failuremodaldata={getFailureModal}
                requiredfields={getErrorFields}
            />
            <SuccessRegisterModal
                show={getShowSuccessModal}
                onHide={() => hideSuccessModal()}
                modaldata={getModalInfo}
                aftersuccess={CustomerConstants.TOLL_MASTER_DETAILS}
            />
        </main>
    );
};

export default TollDetails;
