import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { Card } from "react-bootstrap";
import HomeIntorVideo from "../../../Components/Modal/supplier_app/HomeIntro/HomeIntorVideo";

const SupplierManual = () => {
  localStorage.setItem("menu", "User Manual");
  localStorage.setItem("submenu", "Supplier Manual");

  const files = [
    { name: "1.1.  Supplier Portal Introduction", url: "" },
    { name: "1.2.  Dashboard Guide", url: "" },
    { name: "1.3.  Supplier-Registration-Guide", url: "" },
    { name: "1.4.  Acknowledging-RFQ", url: "" },
    { name: "1.5.  Quotation Submission", url: "" },
    { name: "1.6.  Acknowledging-Purchase-Order", url: "" },
    { name: "1.7.  Advance Shipping Notification Submission ", url: "" },
    { name: "1.8.  Advance Shipping Notification", url: "" },
    { name: "1.9.  Service Delivery Notes/ SES Submission ", url: "" },
    { name: "1.10. Goods Receipt Submission", url: "" },
    { name: "1.11. Invoice Submission", url: "" },
  ];
  const videos = [
    {
      name: "Supplier Management",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-people-fill",
    },
    {
      name: "Sourcing",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-file-earmark-check",
    },
    {
      name: "Procurment",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-card-list",
    },
    {
      name: "Auction",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-people-fill",
    },
    {
      name: "Contract",
      videoUrl: "./assets/videos/vendor_management.mp4",
      icons: "bi bi-people-fill",
    },
    // {
    //   name: "Spend Analysis",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    // {
    //   name: "SupplierManagement",
    //   videoUrl: "./assets/videos/vendor_management.mp4",
    //   icons: "bi bi-people-fill",
    // },
    
  ];

  const Attachments = ({ files }) => {
    return (
      <div className="attachments-list">
        <Row>
          {files.map((file, index) => (
            <Col key={index} md={4} className="commLeftRightPadding">
              <Card>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <i
                        className="bi bi-file-earmark-text"
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </Col>
                    <Col xs={6}>
                      <p
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        className="pt-2"
                      >
                        {file.name}
                      </p>
                    </Col>
                    <Col xs={4} className="text-end">
                      <button className="btn btnTable" href={file.url} download>
                        <i className="bi bi-download"></i>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const [getIntroVideo, setIntroVideo] = useState(false);
  const [getVideoPath, setVideoPath] = useState();

  const introVideoShow = (getVideoPath) => {
    setIntroVideo(true);
    setVideoPath(getVideoPath);
  };

  const introVideoHide = () => {
    setIntroVideo(false);
  };

  const VideoFiels = ({ videos }) => {
    return (
      <>
        {videos.map((item, index) => (
          <>
            <div key={index} className="col-lg-2 commTopButtonRightLeftPadding">
              <div
                onClick={() => introVideoShow(item.videoUrl)}
                className="m-0 card info-card sales-card"
              >
                <div className="card-body">
                  <h5 className="card-title">{item.name}</h5>

                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className={item.icons}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    );
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Supplier User Manual </h2>
                <p className="subText">User Manual Documents and Process Video</p>
              </Col>
              <hr />
              {/* downloads */}
              <Col
                xs={12}
                md={12}
                lg={12}
                className="commTopButtonRightLeftPadding"
              >
                <h1 className="card-title">User Manual Documents :</h1>
                <Attachments files={files} />
              </Col>
              {/* videos */}
              <Col xs={12} md={12} lg={12} className="headers mb-2">
              <h1 className="card-title">Process :</h1>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        <h5 className="card-title">Supplier Portal Introduction</h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <VideoFiels videos={videos} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <HomeIntorVideo
        show={getIntroVideo}
        onHide={introVideoHide}
        videopath={getVideoPath}
      />
    </main>
  );
};

export default SupplierManual;

