import React, { useEffect, useRef, useState } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/customer_appCommonService";

//Components
import { InputField } from "../../../../Components/formElements/InputField";
import { useReactToPrint } from "react-to-print";
import PrintCostingSheet from "./PrintCostingSheet";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";

import { useSelector } from "react-redux"; //useSelector
import SupplierConstants from "../../../../common/SupplierConstants";
import toast from "react-hot-toast";
import CustomerConstants from "../../../../common/CustomerConstants";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import Layout from "../../../../Layout/Layout";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
// import { delayForSecond } from "../../../../common/Validations";

const CostingSheet = () => {
  const navigate = useNavigate();

  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Customer List");

  let customerRegData = useSelector(
    (state) => state?.customerV1?.customerReg?.info
  ); // User Details from Store
  let userData = useSelector((state) => state?.user?.data);
  let customerData = useSelector((state) => state?.customer?.data); // User Details from Store
  let customerID = useSelector((state) => state?.customer?.customerID); // User Details from Store

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [fieldDisabled, setFieldDisabled] = useState(true);
  const [disabled, setDisabled] = useState(false);

  // const [loading, setLoading] = useState(false);
  const [getProjectDetails, setProjectDetails] = useState([]);
  const [getProjectDetailsSave, setProjectDetailsSave] = useState([]);
  const [getProjectDataList, setProjectDataList] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [transportCharges, setTransportCharges] = useState("3.84");

  const [formInputs, setFormInputs] = useState({
    salesOfficerName: "",
    createdBy: "",
    salesOfficerCode: "",
    customerCode: "",
    customerName: "",
    documentNumber: "",
    costingSheetDocumentNr: "",
    costingSheetStatus: "Inprocess",
    projectName: "",
    projectLocation: "",
    distance: "",
    transportCharges: transportCharges,
    plantLocation: "",
    siteLocation: "",
    version: "",
    csStatusHeader: "",
    purchasingOrg: "",
    purchasingGroup: "",
    companyCode: "",
    observationsFeedback: { editorContent },
    createdAt: new Date("2024-12-24T17:38:40.157+05:30"),
    updatedAt: new Date("2024-12-24T17:38:40.157+05:30"),
    items: "",
    termsConditions: "",
    projectDetails: [],
  });
  // console.log(">>formInputs",formInputs)
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  //print
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });

  /* Item details */
  let itemDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "80px",
    },
    {
      name: "Product",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
            <p className="m-0 p-0 ">{row.materialCode} </p>
          </div>
        </>
        // <InputField
        //   className="inputBox mt-1"
        //   // label="Please Enter the Remarks*"
        //   name="materialCode"
        //   placeholder=""
        //   value={row.materialCode || ""}
        //   disabled={true}
        //   onChange={(event) => handleInputChange(row.local_id, event)}
        // />
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: "Quote Price (INR)",
      selector: (row) => row.quotePrice || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          // label="Please Enter the Remarks*"
          name="quotePrice"
          placeholder=""
          value={row.quotePrice || ""}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "180px",
    },
    // {
    //   name: "Product",
    //   selector: (row) => row.materialDescription || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       // label="Please Enter the Remarks*"
    //       name="materialDescription"
    //       placeholder=""
    //       value={row.materialDescription || ""}
    //       disabled={true}
    //       onChange={(event) => handleInputChange(row.local_id, event)}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },

    {
      name: "Freight Rate",
      selector: (row) => row.freightRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          name="freightRate"
          placeholder=""
          // value={row.freightRate || ""}
          value={row.freightRate || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Toll Rate",
      selector: (row) => row.tollRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          // label="Please Enter the Remarks*"
          name="tollRate"
          placeholder=""
          value={row.tollRate || ""}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "120px",
    },
    {
      name: "Route Nr",
      selector: (row) => row.routeNumber || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          // label="Please Enter the Remarks*"
          name="routeNumber"
          placeholder=""
          value={row.routeNumber || ""}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "140px",
    },
    {
      name: "Commission Rate",
      selector: (row) => row.commissionRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="commissionRate"
          placeholder=""
          value={row.commissionRate || ""}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Ex-plant Rate",
      selector: (row) => row.explantRate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1 text-right"
          name="explantRate"
          placeholder=""
          value={row.explantRate || ""}
          disabled={true}
        />
      ),
      sortable: true,
      width: "140px",
    },

    // {
    //   name: "Currency",
    //   selector: (row) => row.currency || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="currency"
    //       placeholder=""
    //       value={row.currency || ""}
    //       disabled={true}
    //       onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          className=""
          onClick={() => removecustomerContactPersonItemRow(row.local_id)}
        >
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];

  let itemDetailsData = [
    {
      local_id: 1,
      materialCode: "FG001",
      materialDescription: "12 MM - Aggregates 12mm",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "10",
      explantRate: "525.00",
      quotePrice: "727.80",
      currency: "INR",
    },
    {
      local_id: 2,
      materialCode: "FG002",
      materialDescription: "20 MM - Aggregates 20mm",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "10",
      explantRate: "525.00",
      quotePrice: "727.80",
      currency: "INR",
    },
    {
      local_id: 3,
      materialCode: "FG005",
      materialDescription: "M SAND - Manufactured Sand (U) 0-4.76mm",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "10",
      explantRate: "525.00",
      quotePrice: "727.80",
      currency: "INR",
    },

    {
      local_id: 4,
      materialCode: "FG006",
      materialDescription: "WMM - Wet Mix Mecodem",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "10",
      explantRate: "510.00",
      quotePrice: "712.80",
      currency: "INR",
    },
    {
      local_id: 5,
      materialCode: "FG007",
      materialDescription: "GSB - Granulates Sub Base",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "10",
      explantRate: "510.00",
      quotePrice: "712.80",
      currency: "INR",
    },
    {
      local_id: 6,
      materialCode: "FG008",
      materialDescription: "P SAND - Manufactured Sand",
      freightRate: 0,
      routeNumber: "1",
      routeDescription: "Yalahanka",
      tollRate: "20",
      commissionRate: "25",
      explantRate: "900.00",
      quotePrice: "1117.80",
      currency: "INR",
    },
  ];

  const handleInputChange = (local_id, event) => {
    const { name, value } = event.target;

    setitemDetailsData((prevRows) =>
      prevRows.map((row) => {
        if (row.local_id === local_id) {
          const updatedRow = { ...row, [name]: value };

          if (
            name === "quotePrice" ||
            name === "tollRate" ||
            name === "commissionRate"
          ) {
            const quotePrice = parseFloat(updatedRow.quotePrice || 0);
            // const explantRate = parseFloat(updatedRow.explantRate || 0);
            const freightRate = parseFloat(updatedRow.freightRate || 0);
            const tollRate = parseFloat(updatedRow.tollRate || 0);
            const commissionRate = parseFloat(updatedRow.commissionRate || 0); // Use updated commissionRate value

            // Update `quotePrice`
            updatedRow.explantRate = (
              quotePrice -
              freightRate -
              tollRate -
              commissionRate
            ).toFixed(2); // Ensures 2 decimal places
          }

          return updatedRow;
        }
        return row;
      })
    );
  };

  const [getitemDetailsCol] = useState(itemDetailsCol);
  const [getitemDetailsData, setitemDetailsData] = useState(itemDetailsData);
  // eslint-disable-next-line no-unused-vars
  const [getPrintData, setPrintData] = useState([]);

  const removecustomerContactPersonItemRow = (local_id) => {
    setitemDetailsData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };

  const addcustomerContactPersonItemRow = () => {
    setitemDetailsData((prevRows) => {
      const newRow = {
        local_id: prevRows.length + 1, // This will increment the ID
        purchasingReqDocumentItemNr: String(prevRows.length + 1).padStart(
          3,
          "0"
        ), // Format as 001, 002, etc.
        product: "Others", // Add any default values here
        freightRate: "172.80", // Default values for other properties
        routeNumber: "1",
        tollRate: "20",
        commissionRate: "0",
        explantRate: "525.00",
        quotePrice: "0.00",
      };
      return [...prevRows, newRow]; // Add new row to the existing data
    });
  };

  // This is for Text Editor
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const [termsConditionsData, setTermsConditionsData] = useState([
    {
      id: 1,
      name: "The price is exclusive of transportation and delivery to your site at CHIKKAJALA.",
      status: true,
      disabled: true,
    },
    {
      id: 2,
      name: "The above prices are excluding GST @ 5 % & including royalty.",
      status: false,
    },
    {
      id: 3,
      name: "Billing will be made for each shipment.",
      status: false,
    },
    {
      id: 4,
      name: "30 Days credit period from the date of material supply.",
      status: false,
    },
    {
      id: 5,
      name: "Deliveries will be made as per your schedule required from time to time",
      status: false,
    },
    {
      id: 6,
      name: "The offer will be revised in case of increase in prices of Diesel, power or any Government duties.",
      status: false,
    },
    {
      id: 7,
      name: "Rates will be revised monthly, for every one-rupee variation @0.002*Km*Mt.",
      status: false,
    },
    {
      id: 8,
      name: "Current fuel rate is considered at Rs.87.89/lit.",
      status: false,
    },
    {
      id: 9,
      name: "Offer valid only for 10 Days.",
      status: false,
    },
  ]);

  const handleTermsConditionCheckBox = (id) => {
    // Update the status of the item based on its id
    const updatedData = termsConditionsData.map((item) => {
      if (item.id === id) {
        return { ...item, status: !item.status };
      }
      return item;
    });
    // Update the state with the modified data
    setTermsConditionsData(updatedData);
  };

  const onInputChange = ({ target: { name, value } }) => {
    // console.log("oncahngeFielsd",name, value)
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  useEffect(() => {
    const updatedObject = {
      ...formInputs,
      items: getitemDetailsData,
      observationsFeedback: editorContent,
      termsConditions: termsConditionsData,
      projectDetails: getProjectDetailsSave,
    };

    setPrintData(updatedObject);
  }, [
    formInputs,
    editorContent,
    termsConditionsData,
    getProjectDetailsSave,
    getitemDetailsData,
  ]);

  useEffect(() => {
    const distance = Number(formInputs?.distance) || 0;
    const charges = Number(formInputs?.transportCharges) || 3.84;
    const freightRate = parseFloat(distance * charges).toFixed(2);

    setitemDetailsData((prevData) =>
      prevData.map((item) => {
        const quotePrice = parseFloat(item.quotePrice) || 0;
        const tollRate = parseFloat(item.tollRate) || 0;
        const commissionRate = parseFloat(item.commissionRate) || 0;
        const explantRate = (quotePrice - tollRate - commissionRate - freightRate).toFixed(2);
    
        return {
          ...item,
          freightRate,
          explantRate,
        };
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInputs?.distance, formInputs?.transportCharges]);

  const handleSave = async () => {
    setDisabled(true);
    const updatedObject = {
      ...formInputs,
      items: getitemDetailsData,
      observationsFeedback: editorContent,
      termsConditions: termsConditionsData,
      projectDetails: getProjectDetailsSave,
      transportCharges: transportCharges,
      createdAt: new Date("2024-12-24T17:38:40.157+05:30"),
      updatedAt: new Date("2024-12-24T17:38:40.157+05:30"),
      csStatusHeader: "Pending with L1",
    };

    // console.log(">> updatedObject", updatedObject);

    const obj = {
      data: updatedObject,
    };
    await ItemService.addCostingSheet(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });
        if (userData?.userId) {
          navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
        } else {
          navigateToLogin();
        }

        // dispatch(saveCustomerRegData(item?.data))
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  useEffect(() => {
    // console.log(">> customerRegData", customerRegData);
    if (JSON.stringify(customerRegData) !== "{}") {
      loadFirstLevelData();
    } else if (customerID !== "") {
      let filteredData = customerData.filter(
        (item) => item.userId === customerID
      );
      setFormInputs(filteredData[0]);
      getProjectName(customerID);
    } else {
      navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerRegData]);

  const navigateToLogin = () => {
    navigate(SupplierConstants.LOGIN);
  };

  const loadFirstLevelData = async () => {
    // console.log(">> customerRegData", customerRegData);

    if (JSON.stringify(customerRegData) !== "{}") {
      setitemDetailsData(itemDetailsData);
      setFormInputs(customerRegData);
      getProjectName(customerRegData?.userId);
    }

    // navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
  };

  const getProjectName = async (code) => {
    let obj = {
      userId: code,
    };
    await ItemService.getProjectName(obj).then((item) => {
      // console.log('>> getProjectName!',item);
      if (item?.status === 200) {
        const updatedData = item?.data?.map((item) => ({
          ...item,
          name: `${item.id} - ${item.name}`, // Combine id and name
        }));

        setProjectDataList(updatedData);
      } else {
        setProjectDataList([]);
        console.log(">> Error: Loading getProjectName");
      }
    });
  };

  const getProjectDetailsByCode = async (code) => {
    let obj = {
      userId: customerID || customerRegData?.userId,
      projectCode: code,
    };
    await ItemService.getProjectDetail(obj).then((item) => {
      // console.log('>> getProjectDetail!',item);
      if (item?.status === 200) {
        if (item?.data.length > 0) {
          setProjectDetails(item?.data[0]?.getLocations[0]);
          setProjectDetailsSave(item?.data[0]);
        } else {
          setProjectDetails([]);
        }
      } else {
        setProjectDetails([]);
        console.log(">> Error: Loading getProjectDetail");
      }
    });
  };

  useEffect(() => {
    loadFirstLevelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.textCode === "projectNameCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      getProjectDetailsByCode(getDropdownData?.id);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Costing Sheet</h2>
            <p className="subText">Costing Sheet</p>
            <hr />
            <Row className="mb-4">
              <Col xs={12} md={2} lg={2} className="text-left">
                {/* {!customerID && (
                  <button className="btnBack" onClick={() => navigateToLogin()}>
                    <i className="bi bi-arrow-return-left"></i> Go Back Login
                  </button>
                )} */}
              </Col>

              <Col
                xs={12}
                md={{ span: 2, offset: 8 }}
                lg={{ span: 2, offset: 8 }}
                className="text-left"
              >
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>

            <>
              {/* Costing Sheet */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">CS</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Costing Sheet</span>
                        <span className="subHeaderTitle">
                          Format of Cost Sheet
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        md={1}
                        lg={1}
                        className="d-flex justify-content-end"
                      >
                        <button className="btnTable" onClick={handlePrint}>
                          <i class="bi bi-printer"></i> Print
                        </button>
                        <div style={{ display: "none" }}>
                          <PrintCostingSheet
                            ref={contentRef}
                            data={getPrintData}
                          />
                        </div>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Sales Officer Name"
                            name="salesOfficerName"
                            value={formInputs.salesOfficerName || ""}
                            onChange={onInputChange}
                            disabled={fieldDisabled}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Customer Name"
                            name="organisationName"
                            value={formInputs.organisationName || ""}
                            onChange={onInputChange}
                            disabled={fieldDisabled}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Project Name"
                            placeholder=""
                            data={getProjectDataList}
                            id="projectName"
                            iconClassName="dropdownIcon"
                            name="projectName"
                            code="projectNameCode"
                            description="projectNameDesc"
                            setValue={formInputs.projectName}
                            getvalue={setDropdownData}
                            required={true}
                            // error={validation.country}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Plant Location"
                            name="plantAddress"
                            value={formInputs.plantAddress || ""}
                            onChange={onInputChange}
                            disabled={fieldDisabled}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Project/Site Location"
                            name="projectLocation"
                            value={getProjectDetails?.projectLocation || ""}
                            onChange={onInputChange}
                            disabled={fieldDisabled}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Project Address"
                            name="projectAddress"
                            value={
                              getProjectDetailsSave?.projectAddress +
                                getProjectDetailsSave?.projectAddress2 || ""
                            }
                            // onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Kilo Meter"
                            name="distance"
                            type="number"
                            value={formInputs?.distance || ""}
                            onChange={onInputChange}
                            disabled={false}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Duration"
                            name="duration"
                            // value={getProjectDetails?.duration || ""}
                            value={formInputs?.duration || ""}
                            onChange={onInputChange}
                            disabled={false}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Transport Charges"
                            type="number"
                            name="transportCharges"
                            value={formInputs.transportCharges}
                            onChange={onInputChange}
                            disabled={false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/*Customer Contact Person / Line Item */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">CP</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Item Details</span>
                        <span className="subHeaderTitle">Item Details</span>
                      </Col>
                      <Col xs={12} md={1} lg={1} className="headerButtonAlign">
                        <button
                          className="btnTable"
                          onClick={addcustomerContactPersonItemRow}
                        >
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            title=""
                            columns={getitemDetailsCol}
                            data={getitemDetailsData}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            // selectableRows
                            // onSelectedRowsChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* Instructions 03*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible3
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility3}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RE</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">
                          Observations/Feedback
                        </span>
                        <span className="subHeaderTitle">
                          Observations/Feedback Section
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible3 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="p-4">
                        <Col xs={12} md={12} lg={12}>
                          <RichTextEditor
                            value={editorContent}
                            onChange={handleEditorChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* Documents 05 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible4
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility4}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">TD</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">Terms & Conditions</span>
                        <span className="subHeaderTitle">
                          Terms & Conditions
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible4 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          className="commTopButtonRightLeftPadding"
                        >
                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={8}
                              className="commTopButtonRightLeftPadding"
                            >
                              {termsConditionsData.map((item, i) => (
                                <div className="form-check" key={item.id}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`gridCheck${item.id}`}
                                    checked={item.status}
                                    onChange={() =>
                                      handleTermsConditionCheckBox(item.id)
                                    }
                                    disabled={item?.disabled}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`gridCheck${item.id}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CostingSheet;
