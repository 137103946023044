import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputField } from "../../../../Components/formElements/InputField";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
// import toast from "react-hot-toast";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { getTodayDate } from "../../../../common/Validations";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import FileUploadComponent from "../../../../Components/formElements/FileUploadComponent";
import toast from "react-hot-toast";
import TableDropdownMenu from "../../../../Components/TableDropdownMenu/TableDropdownMenu";

const CreatePurchaseOrderModal = React.forwardRef((props, ref) => {
  let userData = useSelector((state) => state?.user?.data);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  let uomData = [
    {
      id: 1,
      name: "CBM",
    },
    {
      id: 2,
      name: "TON",
    },
  ];

  let poTypeDropDown = [
    {
      id: 1,
      name: "Open",
    },
    {
      id: 2,
      name: "Standard",
    },
  ];

  let productDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Item",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "330px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "PO Qty",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "UOM",
      cell: (row) => (
        <TableDropdownMenu
          index={row.local_id}
          label={"UOM"}
          data={uomData}
          id={"orderUnitOfMeasure"}
          name={"orderUnitOfMeasure"}
          code={"orderUnitOfMeasureCode"}
          description={"orderUnitOfMeasureDescription"}
          onChange={handleDropdownChange}
          // required={true}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Rate",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          // disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Bal PO Qty",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="balancePurchaseQuantity"
          placeholder=""
          value={row.balancePurchaseQuantity || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Order Qty(MT)",
      selector: (row) => row.dispatchDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderQuantityMT"
          placeholder=""
          value={row.orderQuantityMT || ""}
          disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "150px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button className="" onClick={() => removeProductDetails(row.local_id)}>
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "100px",
    },
  ];

  let productDetailsData = [
    {
      local_id: 1,
      materialCode: "FG005",
      materialDescription: "M SAND - Manufactured Sand (U) 0-4.76mm",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
    {
      local_id: 2,
      materialCode: "FG002",
      materialDescription: "20 MM - Aggregates 20mm",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
    {
      local_id: 3,
      materialCode: "FG001",
      materialDescription: "12 MM - Aggregates 12mm",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
    {
      local_id: 4,
      materialCode: "FG008",
      materialDescription: "P SAND - Manufactured Sand",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
    {
      local_id: 5,
      materialCode: "FG007",
      materialDescription: "GSB - Granulates Sub Base",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
    {
      local_id: 6,
      materialCode: "FG006",
      materialDescription: "WMM - Wet Mix Mecodem",
      materialGroup: "",
      purchaseQuantity: "",
      balancePurchaseQuantity: "",
      orderQuantityMT: "",
      orderQuantityLoad: "",
      orderUnitOfMeasure: "Ton",
      orderPriceUnit: "",
    },
  ];

  // console.log(">> getTodayDate()", getTodayDate());

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const [getDropdownData, setDropdownData] = useState("");
  const [getProductDetailsData, setProductDetailsData] =
    useState(productDetailsData);

  // const [disable, setDisabled] = useState(false);
  const [getCustomer, setCustomer] = useState([]);
  const [getProjects, setProjects] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [getProjectDetails, setProjectDetails] = useState([]);
  const [getProjectDetailsSave, setProjectDetailsSave] = useState([]);
  const [customerID, setCustomerID] = useState("");
  // console.log(">>getProjectDetails",getProjectDetails)
  let [formInputs, setFormInputs] = useState({
    custPurchasingDocumentNr:"",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    purchasingDocumentValue: "",
    customerName: "",
    poType: "",
    poValidFrom: "",
    poValidTo: "",
    projectName: "",
    projectAddress: "",
    oneMatricTon: 33,
  });

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  useEffect(() => {
    getMasterData();

    setFormInputs({
      // orderStatus: "Pending",
      projectName: "",
      customerName: "",
      dispatchZone: "",
      createdDate: new Date(),
      salesOfficerCode: userData?.userId,
      salesOfficerName: userData?.firstName,
      oneMatricTon: 33,
      projectAddress: "",
      poType: "",
      poValidFrom: "",
      poValidTo: "",
    });
    setProductDetailsData(productDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    getMasterData();

    setFormInputs({
      // orderStatus: "Pending",
      projectName: "",
      customerName: "",
      dispatchZone: "",
      projectAddress: "",
      createdDate: new Date(),
      salesOfficerCode: userData?.userId,
      salesOfficerName: userData?.firstName,
      quotationDocumentNumber: props?.poModalData?.documentNumber,
      oneMatricTon: 33,
      poType: "",
      poValidFrom: "",
      poValidTo: "",
    });
    setProductDetailsData(productDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);

    if (getDropdownData?.textCode === "customerCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapDocumentNumber: getDropdownData?.sapDocumentNumber,
      }));
      getProjectNameData(getDropdownData?.id);
      setCustomerID(getDropdownData?.id);
      setFormInputs((formInputs) => ({ ...formInputs, projectName: "" }));
      // setProjectDetails([]);
      setProjectDetailsSave([]);
    } else if (getDropdownData?.textCode === "projectNameCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapProjectCode: getDropdownData?.sapProjectCode,
      }));
      getProjectDetailsByCode(getDropdownData?.id);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const removeProductDetails = (local_id) => {
    setProductDetailsData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };

  // Capture the data from dropdown
  const handleDropdownChange = (data) => {
    // console.log(">> data", data);
    setProductDetailsData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === data.index ? { ...row, [data.name]: data.value } : row
      )
    );
  };

  const handleInputChange = (local_id, event) => {
    const { name, value } = event.target;
    setProductDetailsData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === local_id
          ? {
              ...row,
              [name]: value,
              balancePurchaseQuantity:
                Number(value) -
                Math.round(
                  Number(formInputs.oneMatricTon) *
                    Math.round(Number(value) / Number(formInputs.oneMatricTon))
                ),
              orderQuantityMT: Math.round(
                Number(value) / Number(formInputs.oneMatricTon)
              ),
            }
          : row
      )
    );
  };
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  /* handle save */
  const handleSave = async () => {
    // setDisabled(true);
    const filteredData = getProductDetailsData.filter(
      (item) => item.purchaseQuantity.trim() !== ""
    );
    formInputs.items = filteredData;
    formInputs.projectAddress =
      getProjectDetailsSave?.projectAddress +
        getProjectDetailsSave?.projectAddress2 || "";
    console.log(">>formsinput", formInputs);

    const obj = {
      data: formInputs,
    };
    console.log(">>obj", obj);
    await ItemService.addPO(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onSuccess();
        props.onHide();
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      type: "ALL",
    };
    await ItemService.getCustomers(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setCustomer(item?.data);
      } else {
        setCustomer([]);
        console.log(">> Error: Loading getCustomers");
      }
    });
  };

  // Onselect of Country state will load
  const getProjectNameData = async (userId) => {
    let obj1 = {
      userId: userId,
    };
    await ItemService.getProjectName(obj1).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        const updatedData = item?.data?.map((item) => ({
          ...item,
          name: `${item.id} - ${item.name}`, // Combine id and name
        }));

        setProjects(updatedData || []);
      } else {
        setProjects([]);
        console.log(">> Error: Loading getProjectName");
      }
    });
  };

  const getProjectDetailsByCode = async (code) => {
    let obj = {
      userId: customerID,
      projectCode: code,
    };
    await ItemService.getProjectDetail(obj).then((item) => {
      // console.log(">> getProjectDetail!", item);
      if (item?.status === 200) {
        if (item?.data.length > 0) {
          setProjectDetails(
            item?.data[0]?.getLocations ? item?.data[0]?.getLocations[0] : []
          );
          setProjectDetailsSave(item?.data[0]);
        } else {
          setProjectDetails([]);
        }
      } else {
        setProjectDetails([]);
        console.log(">> Error: Loading getProjectDetail");
      }
    });
  };

  // For Upload Success
  const onSuccessUploadDocumentType = (data) => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      [data.fieldName]: data.attachmentId,
    }));
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Purchase Order{" "}
          {props?.poModalData?.documentNumber
            ? `- ${props?.poModalData?.documentNumber}`
            : " "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col xs={12} md={2} lg={2} className="headerButtonAlign">
            <button
              className="btnInfo"
              onClick={handleSave} /* disabled={disable} */
            >
              Save
            </button>
          </Col>
        </Row>
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CD</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <span className="headerTitle">PO Details</span>
                  <span className="subHeaderTitle">PO Details</span>
                </Col>
              </Row>
            </h2>

            <div
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <InputField
                      className="inputBox"
                      label="Purchase Order"
                      id="custPurchasingDocumentNr"
                      name="custPurchasingDocumentNr"
                      type="text"
                      value={formInputs.custPurchasingDocumentNr || "" || ""}
                      onChange={onInputChange}
                      disabled={false}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <InputField
                      className="inputBox"
                      label="PO Date"
                      id="purchasingDocumentDate"
                      name="purchasingDocumentDate"
                      type="date"
                      value={
                        formInputs?.purchasingDocumentDate ||
                        getTodayDate("") ||
                        ""
                      }
                      onChange={onInputChange}
                      disabled={false}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <InputField
                      className="inputBox"
                      label="PO Value"
                      id="purchasingDocumentValue"
                      name="purchasingDocumentValue"
                      type="number"
                      value={formInputs?.purchasingDocumentValue}
                      onChange={onInputChange}
                      disabled={false}
                    />
                  </Col>

                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="PO Type"
                      placeholder=""
                      data={poTypeDropDown}
                      id="poType"
                      iconClassName="dropdownIcon"
                      name="poType"
                      code="customerCode"
                      description="poTypeDesc"
                      setValue={formInputs.poType || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  {formInputs.poType === "Open" && (
                    <>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Valid From"
                          id="poValidFrom"
                          name="poValidFrom"
                          type="date"
                          value={formInputs?.poValidFrom}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Valid To"
                          id="poValidTo"
                          name="poValidTo"
                          type="date"
                          value={formInputs?.poValidTo}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </>
                  )}
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Customer Name"
                      placeholder=""
                      data={getCustomer}
                      id="customerName"
                      iconClassName="dropdownIcon"
                      name="customerName"
                      code="customerCode"
                      description="customerNameDesc"
                      setValue={formInputs.customerName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>

                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Project Name" //not get key
                      placeholder=""
                      data={getProjects}
                      id="projectName"
                      iconClassName="dropdownIcon"
                      name="projectName"
                      code="projectNameCode"
                      description="projectNameDesc"
                      setValue={formInputs.projectName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <TextAreaField
                      className="inputBox"
                      label="Project Address"
                      name="projectAddress"
                      value={
                        getProjectDetailsSave?.projectAddress +
                          getProjectDetailsSave?.projectAddress2 || ""
                      }
                      // onChange={onInputChange}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <FileUploadComponent
                      id="purchaseOrderFileAttachment"
                      name="purchaseOrderFileAttachment"
                      label="Customer PO"
                      allowedTypes={[
                        "image/png",
                        "image/jpeg",
                        "application/pdf",
                      ]}
                      multiple={true} // Change to `false` for single file selection
                      required={true}
                      onSuccessUpload={onSuccessUploadDocumentType}
                      value={formInputs.purchaseOrderFileAttachment || ""}
                      // disabled={editMode}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible2 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility2}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CP</span>
                </Col>
                <Col xs={12} md={9} lg={9}>
                  <span className="headerTitle">Product Details</span>
                  <span className="subHeaderTitle">Product Details</span>
                </Col>
                {/* <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                  <button className="btnTable" onClick={addProductDetails}>
                    <i className="bi bi-plus-lg"></i> Add
                  </button>
                </Col> */}
              </Row>
            </h2>
            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible2 ? "visible" : ""
              }`}
              // aria-labelledby="headingOne"
              // data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <DataTable
                      title=""
                      columns={productDetailsCol}
                      data={getProductDetailsData}
                      // progressPending={loading}
                      // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalRows}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // selectableRows
                      // onSelectedRowsChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default CreatePurchaseOrderModal;
