import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

const HomeIntorVideo = React.forwardRef((props, ref) => {
  const videoRef = useRef(null);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Process</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <div className="video-container">
              <video ref={videoRef} width="1000" controls autoPlay={true}>
                <source src={props.videopath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default HomeIntorVideo;
