import React from "react";


const GHGScope1CommonFillter = React.forwardRef((props, ref) => {
  return (
    <>
      <div className="filter">
        <span
          className="icon hide headerIcon"
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          <i className="bi bi-three-dots"></i>
        </span>
        <ul
          className="dropdown-menu dropdown-menu-end dropdown-menu-arrow hide menuList"
          data-popper-placement="bottom-end"
        >
          <li className="dropdown-header text-start">
            <h6>Menu</h6>
          </li>

          <li>
            <span className="dropdown-item headerIcon">
              <i className="bi bi-bar-chart mr-3"></i>
              Chart
            </span>
          </li>
          <li>
            <span className="dropdown-item headerIcon">
              <i className="bi bi-download mr-2"></i>
              Download
            </span>
          </li>
          <li>
            <span className="dropdown-item headerIcon">
              <i className="bi bi-funnel"></i>
              Filter
            </span>
          </li>
          <li>
            <span className="dropdown-item headerIcon">
              <i className="bi bi-arrow-clockwise"></i>
              Refresh
            </span>
          </li>
        </ul>
      </div>
    </>
  );
});

export default GHGScope1CommonFillter;
