import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
// import HomeIntorVideo from "../../../Components/Modal/supplier_app/HomeIntro/HomeIntorVideo";
import HomeCategorieDetails from "../../../Components/Modal/supplier_app/HomeIntro/HomeCategorieDetails";
import LoaderSmall from "../../../Components/LoaderSmall";
import CustomerConstants from "../../../common/CustomerConstants";
import { useNavigate } from "react-router-dom";

import customerItemService from "../../../services/customer_appCommonService";
import toast from "react-hot-toast";
import { formatToTwoDigits } from "../../../common/Validations";

//Redux
import { useSelector } from "react-redux"; //useDispatch

import "./homeIntro.scss";

const HomeIntro = () => {
  const currentDate = new Date();
  localStorage.setItem("menu", "Home");
  localStorage.setItem("submenu", "-");
  const navigate = useNavigate();

  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store
  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  const navigateToMainPage = (page) => {
    if (page === "totalOrderCount") {
      navigate(CustomerConstants.CUSTOMER_V1_ORDER_BOOKING_LIST);
    } else if (page === "orderCompletedCount") {
      navigate(CustomerConstants.CUSTOMER_V1_ORDER_COMPLETE_SCHEDULING_LIST);
    } else if (page === "confirmedDispatchCount") {
      navigate(CustomerConstants.CUSTOMER_V1_DISPATCH_CONFIRMATION_LIST);
    } else if (page === "totalUserCount") {
      navigate(CustomerConstants.CUSTOMER_V1_CUSTOMER_MASTER_LIST);
    }
  };

  const [totalUserCount, setTotalUserCount] = useState("");
  const [totalUserCountStatus, setTotalUserCountStatus] = useState(false);

  const [confirmedDispatchCount, setConfirmedDispatchCount] = useState("");
  const [confirmedDispatchStatus, setConfirmedDispatchStatus] = useState(false);

  const [orderCompletedCount, setOrderCompletedCount] = useState("");
  const [orderPendingCount, setPendingCount] = useState("");
  const [orderCompletedStatus, setOrderCompletedStatus] = useState(false);

  const [totalOrderCount, setTotalOrderCount] = useState("");
  const [totalOrderCountStatus, setTotalOrderStatus] = useState(false);

  const [totalPostDispatchCount, setTotalPostDispatchCount] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [totalPostDispatchCountStatus, setTotalPostDispatchCountStatus] =
    useState(false);

  const [productdata, setProductdata] = useState({});

  // loadOrderCount
  const loadOrderCount = async () => {
    setTotalOrderStatus(true);
    let obj = {
      queryName: "GetTotalOrderCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalOrderCount(item.data);

        loadOrderCompletedCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalOrderStatus(false);
  };

  // loadOrderCompletedCount
  const loadOrderCompletedCount = async (totalOrderCountVal) => {
    setOrderCompletedStatus(true);
    let obj = {
      queryName: "totalOrderCompletedCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setOrderCompletedCount(item.data);
        let orderComplete = item.data;
        setPendingCount(totalOrderCountVal - orderComplete);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setOrderCompletedStatus(false);
  };

  // loadConfirmedDispatchCount
  const loadConfirmedDispatchCount = async () => {
    setConfirmedDispatchStatus(true);
    let obj = {
      queryName: "totalConfirmedDispatchCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setConfirmedDispatchCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setConfirmedDispatchStatus(false);
  };

  // loadCustomerCount
  const loadCustomerCount = async () => {
    setTotalUserCountStatus(true);
    let obj = {
      queryName: "totalCustomerCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalUserCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalUserCountStatus(false);
  };

  const loadPostDispatchCount = async () => {
    setTotalPostDispatchCountStatus(true);
    let obj = {
      queryName: "totalPostDispatchCount",
    };

    await customerItemService.ChartCountCard(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalPostDispatchCount(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalPostDispatchCountStatus(false);
  };

  useEffect(() => {
    loadOrderCount();
    loadPostDispatchCount();
    loadConfirmedDispatchCount();
    loadCustomerCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProductdata(appConfig?.homeIntro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConfig]);

  // Format the date (optional)
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // const [getIntroVideo, setIntroVideo] = useState(false);
  const [getCategoryDetail, setCategoryDetail] = useState(false);
  const [hideItem, setHideItem] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const categoryDetailShow = () => {
    // setCategoryDetail(true);
  };

  const categoryDetailHide = () => {
    setCategoryDetail(false);
  };

  useEffect(() => {
    const userRole = userData?.roles?.[0]?.roleId || null;

    if (userRole === "CUSTOMER") {
      setHideItem(true);
    } else {
      setHideItem(false);
    }
    // console.log(">> userRole", userRole);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const ProductSection = ({ data }) => {
    return (
      <Row className="homeintroposter">
        {/* Product Info Section */}
        <Col
          xs={12}
          md={12}
          lg={12}
          className="postertext d-flex justify-content-center align-items-center flex-column mb-4"
        >
          <p className="fw-bold ms-2 fs-3">{data?.mainTitle}</p>
          <div className="smarthub">
            <p className="fs-5 fw-bold ms-2">{data?.subTitle}</p>
          </div>
        </Col>
        {/* Loop through products */}

        {data?.products?.map((product, index) => (
          <Col
            key={index}
            xs={12}
            md={2}
            lg={2}
            className="d-flex flex-column align-items-center p-2"
          >
            <div className="image-box d-flex justify-content-center align-items-center">
              <img
                className="img-fluid"
                src={product?.image}
                alt={product?.alt}
              />
            </div>
            <p className="fs-5 fw-bold m-0">{product?.name}</p>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <main className="dashboard main customer" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row className="home-intro">
              <Col xs={12} md={12} lg={12} className="headers p-2 mb-2">
                <Row>
                  <Col xs={12} md={2} lg={2}>
                    <div className="welcome">
                      <p>Welcome to</p>
                      <span>Customer Portal</span>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={8}
                    lg={8}
                    className="d-flex justify-content-center"
                  >
                    <div className="d-flex justify-content-start align-times-end">
                      {appConfig?.customerLogo1 && (
                        <img
                          className="img-fluid introCustomerlogo"
                          src={appConfig?.customerLogo1}
                          alt="logo1"
                        />
                      )}
                      <p className="fw-bold ms-2 fs-2">
                        {appConfig?.customerName}
                      </p>
                      {appConfig?.customerLogo2 && (
                        <img
                          className="img-fluid"
                          src={appConfig?.customerLogo2}
                          alt="logo2"
                        />
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={2} lg={2}>
                    <div className="date commTopButtonRightLeftPadding">
                      {/* <p>Today Date:</p> */}
                      <InputField
                        className="inputBox"
                        label=""
                        name="Fuels"
                        id="Fuels"
                        placeholder=""
                        // type="date"
                        value={formattedDate}
                        // onChange={onInputChange}
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={12} lg={12} className="card mb-2">
                {/* <p className="title p-2 mb-0">Overview</p> */}

                <div className="row">
                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card sales-card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Orders
                          {/* <span>| Today</span> */}
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-boxes"></i>
                          </div>
                          <div className="ps-3">
                            <LoaderSmall isLoading={totalOrderCountStatus} />
                            <h6>
                              {!totalOrderCountStatus
                                ? formatToTwoDigits(totalOrderCount || 0)
                                : ""}
                            </h6>
                            {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                            <span className="text-muted small pt-2 ps-1">
                              Total Count
                            </span>
                            <span
                              className="text-muted small pt-2 ps-1"
                              onClick={() =>
                                navigateToMainPage("totalOrderCount")
                              }
                            >
                              <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card revenue-card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Completed / Pending
                          {/* <span>| This Month</span> */}
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-box-seam"></i>
                          </div>
                          <div className="ps-3">
                            <LoaderSmall isLoading={orderCompletedStatus} />
                            <h6>
                              {!orderCompletedStatus
                                ? formatToTwoDigits(orderCompletedCount || 0) +
                                  " / " +
                                  formatToTwoDigits(orderPendingCount || 0)
                                : ""}
                            </h6>
                            {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                            <span className="text-muted small pt-2 ps-1">
                              Order Counts
                            </span>
                            <span
                              className="text-muted small pt-2 ps-1"
                              onClick={() =>
                                navigateToMainPage("orderCompletedCount")
                              }
                            >
                              <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card customers-card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Dispatch / Confimed
                          {/* <span>| This Year</span> */}
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-truck"></i>
                          </div>
                          <div className="ps-3">
                            <LoaderSmall isLoading={confirmedDispatchStatus} />
                            <h6>
                              {!confirmedDispatchStatus
                                ? formatToTwoDigits(
                                    totalPostDispatchCount || 0
                                  ) +
                                  " / " +
                                  formatToTwoDigits(confirmedDispatchCount || 0)
                                : ""}
                            </h6>
                            <span className="text-muted small pt-2 ps-1">
                              Count
                            </span>
                            <span
                              className="text-muted small pt-2 ps-1"
                              onClick={() =>
                                navigateToMainPage("confirmedDispatchCount")
                              }
                            >
                              <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!hideItem && (
                    <div className="col-lg-3 commTopButtonRightLeftPadding">
                      <div className="card info-card customers-card">
                        <div className="card-body">
                          <h5 className="card-title">
                            Customers
                            {/* <span>| This Year</span> */}
                          </h5>

                          <div className="d-flex align-items-center">
                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                              <i className="bi bi-people"></i>
                            </div>
                            <div className="ps-3">
                              <LoaderSmall isLoading={totalUserCountStatus} />
                              <h6>
                                {!totalUserCountStatus
                                  ? formatToTwoDigits(totalUserCount || 0)
                                  : ""}
                              </h6>
                              <span className="text-muted small pt-2 ps-1">
                                Total Count
                              </span>
                              <span
                                className="text-muted small pt-2 ps-1"
                                onClick={() =>
                                  navigateToMainPage("totalUserCount")
                                }
                              >
                                <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={12} md={12} lg={12} className="mb-2">
                <ProductSection data={productdata} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* <HomeIntorVideo show={getIntroVideo} onHide={introVideoHide} videopath={getVideoPath} /> */}
      <HomeCategorieDetails
        show={getCategoryDetail}
        onHide={categoryDetailHide}
      />
    </main>
  );
};
export default HomeIntro;
