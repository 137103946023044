import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import CreateDocTypeModal from "../../../../Components/Modal/supplier_app/CreateDocTypeModal";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import {
  addRfqCreation,
  addRfxNr,
  getRfqQuotation,
} from "../../../../redux/feature/supplier_app/sourcingSlice";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import ReverseTimer from "../../../../Components/TableReverseTimer/ReverseTimer";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { currencyFormat, formatDateTime } from "../../../../common/Validations";

const BuyerRequestForQuotationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");
  let rfqData = useSelector((state) => state?.sourcing?.rfq); // User Details from Store

  // Show Status Modal
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getPurchaseDocType, setPurchaseDocType] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Buyer RFQ Details",
      selector: (row) => row.rfxNr,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageBuyer(row.rfxNr)}
        >
          {row.rfxNr}{" "}
        </button>
      ),
      width: "160px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemLevelShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Version",
      selector: (row) => row.rfxVersion,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold"> {row.rfxTitle} </span>
        </>
      ),
      selector: (row) => row.rfxTitle,
      sortable: true,
      width: "250px",
    },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row?.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            cell: (row) => (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center iconTable"
              >
                <img
                  src="assets/img/newIcons/workflow.png"
                  alt="attachments"
                  style={{ width: "35px" }}
                  className="mt-1"
                  onClick={() => showStatusInfo(row.workflowActionLogs)}
                />
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),
    {
      name: "Status",
      selector: (row) => row.rfxHeaderStatus,
      cell: (row) => (
        <>
          {row.rfxHeaderStatus === "Published" ? (
            <span className="bg-table-status bg-table-warning-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "QTN Received" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "RFQ Created" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Commercial Approved" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Tech Approved" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Technical Open" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxHeaderStatus}
            </span>
          ) : row.rfxHeaderStatus === "Commercial Open" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxHeaderStatus}
            </span>
          ) : (
            row.rfxHeaderStatus
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: " Value",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.rfxValue).toFixed(2))}
        </span>
      ),
      selector: (row) => row.rfxValue,
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold"> {row.rfxCurrency} </span>
        </>
      ),
      selector: (row) => row.rfxCurrency,
      sortable: true,
      width: "120px",
    },
    {
      name: "Open Date ",
      selector: (row) => formatDateTime(row.openingDateTime) || "--",
      sortable: true,
      width: "160px",
    },
    {
      name: "Close Date",
      selector: (row) => formatDateTime(row.closingDateTime) || "--",
      sortable: true,
      width: "160px",
    },
    {
      name: "Countdown Timer",
      cell: (row) => (
        <>
          <ReverseTimer
            closingDateTime={row.closingDateTime}
            openingDateTime={row.openingDateTime}
          />
        </>
      ),
      sortable: true,
      width: "270px",
    },
    {
      name: "Supplier List",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => supplierListShowModal(row.supplierList)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.supplierList?.length} </span>
        </>
      ),
      width: "120px",
    },

    {
      name: "SAP RFQ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP Requisition",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " SAP Order ",
      selector: (row) => row.sap_rfx || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " Value",
      selector: (row) => row.rfxValue,
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency",
      selector: (row) => row.rfxCurrency,
      sortable: true,
      width: "120px",
    },
    {
      name: "Procurment Category",
      selector: (row) => row.rfxCategoryDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Technical User",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">
            {" "}
            {row.rfxTechFocalPersonId}{" "}
          </span>
        </>
      ),
      selector: (row) => row.rfxTechFocalPersonId,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Tech Target Date",
    //   selector: (row) => row.rfxTechTargetDate,
    //   sortable: true,
    //   width: "140px",
    // },
    {
      name: "Extension Count",
      selector: (row) => row.extensionCount,
      sortable: true,
      width: "160px",
    },
    {
      name: "Bid Type",
      selector: (row) => row.rfxbidTypeCode,
      cell: (row) => (
        <>
          {row.rfxbidTypeCode === "OPEN" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.rfxbidTypeCode}
            </span>
          ) : row.rfxbidTypeCode === "CLOSE" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.rfxbidTypeCode}
            </span>
          ) : (
            row.rfxbidTypeCode
          )}
        </>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Technical",
      cell: (row) => (
        <>
          {row.rfxHeaderStatus === "Tech Approved" ||
          row.rfxHeaderStatus === "Commercial Open" ? (
            <button
              className="btnApprove"
              onClick={() => detailedTechnicalEvaluationPage(row.rfxNr)}
            >
              TE Details
            </button>
          ) : (
            <button className="btnTable" disabled={true}>
              TE Details
            </button>
          )}
        </>
      ),
      width: "150px",
    },
    {
      name: "Commercial",
      cell: (row) => (
        <>
          {row.rfxHeaderStatus === "Commercial Open" ? (
            <button
              className="btnTable"
              onClick={() => detailedCommercialEvaluation(row.rfxNr)}
            >
              CE Prepare
            </button>
          ) : (
            <button className="btnTable" disabled={true}>
              CE Prepare
            </button>
          )}
        </>
      ),
      width: "140px",
    },
    {
      name: "Order",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => createPO()}>
            Create PO
          </button>
          {/* <button className="btnTable" onClick={() => createInvoice() >Approved {row.order}</button> */}
        </>
      ),
      width: "140px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyAddressDesc} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Buyer Responsible",
      selector: (row) => row.buyerResponsible,
      sortable: true,
      width: "190px",
    },
    {
      name: "Buyer Contact",
      selector: (row) => row.buyerEmail,
      sortable: true,
      width: "250px",
    },
    {
      name: "Envelope Type",
      selector: (row) => row.rfxTypeDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Product",
      selector: (row) => row.productType,
      sortable: true,
      width: "150px",
    },
  ];

  const supplierListColInfo = [
    {
      name: "Supplier Name",
      selector: (row) => row.AssociateNumber,
      sortable: true,
      width: "180px",
    },
    {
      name: "Supplier Name",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Supplier Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "180px",
    },
    {
      name: "Mail",
      selector: (row) => row.email || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Contact",
      selector: (row) => row.contactNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      width: "140px",
    },
    {
      name: "RFX Status",
      selector: (row) => row.rfxSupplierStatus,
      sortable: true,
      width: "160px",
    },
    {
      name: "Supplier Status",
      selector: (row) => row.qtnSupplierStatus,
      sortable: true,
      width: "150px",
    },
    {
      name: "Quotation Number",
      selector: (row) => row.quotationNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Quotation Version",
      selector: (row) => row.qtnVersion,
      sortable: true,
      width: "180px",
    },
  ];

  const LineItemsColInfo = [
    {
      name: "RFQ Item Number",
      selector: (row) => row.rfxItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "PR Number",
      selector: (row) => row.purchaseReqDocumentNr,
      width: "200px",
    },
    {
      name: "Material Code",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "250px",
    },
    {
      name: "Material Desc",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "250px",
    },
    {
      name: "Quantity Unit",
      selector: (row) => row.orderUnitOfMeasure || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "250px",
    },
    {
      name: "Item Rate",
      selector: (row) => row.netValue,
      sortable: true,
      width: "250px",
    },
  ];

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on Line Item Level Data
  const lineItemLevelShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(LineItemsColInfo);
    setModalTitle("Line Item Level Details");
    setItemLevelModalShow(true);
  };

  // OnClick on Supplier List Data
  const supplierListShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(supplierListColInfo);
    setModalTitle("Supplier List Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  let obj = {
    modalTitle: "Create Manual RFQ",
    navigate: Constants.SOURCING_CREATE_MANUAL_RFQ,
  };
  const createRFQ = () => {
    setModalData(obj);
    setModalShow(true);
    dispatch(addRfqCreation("Manual"));
  };

  const hideModal = () => {
    setModalShow(false);
  };

  // eslint-disable-next-line no-unused-vars
  const detailedPageVendor = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_VENDOR_RFQ_DETAILS);
  };
  const detailedPageBuyer = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_BUYER_RFQ_DETAILS);
  };
  const detailedTechnicalEvaluationPage = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_TECHNICAL_EVALUATION);
  };

  const loadInitialData = () => {
    setData(rfqData?.data);
  };

  //Create CE
  const detailedCommercialEvaluation = (rfxNr) => {
    dispatch(addRfxNr(rfxNr));
    navigate(Constants.SOURCING_COMMERCIAL_EVALUATION);
  };
  //Create PO
  const createPO = () => {
    navigate(Constants.SOURCING_PURCHASE_ORDER_CREATION);
  };

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getRfqQuotation(obj));
    setData(rfqData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getRfqQuotation(obj));
    setData(rfqData?.data);
  };

  useEffect(() => {
    loadInitialData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqData]);

  const getMasterData = async () => {
    let obj = {
      codeType: "RFQCategory",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseDocType(item?.data);
      } else {
        setPurchaseDocType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  //on page load
  useEffect(() => {
    getMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getRfqQuotation(obj));
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Request For Quotation</h2>
                <p className="subText">
                  Lists of Request For Quotation and details
                </p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RQ</span>
                    </Col>
                    <Col xs={12} md={5} lg={5}>
                      <span className="headerTitle">RFQ List</span>
                      <span className="subHeaderTitle">
                        View of Request For Quotation and Status{" "}
                      </span>
                    </Col>

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-plus-lg"></i> Change Buyer
                      </button>
                    </Col> */}

                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={() => createRFQ()}>
                        <i className="bi bi-plus-lg"></i> Create Manual
                      </button>
                    </Col>

                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-copy"></i> Copy
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-person"></i> Buyer Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-recycle"></i> Status Change
                      </button>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-x-circle"></i> Cancel
                      </button>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={rfqData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={rfqData?.pagination?.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateDocTypeModal
        show={modalShow}
        onHide={() => hideModal()}
        modaldata={modalData}
        dropdowndata={getPurchaseDocType}
        navigate={Constants.SOURCING_CREATE_RFQ}
      />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
    </main>
  );
};

export default BuyerRequestForQuotationList;
