/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import AuctionContactInformation from "../../../Components/Modal/supplier_app/Auction/AuctionContactInformation";
import AuctionCommonFilter from "../../../Components/Filter/Aucton/AuctionCommonFilter";

const CreateAuction = () => {
  localStorage.setItem("menu", "Auction");
  localStorage.setItem("submenu", "Create Auction");

  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const [AuctionInformationModel, setAuctionInformationModel] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  console.log("PriceSheduleModuledata", PriceSheduleModuledata);
  const [AuctionInformationData, setAuctionInformationData] = useState([]);
  console.log("AuctionInformationData", AuctionInformationData);
  // const handleDataFromCreateOtherChanges = (data) => {
  //   // console.log("Data received from child:", data);
  //   setAuctionInformationData((prevData) => [...prevData, data]);
  // };
  const handleDelete = (indexToDelete) => {
    setAuctionInformationData((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };
  const showsetHeaderText = () => {
    setAuctionInformationModel(true);
  };
  const hideAuctionInformationModel = () => {
    setAuctionInformationModel(false);
  };
  const handleDataPriceShedule = (data) => {
    // console.log('POLineItemsData>>',data)
    setPriceSheduleModuledata((prevData) => [...prevData, data]);
    setAuctionInformationData((prevData) => [...prevData, data]);
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };
  /* acution information */
  const AuctionInformationColumns = [
    {
      name: "",
      cell: (row) => (
        <>
          <span></span>
        </>
      ),
      width: "5rem",
    },
    {
      name: "profile",
      cell: (row) => (
        <>
          <span></span>
        </>
      ),
      width: "5rem",
    },

    {
      name: "buyer Name",
      cell: (row) => (
        <>
          <span>{row?.name}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Buyer Email",
      cell: (row) => (
        <>
          <span>{row?.email}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Buyer Contact Number",
      cell: (row) => (
        <>
          <span>{row?.description}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash text-danger fs-3"
            ></i>
          </span>
        </>
      ),
      width: "10rem",
    },
  ];
  /* itme binding  data */
  const ItemsBindingData = [
    {
      ItemNo: "Item No",
      PRNumber: "PR Number",
      PRLine: "PR Line",
      ItemCode: "Item Code",
      ItemDescription: "Item Description",
      ItemText: "Item Text",
      Quantity: "Quantity*",
      UOM: "UOM*",
      CeilingPrice: "Ceiling Price",
      AuctionValue: "Auction Value",
      RequestedDeliveryDate: "Requested Delivery Date",
      ServiceHeaderCode: "Service Header Code",
      ServiceStartDate: "Service Start Date",
      ServiceEndDate: "Service End Date",
      TechnicalSpecification: "Technical Specification",
      INCOTerms: "INCO Terms",
      INCOTermsLocation: "INCO Terms Location",
      Delete: "Delete",
    },
  ];
  const ItemsBindingColumns = [
    {
      name: "Item No",
      cell: (row) => (
        <>
          <span>{row.ItemNo}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "PR Number",
      cell: (row) => (
        <>
          <span>{row.PRNumber}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "PR Line",
      cell: (row) => (
        <>
          <span>{row.PRLine}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Item Code",
      cell: (row) => (
        <>
          <span>{row.ItemCode}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Item Description",
      cell: (row) => (
        <>
          <span>{row.ItemDescription}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span>{row.ItemText}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Quantity*",
      cell: (row) => (
        <>
          <span>{row.Quantity}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "UOM*",
      cell: (row) => (
        <>
          <span>{row.UOM}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Ceiling Price",
      cell: (row) => (
        <>
          <span>{row.CeilingPrice}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Auction Value",
      cell: (row) => (
        <>
          <span>{row.AuctionValue}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Requested Delivery Date",
      cell: (row) => (
        <>
          <span>{row.RequestedDeliveryDate}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Service Header Code",
      cell: (row) => (
        <>
          <span>{row.ServiceHeaderCode}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Service Start Date",
      cell: (row) => (
        <>
          <span>{row.ServiceStartDate}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Service End Date",
      cell: (row) => (
        <>
          <span>{row.ServiceEndDate}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Technical Specification",
      cell: (row) => (
        <>
          <span>{row.TechnicalSpecification}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "INCO Terms",
      cell: (row) => (
        <>
          <span>{row.INCOTerms}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "INCO Terms Location",
      cell: (row) => (
        <>
          <span>{row.INCOTermsLocation}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <>
          <span>{row.Delete}</span>
        </>
      ),
      width: "150px",
    },
  ];
  /* bidder  data */
  const BidderData = [
    {
      SNo: "S.No",
      SupplierCodeAndName: "Supplier Code And Name",
      Items: "Items",
      Country: "Country",
      EmailId: "Email Id",
      ContactNo: "Contact No",
      UserID: "User ID",
      Delete: "Delete",
    },
  ];
  const BidderColumns = [
    {
      name: "S.No",
      cell: (row) => (
        <>
          <span>{row.SNo}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier Code And Name",
      cell: (row) => (
        <>
          <span>{row.SupplierCodeAndName}</span>
        </>
      ),
      width: "250px",
    },
    {
      name: "Items",
      cell: (row) => (
        <>
          <span>{row.Items}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Country",
      cell: (row) => (
        <>
          <span>{row.Country}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Email Id",
      cell: (row) => (
        <>
          <span>{row.EmailId}</span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Contact No",
      cell: (row) => (
        <>
          <span>{row.ContactNo}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "User ID",
      cell: (row) => (
        <>
          <span>{row.UserID}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <>
          <span>{row.Delete}</span>
        </>
      ),
      width: "100px",
    },
  ];
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          {/* Auction  */}
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Auction</h2>
            <p className="subText">Fill Auction Details</p>
            <hr />
            {/*auction Basic information */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Auction Basic information
                      </span>
                      <span className="subHeaderTitle">
                        Auction Basic information
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tentative Start Time (UAE) "
                          name="firstName"
                          placeholder=""
                          type="date"
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Duration (HH:MM)"
                          name=""
                           type="time"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tentative End Time (UAE) "
                          name=""
                          placeholder=""
                          type="date"
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Auction Type "
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Decrement In Auction Currency"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Auction Currency"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Reference RFQ Number"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Buyer Category"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="firstName"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Description"
                          name=""
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* AuctionContactInformation */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle">
                        Auction Contact Information
                      </span>
                      <span className="subHeaderTitle">
                        Auction Contact Information
                      </span>
                    </Col>
                    <Col xs={4} md={1} lg={1} className="">
                      <button className="btnTable" onClick={showsetHeaderText}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={AuctionInformationColumns}
                          data={AuctionInformationData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Auction Rules And Settings */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Auction Rules And Settings
                      </span>
                      <span className="subHeaderTitle">
                        Auction Rules And Settings
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex-row justify-content-start  align-items-center"
                      >
                        <p>Allow bidders to select items for bidding</p>
                        <div className="d-flex">
                          <div className="mb-3">
                            <label className="mx-2">Yes</label>
                            <input type="radio" name="selectItems" />
                          </div>
                          <div>
                            <label className="mx-2">No</label>
                            <input type="radio" name="selectItems" />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex-row justify-content-start  align-items-center"
                      >
                        <p>Display Bid Rank</p>
                        <div className="d-flex">
                          <div className="mb-3">
                            <label className="mx-2">Yes</label>
                            <input type="radio" name="selectItems" />
                          </div>
                          <div>
                            <label className="mx-2">No</label>
                            <input type="radio" name="selectItems" />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="REFERENCE DOCUMENTS : "
                          name=""
                          type="file"
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex-row justify-content-start  align-items-center"
                      >
                        <p>Celling Price</p>
                        <div className="d-flex">
                          <div className="mb-3">
                            <label className="mx-2">Yes</label>
                            <input type="radio" name="selectItems" />
                          </div>
                          <div>
                            <label className="mx-2">No</label>
                            <input type="radio" name="selectItems" />
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex-row justify-content-start  align-items-center"
                      >
                        <p>Tie Bid Allowed</p>
                        <div className="d-flex">
                          <div className="mb-3">
                            <label className="mx-2">Yes</label>
                            <input type="radio" name="selectItems" />
                          </div>
                          <div>
                            <label className="mx-2">No</label>
                            <input type="radio" name="selectItems" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Auction Auto Extension Rules */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AR</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Auction Auto Extension Rules
                      </span>
                      <span className="subHeaderTitle">
                        Auction auto extension rulesand settings
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding "
                      >
                        <ComboboxField
                          label="Before the end of auction within below mentioned minutes"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding "
                      >
                        <ComboboxField
                          label="Auction will be auto-extended by below mentioned minutes"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding "
                      >
                        <ComboboxField
                          label="When new bid is received for the below mentioned bid level"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* this is dynamic from is not creage empty */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AR</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Form : Instruction to Buidders
                      </span>
                      {/*                       <span className="subHeaderTitle">
                        Auction auto extension rulesand settings
                      </span> */}
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      {/* dynamic from is not creage empty */}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Auction Additional Information */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AR</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">
                        Auction Additional Information
                      </span>
                      <span className="subHeaderTitle">
                        Auction Additional Information
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Plant"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment Term"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding d-flex justify-content-center"
                      >
                        <div>
                          <p>Display Bid Rank</p>
                          <div className="d-flex">
                            <div className="mb-3">
                              <label className="mx-2">Yes</label>
                              <input type="radio" name="selectItems" />
                            </div>
                            <div>
                              <label className="mx-2">No</label>
                              <input type="radio" name="selectItems" />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="INCOTerms"
                          placeholder=""
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCOTERM Location"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Date (UAE) "
                          name=""
                          type="date"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INSTRUCTIONS TO BIDDER"
                          name=""
                          type="file"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      {/* this below col is download option */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="GENERAL TERMS AND CONDITION"
                          name=""
                          type="file"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="GENERAL TERMS AND CONDITION"
                          name=""
                          type="text"
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}

                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* items binding table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AU</span>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <span className="headerTitle">Items for bidding</span>
                      <span className="subHeaderTitle">Items for bidding</span>
                    </Col>
                    <Col xs={12} md={6} lg={1}>
                      <button className="btnTable">dwonload template</button>
                    </Col>
                    <Col xs={12} md={6} lg={1}>
                      <button className="btnTable">Upload Data</button>
                    </Col>
                    <Col xs={12} md={6} lg={1}>
                      <button className="btnTable">Add PR Items</button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={ItemsBindingColumns}
                          data={ItemsBindingData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*bidder section table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AU</span>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <span className="headerTitle">Bidders</span>
                      <span className="subHeaderTitle">Bidders Section</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <AuctionCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={BidderColumns}
                          data={BidderData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* buttons */}
          {/*  <div className="pageFooter">
            <Row className="rowDirection">
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  Crate GRN
                </button>
              </Col>
            </Row>
          </div> */}
        </Col>
      </Row>
      <AuctionContactInformation
        show={AuctionInformationModel}
        onHide={hideAuctionInformationModel}
        hederTextData={handleDataPriceShedule}
      />
    </main>
  );
};

export default CreateAuction;
