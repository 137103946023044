import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemService from "../../../../services/customer_appCommonService";
import { InputField } from "../../../../Components/formElements/InputField";

//Redux
import { useSelector } from "react-redux"; //useDispatch


const GoogleMapComponent = (props) => {
  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store

  const fromAddress = appConfig?.customerGoogleAddress;
  const fromCoords = { lat: 13.398376, lng: 77.671755 }; // Replace with actual coordinates
  const [toAddress, setToAddress] = useState("");
  const [toCoords, setToCoords] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      initializeAutocomplete();
    });
    if (props?.passmapdata) {
      setToAddress(props?.passmapdata[0]?.projectLocation);
      setToCoords(props?.passmapdata[0]?.fromToCoords?.destinations);
      setDistance(props?.passmapdata[0]?.distance);
      setDuration(props?.passmapdata[0]?.duration);
    }
  }, [props]);

  // Load Google Maps JavaScript API
  const loadGoogleMapsScript = (callback) => {
    if (window.google) {
      callback();
      return;
    }
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDfuYFHr0Odqv0EMjVS71uf0tVsP30n1qw&libraries=places`;
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  };

  // Initialize Google Places Autocomplete
  const initializeAutocomplete = () => {
    if (!window.google) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setToAddress(place.formatted_address);
        setToCoords({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setDuration(null);
      }
    });
  };

  const getDistanceAndTime = async () => {
    if (!toCoords) return;

    let obj = {
      origins: {
        lat: fromCoords.lat,
        lng: fromCoords.lng,
      },
      destinations: {
        lat: toCoords.lat,
        lng: toCoords.lng,
      },
    };

    try {
      const data = await ItemService.mapDistance(obj);
      // console.log("API Response:", data); // Debugging purpose

      if (data.rows && data.rows[0].elements[0].status === "OK") {
        setDistance(data.rows[0].elements[0].distance.text);
        setDuration(data.rows[0].elements[0].duration.text);

        let param = {
          projectIndex: props?.projectIndex,
          plantAddress: fromAddress,
          projectLocation: toAddress,
          distance: data.rows[0].elements[0].distance.text,
          duration: data.rows[0].elements[0].duration.text,
          fromToCoords: obj,
        };
        // console.log(">>param", param);
        
        props.getmapdata(param);
      } else {
        console.error("API Error:", data);
        setDistance("Error: " + data.rows[0].elements[0].status);
        setDuration("Error: " + data.rows[0].elements[0].status);
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setDistance("Error fetching data");
      setDuration("Error fetching data");
    }
  };

  return (
    // <div style={{ maxWidth: "800px", margin: "auto", textAlign: "center" }}>
    <>
      <Row className="pt-2 pb-4">
        <Col xs={12} lg={6} className="commTopButtonRightLeftPadding">
          <InputField
            label="From Address"
            id="plantAddress"
            name="plantAddress"
            className="inputBox"
            placeholder=""
            value={fromAddress}
            // onChange={handleInputChange}
            // required={mandatory}\
            disabled={true}
          />
        </Col>
        <Col xs={12} lg={6} className="commTopButtonRightLeftPadding">
          {/* To Address Input with Autocomplete */}
          <p className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion mb-0">
            To Address
          </p>
          <input
            type="text"
            placeholder="Enter destination address..."
            ref={autocompleteRef}
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
            style={{
              width: "90%",
              padding: "10px",
              fontSize: "16px",
              marginBottom: "10px",
            }}
            disabled={props?.editstatus}
          />
          {!props?.editstatus && (
            <>
              <br />
              <button
                onClick={getDistanceAndTime}
                className="btnTable"
                disabled={props?.editstatus}
              >
                Get Distance & Time
              </button>
            </>
          )}
        </Col>

        {/* Display Distance & Estimated Time */}
        {distance && duration && (
          <Col offset={2} xs={12} lg={12} className="text-center mt-4">
            <p className="p-0 m-0">
              <sapn className="fw-bold">Note: </sapn>Distance Shown is near to
              exact.Actual distance may slightly vary
            </p>
            <h4 className="bg-table-status bg-table-info-status mt-1">
              Distance: {distance} | Travel Time: {duration}
            </h4>
          </Col>
        )}

        <Col xs={12} lg={12} className="commTopButtonRightLeftPadding mt-4">
          {/* TO Address Map with Route Preview */}
          {toCoords && (
            <>
              <h5>To: {toAddress}</h5>
              <iframe
                title="TO"
                width="100%"
                height="400"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDfuYFHr0Odqv0EMjVS71uf0tVsP30n1qw&origin=${fromCoords.lat},${fromCoords.lng}&destination=${toCoords.lat},${toCoords.lng}&mode=driving`}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </>
          )}
        </Col>
        {/* </div> */}
      </Row>
    </>
  );
};

export default GoogleMapComponent;
