import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { validateRequired } from "../../../../common/Validations";

import ItemService from "../../../../services/supplier_appCommonService";
//import { getCategoryListData } from "../../../redux/feature/userSlice";

//Redux
// import { useDispatch } from "react-redux"; //useDispatch
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import SupplierConstants from "../../../../common/SupplierConstants";
import CustomerConstants from "../../../../common/CustomerConstants";

const MaterialDetails = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Material Master");

  let materialDetailsData = useSelector(
    (state) => state?.user?.materialList?.materialDetails
  );

  const [isCardVisible, setCardVisible] = useState(true);
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const [formInputs, setFormInputs] = useState({
    sNo: "",
    materialCode: "",
    materialLongText: "",
    materialGroup: "",
    uOM: "",
    plant: "",
  });
  const modalData = {
    title: "Material Updated",
    successMsg: "",
  };
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getFailureModal] = useState(failureModalData);

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  /* Edit Button */
  const handleEdit = () => {
    setEditMode(false);
  };
  /* Edit Button */
  const handleEditMaterial = async () => {
    let requiredFields = [];
    if (
      !validateRequired(formInputs?.vendorCategoryText) ||
      !validateRequired(formInputs?.vendorSubCategoryText) ||
      !validateRequired(formInputs?.vendorSubCategoryCode) ||
      !validateRequired(formInputs?.vendorSubCategoryLongText)
    ) {
      requiredFields.push("All fields are required..!");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    setDisabled(true);
    await ItemService.updateMaterial(formInputs).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        //dispatch(getMaterialListData()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };
  console.log(handleEditMaterial)// remove this button
   /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (materialDetailsData) {
      setFormInputs(materialDetailsData);
    } else {
      navigate(CustomerConstants.CUSTOMER_V1_MATERIAL_MASTER_DETAILS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Material</h2>
            <p className="subText">Material Master Details</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MD</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">Create Material</span>
                      <span className="subHeaderTitle">Create</span>
                    </Col>

                    <Col xs={4} md={2} lg={2} className="text-right">
                      {editMode && (
                        <span>
                          <button className="btnTable" onClick={handleEdit}>
                            Edit
                          </button>
                        </span>
                      )}
                      {!editMode && (
                        <span>
                          <button
                            className="btnTable"
                            disabled={disabled}
                            //onClick={handleEditCategory}
                          >
                            Update Category
                          </button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      
                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="S no"
                          name="sNo"
                          id="sNo"
                          extraLabel=""
                          placeholder="S no"
                          value={formInputs.sNo || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Material Code"
                          name="materialCode"
                          id="materialCode"
                          placeholder="MaterialCode"
                          value={formInputs.materialCode || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Material Description"
                          type="text"
                          name="materialLongText"
                          id="materialLongText"
                          placeholder="Material Description"
                          value={formInputs.materialLongText || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Material Group"
                          name="materialGroup"
                          id="materialGroup"
                          extraLabel=""
                          placeholder="Material Group"
                          value={formInputs.materialGroup || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="UOM"
                          name="uOM"
                          id="uOM"
                          extraLabel=""
                          placeholder="UOM"
                          value={formInputs.uOM || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant"
                          name="plant"
                          id="plant"
                          extraLabel=""
                          placeholder="plant"
                          value={formInputs.plant || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={SupplierConstants.CATEGORY_LIST}
      />
    </main>
  );
};

export default MaterialDetails;
