import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout  from '../../../Layout/Layout'; 

const UserDelegation =() =>{
  localStorage.setItem('menu','Delegation');
  localStorage.setItem('submenu','User Delegation');

  return(
    <main className="dashboard main" id="main"> 
      <Layout/>
        <Row className="justify-center subContent">
          <Col xs={12} md={12}  className="rightSide">

            <div className="rightSideInner timesheet">
              <h2 className='mainTitle'>User Delegation</h2>
              <p className='subText'>User Delegation Main title</p>
              <hr/>

              {/* Code Start Here */}
              <h1>Test coding User Delegation</h1>

            </div>   
          </Col>
        </Row>
    </main>
  )
}

export default UserDelegation;