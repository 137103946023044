import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

const FailureModal = React.forwardRef((props, ref) => {
  // Handle form OK
  const okConfirm = async () => {
    props.onHide();
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="md"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-exclamation-triangle"></i>
          <span className="modal-title"> {props?.failuremodaldata?.title}</span>
          {/* <p className='subText'>Confirm the below given statement</p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col
              xs={12}
              md={12}
              lg={12}
              className="commLeftRightPadding text-left mt-2"
            >
              {/* <h3>
                <i className="bi bi-x-circle-fill text-danger"></i> Fill Required Fields
              </h3> */}
              <div className="text-danger ml-8 mt-4">
                {/* {props?.failureModalData?.successMsg} */}
                {props.requiredfields?.map((item, index) => {
                  return (
                    <div key={index}>
                      <h5>
                        {/* <i className="bi bi-x-circle-fill text-danger"></i>{" "} */}
                        <i className="bi bi-x-circle-fill text-danger"></i> {item}
                      </h5>
                      {/* <p>{item}</p> */}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={4} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => okConfirm()}
                className="btn btn-primary w-full justify-center rounded-md"
              >
                OK
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default FailureModal;
