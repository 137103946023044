import React, { useState, useEffect } from "react";
import DropdownList from "react-widgets/DropdownList";

export const ComboboxFieldSubArray = ({
  index,
  id,
  name,
  code,
  description,
  label,
  placeholder,
  error,
  data,
  setValue,
  getvalue = () => {},
  disabled,
  onChangeValue = () => {},
  extraLabel = "",
  required,
  errors = {
    name: "test",
  },
}) => {
  const [selectedData, setSelectedData] = useState(setValue);

  // Sync selectedData with setValue
  useEffect(() => {
    setSelectedData(setValue);
  }, [setValue]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
        {label} {required ? "*" : ""}{" "}
        {extraLabel !== "" ? (
          <i className="bi bi-info-circle" title={extraLabel}></i>
        ) : (
          ""
        )}
      </label>
      <DropdownList
        id={id}
        name={name}
        className={`dropdownHive w-full rounded-md ${
          error ? "border-red-dropdown" : ""
        }`}
        data={data}
        dataKey="id"
        textField="name"
        defaultValue={setValue}
        value={selectedData} // Use selectedData instead of setValue
        onChange={(value) => {
          setSelectedData(value); // Update selectedData
          onChangeValue(index, id, name, value); // Call onChangeValue with the full value object
          getvalue({
            ...value,
            textField: name,
            textCode: code,
            textDesc: description,
            index: index,
            status: value?.name ? true : false,
            label: label,
          }); // Call getvalue
        }}
        filter="contains"
        placeholder={placeholder}
        disabled={disabled}
      />
      {error && (
        <span className="text-red-500 text-md fieldErrMsg">{error}</span>
      )}
    </>
  );
};