import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../../Layout/Layout";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Compomnent
import { InputField } from "../../../../Components/formElements/InputField";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/CustomerConstants";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { getLoginUserData } from "../../../../redux/feature/userSlice";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import Loader from "../../../../Components/Loader";
import CutomerQuotationPrint from "./CutomerQuotationPrint";
import { useReactToPrint } from "react-to-print";

const CustomerQuotationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Quotation");

  let quotationID = useSelector(
    (state) => state?.customerV1?.costingSheet?.quotationID
  ); // User Details from Store
  let workflow = useSelector((state) => state?.customer?.customerWorkflow); // workflow from Store

  const [formInputs, setFormInputs] = useState({
    termsConditions: [],
  });

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [getAction, setAction] = useState("");
  // const [disabled, setDisabled] = useState(false);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  let itemDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "80px",
    },
    {
      name: "Material",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialCode"
          placeholder=""
          value={row.materialCode || ""}
          disabled={true}
          //  onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Product",
      selector: (row) => row.materialDescription || "--",
      cell: (row) => (
        <>
          <p>{row.materialDescription || ""}</p>
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Quote Price (INR)",
      selector: (row) => row.quotePrice || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="quotePrice"
          placeholder=""
          value={row.quotePrice || ""}
          disabled={true}
          //  onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    // {
    //   name: "Currency",
    //   selector: (row) => row.currency || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       name="currency"
    //       placeholder=""
    //       value={row.currency || ""}
    //       disabled={true}
    //       //  onChange={(event) => handleInputChange(row.local_id, event, "Number")}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
  ];

  const loadInitialPageData = async () => {
    setLoading(true);
    if (quotationID) {
      let obj = {
        documentNumber: quotationID,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getQuotationDetailV1(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item?.data[0]);
          setData(item?.data[0]?.items);
          // dispatch(addPoSelectedDetails(item?.data));
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      if (JSON.stringify(workflow) === "{}") {
        if (workflow?.page === "Inbox") {
          navigate(Constants.INBOX);
        } else {
          navigate(Constants.OUTBOX);
        }
      } else {
        navigate(Constants.CUSTOMER_V1_QUOTATION_LIST);
      }
    }
  };

  // const handleSubmit = async () => {
  //   setDisabled(true);
  //   let obj = {
  //     data: formInputs,
  //   };
  //   const toastId = toast.loading("Loading...", {
  //     position: "top-right",
  //   });
  //   await ItemService.addQuotationV1(obj).then((item) => {
  //     console.log(">> res!", item);
  //     if (item?.status === 200) {
  //       toast.success(item?.message || "Quotation Created successfully", {
  //         duration: 2000,
  //         position: "top-right",
  //       });
  //       navigate(Constants.CUSTOMER_V1_COSTING_SHEET_LIST)
  //     } else {
  //       toast.error(item?.message || "Please try again !!", {
  //         duration: 4000,
  //         position: "top-right",
  //       });
  //     }
  //     toast.dismiss(toastId);
  //   });

  //   setDisabled(false);
  // };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationID]);

  // Approve
  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {
      items: data,
    };
    let newObj = { ...bodyObj, ...obj };

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        navigate(Constants.INBOX);
        dispatch(getLoginUserData());
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const showApproveModal = async (name) => {
    setModalShowApprove(true);
    setAction(name);
  };
  const hideModalApprove = () => {
    setModalShowApprove(false);
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const navigateToWorkflow = () => {
    if (workflow?.page === "Inbox") {
      navigate(Constants.INBOX);
    } else {
      navigate(Constants.OUTBOX);
    }
  };

  //print
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `KYC_${Math.floor(Math.random() * 1000000)}`,
  });
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Quotation Creation :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {quotationID}
              </span>
              <span>
                {formInputs?.quotationStatus === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Non Invited" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Approved" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : formInputs?.quotationStatus === "Submitted" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.quotationStatus}
                  </span>
                ) : (
                  formInputs?.quotationStatus
                )}
              </span>
            </h2>
            <p className="subText">Quotation Information </p>
            <hr />
            {!workflow?.workflow_record?.decision?.length > 0 && (
              <Col
                xs={6}
                md={{ span: 2, offset: 10 }}
                lg={{ span: 2, offset: 10 }}
                className="text-left"
              >
                <button
                  className="btnInfo"
                  onClick={handlePrint}
                  // disabled={}
                >
                  <i class="bi bi-printer"></i> Print Quotation
                </button>
                <div style={{ display: "none" }}>
                  <CutomerQuotationPrint ref={contentRef} data={formInputs} />
                </div>
              </Col>
            )}

            <Loader isLoading={loading} className={"text-center"} />

            {!loading && (
              <>
                <div className="pageFooter mb-4">
                  <Row>
                    {workflow?.workflow_record?.decision?.length > 0 && (
                      <Col xs={12} md={4} lg={4} className="text-left">
                        <button
                          className="btnBack"
                          onClick={() => navigateToWorkflow()}
                        >
                          <i className="bi bi-arrow-return-left"></i> Go Back
                        </button>
                      </Col>
                    )}
                    <Col xs={12} md={8} lg={8} className="text-right">
                      {workflow?.workflow_record?.decision?.length > 0 &&
                        workflow?.workflow_record?.decision?.map(
                          (item, index) => (
                            <button
                              className={
                                item === "Approve" || item === "Submit"
                                  ? "btnCommon btn-approve"
                                  : "btnCommon btn-danger"
                              }
                              onClick={() => showApproveModal(item)}
                              key={index}
                            >
                              <i
                                className={
                                  item === "Approve" || item === "Submit"
                                    ? "bi bi-check2-circle"
                                    : "bi bi-x-circle"
                                }
                              ></i>{" "}
                              {item}
                            </button>
                          )
                        )}
                    </Col>
                  </Row>
                </div>

                {/* 01 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">BI</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">
                            Quotation Creation{" "}
                          </span>
                          <span className="subHeaderTitle">
                            Quotation Details
                          </span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Sales Officer"
                              name="salesOfficerName"
                              value={formInputs.salesOfficerName || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Customer Name"
                              name="organisationName"
                              value={formInputs.organisationName || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Project Name"
                              name="projectName"
                              value={
                                formInputs?.projectDetails?.length > 0
                                  ? formInputs?.projectDetails[0]?.projectName
                                  : ""
                              }
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Project/Site Location"
                              name="projectLocation"
                              value={formInputs.projectLocation || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex-column"
                          >
                            <InputField
                              className="inputBox"
                              label="Plant Location"
                              name="plantAddress"
                              value={formInputs.plantAddress || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label=" Address Lines 1 / Street"
                              name="address"
                              placeholder=""
                              value={formInputs.address || ""}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Address Line 2"
                              name="address2"
                              placeholder=""
                              value={formInputs.address2 || ""}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="City"
                              name="city"
                              placeholder=""
                              value={formInputs.city || ""}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Country"
                              name="country"
                              placeholder=""
                              value={formInputs.country || ""}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="State"
                              name="state"
                              placeholder=""
                              value={formInputs.state || ""}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Postal Code/PO Box"
                              name="cityPostalCode"
                              placeholder=""
                              value={formInputs.cityPostalCode}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              className="inputBox"
                              label="Remarks"
                              name="quotationRemarks"
                              placeholder=""
                              value={formInputs.quotationRemarks}
                              onChange={onInputChange}
                              required={true}
                              disabled={true}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 02 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CP</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Item Details</span>
                          <span className="subHeaderTitle">Item Details</span>
                        </Col>
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={itemDetailsCol}
                              data={data}
                              // progressPending={loading}
                              // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              // selectableRows
                              // onSelectedRowsChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Documents 04 */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">TD</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">
                            Terms & Conditions
                          </span>
                          <span className="subHeaderTitle">
                            Terms & Conditions
                          </span>
                        </Col>
                        {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={12}
                            lg={12}
                            className="commTopButtonRightLeftPadding"
                          >
                            <Row>
                              <Col
                                xs={12}
                                md={12}
                                lg={8}
                                className="commTopButtonRightLeftPadding"
                              >
                                {formInputs?.termsConditions.map((item, i) => (
                                  <div className="form-check" key={item.id}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`gridCheck${item.id}`}
                                      checked={item.status}
                                      // onChange={() =>
                                      //   handleTermsConditionCheckBox(item.id)
                                      // }
                                      disabled={item?.disabled}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`gridCheck${item.id}`}
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                ))}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      <ApproveRemarksModal
        show={modalShowApprove}
        onHide={() => hideModalApprove()}
        onvaluechange={handleValueChange}
      />
    </main>
  );
};

export default CustomerQuotationDetails;
