import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector } from "react-redux"; // useDispatch

//API
import ItemService from "../../../../services/customer_v2_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
// import { InputField } from "../../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
// import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import Constants from "../../../../common/SupplierConstants";
import { currencyFormat } from "../../../../common/Validations";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import SuccessModal from "../../../../Components/Modal/supplier_app/SuccessModal";
import toast from "react-hot-toast";
import { InputField } from "../../../../Components/formElements/InputField";


const CustInvoiceDetailsV2 = () => {
  // const dispatch = useDispatch();

  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Invoice");

  let invoiceNumber = useSelector(
    (state) => state?.preSales?.invoiceID
  ); // invoiceNumber from Store

  // let invoice_data = useSelector((state) => state?.purchasing?.invoice?.data); // invoice_data from Store

  let workflow = useSelector(
    (state) => state?.purchasing?.purchase_order?.workflowPO
  ); // workflowPO from Store

  // invoice columns
  const invoiceDataColumns = [
    {
      name: "Invoice Item Nr",
      selector: (row) => row.invoiceItemNr,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "PO Item Nr",
    //   selector: (row) => row.purchasingDocumentItemNr,
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "GRN Item Nr",
    //   selector: (row) => row.grnItemNr,
    //   sortable: true,
    //   width: "130px",
    // },
    {
      name: "GRN Nr",
      selector: (row) => row.grnNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-info-status">{"New"}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    // {
    //   name: "Purchase Qty",
    //   selector: (row) => row.purchaseQuantity,
    //   cell: (row) => <>{row.purchaseQuantity}</>,
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "GRN Qty",
      selector: (row) => row.grnQuantity,
      cell: (row) => <>{row.grnQuantity}</>,
      sortable: true,
      width: "150px",
    },
    {
      name: "Invoice Qty",
      cell: (row) => <>{row.invoiceQuantity}</>,
      sortable: true,
      width: "160px",
    },
    {
      name: "Invoice Balance Qty",
      cell: (row) => <>{row.invBalancePurchaseQuantity}</>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      // selector: (row) => row.orderPriceUnit,
      cell: (row) => (
        <span className="numberFormate">
          {currencyFormat(Number(row.orderPriceUnit).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      selector: (row) => row.totalPrice,
      cell: (row) => <>{row.totalPrice}</>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Total Value",
      selector: (row) => row.netValue,
      cell: (row) => <>{row.netValue}</>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Code",
      selector: (row) => row.taxCode,
      sortable: true,
      width: "130px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Nr",
      selector: (row) => row.purchaseReqNr,
      sortable: true,
      width: "200px",
    },
    {
      name: "PReq Item Nr ",
      selector: (row) => row.purchaseReqitemNr,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "ExcessTolerance",
    //   selector: (row) => row.excessTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "ShortageTolerance",
    //   selector: (row) => row.shortageTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
  ];

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  // const [isCardVisible4, setCardVisible4] = useState(true);

  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  // const [currentDate] = useState(getCurrentDate("default"));

  const [modalShow, setModalShow] = useState(false);
  const [getAction, setAction] = useState("");

  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");
  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const [formInputs, setFormInputs] = useState({
    transactionActivity: "INV",
    subTransactionActivity: "GRN",
    customerCode: "",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    purchasingDocumentValue: "",
    currency: "",
    currencyCode: "",
    paymentTerms: "",
    totalInvoiceAmount: "",
    sumOfCurrentAmt: "",
    notes: "",
    weighSlipQty: "",
    itemClass: "",
    customerInvoiceNo: "",
    customerInvoiceDate: "",
    customerInvoiceCurrency: "",
    customerBasicValue: "",
    customerFreightValue: "",
    customerTaxValue: "",
    customerOtherChargesValue: "",
    customerTotalInvoiceValue: "",
    invoicedValue: "",
    generatedBy: "",
    createdBy: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  // const toggleCardVisibility4 = () => {
  //   setCardVisible4(!isCardVisible4);
  // };
  // const toggleCardVisibility5 = () => {
  //   setCardVisible5(!isCardVisible4);
  // };
  // const toggleCardVisibility6 = () => {
  //   setCardVisible6(!isCardVisible4);
  // };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  // load loadInitialPageData data on page load
  const loadInitialPageData = async () => {
    setLoading(true);
    if (invoiceNumber) {
      let obj = {
        documentNumber: invoiceNumber,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });

      await ItemService.getCustInvoiceDatails(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item?.data[0]);
          let itemData = item?.data[0]?.items;
          setData(itemData);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitialPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceNumber]);

  // Workflow
  const navigateToWorkflow = () => {
    window.history.back();
  };
  const showApproveModal = async (name) => {
    setModalShow(true);
    setAction(name);
  };
  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {};
    let newObj = { ...bodyObj, ...obj };

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        successModalData.successMsg =
          getAction === "Approve"
            ? "Approved Successfully !!"
            : "Rejected Successfully !!";
        setsucceModalData(successModalData);
        setNextNavigate(Constants.INBOX);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };
  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />

      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Invoice Details : <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {invoiceNumber}
                </span>
              <span>
                {formInputs?.invoiceStatusHeader === "Invoice Created" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : formInputs?.invoiceStatusHeader === "Pending for Approval" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : formInputs?.invoiceStatusHeader === "Invoice Approved" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : (
                  formInputs?.invoiceStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">Invoice Details</p>
            <hr />

            <Row className="mb-4">
              <Col xs={12} md={4} lg={4} className="text-left">
                <button
                  className="btnBack"
                  onClick={() => navigateToWorkflow()}
                >
                  <i className="bi bi-arrow-return-left"></i> Go Back
                </button>
              </Col>
              <Col xs={12} md={8} lg={8} className="text-right">
                {workflow?.workflow_record?.decision?.length > 0 &&
                  workflow?.workflow_record?.decision?.map((item, index) => (
                    <button
                      className={
                        item === "Approve" || item === "Submit"
                          ? "btnCommon btn-approve"
                          : "btnCommon btn-danger"
                      }
                      onClick={() => showApproveModal(item)}
                      key={index}
                    >
                      <i
                        className={
                          item === "Approve" || item === "Submit"
                            ? "bi bi-check2-circle"
                            : "bi bi-x-circle"
                        }
                      ></i>{" "}
                      {item}
                    </button>
                  ))}
              </Col>
            </Row>

            <>
              {/* Invoice header */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">GH</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Invoice Header</span>
                        <span className="subHeaderTitle">Invoice Header</span>
                      </Col>

                      {/* <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col> */}
                    </Row>
                  </h2>
                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice Nr"
                            name="customerInvoiceNo"
                            placeholder=""
                            value={formInputs?.customerInvoiceNo}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice Date"
                            name="customerInvoiceDate"
                            placeholder=""
                            value={formInputs?.customerInvoiceDate}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice Value"
                            name="customerTotalInvoiceValue"
                            placeholder=""
                            value={formInputs?.customerTotalInvoiceValue}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Tax Amount"
                            name="taxAmount"
                            placeholder=""
                            value={formInputs?.taxAmount}
                            disabled={getDisabledStatus}
                          />
                        </Col>                       
                        {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice No "
                          name=""
                          placeholder=""
                          // value={""}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col> */}
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Currency"
                            name="currency"
                            placeholder=""
                            value={formInputs?.taxAmount}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Invoice"
                            name=""
                            type="file"
                            placeholder=""
                            value={formInputs?.taxAmount}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="SO Number"
                            name="salesDocumentNr"
                            placeholder=""
                            value={formInputs?.salesDocumentNr}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="SO Date"
                            name="salesDocumentDate"
                            placeholder=""
                            value={formInputs?.salesDocumentDate}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Supporting Document"
                            name=""
                            type="file"
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Customer Code"
                            name="customerCode"
                            placeholder=""
                            value={formInputs?.customerCode}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Customer Name"
                            name="customerName"
                            placeholder=""
                            value={formInputs?.customerName}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Weigh Slip Qty"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Payment Terms"
                            name="paymentTerms"
                            placeholder=""
                            value={formInputs?.paymentTerms}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Notes "
                            name=""
                            placeholder=""
                            value={formInputs?.notes}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* invoice service itme details table*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">PI</span>
                      </Col>
                      <Col xs={12} md={8} lg={8}>
                        <span className="headerTitle">
                          Invoice Service Item Details
                        </span>
                        <span className="subHeaderTitle">
                          Invoice Service Item Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            columns={invoiceDataColumns}
                            data={data}
                            progressPending={loading}
                            paginationRowsPerPageOptions={
                              Constants.ROW_PER_PAGE
                            }
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* invoice details */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">ID</span>
                      </Col>

                      <Col xs={4} md={8} lg={8}>
                        <span className="headerTitle"> Invoice details</span>
                        <span className="subHeaderTitle">Invoice details</span>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="">
                        <POCollabrationCommonFillter />
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <div className="accordion-body">
                            <Row className="pt-2 pb-4">
                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Invoice Number *"
                                  name="customerInvoiceNo"
                                  id="customerInvoiceNo"
                                  value={formInputs?.customerInvoiceNo}
                                  onChange={onInputChange}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Invoice Date*"
                                  name="customerInvoiceDate"
                                  id="customerInvoiceDate"
                                  type="date"
                                  value={formInputs?.customerInvoiceDate}
                                  onChange={onInputChange}
                                  disabled={getDisabledStatus}
                                />
                              </Col>

                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Basic Value *"
                                  name="customerBasicValue"
                                  placeholder="0.00"
                                  id="customerBasicValue"
                                  value={formInputs?.customerBasicValue}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Tax Amount"
                                  name="customerTaxValue"
                                  placeholder="0.00"
                                  id="customerTaxValue"
                                  value={formInputs?.customerTaxValue}
                                  // onChange={onInputChange}
                                  disabled={getDisabledStatus}
                                />
                              </Col>

                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Other Charges"
                                  name="customerOtherChargesValue"
                                  placeholder="0.00"
                                  id="customerOtherChargesValue"
                                  value={formInputs?.customerOtherChargesValue}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Freight Value"
                                  name="customerFreightValue"
                                  placeholder="0.00"
                                  id="customerFreightValue"
                                  value={formInputs?.customerFreightValue}
                                  onChange={onInputChange}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={3}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Total Invoice Value "
                                  name="customerTotalInvoiceValue "
                                  placeholder="0.00"
                                  id="customerTotalInvoiceValue"
                                  value={formInputs?.customerTotalInvoiceValue}
                                  disabled={getDisabledStatus}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Invoice Bank Details */}
              {/* <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible4
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility4}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">ID</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">
                          Invoice Bank Details
                        </span>
                        <span className="subHeaderTitle">
                          Invoice Bank Details
                        </span>
                      </Col>

                      <Col xs={2} md={1} lg={1} className="">
                        <POCollabrationCommonFillter />
                      </Col>
                    </Row>
                  </h2>
                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible4 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Select Bank A/C Number"
                            name=""
                            placeholder=""
                            value={formInputs?.bankAccountNumber}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Bank Account Holder Name"
                            name=""
                            placeholder=""
                            value={formInputs?.selectedBankDetails?.bankName}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Swift Code/ IFSC Code"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Bank Name"
                            name=""
                            placeholder=""
                            value={formInputs?.bankName}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Branch Address"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Bank Country"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Bank ID"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="IBAN"
                            name=""
                            placeholder=""
                            // value={""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          </div>
        </Col>
      </Row>

      {/* Modal Workflow */}
      <ApproveRemarksModal
        show={modalShow}
        onHide={() => hideModal()}
        onvaluechange={handleValueChange}
      />

      {/* Success Modal Workflow */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />
    </main>
  );
};

export default CustInvoiceDetailsV2;
