import React, { useState, useEffect } from "react";
import Multiselect from "react-widgets/Multiselect";

export const MultiselectField = ({
  index,
  id,
  name,
  code,
  label,
  placeholder,
  error,
  data,
  setValue,
  getvalue = () => {},
  disabled,
  onChangeValue = () => {},
  extraLabel = "",
  required,
  searchTerm,
  defaultValue,
}) => {
  const [selectedData, setSelectedData] = useState(setValue);

  useEffect(() => {
    let obj = {
      selected: selectedData,
      textField: name,
      textCode: code,
      index: index,
    };
    getvalue(obj);
    onChangeValue(index, name, selectedData?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getvalue, selectedData]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
        {label} {required ? "*" : ""}{" "}
        {extraLabel !== "" ? (
          <i className="bi bi-info-circle" title={extraLabel}></i>
        ) : (
          ""
        )}
      </label>
      <Multiselect
        id={id}
        name={name}
        className="dropdownHive w-full rounded-md"
        data={data}
        dataKey="id"
        textField="name"
        value={setValue}
        onChange={(value) => setSelectedData(value)}
        filter="contains"
        placeholder={placeholder}
        disabled={disabled}
        searchTerm={searchTerm}
        defaultValue={defaultValue}
      />
      {error && typeof error.message === "string" && (
        <span className="text-red-500 text-xs">{error.message}</span>
      )}
    </>
  );
};
