import React, { useEffect, useState } from "react";
import { ComboboxField } from "../formElements/ComboboxField";
import { InputField } from "../formElements/InputField";

const EvaluationTable = (props) => {
  const [quotationDocument, setQuotationDocument] = useState([]);
  const [rfxQuestions, setRfxQuestions] = useState([]);
  const [weightageTotal, setWeightageTotal] = useState(0);
  const [scoreTotal, setScoreTotal] = useState();
  const [getDropdownData, setDropdownData] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [getPageStatus, setPageStatus] = useState(false);

  const dropdownOption = [
    {
      id: "weighted",
      name: "Weighted",
    },
    {
      id: "wnweighted",
      name: "Unweighted",
    },
  ];

  // Function to get supplier's response for a given question
  const getSupplierResponse = (questionNo, formTECH_COMM) => {
    const response = formTECH_COMM?.find((item) => item.question_no === questionNo);
    return response ? response.question_value : "--"; // Default to '--' if no response
  };

  useEffect(() => {
    if (
      !isInitialized &&
      props?.data?.length > 0 &&
      props?.data[0]?.rfxQuestions?.length > 0
    ) {
      // console.log(">> TECH props >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", props);
      setQuotationDocument(props?.data[0]?.rfxQuestions[0]?.quotationDocument);
      setRfxQuestions(props?.data[0]?.rfxQuestions);
      setPageStatus(props?.pageStatus);
      setIsInitialized(true);

      getTotalCount(props?.data[0]?.rfxQuestions);
      getScoreTotalCount(props?.data[0]?.rfxQuestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isInitialized]);

  useEffect(() => {
    // console.log(">> Evaluation rfxQuestions", rfxQuestions);
    props?.sendDataToParent(rfxQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxQuestions]);

  // Weightage Total
  const handleWeightageInput = (id, value) => {
    const updatedData = rfxQuestions?.map((rfxItem) => {
      if (rfxItem.serialNo === id) {
        const updatedQuotationDocument = rfxItem.quotationDocument?.map(
          (item) => {
            return {
              ...item,
              score: parseInt(0), // Update the score
            };
          }
        );

        return {
          ...rfxItem,
          quotationDocument: updatedQuotationDocument,
          weightage: parseInt(value),
        };
      } else {
        return {
          ...rfxItem,
        };
      }
    });

    // console.log(">> updatedData", updatedData);
    setRfxQuestions(updatedData);
    getTotalCount(updatedData);
  };

  // Get Total for weightage
  const getTotalCount = (data) => {
    const totalWeightage = data.reduce((total, item) => {
      // Add the weightage if it exists, converting it to a number
      return total + (item?.weightage ? Number(item?.weightage) : 0);
    }, 0);
    setWeightageTotal(totalWeightage);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    // eslint-disable-next-line array-callback-return
    const updatedData = rfxQuestions.map((rfxItem) => {
      if (getDropdownData?.name === "Unweighted") {
        if (rfxItem.serialNo === getDropdownData?.index) {
          const updatedQuotationDocument = rfxItem.quotationDocument?.map(
            (item) => {
              return {
                ...item,
                score: parseInt(0), // Update the score
                weightageStatus: false,
                scoreStatus: false,
              };
            }
          );

          return {
            ...rfxItem,
            [getDropdownData?.textField]: getDropdownData?.name,
            quotationDocument: updatedQuotationDocument,
            weightage: parseInt(0),
            weightageStatus: true,
            scoreStatus: true,
          };
        } else {
          return {
            ...rfxItem,
          };
        }
      } else {
        return rfxItem.serialNo === getDropdownData?.index
          ? {
              ...rfxItem,
              [getDropdownData?.textField]: getDropdownData?.name,
              weightage: parseInt(0),
              weightageStatus: false,
              scoreStatus: false,
            }
          : rfxItem;
      }
    });
    setRfxQuestions(updatedData);
    getScoreTotalCount(updatedData);
    getTotalCount(updatedData);
    // console.log(">> updatedData", updatedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // -------------------------------------------------------------------------
  // Score Total
  const handleScoreInput = (quotationNr, componentId, value, weightage) => {
    if (Number(weightage) >= Number(value)) {
      const updatedData = rfxQuestions?.map((rfxItem) => {
        const updatedQuotationDocument = rfxItem.quotationDocument?.map(
          (item) => {
            // Check if the quotationNr matches, then update the score
            return item.quotationNr === quotationNr
              ? {
                  ...item,
                  score: parseInt(value), // Update the score
                }
              : item; // Return unchanged if quotationNr doesn't match
          }
        );

        // Return the updated rfxItem with the updated quotationDocument
        return rfxItem.componentId === componentId
          ? {
              ...rfxItem,
              quotationDocument: updatedQuotationDocument,
            }
          : rfxItem;
      });

      // console.log(">> updatedData", updatedData);
      setRfxQuestions(updatedData);
      getScoreTotalCount(updatedData);
    } else {
      // console.log("Do Nothing");
    }
  };

  const getScoreTotalCount = (data) => {
    const scoreByQuotationNr = {};

    // Loop through each component in the data array
    data.forEach((item) => {
      // Loop through each quotationDocument inside the component
      item?.quotationDocument.forEach((doc) => {
        // Ensure the score is a valid number before adding
        const score = parseInt(doc?.score) || 0;

        // If the quotationNr already exists in the object, add the score, otherwise, initialize it
        if (scoreByQuotationNr[doc?.quotationNr]) {
          scoreByQuotationNr[doc?.quotationNr] += score;
        } else {
          scoreByQuotationNr[doc?.quotationNr] = score;
        }
      });
    });

    // console.log(">> scoreByQuotationNr", scoreByQuotationNr);
    setScoreTotal(scoreByQuotationNr);
  };

  // Update Comments
  const handleCommentsInput = (quotationNr, componentId, value) => {
    const updatedData = rfxQuestions?.map((rfxItem) => {
      const updatedQuotationDocument = rfxItem.quotationDocument?.map(
        (item) => {
          // Check if the quotationNr matches, then update the score
          return item.quotationNr === quotationNr
            ? {
                ...item,
                comments: value, // Update the score
              }
            : item; // Return unchanged if quotationNr doesn't match
        }
      );

      // Return the updated rfxItem with the updated quotationDocument
      return rfxItem.componentId === componentId
        ? {
            ...rfxItem,
            quotationDocument: updatedQuotationDocument,
          }
        : rfxItem;
    });

    // console.log(">> updatedData", updatedData);
    setRfxQuestions(updatedData);
    getScoreTotalCount(updatedData);
  };

  return (
    <div className={quotationDocument?.length > 4 ? 'generatedTable width-200' : 'generatedTable width-150'}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            {quotationDocument?.map((supplier, index) => (
              <th className="tableHeader text-center" colSpan={3} key={index}>
                <span className="bg-table-status bg-table-new-status px-2">
                  {supplier.organisationName} - {supplier.quotationNr}
                </span>
                <div className="mt-1">Version: {supplier.qtnVersion}</div>
              </th>
            ))}
          </tr>
          <tr>
            <th className="tableHeader _01">SL No</th>
            <th className="tableHeader _02">Questionaries</th>
            <th className="tableHeader _03">Criteria</th>
            <th className="tableHeader _04">Weightage</th>
            {quotationDocument?.map((supplier, index) => (
              <>
                <th className="tableHeader _04">Supplier Response</th>
                <th className="tableHeader _05">Score</th>
                <th className="tableHeader _05">Comments</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* {rfxQuestions?.length} */}
          {rfxQuestions?.map((question, index) => (
            <tr>
              <td className="text-center">{question?.serialNo}</td>
              <td>{question?.question?.label}</td>
              <td>
                {!getPageStatus ? (
                  <ComboboxField
                    index={question?.serialNo}
                    label=""
                    placeholder="Select Criteria"
                    data={dropdownOption}
                    id="criteria"
                    iconClassName="dropdownIcon"
                    name="criteria"
                    code="criteriaTypeCode"
                    description="criteriaTypeDesc"
                    setValue={question?.criteria}
                    getvalue={setDropdownData}
                    className="dropdownOption"
                    disabled={getPageStatus}
                  />
                ) : question?.criteria === "Weighted" ? (
                  <span className="bg-table-status bg-table-progress-status d-flex justify-content-center">
                    {question?.criteria}
                  </span>
                ) : question?.criteria === "Unweighted" ? (
                  <span className="bg-table-status bg-table-danger-status d-flex justify-content-center">
                    {question?.criteria}
                  </span>
                ) : (
                  question?.criteria
                )}
              </td>
              <td>
                <InputField
                  type="number"
                  className="inputBox"
                  label=""
                  name="weightage"
                  placeholder=""
                  value={question?.weightage}
                  onChange={(e) =>
                    handleWeightageInput(question?.serialNo, e.target.value)
                  }
                  disabled={question?.weightageStatus || getPageStatus}
                />
              </td>
              {question?.quotationDocument?.map((supplier, supplierIndex) => (
                <>
                  <td key={supplierIndex}>
                    {getSupplierResponse(
                      question?.componentId,
                      (supplier?.formTECH || supplier?.formCOMM)
                    )}
                  </td>
                  <td>
                    <InputField
                      type="number"
                      className="inputBox"
                      label=""
                      name="score"
                      placeholder=""
                      value={supplier?.score || 0}
                      onChange={(e) =>
                        handleScoreInput(
                          supplier?.quotationNr,
                          question?.componentId,
                          e.target.value,
                          question?.weightage || 0
                        )
                      }
                      disabled={question?.scoreStatus || getPageStatus}
                    />
                  </td>
                  <td>
                    <InputField
                      type="text"
                      className="inputBox"
                      label=""
                      name="comments"
                      placeholder=""
                      value={supplier?.comments}
                      onChange={(e) =>
                        handleCommentsInput(
                          supplier?.quotationNr,
                          question?.componentId,
                          e.target.value
                        )
                      }
                      disabled={getPageStatus}
                    />
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className="tableHeader"></th>
            <th className="tableHeader">Total</th>
            <th className="tableHeader"></th>
            <th className="tableHeader">{weightageTotal}</th>
            {quotationDocument?.map((supplier, index) => (
              <>
                <th className="tableHeader"></th>
                <th className="tableHeader">
                  {scoreTotal?.[supplier?.quotationNr] || 0}
                </th>
                <th className="tableHeader"></th>
              </>
            ))}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default EvaluationTable;
