/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { InputField } from "../../../../Components/formElements/InputField";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import toast from "react-hot-toast";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { getTodayDate } from "../../../../common/Validations";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import TableDropdownMenu from "../../../../Components/TableDropdownMenu/TableDropdownMenu";

const OrderSchedulingModal = React.forwardRef((props, ref) => {
  let userData = useSelector((state) => state?.user?.data);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  let royalityNameData = [
    {
      id: 1,
      name: "Early Morning",
    },
    {
      id: 2,
      name: "Day",
    },
    {
      id: 3,
      name: "Night",
    },
  ];

  let billingTypeData = [
    {
      id: 1,
      name: "Cash",
    },
    {
      id: 2,
      name: "Online",
    }
  ];
  let getPurchaseOrder = [
    {
      id: 1,
      name: "PO123",
    },
    {
      id: 2,
      name: "PO456",
    }
  ];

  let productDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "60px",
    },
    {
      name: "Product",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "330px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "Loads",
      selector: (row) => row.orderQuantityLoad || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderQuantityLoad"
          placeholder=""
          value={row.orderQuantityLoad || ""}
          // disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Dispatch Remarks",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="dispatchRemarks"
          placeholder=""
          value={row.dispatchRemarks || ""}
          // disabled={true}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Royality Name",
    //   cell: (row) => (
    //     <TableDropdownMenu
    //       index={row.local_id}
    //       label={"Royality Name"}
    //       data={royalityNameData}
    //       id={"royalityName"}
    //       name={"royalityName"}
    //       code={"royalityNameCode"}
    //       description={"royalityNameDescription"}
    //       onChange={handleDropdownChange}
    //       // required={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Billing Type",
    //   cell: (row) => (
    //     <TableDropdownMenu
    //       index={row.local_id}
    //       label={"Billing Type"}
    //       data={billingTypeData}
    //       id={"billingType"}
    //       name={"billingType"}
    //       code={"billingTypeCode"}
    //       description={"billingTypeDescription"}
    //       onChange={handleDropdownChange}
    //       // required={true}
    //     />
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Dispatch Date",
      selector: (row) => row.dispatchDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="dispatchDate"
          type="date"
          placeholder=""
          value={row.dispatchDate || getTodayDate("")}
          disabled={false}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button className="" onClick={() => removeProductDetails(row.local_id)}>
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];

  let productDetailsData = [
    {
      local_id: 1,
      materialCode: "FG005",
      materialDescription: "M SAND - Manufactured Sand (U) 0-4.76mm",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
    {
      local_id: 2,
      materialCode: "FG002",
      materialDescription: "20 MM - Aggregates 20mm",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
    {
      local_id: 3,
      materialCode: "FG001",
      materialDescription: "12 MM - Aggregates 12mm",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
    {
      local_id: 4,
      materialCode: "FG008",
      materialDescription: "P SAND - Manufactured Sand",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
    {
      local_id: 5,
      materialCode: "FG007",
      materialDescription: "GSB - Granulates Sub Base",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
    {
      local_id: 6,
      materialCode: "FG006",
      materialDescription: "WMM - Wet Mix Mecodem",
      orderQuantityLoad: "",
      // orderQuantityMts: "",
      dispatchDate: getTodayDate(""),
    },
  ];

  // console.log(">> getTodayDate()", getTodayDate());

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const [getDropdownData, setDropdownData] = useState("");
  const [getProductDetailsData, setProductDetailsData] =
    useState(productDetailsData);
  const [getProductDetailsCol] = useState(productDetailsCol);

  const [disable, setDisabled] = useState(false);
  const [getCustomer, setCustomer] = useState([]);
  const [getProjects, setProjects] = useState([]);
  const [getProjectDetails, setProjectDetails] = useState([]);
  const [getProjectDetailsSave, setProjectDetailsSave] = useState([]);
  const [customerID, setCustomerID] = useState("");

  const [formInputs, setFormInputs] = useState({
    orderStatus: "Pending",
    projectName: "",
    customerName: "",
    dispatchZone: "",
    salesDocumentNr: "",
    createdDate: new Date(),
    createdBy: userData?.userId,
    dispatchDate: getTodayDate(""),
  });

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  useEffect(() => {
    getMasterData();

    setFormInputs({
      orderStatus: "Pending",
      projectName: "",
      customerName: "",
      dispatchZone: "",
      createdDate: new Date(),
      createdBy: userData?.userId,
    });
    setProductDetailsData(productDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    getMasterData();

    setFormInputs({
      orderStatus: "Pending",
      projectName: "",
      customerName: "",
      dispatchZone: "",
      createdDate: new Date(),
      createdBy: userData?.userId,
    });
    setProductDetailsData(productDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);

    if (getDropdownData?.textCode === "customerCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapDocumentNumber: getDropdownData?.sapDocumentNumber,
      }));
      getProjectNameData(getDropdownData?.id);
      setCustomerID(getDropdownData?.id);
      setFormInputs((formInputs) => ({ ...formInputs, projectName: "" }));
      setProjectDetails([]);
      setProjectDetailsSave([]);
    } else if (getDropdownData?.textCode === "projectNameCode") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.id,
        [getDropdownData?.textDesc]: getDropdownData?.description,
        sapProjectCode: getDropdownData?.sapProjectCode,
      }));
      getProjectDetailsByCode(getDropdownData?.id);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const removeProductDetails = (local_id) => {
    setProductDetailsData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };

  const handleInputChange = (local_id, event) => {
    const { name, value } = event.target;
    setProductDetailsData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === local_id ? { ...row, [name]: value } : row
      )
    );
  };
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };
  /* handle save */
  const handleSave = async () => {
    setDisabled(true);
    const finalobj = getProductDetailsData.map((item) => ({
      ...item,
      ...formInputs,
      getProjectDetails: getProjectDetailsSave,
    }));

    const filteredData = finalobj.filter(
      (item) => item.orderQuantityLoad.trim() !== ""
    );
    const obj = {
      data: filteredData,
    };
    // console.log(">> filteredData", filteredData);

    await ItemService.addOrderScheduleBooking(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onHide();
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {};
    await ItemService.getCustomers(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setCustomer(item?.data);
      } else {
        setCustomer([]);
        console.log(">> Error: Loading getCustomers");
      }
    });
  };

  // Onselect of Country state will load
  const getProjectNameData = async (userId) => {
    let obj1 = {
      userId: userId,
    };
    await ItemService.getProjectName(obj1).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        const updatedData = item?.data?.map((item) => ({
          ...item,
          name: `${item.id} - ${item.projectAddress}`, // Combine id and name
        }));

        setProjects(updatedData || []);
      } else {
        setProjects([]);
        console.log(">> Error: Loading getProjectName");
      }
    });
  };

  const getProjectDetailsByCode = async (code) => {
    let obj = {
      userId: customerID,
      projectCode: code,
    };
    await ItemService.getProjectDetail(obj).then((item) => {
      // console.log(">> getProjectDetail!", item);
      if (item?.status === 200) {
        if (item?.data.length > 0) {
          setProjectDetails(
            item?.data[0]?.getLocations ? item?.data[0]?.getLocations[0] : []
          );
          setProjectDetailsSave(item?.data[0]);
        } else {
          setProjectDetails([]);
        }
      } else {
        setProjectDetails([]);
        console.log(">> Error: Loading getProjectDetail");
      }
    });
  };

  const handleDropdownChange = (data) => {
    // console.log(">> data", data);
    setProductDetailsData((prevRows) =>
      prevRows.map((row) =>
        row.local_id === data.index ? { ...row, [data.name]: data.value } : row
      )
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Order Booking
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col xs={12} md={2} lg={2} className="headerButtonAlign">
            <button className="btnInfo" onClick={handleSave} disabled={disable}>
              Save
            </button>
          </Col>
        </Row>
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CD</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <span className="headerTitle">Customer Details</span>
                  <span className="subHeaderTitle">Customer Details</span>
                </Col>
              </Row>
            </h2>

            <div
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Customer Name"
                      placeholder=""
                      data={getCustomer}
                      id="customerName"
                      iconClassName="dropdownIcon"
                      name="customerName"
                      code="customerCode"
                      description="customerNameDesc"
                      setValue={formInputs.customerName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Project Name"
                      placeholder=""
                      data={getProjects}
                      id="projectName"
                      iconClassName="dropdownIcon"
                      name="projectName"
                      code="projectNameCode"
                      description="projectNameDesc"
                      setValue={formInputs.projectName || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <InputField
                      className="inputBox"
                      label="Project Location"
                      id="location"
                      name="location"
                      type="text"
                      value={getProjectDetails?.projectLocation || "" || ""}
                      onChange={onInputChange}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <TextAreaField
                      className="inputBox"
                      label="Project Address"
                      name="projectAddress"
                      value={
                        getProjectDetailsSave?.projectAddress +
                          getProjectDetailsSave?.projectAddress2 || ""
                      }
                      // onChange={onInputChange}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
                    <ComboboxField
                      label="Purchase Order"
                      placeholder=""
                      data={getPurchaseOrder}
                      id="purchaseOrder"
                      iconClassName="dropdownIcon"
                      name="purchaseOrder"
                      code="purchaseOrderCode"
                      description="purchaseOrderDesc"
                      setValue={formInputs.purchaseOrder || ""}
                      getvalue={setDropdownData}
                      required={true}
                      // error={validation.country}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible2 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility2}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CP</span>
                </Col>
                <Col xs={12} md={9} lg={9}>
                  <span className="headerTitle">Product Details</span>
                  <span className="subHeaderTitle">Product Details</span>
                </Col>
                {/* <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                  <button className="btnTable" onClick={addProductDetails}>
                    <i className="bi bi-plus-lg"></i> Add
                  </button>
                </Col> */}
              </Row>
            </h2>
            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible2 ? "visible" : ""
              }`}
              // aria-labelledby="headingOne"
              // data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <DataTable
                      title=""
                      columns={getProductDetailsCol}
                      data={getProductDetailsData}
                      // progressPending={loading}
                      // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalRows}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // selectableRows
                      // onSelectedRowsChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default OrderSchedulingModal;
