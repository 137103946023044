import config from './configuration'
import ConstantsList from '../common/SupplierConstants';
import toast from 'react-hot-toast';

// this block needed to get the login Token and Login user _id 
let localData = localStorage.getItem('userData');
let userData={}
if(localData){
    userData = JSON.parse(localData);
}

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/* Service Function starts here */

// API Error Handling
async function handleResponseError(response) {
    const errorMessage = `HTTP error, status = ${response.status}`;
    toast.error(errorMessage); // Display error toast
    // throw new Error(errorMessage);
}

// API Error Handling
async function handleError(error) {
    console.log(">> handleError", error);
    // toast.error(error.message || "An unknown error occurred");
}

// Common GET Method 
async function callGET(api_url) {
    return fetch(api_url,{
                method:'GET',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                }
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                if(items.responseCode !== 401){
                    // console.log(">> GET Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }            
            }).catch(error => {
                console.log(">> e", error);
                handleError(error);
            });
}

// Common POST Method
async function callPOST(api_url,param) {
    // console.log(">> check URL", api_url);
    if(!param){
        param = {}
    }
    param.timeZone = userTimezone;
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                },
                body:JSON.stringify(param)
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

// Common POST Method for Upload
async function callPOSTUpload(api_url,param) {
    // console.log(">>callPOSTUpload param ", param);
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    // 'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer '+ userData.token,
                },
                body:param
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

//POST - USER PROFILE IMAGE UPLOAD 
async function uploadImg(param) {
    let api_url= config.BASE_URL + 'api/uploadImg';
    return callPOSTUpload(api_url,param);
}

//POST - userUpdate Commmon
async function userUpdate(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/userUpdate";
    return callPOST(api_url, param);
}

// ---------------------------------- Customer_V2 ----------------------------------------------- 

//Customer
async function addCustomerV2(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer-v2/api/addCustomer";
    return callPOST(api_url,param);
}

async function addOrderScheduleBookingV2(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/addOrderScheduleBooking";
    return callPOST(api_url,param);
}

async function updateLoading(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/updateLoading";
    return callPOST(api_url,param);
}

async function getOrderBoogkingDetail(param) {
  let api_url = config.BASE_URL + "customer-v2/api/getOrderBoogkingDetail";
  return callPOST(api_url, param);
}

async function getCustInvoiceDatails(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/getCustInvoiceDatails";
    return callPOST(api_url,param);
}   

//cash request
async function getCustomers(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/getCustomers";
    return callPOST(api_url,param);
}   

async function addCashRequest(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/addCashRequest";
    return callPOST(api_url,param);
}
async function addInvoice(param) { 
    let api_url= config.BASE_URL + "customer-v2/api/addInvoice";
    return callPOST(api_url,param);
}

const func = {
    userUpdate,
    uploadImg,
    handleResponseError,
    handleError,
    callGET,
    callPOST,
    callPOSTUpload,    

    //customer_v2
    addCustomerV2,
    // updateSOV2,
    addOrderScheduleBookingV2,
    updateLoading,

    getOrderBoogkingDetail,


    getCustInvoiceDatails,
    
    //cashrequest
    addCashRequest,
    
    getCustomers,

    addInvoice,
};

export default func;