import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import { formatDateTime } from "../../../../common/Validations";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useSelector
import CustomerConstants from "../../../../common/CustomerConstants";
import {
  addInquiryID,
  getInquiryList,
} from "../../../../redux/feature/customer_app/preSalesSlice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

const InquiryList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Pre Sales");
  localStorage.setItem("submenu", "Inquiry");

  let preSalesData = useSelector((state) => state?.preSales?.inquiry); // User Details from Store
  let preSalesTable = useSelector(
    (state) => state?.preSales?.inquiry?.pagination
  ); // User Details from Store
  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const detailedPage = (inquiryID) => {
    dispatch(addInquiryID(inquiryID));
    navigate(CustomerConstants.PRESALES_INQUIRY_DETAILS);
  };

  const createInquiry = () => {
    navigate(CustomerConstants.PRESALES_INQUIRY_CREATION);
  };

  const columns = [
    {
      name: "Inquiry Nr",
      selector: (row) => row.documentNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.documentNumber)}
        >
          {row.documentNumber || "-"}{" "}
        </button>
      ),
      width: "140px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Created Date",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "160px",
    },
    {
      name: "Customer Nr",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.customerCode}</span>
        </>
      ),
      selector: (row) => row.customerCode || "--",
      sortable: true,
      width: "140px",
    },
    {
      name: "Customer Name",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.customerName}</span>
        </>
      ),
      selector: (row) => row.customerName,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Workflow log",
    //   selector: (row) => row.workflowActionLogs,
    //   cell: (row) => (
    //     <div
    //       style={{ width: "100%" }}
    //       className="d-flex justify-content-center iconTable"
    //     >
    //       <img
    //         src="assets/img/newIcons/workflow.png"
    //         alt="attachments"
    //         style={{ width: "35px" }}
    //         className="mt-1"
    //         onClick={() => showStatusInfo(row.workflowActionLogs)}
    //       />
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "132px",
    // },
    // {
    //   name: "Sold To Party",
    //   cell: (row) => (
    //     <>
    //       <span>{row.soldToParty || "--"}</span>
    //     </>
    //   ),
    //   selector: (row) => row.soldToParty,
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Ship To Party",
    //   cell: (row) => (
    //     <>
    //       <span>{row.shipToParty || "--"}</span>
    //     </>
    //   ),
    //   selector: (row) => row.shipToParty,
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Status",
      selector: (row) => row.inqHeaderstatus,
      sortable: true,
      cell: (row) => (
        <>
          {row.inqHeaderstatus === "Pending" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.inqHeaderstatus}
            </span>
          ) : row.inqHeaderstatus === "New" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.inqHeaderstatus}
            </span>
          ) : row.inqHeaderstatus === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.inqHeaderstatus}
            </span>
          ) : row.inqHeaderstatus === "Acknowledged" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.inqHeaderstatus}
            </span>
          ) : row.inqHeaderstatus === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.inqHeaderstatus}
            </span>
          ) : row.inqHeaderstatus === "Deactivated" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.inqHeaderstatus}
            </span>
          ) : (
            row.inqHeaderstatus
          )}
        </>
      ),
      width: "170px",
    },
    {
      name: "Req Delivery Date",
      selector: (row) => formatDateTime(row.reqestDeliveryDate) || "--",
      sortable: true,
      width: "180px",
    },

    {
      name: "PaymentTerms",
      cell: (row) => (
        <>
          <span> {row.paymentTerms} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Sales Org",
      cell: (row) => (
        <>
          <span> {row.salesOrg} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Reason",
      selector: (row) => row.inquiryRemark,
      sortable: true,
      width: "130px",
    },
    {
      name: "Initiator",
      selector: (row) => row.initiator || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "SAP Inquiry Nr",
      selector: (row) => row.sapInquiryNr || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP Remark",
      selector: (row) => row.rfqTechTargetDate,
      sortable: true,
      width: "140px",
    },
  ];

  const loadInitialSuuplierData = async () => {
    setData(preSalesData?.data);
  };

  const lineItemColInfo = [
    {
      name: "Item Nr",
      cell: (row) => <span>{row.inquiryDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialGroup} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.inquiryQuantity} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "220px",
    },
  ];

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getCustomerInquiryList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getInquiryList(obj));
    setData(preSalesData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCustomerInquiryList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getInquiryList(obj));
    setData(preSalesData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCustomerInquiryList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getInquiryList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSalesData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCustomerInquiryList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getInquiryList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Inquiry List</h2>
                <p className="subText">Inquiry Details</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CL</span>
                    </Col>
                    <Col xs={1} md={8} lg={8}>
                      <span className="headerTitle">Inquiry List</span>
                      <span className="subHeaderTitle">
                        View of Customers and Status{" "}
                      </span>
                    </Col>
                    {userData?.category === "CUSTOMER" && (
                      <Col xs={1} md={2} lg={2} className="">
                        <button className="btnTable" onClick={createInquiry}>
                          <i className="bi bi-cart-check"></i> Create Inquiry
                        </button>
                      </Col>
                    )}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={preSalesData?.status}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={preSalesTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      /> */}
    </main>
  );
};

export default InquiryList;
