import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import { currencyFormat, formatDateTime } from "../../../../common/Validations";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useSelector
import CustomerConstants from "../../../../common/CustomerConstants";
import { addQuotationID, getQuotationList } from "../../../../redux/feature/customer_app/preSalesSlice";
import { SearchField } from "../../../../Components/formElements/SearchField";

const QuotationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Pre Sales");
  localStorage.setItem("submenu", "Quotation");

  let preSalesData = useSelector((state) => state?.preSales?.quotation); // User Details from Store
  let preSalesTable = useSelector((state) => state?.preSales?.quotation?.pagination); // User Details from Store

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const detailedPage = (quotationID) => {
    dispatch(addQuotationID(quotationID));
    navigate(CustomerConstants.PRESALES_CUSTOMER_QUOTATION_DETAILS);
  };

 
  const columns = [
    {
      name: "Quotation Nr",
      selector: (row) => row.quotationNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.quotationNr, row.qtnVersion)}
        >
          {row.quotationNr || "-"}{" "}
        </button>
      ),
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            // onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },

    {
      name: "Version",
      selector: (row) => row.qtnVersion,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      
        cell: (row) => (
          <>
            {row.quotationStatus === "Published" ? (
              <span className="bg-table-status bg-table-success-status">
                {row.quotationStatus}
              </span>
            ) : row.quotationStatus === "Cancelled" ? (
              <span className="bg-table-status bg-table-danger-status">
                {row.quotationStatus}
              </span>
            ) : row.quotationStatus === "Submitted" ? (
              <span className="bg-table-status bg-table-new-status">
                {row.quotationStatus}
              </span>
            ) : row.quotationStatus === "Acknowledged" ? (
              <span className="bg-table-status bg-table-success-status">
                {row.quotationStatus}
              </span>
            ) : (
              row.quotationStatus
            )}
          </>
        ),
        
      width: "130px",
    },
    {
      name: "Qtn Value ",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.qtnTotal).toFixed(2))}
        </span>
      ),
      selector: (row) => row.qtnTotal || "--",
      sortable: true,     
      width: "130px",
    },
    {
      name: "Currency",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold"> {row.qtnBidderCurrency} </span>
        </>
      ),
      selector: (row) => row.qtnBidderCurrency,
      sortable: true,
      width: "160px",
    },
    {
      name: "Date",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "190px",
    }, 
    {
      name: "Inquiry Nr",
      selector: (row) => row.inquiryNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.inquiryNr)}
        >
          {row.inquiryNr || "-"}{" "}
        </button>
      ),
      width: "140px",
    },
    {
      name: "Inquiry Date",
      selector: (row) => formatDateTime(row.inquiryDate),
      sortable: true,
      width: "190px",
    }, 
    {
      name: "Customer Nr",
      selector: (row) => row.customerNr || "--",
      sortable: true,
      width: "140px",
    },  
    {
      name: "Customer Name",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.customerName}</span>
        </>
      ),
      selector: (row) => row.customerName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Submitted By",
      selector: (row) => row.quotationSubmittedBy,
      sortable: true,
      width: "150px",
    },
    {
      name: "Submitted ID",
      selector: (row) => row.qtnSubmittedID,
      sortable: true,
      width: "150px",
    },  
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyAddressDesc} </span>
        </>
      ),
      selector: (row) => row.companyAddressDesc,
      sortable: true,
      width: "130px",
    },
    {
      name: "Plant",
      cell: (row) => (
        <>
          <span> {row.plantDesc} </span>
        </>
      ),
      selector: (row) => row.plantDesc,
      sortable: true,
      width: "140px",
    },
  ];
  const loadInitialSuuplierData = async () => {
    setData(preSalesData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getCustomerQuotationList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getQuotationList(obj));
    setData(preSalesData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCustomerQuotationList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getQuotationList(obj));
    setData(preSalesData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCustomerQuotationList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getQuotationList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSalesData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCustomerQuotationList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getQuotationList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Quotation List</h2>
                <p className="subText">Quotation Details</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Quotation List</span>
                      <span className="subHeaderTitle">
                        View of Customers and Status{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={preSalesData?.status}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={preSalesTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default QuotationList;
