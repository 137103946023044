import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector, useDispatch } from "react-redux";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import HeaderText from "../../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import {
  getCurrentDate,
  isValidNumber,
  validateRequired,
} from "../../../../common/Validations";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import SericesSchedulePO from "../../../../Components/Modal/supplier_app/PO_Collabration/SericesSchedulePO";
import Loader from "../../../../Components/Loader";
import { getPurchaseRequisition } from "../../../../redux/feature/supplier_app/sourcingSlice";

const CreatePR = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Purchase Requisition");

  let purchaseDocType = useSelector(
    (state) => state?.sourcing.purchaseDocType
  );
  let purchaseDocCode = useSelector(
    (state) => state?.sourcing.purchaseDocCode
  );
  let userData = useSelector((state) => state?.user); // User Details from Store


  const [currentDate] = useState(getCurrentDate("default"));
  // JSON Object
  const [formInputs, setFormInputs] = useState({
    manualIndicator: true,
    manualRelease: true,
    initiatorName: userData?.data?.firstName,
    initiator: userData?.data?.userId,
    purchasingReqDocumentType: purchaseDocType,
    purchasingReqDocumentCode: purchaseDocCode,
    version: "V0",
    prStatusHeader: "In Release",

    vendorCode: "",
    vendorName: "",
    procurementCategory: "",
    purchasingReqDocumentDate: currentDate,
    purchasingOrg: "",
    purchasingGroup: "",
    companyCode: "",
    currency: "",
    currencyCode: "",
    paymentTerms: "",
    incoTerms: "",
    incoTermsLocation: "",
    purchasingReqDocumentValue: "",
    purchasingReqRemark: "",

    headerTextData: [
      {
        itemIndex: "",
        documentId: "",
        documentText: "",
      },
    ],

    items: [
      {
        purchasingReqDocumentItemNr: "",
        itemTextData: [
          {
            itemIndex: "",
            documentId: "",
            documentText: "",
          },
        ],
        materialCode: "",
        prStatusItem:"In Release",
        materialDescription: "",
        materialGroup: "",
        purchaseQuantity: "",
        orderUnitOfMeasure: "",
        orderPriceUnit: "",
        netValue: "",
        deliveryDate: "",
        itemCurrency: "",
        plant: "",
        storageLocn: "",
        taxCode: 0,
        taxAmount: 0,
      },
    ],
  });

  const modalData = {
    title: "PO Creation",
    successMsg: "",
  };

  const [getCurrencyDropdown, setCurrencyDropdown] = useState([]);
  const [getLoader, setLoader] = useState(true);

  // Inital Create PO
  let POLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showLineItemHeaderText(row.local_id, row.itemTextData)
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialCode"
          placeholder=""
          value={row.materialCode || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      cell: (row) => (
        <TextAreaField
          className="inputBox mt-1"
          name="materialDescription"
          placeholder=""
          value={row.materialDescription || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialGroup"
          placeholder=""
          value={row.materialGroup || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderUnitOfMeasure"
          placeholder=""
          value={row.orderUnitOfMeasure || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Unit Price ",
      selector: (row) => row.orderPriceUnit || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="netValue"
          placeholder=""
          value={row.netValue || 0}
          disabled={true}
        />
      ),
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxAmount"
          placeholder=""
          value={row.taxAmount || 0}
          disabled={true}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxCode"
          placeholder=""
          value={row.taxCode}
          onChange={(event) =>
            handleInputChange(row.local_id, event, "Number", "VAT")
          }
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="plant"
          placeholder=""
          value={row.plant || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="storageLocn"
          placeholder=""
          value={row.storageLocn || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => removePOItemRow(row.local_id)}
        >
          <i className="bi bi-trash3"></i>
        </button>
      ),
      width: "120px",
    },
  ];

  let POLineItemInitialData = [
    {
      local_id: 1,
      purchasingReqDocumentItemNr: 1,
      itemTextData: [],
      prStatusItem: "In Release",
      currency: getCurrencyDropdown.name || "Rupee",
      currencyCode: getCurrencyDropdown.code || "INR",
      taxCode: 0,
      taxAmount: 0,
    },
  ];

  // Inital Create Service PO
  let ServicePOLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showLineItemHeaderText(row.local_id, row.itemTextData)
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialCode"
          placeholder=""
          value={row.materialCode || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Service",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showServicesLineItem(row.local_id, row.services, "SER")
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.services?.length || 0} </span>
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      cell: (row) => (
        <TextAreaField
          className="inputBox mt-1"
          name="materialDescription"
          placeholder=""
          value={row.materialDescription || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialGroup"
          placeholder=""
          value={row.materialGroup || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderUnitOfMeasure"
          placeholder=""
          value={row.orderUnitOfMeasure || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="netValue"
          placeholder=""
          value={row.netValue || 0}
          disabled={true}
        />
      ),
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxAmount"
          placeholder=""
          value={row.taxAmount || 0}
          disabled={true}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxCode"
          placeholder=""
          value={row.taxCode}
          onChange={(event) =>
            handleInputChange(row.local_id, event, "Number", "VAT")
          }
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="plant"
          placeholder=""
          value={row.plant || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="storageLocn"
          placeholder=""
          value={row.storageLocn || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => removePOItemRow(row.local_id)}
        >
          <i className="bi bi-trash3"></i>
        </button>
      ),
      width: "120px",
    },
  ];

  let ServicePOLineItemInitialData = [
    {
      local_id: 1,
      purchasingReqDocumentItemNr: 1,
      itemTextData: [],
      prStatusItem: "In Release",
      curency: getCurrencyDropdown.name || "Rupee",
      currencyCode: getCurrencyDropdown.code || "INR",
      taxCode: 0,
      taxAmount: 0,
    },
  ];

  // Inital Create Schedule PO
  let SchedulePOLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showLineItemHeaderText(row.local_id, row.itemTextData)
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialCode"
          placeholder=""
          value={row.materialCode || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Schedule",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showServicesLineItem(row.local_id, row.schedule, "SE")
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.schedule?.length || 0} </span>
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      cell: (row) => (
        <TextAreaField
          className="inputBox mt-1"
          name="materialDescription"
          placeholder=""
          value={row.materialDescription || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialGroup"
          placeholder=""
          value={row.materialGroup || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderUnitOfMeasure"
          placeholder=""
          value={row.orderUnitOfMeasure || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Unit Price ",
      selector: (row) => row.orderPriceUnit || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="netValue"
          placeholder=""
          value={row.netValue || 0}
          disabled={true}
        />
      ),
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxAmount"
          placeholder=""
          value={row.taxAmount || 0}
          disabled={true}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxCode"
          placeholder=""
          value={row.taxCode}
          onChange={(event) =>
            handleInputChange(row.local_id, event, "Number", "VAT")
          }
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="plant"
          placeholder=""
          value={row.plant || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="storageLocn"
          placeholder=""
          value={row.storageLocn || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => removePOItemRow(row.local_id)}
        >
          <i className="bi bi-trash3"></i>
        </button>
      ),
      width: "120px",
    },
  ];

  let SchedulePOLineItemInitialData = [
    {
      local_id: 1,
      purchasingReqDocumentItemNr: 1,
      itemTextData: [],
      statusItem: "In Release",
      currency: getCurrencyDropdown.name || "Rupee",
      currencyCode: getCurrencyDropdown.code || "INR",
      taxCode: 0,
      taxAmount: 0,
    },
  ];

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(false);
  const [getDropdownData, setDropdownData] = useState("");

  // const [loading, setLoading] = useState(false);
  const [getLineItemHeaderTextModal, setLineItemHeaderTextModal] =
    useState(false);
  const [getHeaderTextIndex, setHeaderTextIndex] = useState(0);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);
  const [getDataToHeaderText, setDataToHeaderText] = useState([]);
  const [subTotalValue, setSubTotalValue] = useState(0);
  const [getTaxAmountValue, setTaxAmountValue] = useState(0);
  const [getItemAmountValue, setItemAmountValue] = useState(0);
  const [getPurchaseType, setPurchaseType] = useState(0);
  const [getPurchaseCategory, setPurchaseCategory] = useState(0);
  const [getpurchaseGroup, setpurchaseGroup] = useState(0);

  //Services
  const [getServicesLineItemModal, setServicesLineItemModal] = useState(false);
  const [getServicesLineItemIndex, setServicesLineItemIndex] = useState(0);
  const [getServicesLineItemText, setServicesLineItemText] = useState([]);
  const [getServiceTitle, setServiceTitle] = useState("");

  //Schedule
  // const [getScheduleLineItemModal, setScheduleLineItemModal] = useState(false);
  // const [getScheduleLineItemIndex, setScheduleLineItemIndex] = useState(0);
  // const [getScheduleLineItemText, setScheduleLineItemText] = useState([]);

  // Header Text
  const handleHeaderTextLineItemData = (data) => {
    setPOLineItemsData((prevUsers) =>
      prevUsers.map((item) =>
        item.purchasingReqDocumentItemNr === getHeaderTextIndex
          ? { ...item, itemTextData: data }
          : item
      )
    );
  };

  //Service PO and Schedule PO
  const handleServicePOLineItemData = (data) => {
    if (formInputs.purchaseTypeCode === "SER") {
      let totalServiceQty = 0;
      let totalGrossValue = 0;
      let orderUnitOfMeasure = 0;

      data.map((item) => {
        totalServiceQty += Number(item.serviceQuantity);
        totalGrossValue += Number(item.grossValue);
        orderUnitOfMeasure = item.orderUnitOfMeasure;
        return "";
      });

      setPOLineItemsData((prevUsers) =>
        prevUsers.map((item, index) => {
          return item.purchasingReqDocumentItemNr === getServicesLineItemIndex
            ? {
                ...item,
                services: data,
                purchaseQuantity: totalServiceQty,
                orderPriceUnit: totalGrossValue,
                orderUnitOfMeasure: orderUnitOfMeasure,
              }
            : item;
        })
      );
    } else {
      let totalScheduleQty = 0;

      data.map((item) => {
        totalScheduleQty += Number(item.scheduleQuantity);
        return "";
      });

      setPOLineItemsData((prevUsers) =>
        prevUsers.map((item) => {
          return item.purchasingReqDocumentItemNr === getServicesLineItemIndex
            ? {
                ...item,
                schedule: data,
                purchaseQuantity: totalScheduleQty,
              }
            : item;
        })
      );
    }
  };

  /* Header text selected data */
  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);
  const handleHeaderTextSelectedData = (data) => {
    setHeaderTextSelectedData(data);
  };

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  const [disabled, setDisabled] = useState(false);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getPOLineItemsData, setPOLineItemsData] = useState(
    POLineItemInitialData
  );
  // eslint-disable-next-line no-unused-vars
  const [getPOLineItemsCol, setPOLineItemsCol] = useState(POLineItemInitialCol);


  const [getPurchaseOrg, setPurchaseOrg] = useState([]);
  const [getCompanyCode, setCompanyCode] = useState([]);
  const [getINCOTerms, setINCOTerms] = useState([]);
  const [getcurrency, setcurrency] = useState([]);
  const [getpaymentTerm, setpaymentTerm] = useState([]);
  // const [getLdperdaypercentage, setLdperdaypercentage] = useState([]);
  // const [getLdApplicability, setLdApplicability] = useState([]);
  // const [getdownPaymentCategory, setdownPaymentCategory] = useState([]);
  // const [getmodeOfTransport, setmodeOfTransport] = useState([]);
  // const [getretention, setretention] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [getFailureModal, setFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };

  /* Create PO Line Item Header Text Modal Function */
  const showLineItemHeaderText = (index, data) => {
    setLineItemHeaderTextModal(true);
    setHeaderTextIndex(index);
    setDataToHeaderText(data);
  };
  const hideLineItemHeaderText = () => {
    setLineItemHeaderTextModal(false);
  };

  /* Create Header Text Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
    setDataToHeaderText();
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Text",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
    // {
    //   name: "Delete",
    //   cell: (row, index) => (
    //     <>
    //       <span>
    //         <i
    //           onClick={() => handleDelete(index)}
    //           className="bi bi-trash text-danger fs-3"
    //         ></i>
    //       </span>
    //     </>
    //   ),
    //   width: "10rem",
    // },
  ];

  /* Create Services PO Line Item Modal Function */
  const showServicesLineItem = (index, data, type) => {
    setServicesLineItemModal(true);
    setServicesLineItemIndex(index);
    setServicesLineItemText(data);
    setServiceTitle(type === "SER" ? "Services List" : "Schedule List");
  };
  const hideServicesLineItem = () => {
    setServicesLineItemModal(false);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> Dropdown data > ", getDropdownData);

    if (getDropdownData?.code === "SA") {
      // Schedule Agreement
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
      }));
      setPOLineItemsCol(SchedulePOLineItemInitialCol);
      setPOLineItemsData(SchedulePOLineItemInitialData);
    } else if (getDropdownData?.code === "SER") {
      // Service
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
      }));
      setPOLineItemsCol(ServicePOLineItemInitialCol);
      setPOLineItemsData(ServicePOLineItemInitialData);
    } else if (getDropdownData?.code === "MAT") {
      // Material
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
      }));
      setPOLineItemsCol(POLineItemInitialCol);
      setPOLineItemsData(POLineItemInitialData);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const handleInputChange = (local_id, event, type, vat) => {
    const { name, value } = event.target;
    if (type === "Number" && vat === undefined) {
      if (isValidNumber(value)) {
        setPOLineItemsData((prevRows) =>
          prevRows.map((row) => {
            return row.local_id === local_id
              ? {
                  ...row,
                  [name]: value,
                  netValue:
                    name === "purchaseQuantity"
                      ? row.orderPriceUnit * value
                      : row.purchaseQuantity * value,
                  taxAmount:
                    (Number(row.taxCode) / 100) *
                    (name === "purchaseQuantity"
                      ? row.orderPriceUnit * value
                      : row.purchaseQuantity * value),
                }
              : row;
          })
        );
      }
    } else if (type === "Number" && vat === "VAT") {
      if (isValidNumber(value)) {
        setPOLineItemsData((prevRows) =>
          prevRows.map((row) =>
            row.local_id === local_id
              ? {
                  ...row,
                  [name]: value,
                  taxAmount:
                    name === "taxCode"
                      ? (Number(value) / 100) *
                        (Number(row.purchaseQuantity || 0) *
                          Number(row.orderPriceUnit || 0))
                      : 0,
                }
              : row
          )
        );
      }
    } else {
      setPOLineItemsData((prevRows) =>
        prevRows.map((row) =>
          row.local_id === local_id ? { ...row, [name]: value } : row
        )
      );
    }
    // getTotalCount()
  };

  // Get Total for SubTotal Pricing Schedule
  useEffect(() => {
    // console.log(">> getPOLineItemsData", getPOLineItemsData);

    const getItemTotal = getPOLineItemsData?.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.netValue || 0),
      0
    );
    const taxTotalAmount = getPOLineItemsData?.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.taxAmount || 0),
      0
    );

    setTaxAmountValue(taxTotalAmount);
    setItemAmountValue(getItemTotal);

    setSubTotalValue(getItemTotal + taxTotalAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPOLineItemsData]);

  useEffect(() => {
    if (purchaseDocType) {
      getMasterDataFunc();
    } else {
      navigate(Constants.SOURCING_PURCHASE_REQUISITION_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Load Master Data
  const getMasterDataFunc = async () => {
    // 1
    // let obj1 = {};
    // await ItemService.getSupplierDatail(obj1).then((item) => {
     
    //   if (item?.status === 200) {
    //     setVendorDropdownData(item?.data);
    //   } else {
    //     setVendorDropdownData([]);
    //     console.log(">> Error: Loading getSupplierDatail");
    //   }
    // });

    // 2
    let obj2 = {
      codeType: "purchaseOrganisation",
    };
    await ItemService.getMasterData(obj2).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setPurchaseOrg(item?.data);
      } else {
        setPurchaseOrg([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj3 = {
      codeType: "companyCode",
    };
    await ItemService.getMasterData(obj3).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setCompanyCode(item?.data);
      } else {
        setCompanyCode([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj4 = {
      codeType: "INCOTerms",
    };
    await ItemService.getMasterData(obj4).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setINCOTerms(item?.data);
      } else {
        setINCOTerms([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj5 = {
      codeType: "currency",
    };
    await ItemService.getMasterData(obj5).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setcurrency(item?.data);
      } else {
        setcurrency([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj6 = {
      codeType: "paymentTerm",
    };
    await ItemService.getMasterData(obj6).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setpaymentTerm(item?.data);
      } else {
        setpaymentTerm([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    // let obj7 = {
    //   codeType: "paymentTerm",
    // };
    // await ItemService.getMasterData(obj7).then((item) => {
    //   // console.log('>> Check title ',item);
    //   if (item?.status === 200) {
    //     setLdApplicability(item?.data);
    //   } else {
    //     setLdApplicability([]);
    //     console.log(">> Error: Loading getMasterData");
    //   }
    // });
    // let obj8 = {
    //   codeType: "paymentTerm",
    // };
    // await ItemService.getMasterData(obj8).then((item) => {
    //   // console.log('>> Check title ',item);
    //   if (item?.status === 200) {
    //     setdownPaymentCategory(item?.data);
    //   } else {
    //     setdownPaymentCategory([]);
    //     console.log(">> Error: Loading getMasterData");
    //   }
    // });
    // let obj9 = {
    //   codeType: "paymentTerm",
    // };
    // await ItemService.getMasterData(obj9).then((item) => {
    //   // console.log('>> Check title ',item);
    //   if (item?.status === 200) {
    //     setmodeOfTransport(item?.data);
    //   } else {
    //     setmodeOfTransport([]);
    //     console.log(">> Error: Loading getMasterData");
    //   }
    // });
    // let obj10 = {
    //   codeType: "paymentTerm",
    // };
    // await ItemService.getMasterData(obj10).then((item) => {
    //   // console.log('>> Check title ',item);
    //   if (item?.status === 200) {
    //     setretention(item?.data);
    //   } else {
    //     setretention([]);
    //     console.log(">> Error: Loading getMasterData");
    //   }
    // });
    // let obj11 = {
    //   codeType: "paymentTerm",
    // };
    // await ItemService.getMasterData(obj11).then((item) => {
    //   // console.log('>> Check title ',item);
    //   if (item?.status === 200) {
    //     setLdperdaypercentage(item?.data);
    //   } else {
    //     setLdperdaypercentage([]);
    //     console.log(">> Error: Loading getMasterData");
    //   }
    // });
    let obj12 = {
      codeType: "PrPurchaseType",
    };
    await ItemService.getMasterData(obj12).then((item) => {
      if (item?.status === 200) {
        setPurchaseType(item?.data);
      } else {
        setPurchaseType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj13 = {
      codeType: "PurchaseCategory",
    };
    await ItemService.getMasterData(obj13).then((item) => {
      if (item?.status === 200) {
        setPurchaseCategory(item?.data);
      } else {
        setPurchaseCategory([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj14 = {
      codeType: "paymentTerm",
    };
    await ItemService.getMasterData(obj14).then((item) => {
      if (item?.status === 200) {
        setpurchaseGroup(item?.data);
      } else {
        setpurchaseGroup([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    setLoader(false);
  };

  // SAVE
  const handleSave = async () => {
    let requiredFields = [];

    // if (!validateRequired(getVendorDropdown.firstName)) {
    //   requiredFields.push("*Please Select the Vendor");
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }
    if (!validateRequired(formInputs.paymentTerms)) {
      requiredFields.push("*Please Select the Payment Terms");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.purchaseType)) {
      requiredFields.push("*Please Select the Purchase Type");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    if (
      typeof getPOLineItemsData[0]?.materialCode === "undefined" ||
      typeof getPOLineItemsData[0]?.purchaseQuantity === "undefined" ||
      typeof getPOLineItemsData[0]?.orderUnitOfMeasure === "undefined" ||
      typeof getPOLineItemsData[0]?.orderPriceUnit === "undefined" ||
      typeof getPOLineItemsData[0]?.deliveryDate === "undefined"
    ) {
      requiredFields.push("*Alteast one PO Line item should be added.");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    // let lineItem = []
    formInputs.items = getPOLineItemsData;
    // formInputs.vendorCode = getVendorDropdown.id;
    // formInputs.vendorName = getVendorDropdown.firstName;
    formInputs.headerTextData = getHeaderTextSelectedData;
    formInputs.purchasingReqDocumentValue = subTotalValue;
    formInputs.purchasingDocumentTaxTotalValue = getTaxAmountValue;
    formInputs.purchasingDocumentItemTotalValue = getItemAmountValue;
    formInputs.currencyCode = getCurrencyDropdown.code;
    formInputs.currency = getCurrencyDropdown.name;

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addPR(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        dispatch(getPurchaseRequisition({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  const addPOItemRow = () => {
    setPOLineItemsData((prevRows) => {
      POLineItemInitialData[0].local_id = prevRows.length + 1;
      POLineItemInitialData[0].purchasingReqDocumentItemNr = prevRows.length + 1; // make it to 3 digit 001, 002
      return [...prevRows, ...POLineItemInitialData];
    });
  };

  const removePOItemRow = (local_id) => {
    setPOLineItemsData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Purchase Requisition</h2>
            <p className="subText">Fill Purchase Requisition Details</p>
            <hr />

            <Loader isLoading={getLoader} className={"text-center"} />

            {!getLoader && (
              <>
                {/*Document Header Details */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">HD</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">Header Details</span>
                          <span className="subHeaderTitle">
                            Document Description and Details{" "}
                          </span>
                        </Col>

                        {/* <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col> */}
                      </Row>
                    </h2>
                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="purchasingDocumentType"
                              className="inputBox"
                              label="Document type"
                              name="purchasingDocumentType"
                              placeholder=""
                              value={purchaseDocType} // purchasingDocumentType
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="purchasingReqDocumentDate"
                              className="inputBox"
                              label="Document Date"
                              name="purchasingReqDocumentDate"
                              placeholder=""
                              type="date"
                              value={formInputs.purchasingReqDocumentDate}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="prStatusHeader"
                              className="inputBox"
                              label="Status"
                              name="prStatusHeader"
                              placeholder=""
                              value={formInputs.prStatusHeader}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="version"
                              className="inputBox"
                              label="Version"
                              name="version"
                              placeholder=""
                              value={formInputs.version}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              id="purchaseType"
                              label="Purchase Type"
                              placeholder=""
                              data={getPurchaseType}
                              iconClassName="dropdownIcon"
                              name="purchaseType"
                              code="purchaseTypeCode"
                              setValue={formInputs?.purchaseType || []}
                              getvalue={setDropdownData}
                              disabled={false}
                              required={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              id="purchaseCategory"
                              label="Purchase Category"
                              placeholder=""
                              data={getPurchaseCategory}
                              iconClassName="dropdownIcon"
                              name="purchaseCategory"
                              code="purchaseCategoryCode"
                              setValue={formInputs?.purchaseCategory || []}
                              getvalue={setDropdownData}
                              disabled={false}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Purchase Organization"
                              extraLabel="SAP Purchase organization"
                              placeholder=""
                              data={getPurchaseOrg}
                              id="purchasingOrg"
                              iconClassName="dropdownIcon"
                              name="purchasingOrg"
                              code="purchasingOrgCode"
                              setValue={formInputs?.purchasingOrg || []}
                              getvalue={setDropdownData}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Purchase Group"
                              name="purchasingGroup"
                              data={getpurchaseGroup}
                              id="purchasingGroup"
                              iconClassName="dropdownIcon"
                              setValue={formInputs?.purchasingGroup || []}
                              getvalue={setDropdownData}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Company Code"
                              extraLabel="SAP Company Code"
                              placeholder=""
                              data={getCompanyCode}
                              id="companyCode"
                              iconClassName="dropdownIcon"
                              name="companyCode"
                              code="companyCodeCode"
                              setValue={formInputs?.companyCode || []}
                              getvalue={setDropdownData}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Payment Terms"
                              extraLabel=""
                              placeholder=""
                              data={getpaymentTerm}
                              id="paymentTerms"
                              iconClassName="dropdownIcon"
                              name="paymentTerms"
                              code="paymentTermsCode"
                              setValue={formInputs?.paymentTerms || []}
                              getvalue={setDropdownData}
                              required={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="INCOTerms"
                              extraLabel="INCOTerms Code"
                              placeholder=""
                              data={getINCOTerms}
                              id="incoTerms"
                              iconClassName="dropdownIcon"
                              name="incoTerms"
                              code="incoTermsCode"
                              setValue={formInputs?.incoTerms || []}
                              getvalue={setDropdownData}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="incoTermsLocation"
                              className="inputBox"
                              label="INCOTerms Location"
                              name="incoTermsLocation"
                              placeholder=""
                              value={formInputs.incoTermsLocation}
                              onChange={onInputChange}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Currency"
                              placeholder=""
                              data={getcurrency}
                              id="currency"
                              iconClassName="dropdownIcon"
                              name="currency"
                              code="currencyCode"
                              setValue={
                                getCurrencyDropdown?.name || {
                                  code: "INR",
                                  name: "INR-Rupee",
                                }
                              }
                              getvalue={setCurrencyDropdown}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="purchasingReqDocumentValue"
                              className="inputBox"
                              label="Total Tax Amount"
                              name="purchasingReqDocumentValue"
                              placeholder=""
                              value={getTaxAmountValue}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="purchasingReqDocumentValue"
                              className="inputBox"
                              label="Item Total Amount"
                              name="purchasingReqDocumentValue"
                              placeholder=""
                              value={getItemAmountValue}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              id="purchasingReqDocumentValue"
                              className="inputBox"
                              label="Purchase Net Value"
                              name="purchasingReqDocumentValue"
                              placeholder=""
                              value={subTotalValue}
                              onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Exchange Rate"
                              name="firstName"
                              placeholder=""
                              // value={""}
                              onChange={onInputChange}
                              disabled={getDisabledStatus}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <TextAreaField
                              id="purchasingReqRemark"
                              className="inputBox"
                              label="Purchasing Info"
                              name="purchasingReqRemark"
                              placeholder=""
                              value={formInputs.purchasingReqRemark || ""}
                              onChange={onInputChange}
                            />
                          </Col>
                          
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* other  charges table */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible5
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility5}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">DT</span>
                        </Col>

                        <Col xs={4} md={3} lg={3}>
                          <span className="headerTitle">Document Texts</span>
                          <span className="subHeaderTitle">
                            Document texts and Specification{" "}
                          </span>
                        </Col>
                        <Col
                          xs={12}
                          md={8}
                          lg={8}
                          className="headerButtonAlign"
                        >
                          <Col xs={4} md={2} lg={2} className="">
                            <button
                              className="btnTable"
                              onClick={showsetHeaderText}
                            >
                              <i className="bi bi-plus-lg"></i> Add
                            </button>
                          </Col>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible5 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              // title=""
                              columns={HeaderTextColumns}
                              data={getHeaderTextSelectedData}
                              // progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Price Shedule / Line Item */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible4
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility4}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">PS</span>
                        </Col>
                        <Col xs={12} md={3} lg={3}>
                          <span className="headerTitle">Price Schedule</span>
                          <span className="subHeaderTitle">
                            Price Shedule / Line Item
                          </span>
                        </Col>
                        <Col
                          xs={12}
                          md={8}
                          lg={8}
                          className="headerButtonAlign"
                        >
                          <Col xs={4} md={2} lg={2} className="">
                            <button className="btnTable">
                              <i className="bi bi-file-excel"></i> Template
                            </button>
                          </Col>
                          <Col xs={4} md={2} lg={2} className="">
                            <button
                              className="btnTable"
                              onClick={() => {
                                alert("asd");
                              }}
                            >
                              <i className="bi bi-file-excel"></i> Upload
                            </button>
                          </Col>
                          <Col xs={4} md={2} lg={2} className="">
                            <button className="btnTable" onClick={addPOItemRow}>
                              <i className="bi bi-plus-lg"></i> Add
                            </button>
                          </Col>
                        </Col>
                      </Row>
                    </h2>
                    <div
                      // id="acc"
                      className={`accordion-collapse collapse show ${
                        isCardVisible4 ? "visible" : ""
                      }`}
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {" "}
                        {/* dynamicDropdown */}
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <DataTable
                              title=""
                              columns={getPOLineItemsCol}
                              data={getPOLineItemsData}
                              // progressPending={loading}
                              paginationRowsPerPageOptions={
                                Constants.ROW_PER_PAGE
                              }
                              pagination
                              // paginationServer
                              // paginationTotalRows={totalRows}
                              // onChangeRowsPerPage={handlePerRowsChange}
                              // onChangePage={handlePageChange}
                              selectableRows
                              // onSelectedRowsChange={handleChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      {!getLoader && (
        <>
          <div className="pageFooter">
            <Row className="rowDirection">
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btnTable"
                  //  onClick={handleModify}
                >
                  <i className="bi bi-pencil-square"></i> Modify
                </button>
              </Col>
            </Row>
          </div>
        </>
      )}

      {/* Header Text */}
      <HeaderText
        index={0}
        headername="Header Text"
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hedertextdata={handleHeaderTextSelectedData}
        showcurrentdata={getHeaderTextSelectedData}
        masterdata="POHeaderTextID"
      />

      {/* Price Shedule / Line Item */}
      <HeaderText
        index={getHeaderTextIndex}
        headername="Header Text for Line Item"
        show={getLineItemHeaderTextModal}
        onHide={hideLineItemHeaderText}
        hedertextdata={handleHeaderTextLineItemData}
        showcurrentdata={getDataToHeaderText}
        masterdata="POItemTextID"
      />

      {/* Price Shedule -> Service PO */}
      <SericesSchedulePO
        index={getServicesLineItemIndex}
        headername={getServiceTitle}
        show={getServicesLineItemModal}
        onHide={hideServicesLineItem}
        servicescheduledata={handleServicePOLineItemData}
        showcurrentdata={getServicesLineItemText}
        purchasetype={formInputs.purchaseTypeCode}
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_PURCHASE_REQUISITION_LIST}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CreatePR;
