import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { validateRequired } from "../../../../common/Validations";
import toast from "react-hot-toast";
// import Spinner from "react-bootstrap/Spinner";
import { getNextDate } from "../../../../common/Validations";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
};

const OrderCarryForwardModal = React.forwardRef((props, ref) => {
  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  // const [uploadStatus, setUploadStatus] = useState(false);
  const [getData, setData] = useState();

  const [formInputs, setFormInputs] = useState({
    orderSchedulingNr: "",
    carryForwardOrderLoad: "",
    carryForwardOrderRemarks: "",
    dispatchDate: getNextDate(""),
  });
  /* pop failure modal */
  const [validation, setValidation] = useState({
    orderSchedulingNr: "",
    carryForwardOrderLoad: "",
    carryForwardOrderRemarks: "",
    dispatchDate: "",
  });
  // console.log(">>validation", validation);

  useEffect(() => {
    setFormInputs({
      carryForwardOrderLoad: props?.data?.balOrderQuantityLoad,
      carryForwardOrderRemarks: "",
      dispatchDate: getNextDate(""),
      orderSchedulingNr: "",
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    setData(props?.data);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    // console.log(">>name,value", value);
    const errors = {};

    if (
      name === "carryForwardOrderLoad" &&
      Number(value) > getData?.balOrderQuantityLoad
    ) {
      toast.error(
        `Carry Forward quantity cannot exceed ${getData?.balOrderQuantityLoad}`
      );
      return; // Prevents updating state if condition fails
    }

    setFormInputs((formInputs) => ({
      ...formInputs,
      [name]: name === "carryForwardOrderLoad" ? Number(value) : value,
      balOrderQuantityLoad: getData?.balOrderQuantityLoad,
      orderSchedulingNr: getData?.documentNumber,
      // orderStatus: "Cancelled",
    }));

    setValidation((prev) => ({
      ...prev,
      [name]: value === "" ? "" : errors[name] || "",
    }));
  };

  const handleCancel = () => {
    props.onHide();
  }

  //handle save
  const handleSave = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      return;
    }

    if (formInputs.carryForwardOrderLoad === 0) {
      toast.error(`Carry Forward quantity cannot be "0"`);
      return; // Prevents updating state if condition fails
    }

    if (!validateRequired(formInputs.carryForwardOrderLoad)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setValidation((prev) => ({
        ...prev,
        carryForwardOrderLoad: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.carryForwardOrderRemarks)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setValidation((prev) => ({
        ...prev,
        carryForwardOrderRemarks: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    // if (formInputs.dispatchDate) {
    //   requiredFields.push(`Should be greater then Today's Date`);
    //   setValidation((prev) => ({
    //     ...prev,
    //     dispatchDate: "Should be greater then Today's Date",
    //   }));
    //   return;
    // }

    console.log(">> formInputs", formInputs);

    await ItemService.carryForwardOrder(formInputs).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "Cancel Order successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onSuccess();
        props.onHide();
      } else {
        console.log(">> Error: Loading carryForwardOrder");
      }
      // setDisabled(false);
    });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Carry Forward - {getData?.documentNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">CF</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle"> Carry Forward</span>
                  <span className="subHeaderTitle">Carry Forward details</span>
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Carry Forward Order Qty(Load)"
                            type="number"
                            value={formInputs?.carryForwardOrderLoad}
                            name="carryForwardOrderLoad"
                            id="carryForwardOrderLoad"
                            onChange={onInputChange}
                            error={validation.carryForwardOrderLoad}
                            disabled={false}
                            required={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox mt-1"
                            label="Select Dispatch Date"
                            name="dispatchDate"
                            type="date"
                            placeholder=""
                            value={formInputs.dispatchDate}
                            disabled={false}
                            onChange={onInputChange}
                            required={true}
                            error={validation.dispatchDate}
                            min={
                              new Date(Date.now() + 86400000)
                                .toISOString()
                                .split("T")[0]
                            } // Tomorrow's date
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Carry Forward Remark *"
                            name="carryForwardOrderRemarks"
                            id="carryForwardOrderRemarks"
                            value={formInputs?.carryForwardOrderRemarks}
                            onChange={onInputChange}
                            error={validation.carryForwardOrderRemarks}
                            disabled={false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Row className="mt-4">
            <Col
              lg={{ span: 1, offset: 10 }}
              className="d-flex justify-content-end"
            >
              <button className="btnTable btn-danger" onClick={handleCancel}>
                Cancel
              </button>
            </Col>
            <Col
              lg={{ span: 1, offset: 0 }}
              className="d-flex justify-content-end"
            >
              <button className="btnTable" onClick={handleSave}>
                Save
              </button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default OrderCarryForwardModal;
