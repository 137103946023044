import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
// import Constants from "../common/Constants";

const SuccessRegisterModal = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  // Handle form OK
  const okConfirm = async () => {
    navigate(props.aftersuccess);
    props.onHide();
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="md"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-check2-circle"></i>
          <span className="modal-title"> {props?.modaldata?.title}</span>
          {/* <p className='subText'>Confirm the below given statement</p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col
              xs={12}
              md={12}
              lg={12}
              className="commLeftRightPadding text-left mt-2"
            >
              {/* <h3>Successfully Registered</h3> */}
              <div>
                Thank you, 
                {/* Supplier Id - S0080 registered successfully */}
                <br /> 
                <h5>{props?.modaldata?.successMsg}</h5> 
              </div>
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={4} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => okConfirm()}
                className="btn btn-primary w-full justify-center rounded-md"
              >
                OK
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default SuccessRegisterModal;
