import React, { useState } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";

import ItemService from "../../../../services/customer_appCommonService";

//Redux
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import SupplierConstants from "../../../../common/SupplierConstants";
import { validateRequired } from "../../../../common/Validations";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";

const CreateMaterial = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Material");

  const [isCardVisible, setCardVisible] = useState(true);

  const message = {
    VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
    VALID_MAIL_ID: "Please enter a valid email address.",
    VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
    VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
    VALID_ALTERNATE_CONTACT_NUMBER:
      "Please enter a valid alternate contact number.",
    VALID_PURPOSE_JUSTIFICATION:
      "Please ensure the justification is under 500 characters.",
    VALID_POSTAL_CODE:
      "Enter a 6-character postal code with uppercase letters and numbers only",
    VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
    VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
    EXIStS_MAIL_ID: "Email already exists",
    EXIStS_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
    EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
    FILL_REQUIRED_FIELD: "Please fill the required field",
    ALTERNATE_MAIL_ID: "Email ID is same",
    ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  };

  const [formInputs, setFormInputs] = useState({
    slNo: "",
    materialCode: "",
    materialLongText: "",
    materialGroup: "",
    uOM: "",
    plant: "",
  });

  /* pop failure modal data*/
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);
  const [validation, setValidation] = useState({
    slNo: "",
    materialCode: "",
    materialLongText: "",
    materialGroup: "",
    uOM: "",
    plant: "",
  });

  const modalData = {
    title: "Material Creation",
    successMsg: "",
  };
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  //hide  failur modal
  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  /* handle create */
  const handleCreate = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.slNo)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        slNo: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.materialCode)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        materialCode: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.materialLongText)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        materialLongText: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.materialGroup)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        materialGroup: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.uOM)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        uOM: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.plant)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        plant: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }

    let obj = {
      data: formInputs,
    };
    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    setDisabled(true);
    await ItemService.addMaterial(obj).then((item) => {
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Material</h2>
            <p className="subText">Create Material</p>
            <hr />
            <Row className="mb-2">
              <Col
                xs={2}
                md={{ offset: 10, span: 1 }}
                lg={{ offset: 10, span: 1 }}
                className="text-right"
              >
                <span>
                  <button
                    className="btnTable"
                    disabled={disabled}
                    onClick={handleCreate}
                  >
                    Create
                  </button>
                </span>
              </Col>
            </Row>
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CM</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Create Material</span>
                      <span className="subHeaderTitle">Create material</span>
                    </Col>
                    {/* 
                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button
                          className="btnTable"
                          disabled={disabled}
                          onClick={handleCreate}
                        >
                          Create
                        </button>
                      </span>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SlNo."
                          name="slNo"
                          id="slNo"
                          extraLabel=""
                          value={formInputs.slNo || ""}
                          onChange={onInputChange}
                          error={validation.slNo || ""}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Material Code"
                          name="materialCode"
                          id="materialCode"
                          value={formInputs.materialCode || ""}
                          onChange={onInputChange}
                          error={validation.materialCode || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Material Description"
                          type="text"
                          name="materialLongText"
                          id="materialLongText"
                          value={formInputs.materialLongText || ""}
                          onChange={onInputChange}
                          error={validation.materialLongText || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Material Group"
                          name="materialGroup"
                          id="materialGroup"
                          extraLabel=""
                          value={formInputs.materialGroup || ""}
                          onChange={onInputChange}
                          error={validation.materialGroup || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="UOM"
                          name="uOM"
                          id="uOM"
                          extraLabel=""
                          value={formInputs.uOM || ""}
                          onChange={onInputChange}
                          error={validation.uOM || ""}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant"
                          name="plant"
                          id="plant"
                          extraLabel=""
                          value={formInputs.plant || ""}
                          onChange={onInputChange}
                          error={validation.plant || ""}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={SupplierConstants.MATERIAL_LIST}
      />
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CreateMaterial;
