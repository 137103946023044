import React, { useEffect, useState } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";

import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import Constants from "../../../../common/CustomerConstants";
import ApproveRemarksModal from "../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import Loader from "../../../../Components/Loader";
import ShowHorizontalStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowHorizontalStatus";
import toast from "react-hot-toast";


//Serivce
import ItemService from "../../../../services/customer_appCommonService";

const CreditPolicyRequestFormDetails = () => {
  const navigate = useNavigate();

  localStorage.setItem("menu", "Customer");
  localStorage.setItem("submenu", "");

  let addCreditPolicyID = useSelector(
    (state) => state?.customerV1?.creditPolicy?.addCreditPolicyID
  ); // User Details from Store
  let workflow = useSelector((state) => state?.customer?.customerWorkflow); // workflow from Store

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);

  const [modalShowApprove, setModalShowApprove] = useState(false);
  const [getAction, setAction] = useState("");

  const availabilityOfSecurityChequeDropDown = [
    { id: 1, name: "Yes" },
    { id: 1, name: "No" },
  ];
  const PDCdetailsDropdown = [
    { id: 1, name: "Yes" },
    { id: 1, name: "No" },
  ];
  // eslint-disable-next-line no-unused-vars
  const [creditLimitData, setcreditLimitData] = useState([
    {
      id: 1,
      name: "Limit",
      status: true,
    },
    {
      id: 2,
      name: "Period",
      status: false,
    },
  ]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [editorContent, setEditorContent] = useState("");
  const [getDropdownData, setDropdownData] = useState("");

  const [formInputs, setFormInputs] = useState({
    creditLimitExceedCustomer: "",
    nextPaymentScheduleDate: "",
    nextPaymentScheduleAmount: "",
    PDCdetails: "",
    limitToExtendedDays: "",
    periodToExtendedAmount: "",
    currentOutstandingAmount: "",
    creditLimitAmount: "",
    currentPeriodDays: "",
    creditPeriodDays: "",
    availabilityOfSecurityCheque: "",
    totalTurnover: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  // Commmon OnChange of the input scetion
  // const onInputChange = ({ target: { name, value } }) => {
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  //   // console.log("onInputChange => formInputs", formInputs);
  // };
  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    setFormInputs((formInputs) => ({
      ...formInputs,
      [getDropdownData?.textField]: getDropdownData?.name,
      [getDropdownData?.textCode]: getDropdownData?.code,
      [getDropdownData?.textDesc]: getDropdownData?.description,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const loadInitialPageData = async () => {
    setLoading(true);
    if (addCreditPolicyID) {
      let obj = {
        documentNumber: addCreditPolicyID,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getCreditPolicyDetailV1(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item?.data[0]);
          // setProjectDetails(item?.data[0]?.projectDetails);
          // setData(item?.data[0]?.items);
          // dispatch(addPoSelectedDetails(item?.data));
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      if (JSON.stringify(workflow?.workflow_record) !== "{}") {
        if (workflow?.page === "Inbox") {
          navigate(Constants.INBOX);
        } else {
          navigate(Constants.OUTBOX);
        }
      } else {
        navigate(Constants.CUSTOMER_V1_COSTING_SHEET_LIST);
      }
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCreditPolicyID]);

  // Approve
  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    // obj.data = {
    //   items: data,
    // };
    let newObj = { ...bodyObj, ...obj };

    // console.log(">> obj", obj);

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        navigate(Constants.INBOX);
        // dispatch(getLoginUserData());
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const showApproveModal = async (name) => {
    setModalShowApprove(true);
    setAction(name);
  };
  const hideModalApprove = () => {
    setModalShowApprove(false);
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const navigateToWorkflow = () => {
    if (workflow?.page === "Inbox") {
      navigate(Constants.INBOX);
    } else {
      navigate(Constants.OUTBOX);
    }
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Credit Policy Request
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {addCreditPolicyID}
              </span>
              <span>
                {formInputs?.cpHeaderStatus === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Non Invited" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Approved" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Pending with L1" ? (
                  <span className="bg-table-status bg-table-warning-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Pending with L2" ? (
                  <span className="bg-table-status bg-table-warning-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : formInputs?.cpHeaderStatus === "Pending for approval" ? (
                  <span className="bg-table-status bg-table-warning-status headerStatusDetailedPage">
                    {formInputs?.cpHeaderStatus}
                  </span>
                ) : (
                  formInputs?.cpHeaderStatus
                )}
              </span>
            </h2>
            <p className="subText">
              Credit Policy Check based on Limit and Period{" "}
            </p>
            <hr />

            {!workflow?.workflow_record && (
              <ShowHorizontalStatus rowdatamodal={workflow} />
            )}

            <div className="pageFooter mb-4">
              <Row>
                {workflow?.workflow_record?.decision?.length > 0 && (
                  <Col xs={12} md={4} lg={4} className="text-left">
                    <button
                      className="btnBack"
                      onClick={() => navigateToWorkflow()}
                    >
                      <i className="bi bi-arrow-return-left"></i> Go Back
                    </button>
                  </Col>
                )}
                <Col xs={12} md={8} lg={8} className="text-right">
                  {workflow?.workflow_record?.decision?.length > 0 &&
                    workflow?.workflow_record?.decision?.map((item, index) => (
                      <button
                        className={
                          item === "Approve" || item === "Submit"
                            ? "btnCommon btn-approve"
                            : "btnCommon btn-danger"
                        }
                        onClick={() => showApproveModal(item)}
                        key={index}
                      >
                        <i
                          className={
                            item === "Approve" || item === "Submit"
                              ? "bi bi-check2-circle"
                              : "bi bi-x-circle"
                          }
                        ></i>{" "}
                        {item}
                      </button>
                    ))}
                </Col>
              </Row>
            </div>

            <Loader isLoading={loading} className={"text-center"} />

            {!loading && (
              <>
                {/* 01 Basic Information */}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">CA</span>
                        </Col>
                        <Col xs={12} md={9} lg={9}>
                          <span className="headerTitle">
                            Credit Policy Details
                          </span>
                          <span className="subHeaderTitle">
                            Credit Policy Details
                          </span>
                        </Col>

                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="pt-2 pb-4">
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Customer Code"
                              name="customerCode"
                              placeholder=""
                              value={formInputs?.customerCode || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Customer Name"
                              name="customerName"
                              placeholder=""
                              value={formInputs?.customerName || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Sales Officer Name"
                              name="salesOfficerName"
                              placeholder=""
                              value={formInputs?.salesOfficerName || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding d-flex gap-1 align-items-center"
                          >
                            {creditLimitData.map((item, i) => (
                              <div className="form-check" key={item.id}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`gridCheck${item.id}`}
                                  checked={item.status}
                                  // onChange={() =>
                                  //   handleTermsConditionCheckBox(item.id)
                                  // }
                                  disabled={item?.disabled}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`gridCheck${item.id}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            ))}
                          </Col>
                         
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Availability Of Security Cheque"
                              placeholder=""
                              data={availabilityOfSecurityChequeDropDown}
                              id="availabilityOfSecurityCheque"
                              iconClassName="dropdownIcon"
                              name="availabilityOfSecurityCheque"
                              // code="availabilityOfSecurityChequeCode"
                              // description="availabilityOfSecurityChequeDesc"
                              setValue={formInputs.availabilityOfSecurityCheque}
                              getvalue={setDropdownData}
                              required={true}
                              disabled={true}
                              // error={validation.country}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Security Cheque Amount"
                              type="text"
                              name="securityChequeAmount"
                              placeholder=""
                              value={formInputs.securityChequeAmount || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="PDC Details"
                              placeholder=""
                              data={PDCdetailsDropdown}
                              id="PDCdetails"
                              iconClassName="dropdownIcon"
                              name="PDCdetails"
                              // code="PDCdetailsCode"
                              // description="PDCdetailsDesc"
                              setValue={formInputs.PDCdetails}
                              getvalue={setDropdownData}
                              required={true}
                              disabled={true}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Next Payment Schedule Amount"
                              type="text"
                              name="nextPaymentScheduleAmount"
                              placeholder=""
                              value={formInputs.nextPaymentScheduleAmount || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Next Payment Schedule Date"
                              name="nextPaymentScheduleDate"
                              type="date"
                              placeholder=""
                              value={formInputs.nextPaymentScheduleDate || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Limit To Extended Days"
                              name="limitToExtendedDays"
                              placeholder=""
                              value={formInputs.limitToExtendedDays || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Period To Extended Amount"
                              name="periodToExtendedAmount"
                              placeholder=""
                              value={formInputs.periodToExtendedAmount || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          ></Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Current Out Standing Amount"
                              name="currentOutstandingAmount"
                              placeholder=""
                              value={formInputs.currentOutstandingAmount || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Credit Limit Amount"
                              name="creditLimitAmount"
                              placeholder=""
                              value={formInputs.creditLimitAmount || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          ></Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Current Period Days"
                              name="currentPeriodDays"
                              placeholder=""
                              value={formInputs.currentPeriodDays || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Credit Period Days"
                              name="creditPeriodDays"
                              placeholder=""
                              value={formInputs.creditPeriodDays || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Total Turnover"
                              name="totalTurnover"
                              placeholder=""
                              value={formInputs.totalTurnover || ""}
                              // onChange={onInputChange}
                              disabled={true}
                            />
                          </Col>
                          <Col
                            lg={12}
                            className="commTopButtonRightLeftPadding"
                          >
                            <p>
                              Credit
                              <span className="fw-bold">Limit</span>,Exceed for
                              the customer
                              <span className="fw-bold">
                                {" "}
                                TECHNOCON CONSTRUCTIONS & INFRASTRU(T10024){" "}
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  02 */}
                {/* Instructions 03*/}
                <div className="accordion mb-4" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <Row className="accordion-button">
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-left arrowBtn"
                        >
                          <i
                            className={`bi ${
                              isCardVisible2
                                ? "bi-chevron-right"
                                : "bi-chevron-down"
                            }`}
                            onClick={toggleCardVisibility2}
                          ></i>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          lg={1}
                          className="text-center logoBtn"
                        >
                          <span className="iconLogo">RE</span>
                        </Col>
                        <Col xs={8} md={9} lg={9}>
                          <span className="headerTitle">instructions</span>
                          <span className="subHeaderTitle">instructions</span>
                        </Col>
                      </Row>
                    </h2>

                    <div
                      className={`accordion-collapse collapse show ${
                        isCardVisible2 ? "visible" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <Row className="p-4">
                          <Col xs={12} md={12} lg={12}>
                            <RichTextEditor
                              value={formInputs?.instruction}
                              // onChange={handleEditorChange}
                              readOnly={true}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      <ApproveRemarksModal
        show={modalShowApprove}
        onHide={() => hideModalApprove()}
        onvaluechange={handleValueChange}
      />
    </main>
  );
};

export default CreditPolicyRequestFormDetails;
