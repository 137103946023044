/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/customer_appCommonService";

const initialState = {
  status: true,
  inquiryID: "",
  inquiry: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },  
  quotationID: "",
  quotation: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
};

export const customerSlice = createSlice({
  name: "preSales",
  initialState,
  reducers: {
    addInquiryID(state, action) {
      state.inquiryID = action.payload;
    },
    addQuotationID(state, action) {
      state.quotationID = action.payload;
    },
    workflow(state, action) {
      state.customerWorkflow = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInquiryList.pending, (state, action) => {
        state.inquiry.status = true;
      })
      .addCase(getInquiryList.fulfilled, (state, action) => {
        state.inquiry.data = action.payload.data;
        state.inquiry.pagination.page = action.payload.page;
        state.inquiry.pagination.per_page = action.payload.per_page;
        state.inquiry.pagination.total = action.payload.total;
        state.inquiry.status = false;
      })
      .addCase(getQuotationList.pending, (state, action) => {
        state.quotation.status = true;
      })
      .addCase(getQuotationList.fulfilled, (state, action) => {
        state.quotation.data = action.payload.data;
        state.quotation.pagination.page = action.payload.page;
        state.quotation.pagination.per_page = action.payload.per_page;
        state.quotation.pagination.total = action.payload.total;
        state.quotation.status = false;
      });
  },
});

export const { addInquiryID, addQuotationID, workflow } = customerSlice.actions;
export default customerSlice.reducer;

export const getInquiryList = createAsyncThunk(
  "getInquiryList",
  async (param) => {
    console.log(">> loaded getInquiryList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getQuotationList = createAsyncThunk(
  "getQuotationList",
  async (param) => {
    console.log(">> loaded getQuotationList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);
