import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/CustomerConstants";

//Redux
import { useSelector } from "react-redux"; //useDispatch
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { getCurrentDate } from "../../../../common/Validations";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import DataTable from "react-data-table-component";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

const DeliveryDetails = () => {
  const navigate = useNavigate();
  localStorage.setItem("menu", "Pre Sales");
  localStorage.setItem("submenu", "Quotation");

  let quotationID = useSelector((state) => state?.preSales?.quotationID); // User Details from Store

  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  const [getDisabledStatus, setDisabledStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedShipment, setSelectedShipment] = useState("Yes");
  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [itemTableData, setItemTableData] = useState();

  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];
  
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Product Id",
      selector: (row) => row.productId,
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Quantity ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="qtnQuantity"
            name="qtnQuantity"
            value={row.qtnQuantity || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "160px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "200px",
    },
    {
      name: "Unit Price ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="quotationUnitPrice"
            name="quotationUnitPrice"
            value={row.quotationUnitPrice || 0}
            disabled={disabled}
          />
        </>
      ),
    },
    {
      name: "Item Value ", // calculation
      selector: (row) => row.qtnitemValue || "--",
      width: "200px",
    },
    {
      name: "Delivery Lead Time (Days)", // input
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="deliveryLeadTime"
            name="deliveryLeadTime"
            value={row.deliveryLeadTime || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Service Start Date", // input
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceStartDate"
            name="serviceStartDate"
            value={row.serviceStartDate || getCurrentDate("")}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },

    {
      name: "Service End Date", // input
      selector: (row) => row.serviceEndDate || "--",
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceEndDate"
            name="serviceEndDate"
            value={row.serviceEndDate || getCurrentDate("")}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation", // input yes/NO
      selector: (row) => row.deviation || "--",
      cell: (row) => (
        <>
          <ComboboxField
            placeholder=""
            data={dropdownOption}
            id="deviation"
            name="deviation"
            value={row.deviation || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation Remarks", // input
      selector: (row) => row.deviationRemark || "--",
      cell: (row) => (
        <>
          <TextAreaField
            className="inputBox mt-1"
            id="deviation"
            name="deviation"
            value={row.deviation}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
  ];

  const loadFirstLevelData = async (page) => {
    console.log(">> quotationID", quotationID);
    if (quotationID) {
      let obj = {
        documentNumber: quotationID,
      };
      await ItemService.getQuotationDatail(obj).then((item) => {
        if (item?.status === 200) {
          // console.log(">> item", item);
          setDisabledStatus(true);
          setFormInputs(item?.data[0]);
          setItemTableData(item?.data[0].items);
        } else {
          setFormInputs([]);
          console.log(">> Error: Loading getQuotationDatail");
        }
      });
    } else {
      navigate(Constants.PRESALES_CUSTOMER_QUOTATION_LIST);
    }
  };

  useEffect(() => {
    loadFirstLevelData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Quotation Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {quotationID}
              </span>
              <span>
                {formInputs?.status === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Sent" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Draft" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Deactivated" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : (
                  formInputs?.status
                )}
              </span>
            </h2>
            <p className="subText">Quotation Information </p>
            <hr />
            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="companyAddressDesc"
                          id="companyAddressDesc"
                          placeholder=""
                          value={formInputs?.companyAddressDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="rfxNr"
                          id="rfxNr"
                          placeholder=""
                          value={formInputs?.rfxNr}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationNr9"
                          className="inputBox"
                          label="Quotation Number"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="billingAddressDesc"
                          id="billingAddressDesc"
                          value={formInputs?.billingAddressDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="rfqVersion"
                          className="inputBox"
                          label="RFQ Version"
                          name="rfxVersion"
                          placeholder=""
                          value={formInputs?.rfxVersion}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationCreatedOn"
                          className="inputBox"
                          label="Quotation Created Date"
                          name="quotationCreatedOn"
                          placeholder=""
                          value={getCurrentDate("default")}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="billingAddressDesc"
                          id="billingAddressDesc"
                          value={formInputs?.billingAddressDesc}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="rfxbidTypeDesc"
                          id="rfxbidTypeDesc"
                          value={formInputs?.rfxbidTypeDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Version"
                          name="qtnVersion"
                          id="qtnVersion"
                          placeholder=""
                          value={" v1"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="buyerResponsible"
                          id="buyerResponsible"
                          value={formInputs?.buyerResponsible}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="productType"
                          id="productType"
                          value={formInputs?.productType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmittedBy"
                          className="inputBox"
                          label="Quotation Submitted By"
                          name="quotationSubmittedBy"
                          placeholder=""
                          value={" "}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="buyerConact"
                          id="buyerConact"
                          value={formInputs?.buyerConact}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Evaluation"
                          name="rfxNoOfEvaluationType"
                          id="rfxNoOfEvaluationType"
                          value={formInputs?.rfxNoOfEvaluationType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmissionDate"
                          className="inputBox"
                          label="Quotation Submitted On"
                          name="quotationSubmissionDate"
                          placeholder=""
                          value={formInputs?.quotationSubmissionDate}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="rfxbidType"
                          id="rfxbidType"
                          value={formInputs?.rfxbidType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Currency"
                          name="rfxCurrency"
                          id="rfxCurrency"
                          value={formInputs?.rfxCurrency}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationStatus"
                          className="inputBox"
                          label="Quotation Status"
                          name="quotationStatus"
                          placeholder=""
                          value={"New"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header Info</span>
                      <span className="subHeaderTitle">
                        Quotation Header Information
                      </span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationReference"
                          className="inputBox"
                          label="Quotation Reference"
                          name="quotationReference"
                          placeholder=""
                          value={formInputs?.quotationReference}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmissionDate"
                          className="inputBox"
                          type="date"
                          label="Quotation Date"
                          name="quotationSubmissionDate"
                          placeholder=""
                          value={formInputs?.quotationSubmissionDate}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment term"
                          placeholder=""
                          // data={getpaymentTerm}
                          id="qtnPaymentTerms"
                          iconClassName="dropdownIcon"
                          name="qtnPaymentTerms"
                          code="qtnPaymentTermsCode"
                          description="qtnPaymentTermsDesc"
                          setValue={formInputs?.qtnPaymentTerms}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Inco Terms"
                          placeholder=""
                          data={[]}
                          id="qtnIncoTerms"
                          iconClassName="dropdownIcon"
                          name="qtnIncoTerms"
                          code="qtnIncoTermsCode"
                          description="qtnIncoTermsDesc"
                          setValue={formInputs?.qtnIncoTerms}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Bid Currency"
                          placeholder=""
                          data={[]}
                          id="qtnBidderCurrency"
                          iconClassName="dropdownIcon"
                          name="qtnBidderCurrency"
                          code="qtnBidderCurrencyCode"
                          description="qtnBidderCurrencyDesc"
                          setValue={formInputs?.qtnBidderCurrency}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="technicalQuoteArticle"
                          className="inputBox"
                          label="Milestone / Scheduled payments"
                          type="text"
                          name="technicalQuoteArticle"
                          placeholder=""
                          value="No"
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="technicalQuoteArticle"
                          className="inputBox"
                          label="TECHNICAL PROPOSAL"
                          type="file"
                          name="technicalQuoteArticle"
                          placeholder=""
                          value={formInputs?.technicalQuoteArticle || ""}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Pricing Notes"
                          type="text"
                          name="qtnPricingNotes"
                          id="qtnPricingNotes"
                          placeholder=""
                          value={formInputs?.qtnPricingNotes || ""}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="unpricedCommercialQuoteArticle"
                          className="inputBox"
                          label="UNPRICED COMMERCIAL PROPOSAL"
                          type="file"
                          name="unpricedCommercialQuoteArticle"
                          placeholder=""
                          value={
                            formInputs?.unpricedCommercialQuoteArticle || ""
                          }
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">
                          Acceptance to Terms & Conditions
                        </p>
                        <div>
                          <input
                            type="radio"
                            name="gender"
                            value="Yes"
                            className="form-check-input me-2"
                            checked={selectedShipment === "Yes"}
                            // onChange={handleShipmentChange}
                            disabled={disabled}
                          />
                          <span className="me-2">Yes</span>
                          <input
                            type="radio"
                            name="gender"
                            value="No"
                            className="form-check-input me-2"
                            checked={selectedShipment === "No"}
                            // onChange={handleShipmentChange}
                            disabled={disabled}
                          />
                          <span className="me-2">No</span>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Quotation Notes"
                          type="text"
                          name="quotationNotes"
                          id="quotationNotes"
                          placeholder=""
                          value={formInputs?.quotationNotes || ""}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="commercialQuoteArticle"
                          className="inputBox"
                          label="COMMERIAL PROPOSAL"
                          type="file"
                          name="commercialQuoteArticle"
                          placeholder=""
                          value={formInputs?.commercialQuoteArticle}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 03 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PRLineItemInitialCol}
                          data={itemTableData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default DeliveryDetails;
