/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
  rfq: {
    data: [],
    status: true,
    rfxNr: "",
    workflowTECH: [],
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  purchase_requisition: {
    data: [],
    status: true,
    prNumber: "",
    workflowPR: [],
    approvedSelectedRows: [],
    dynamicFormDataCOMM: [],
    dynamicFormDataTECH: [],
    dynamicFormDataStatus: false,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  quotation: {
    data: [],
    status: true,
    rfxNr: "",
    quotationListInfo: "",
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  addRfx: {
    data: [],
    status: true,
  },
  status: true,
  purchaseDocType: "",
  purchaseDocCode: "",
  purchaseDocDescription: "",
  rfqCreation: "",
};

export const sourcingSlice = createSlice({
  name: "sourcing",
  initialState,
  reducers: {
    addPrDetailNumber(state, action) {
      state.purchase_requisition.prNumber = action.payload;
    },
    addPurchaseDocType(state, action) {
      state.purchaseDocType = action.payload.name;
      state.purchaseDocCode = action.payload.code;
      state.purchaseDocDescription = action.payload.description;
    },
    workflowPR(state, action) {
      state.purchase_requisition.workflowPR = action.payload;
    },
    addApprovedSelectedRows(state, action) {
      state.purchase_requisition.approvedSelectedRows = action.payload;
    },
    addDynamicFormDataCOMM(state, action) {
      state.purchase_requisition.dynamicFormDataCOMM = action.payload;
    },
    addDynamicFormDataTECH(state, action) {
      state.purchase_requisition.dynamicFormDataTECH = action.payload;
    },
    saveDynamicForm(state, action) {
      state.purchase_requisition.dynamicFormDataStatus = action.payload;
    },
    addRfqCreation(state, action) {
      state.rfqCreation = action.payload;
    },
    addRfxNr(state, action) {
      state.rfq.rfxNr = action.payload;
    },
    createQuotation(state, action) {
      state.quotation.rfxNr = action.payload;
    },
    quotationListInfo(state, action) {
      state.quotation.quotationListInfo = action.payload;
    },
    workflowTECH(state, action) {
      state.rfq.workflowTECH = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRfqQuotation.pending, (state, action) => {
        state.rfq.status = true;
      })
      .addCase(getRfqQuotation.fulfilled, (state, action) => {
        state.rfq.data = action.payload.data;
        state.rfq.pagination.page = action.payload.page;
        state.rfq.pagination.per_page = action.payload.per_page;
        state.rfq.pagination.total = action.payload.total;
        state.rfq.status = false;
      })
      .addCase(getPurchaseRequisition.pending, (state, action) => {
        state.purchase_requisition.status = true;
      })
      .addCase(getPurchaseRequisition.fulfilled, (state, action) => {
        state.purchase_requisition.data = action.payload.data;
        state.purchase_requisition.pagination.page = action.payload.page;
        state.purchase_requisition.pagination.per_page =
          action.payload.per_page;
        state.purchase_requisition.pagination.total = action.payload.total;
        state.purchase_requisition.status = false;
      })
      .addCase(getQuotation.pending, (state, action) => {
        state.quotation.status = true;
      })
      .addCase(getQuotation.fulfilled, (state, action) => {
        state.quotation.data = action.payload.data;
        state.quotation.pagination.page = action.payload.page;
        state.quotation.pagination.per_page = action.payload.per_page;
        state.quotation.pagination.total = action.payload.total;
        state.quotation.status = false;
      });
  },
});

export const {
  addPrDetailNumber,
  addPurchaseDocType,
  workflowPR,
  workflowTECH,
  addApprovedSelectedRows,
  addDynamicFormDataCOMM,
  addDynamicFormDataTECH,
  saveDynamicForm,
  addRfqCreation,
  addRfxNr,
  createQuotation,
  quotationListInfo,
} = sourcingSlice.actions;
export default sourcingSlice.reducer;

export const getRfqQuotation = createAsyncThunk(
  "getRfqQuotation",
  async (param) => {
    console.log(">> loaded getRfqQuotation");
    const res = await ItemService.getRfqQuotation(param);
    return res;
  }
);

export const getPurchaseRequisition = createAsyncThunk(
  "getPurchaseRequisition",
  async (param) => {
    console.log(">> loaded getPurchaseRequisition");
    const res = await ItemService.getPurchaseRequisition(param);
    return res;
  }
);

export const getQuotation = createAsyncThunk("getQuotation", async (param) => {
  console.log(">> loaded getQuotation");
  const res = await ItemService.getQuotation(param);
  return res;
});
