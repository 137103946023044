import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import AddFormModal from "../../../../Components/Modal/supplier_app/AddFormModal";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import DataTable from "react-data-table-component";
import CreateRFQSuppliers from "../../../../Components/Modal/supplier_app/Sourcing/CreateRFQSuppliers";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import {
  checkTimeDifference,
  isValidNumber,
} from "../../../../common/Validations";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import { MultiselectField } from "../../../../Components/formElements/MultiselectField";
import {
  addDynamicFormDataCOMM,
  addDynamicFormDataTECH,
  getRfqQuotation,
} from "../../../../redux/feature/supplier_app/sourcingSlice";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

const CreateManualRFQ = () => {
  const dispatch = useDispatch();

  let rfxCategoryType = useSelector((state) => state?.sourcing.purchaseDocType);
  let rfxCategoryCode = useSelector((state) => state?.sourcing.purchaseDocCode);
  let rfxCategoryDescription = useSelector(
    (state) => state?.sourcing.purchaseDocDescription
  );
  let rfqCreation = useSelector((state) => state?.sourcing.rfqCreation);
  let approvedSelectedRows = useSelector(
    (state) => state?.sourcing.purchase_requisition.approvedSelectedRows
  );
  let userData = useSelector((state) => state?.user?.data); // User Details from Store

  let config = useSelector((state) => state?.common?.data?.sourcing); // Common Details from Store

  // let dynamicFormDataStatus = useSelector(
  //   (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataStatus
  // ); // purchase_requisition from Store
  let dynamicFormDataCOMM = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataCOMM
  ); // purchase_requisition from Store
  let dynamicFormDataTECH = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataTECH
  ); // purchase_requisition from Store

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];
  let reminderDropDown = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible7, setCardVisible7] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible10, setCardVisible10] = useState(true);

  const [disabled, setDisabled] = useState(false);
  const [getDropdownData, setDropdownData] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [getpaymentTerm, setpaymentTerm] = useState([]);
  const [getRFxType, setRFxType] = useState([]);
  const [getRFxProductType, setRFxProductType] = useState([]);
  const [getRfxPurchaseType, setRfxPurchaseType] = useState([]);
  const [getRfxBidType, setRfxBidType] = useState([]);
  const [getCompanyAddress, setCompanyAddress] = useState([]);
  const [getBillingAddress, setBillingAddress] = useState([]);
  const [getDeliveryAddress, setDeliveryAddress] = useState([]);
  const [getINCOTerms, setINCOTerms] = useState([]);
  const [getcurrency, setcurrency] = useState([]);
  const [getTechnicalApprovalType, setTechnicalApprovalType] = useState([]);
  const [getRfxNoOfEvaluationType, setRfxNoOfEvaluationType] = useState([]);
  const [getPlant, setPlant] = useState([]);
  const [getTechnicalUserList, setTechnicalUserList] = useState([]);

  const [getLineItemData, setLineItemData] = useState([]);
  const [getCreateDynamicformModelShow, setCreateDynamicformModelShow] =
    useState(false);
  const [getFormModalData, setFormModalData] = useState({});

  const [formInputs, setFormInputs] = useState({
    rfxType: "",
    productType: "",
    rfxValue: "",
    rfxHeaderStatus: "RFQ Created",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    companyAddress: "", //i did'nt get in json
    rfxVersion: "V0",
    buyerEmail: userData?.email,
    initiatorName: userData?.firstName,
    initiator: userData?.userId,
    buyerResponsible:userData?.userId,
    createdOn: "", //i did'nt get in json
    buyerConact: userData?.contactNumber,
    deliveryAddressCodeDesc: "",
    rfxCategoryType: rfxCategoryType,
    rfxCategoryCode: rfxCategoryCode,
    rfxCategoryDesc: rfxCategoryDescription,
    rfqCreation: rfqCreation,
    rfxTitle: "",
    openingDate: "",
    openingTime: "",
    closingDate: "",
    closingTime: "",
    rfxDescription: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",

    openingDateTime: "",
    closingDateTime: "",
    totalHour: "",
  });

  const [getOpenCloseInput, setOpenCloseInput] = useState({
    openingDate: "",
    openingTime: "",
    closingDate: "",
    closingTime: "",
    totalHour: "",
    status: false,
    msg:'',
  });
  const modalData = {
    title: "RFQ Creation",
    successMsg: "",
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility7 = () => {
    setCardVisible7(!isCardVisible7);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility10 = () => {
    setCardVisible10(!isCardVisible10);
  };
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  // This is for Text Editor
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const removeSupplierRow = (AssociateNumber) => {
    setSupplierData((prevPrograms) =>
      prevPrograms.filter((program) => program.AssociateNumber !== AssociateNumber)
    );
  };

  const suppliersColumns = [
    {
      name: "Delete",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => removeSupplierRow(row.AssociateNumber)}
        >
          <i className="bi bi-trash3"></i>
        </button>
      ),
      width: "120px",
    },
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          {row.status === "Non Invited" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.status}
            </span>
          ) : row.status === "Invited" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.status}
            </span>
          ) : row.status === "In Registration" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.status}
            </span>
          ) : row.status === "Registered" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.status}
            </span>
          ) : row.status === "Reject" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.status}
            </span>
          ) : (
            row.status
          )}
        </>
      ),
      width: "170px",
    },

    {
      name: "Supplier openDate",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactfirstName || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Contact Number",
      selector: (row) => row.contactNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "EMail",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "initiator",
      selector: (row) => row.initiator || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "ICV Score %",
      selector: (row) => row.totalScore || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Category",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            // onClick={() => categoryItemShowModal(row.vendorCategory)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.vendorCategory?.length || 0}{" "}
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "160px",
    },
    {
      name: "Company Code",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      width: "150px",
    },
  ];

  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);
  const [getFailureModal] = useState(failureModalData);

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">>datarfq", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  /* submit button */
  const handleCreateManualRFQ = async () => {
    let requiredFields = [];

    if (formInputs.rfxValue === "") {
      requiredFields.push("* Please enter the Estimated RFQ Value.");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (getSupplierData.length === 0) {
      requiredFields.push("* Add atleast one supplier.");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (dynamicFormDataCOMM.length === 0) {
      requiredFields.push("*Add atleast one Form: COMM- Spares.");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (dynamicFormDataTECH.length === 0) {
      requiredFields.push("* Add atleast one Form : TECH - Spares.");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (getOpenCloseInput.status === false) {
      if(getOpenCloseInput.msg === ''){
        requiredFields.push("* Please update Opening and Closing Date, Time");
      }else{
        requiredFields.push(getOpenCloseInput.msg);
      }

      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    formInputs.items =
      rfqCreation === "PR" ? getLineItemData : getPRLineItemsData;
    formInputs.supplierList = getSupplierData;
    formInputs.termsConditions = termsConditionsData;
    formInputs.instructions = editorContent;
    formInputs.formCOMM = dynamicFormDataCOMM;
    formInputs.formTECH = dynamicFormDataTECH;

    // open and close date
    formInputs.openingDateTime =
      getOpenCloseInput.openingDate + " " + getOpenCloseInput.openingTime;
    formInputs.closingDateTime =
      getOpenCloseInput.closingDate + " " + getOpenCloseInput.closingTime;
    formInputs.totalHour = getOpenCloseInput.totalHour;

    formInputs.openingDate = getOpenCloseInput.openingDate;
    formInputs.openingTime = getOpenCloseInput.openingTime;
    formInputs.closingDate = getOpenCloseInput.closingDate;
    formInputs.closingTime = getOpenCloseInput.closingTime;

    // console.log(">> handleCreateManualRFQ", formInputs);

    let obj = {
      data: formInputs,
    };
    setDisabled(false);
    await ItemService.addRfx(obj).then((item) => {
      // console.log(">> item! ", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        dispatch(getRfqQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setDisabled(true);
  };

  /* suppplierModalShow */
  const [getSupplierModal, setSupplierModal] = useState(false);
  const suppplierModalShow = () => {
    setSupplierModal(true);
  };
  const supplerMoadHide = () => {
    setSupplierModal(false);
  };
  /* selectedSupplierIds */
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [getSupplierData, setSupplierData] = useState([]);
  const handleSelectedRows = (selectedRows) => {
    setSelectedSuppliers(selectedRows);
  };

  const handlePassDataToParent = (data) => {
    // console.log("Data received from child:", data);
    // You can now use this data in the parent component
   
    setSupplierData((prevData) => {
      // Combine previous data and filter out duplicates based on id
      const updatedData = [
        ...prevData,
        ...data.filter(newObj => 
          !prevData.some(existingObj => existingObj.AssociateNumber === newObj.AssociateNumber)
        )
      ];
    
      return updatedData;
    });

  };

  const [termsConditionsData, setTermsConditionsData] = useState([
    {
      id: 1,
      name: "Terms and Conditions",
      status: true,
      disabled: true
    },
    {
      id: 2,
      name: "Code of Conduct",
      status: false,
    },
    // {
    //   id: 3,
    //   name: "HSE Requirements",
    //   status: false,
    // },
    // {
    //   id: 4,
    //   name: "Engineering Standards",
    //   status: false,
    // },
    {
      id: 3,
      name: "Packaging Requirement",      
      status: false,
    },
    {
      id: 4,
      name: "Others",
      status: false,
    },
    // {
    //   id: 7,
    //   name: "Trucking H&S Requirements",
    //   status: false,
    // },
  ]);

  const handleTermsConditionCheckBox = (id) => {
    // Update the status of the item based on its id
    const updatedData = termsConditionsData.map((item) => {
      if (item.id === id) {
        return { ...item, status: !item.status };
      }
      return item;
    });
    // Update the state with the modified data
    setTermsConditionsData(updatedData);
  };

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service ",
      selector: (row) => row.materialCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialCode"
          placeholder=""
          value={row.materialCode || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription || "--",
      cell: (row) => (
        <TextAreaField
          className="inputBox mt-1"
          name="materialDescription"
          placeholder=""
          value={row.materialDescription || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="materialGroup"
          placeholder=""
          value={row.materialGroup || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderUnitOfMeasure"
          placeholder=""
          value={row.orderUnitOfMeasure || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Unit Price ",
      selector: (row) => row.orderPriceUnit || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          onChange={(event) => handleInputChange(row.local_id, event, "Number")}
        />
      ),
      width: "200px",
    },
    {
      name: "Item Value ",
      selector: (row) => row.netValue || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="netValue"
          placeholder=""
          value={row.netValue || 0}
          disabled={true}
        />
      ),
      width: "200px",
    },
    {
      name: "Currency ",
      selector: (row) => row.currencyCode || "INR",
      width: "200px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxAmount"
          placeholder=""
          value={row.taxAmount || 0}
          disabled={true}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "VAT Code % ",
      selector: (row) => row.taxCode || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="taxCode"
          placeholder=""
          value={row.taxCode}
          onChange={(event) =>
            handleInputChange(row.local_id, event, "Number", "VAT")
          }
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="plant"
          placeholder=""
          value={row.plant || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Storage Location",
      selector: (row) => row.storageLocn || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          // label="Please Enter the Remarks*"
          name="storageLocn"
          placeholder=""
          value={row.storageLocn || ""}
          onChange={(event) => handleInputChange(row.local_id, event)}
        />
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btn btn-danger"
          onClick={() => removePOItemRow(row.local_id)}
        >
          <i className="bi bi-trash3"></i>
        </button>
      ),
      width: "120px",
    },
  ];
  let PRLineItemInitialData = [
    {
      local_id: 1,
      rfxNr: 1,
      itemTextData: [],
      statusItem: "In Release",
      // currency: getCurrencyDropdown.name || "Rupee",
      // currencyCode: getCurrencyDropdown.code || "INR",
      currency: "Rupee",
      currencyCode: "INR",
      taxCode: 0,
      taxAmount: 0,
    },
  ];

  // PO items columns Details
  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // Show Item level data
  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Item Text Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  const [getPRLineItemsData, setPRLineItemsData] = useState(
    PRLineItemInitialData
  );

  const handleInputChange = (local_id, event, type, vat) => {
    const { name, value } = event.target;
    if (type === "Number" && vat === undefined) {
      if (isValidNumber(value)) {
        setPRLineItemsData((prevRows) =>
          prevRows.map((row) => {
            return row.local_id === local_id
              ? {
                  ...row,
                  [name]: value,
                  netValue:
                    name === "purchaseQuantity"
                      ? row.orderPriceUnit * value
                      : row.purchaseQuantity * value,
                  taxAmount:
                    (Number(row.taxCode) / 100) *
                    (name === "purchaseQuantity"
                      ? row.orderPriceUnit * value
                      : row.purchaseQuantity * value),
                }
              : row;
          })
        );
      }
    } else if (type === "Number" && vat === "VAT") {
      if (isValidNumber(value)) {
        setPRLineItemsData((prevRows) =>
          prevRows.map((row) =>
            row.local_id === local_id
              ? {
                  ...row,
                  [name]: value,
                  taxAmount:
                    name === "taxCode"
                      ? (Number(value) / 100) *
                        (Number(row.purchaseQuantity || 0) *
                          Number(row.orderPriceUnit || 0))
                      : 0,
                }
              : row
          )
        );
      }
    } else {
      setPRLineItemsData((prevRows) =>
        prevRows.map((row) =>
          row.local_id === local_id ? { ...row, [name]: value } : row
        )
      );
    }
    // getTotalCount()
  };

  // Add or Remove Line Item
  const addPOItemRow = () => {
    setPRLineItemsData((prevRows) => {
      PRLineItemInitialData[0].local_id = prevRows.length + 1;
      PRLineItemInitialData[0].rfxNr = prevRows.length + 1; // make it to 3 digit 001, 002
      return [...prevRows, ...PRLineItemInitialData];
    });
  };
  const removePOItemRow = (local_id) => {
    setPRLineItemsData((prevPrograms) =>
      prevPrograms.filter((program) => program.local_id !== local_id)
    );
  };

  //Load Master Data
  const getMasterDataFunc = async () => {
    let obj1 = {
      codeType: "paymentTerm",
    };
    await ItemService.getMasterData(obj1).then((item) => {
      if (item?.status === 200) {
        setpaymentTerm(item?.data);
      } else {
        setpaymentTerm([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj2 = {
      codeType: "RFxType",
    };
    await ItemService.getMasterData(obj2).then((item) => {
      if (item?.status === 200) {
        setRFxType(item?.data);
      } else {
        setRFxType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj3 = {
      codeType: "RFxProductType",
    };
    await ItemService.getMasterData(obj3).then((item) => {
      if (item?.status === 200) {
        setRFxProductType(item?.data);
      } else {
        setRFxProductType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj4 = {
      codeType: "CompanyAddress",
    };
    await ItemService.getMasterData(obj4).then((item) => {
      if (item?.status === 200) {
        setCompanyAddress(item?.data);
      } else {
        setCompanyAddress([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj5 = {
      codeType: "BillingAddress",
    };
    await ItemService.getMasterData(obj5).then((item) => {
      if (item?.status === 200) {
        setBillingAddress(item?.data);
      } else {
        setBillingAddress([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj6 = {
      codeType: "DeliveryAddress",
    };
    await ItemService.getMasterData(obj6).then((item) => {
      if (item?.status === 200) {
        setDeliveryAddress(item?.data);
      } else {
        setDeliveryAddress([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj7 = {
      codeType: "currency",
    };
    await ItemService.getMasterData(obj7).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setcurrency(item?.data);
      } else {
        setcurrency([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj8 = {
      codeType: "INCOTerms",
    };
    await ItemService.getMasterData(obj8).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setINCOTerms(item?.data);
      } else {
        setINCOTerms([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj9 = {
      codeType: "RfxPurchaseType",
    };
    await ItemService.getMasterData(obj9).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setRfxPurchaseType(item?.data);
      } else {
        setRfxPurchaseType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj10 = {
      codeType: "RfxBidType",
    };
    await ItemService.getMasterData(obj10).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setRfxBidType(item?.data);
      } else {
        setRfxBidType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj11 = {
      codeType: "TechnicalApprovalType",
    };
    await ItemService.getMasterData(obj11).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setTechnicalApprovalType(item?.data);
      } else {
        setTechnicalApprovalType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj12 = {
      codeType: "RfxNoOfEvaluationType",
    };
    await ItemService.getMasterData(obj12).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setRfxNoOfEvaluationType(item?.data);
      } else {
        setRfxNoOfEvaluationType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj13 = {
      codeType: "Plant",
    };
    await ItemService.getMasterData(obj13).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setPlant(item?.data);
      } else {
        setPlant([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };
  const loadLineItem = async () => {
    let obj6 = {
      purchaseReqDocumentNr: approvedSelectedRows,
    };
    await ItemService.getPRLineItems(obj6).then((item) => {
      if (item?.status === 200) {
        const updatedItemData = item?.data.map((obj, index) => ({
          ...obj,
          rfxItemNr: index + 1,
          local_id: index + 1,
        }));
        setLineItemData(updatedItemData);
      } else {
        setLineItemData([]);
        console.log(">> Error: Loading Line Items");
      }
    });
  };
  const loadTechUser = async () =>{
    let obj = {
      "roleId" : "TECHNICAL"
    };
    await ItemService.getTechnicalUser(obj).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setTechnicalUserList(item?.data);
      } else {
        setTechnicalUserList([]);
        console.log(">> Error: Loading loadTechUser");
      }
    });
  }


  useEffect(() => {
    if (rfqCreation !== "") {
      getMasterDataFunc();
      loadLineItem();
      loadTechUser();

      if (rfqCreation === "PR") {
        localStorage.setItem("menu", "Sourcing");
        localStorage.setItem("submenu", "Purchase Requisition");
      } else {
        localStorage.setItem("menu", "Sourcing");
        localStorage.setItem("submenu", "Request for Quotation");
      }
    } else {
      window.history.back();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDynamicformModelCOMM = () => {
    let obj = {
      formType: "COMM",
    };
    setCreateDynamicformModelShow(true);
    setFormModalData(obj);
  };
  const createDynamicformModelTECH = () => {
    let obj = {
      formType: "TECH",
    };
    setCreateDynamicformModelShow(true);
    setFormModalData(obj);
  };

  const hideModalStatus = () => {
    setCreateDynamicformModelShow(false);
  };

  // Render Questions
  const RenderInputField = ({ questionData }) => {
    const { componentId, mandatory, question, answerDefinition, serialNo } =
      questionData;

    switch (answerDefinition?.type) {
      case "textarea":
        return (
          <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
            <TextAreaField
              label={serialNo + ". " + question?.label}
              id={componentId}
              name={componentId}
              className="inputBox"
              placeholder=""
              // value={getRemarks}
              // onChange={handleInputChange}
              required={mandatory}
            />
          </Col>
        );
      case "select":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <ComboboxField
              label={serialNo + ". " + question?.label}
              placeholder=""
              data={answerDefinition?.options}
              id={componentId}
              iconClassName="dropdownIcon"
              name={componentId}
              code={componentId}
              required={mandatory}
              // setValue={formData.type || ""}
              // getvalue={setDropdownData}
            />
          </Col>
        );
      case "radio":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="radio"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "checkbox":
        return (
          <Col xs={12} md={6} lg={6} className="commTopButtonRightLeftPadding">
            <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
              {serialNo + ". " + question?.label} {mandatory && "*"}
            </label>

            {answerDefinition?.options.map((option, index) => {
              return (
                <div key={index} className="block">
                  <input
                    type="checkbox"
                    className="form-check-input fs-5 ml-3"
                    id={`${componentId}-${index}`}
                    name={componentId}
                    // value={option?.value}
                    required={mandatory}
                  />
                  <span className="ml-3">{option?.value} </span>
                </div>
              );
            })}
          </Col>
        );
      case "text":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="text"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      case "number":
        return (
          <Col
            xs={12}
            md={6}
            lg={6}
            className="commTopButtonRightLeftPadding block"
          >
            <InputField
              label={serialNo + ". " + question?.label}
              type="number"
              className="inputBox"
              id={componentId}
              name={componentId}
              placeholder=""
              // value={option.value}
              // onChange={(e) =>
              //   handleOptionChange(index, e.target.value)
              // }
              required={mandatory}
            />
          </Col>
        );
      default:
        return "";
    }
  };

  const onInputChangeopeningDate = ({ target: { name, value } }) => {
    setOpenCloseInput((getOpenCloseInput) => ({
      ...getOpenCloseInput,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (
      getOpenCloseInput.openingDate !== "" ||
      getOpenCloseInput.openingTime !== "" ||
      getOpenCloseInput.closingDate !== "" ||
      getOpenCloseInput.closingTime !== ""
    ) {
      let openDate =
        getOpenCloseInput.openingDate + " " + getOpenCloseInput.openingTime;
      let closeDate =
        getOpenCloseInput.closingDate + " " + getOpenCloseInput.closingTime;
      let res = checkTimeDifference(openDate, closeDate);
      // console.log(">> res", res);
      getOpenCloseInput.status = res.status;
      getOpenCloseInput.msg = res.msg + " '(Opening and Closing Fields)' !!";

      if (getOpenCloseInput.closingDate !== "") {
        if (res.status) {
          getOpenCloseInput.totalHour = res.totalHours;
        } else {
          toast.error(res.msg, {
            duration: 4000,
            position: "top-right",
          });
        }
      }
    }
  }, [getOpenCloseInput]);

  // Set Default Form value
  useEffect(() => {
    if (config) {
      dispatch(addDynamicFormDataCOMM(config?.RFX?.formCOMM));
      dispatch(addDynamicFormDataTECH(config?.RFX?.formTECH));
    } else {
      toast.error("Error while getting the default form value !!", {
        duration: 4000,
        position: "top-right",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create {rfqCreation} RFQ</h2>
            <p className="subText">Fill RFQ Details</p>
            <hr />
            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RT</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">RFQ Type</span>
                      <span className="subHeaderTitle">Basic Information</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="RFQ Type"
                          placeholder=""
                          data={getRFxType}
                          id="rfxType"
                          iconClassName="dropdownIcon"
                          name="rfxType"
                          code="rfxTypeCode"
                          description="rfxTypeDesc"
                          setValue={formInputs.rfxTypeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Product Type"
                          placeholder=""
                          data={getRFxProductType}
                          id="productType"
                          iconClassName="dropdownIcon"
                          name="productType"
                          code="productTypeCode"
                          description="productTypeDesc"
                          setValue={formInputs.productTypeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Estimated RFQ Value *"
                          id="rfxValue"
                          name="rfxValue"
                          placeholder=""
                          // type="checkbox"
                          value={formInputs.rfxValue || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Bid Type"
                          placeholder=""
                          data={getRfxBidType}
                          id="rfxbidType"
                          iconClassName="dropdownIcon"
                          name="rfxbidType"
                          code="rfxbidTypeCode"
                          description="rfxbidTypeDesc"
                          setValue={formInputs.rfxbidTypeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Number Of Evaluation"
                          placeholder=""
                          data={getRfxNoOfEvaluationType}
                          id="rfxNoOfEvaluationType"
                          iconClassName="dropdownIcon"
                          name="rfxNoOfEvaluationType"
                          code="rfxNoOfEvaluationTypeCode"
                          description="rfxNoOfEvaluationTypeDesc"
                          setValue={formInputs.rfxNoOfEvaluationTypeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Currency"
                          placeholder=""
                          data={getcurrency}
                          id="rfxCurrency"
                          iconClassName="dropdownIcon"
                          name="rfxCurrency"
                          code="rfxCurrencyCode"
                          description="rfxCurrencyDesc"
                          setValue={formInputs.rfxCurrency || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Tech Focal Point *"
                          placeholder=""
                          data={getTechnicalUserList}
                          id="rfxTechFocalPersonId"
                          iconClassName="dropdownIcon"
                          name="rfxTechFocalPersonId"
                          code="rfxTechFocalPersonIdCode"
                          description="rfxTechFocalPersonIdDesc"
                          setValue={formInputs.rfxTechFocalPersonId || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Technical Approval Type"
                          placeholder=""
                          data={getTechnicalApprovalType}
                          id="technicalApprovalType"
                          iconClassName="dropdownIcon"
                          name="technicalApprovalType"
                          code="technicalApprovalTypeCode"
                          description="technicalApprovalTypeDesc"
                          setValue={formInputs.technicalApprovalTypeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RH</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">RFQ Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="buyerResponsible"
                          placeholder=""
                          value={formInputs.buyerResponsible || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Create / Initiator"
                          name="initiator"
                          placeholder=""
                          value={formInputs.initiator || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Plant"
                          placeholder=""
                          data={getPlant}
                          id="plant"
                          iconClassName="dropdownIcon"
                          name="plant"
                          code="plantCode"
                          description="plantDesc"
                          setValue={formInputs.plant || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Company Address"
                          placeholder=""
                          data={getCompanyAddress}
                          id="companyAddress"
                          iconClassName="dropdownIcon"
                          name="companyAddress"
                          code="companyAddressCode"
                          description="companyAddressDesc"
                          setValue={formInputs.companyAddress || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Version"
                          name="rfxVersion"
                          id="rfxVersion"
                          placeholder=""
                          value={formInputs.rfxVersion || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Email"
                          name="buyerEmail"
                          id="buyerEmail"
                          placeholder=""
                          value={formInputs.buyerEmail || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Billing Address"
                          placeholder=""
                          data={getBillingAddress}
                          id="billingAddress"
                          iconClassName="dropdownIcon"
                          name="billingAddress"
                          code="billingAddressCode"
                          description="billingAddressDesc"
                          setValue={formInputs.billingAddress || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Created On"
                          name="createdOn"
                          id="createdOn"
                          placeholder=""
                          value={formInputs.createdOn || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="buyerConact"
                          id="buyerConact"
                          placeholder=""
                          value={formInputs.buyerConact || ""}
                          onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Delivery Address"
                          placeholder=""
                          data={getDeliveryAddress}
                          id="deliveryAddressCodeDesc"
                          iconClassName="dropdownIcon"
                          name="deliveryAddressCodeDesc"
                          setValue={formInputs.deliveryAddressCodeDesc || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Category"
                          name="rfxCategory"
                          id="rfxCategory"
                          placeholder=""
                          value={formInputs.rfxCategoryType}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* header info 03*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Header Info</span>
                      <span className="subHeaderTitle">Header Information</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title"
                          name="rfxTitle"
                          id="rfxTitle"
                          placeholder=""
                          value={formInputs.rfxTitle || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Date"
                          name="openingDate"
                          id="openingDate"
                          type="date"
                          placeholder=""
                          value={getOpenCloseInput.openingDate || ""}
                          onChange={onInputChangeopeningDate}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Time"
                          name="openingTime"
                          id="openingTime"
                          type="time"
                          placeholder=""
                          value={getOpenCloseInput.openingTime || ""}
                          onChange={onInputChangeopeningDate}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Description"
                          name="rfxDescription"
                          id="rfxDescription"
                          placeholder=""
                          value={formInputs.rfxDescription || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Date"
                          name="closingDate"
                          id="closingDate"
                          type="date"
                          placeholder=""
                          value={getOpenCloseInput.closingDate || ""}
                          onChange={onInputChangeopeningDate}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Time"
                          name="closingTime"
                          id="closingTime"
                          type="time"
                          placeholder=""
                          value={getOpenCloseInput.closingTime || ""}
                          onChange={onInputChangeopeningDate}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Type of Purchase"
                          placeholder=""
                          data={getRfxPurchaseType}
                          id="typeOfPurchase"
                          iconClassName="dropdownIcon"
                          name="typeOfPurchase"
                          setValue={formInputs.typeOfPurchase || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <MultiselectField
                          label="Other Currencies"
                          placeholder="Please select the Other Currencies"
                          data={getcurrency}
                          id="othercurrencies"
                          iconClassName="dropdownIcon"
                          name="othercurrencies"
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment Term"
                          placeholder=""
                          data={getpaymentTerm}
                          id="otherCurrencies"
                          iconClassName="dropdownIcon"
                          name="otherCurrencies"
                          setValue={formInputs.otherCurrencies || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <MultiselectField
                          label="INCO Terms"
                          placeholder="Please select the INCO Terms"
                          data={getINCOTerms}
                          id="INCOTerms"
                          iconClassName="dropdownIcon"
                          name="INCOTerms"
                          getvalue={setDropdownData}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="INCO Terms Location"
                          name="incoTermsLocation"
                          id="incoTermsLocation"
                          placeholder=""
                          setValue={formInputs.incoTermsLocation || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Procurement Strategy"
                          placeholder=""
                          data={dropdownOption}
                          id="procurementStrategies"
                          iconClassName="dropdownIcon"
                          name="procurementStrategies"
                          setValue={formInputs.procurementStrategies || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Milestone Payments"
                          placeholder=""
                          data={dropdownOption}
                          id="milestonepayments"
                          iconClassName="dropdownIcon"
                          name="milestonepayments"
                          setValue={formInputs.milestonepayments || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        {" "}
                        {/*   <span> Instructions Attachment</span>
                        <input type="file" id="myfile" name="myfile" /> */}
                        <InputField
                          className="inputBox"
                          label="Instructions Attachment"
                          name=""
                          // placeholder=""
                          // value={PriceSheduleModuledata.UnitPrice || ""}
                          type="file"
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Reminder Enabled"
                          placeholder=""
                          data={reminderDropDown}
                          id="incoTermsLocation"
                          iconClassName="dropdownIcon"
                          name="reminderEnabled"
                          setValue={formInputs.reminderEnabled || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Instructions 10*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible10
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility10}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">RE</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Instructions</span>
                      <span className="subHeaderTitle">Instructions Section</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible10 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="p-4">
                      <Col xs={12} md={12} lg={12}>
                        <RichTextEditor
                          value={editorContent}
                          onChange={handleEditorChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Documents 05 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TD</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Documents</span>
                      <span className="subHeaderTitle">
                        Documents Form
                      </span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <h1 className="card-title">Documents :</h1>
                        <Row>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="1.1. SCOPE OF WORK"
                              name="scopeOfwork"
                              id="scopeOfwork"
                              placeholder=""
                              type="file"
                              value={formInputs.scopeOfwork || ""}
                              onChange={onInputChange}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="1.2. DRAWING"
                              name="drawing"
                              id="drawing"
                              placeholder=""
                              type="file"
                              value={formInputs.drawing || ""}
                              onChange={onInputChange}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="1.3. ANNEXURES"
                              name="annexures"
                              id="annexures"
                              placeholder=""
                              type="file"
                              value={formInputs.annexures || ""}
                              onChange={onInputChange}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="1.4. OTHERS"
                              name="others"
                              id="others"
                              placeholder=""
                              type="file"
                              value={formInputs.others || ""}
                              onChange={onInputChange}
                            />
                          </Col>
                        </Row>
                        <h5 className="card-title"> Terms & Conditions</h5>
                        <Row>
                          <Col
                            xs={12}
                            md={12}
                            lg={8}
                            className="commTopButtonRightLeftPadding"
                          >
                            {termsConditionsData.map((item, i) => (
                              <div className="form-check" key={item.id}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`gridCheck${item.id}`}
                                  checked={item.status}
                                  onChange={() =>
                                    handleTermsConditionCheckBox(item.id)
                                  }
                                  disabled={item?.disabled}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`gridCheck${item.id}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            ))}
                          </Col>
                          <Col xs={12} md={12} lg={3}>
                            <ComboboxField
                              label="Acceptance To Terms & Conditions"
                              placeholder=""
                              data={dropdownOption}
                              id=""
                              iconClassName="dropdownIcon"
                              name=""
                              setValue={formInputs.INCOTermslocation || ""}
                              getvalue={setDropdownData}
                              // className="dropdownOption"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Items 06*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={8} md={4} lg={4}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle">
                        Material/ Service Details
                      </span>
                    </Col>

                    <Col xs={12} md={6} lg={6} className="headerButtonAlign">
                      <Col xs={4} md={3} lg={3} className="">
                        <button className="btnTable">Download Template</button>
                      </Col>
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("asd");
                          }}
                        >
                          Upload Date
                        </button>
                      </Col>
                      <Col xs={4} md={2} lg={2} className="">
                        <button className="btnTable" onClick={addPOItemRow}>
                          Add PR Items
                        </button>
                      </Col>
                    </Col>
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PRLineItemInitialCol}
                          data={
                            formInputs?.rfqCreation === "PR"
                              ? getLineItemData
                              : getPRLineItemsData
                          }
                          // columns={lineItemsColumns}
                          // data={lineItemsData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Suppliers 07*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible7
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility7}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={8} md={4} lg={4}>
                      <span className="headerTitle">Suppliers * </span>
                      <span className="subHeaderTitle">
                        Material/ Service Details
                      </span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={suppplierModalShow}
                        >
                          Add Supplier
                        </button>
                      </Col>
                      <Col xs={5} md={3} lg={3} className="">
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("Need to Implement");
                          }}
                        >
                          Initiate Supplier Request
                        </button>
                      </Col>
                      {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible7 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={suppliersColumns}
                          data={getSupplierData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* COMM - Spares & Operation Maintenance 08*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">
                        Form: COMMERCIAL - Questionnaire
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>

                    <Col xs={12} md={5} lg={5} className="headerButtonAlign">
                      <Col xs={6} md={4} lg={4} className="">
                        <button
                          className="btnTable"
                          onClick={createDynamicformModelCOMM}
                        >
                          <i className="bi bi-plus-lg"></i>{" "}
                          {dynamicFormDataCOMM.length > 0
                            ? "Modify Form"
                            : "Generate Form"}
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {dynamicFormDataCOMM.length > 0 &&
                        dynamicFormDataCOMM.map((formJsonData, index) => {
                          return (
                            <RenderInputField
                              questionData={formJsonData}
                              key={index}
                            />
                          );
                        })}

                      {dynamicFormDataCOMM.length === 0 && (
                        <p className="text-center mt-4">
                          {" "}
                          Please Generate Form !!
                        </p>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Form :TECH - Spares & Operation Maintenance09*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible9
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility9}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TS</span>
                    </Col>

                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">
                        Form : TECHNICAL - Questionnaire
                      </span>
                    </Col>

                    <Col xs={12} md={5} lg={5} className="headerButtonAlign">
                      <Col xs={6} md={4} lg={4} className="">
                        <button
                          className="btnTable"
                          onClick={createDynamicformModelTECH}
                        >
                          <i className="bi bi-plus-lg"></i>{" "}
                          {dynamicFormDataTECH.length > 0
                            ? "Modify Form"
                            : "Generate Form"}
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible9 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {dynamicFormDataTECH.length > 0 &&
                        dynamicFormDataTECH.map((formJsonData, index) => {
                          return (
                            <RenderInputField
                              questionData={formJsonData}
                              key={index}
                            />
                          );
                        })}

                      {dynamicFormDataTECH.length === 0 && (
                        <p className="text-center mt-4">
                          {" "}
                          Please Generate Form !!
                        </p>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={{ span: 2, offset: 10 }}
          lg={{ span: 2, offset: 10 }}
          className="mb-2"
        >
          <button
            disabled={disabled}
            className="btnTable"
            onClick={handleCreateManualRFQ}
          >
            Create Manual RFQ
          </button>
        </Col>
      </Row>

      <CreateRFQSuppliers
        show={getSupplierModal}
        onHide={supplerMoadHide}
        selectedSuppliers={selectedSuppliers}
        onSelectedRows={handleSelectedRows}
        passDataToParent={handlePassDataToParent}
      />
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_BUYER_REQUEST_FOR_QUOTATION}
      />

      <AddFormModal
        show={getCreateDynamicformModelShow}
        onHide={() => hideModalStatus()}
        modeldata={getFormModalData}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default CreateManualRFQ;
