/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
    data:[],
    category_selection:{
        data:[],
        status:true,
    },
    selectedCategory:[],
    status:true,
    supplierID:'',
    workflow:{},
    accountGroup:'',
    pagination:{
        page:"",
        per_page:"",
        total:""
    }
};

export const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        addCategorySelectedData(state, action){
          state.selectedCategory = action.payload || [];
        },
        addsupplierID(state, action){
            state.supplierID = action.payload
        },
        workflow(state, action){
            state.workflow = action.payload
        },
        addAccountGroup(state, action){
            state.accountGroup = action.payload
        }
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getSupplierList.pending, (state,action)=>{
            state.status = true;
        })
        .addCase(getSupplierList.fulfilled, (state,action)=>{
            state.data = action.payload.data;
            state.pagination.page = action.payload.page;
            state.pagination.per_page = action.payload.per_page;
            state.pagination.total = action.payload.total;
            state.status = false;
        })
        .addCase(getSupplierCategorySelection.fulfilled, (state,action)=>{
            state.category_selection.data = action.payload;
            state.status = false;
        })
    }
  });

export const { addCategorySelectedData, addsupplierID, addAccountGroup, workflow } = supplierSlice.actions;
export default supplierSlice.reducer;

export const getSupplierList = createAsyncThunk('getSupplierList', async (param) =>{
    console.log(">> loaded getSupplierList");
    const res = await ItemService.getSupplierList(param);
    return res;
})
export const getSupplierCategorySelection = createAsyncThunk('getSupplierCategorySelection', async () =>{
    console.log(">> loaded getSupplierCategorySelection");
    const res = await ItemService.getSupplierCategorySelection();
    return res.data;
})
