import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
//Redux
import { useSelector } from "react-redux"; //useDispatch


const CutomerQuotationPrint = React.forwardRef((props, ref) => {

  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store

  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "4px",
  };
  const printbody = {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  };
  const { data } = props;
  const items = data?.items || [];
  // const createdDate=data?.createdAt.timestamp.split("T")[0];
  const termsConditions = useMemo(
    () => data?.termsConditions || [],
    [data?.termsConditions]
  );

  const [getTermsAndCondition, setTermsAndCondition] = useState([]);

  useEffect(() => {
    const filtered = termsConditions.filter((item) => item.status === true);
    setTermsAndCondition(filtered);
  }, [termsConditions]);
  const currentYear = new Date().getFullYear();
  const preiousYear = currentYear - 1;

  return (
    <div ref={ref} style={printbody} className="m-4">
      <Row>
        <Col
          lg={12}
          className="d-flex justify-content-center g-2 align-items-end"
        >
          <img
            src="./assets/img/BharthiCementLogo/BharthiCementLogo.png"
            alt="bharthiCementlog"
          />

          <p className="fs-2 fw-bold m-0">
            {appConfig?.customerName}
          </p>
          <img
            src="./assets/img/BharthiCementLogo/vicatLogo.png"
            alt="vicatlogo"
          />
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-center">
          <p className="fw-bold text-center mt-2">
            Reg Office: No.88,1st floor,Keerthi,2nd main,Palace Guttahalli,
            Bangalore – 560 003.
          </p>
          <p className="fw-bold text-center">
            Plant Location: Kanivenarayanapura, Chickballapur Dist.
          </p>
        </Col>
        <Col>
          <p className="fs-5 fw-bold m-0">
            Qtn No.: {data?.documentNumber}/NCC/Auto number-R1/ {preiousYear}-
            {currentYear}
          </p>
          <br />
          <p className="fs-5 fw-bold m-0">
            Date: {data?.createdAt?.split("T")[0]}
          </p>
          <p className="fs-5 fw-bold m-0"> {data?.address}</p>
          <p className="fs-5 fw-bold m-0">{data?.address2}</p>
          <p className="fs-5 fw-bold">
            {data?.city} - {data?.cityPostalCode}.
          </p>
          <p className="fs-5 fw-bold m-0">Dear Sir,</p>
          <p className="fs-5 fw-bold  text-center">
            {" "}
            SUB: Quotation For Supply Of {appConfig?.customerName}.
          </p>
          <p className="fs-5 fw-bold m-0">
            With reference to your enquiry, we are pleased to offer our
            quotation as given below:
          </p>
        </Col>
      </Row>
      <table
        style={{ marginTop: "16px", width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr style={tableStyle}>
            <th style={tableStyle}>SL No</th>
            <th style={tableStyle}>Material</th>
            <th style={tableStyle}>Quote Price</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) => (
            <tr key={item.local_id} style={tableStyle}>
              <td style={tableStyle} className="text-end">
                {item.local_id}
              </td>
              <td style={tableStyle}>{item.materialCode}</td>
              <td style={tableStyle} className="text-end">
                {item.quotePrice}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <h2>Terms and Conditions</h2>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          {getTermsAndCondition.map((items, i) => (
            <li key={i}>{items.name}</li>
          ))}
        </ul>
      </div>
      <Row>
        <Col>
          <p className="fs-5 fw-bold ">For any clarification please contact:</p>
          <p className="fs-5 fw-bold ">
            Mr. Srivallabha KS - +91 7204709007 / {data?.salesOfficerName} - +91 
            9686055449
          </p>
          <p className="fs-5 fw-bold ">
            We hope that you will find our offer competitive to place your
            orders with us.
          </p>
          <p className="fs-5 fw-bold ">
            Thanking you and assuring you of our best services.
          </p>
          <p className="fs-5 fw-bold ">Yours faithfully,</p>
        </Col>
      </Row>
    </div>
  );
});

export default CutomerQuotationPrint;
