import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../../Layout/Layout";
// import FileUploadComponent from "../../../../Components/formElements/FileUploadComponent";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import DataTable from "react-data-table-component";

import ItemService from "../../../../services/customer_appCommonService";
import { getTodayDate } from "../../../../common/Validations";
import FileViewerModal from "../../../../Components/FileViewer/FileViewerModal";
import { useSelector } from "react-redux";

const PurchaseOrderDetails = () => {
  const quotationID = useSelector(
    (state) => state?.customerV1?.costingSheet?.quotationID
  );

  // console.log(">>quotationDocData",quotationID)
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const [getPODetails, setPODetails] = useState([]);

  const [getShowFileViewModal, setShowFileViewModal] = useState(false);
  const [getFileViewData, setFileViewData] = useState(null);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  let productDetailsCol = [
    {
      name: "Sl.No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}.</>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Item",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "330px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "QTY",
      selector: (row) => row.purchaseQuantity || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="purchaseQuantity"
          placeholder=""
          value={row.purchaseQuantity || ""}
          disabled={true}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "UOM",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderUnitOfMeasure"
          placeholder=""
          value={row.orderUnitOfMeasure || ""}
          disabled={true}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Rate",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderPriceUnit"
          placeholder=""
          value={row.orderPriceUnit || ""}
          disabled={true}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Bal QTY",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="balancePurchaseQuantity"
          placeholder=""
          value={row.balancePurchaseQuantity || ""}
          disabled={true}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Order QTY(MT)",
      selector: (row) => row.dispatchDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          name="orderQuantityMT"
          placeholder=""
          value={row.orderQuantityMT || ""}
          disabled={true}
        />
      ),
      width: "150px",
    },
  ];

  let productDetailsData = getPODetails[0]?.items;

  useEffect(() => {
    poDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const poDetails = async () => {
    let obj = {
      documentNumber: quotationID,
    };
    await ItemService.getPODetail(obj).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        // console.log(">>PO data", item?.data);
        setPODetails(item?.data);
      } else {
        setPODetails([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  //file veiw
  const handleViewFile = (fileAttachmentId) => {
    setFileViewData(fileAttachmentId);
    setShowFileViewModal(true);
  };
  const handleViewFileHide = () => {
    setShowFileViewModal(false);
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle"> Purchase Order Details</h2>
                <p className="subText"> Purchase Order Details </p>
              </Col>
            </Row>
            <hr />
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CD</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">PO Details</span>
                      <span className="subHeaderTitle">PO Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex align-items-center">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchase Order"
                          id="custPurchasingDocumentNr"
                          name="custPurchasingDocumentNr"
                          type="text"
                          value={
                            getPODetails[0]?.custPurchasingDocumentNr ||
                            "" ||
                            ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Date"
                          id="purchasingDocumentDate"
                          name="purchasingDocumentDate"
                          type="date"
                          value={
                            getPODetails[[0]]?.purchasingDocumentDate ||
                            getTodayDate("") ||
                            ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Value"
                          id="purchasingDocumentValue"
                          name="purchasingDocumentValue"
                          type="number"
                          value={getPODetails[0]?.purchasingDocumentValue}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <ComboboxField
                          label="PO Type"
                          placeholder=""
                          // data={poTypeDropDown}
                          id="poType"
                          iconClassName="dropdownIcon"
                          name="poType"
                          code="customerCode"
                          description="poTypeDesc"
                          setValue={getPODetails[0]?.poType || ""}
                          // getvalue={setDropdownData}
                          required={true}
                          disabled={true}
                          // error={validation.country}
                        />
                      </Col>
                      {getPODetails[0]?.poType === "Open" && (
                    <>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Valid From"
                          id="poValidFrom"
                          name="poValidFrom"
                          type="date"
                          value={getPODetails[0]?.poValidFrom}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Valid To"
                          id="poValidTo"
                          name="poValidTo"
                          type="date"
                          value={getPODetails[0]?.poValidTo}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </>
                  )}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <ComboboxField
                          label="Customer Name"
                          placeholder=""
                          id="customerName"
                          iconClassName="dropdownIcon"
                          name="customerName"
                          code="customerCode"
                          description="customerNameDesc"
                          setValue={getPODetails[0]?.customerName || ""}
                          // getvalue={setDropdownData}
                          required={true}
                          disabled={true}
                          // error={validation.country}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <ComboboxField
                          label="Project Name" //not get key
                          placeholder=""
                          // data={getProjects}
                          id="projectName"
                          iconClassName="dropdownIcon"
                          name="projectName"
                          code="projectNameCode"
                          description="projectNameDesc"
                          setValue={getPODetails[0]?.projectName || ""}
                          // getvalue={setDropdownData}
                          required={true}
                          disabled={true}
                          // error={validation.country}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Project Address"
                          name="projectAddress"
                          value={
                            getPODetails[0]?.projectAddress +
                              getPODetails[0]?.projectAddress2 || ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commLeftRightPadding"
                      >
                        <p className="fw-medium">PO Attachments</p>
                        {getPODetails[0]?.purchaseOrderFileAttachment && (
                          <img
                            src="assets/img/newIcons/attached-file.png"
                            className="mt-1 attachmentIcon"
                            alt="attachments"
                            onClick={() =>
                              handleViewFile(
                                getPODetails[0]?.purchaseOrderFileAttachment
                              )
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Product Details</span>
                      <span className="subHeaderTitle">Product Details</span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={productDetailsCol}
                          data={productDetailsData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // selectableRows
                          // onSelectedRowsChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <FileViewerModal
        show={getShowFileViewModal}
        onHide={handleViewFileHide}
        fileid={getFileViewData}
      />
    </main>
  );
};

export default PurchaseOrderDetails;
