/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { useNavigate } from "react-router-dom";

//Componentd
import Loader from "../../../Components/Loader";
import { reportInfo } from "../../../redux/feature/reportSlice";

//Redux
import { useDispatch } from "react-redux"; //useDispatch

const CustomerReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Customer Reports");
  localStorage.setItem("submenu", "-");

  //   let menuData = useSelector((state) => state?.menu); // User Details from Store
  let menuData = [
    {
      id: "1",
      hrefLink: "/customer-report-details",
      icon: "bi bi-list-ul",
      name: "Inquiry",
      description: "",
      color: "",
    },
    {
      id: "2",
      hrefLink: "/customer-report-details",
      icon: "bi-file-earmark-plus",
      name: "Quotation",
      description: "",
      color: "",
    },
    {
      id: "3",
      hrefLink: "/customer-report-details",
      icon: "bi-truck",
      name: "Customer Purchase Order",
      description: "",
      color: "",
    },
    {
      id: "4",
      hrefLink: "/customer-report-details",
      icon: "bi-file-earmark-text",
      name: "Sales Order",
      description: "",
      color: "",
    },
  ];
  //   console.log("menudata",menuData.length)
  const handleClick = (obj) => {    
    dispatch(reportInfo(obj));
    navigate(obj.hrefLink);
  };

  return (
    <main className="dashboard main homePage" id="main">
      <Layout />
      <Row className="homeScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">Reports</h2>
            <p className="subText">Complete Details of the Reports</p>
            <hr />

            <Row>
              <Loader isLoading={!menuData} className={"text-center"} />
              {menuData && menuData?.length > 0
                ? menuData?.map((value, index) => {
                    return (
                      <Col
                        xs={12}
                        md={4}
                        className="commLeftRightPadding"
                        key={index}
                      >
                        <div
                          className="card info-card sales-card"
                          onClick={() => handleClick(value)}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <i className={value.icon}></i>
                              </div>
                              <div className="ps-3">
                                <h6>{value.name}</h6>
                                <span
                                  className="text-muted small pt-2"
                                  style={{ fontWeight: "600" }}
                                >
                                  {value.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CustomerReports;
