import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import "../Style/customer_v2.scss";

//API
import ItemService from "../../../../services/customer_appCommonService";
import ItemServiceV2 from "../../../../services/customer_v2_appCommonService";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch

//Components
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
// import { ComboboxFieldSubArray } from "../../../../Components/formElements/ComboboxFieldSubArray";
// import DataTable from "react-data-table-component";
import Constants from "../../../../common/SupplierConstants";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import {
  validateName,
  validateRequired,
  validPostalCode,
} from "../../../../common/Validations";
import CustomerConstants from "../../../../common/CustomerConstants";
import toast from "react-hot-toast";
import GoogleMapComponent from "../Common/GoogleMapComponent";
import FileUploadComponent from "../../../../Components/formElements/FileUploadComponent";
// import { saveCustomerRegData } from "../../../../redux/feature/customer_app/customerV1Slice";
import Layout from "../../../../Layout/Layout";
import FileViewerModal from "../../../../Components/FileViewer/FileViewerModal";
import CommonFillter from "../../../../Components/Filter/Supplier/SupplierCommonFillter";
import { ComboboxFieldSubArray } from "../../../../Components/formElements/ComboboxFieldSubArray";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
};

const CustomerRegistrationV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "Customer List");

  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store
  let userData = useSelector((state) => state?.user?.data);

  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  let productDocumentAttachment = [
    {
      id: "1",
      name: "Stage1",
    },
    {
      id: "2",
      name: "Stage2",
    },
    {
      id: "3",
      name: "Stage3",
    },
    {
      id: "4",
      name: "Stage4",
    },
    {
      id: "5",
      name: "Stage5",
    },

    {
      id: "6",
      name: "Google Pin location",
    },
    {
      id: "7",
      name: "Project Photos",
    },
    {
      id: "8",
      name: "Blueprints or Layout Plans",
    },
    {
      id: "9",
      name: "Project Document",
    },
    {
      id: "10",
      name: "Others",
    },
  ];

  // const getCustomer = [
  //   { id: 1, name: "Yes" },
  //   { id: 2, name: "No" },
  // ];

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  const SalesOfficerData = [
    { id: 4, code: "A000", name: "Amarendra" },
    { id: 1, code: "S0001", name: "Manjunatha" },
    { id: 2, code: "S0002", name: "Umesh" },
    { id: 3, code: "S0003", name: "Amith" },
    { id: 4, code: "S0004", name: "Sathish" },
  ];

  const dropDownTypeOfFirm = [
    { id: 1, name: "Proprietorship" },
    { id: 2, name: "Partnership" },
    { id: 3, name: "Public Limited Company" },
    { id: 4, name: "Public Limited Company" },
    { id: 5, name: "Public Liability Company" },
  ];
  const dropDowntypeOfCreditType = [
    { id: 1, name: "Credit" },
    { id: 2, name: "Advance Payment" },
  ];
  const dropDownCreditPeriodDays = [
    { id: 1, name: "30" },
    { id: 2, name: "60" },
    { id: 2, name: "90" },
    { id: 2, name: "120" },
    { id: 2, name: "150" },
  ];

  const securityType = [
    { id: 1, name: "PDC" },
    { id: 2, name: "BG" },
    { id: 3, name: "LC" },
  ];

  let dropDownYear = [
    {
      id: "1",
      name: "2024-2025",
    },
    {
      id: "2",
      name: "2023-2024",
    },
    {
      id: "3",
      name: "2022-2023",
    },
    {
      id: "4",
      name: "2021-2022",
    },
    {
      id: "5",
      name: "2019-2020",
    },
    {
      id: "6",
      name: "2017-2018",
    },
  ];
  const dropDownLackCrore = [
    { id: 1, name: "Lakhs" },
    { id: 1, name: "Crore" },
  ];
  // let requiredFields = [];

  const [getDropdownData, setDropdownData] = useState("");
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);

  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);

  const [getErrorFields, setErrorFields] = useState([]);
  const [getcontactPosition, setcontactPosition] = useState([]);
  const [getTitle, setTitle] = useState([]);
  const [nextId, setNextId] = useState(1); // Start IDs from 1

  const [getcustomerContactPerson, setcustomerContactPerson] = useState([
    {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    },
  ]);
  const [getDocumentTypeData, setDocumentTypeData] = useState([
    {
      id: Date.now(),
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "29AADCN1012B1Z6",
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  // const [getProjectContactPerson, setProjectContactPerson] = useState([
  //   {
  //     id: Date.now(),
  //     projectContactPosition: "",
  //     projectContactTitle: "",
  //     projectContactName: "",
  //     projectContactNumber: "",
  //     projectContactEmail: "",
  //   },
  // ]);

  const [getdocumentType, setdocumentType] = useState([]);

  const [getState, setState] = useState([]);
  const [getCountry, setCountry] = useState([]);

  const [getShowFileViewModal, setShowFileViewModal] = useState(false);
  const [getFileViewData, setFileViewData] = useState(null);

  const [validation, setValidation] = useState({
    organisationName: "",
    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
  });

  // ADD new projects
  const newProject = {
    projectCode: 1,
    projectCodeDesc: "PROJECT_1",
    projectName: "",
    projectAddress: "",
    projectAddress2: "",
    projectCity: "",
    projectCountry: "IN - India",
    projectState: "IN - Karanataka",
    projectCityPostalCode: "",
    getProjectContactPerson: [
      {
        id: Date.now(), // Unique ID for the first contact person
        projectContactPosition: "",
        projectContactTitle: "",
        projectContactName: "",
        projectContactNumber: "",
        projectContactEmail: "",
      },
    ],
    getProjectCertifications: [
      {
        id: Date.now(),
        name: "Document 1",
        documentType: "",
        certificateCode: "29AADCN1012B1Z6",
        stageDocument: "",
      },
    ],
    getLocations: [],
  };

  const [getProjectDetails, setProjectDetails] = useState([newProject]);

  //bank state
  const [getBankDetails, setBankDetails] = useState([]);

  const [getBankDetailsData, setBankDetailsData] = useState([]);

  const [getCompanyCode, setCompanyCode] = useState([]);

  // const [getExisitingCustomer, setExisitingCustomer] = useState("");

  // const [getCustomer, setCustomer] = useState([]);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  // const toggleCardVisibility6 = () => {
  //   setCardVisible6(!isCardVisible6);
  // };
  // const toggleCardVisibility7 = () => {
  //   setCardVisible7(!isCardVisible7);
  // };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
    validateName(name === "organisationName" ? value : "")
      ? (errors.organisationName = "")
      : (errors.organisationName = message.VALID_CUSTOMER_NAME);
    validPostalCode(name === "cityPostalCode" ? value : "")
      ? (errors.cityPostalCode = "")
      : (errors.cityPostalCode = message.VALID_POSTAL_CODE);

    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };
  //inputchange for companyprofile
  const handleCompanyProfileChange = ({ target: { name, value } }) => {
    setCompanyProfile((prevCompanyProfile) => ({
      ...prevCompanyProfile,
      [name]: value,
    }));
  };

  const [formInputs, setFormInputs] = useState({
    createdBy: userData?.userId || "",
    organisationName: "",
    address: "",
    address2: "",
    city: "",
    country: "IN - India",
    state: "IN - Karnataka",
    cityPostalCode: "",
    companyCode: "",

    version: "v0",
    status: "Registered",
    category: "VENDOR",

    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
    salesOfficerCode: "",
    salesOfficerName: "",

    plantAddress: appConfig?.customerPlantAddress,
    projectLocation: "",

    customerTypeCode: "C03",
    customerTypeDesc: "Domestic",
  });
  const [disabled, setDisabled] = useState(false);

  const [getCompanyProfile, setCompanyProfile] = useState({
    customerCode: "",
    typeOfCompany: "",
    typeOfFirm: "",
    creditLimit: "",
    creditLimitINR: "",
    typeOfCustomerSegment: "",
    creditPeriodDays: "",
    numberOfYearsInBusiness: "",
    security: "",
    detailsOfSecurity: "",
    paymentType: "",
  });
  // console.log(">>formInputs", formInputs);
  useEffect(() => {
    // dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getMasterData();
  }, [dispatch]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.codeType === "country") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      getStateData(getDropdownData?.code);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      setCompanyProfile((prevCompanyProfile) => ({
        ...prevCompanyProfile,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    setValidation((prev) => ({
      ...prev,
      [getDropdownData?.textField]: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // console.log(">> formInputs", formInputs);

  // SAVE
  const handleSave = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.organisationName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        organisationName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    // if (!validateRequired(formInputs.address)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     address: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.address2)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     address2: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.city)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     city: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }
    // if (!validateRequired(formInputs.cityPostalCode)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     cityPostalCode: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // if (!validateRequired(formInputs.distance)) {
    //   requiredFields.push(`${message.GET_MAP_DISTANCE}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   // setValidation((prev) => ({
    //   //   ...prev,
    //   //   cityPostalCode: message.FILL_REQUIRED_FIELD,
    //   // }));
    //   return;
    // }
    // if (!validateRequired(formInputs.salesOfficerName)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     salesOfficerName: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    // if (!validateRequired(formInputs.routeNumber)) {
    //   requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   setValidation((prev) => ({
    //     ...prev,
    //     routeNumber: message.FILL_REQUIRED_FIELD,
    //   }));
    //   return;
    // }

    formInputs.firstName = formInputs.organisationName;
    formInputs.contactDetails = getcustomerContactPerson;
    formInputs.certifications = getDocumentTypeData;
    formInputs.projectDetails = getProjectDetails;
    formInputs.salesOfficerCode = userData?.userId;
    formInputs.salesOfficerName = userData?.firstName;
    // formInputs.routeDetails = getRouteDetails;
    formInputs.bandkDetails = getBankDetails;

    formInputs.companyProfile = getCompanyProfile;

    formInputs.financialTurnover = getFinancialTurnover;

    const obj = {
      data: formInputs,
    };
    // console.log(">>obj", obj);
    await ItemServiceV2.addCustomerV2(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });

        // dispatch(saveCustomerRegData(item?.data));
        navigate(CustomerConstants.CUSTOMER_V2_CUSTOMER_MASTER_LIST);

        // console.log(">> userData");

        // if(userData){
        //   navigate(CustomerConstants.CUSTOMER_V1_COSTING_SHEET);
        //   dispatch(saveCustomerRegData(item?.data));
        // }else{
        //   navigate(CustomerConstants.CUSTOMER_V1_COSTING_SHEET_LOGIN);
        //   dispatch(saveCustomerRegData(item?.data));
        // }
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "title",
    };
    await ItemService.getMasterDataSelf(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setTitle(item?.data);
      } else {
        setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj1 = {
      codeType: "documentType",
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setdocumentType(item?.data);
      } else {
        setdocumentType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj2 = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterDataSelf(obj2).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        setcontactPosition(item?.data);
      } else {
        setcontactPosition([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj3 = {
      codeType: "country",
    };
    await ItemService.getMasterDataSelf(obj3).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj4 = {
      codeType: "companyCode",
    };
    await ItemService.getMasterData(obj4).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCompanyCode(item?.data);
      } else {
        setCompanyCode([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };
  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    // let obj4 = {
    //   type: "ALL",
    // };
    // await ItemService.getCustomers(obj4).then((item) => {
    //   // console.log(">> item! title", item);
    //   if (item?.status === 200) {
    //     setCustomer(item?.data);
    //   } else {
    //     setCustomer([]);
    //     console.log(">> Error: Loading getCustomers");
    //   }
    // });
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  // eslint-disable-next-line no-unused-vars
  const navigateToLogin = () => {
    navigate(Constants.LOGIN);
  };

  /* ---------------------Customer Contact Person -------- */

  const deleteCustomerContactPerosn = (id) => {
    const updatedData1 = getcustomerContactPerson.filter(
      (doc) => doc.id !== id
    );
    setcustomerContactPerson(updatedData1);
  };
  const addCustomerCantactPerson = () => {
    const newDocument = {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    };
    setcustomerContactPerson((prevData) => [...prevData, newDocument]);
  };
  const handleChangeCustomerContacts = (id, fieldName, value) => {
    setcustomerContactPerson((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------Document Type  03rd block-------- */

  const deleteDocumentType = (id) => {
    const updatedData1 = getDocumentTypeData.filter((doc) => doc.id !== id);
    setDocumentTypeData(updatedData1);
  };
  const addDocumentType = () => {
    const newDocument = {
      id: Date.now(),
      name: `Document ${getDocumentTypeData.length + 1}`,
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "",
    };
    setDocumentTypeData((prevData) => [...prevData, newDocument]);
  };
  const handleInputChange = (id, fieldName, value) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------project details block-------- */
  const addProjectDetails = () => {
    newProject.projectCode = nextId + 1;
    newProject.projectCodeDesc = "PROJECT_" + (nextId + 1);
    setProjectDetails((prevData) => [...prevData, newProject]);
    setNextId((prevId) => prevId + 1);
  };

  const removeProjectDetails = (indexToRemove) => {
    setProjectDetails(
      getProjectDetails.filter((_, idx) => idx !== indexToRemove)
    );
  };

  const handleProjectInputChange = (value, index, fieldName) => {
    // console.log(">>handleProjectInputChange", { index, fieldName, value });

    if (index === undefined || value === undefined) return;

    // Update the specific project detail
    setProjectDetails((prevDetails) =>
      prevDetails.map((item, idx) =>
        idx === index ? { ...item, [fieldName]: value } : item
      )
    );
  };

  /* --------------------- Contact Person Inside Project Details -------- */

  //Add Projects
  const addProjectCantactPersonNew = (projectIndex) => {
    const newContactPerson = {
      id: Date.now(), // Unique ID for each contact person
      projectContactPosition: "",
      projectContactTitle: "",
      projectContactName: "",
      projectContactNumber: "",
      projectContactEmail: "",
    };

    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: [
          ...updatedProjectDetails[projectIndex].getProjectContactPerson,
          newContactPerson,
        ],
      };
      return updatedProjectDetails;
    });
  };

  //Delete Project
  const deleteProjectContactPerosnNew = (projectIndex, id) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.filter((contact) => contact.id !== id),
      };
      return updatedProjectDetails;
    });
  };

  //Project Contact Inputs
  const handleChangeProjectContactsNew = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value } // Update the specific field
              : contact // Keep other contacts unchanged
        ),
      };
      return updatedProjectDetails;
    });
  };

  //Dropdown
  const handleChangeProjectContactsNewDropdown = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Update the specific contact person's field
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value.name } // Update the field with the full object
              : contact // Keep other contacts unchanged
        ),
      };

      return updatedProjectDetails;
    });
  };

  /* ---------------------project Certifications  -------- */

  const deleteProjectCertifications = (projectIndex, certificationId) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Filter out the certification with the specified id
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: updatedProjectDetails[
          projectIndex
        ].getProjectCertifications.filter(
          (certification) => certification.id !== certificationId
        ),
      };

      return updatedProjectDetails;
    });
  };

  const addProjectCertifications = (projectIndex) => {
    const newCertification = {
      id: Date.now(), // Unique ID for the new certification
      name: "Document 1",
      documentType: "",
      certificateCode: "29AADCN1012B1Z6",
      stageDocument: "",
    };
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Add the new certification to the getProjectCertifications array
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: [
          ...updatedProjectDetails[projectIndex].getProjectCertifications,
          newCertification,
        ],
      };
      return updatedProjectDetails;
    });
  };

  const onSuccessUploadDocumentType = (data) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === data.fieldId
          ? { ...doc, [data.fieldName]: data.attachmentId }
          : doc
      )
    );
  };

  //document upload for projects
  const onSuccessUploadProjectDocumentType = (
    projectIndex,
    docId,
    fieldName,
    attachmentId
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: updatedProjectDetails[
          projectIndex
        ].getProjectCertifications.map((cert) =>
          cert.id === docId ? { ...cert, [fieldName]: attachmentId } : cert
        ),
      };

      return updatedProjectDetails;
    });
  };

  const onHandleMapData = (data) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Update the getLocations array with the new data
      updatedProjectDetails[data?.projectIndex] = {
        ...updatedProjectDetails[data?.projectIndex],
        getLocations: [
          ...updatedProjectDetails[data?.projectIndex].getLocations,
          {
            distance: data.distance,
            duration: data.duration,
            fromToCoords: data.fromToCoords,
            plantAddress: data.plantAddress,
            projectLocation: data.projectLocation,
          },
        ],
      };

      return updatedProjectDetails;
    });
  };

  // View attachment files
  const handleViewFile = (fileAttachmentId) => {
    setFileViewData(fileAttachmentId);
    setShowFileViewModal(true);
  };
  const handleViewFileHide = () => {
    setShowFileViewModal(false);
  };

  //bank start

  const bankInfoInitialData = {
    bankNotListed: "",
    bankAccountNumber: "",
    bankKey: "",
    bankName: "",
    bankCountry: "",
    bankAddress: "",
    AccountHolderName: "",
    bankCompanyletterhead: "",
    bankConfirmationLetter: "",
    chipsCode: "",
    swiftIfscCode: "",
    bankInfoTemplate: "",
    preferredCurrency: "",
  };

  //Load Bank Master Data
  const getBankMasterData = async () => {
    await ItemService.getBankMasterData().then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        setBankDetailsData(item?.data);
      } else {
        setBankDetailsData([]);
        console.log(">> Error: Loading getBankMasterData");
      }
    });
  };
  useEffect(() => {
    getBankMasterData(); // Load Bank Details
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Bank Info Section
  const addBankForm = () => {
    setBankDetails([...getBankDetails, bankInfoInitialData]); // Add an empty object to the forms array
  };

  const removeBankForm = (indexToRemove) => {
    setBankDetails(
      getBankDetails.filter((form, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    setBankDetails([...getBankDetails, bankInfoInitialData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeBankInfo = (index, key, value) => {
    if (value) {
      const newForms = [...getBankDetails];
      newForms[index][key] = value;
      setBankDetails(newForms);

      if (value.length < 6 || value.length > 18) {
      }

      if (key === "bankKey") {
        // eslint-disable-next-line array-callback-return
        getBankDetailsData.filter((item) => {
          if (item.name === value) {
            const newForms = [...getBankDetails];
            newForms[index]["bankCountryDescription"] =
              item.bankCountryDescription;
            newForms[index]["bankAccountName"] = item.bankAccountName;
            newForms[index]["bankAddress"] = item.bankAddress;
            newForms[index]["bankBranchName"] = item.bankBranchName;
            newForms[index]["bankCurrency"] = item.bankCurrency;
            setBankDetails(newForms);
          }
        });
      }
    } else {
      // console.log(">> its empty", value);
      setBankDetails((prevState) => {
        const updatedForms = [...prevState];
        updatedForms[index] = {
          ...updatedForms[index],
          [key]: "", // Update the field with the given value (even if empty)
        };
        return updatedForms;
      });
    }
  };
  //bank end

  //Financial Turnover
  const [getFinancialTurnover, setFinancialTurnover] = useState([
    {
      id: 1,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
    {
      id: 2,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
    {
      id: 3,
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      financialTurnoverFileAttachment: "",
    },
  ]);

  const handleChangeFinancialTurnover = (id, fieldName, value) => {
    setFinancialTurnover((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };
  const deleteFinancialTurnover = (id) => {
    const updatedData1 = getFinancialTurnover.filter((doc) => doc.id !== id);
    setFinancialTurnover(updatedData1);
  };

  // For Upload Success
  const onSuccessUploadFinancilaDocumentType = (data, id) => {
    setFinancialTurnover((prevData) =>
      prevData.map((doc) =>
        doc.id === id
          ? { ...doc, financialTurnoverFileAttachment: data.attachmentId }
          : doc
      )
    );
  };
  // const addFinancialTurnover = () => {
  //   const newDocument = {
  //     id: Date.now(),
  //     name: `Document ${getFinancialTurnover.length + 1}`,
  //     turnoverYear: "",
  //     documentNumber: "",
  //     valueTurnover: "",
  //     lakhsCroreTurnover: "",
  //   };
  //   setFinancialTurnover((prevData) => [...prevData, newDocument]);
  // };
  return (
    <main className="dashboard main sri-venkateshwara" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Customer Registration</h2>
            <p className="subText">
              Register as a new Customer or Sub-Contractor with our Company
            </p>
            <hr />
            <Row className="mb-4">
              {/* <Col xs={12} md={2} lg={2} className="text-left">
                <button className="btnBack" onClick={() => navigateToLogin()}>
                  <i className="bi bi-arrow-return-left"></i> Go Back Login
                </button>
              </Col> */}
              {/* Next Button*/}
              <Col
                xs={12}
                md={{ span: 2, offset: 10 }}
                lg={{ span: 2, offset: 10 }}
                className="text-left"
              >
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>
            {/* customer details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Customer </span>
                      <span className="subHeaderTitle">Customer Details</span>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          extraLabel="As per the trade license"
                          name="organisationName"
                          placeholder=""
                          value={formInputs.organisationName || ""}
                          onChange={onInputChange}
                          required={true}
                          error={validation.organisationName}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label=" Address Lines 1 / Street"
                          name="address"
                          placeholder=""
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                          error={validation.address}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Address Line 2"
                          name="address2"
                          placeholder=""
                          value={formInputs.address2 || ""}
                          onChange={onInputChange}
                          error={validation.address2}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          placeholder=""
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                          error={validation.city}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Country"
                          placeholder=""
                          data={getCountry}
                          id="country"
                          iconClassName="dropdownIcon"
                          name="country"
                          code="countryCode"
                          description="countryDesc"
                          setValue={formInputs.country}
                          getvalue={setDropdownData}
                          required={true}
                          // error={validation.country}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="State"
                          placeholder=""
                          data={getState}
                          id="state"
                          iconClassName="dropdownIcon"
                          name="state"
                          code="stateCode"
                          description="stateDesc"
                          setValue={formInputs.state}
                          getvalue={setDropdownData}
                          required={true}
                          // error={validation.state}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code/PO Box"
                          name="cityPostalCode"
                          placeholder=""
                          value={formInputs.cityPostalCode}
                          onChange={onInputChange}
                          error={validation.cityPostalCode}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sales Officer Name"
                          extraLabel=""
                          name="salesOfficerName"
                          code="salesOfficerCode"
                          description="salesOfficerDesc"
                          data={SalesOfficerData}
                          id="salesOfficerName"
                          iconClassName="dropdownIcon"
                          setValue={formInputs?.salesOfficerName}
                          error={validation.salesOfficerName}
                          getvalue={setDropdownData}
                          required={true}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Company Code"
                          extraLabel="SAP Company Code"
                          placeholder=""
                          data={getCompanyCode}
                          id="companyCode"
                          iconClassName="dropdownIcon"
                          name="companyCode"
                          code="companyCodeCode"
                          description="companyCodeDesc"
                          setValue={formInputs.companyCode || ""}
                          getvalue={setDropdownData}
                          onChange={onInputChange}
                          // error={validation.companyCode}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Customer Contact Person / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Contact Person</span>
                      <span className="subHeaderTitle">
                        Customer Contact Person
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button
                          className="btnTable"
                          onClick={addCustomerCantactPerson}
                        >
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={12}>
                                <div className="accordion-body">
                                  <div className="d-flex-column justify-content-center">
                                    <Row className="pt-4 d-none d-lg-flex ">
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Job Title
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Title
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Person
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Number
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Email-ID
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex">
                                          Action
                                        </p>
                                      </Col>
                                    </Row>
                                    {getcustomerContactPerson.map(
                                      (doc, index) => (
                                        <Row key={doc.id}>
                                          <Col xs={12}>
                                            <hr className="mx-4 my-0 text-color-black" />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <ComboboxField
                                              label=""
                                              placeholder="Job Title"
                                              data={getcontactPosition}
                                              id="contactPosition"
                                              iconClassName="dropdownIcon"
                                              name="contactPosition"
                                              code="contactPositionCode"
                                              description="contactPositionDesc"
                                              setValue={doc?.contactPosition}
                                              getvalue={setDropdownData}
                                              index={doc.id}
                                              onChangeValue={
                                                handleChangeCustomerContacts
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <ComboboxField
                                              label=""
                                              placeholder="Contact Title"
                                              data={getTitle}
                                              id="contactTitle"
                                              iconClassName="dropdownIcon"
                                              name="contactTitle"
                                              code="contactTitleCode"
                                              description="contactTitleDesc"
                                              setValue={doc?.contactTitle}
                                              getvalue={setDropdownData}
                                              index={doc.id}
                                              onChangeValue={
                                                handleChangeCustomerContacts
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              name="contactName"
                                              placeholder="Contact Person"
                                              value={doc.contactName || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactName",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              name="contactNumber"
                                              placeholder="Contact Number"
                                              value={doc.contactNumber || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactNumber",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              // label="Please Enter the Remarks*"
                                              name="contactEmail"
                                              placeholder="Email"
                                              value={doc.contactEmail || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactEmail",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>

                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={1}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <i
                                              onClick={() =>
                                                deleteCustomerContactPerosn(
                                                  doc.id
                                                )
                                              }
                                              className="bi bi-trash text-danger fs-4"
                                            />
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* customer Doucment Type */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DT</span>
                    </Col>
                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Doucment Type</span>
                      <span className="subHeaderTitle">Doucment Type</span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button className="btnTable" onClick={addDocumentType}>
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={12}>
                                <div className="accordion-body">
                                  <div className="d-flex-column justify-content-center">
                                    <Row className="pt-4 d-none d-lg-flex ">
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Document Type
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Document Number
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Issue Date
                                        </p>
                                      </Col>
                                      {/* <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Issuing Authority
                                        </p>
                                      </Col> */}
                                      <Col xs={12} md={4} lg={1}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          View Files
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Attachments
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex justify-content-center ms-4">
                                          Action
                                        </p>
                                      </Col>
                                    </Row>
                                    {getDocumentTypeData.map((doc, index) => (
                                      <Row key={doc.id}>
                                        <Col xs={12}>
                                          <hr className="mx-4 my-0 text-color-black" />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <ComboboxField
                                            label=""
                                            placeholder="Document type"
                                            data={getdocumentType}
                                            id="documentType"
                                            iconClassName="dropdownIcon"
                                            name="documentType"
                                            code="documentTypeCode"
                                            description="documentTypeDesc"
                                            setValue={doc?.documentType}
                                            getvalue={setDropdownData}
                                            index={doc.id}
                                            onChangeValue={handleInputChange}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            placeholder="Document Number"
                                            value={doc.documentNumber}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "documentNumber",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            type="date"
                                            value={doc.issueDate}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "issueDate",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        {/* <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            placeholder="Issuing Authority"
                                            value={doc.issuingAuthority}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "issuingAuthority",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col> */}
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={1}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <div className="d-flex justify-content-center">
                                            {(doc.iSOCertification && (
                                              <img
                                                src="assets/img/newIcons/attached-file.png"
                                                className="mt-1 attachmentIcon"
                                                alt="attachments"
                                                onClick={() =>
                                                  handleViewFile(
                                                    doc.iSOCertification
                                                  )
                                                }
                                              />
                                            )) ||
                                              "--"}
                                          </div>
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={3}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <FileUploadComponent
                                            id={doc.id}
                                            name="iSOCertification"
                                            label=""
                                            allowedTypes={[
                                              "image/png",
                                              "image/jpeg",
                                              "application/pdf",
                                            ]}
                                            multiple={true} // Change to `false` for single file selection
                                            required={true}
                                            onSuccessUpload={
                                              onSuccessUploadDocumentType
                                            }
                                            value={doc.iSOCertification || ""}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={1}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <i
                                            onClick={() =>
                                              deleteDocumentType(doc.id)
                                            }
                                            className="bi bi-trash text-danger fs-4"
                                          />
                                        </Col>
                                      </Row>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 08 Banker Info*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BK</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">Bank Information</span>
                      <span className="subHeaderTitle">
                        Bank Information (Please add all your Bank Accounts)
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => addBankForm()}
                        // disabled={getDisabledStatus}
                      >
                        <i className="bi bi-plus-lg"></i> Add Bank Info
                      </button>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <CommonFillter />
                    </Col>
                  </Row>
                </h2>

                {/* Banker Information */}
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* To Load Dynamic Bank Info Form */}
                    {getBankDetails &&
                      getBankDetails?.map((item, index) => (
                        <div key={index}>
                          <Row className="pt-2 pb-2">
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <h6>Bank Information Section {index + 1}</h6>
                              <span
                                className="deleteIconAccordion"
                                onClick={() => removeBankForm(index)}
                              >
                                {" "}
                                <i className="bi bi-x-circle"></i>{" "}
                              </span>
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Is the Company bank included on list?"
                                placeholder=""
                                data={dropdownOption}
                                id="bankNotListed"
                                iconClassName="dropdownIcon"
                                name="bankNotListed"
                                setValue={item?.bankNotListed || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                // disabled={getDisabledStatus}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="BankID"
                                placeholder=""
                                data={getBankDetailsData}
                                id="bankKey"
                                iconClassName="dropdownIcon"
                                name="bankKey"
                                setValue={item?.bankKey}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                // disabled={getDisabledStatus}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Bank A/C Number"
                                name="bankAccountNumber"
                                placeholder=""
                                value={item?.bankAccountNumber}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "bankAccountNumber",
                                    e.target.value
                                  )
                                }
                                // disabled={getDisabledStatus}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              {/* <ComboboxField
                                            label="Bank Name"
                                            placeholder=""
                                            data={dropdownOption}
                                            id="bankName"
                                            iconClassName="dropdownIcon"
                                            name="bankName"
                                            setValue={item?.bankName || ""}
                                            getvalue={setDropdownData}
                                            index={index}
                                            onChangeValue={handleChangeBankInfo}
                                            disabled={getDisabledStatus}
                                          /> */}

                              <InputField
                                className="inputBox"
                                label="Bank Name"
                                name="bankBranchName"
                                placeholder=""
                                value={item?.bankBranchName}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "bankBranchName",
                                    e.target.value
                                  )
                                }
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              {/* <ComboboxField
                                            label="Bank Country"
                                            placeholder=""
                                            data={dropdownOption}
                                            id="bankCountry"
                                            iconClassName="dropdownIcon"
                                            name="bankCountry"
                                            setValue={item?.bankCountry || ""}
                                            getvalue={setDropdownData}
                                            index={index}
                                            onChangeValue={handleChangeBankInfo}
                                            disabled={getDisabledStatus}
                                          /> */}
                              <InputField
                                className="inputBox"
                                label="Bank Country"
                                name="bankCountryDescription"
                                placeholder=""
                                value={item?.bankCountryDescription}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "bankCountryDescription",
                                    e.target.value
                                  )
                                }
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              {/* <ComboboxField
                                            label="Branch Address"
                                            placeholder=""
                                            data={dropdownOption}
                                            id="bankAddress"
                                            iconClassName="dropdownIcon"
                                            name="bankAddress"
                                            setValue={item?.bankAddress || ""}
                                            getvalue={setDropdownData}
                                            index={index}
                                            onChangeValue={handleChangeBankInfo}
                                            disabled={getDisabledStatus}
                                          /> */}
                              <InputField
                                className="inputBox"
                                label="Branch Address"
                                name="bankAddress"
                                placeholder=""
                                value={item?.bankAddress}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "bankAddress",
                                    e.target.value
                                  )
                                }
                                disabled={true}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              {/* <ComboboxField
                                            label="Preferred Currency"
                                            placeholder=""
                                            data={dropdownOption}
                                            id="preferredCurrency"
                                            iconClassName="dropdownIcon"
                                            name="preferredCurrency"
                                            setValue={item?.preferredCurrency || ""}
                                            getvalue={setDropdownData}
                                            index={index}
                                            onChangeValue={handleChangeBankInfo}
                                            disabled={getDisabledStatus}
                                          /> */}
                              <InputField
                                className="inputBox"
                                label="Preferred Currency"
                                name="bankCurrency"
                                placeholder=""
                                value={item?.bankCurrency}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "bankCurrency",
                                    e.target.value
                                  )
                                }
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Name of Beneficiary"
                                extraLabel="Name of Beneficiary (Bank Account Holder Name) as per company /trade license"
                                name="AccountHolderName"
                                placeholder=""
                                value={item?.AccountHolderName}
                                onChange={(e) =>
                                  handleChangeBankInfo(
                                    index,
                                    "AccountHolderName",
                                    e.target.value
                                  )
                                }
                                // disabled={getDisabledStatus}
                              />
                              {/* <ComboboxField
                                            label="Name of Beneficiary"
                                            placeholder=""
                                            extraLabel="Name of Beneficiary (Bank Account Holder Name) as per company /trade license"
                                            data={dropdownOption}
                                            id="AccountHolderName"
                                            iconClassName="dropdownIcon"
                                            name="AccountHolderName"
                                            setValue={item?.AccountHolderName || ""}
                                            getvalue={setDropdownData}
                                            index={index}
                                            onChangeValue={handleChangeBankInfo}
                                            disabled={getDisabledStatus}
                                          /> */}
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Swift IFSC Code"
                                placeholder=""
                                data={dropdownOption}
                                id="swiftIfscCode"
                                iconClassName="dropdownIcon"
                                name="swiftIfscCode"
                                setValue={item?.swiftIfscCode || ""}
                                getvalue={setDropdownData}
                                index={index}
                                onChangeValue={handleChangeBankInfo}
                                // disabled={getDisabledStatus}
                              />
                            </Col>
                            <Row>
                              <Col
                                xs={12}
                                md={4}
                                lg={4}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Company letterhead"
                                  extraLabel="Upload the filled template on company letterhead , signed by authorized person with company seal "
                                  name="bankCompanyletterhead"
                                  placeholder=""
                                  value={item?.bankCompanyletterhead}
                                  onChange={(e) =>
                                    handleChangeBankInfo(
                                      index,
                                      "bankCompanyletterhead",
                                      e.target.value
                                    )
                                  }
                                  // disabled={getDisabledStatus}
                                />
                                <div className="col-sm-10">
                                  <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                  />
                                </div>
                              </Col>
                              <Col
                                xs={12}
                                md={4}
                                lg={4}
                                className="commTopButtonRightLeftPadding"
                              >
                                <InputField
                                  className="inputBox"
                                  label="Confirmation letter"
                                  extraLabel="Do you have a Confirmation letter from the bank( mandatory for bank updates ) "
                                  name="bankConfirmationLetter"
                                  placeholder=""
                                  value={item?.bankConfirmationLetter}
                                  onChange={(e) =>
                                    handleChangeBankInfo(
                                      index,
                                      "bankConfirmationLetter",
                                      e.target.value
                                    )
                                  }
                                  // disabled={getDisabledStatus}
                                />
                                <div className="col-sm-10">
                                  <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <hr />
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Company Profile */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible9
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility9}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Company Profile </span>
                      <span className="subHeaderTitle">
                        Company Profile Details
                      </span>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible9 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Type Of Customer Segment"
                          id="typeOfCustomerSegment"
                          name="typeOfCustomerSegment"
                          placeholder=""
                          value={getCompanyProfile.typeOfCustomerSegment || ""}
                          onChange={handleCompanyProfileChange}
                          // error={validation.typeOfCustomerSegment}
                          required={true}
                          // disabled={editMode}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Type of Firm"
                          placeholder=""
                          data={dropDownTypeOfFirm}
                          id="typeOfFirm"
                          iconClassName="dropdownIcon"
                          name="typeOfFirm"
                          code="typeOfFirmCode"
                          description="typeOfFirmDesc"
                          setValue={getCompanyProfile.typeOfFirm}
                          getvalue={setDropdownData}
                          required={true}
                          // disabled={editMode}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Years in Business"
                          name="numberOfYearsInBusiness"
                          placeholder=""
                          type="number"
                          value={
                            getCompanyProfile.numberOfYearsInBusiness || ""
                          }
                          onChange={handleCompanyProfileChange}
                          // error={validation.numberOfYearsInBusiness}
                          required={true}
                          // disabled={editMode}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment Type"
                          placeholder=""
                          data={dropDowntypeOfCreditType}
                          id="paymentType"
                          iconClassName="dropdownIcon"
                          name="paymentType"
                          code="paymentTypeCode"
                          description="paymentTypeDesc"
                          setValue={getCompanyProfile.paymentType}
                          getvalue={setDropdownData}
                          required={true}
                          // error={validation.paymentType}
                          // disabled={editMode}
                        />
                      </Col>

                      {getCompanyProfile.paymentType === "Credit" && (
                        <>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Credit Period (Days)"
                              placeholder=""
                              data={dropDownCreditPeriodDays}
                              id="creditPeriodDays"
                              iconClassName="dropdownIcon"
                              name="creditPeriodDays"
                              code="creditPeriodDaysCode"
                              description="creditPeriodDaysDesc"
                              setValue={getCompanyProfile.creditPeriodDays}
                              getvalue={setDropdownData}
                              required={true}
                              error={validation.creditPeriodDays}
                              // disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Credit Limit (Lakhs)"
                              name="creditLimitINR"
                              placeholder=""
                              type="number"
                              value={getCompanyProfile.creditLimitINR || ""}
                              onChange={handleCompanyProfileChange}
                              // error={validation.creditLimitINR}
                              required={true}
                              // disabled={editMode}
                            />
                          </Col>

                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <ComboboxField
                              label="Security"
                              placeholder=""
                              data={securityType}
                              id="security"
                              iconClassName="dropdownIcon"
                              name="security"
                              code="securityCode"
                              description="securityDesc"
                              setValue={getCompanyProfile.security || ""}
                              getvalue={setDropdownData}
                              required={true}
                              // error={validation.security}
                              // disabled={editMode}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="commTopButtonRightLeftPadding"
                          >
                            <InputField
                              className="inputBox"
                              label="Details Of Security"
                              name="detailsOfSecurity"
                              placeholder=""
                              value={getCompanyProfile.detailsOfSecurity || ""}
                              onChange={handleCompanyProfileChange}
                              // error={validation.detailsOfSecurity}
                              required={true}
                              // disabled={editMode}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Financial Turnover */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DT</span>
                    </Col>
                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Financial Turnover</span>
                      <span className="subHeaderTitle">
                        Please provide your company's turnover for the previous
                        years, supported by documentary evidence
                      </span>
                    </Col>
                    {/* {!editMode && (
                                <Col xs={4} md={2} lg={2}>
                                  <div className="d-flex justify-content-end  p-2 me-3">
                                    <button
                                      className="btnTable"
                                      onClick={addFinancialTurnover}
                                    >
                                      <i className="bi bi-plus-lg"></i> Add
                                    </button>
                                  </div>
                                </Col>
                              )} */}
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={12}>
                                <div className="accordion-body">
                                  <div className="d-flex-column justify-content-center">
                                    <Row className="pt-4 d-none d-lg-flex ">
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Financial Year
                                        </p>
                                      </Col>
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Turnover Value
                                        </p>
                                      </Col>
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Lakhs/Crore
                                        </p>
                                      </Col>
                                      <Col xs={12} md={3} lg={3}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          File View
                                        </p>
                                      </Col>
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Attachments
                                        </p>
                                      </Col>

                                      <Col xs={12} md={1} lg={1}>
                                        <p className="fs-6 fw-medium d-flex justify-content-start ms-4">
                                          Delete
                                        </p>
                                      </Col>
                                    </Row>
                                    {getFinancialTurnover.map((doc, index) => (
                                      <Row key={doc.id}>
                                        <Col xs={12}>
                                          <hr className="mx-4 my-0 text-color-black" />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={2}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <ComboboxField
                                            label=""
                                            placeholder="Financial Year"
                                            data={dropDownYear}
                                            id="turnoverYear"
                                            iconClassName="dropdownIcon"
                                            name="turnoverYear"
                                            code="turnoverYearCode"
                                            description="turnoverYearDesc"
                                            setValue={doc?.turnoverYear}
                                            getvalue={setDropdownData}
                                            index={doc.id}
                                            onChangeValue={
                                              handleChangeFinancialTurnover
                                            }
                                            // disabled={editMode}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={2}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox mt-1"
                                            name="valueTurnover"
                                            placeholder="Turnover Value"
                                            value={doc.valueTurnover || ""}
                                            onChange={(e) =>
                                              handleChangeFinancialTurnover(
                                                doc.id,
                                                "valueTurnover",
                                                e.target.value
                                              )
                                            }
                                            // disabled={editMode}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={2}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <ComboboxField
                                            label=""
                                            placeholder="lakhs/Crore"
                                            data={dropDownLackCrore}
                                            id="lakhsCroreTurnover"
                                            iconClassName="dropdownIcon"
                                            name="lakhsCroreTurnover"
                                            code="lakhsCroreTurnoverCode"
                                            description="lakhsCroreTurnoverDesc"
                                            setValue={doc?.lakhsCroreTurnover}
                                            getvalue={setDropdownData}
                                            index={doc.id}
                                            onChangeValue={
                                              handleChangeFinancialTurnover
                                            }
                                            // disabled={editMode}
                                          />
                                        </Col>

                                        <Col
                                          xs={12}
                                          md={2}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          {(doc.financialTurnoverFileAttachment && (
                                            <>
                                              <img
                                                src="assets/img/newIcons/attached-file.png"
                                                className="mt-1 attachmentIcon"
                                                alt="attachments"
                                                onClick={() =>
                                                  handleViewFile(
                                                    doc.financialTurnoverFileAttachment
                                                  )
                                                }
                                              />
                                            </>
                                          )) ||
                                            "--"}
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={3}
                                          lg={3}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <FileUploadComponent
                                            id="financialTurnoverFileAttachment"
                                            name="financialTurnoverFileAttachment"
                                            label=""
                                            allowedTypes={[
                                              "image/png",
                                              "image/jpeg",
                                              "application/pdf",
                                            ]}
                                            multiple={true} // Change to false for single file selection
                                            required={true}
                                            onSuccessUpload={(data) =>
                                              onSuccessUploadFinancilaDocumentType(
                                                data,
                                                doc.id
                                              )
                                            }
                                            value={
                                              doc.financialTurnoverFileAttachment ||
                                              ""
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={1}
                                          lg={1}
                                          className="commTopButtonRightLeftPadding "
                                        >
                                          <i
                                            onClick={() =>
                                              deleteFinancialTurnover(doc.id)
                                            }
                                            className="bi bi-trash text-danger fs-4"
                                          />
                                        </Col>
                                      </Row>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Project Details*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PD</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Ship-to-Party </span>
                      <span className="subHeaderTitle">
                        Ship-to-Party Details
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button
                          className="btnTable"
                          onClick={() => addProjectDetails()}
                        >
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {getProjectDetails &&
                      getProjectDetails?.map((item, index) => (
                        <div key={index}>
                          <Row className="pt-2 pb-4">
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <h5 className="bg-table-status bg-table-new-status mt-3 p-3">
                                Ship-to-Party {index + 1}
                              </h5>
                              <span
                                className="deleteIconAccordion"
                                onClick={() => removeProjectDetails(index)}
                              >
                                {" "}
                                <i className="bi bi-x-circle"></i>{" "}
                              </span>
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Ship-to-Party"
                                extraLabel="As per the trade license"
                                name="projectName"
                                value={item.projectName || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectName"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Address Lines 1 / Street"
                                extraLabel="As per the trade license"
                                name="projectAddress"
                                value={item.projectAddress || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectAddress"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Address Line 2"
                                extraLabel="As per the trade license"
                                name="projectAddress2"
                                value={item.projectAddress2 || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectAddress2"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="City"
                                extraLabel="As per the trade license"
                                name="projectCity"
                                value={item.projectCity || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectCity"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Country"
                                placeholder=""
                                data={getCountry}
                                id="projectCountry"
                                iconClassName="dropdownIcon"
                                name="projectCountry"
                                code="projectCountryCode"
                                description="projectCountryDesc"
                                setValue={item?.projectCountry}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "projectCountry"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="State"
                                placeholder=""
                                data={getState}
                                id="projectState"
                                iconClassName="dropdownIcon"
                                name="projectState"
                                code="projectStateCode"
                                description="projectStateDesc"
                                setValue={item?.projectState}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "projectState"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Postal Code/PO Box"
                                extraLabel="As per the trade license"
                                name="projectCityPostalCode"
                                value={item.projectCityPostalCode || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectCityPostalCode"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Commission"
                                placeholder=""
                                data={dropdownOption}
                                id="commission"
                                iconClassName="dropdownIcon"
                                name="commission"
                                code="commissionCode"
                                description="commissionDesc"
                                setValue={item?.commission}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "commission"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Commission Amount"
                                name="commissionRate"
                                placeholder=""
                                // value={formInputs.commissionRate || ""}
                                // onChange={onInputChange}
                                // error={validation.commissionRate}
                                required={true}
                                value={item.commissionRate || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "commissionRate"
                                  )
                                }
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-1">
                                        Ship-to-Party Contacts
                                      </h5>
                                    </Col>

                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <Row className="pt-4 d-none d-lg-flex ">
                                            <Col xs={12} md={2} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Job Title
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Title
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Person
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Number
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Email-ID
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex">
                                                Action
                                              </p>
                                            </Col>
                                          </Row>
                                          {item?.getProjectContactPerson.map(
                                            (doc, itemIndex) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Job Title"
                                                    data={getcontactPosition}
                                                    id="projectContactPosition"
                                                    name="projectContactPosition"
                                                    code="projectContactPositionCode"
                                                    description="projectContactPositionDesc"
                                                    setValue={
                                                      doc.projectContactPosition
                                                    }
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      contactId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Contact Title"
                                                    data={getTitle}
                                                    id="projectContactTitle"
                                                    iconClassName="dropdownIcon"
                                                    name="projectContactTitle"
                                                    code="projectContactTitleCode"
                                                    description="projectContactTitleDesc"
                                                    setValue={
                                                      doc.projectContactTitle
                                                    }
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      contactId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactName"
                                                    placeholder="Contact Person"
                                                    value={
                                                      doc.projectContactName ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactName",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactNumber"
                                                    placeholder="Contact Number"
                                                    value={
                                                      doc.projectContactNumber ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactNumber",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactEmail"
                                                    placeholder="Email"
                                                    value={
                                                      doc.projectContactEmail ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactEmail",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex">
                                                    {item
                                                      ?.getProjectContactPerson
                                                      .length !== 1 && (
                                                      <i
                                                        onClick={() =>
                                                          deleteProjectContactPerosnNew(
                                                            index,
                                                            doc.id
                                                          )
                                                        }
                                                        className="bi bi-trash text-danger fs-4"
                                                      />
                                                    )}

                                                    {itemIndex ===
                                                      item
                                                        ?.getProjectContactPerson
                                                        .length -
                                                        1 && (
                                                      <button
                                                        className="btnTable width-40 ml-6 mt-1"
                                                        onClick={() =>
                                                          addProjectCantactPersonNew(
                                                            index
                                                          )
                                                        } // Fixed: Pass as a callback
                                                      >
                                                        <i className="bi bi-plus-lg"></i>{" "}
                                                        Add
                                                      </button>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-4">
                                        Construction Stages
                                      </h5>
                                    </Col>
                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <Row className="pt-4 d-none d-lg-flex ">
                                            <Col xs={12} md={2} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Stages
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={1}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                View Files
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={3}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Attachments
                                              </p>
                                            </Col>

                                            <Col xs={12} md={4} lg={3}>
                                              <p className="fs-6 fw-medium d-flex">
                                                Action
                                              </p>
                                            </Col>
                                          </Row>
                                          {item.getProjectCertifications.map(
                                            (doc, itemIndex) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Stages"
                                                    data={
                                                      productDocumentAttachment
                                                    }
                                                    id="documentType"
                                                    iconClassName="dropdownIcon"
                                                    name="documentType"
                                                    code="documentTypeCode"
                                                    description="documentTypeDesc"
                                                    setValue={doc.documentType}
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      fileId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>

                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={1}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex justify-content-center">
                                                    {(doc.stageDocument && (
                                                      <img
                                                        src="assets/img/newIcons/attached-file.png"
                                                        className="mt-1 attachmentIcon"
                                                        alt="attachments"
                                                        onClick={() =>
                                                          handleViewFile(
                                                            doc.stageDocument
                                                          )
                                                        }
                                                      />
                                                    )) ||
                                                      "--"}
                                                  </div>
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={3}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <FileUploadComponent
                                                    id={doc.id}
                                                    name="stageDocument"
                                                    label=""
                                                    allowedTypes={[
                                                      "image/png",
                                                      "image/jpeg",
                                                      "application/pdf",
                                                    ]}
                                                    multiple={true} // Change to `false` for single file selection
                                                    required={true}
                                                    onSuccessUpload={(data) =>
                                                      onSuccessUploadProjectDocumentType(
                                                        index,
                                                        doc.id,
                                                        data.fieldName,
                                                        data.attachmentId
                                                      )
                                                    }
                                                    value={
                                                      doc.stageDocument || ""
                                                    }
                                                  />
                                                </Col>

                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex">
                                                    {item
                                                      ?.getProjectCertifications
                                                      .length !== 1 && (
                                                      <i
                                                        onClick={() =>
                                                          deleteProjectCertifications(
                                                            index,
                                                            doc.id
                                                          )
                                                        }
                                                        className="bi bi-trash text-danger fs-4"
                                                      />
                                                    )}

                                                    {itemIndex ===
                                                      item
                                                        ?.getProjectCertifications
                                                        .length -
                                                        1 && (
                                                      <button
                                                        className="btnTable width-40 ml-6 mt-1"
                                                        onClick={() =>
                                                          addProjectCertifications(
                                                            index
                                                          )
                                                        } // Fixed: Pass as a callback
                                                      >
                                                        <i className="bi bi-plus-lg"></i>{" "}
                                                        Add
                                                      </button>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-4">
                                        Locations
                                      </h5>
                                    </Col>
                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <GoogleMapComponent
                                            projectIndex={index}
                                            getmapdata={onHandleMapData}
                                            passmapdata={false}
                                            editstatus={false}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <hr />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col
          xs={12}
          md={{ span: 2, offset: 10 }}
          lg={{ span: 2, offset: 10 }}
          className="text-left"
        >
          <button className="btnTable" onClick={handleSave} disabled={disabled}>
            <i className="bi bi-save"></i> Create
          </button>
        </Col>
      </Row>

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
      <FileViewerModal
        show={getShowFileViewModal}
        onHide={handleViewFileHide}
        fileid={getFileViewData}
      />
    </main>
  );
};

export default CustomerRegistrationV2;
