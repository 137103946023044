import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputField } from "../formElements/InputField";
import TableDropdownMenuModal from "./TableDropdownMenuModal";

const TableDropdownMenu = (props) => {
  const [getShowModalViewModal, setShowModalViewModal] = useState(false);
  const [getModalViewData, setModalViewData] = useState(null);
  const [formInputs, setFormInputs] = useState({});

  const handleShowDropdownShow = () => {
    setModalViewData(props);
    setShowModalViewModal(true);
  };
  const handleShowDropdownHide = () => {
    setShowModalViewModal(false);
  };

  const onSuccess=(data)=>{
    // console.log(">> handleSuccess", data);
    setFormInputs(data)
    props.onChange(data)
  }
  return (
    <div className="">
      <Row>
        <Col xs={10} md={10} lg={10} className="commRightPadding">
          <InputField
            id={formInputs?.index}
            className="inputBox"
            label=""
            name={formInputs?.name}
            value={formInputs?.value}
            // onChange={onInputChange}
            disabled={true}
          />
        </Col>
        <Col xs={2} md={2} lg={2} className="">
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-center iconTable"
          >
            <img
              src="assets/img/newIcons/drop-down.png"
              alt="attachments"
              style={{ width: "20px", height: "25px" }}
              className="mt-2"
              onClick={() => handleShowDropdownShow()}
            />
          </div>
        </Col>
      </Row>

      <TableDropdownMenuModal
        show={getShowModalViewModal}
        onHide={handleShowDropdownHide}
        modaldata={getModalViewData}
        onsuccess={onSuccess}
      />
    </div>
  );
};

export default TableDropdownMenu;
