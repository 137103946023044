export const hasAccess = (userRole, elementType, elementName, pageConfig) => {
  if (!userRole || !elementType || !elementName) return false;
  // Get the allowed roles from the config based on the element type and name
  //   console.log(">> userRole", userRole);
  //   console.log(">> elementType", elementType);
  //   console.log(">> elementName", elementName);
  //   console.log(">> pageConfig", pageConfig);

  if (!pageConfig || !pageConfig[elementType]) {
    console.log(`Invalid elementType: ${elementType}`, pageConfig);
    return false;
  }

  const allowedRoles = pageConfig[elementType]?.[elementName];
  return allowedRoles ? allowedRoles.includes(userRole) : false;
};
