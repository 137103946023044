import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
// import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import Constants from "../../../../common/SupplierConstants";
// import OrderSchedulingModal from "./OrderSchedulingModal";
import { getPostDispatchDocList } from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
// import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import { formatDateTime, getLocationData } from "../../../../common/Validations";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
import { InputField } from "../../../../Components/formElements/InputField";

const PostDispatchList = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Order");
  localStorage.setItem("submenu", "Post Dispatch");

  // let userListData = useSelector((state) => state?.user.userList); // User Details from Store

  let postDispatchDocData = useSelector(
    (state) => state?.customerV1?.postDispatchDoc
  ); // User Details from Store
  let postDispatchDocTable = useSelector(
    (state) => state?.customerV1?.postDispatchDoc.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState();
  // console.log(">>data",data)

  const userListColInfo = [
    {
      name: "Dispatch",
      selector: (row) => row.dispatchDocNr,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-new-status">{row.dispatchDocNr}</span>          
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.orderStatus,
      cell: (row) => (
        <>
          <TableStatusField status={row.dispatchStatus} />
        </>
      ),    
      width: "140px",
    },
    {
      name: "INV Qty(MT)",
      selector: (row) => row.disptachQty,
      cell: (row) => (
        <>
          <span className="text-primary fw-bold">{row.disptachQty}</span>
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Invoice",
      selector: (row) => row.invoiceNumber,
      cell: (row) => (
        <div>
          <InputField
          className="inputBox text-primary"
          name="invoiceNumber"
          id="invoiceNumber"
          value={row.invoiceNumber}
          disabled={true}
          />          
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "MDP",
      selector: (row) => row.mDPNumber,
      cell: (row) => (
        <>
          <InputField
          className="inputBox"
          name="mDPNumber"
          id="mDPNumber"
          value={row.mDPNumber}
          disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Vehical",
      selector: (row) => row.vehicalNumber,
      cell: (row) => (
        <>
          <InputField
          className="inputBox"
          name="vehicalNumber"
          id="vehicalNumber"
          value={row.vehicalNumber}
          disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "130px",
    },

    {
      name: "Order",
      selector: (row) => row.orderSchedulingNr,
      cell: (row) => (
        <>
          {/* <span className="m-0 p-0 pt-1 fw-bold text-success">{row.orderSchedulingNr}</span> */}
          
          <span className="bg-table-status bg-table-success-status">{row.orderSchedulingNr}</span>
        </>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Customer",
      selector: (row) => row.customerCode,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.customerName} </p>
          <p className="m-0 p-0 ">{row.sapDocumentNumber} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Project",
      selector: (row) => row.projectName,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.projectName} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Project Address",
    //   cell: (row) => (
    //     <>
    //       <span>{row.getProjectDetails?.projectAddress}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },   
    {
      name: "Product",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    // {
    //   name: "Order Qty(Load)",
    //   selector: (row) => row.orderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span>{row.orderQuantityLoad}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Balance Qty(Load)",
    //   selector: (row) => row.balanceOrderQuantityLoad,
    //   cell: (row) => (
    //     <>
    //       <span>{row.balanceOrderQuantityLoad}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
 
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      cell: (row) => <>{formatDateTime(row.createdDate)}</>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
      cell: (row) => (
        <div>
        <p className="m-0 p-0 pt-1 fw-bold ">{row.createdByName} </p>
        <p className="m-0 p-0 ">{row.createdBy} </p>
      </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      cell: (row) => (
        <>
          <span>{getLocationData(row.getProjectDetails)}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Zone",
      selector: (row) => row.dispatchZone,
      cell: (row) => (
        <>
          <span>{row.dispatchZone}</span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const loadInitialSuuplierData = async () => {
    setData(postDispatchDocData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getPostDispatchDocList(obj));
    setData(postDispatchDocData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getPostDispatchDocList(obj));
    setData(postDispatchDocData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getPostDispatchDocList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postDispatchDocData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getDispatchDocList",
      module: "DISPATCH",
      page: 1,
      per_page: 10,
    };
    dispatch(getPostDispatchDocList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle"> Post Dispatch</h2>
                <p className="subText">Post Dispatch List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OS</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle"> Post Dispatch</span>
                       <span className="subHeaderTitle">
                        View of Post Dispatch List{" "}
                      </span>
                    </Col>
                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => introVideoShow()}
                      >
                        Order Booking
                      </button>
                    </Col> */}
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={postDispatchDocData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={postDispatchDocTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default PostDispatchList;
