import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";

// Components
import { InputField } from "../../../formElements/InputField";
import { ComboboxField } from "../../../formElements/ComboboxField";
import { isValidNumber } from "../../../../common/Validations";

//API
import ItemService from "../../../../services/supplier_appCommonService";

const HeaderText = React.forwardRef((props, ref) => {
  // console.log(">> props", props);

  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [getDocumentIdData, setDocumentIdData] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");

  const [formInputs, setFormInputs] = useState({
    ref: props.index,
    itemIndex: (props.index + 1),
    documentId: "",
    documentText: "",
  });

  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "250px",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "300px",
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash close-btn"
            ></i>
          </span>
        </>
      ),
      width: "10rem",
    },
  ];

  const handleDelete = (indexToDelete) => {
    setHeaderTextSelectedData((prevData) =>
      prevData.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleHeaderTextSelectedData = () => {
    if (formInputs.documentText !== '' && formInputs.documentId !== '') {
      formInputs.ref = props.index;
      formInputs.itemIndex = (props.index + 1);      
      setHeaderTextSelectedData((prevData) => [...prevData, formInputs]);
      setErrorMsg("")
    } else {      
      setErrorMsg("* Please enter Header text / Document Id");
    }
  };

  const handleSave = async () => {        
    // if (getHeaderTextSelectedData.length === 0) {
    //   setErrorMsg("* Add atleast one record");
    //   return;
    // } else {
    //   setErrorMsg("");
    // }
    props.hedertextdata(getHeaderTextSelectedData);
    props.onHide();
  };

  useEffect(() => {
    setHeaderTextSelectedData(props.showcurrentdata);
    setFormInputs({
      documentId: "",
      documentText: "",
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    if(props?.type === 'Number'){
      if(isValidNumber(value)){
        setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
      }
    }else{
      setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    }
  };

  useEffect(() => {
    let isActive = true;
    if (isActive) {      
      getMasterData();
    }
    // eslint-disable-next-line
  }, []);

  //Load Master Data
  const getMasterData = async () => {
    // 1
    let obj1 = {
      codeType: props.masterdata,
    };
    await ItemService.getMasterData(obj1).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setDocumentIdData(item?.data);
      } else {
        setDocumentIdData([]);
        console.log(">> Error: Loading getSupplierDatail");
      }
    });
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      // dialogClassName="modal-90w"
      size="xl"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> {props.headername}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="space-y-4">
          <Row className="d-flex">
            <Col xs={12} md={5} lg={5} className="commLeftRightPadding">
              <ComboboxField
                label="Code"
                data={getDocumentIdData}
                id="documentId"
                iconClassName="dropdownIcon"
                name="documentId"
                setValue={formInputs.documentId}
                getvalue={setDropdownData}
                className="dropdownOption"
              />
            </Col>
            <Col xs={10} md={5} lg={5} className="commLeftRightPadding">
              <InputField
                id="documentText"
                type="text"
                className="inputBox"
                label="Value"
                name="documentText"
                value={formInputs.documentText || ""}
                onChange={onInputChange}
              />
            </Col>
            <Col xs={2} md={1} lg={1} className="">
              <button className="btnTable mt-4" onClick={handleHeaderTextSelectedData}>
                <i className="bi bi-plus-lg"></i>
              </button>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} lg={12}>
              <DataTable
                // title=""
                columns={HeaderTextColumns}
                data={getHeaderTextSelectedData}
                // progressPending={loading}
                // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                selectableRows
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default HeaderText;
