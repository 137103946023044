import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";

// Components
import { InputField } from "../../../formElements/InputField";
// import { ComboboxField } from "../../../formElements/ComboboxField";
import { isValidNumber } from "../../../../common/Validations";

//API
import ItemService from "../../../../services/supplier_appCommonService";

const ServicePO = React.forwardRef((props, ref) => {
  // console.log(">> props", props);

  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [getDocumentIdData, setDocumentIdData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // const [getDropdownData, setDropdownData] = useState("");

  // const [formInputs, setFormInputs] = useState({
  //   ref: props.index,
  //   itemIndex: props.index + 1,
  //   documentId: "",
  //   documentText: "",
  // });

  const ServiceScheduleColumns = [
    {
      name: "Schedule LineNr",
      cell: (row) => (
        <>
          <span>{row.scheduleLineNr}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Day/Week/Month",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="schedulePeriod"
            placeholder=""
            value={row.schedulePeriod || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Schedule From",
      selector: (row) => row.scheduleFromDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="scheduleFromDate"
          placeholder=""
          value={row.scheduleFromDate || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    {
      name: "Schedule To",
      selector: (row) => row.scheduleToDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="scheduleToDate"
          placeholder=""
          value={row.scheduleToDate || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    
    {
      name: "Schedule Quantity",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="scheduleQuantity"
            placeholder=""
            value={row.scheduleQuantity || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Balance Quantity",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="balanceQuantity"
            placeholder=""
            value={row.balanceQuantity || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Reservation No",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="reservationNr"
            placeholder=""
            value={row.reservationNr || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    {
      name: "Delivery Time",
      selector: (row) => row.deliveryTime || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="time"
          name="deliveryTime"
          placeholder=""
          value={row.deliveryTime || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    // {
    //   name: "Static Delivery Date",
    //   selector: (row) => row.stDeliveryDate || "--",
    //   cell: (row) => (
    //     <InputField
    //       className="inputBox mt-1"
    //       type="date"
    //       name="stDeliveryDate"
    //       placeholder=""
    //       value={row.stDeliveryDate || ""}
    //       onChange={(event) =>
    //         handleServiceInputChange(row.scheduleLineNr, event)
    //       }
    //     />
    //   ),
    //   width: "180px",
    // },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash close-btn"
            ></i>
          </span>
        </>
      ),
      width: "90",
    },
  ];
  let ServiceScheduleColumnsData = [
    {
      scheduleLineNr: 1,
      scheduleQuantity: 0,
      reservation_no: 0,
      deliveryDate: "",
      deliveryTime: "",
      stDeliveryDate: "",
    },
  ];

  const ServicePOColumns = [
    {
      name: "Service LineNr",
      cell: (row) => (
        <>
          <span>{row.serviceLineNr}</span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Service No",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="serviceNr"
            placeholder=""
            value={row.serviceNr || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },   
    {
      name: "Service Text",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="serviceDescription"
            placeholder=""
            value={row.serviceDescription || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Quantity",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="serviceQuantity"
            placeholder=""
            value={row.serviceQuantity || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "UOM",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="orderUnitOfMeasure"
            placeholder=""
            value={row.orderUnitOfMeasure || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Unit Value",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="unitValue"
            placeholder=""
            value={row.unitValue || ""}
            onChange={(event) =>
              handleServiceInputChange(row.serviceLineNr, event)
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Gross Value",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="grossValue"
            placeholder=""
            value={Number(row.serviceQuantity) * Number(row.unitValue) || 0}
          />
        </>
      ),
      width: "200px",
    },
    // {
    //   name: "Currency",
    //   cell: (row) => (
    //     <>
    //       <InputField
    //         className="inputBox mt-1"
    //         name="currency"
    //         placeholder=""
    //         value={row.currency || ""}
    //         onChange={(event) =>
    //           handleServiceInputChange(row.serviceLineNr, event)
    //         }
    //       />
    //     </>
    //   ),
    //   width: "200px",
    // },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="date"
          name="deliveryDate"
          placeholder=""
          value={row.deliveryDate || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    {
      name: "Delivery Time",
      selector: (row) => row.deliveryTime || "--",
      cell: (row) => (
        <InputField
          className="inputBox mt-1"
          type="time"
          name="deliveryTime"
          placeholder=""
          value={row.deliveryTime || ""}
          onChange={(event) =>
            handleServiceInputChange(row.scheduleLineNr, event)
          }
        />
      ),
      width: "180px",
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <>
          <span>
            <i
              onClick={() => handleDelete(index)}
              className="bi bi-trash close-btn"
            ></i>
          </span>
        </>
      ),
      width: "90",
    },
  ];
  let ServicePOColumnsData = [
    {
      serviceLineNr: 1,
      serviceQuantity: 0,
      deliveryDate: "",
      deliveryTime: "",
      stDeliveryDate: "",
    },
  ];

  const [getSchedulePOSelectedData, setSchedulePOSelectedData] = useState(
    ServiceScheduleColumnsData
  );
  const [getServicePOSelectedData, setServicePOSelectedData] =
    useState(ServicePOColumnsData);

  const addServicePOItemRow = () => {
    if (props.purchasetype === "SER") {
      setServicePOSelectedData((prevRows) => {
        ServicePOColumnsData[0].serviceLineNr = prevRows?.length + 1;
        return [...prevRows, ...ServicePOColumnsData];
      });
    } else {
      setSchedulePOSelectedData((prevRows) => {
        ServiceScheduleColumnsData[0].serviceLineNr = prevRows?.length + 1;
        return [...prevRows, ...ServiceScheduleColumnsData];
      });
    }
  };

  const handleServiceInputChange = (serviceLineNr, event) => {
    const { name, value } = event.target;
    if (props.purchasetype === "SER") {
      if (name === "serviceQuantity" || name === "unitValue") {
        if (isValidNumber(value)) {
          setServicePOSelectedData((prevRows) =>
            prevRows.map((row) => {
              return row.serviceLineNr === serviceLineNr
                ? {
                    ...row,
                    grossValue: Number(row.serviceQuantity || 0) * Number(value || 0),
                    [name]: value,
                  }
                : row;
            })
          );
        }
      } else {
        setServicePOSelectedData((prevRows) =>
          prevRows.map((row) =>
            row.serviceLineNr === serviceLineNr
              ? { ...row, [name]: value }
              : row
          )
        );
      }
    } else { // SA
      if (name === "scheduleQuantity" || name === "reservation_no") {
        if (isValidNumber(value)) {
          setSchedulePOSelectedData((prevRows) =>
            prevRows.map((row) => {
              return row.serviceLineNr === serviceLineNr
                ? {
                    ...row,
                    [name]: value,
                  }
                : row;
            })
          );
        }
      } else {
        setSchedulePOSelectedData((prevRows) =>
          prevRows.map((row) =>
            row.serviceLineNr === serviceLineNr
              ? { ...row, [name]: value }
              : row
          )
        );
      }
    }
  };

  const handleDelete = (indexToDelete) => {
    if (props.purchasetype === "SER") {
      setServicePOSelectedData((prevData) =>
        prevData.filter((_, index) => index !== indexToDelete)
      );
    } else {
      setSchedulePOSelectedData((prevData) =>
        prevData.filter((_, index) => index !== indexToDelete)
      );
    }
  };

  const handleSave = async () => {
    if (
      props.purchasetype === "SER"
        ? getServicePOSelectedData.length === 0
        : getSchedulePOSelectedData.length === 0
    ) {
      setErrorMsg("* Add atleast one record");
      return;
    } else {
      setErrorMsg("");
    }

    props.servicescheduledata(
      props.purchasetype === "SER"
        ? getServicePOSelectedData
        : getSchedulePOSelectedData
    );
    props.onHide();
  };

  useEffect(() => {
    if (props.showcurrentdata) {      
      setServicePOSelectedData(props.showcurrentdata);
      setSchedulePOSelectedData(props.showcurrentdata);
    } else {
      setServicePOSelectedData([]);
      setSchedulePOSelectedData([]);
    }

    // setFormInputs({
    //   documentId: "",
    //   documentText: "",
    // });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  // const onInputChange = ({ target: { name, value } }) => {
  //   if (props?.type === "Number") {
  //     if (isValidNumber(value)) {
  //       setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  //     }
  //   } else {
  //     setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  //   }
  // };

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getMasterData();
    }
    // eslint-disable-next-line
  }, []);

  //Load Master Data
  const getMasterData = async () => {
    // 1
    let obj1 = {
      codeType: props.masterdata,
    };
    await ItemService.getMasterData(obj1).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setDocumentIdData(item?.data);
      } else {
        setDocumentIdData([]);
        console.log(">> Error: Loading getSupplierDatail");
      }
    });
  };

  // Get Dropdown data select
  // useEffect(() => {
  //   // console.log(">> data ss", getDropdownData);
  //   if (getDropdownData?.name) {
  //     setFormInputs((formInputs) => ({
  //       ...formInputs,
  //       [getDropdownData?.textField]: getDropdownData?.name,
  //     }));
  //   } else {
  //     setFormInputs((formInputs) => ({
  //       ...formInputs,
  //       [getDropdownData?.textField]: getDropdownData?.name,
  //     }));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getDropdownData]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      dialogClassName="modal-80w"
      // size="xl"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> {props.headername}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={12} className="headerButtonAlign">
              <Col xs={12} md={1} lg={1}>
                <button className="btnTable" onClick={addServicePOItemRow}>
                  <i className="bi bi-plus-lg"></i> Add
                </button>
              </Col>
            </Col>
            <Col xs={12} md={12} lg={12}>
              <DataTable
                // title=""
                columns={
                  props.purchasetype === "SER"
                    ? ServicePOColumns
                    : ServiceScheduleColumns
                }
                data={
                  props.purchasetype === "SER"
                    ? getServicePOSelectedData
                    : getSchedulePOSelectedData
                }
                // progressPending={loading}
                // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                selectableRows
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default ServicePO;
