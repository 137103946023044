// import Spinner from "react-bootstrap/Spinner";

const Loader = ({ isLoading, className }) => {
  // console.log(">> ", className, isLoading);

  return (
    <>
      <div>
        {/* {isLoading &&
          <Spinner animation="border" role="status" variant="info" className='mt-4'>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        } */}
        {isLoading && (
          <section className={`dots-container ${className}`}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </section>
        )}

        {/* {isLoading && (
          <div className="loader">
            <div className="box box-1">
              <div className="side-left"></div>
              <div className="side-right"></div>
              <div className="side-top"></div>
            </div>
            <div className="box box-2">
              <div className="side-left"></div>
              <div className="side-right"></div>
              <div className="side-top"></div>
            </div>
            <div className="box box-3">
              <div className="side-left"></div>
              <div className="side-right"></div>
              <div className="side-top"></div>
            </div>
            <div className="box box-4">
              <div className="side-left"></div>
              <div className="side-right"></div>
              <div className="side-top"></div>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default Loader;
