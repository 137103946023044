// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the timer */
.timer-container {
  display: inline-block;
  padding: 4px 4px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

/* Style for each part of the timer */
.timer-container .time-part {
  display: inline-block;
  min-width: 40px;
  padding: 2px;
  background-color: #88f36a;
  border-radius: 4px;
  margin: 0 4px;
  border: 1px solid #86e46b;
}

/* Style for labels (days, hours, etc.) */
.timer-container .time-label {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #272727;
  margin-top: 0px;
}

/* Highlight the numbers to make them more visible */
.timer-container .time-part span {
  font-size: 16px;
  color: #444343;
}

/* Add a special style for when the timer reaches zero */
.timer-zero .time-part {
  background-color: #f0eaea;
  border: 1px solid #e4e2e2;
}
.timer-zero .time-part span {
  color: #b6b4b4;
}`, "",{"version":3,"sources":["webpack://./src/Components/TableReverseTimer/timmer.scss"],"names":[],"mappings":"AAAA,4BAAA;AACA;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA,qCAAA;AACA;EACE,qBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;AACF;;AAEA,yCAAA;AACA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AACF;;AAEA,oDAAA;AACA;EACE,eAAA;EACA,cAAA;AACF;;AAEA,wDAAA;AAEE;EACE,yBAAA;EACA,yBAAA;AAAJ;AACI;EACE,cAAA;AACN","sourcesContent":["/* Container for the timer */\n.timer-container {\n  display: inline-block;\n  padding: 4px 4px;\n  font-size: 16px;\n  font-weight: bold;\n  text-align: center;\n}\n\n/* Style for each part of the timer */\n.timer-container .time-part {\n  display: inline-block;\n  min-width: 40px;\n  padding: 2px;\n  background-color: #88f36a;\n  border-radius: 4px;\n  margin: 0 4px;\n  border: 1px solid #86e46b;\n}\n\n/* Style for labels (days, hours, etc.) */\n.timer-container .time-label {\n  display: block;\n  font-size: 12px;\n  font-weight: normal;\n  color: #272727;\n  margin-top: 0px;\n}\n\n/* Highlight the numbers to make them more visible */\n.timer-container .time-part span {\n  font-size: 16px;\n  color: #444343;\n}\n\n/* Add a special style for when the timer reaches zero */\n.timer-zero {\n  .time-part {\n    background-color: #f0eaea;\n    border: 1px solid #e4e2e2;\n    span {\n      color: #b6b4b4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
