/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../common/SupplierConstants";

const ManageEditUser = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  // let dropdownOption = [
  //   {
  //     id: "1",
  //     name: "Sample Data 1",
  //   },
  //   {
  //     id: "2",
  //     name: "Sample Data 2",
  //   },
  // ];

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [getAccessData, setAccessData] = useState("");

  // const [formInputs, setFormInputs] = useState({
  //   email: "",
  //   firstName: "",
  //   lastName: "",
  //   contactNumber: "",
  //   companyName: "",
  //   _id: "",
  // });

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  // const onInputChange = ({ target: { name, value } }) => {
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  //   // console.log("onInputChange => formInputs", formInputs);
  // };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Edit User</h2>
                <p className="subText">Edit User to User's Collection</p>
              </Col>
              <Col xs={12} md={2} lg={2} className="mb-2">
                <button className="btnTable">Update</button>
              </Col>
              <hr />
            </Row>

            {/* add user */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AU</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Add User</span>
                      <span className="subHeaderTitle">
                        Add User to User's Collection
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Tenant"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="User ID"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Username"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          // disabled={flase}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Alternate Phone No"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          // disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Username"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Auction Currency"
                          placeholder="User Category"
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          // setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Email"
                          name=""
                          placeholder=""
                          // value={formInputs.firstName || ""}
                          // onChange={onInputChange}
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Auction Currency"
                          placeholder="User Roles"
                          // data={dropdownOption}
                          id=""
                          iconClassName="dropdownIcon"
                          name=""
                          // setValue={formInputs.INCOTermslocation || ""}
                          // getvalue={setAccessData}
                          // className="dropdownOption"
                          // disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-black">status</p>
                        <button className="btn">On Boarded</button>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <div>
                          <input type="checkbox" defaultChecked />
                          <span>Enabeld</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Historical Log Of Approval */}
            <div className="accordion mt-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LG</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">
                        Historical Log Of Approval
                      </span>
                      {/*  <span className="subHeaderTitle">
                       Add User to User's Collection
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {/* Historical Log Of Approval */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default ManageEditUser;
