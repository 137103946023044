import React, { useState, useEffect } from "react";
import './timmer.scss'

// Function to format time in days, hours, minutes, and seconds
const formatTime = (seconds) => {
  const days = isNaN(seconds) ? 0 : Math.floor(seconds / (24 * 60 * 60));
  const hours = isNaN(seconds) ? 0 : Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const mins = isNaN(seconds) ? 0 : Math.floor((seconds % (60 * 60)) / 60);
  const secs = isNaN(seconds) ? 0 : seconds % 60;

  return { days, hours, mins, secs };
};

// Reverse Timer component
const ReverseTimer = ({ closingDateTime, openingDateTime }) => {
  const closingDate = new Date(closingDateTime);
  const currentTime = new Date(); // Current datetime
  const initialTime = Math.max(Math.floor((closingDate - currentTime) / 1000), 0); // Ensures no negative time

  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => Math.max(prevTime - 1, 0)); // Prevents timeLeft from going negative
      }, 1000);

      return () => clearInterval(timerId); // Clean up timer on component unmount
    }
  }, [timeLeft]);

  const { days, hours, mins, secs } = formatTime(timeLeft);

  // Conditional class for when the timer reaches zero
  const timerClass = timeLeft === 0 || isNaN(timeLeft) ? 'timer-zero' : '';

  return (
    <div className={`timer-container ${timerClass}`}>
      <div className="time-part">
        <span>{days}</span>
        <div className="time-label">Days</div>
      </div>
      <div className="time-part">
        <span>{hours.toString().padStart(2, "0")}</span>
        <div className="time-label">Hours</div>
      </div>
      <div className="time-part">
        <span>{mins.toString().padStart(2, "0")}</span>
        <div className="time-label">Minutes</div>
      </div>
      <div className="time-part">
        <span>{secs.toString().padStart(2, "0")}</span>
        <div className="time-label">Seconds</div>
      </div>
    </div>
  );
};

export default ReverseTimer;
