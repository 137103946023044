import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const PieChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = new ApexCharts(chartRef.current, {
      series: [44, 55, 13, 43, 22],
      chart: {
        height: 350,
        type: 'pie',
        toolbar: {
          show: true
        }
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E']
    });

    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="pieChart" ref={chartRef}></div>;
};

export default PieChart;
