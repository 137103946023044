/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { InputField } from "../../../formElements/InputField";

const PriceShedule = React.forwardRef((props, ref) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [formInputs, setFormInputs] = useState({
    UnitPrice: '',
    DeliveryLeadTimeDays: '',
    Date: ''
  });

  const handleSubmit = async () => {
    if (
      formInputs.UnitPrice === "" ||
      formInputs.DeliveryLeadTimeDays === "" ||
      formInputs.Date === ""
    ) {
      setErrorMsg("* Fields are mandatory");
      return;
    } else {
      setErrorMsg("");
    }

    const formData = {
      UnitPrice: formInputs.UnitPrice,
      DeliveryLeadTimeDays: formInputs.DeliveryLeadTimeDays,
      Date: formInputs.Date
    };
    props.submitPriceShedule(formData);  // Call the callback function with the form data
    props.onHide();  // Close the modal after submitting
  };

  useEffect(() => {
    setFormInputs({
      UnitPrice: '',
      DeliveryLeadTimeDays: '',
      Date: ''
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value ||""}));
  };



  return (
    <Modal
    //   {...props}
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      dialogClassName="modal-90w"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> Create Other Changes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <InputField
                type="text"
                className="inputBox"
                label="UnitPrice"
                name="UnitPrice"
                placeholder=""
                value={formInputs.UnitPrice ||""}
                onChange={onInputChange}
              />
            </Col>
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <InputField
                type="text"
                className="inputBox"
                label="Delivery lead Time (Days)"
                name="DeliveryLeadTimeDays"
                value={formInputs.DeliveryLeadTimeDays || ""}
                onChange={onInputChange}
              />
            </Col>
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <InputField
                type="date"
                className="inputBox"
                label="Date"
                name="Date"
                // placeholder=""
                value={formInputs.Date || ""}
                onChange={onInputChange}
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default PriceShedule;
