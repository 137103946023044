import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Layout from "../../../../Layout/Layout";

import { SearchField } from "../../../../Components/formElements/SearchField";


const CreatePurchaseOrder = () => {
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Create Purchase Order</h2>
                <p className="subText">Create Purchase Order </p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                //   value={getCommonSearchTxt}
                //   onChange={(event) => setCommonSearchTxt(event.target.value)}
                //   onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CreatePurchaseOrder;
