import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const IOMCreditNotePrint = React.forwardRef((props, ref) => {
  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store

  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "4px",
  };
  const printbody = {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  };
  const { data } = props;
  // console.log("IOMdata>>", data);

  return (
    <div ref={ref} style={printbody} className="m-4">
      <Row>
        <Col
          lg={12}
          className="d-flex justify-content-center g-2 align-items-end"
        >
          <img
            src={appConfig?.customerLogo1}
            alt="bharthiCementlog"
            // style={{width:"100px"}}
            className="img-fluid introCustomerlogo"
          />

          <p className="fs-2 fw-bold m-0 text-decoration-underline">
            {appConfig?.customerName}
          </p>
          {appConfig?.customerLogo2 && (
            <img
              src={appConfig?.customerLogo2}
              className="img-fluid introCustomerlogo"
              alt="logo2"
            />
          )}
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-center">
          <p className="fs-2 fw-bold text-center mt-2 text-decoration-underline">
            Bangalore
          </p>
        </Col>
        <Col lg={12} className="d-flex justify-content-end">
          <p className="fw-bold text-end mt-2">DATE : 11-03-2025 </p>
        </Col>
        <Col lg={12} className="d-flex justify-content-center">
          <p className="fw-bold text-center mt-2 text-decoration-underline">
            IOM-1169{" "}
          </p>
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-start">
          <p className="fw-bold text-start mt-2">To, </p>
          <p className="fw-bold text-start m-0">{((data) && (data?.salesOfficerName)) || ""}</p>
          <p className="fw-bold text-start m-0">Finance Department</p>
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-start">
          <p className="fw-bold text-start mt-2">
            Sub: Credit note for M/s. Urbanac Projects Pvt Ltd. For the shortage
            supply & Moisture Deduction.
          </p>
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-start mt-2">
          <p className="fw-bold text-start m-0">
            As above subject mentioned kindly issue a credit note to M/s.
            Urbanac Projects Pvt Ltd (U10008).
          </p>
        </Col>
      </Row>

      <table
        style={{ marginTop: "16px", width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr style={tableStyle}>
            <th style={tableStyle}>SL No</th>
            <th style={tableStyle}>Product</th>
            <th style={tableStyle}>Invoice Date</th>
            <th style={tableStyle}>Invoice Number</th>
            <th style={tableStyle}>Invoice Qunatity</th>
            <th style={tableStyle}>Rec Qty</th>
            <th style={tableStyle}>Diff Qty</th>
            <th style={tableStyle}>% of Qty</th>
            <th style={tableStyle}>Price</th>
            <th style={tableStyle}>Amount</th>
            <th style={tableStyle}>CGST 2.5%</th>
            <th style={tableStyle}>SGST 2.5%</th>
            <th style={tableStyle}>Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={item?.slNo} style={tableStyle}>
              <td style={tableStyle} className="text-end">
                {i+1}
              </td>
              <td style={tableStyle}>{item?.materialDescription}</td>
              <td style={tableStyle} className="text-end">
                {item?.dispInvoiceDate}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispInvoiceNumber}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispTotalQty}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.acceptedQtyMT}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.differenceQtyMT}
              </td>
              <td style={tableStyle} className="text-end">
                %
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispReceivedAmount}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispTotalAmount}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispRecivedDiffCGSTAmount}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispRecivedDiffSGSTAmount}
              </td>
              <td style={tableStyle} className="text-end">
                {item?.dispTotalAmount.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <table style={tableStyle} className="mt-4">
        <tr style={tableStyle}>
          <th style={tableStyle}>SL No</th>
          <th style={tableStyle}>Product</th>
          <th style={tableStyle}>Ex-Plant Rate</th>
          <th style={tableStyle}>Freight</th>
          <th style={tableStyle}>Route No</th>
          <th style={tableStyle}>Toll</th>
          <th style={tableStyle}>Commission</th>
          <th style={tableStyle}>Quote Price</th>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle}>M Sand</td>
          <td style={tableStyle} className="text-end">525.00</td>
          <td style={tableStyle} className="text-end">172.80</td>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle} className="text-end">20</td>
          <td style={tableStyle} className="text-end">10</td>
          <td style={tableStyle} className="text-end">727.80</td>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle} className="text-end">2</td>
          <td style={tableStyle}>12 MM</td>
          <td style={tableStyle} className="text-end">525.00</td>
          <td style={tableStyle} className="text-end">172.80</td>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle} className="text-end">20</td>
          <td style={tableStyle} className="text-end">10</td>
          <td style={tableStyle} className="text-end">727.80</td>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle} className="text-end">3</td>
          <td style={tableStyle}>20 MM</td>
          <td style={tableStyle} className="text-end">525.00</td>
          <td style={tableStyle} className="text-end">172.80</td>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle} className="text-end">20</td>
          <td style={tableStyle} className="text-end">10</td>
          <td style={tableStyle} className="text-end">727.80</td>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle} className="text-end">4</td>
          <td style={tableStyle}>P Sand</td>
          <td style={tableStyle} className="text-end">900.00</td>
          <td style={tableStyle} className="text-end">172.80</td>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle} className="text-end">20</td>
          <td style={tableStyle} className="text-end">25</td>
          <td style={tableStyle} className="text-end">1,117.80</td>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle} className="text-end">5</td>
          <td style={tableStyle}>GSB/WMM</td>
          <td style={tableStyle} className="text-end">510.00</td>
          <td style={tableStyle} className="text-end">172.80</td>
          <td style={tableStyle} className="text-end">1</td>
          <td style={tableStyle} className="text-end">20</td>
          <td style={tableStyle} className="text-end">10</td>
          <td style={tableStyle} className="text-end">712.80</td>
        </tr>
      </table> */}
      <Row>
        <Col lg={12} className="d-flex flex-column justify-content-start">
          <p className="fw-bold text-start mt-2">
            (In Words: Five Thousand One Hundred Sixty Eight Rupees & Seventy
            Three Paisa Only)
          </p>
          <p className="fw-bold text-start mt-2">Detail Enclosed</p>
        </Col>
        <Col lg={12} className="d-flex flex-column justify-content-start">
          <p className="fw-bold text-start mt-2 mb-0">
            Kindly acknowledge and do the needful.
          </p>
        </Col>
      </Row>

      {/* signature */}
      {/* signature  */}
      <table style={tableStyle} className="mt-4">
        <thead style={tableStyle}>
          <tr>
            <th style={tableStyle} className="text-center">
              <br />
              <br />
              <br />
            </th>
            <th style={tableStyle} className="text-center">
              <br />
              <br />
              <br />
            </th>
            <th style={tableStyle} className="text-center">
              <br />
              <br />
              <br />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableStyle}>
              <span className="fw-bold d-flex justify-content-center">
                Prepared by
              </span>
            </td>
            <td style={tableStyle}>
              <span className="fw-bold d-flex justify-content-center">
                Verified by
              </span>
            </td>
            <td style={tableStyle}>
              <spanc className="fw-bold d-flex justify-content-center">
                Approved by
              </spanc>
            </td>
          </tr>
          <tr>
            <td style={tableStyle}>
              <span className="fw-bold d-flex justify-content-center">
                Jaywant
              </span>
            </td>
            <td style={tableStyle}>
              <span className="fw-bold d-flex justify-content-center">
                Nagarjun Reddy
              </span>
            </td>
            <td style={tableStyle}>
              <spanc className="fw-bold d-flex justify-content-center">
                Srivallabha
              </spanc>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default IOMCreditNotePrint;
