import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../common/SupplierConstants";
import { addUserID } from "../../../redux/feature/userSlice";
// import configFromEnv from "../../../services/configuration";
// import Moment from "react-moment";

const ManageUserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Manage User");

  let userListData = useSelector((state) => state?.user.userList); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState([]);

  const navigateInvoiceDetial = () => {
    navigate(Constants.ADMIN_MANAGE_ADD_USER);
  };

  const detailedPage = (userId) => {
    dispatch(addUserID(userId));
    navigate(Constants.ADMIN_MANAGE_USER_LIST_DETAILS);
  };

  const userListColInfo = [
    {
      name: "UserId",
      selector: (row) => row.userId,
      sortable: true,
      cell: (row) => (
        <>
          <button className="btnInfo" onClick={() => detailedPage(row.userId)}>
            {row.userId}
          </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      cell: (row) => (
        <>
          {row.firstName} {row.lastName}
        </>
      ),
      width: "180px",
    },
    {
      name: "Contact",
      selector: (row) => row.contactNumber,
      sortable: true,
      cell: (row) => <>{row.contactNumber}</>,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <>{row.status}</>,
      width: "150px",
    },
    {
      name: "Roles",
      // cell: (row) => <>{row.roles.map(role => role.roleId).join(', ')}</>,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-info-status">
            {row?.roles?.map((role) => role?.roleId).join(", ")}
          </span>
        </>
      ),
      width: "200px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-info-status">
            {row.category}
          </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Action",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-danger"
            // onClick={() => removePOItemRow(row.local_id)}
          >
            De-activate
          </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-danger"
            // onClick={() => removePOItemRow(row.local_id)}
          >
            <i className="bi bi-trash3"></i>
          </button>
        </>
      ),
      width: "200px",
    },
    // {
    //   name: "Created",
    //   selector: (row) => row.updatedAt,
    //   sortable: true,
    //   cell: (row) => (
    //     <>
    //     {row.updatedAt}
    //       {/* {row.createdAt && (
    //         <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>
    //           {row.createdAt}
    //         </Moment>
    //       )} */}
    //     </>
    //   ),
    //   width: "180px",
    // },
  ];

  const loadInitialSuuplierData = async () => {
    setData(userListData?.data);
  };

  // const handlePageChange = (page) => {
  //   let obj = {
  //     page: page,
  //     per_page: getNewPerPage,
  //   };

  //   dispatch(getSupplierList(obj));
  //   setData(supplierData?.data);
  // };

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   let obj = {
  //     page: page,
  //     per_page: newPerPage,
  //   };
  //   setNewPerPage(newPerPage);
  //   dispatch(getSupplierList(obj));
  //   setData(supplierData?.data);
  // };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListData]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={8} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Manage User</h2>
                <p className="subText">Manage User Details</p>
              </Col>
              <Col xs={4} md={2} lg={2} className="mb-2">
                <button className="btn w-full" onClick={navigateInvoiceDetial}>
                  Add User
                </button>
              </Col>
              <hr />
            </Row>

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">UL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Manage Users List</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={userListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default ManageUserList;
