import React from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import GenerateForm from "../../../Components/formElements/GenerateQuestionaries/GenerateForm";

const AddFormModal = React.forwardRef((props, ref) => {

  // Handle form OK
  const okConfirm = async () => {
    props.onHide();
  };

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      //size="lg"
      ref={ref}
      //centered
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-add"></i>
          <span className="modal-title"> Add Form </span>
          {/* <p className='subText'>Confirm the below given statement</p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalSection">
        <Row>
          <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
            <GenerateForm
              saveconfirm={okConfirm}
              formType={props?.modeldata?.formType}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default AddFormModal;
