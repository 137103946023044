import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { validateRequired } from "../../../../common/Validations";
import toast from "react-hot-toast";
// import Spinner from "react-bootstrap/Spinner";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
};

const OrderCancelModal = React.forwardRef((props, ref) => {
  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  // const [uploadStatus, setUploadStatus] = useState(false);
  const [getData, setData] = useState();

  const [formInputs, setFormInputs] = useState({
    cancelledOrderQuantityLoad: "",
    cancelledOrderRemarks: "",
  });
  /* pop failure modal */
  const [validation, setValidation] = useState({
    cancelledOrderQuantityLoad: "",
    cancelledOrderRemarks: "",
  });
  // console.log(">>validation", validation);

  useEffect(() => {
    setFormInputs({
      cancelledOrderQuantityLoad: props?.data?.balOrderQuantityLoad,
      cancelledOrderRemarks: "",
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    setData(props?.data);
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    // console.log(">>name,value", value);
    const errors = {};

    if (
      name === "cancelledOrderQuantityLoad" &&
      Number(value) > getData?.balOrderQuantityLoad
    ) {
      toast.error(
        `Cancelled quantity cannot exceed ${getData?.balOrderQuantityLoad}`
      );
      return; // Prevents updating state if condition fails
    }

    setFormInputs((formInputs) => ({
      ...formInputs,
      [name]: name === "cancelledOrderQuantityLoad" ? Number(value) : value,
      balOrderQuantityLoad: getData?.balOrderQuantityLoad,
      orderSchedulingNr: getData?.documentNumber,
      orderStatus: "Cancelled",
    }));

    setValidation((prev) => ({
      ...prev,
      [name]: value === "" ? "" : errors[name] || "",
    }));
  };

  //handle save
  const handleSave = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      return;
    }

    if (formInputs.cancelledOrderQuantityLoad === 0) {
      toast.error(`Cancelled quantity cannot be "0"`);
      return; // Prevents updating state if condition fails
    }

    if (!validateRequired(formInputs.cancelledOrderQuantityLoad)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setValidation((prev) => ({
        ...prev,
        cancelledOrderQuantityLoad: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.cancelledOrderRemarks)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setValidation((prev) => ({
        ...prev,
        cancelledOrderRemarks: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }

    await ItemService.cancelOrder(formInputs).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "Cancel Order successfully", {
          duration: 2000,
          position: "top-right",
        });
        props.onSuccess();
        props.onHide();
      } else {
        console.log(">> Error: Loading cancelOrder");
      }
      // setDisabled(false);
    });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancel - {getData?.documentNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-right" : "bi-chevron-down"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">ID</span>
                </Col>

                <Col xs={4} md={4} lg={4}>
                  <span className="headerTitle"> Cancel details</span>
                  <span className="subHeaderTitle">Cancel details</span>
                </Col>
              </Row>
            </h2>

            <div
              // id="acc"
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Cancel Order Qty(Bags)"
                            type="number"
                            value={formInputs?.cancelledOrderQuantityLoad}
                            name="cancelledOrderQuantityLoad"
                            id="cancelledOrderQuantityLoad"
                            onChange={onInputChange}
                            error={validation.cancelledOrderQuantityLoad}
                            disabled={false}
                            required={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Order Cancel Remark *"
                            name="cancelledOrderRemarks"
                            id="cancelledOrderRemarks"
                            value={formInputs?.cancelledOrderRemarks}
                            onChange={onInputChange}
                            error={validation.cancelledOrderRemarks}
                            disabled={false}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Row className="mt-4">
            <Col
              lg={{ span: 1, offset: 11 }}
              className="d-flex justify-content-end"
            >
              <button className="btn" onClick={handleSave}>
                Save
              </button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default OrderCancelModal;
