import React, { useEffect, useState } from "react";
import { ComboboxField } from "../formElements/ComboboxField";
import { InputField } from "../formElements/InputField";

const TechLineItemTable = (props) => {
  const [quotationDocument, setQuotationDocument] = useState([]);
  const [rfxQuestions, setRfxQuestions] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [getPageStatus, setPageStatus] = useState(false);

  const dropdownOption = [
    {
      id: "approve",
      name: "Approve",
    },
    {
      id: "reject",
      name: "Reject",
    },
  ];

  useEffect(() => {
    if (
      !isInitialized &&
      props?.data?.length > 0 &&
      props?.data[0]?.rfxQuestions?.length > 0
    ) {
      // console.log(">> ITEM props >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", props);
      setQuotationDocument(props?.data[0]?.items[0]?.quotationDocument);
      setRfxQuestions(props?.data[0]?.items);
      setPageStatus(props?.pageStatus);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isInitialized]);

  useEffect(() => {
    // console.log(">> ITEM rfxQuestions", rfxQuestions);
    props?.sendDataToParent(rfxQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxQuestions]);

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">> data ss", getDropdownData);
    // eslint-disable-next-line array-callback-return
    const updatedData = rfxQuestions?.map((rfxItem) => {
      const updatedQuotationDocument = rfxItem.quotationDocument?.map(
        (item) => {
          // Check if the quotationNr matches, then update the score
          return item.quotationNr === getDropdownData?.textCode
            ? {
                ...item,
                [getDropdownData?.textField]: getDropdownData?.name, // Update the score
              }
            : item; // Return unchanged if quotationNr doesn't match
        }
      );

      // Return the updated rfxItem with the updated quotationDocument
      return rfxItem.rfxItemNr === getDropdownData?.index
        ? {
            ...rfxItem,
            quotationDocument: updatedQuotationDocument,
          }
        : rfxItem;
    });

    setRfxQuestions(updatedData);
    // console.log(">> updatedData", updatedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // Update Comments
  const handleCommentsInput = (quotationNr, rfxItemNr, value) => {
    const updatedData = rfxQuestions?.map((rfxItem) => {
      const updatedQuotationDocument = rfxItem.quotationDocument?.map(
        (item) => {
          // Check if the quotationNr matches, then update the score
          return item.quotationNr === quotationNr
            ? {
                ...item,
                comments: value, // Update the score
              }
            : item; // Return unchanged if quotationNr doesn't match
        }
      );

      // Return the updated rfxItem with the updated quotationDocument
      return rfxItem.rfxItemNr === rfxItemNr
        ? {
            ...rfxItem,
            quotationDocument: updatedQuotationDocument,
          }
        : rfxItem;
    });

    // console.log(">> handleCommentsInput updatedData", updatedData);
    setRfxQuestions(updatedData);
  };

  return (
    <div className={quotationDocument?.length > 4 ? 'generatedTable width-200' : 'generatedTable width-150'}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            {quotationDocument?.map((supplier, index) => (
              <th className="tableHeader text-center" colSpan={2} key={index}>
                <span className="bg-table-status bg-table-new-status px-2">
                  {supplier.organisationName} - {supplier.quotationNr}
                </span>
                <div className="mt-1">Version: {supplier.qtnVersion}</div>
              </th>
            ))}
          </tr>
          <tr>
            <th className="tableHeader _01">SL No</th>
            <th className="tableHeader _03">Purchase Document</th>
            <th className="tableHeader _03">Status</th>
            <th className="tableHeader _04">Material Code</th>
            <th className="tableHeader _04">Qty</th>
            <th className="tableHeader _04">Unit</th>
            {quotationDocument?.map((supplier, index) => (
              <>
                <th className="tableHeader _04">Approve/Reject</th>
                <th className="tableHeader _05">Comments</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {rfxQuestions?.map((question, index) => (
            <tr key={index}>
              <td className="_01">
                {question?.rfxItemNr || question?.local_id}
              </td>
              <td>{question?.purchaseReqDocumentNr}</td>
              <td className="text-center">
                {question?.prStatusItem === "PR Approved" ? (
                  <span className="bg-table-status bg-table-success-status d-flex justify-content-center">
                    {question?.prStatusItem}
                  </span>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {question?.materialCode} <br />
                {question?.materialDescription}
              </td>
              <td>{question?.purchaseQuantity}</td>
              <td>{question?.orderUnitOfMeasure}</td>
              {question?.quotationDocument?.map((supplier, supplierIndex) => (
                <>
                  <td key={supplierIndex}>
                    {!getPageStatus ? (
                      <ComboboxField
                        index={question?.rfxItemNr}
                        label=""
                        placeholder="Select"
                        data={dropdownOption}
                        id="approveReject"
                        iconClassName="dropdownIcon"
                        name="approveReject"
                        code={supplier?.quotationNr}
                        description="approveRejectTypeDesc"
                        setValue={supplier?.approveReject}
                        getvalue={setDropdownData}
                        className="dropdownOption"
                        disabled={getPageStatus}
                      />
                    ) : supplier?.approveReject === "Approve" ? (
                      <span className="bg-table-status bg-table-success-status d-flex justify-content-center">
                        {supplier?.approveReject}
                      </span>
                    ) : supplier?.approveReject === "Reject" ? (
                      <span className="bg-table-status bg-table-danger-status d-flex justify-content-center">
                        {supplier?.approveReject}
                      </span>
                    ) : (
                      supplier?.approveReject
                    )}
                  </td>
                  <td>
                    <InputField
                      type="text"
                      className="inputBox"
                      label=""
                      name="comments"
                      placeholder=""
                      value={supplier?.comments}
                      onChange={(e) =>
                        handleCommentsInput(
                          supplier?.quotationNr,
                          question?.rfxItemNr,
                          e.target.value
                        )
                      }
                      disabled={getPageStatus}
                    />
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TechLineItemTable;
