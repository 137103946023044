import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Serivce
import ItemService from "../../services/supplier_appCommonService";
import SuccessModal from "../Modal/supplier_app/SuccessModal";
import ApproveRemarksModal from "../Modal/supplier_app/ApproveRemarksModal";
import Constants from "../../common/SupplierConstants";

const WorkflowApproveReject = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [getAction, setAction] = useState("");

  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");

  useEffect(() => {
    if (
      !isInitialized
    ) {
    //   console.log(">> WorkflowApproveReject props >>>", props);
    //   console.log(">> formInputsData >>>", props?.formInputsData);
      setWorkflow(props?.workflowData);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isInitialized]);

  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const navigateToWorkflow = () => {
    window.history.back();
  };

  const showApproveModal = async (name) => {
    setModalShow(true);
    setAction(name);
  };

  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {...props?.formInputsData};
    let newObj = { ...bodyObj, ...obj };

    console.log(">> newObj", newObj);
    

    // Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        successModalData.successMsg =
          getAction === "Approve"
            ? "Approved Successfully !!"
            : "Rejected Successfully !!";
        setsucceModalData(successModalData);
        setNextNavigate(Constants.INBOX);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };

  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  //   const showServiceItemTable = (serviceData, itemNr) => {
  //     setItemNr(itemNr);
  //     setServiceData(serviceData);
  //     setServiceTableStatus(true);
  //   };
  //   const hide = () => {
  //     setServiceTableStatus(false);
  //   };

  return (
    <div className="workflow_section">
      <Row className="mb-4">
        <Col xs={12} md={4} lg={4} className="text-left">
          <button className="btnBack" onClick={() => navigateToWorkflow()}>
            <i className="bi bi-arrow-return-left"></i> Go Back
          </button>
        </Col>
        <Col xs={12} md={8} lg={8} className="text-right">
          {workflow?.workflow_record?.decision?.length > 0 &&
            workflow?.workflow_record?.decision?.map((item, index) => (
              <button
                className={
                  item === "Approve" || item === "Submit"
                    ? "btnCommon btn-approve"
                    : "btnCommon btn-danger"
                }
                onClick={() => showApproveModal(item)}
                key={index}
              >
                <i
                  className={
                    item === "Approve" || item === "Submit"
                      ? "bi bi-check2-circle"
                      : "bi bi-x-circle"
                  }
                ></i>{" "}
                {item}
              </button>
            ))}
        </Col>
      </Row>

      {/* Success Modal */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />

      <ApproveRemarksModal
        show={modalShow}
        onHide={() => hideModal()}
        onvaluechange={handleValueChange}
      />
    </div>
  );
};

export default WorkflowApproveReject;
