import { Col, Row } from "react-bootstrap";
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import HomeIntorVideo from "../../../Components/Modal/supplier_app/HomeIntro/HomeIntorVideo";
import { useState } from "react";
import HomeCategorieDetails from "../../../Components/Modal/supplier_app/HomeIntro/HomeCategorieDetails";

const HomeIntro = () => {
  const currentDate = new Date();
  localStorage.setItem("menu", "Home");
  localStorage.setItem("submenu", "-");

  // Format the date (optional)
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const [getIntroVideo, setIntroVideo] = useState(false);
  const [getCategoryDetail, setCategoryDetail] = useState(false);

  const [getVideoPath, setVideoPath] = useState();

  const introVideoShow = (getVideoPath) => {
    setIntroVideo(true);
    setVideoPath(getVideoPath);
  };

  const introVideoHide = () => {
    setIntroVideo(false);
  };

  const categoryDetailShow = () => {
    setCategoryDetail(true);
  };

  const categoryDetailHide = () => {
    setCategoryDetail(false);
  };
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row className="home-intro">
              <Col xs={12} md={12} lg={12} className="headers p-2 mb-2">
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <div className="welcome">
                      <p>Welcome to</p>
                      <span>Supplier Portal</span>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="date commTopButtonRightLeftPadding">
                      <p>Today Date:</p>
                      <InputField
                        className="inputBox"
                        label=""
                        name="Fuels"
                        id="Fuels"
                        placeholder=""
                        // type="date"
                        value={formattedDate}
                        // onChange={onInputChange}
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={12} className="card mb-2">
                <p className="title p-2 mb-0">My Category</p>
                <div className="d-flex">
                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card sales-card">
                      <div onClick={categoryDetailShow} className="card-body">
                        <h5 className="card-title">
                          Procurement <span>| Today</span>
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-cart"></i>
                          </div>
                          <div className="ps-3">
                            <h6>145</h6>
                            <span className="text-success small pt-1 fw-bold">
                              12%
                            </span>{" "}
                            <span className="text-muted small pt-2 ps-1">
                              increase
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card revenue-card">
                      <div onClick={categoryDetailShow} className="card-body">
                        <h5 className="card-title">
                          Revenue <span>| This Month</span>
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-currency-dollar"></i>
                          </div>
                          <div className="ps-3">
                            <h6>$3,264</h6>
                            <span className="text-success small pt-1 fw-bold">
                              8%
                            </span>{" "}
                            <span className="text-muted small pt-2 ps-1">
                              increase
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card customers-card">
                      <div onClick={categoryDetailShow} className="card-body">
                        <h5 className="card-title">
                          Supplier <span>| This Year</span>
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                          </div>
                          <div className="ps-3">
                            <h6>1244</h6>
                            <span className="text-danger small pt-1 fw-bold">
                              12%
                            </span>{" "}
                            <span className="text-muted small pt-2 ps-1">
                              decrease
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 commTopButtonRightLeftPadding">
                    <div className="card info-card customers-card">
                      <div onClick={categoryDetailShow} className="card-body">
                        <h5 className="card-title">
                          Total Users <span>| This Year</span>
                        </h5>

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                          </div>
                          <div className="ps-3">
                            <h6>254</h6>
                            <span className="text-danger small pt-1 fw-bold">
                              10%
                            </span>{" "}
                            <span className="text-muted small pt-2 ps-1">
                              decrease
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={12} className="mb-2">
                <Row className="homeintroposter">
                  <Col
                    xs={12}
                    md={6}
                    lg={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      className="img-fluid"
                      src="./assets/img/supplyChain.png"
                      alt="supplychiain"
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="postertext">
                    <p>End-to-End Supply Chain Management Platform</p>
                    <div className="smarthub">
                      {/* <p>Smart Hub</p> */}
                      <p>Intelligent, Scalable, Empowering</p>
                    </div>
                    <p>
                      Your all-in-one solution for optimizing <br /> operations
                      and maximizing productivity
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={12} className="mb-2">
                <Row>
                  <Col xs={12} md={12} lg={12} className="mb-2">
                    <div className="card m-0">
                      <div className="card-body">
                        <p className="title card-title">Notification</p>

                        <div
                          className="alert border-dark alert-dismissible fade show"
                          role="alert"
                        >
                          <p>A simple primary outlined alert—check it out!</p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          className="alert border-dark alert-dismissible fade show"
                          role="alert"
                        >
                          <p>A simple primary outlined alert—check it out!</p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          className="alert border-dark alert-dismissible fade show"
                          role="alert"
                        >
                          <p>A simple primary outlined alert—check it out!</p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          className="alert border-dark alert-dismissible fade show"
                          role="alert"
                        >
                          <p>A simple primary outlined alert—check it out!</p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          className="alert border-dark alert-dismissible fade show"
                          role="alert"
                        >
                          <p>A simple primary outlined alert—check it out!</p>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="card m-0 ">
                      <div className="card-body">
                        <h5 className="My Team card-title">My Team</h5>

                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Position</th>
                              <th scope="col">Categorie</th>
                              <th scope="col">Start Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Brandon Jacob</td>
                              <td>Designer</td>
                              <td>28</td>
                              <td>2016-05-25</td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>Bridie Kessler</td>
                              <td>Developer</td>
                              <td>35</td>
                              <td>2014-12-05</td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>Ashleigh Langosh</td>
                              <td>Finance</td>
                              <td>45</td>
                              <td>2011-08-12</td>
                            </tr>
                            <tr>
                              <th scope="row">4</th>
                              <td>Angus Grady</td>
                              <td>HR</td>
                              <td>34</td>
                              <td>2012-06-11</td>
                            </tr>
                            <tr>
                              <th scope="row">5</th>
                              <td>Raheem Lehner</td>
                              <td>Dynamic Division Officer</td>
                              <td>47</td>
                              <td>2011-04-19</td>
                            </tr>
                            <tr>
                              <th scope="row">6</th>
                              <td> Lehner Raheem </td>
                              <td>Dynamic Division Officer</td>
                              <td>47</td>
                              <td>2011-04-19</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={12} lg={12} className="headers mb-2">
                <p className="title p-2">Process</p>
                <div className="d-flex overflow-auto">
                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                          <h5 className="card-title">Vendor Management</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">     
                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                          <h5 className="card-title">Procurement</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        
                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-file-earmark-check"></i>
                          </div>
                          <h5 className="card-title">Sourcing</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-card-list"></i>
                          </div>
                          <h5 className="card-title">Contract</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/vendor_management.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                          <h5 className="card-title">Auction</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 commTopButtonRightLeftPadding">
                    <div
                      onClick={() =>
                        introVideoShow("./assets/videos/Spend_Analysis.mp4")
                      }
                      className="m-0 card info-card sales-card"
                    >
                      <div className="card-body">
                        

                        <div className="d-flex align-items-center">
                          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people-fill"></i>
                          </div>
                          <h5 className="card-title">Spend Analysis</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <div className="card">
                  <div className="card-body pb-0">
                    <p className="title card-title">
                      News &amp; Updates <span>| Today</span>
                    </p>
                    <div className="news">
                      <div className="post-item clearfix">
                        <img src="assets/img/news-1.jpg" alt="" />

                        <h4>
                          <span>Nihil blanditiis at in nihil autem</span>
                        </h4>
                        <p>
                          Sit recusandae non aspernatur laboriosam. Quia enim
                          eligendi sed ut harum...
                        </p>
                      </div>

                      <div className="post-item clearfix">
                        <img src="assets/img/news-2.jpg" alt="" />
                        <h4>
                          <span>Quidem autem et impedit</span>
                        </h4>
                        <p>
                          Illo nemo neque maiores vitae officiis cum eum turos
                          elan dries werona nande...
                        </p>
                      </div>

                      <div className="post-item clearfix">
                        <img src="assets/img/news-3.jpg" alt="" />
                        <h4>
                          <span>
                            Id quia et et ut maxime similique occaecati ut
                          </span>
                        </h4>
                        <p>
                          Fugiat voluptas vero eaque accusantium eos.
                          Consequuntur sed ipsam et totam...
                        </p>
                      </div>

                      <div className="post-item clearfix">
                        <img src="assets/img/news-4.jpg" alt="" />
                        <h4>
                          <span>Laborum corporis quo dara net para</span>
                        </h4>
                        <p>
                          Qui enim quia optio. Eligendi aut asperiores enim
                          repellendusvel rerum cuder...
                        </p>
                      </div>

                      <div className="post-item clearfix">
                        <img src="assets/img/news-5.jpg" alt="" />
                        <h4>
                          <span>Et dolores corrupti quae illo quod dolor</span>
                        </h4>
                        <p>
                          Odit ut eveniet modi reiciendis. Atque cupiditate
                          libero beatae dignissimos eius...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <HomeIntorVideo
        show={getIntroVideo}
        onHide={introVideoHide}
        videopath={getVideoPath}
      />
      <HomeCategorieDetails
        show={getCategoryDetail}
        onHide={categoryDetailHide}
      />
    </main>
  );
};
export default HomeIntro;
