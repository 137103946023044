import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputField } from "../../formElements/InputField";

const CreateInviteSupplierModal = React.forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    firstName: "",
    companyName: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    // Ensure all fields are filled
    if (formData.firstName && formData.companyName && formData.email) {
      props?.onSubmit(formData);
      props?.onHide();
    } else {
      alert("All fields are required.");
    }
  };

  useEffect(() => {
    // Reset form when modal is closed
    if (!props.show) {
      setFormData({
        firstName: "",
        companyName: "",
        email: "",
      });
    }
  }, [props.show, setFormData]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Invitation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <InputField
              className="inputBox"
              label="First Name"
              name="firstName"
              id="firstName"
              extraLabel=""
              placeholder="First Name"
              value={formData.firstName || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <InputField
              className="inputBox"
              label="Company Name"
              name="companyName"
              id="companyName"
              extraLabel=""
              placeholder="Company Name"
              value={formData.companyName || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
             <InputField
              className="inputBox"
              label="Email"
              name="email"
              id="email"
              extraLabel=""
              placeholder="test@gmail.com"
              value={formData.email || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleSubmit} className="btn btn-primary">
          Submit
        </button>
        <button onClick={props?.onHide} className="btn btn-danger">
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
});

export default CreateInviteSupplierModal;
