import React, { useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//API
import ItemService from "../../services/supplier_appCommonService";
import LoaderSmall from "../LoaderSmall";

const FileUploadComponent = ({
  allowedTypes = [],
  multiple = true,
  label,
  extraLabel = "",
  required,
  name,
  id,
  value,
  disabled,
  onSuccessUpload = () => {},
}) => {
  const fileInputRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const filteredFiles = files.filter((file) =>
      allowedTypes.includes(file.type)
    );

    const fileLinks = filteredFiles.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));

    if (filteredFiles.length !== files.length) {
      alert("Some files were not accepted due to unsupported file types.");
    }

    console.log(">> filteredFiles", filteredFiles);

    setSelectedFiles((prevFiles) =>
      multiple ? [...prevFiles, ...filteredFiles] : filteredFiles
    );

    setFileLinks((prevLinks) =>
      multiple ? [...prevLinks, ...fileLinks] : fileLinks
    );
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, fileIndex) => fileIndex !== index)
    );
  };

  const uploadFiles = async () => {
    setUploadStatus(true);
    if (selectedFiles) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file); // 'files' is the field name
      });

      if (value) {
        formData.append("attachmentId", value);
      }

      await ItemService.uploadFiles(formData).then((item) => {
        // console.log(">> uploadFiles ", item);
        item.fieldName = name;
        item.fieldId = id;
        onSuccessUpload(item);
        getUploadedFiles(item?.attachmentId);
      });
      // setSelectedFiles([]);
      setUploadStatus(false);
    } else {
      // alert('Please select a file first.');
    }
  };

  // View uploaded files for the attachment ID
  // eslint-disable-next-line no-unused-vars
  const getUploadedFiles = async (attachmentId) => {
    if (attachmentId) {
      let obj = {
        attachmentId,
      };
      await ItemService.getUploadedFiles(obj).then((item) => {
        // console.log(">> getUploadedFiles ", item.data);
        setUploadedFiles(item.data);
      });
    } else {
      // alert('Please select a file first.');
    }
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      alert("No files selected for upload.");
      return;
    }
    uploadFiles();
  };

  const selectFile = () => {
    fileInputRef.current.click();
  };

  const getURL = (name) => {
    const file = fileLinks.find((item) => item.name === name);
    return file ? file.url : null; // Return the URL if found, otherwise return null
  };

  // console.log(">> fileLinks", fileLinks);

  return (
    <div>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
            {label} {required ? (label ? "*" : "") : ""}{" "}
            {extraLabel !== "" ? (
              <i className="bi bi-info-circle" title={extraLabel}></i>
            ) : (
              ""
            )}
          </label>
        </Col>
        <Col xs={6} md={6} lg={6}>
          {disabled ? (
            <div className="fileChooseDisabled">
              <i className="bi bi-upload mr-2"></i>
              Choose File
            </div>
          ) : (
            <div onClick={selectFile} className="fileChoose">
              <i className="bi bi-upload mr-2"></i>
              Choose File
            </div>
          )}
        </Col>
        {disabled ? (
          <Col xs={6} md={6} lg={6} className="commLeftRightPadding">
            <button
              onClick={handleUpload}
              className="btnTable"
              style={{ width: "100%" }}
              disabled={disabled}
            >
              <i class="bi bi-upload"></i> Upload Files
            </button>
          </Col>
        ) : (
          <Col xs={6} md={6} lg={6} className="commLeftRightPadding">
            <LoaderSmall isLoading={uploadStatus} />
            {!uploadStatus && (
              <button
                onClick={handleUpload}
                className="btnTable"
                style={{ width: "100%" }}
                disabled={uploadStatus}
              >
                <i class="bi bi-upload"></i> Upload Files
              </button>
            )}
          </Col>
        )}
      </Row>

      <input
        className="inputBox"
        type="file"
        ref={fileInputRef}
        accept={allowedTypes.join(",")}
        multiple={multiple}
        onChange={handleFileChange}
        style={{ marginBottom: "10px", display: "none" }}
      />
      {selectedFiles.length > 0 && (
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index} className="fileUploadedList">
              <i className="bi bi-paperclip"></i>{" "}
              <span style={{ flex: 1 }}>{file.name}</span>
              <a
                href={getURL(file.name)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ flex: 1 }}
              >
                <i className="bi bi-cloud-arrow-down fileDownload"></i>
              </a>
              <i
                className="bi bi-x-circle fileRemoveIcon"
                onClick={() => handleRemoveFile(index)}
              ></i>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FileUploadComponent;
