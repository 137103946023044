/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
    data:[],
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getConstantVariable.fulfilled, (state,action)=>{
            state.data = action.payload;
        })
    }
  });

// export const { addCategorySelectedData } = commonSlice.actions;
export default commonSlice.reducer;

export const getConstantVariable = createAsyncThunk('getConstantVariable', async () =>{
    console.log(">> loaded getConstantVariable");
    const res = await ItemService.getConstantVariable();
    return res.data;
})

