import Spinner from "react-bootstrap/Spinner";

const ConfirmationLoader = ({ isPageLoading }) => {

  return (
    <>
       {isPageLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p style={{ marginTop: "10px", fontSize: "1.2rem" }}>Loading...</p>
        </div>
      )}
    </>
  );
};

export default ConfirmationLoader;
