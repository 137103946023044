import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import { ComboboxField } from "../Components/formElements/ComboboxField";
import CustomerSelfRegistration from "./customer_app/Customer/CustomerMaster/CustomerSelfRegistration";
import SupplierSelfRegistration from "./supplier_app/Supplier/SupplierMaster/SupplierSelfRegistration";

const SelfRegistration = () => {
  const [getDropdownData, setDropdownData] = useState("");
  const [getType, setType] = useState(false);

  let dropdownOptions = [
    {
      id: "1",
      name: "SUPPLIER",
    },
    {
      id: "2",
      name: "CUSTOMER",
    },
  ];

  useEffect(() => {
    console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.name === "SUPPLIER") {
      setType(getDropdownData?.name);
    } else {
      setType(getDropdownData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  return (
    <main className="p-4">
      <div className="rightSideInner PageCommonCSS">
        <Row className="mb-4">
          <Col xs={12} md={3} className="rightSide">
            <h2 className="mainTitle">Self Registration Form</h2>
            <p className="subText">
              Register as a new or Sub-Contractor with our Company
            </p>
          </Col>
          <Col xs={12} md={3} className="rightSide">
            <ComboboxField
              label=""
              placeholder="Please select the type"
              data={dropdownOptions}
              id="type"
              iconClassName="dropdownIcon"
              name="type"
              code="typeCode"
              description="countryDesc"
              //   setValue={formInputs.country}
              getvalue={setDropdownData}
            />
          </Col>
        </Row>
        <hr />

        {getType === "SUPPLIER" && <SupplierSelfRegistration />}
        {getType === "CUSTOMER" && <CustomerSelfRegistration />}
      </div>
    </main>
  );
};

export default SelfRegistration;
