import React, { useEffect, useState } from "react";
// import { ComboboxField } from "../formElements/ComboboxField";
import { InputField } from "../formElements/InputField";
import CommonItemLevelDatatableModal from "../Modal/supplier_app/CommonItemLevelDatatableModal";
import { formatPriceValue } from "../../common/Validations";

const CommLineItemTable = (props) => {
  const [quotationDocument, setQuotationDocument] = useState([]);
  const [rfxQuestions, setRfxQuestions] = useState([]);
  // const [getDropdownData, setDropdownData] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  const [getPageStatus, setPageStatus] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const lineItemColInfo = [
    {
      name: "Item Text Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Item Text",
      cell: (row) => <span>{row.prItemNr} </span>,
      width: "250px",
    },
  ];

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  // const dropdownOption = [
  //   {
  //     id: "approve",
  //     name: "Approve",
  //   },
  //   {
  //     id: "reject",
  //     name: "Reject",
  //   },
  // ];

  useEffect(() => {
    if (
      !isInitialized &&
      props?.data?.length > 0 &&
      props?.data[0]?.rfxQuestions?.length > 0
    ) {
      console.log(">> COMM ITEM props >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", props);
      setQuotationDocument(props?.data[0]?.items[0]?.quotationDocument);
      setRfxQuestions(props?.data[0]?.items);
      setPageStatus(props?.pageStatus);
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, isInitialized]);

  useEffect(() => {
    // console.log(">> ITEM rfxQuestions", rfxQuestions);
    props?.sendDataToParent(rfxQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxQuestions]);

  // Get Dropdown data select
  // useEffect(() => {
  //   // console.log(">> data ss", getDropdownData);
  //   // eslint-disable-next-line array-callback-return
  //   const updatedData = rfxQuestions?.map((rfxItem) => {
  //     const updatedQuotationDocument = rfxItem.quotationDocument?.map(
  //       (item) => {
  //         // Check if the quotationNr matches, then update the score
  //         return item.quotationNr === getDropdownData?.textCode
  //           ? {
  //               ...item,
  //               [getDropdownData?.textField]: getDropdownData?.name, // Update the score
  //             }
  //           : item; // Return unchanged if quotationNr doesn't match
  //       }
  //     );

  //     // Return the updated rfxItem with the updated quotationDocument
  //     return rfxItem.rfxItemNr === getDropdownData?.index
  //       ? {
  //           ...rfxItem,
  //           quotationDocument: updatedQuotationDocument,
  //         }
  //       : rfxItem;
  //   });

  //   setRfxQuestions(updatedData);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getDropdownData]);

  // Update Comments
  // eslint-disable-next-line no-unused-vars
  const handleCommentsInput = (quotationNr, rfxItemNr, value) => {
    // eslint-disable-next-line no-unused-vars
    const updatedData = rfxQuestions?.map((rfxItem) => {
      const updatedQuotationDocument = rfxItem.quotationDocument?.map(
        (item) => {
          // Check if the quotationNr matches, then update the score
          return item.quotationNr === quotationNr
            ? {
                ...item,
                comments: value, // Update the score
              }
            : item; // Return unchanged if quotationNr doesn't match
        }
      );

      // Return the updated rfxItem with the updated quotationDocument
      return rfxItem.rfxItemNr === rfxItemNr
        ? {
            ...rfxItem,
            quotationDocument: updatedQuotationDocument,
          }
        : rfxItem;
    });
  };


    // NEED TO WORK ON THIS ITEM TABLE ------------------------------------------------------------------------------- 


  // Update Award Qty
  const handleAwardQtyInput = (
    purchaseQuantity,
    quotationNr,
    rfxItemNr,
    value
  ) => {
    const updatedData = rfxQuestions?.map((rfxItem) => {
      const updatedQuotationDocument = rfxItem.quotationDocument?.map(
        (item) => {
          // Check if the quotationNr matches, then update the score
          return item.quotationNr === quotationNr
            ? {
                ...item,
                // awardTotalQtyRow:Number(Number(item?.awardTotalQtyRow || 0) + Number(value)),
                awardQty: Number(value), // Update the score
              }
            : item; // Return unchanged if quotationNr doesn't match
        }
      );

      // Return the updated rfxItem with the updated quotationDocument
      return rfxItem.rfxItemNr === rfxItemNr
        ? {
            ...rfxItem,
            // awardTotalQtyRow: value,
            quotationDocument: updatedQuotationDocument,
          }
        : rfxItem;
    });

    console.log(">> handleCommentsInput updatedData", updatedData);
    setRfxQuestions(updatedData);
    // addScoreToItem(rfxItemNr, value);
  };

  const addScoreToItem = (rfxItemNr, value) => {
    console.log(">> rfxItemNr", rfxItemNr);
    console.log(">> value", value);
    setRfxQuestions((prevItems) =>
      prevItems.map((item) =>
        item.rfxItemNr === rfxItemNr ? { ...item, awardTotalQtyRow: Number(Number(item.awardTotalQtyRow || 0) + Number(value)) } : item
      )
    );

    // setRfxQuestions((prevRows) =>
    //   prevRows.map((row) => {
    //     if (row.rfxItemNr === rfxItemNr) {
    //       const updatedRow = {
    //         ...row,
    //         [`name_${rfxItemNr}`]: parseFloat(value) || 0, // Ensure value is a number or 0 if invalid
    //       };
    //       updatedRow.awardTotalQtyRow = updatedRow.input1 + updatedRow.input2 + updatedRow.input3; // Recalculate total
    //       return updatedRow;
    //     }
    //     return row;
    //   })
    // );

  };

  return (
    <div
      className={
        quotationDocument?.length > 4
          ? "generatedTable width-400"
          : "generatedTable width-200"
      }
    >
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            <th className="tableHeader"></th>
            {quotationDocument?.map((supplier, index) => (
              <th className="tableHeader text-center" colSpan={7} key={index}>
                <span className="bg-table-status bg-table-new-status px-2">
                  {supplier.organisationName} - {supplier.quotationNr}
                </span>
                <div className="mt-1">Version: {supplier.qtnVersion}</div>
              </th>
            ))}
          </tr>
          <tr>
            <th className="tableHeader _01">SL No</th>
            <th className="tableHeader _03">Purchase Document</th>
            <th className="tableHeader _03">Status</th>
            <th className="tableHeader _04">Material Code</th>
            <th className="tableHeader _04">Qty</th>
            <th className="tableHeader _04">Unit</th>
            <th className="tableHeader _04">Item Text</th>
            {quotationDocument?.map((supplier, index) => (
              <React.Fragment key={index + 1}>
                <th className="tableHeader _04">Tech Eval</th>
                <th className="tableHeader _05">Initial Price</th>
                <th className="tableHeader _05">Final Price</th>
                <th className="tableHeader _05">Final Price (RFQ CURR)</th>
                <th className="tableHeader _05">Net Unit Price</th>
                <th className="tableHeader _05">Award Qty</th>
                <th className="tableHeader _05">Award Value</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {rfxQuestions?.map((question, index) => (
            <tr key={index + 1}>
              <td className="_01">
                {question?.rfxItemNr || question?.local_id}
              </td>
              <td>{question?.purchaseReqDocumentNr}</td>
              <td className="text-center">
                {question?.prStatusItem === "PR Approved" ? (
                  <span className="bg-table-status bg-table-success-status d-flex justify-content-center">
                    {question?.prStatusItem}
                  </span>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {question?.materialCode} <br />
                {question?.materialDescription}
              </td>
              <td>{question?.purchaseQuantity}</td>
              <td>{question?.orderUnitOfMeasure}</td>
              <td>
                <span
                  style={{ marginLeft: "12px" }}
                  onClick={() => lineItemShowModal(question?.itemTextData)}
                >
                  <i className="bi bi-calendar2-week iconTable"></i>
                </span>
                <span className="suppliersIcon">
                  {question?.itemTextData?.length}
                </span>
              </td>
              {question?.quotationDocument?.map((supplier, supplierIndex) => (
                <React.Fragment>
                  <td>
                    {supplier?.approveReject === "Approve" ? (
                      <span className="bg-table-status bg-table-success-status d-flex justify-content-center">
                        {supplier?.approveReject}
                      </span>
                    ) : supplier?.approveReject === "Reject" ? (
                      <span className="bg-table-status bg-table-danger-status d-flex justify-content-center">
                        {supplier?.approveReject}
                      </span>
                    ) : (
                      supplier?.approveReject || "-"
                    )}
                  </td>
                  <td className="text-end">
                    {formatPriceValue(supplier?.quotationUnitPrice)}
                  </td>
                  <td className="text-end">
                    {formatPriceValue(supplier?.quotationUnitPrice)}
                  </td>
                  <td className="text-end">
                    {formatPriceValue(supplier?.quotationUnitPrice)}
                  </td>
                  <td className="text-end">
                    {formatPriceValue(supplier?.qtnitemValue)}
                  </td>
                  <td>
                    <InputField
                      type="number"
                      className="inputBox"
                      label=""
                      name="awardQty"
                      placeholder=""
                      value={supplier?.awardQty}
                      onChange={(e) =>
                        handleAwardQtyInput(
                          question?.purchaseQuantity,
                          supplier?.quotationNr,
                          question?.rfxItemNr,
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        addScoreToItem(
                          // question?.purchaseQuantity,
                          // supplier?.quotationNr,
                          question?.rfxItemNr,
                          e.target.value
                        )
                      }
                      disabled={getPageStatus}
                    />
                  </td>
                  <td>66</td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </div>
  );
};

export default CommLineItemTable;
