import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import { InputField } from "../../../../Components/formElements/InputField";
import { colData } from "./ReportTableColumns";

const SupplierReportsDetails = () => {
  localStorage.setItem("menu", "Report");
  localStorage.setItem("submenu", "-");

  let reportInfo = useSelector((state) => state?.report?.data); // Report Details from Store
  // let purchaseOrderData = useSelector(
  //   (state) => state?.purchasing?.purchase_order
  // );
  let purchaseOrderTable = useSelector(
    (state) => state?.purchasing?.purchase_order?.pagination
  );

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [isCardVisible1, setCardVisible1] = useState(false);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const loadReport = async () => {
    if (reportInfo) {
      let obj = {
        queryName: reportInfo?.queryName,
        page: 1,
        per_page: 100,
        rowFilter: {},
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.generateReportQuery(obj).then((item) => {
        console.log(">> res!", item);
        if (item?.status === 200) {
          setTableData(item?.data);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    if (reportInfo) {
      const reportCol = colData.find((data) => data.name === reportInfo?.queryName);
      if (reportCol) {
        setColumns(reportCol.columns);
      }
      loadReport();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInfo]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">{reportInfo?.name}</h2>
            <p className="subText">Complete Details of the Reports</p>
            <hr />

            {/* Filter Section */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="vendor"
                          name="vendor"
                          placeholder=""
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="plant"
                          name="plant"
                          placeholder=""
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc No(From)"
                          name="Doc No(From)"
                          placeholder=""
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc No(To)"
                          name="Doc No(To)"
                          placeholder=""
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc Date(From)"
                          name="Doc Date(From)"
                          placeholder=""
                          type="date"
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc Date(To)"
                          name="Doc Date(To)"
                          placeholder=""
                          type="date"
                          //   value={formInputs.contactfirstName || ""}
                          //   onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Search</button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Referesh</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Datatable Section */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">R</span>
                    </Col>
                    <Col xs={6} md={4} lg={4}>
                      <span className="headerTitle"> Reports</span>
                      <span className="subHeaderTitle">Reports and Status</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={tableData}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={purchaseOrderTable.total}
                          //   onChangeRowsPerPage={handlePerRowsChange}
                          //   onChangePage={handlePageChange}
                          //   onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SupplierReportsDetails;
