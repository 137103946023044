/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/supplier_appCommonService";

const initialState = {
  purchase_order: {
    data: [],
    status: true,
    workflowPO: [],
    selectedPoDetails:{},
    poNumber: "",
    invoiceNumber:"",
    sesNumber:"",
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
    service_po:{
      data: [],
      status: true,
      workflowPO: [],
      selectedPoDetails:{},
      pagination: {
        page: "",
        per_page: "",
        total: "",
      },
    },
    scheduling_agreement:{
      data: [],
      status: true,
      workflowPO: [],
      selectedPoDetails:{},
      pagination: {
        page: "",
        per_page: "",
        total: "",
      },
    },
    itemList:[]
  },
  single_purchase_order: {
    data: {},
    status: true,
  },
  goods_received_note: {
    data: [],
    status: true,
    grnNumber: "",
    asnNumber: "",
    subTransactionActivity: "",
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  asn: {
    data: [],
    status: true,
    asnNumber: "",
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  invoice: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  ses: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  purchaseDocType: "",
  purchaseDocCode: "",
  // status:true,
};

export const purchasingSlice = createSlice({
  name: "purchasing",
  initialState,
  reducers: {
    addRfqData(state, action) {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    },
    workflowPO(state, action) {
      state.purchase_order.workflowPO = action.payload;
    },
    addPoDetailNumber(state, action) {
      state.purchase_order.poNumber = action.payload;
    },
    addPoSelectedDetails(state, action) {
      state.purchase_order.selectedPoDetails = action.payload;
    },
    addASNDetailNumber(state, action) {
      state.asn.asnNumber = action.payload;
    },
    addGRNDetailNumber(state, action) {
      state.goods_received_note.grnNumber = action.payload;
    },
    addInvoiceDetailNumber(state, action) {
      state.purchase_order.invoiceNumber = action.payload;
    },    
    addSesDetailNumber(state, action) {
      state.purchase_order.sesNumber = action.payload;
    },
    addPurchaseDocType(state, action) {
      state.purchaseDocType = action.payload.name;
      state.purchaseDocCode = action.payload.code;
    },

    addSubTransactionActivity(state, action) {
      state.goods_received_note.asnNumber = action.payload?.asnNumber;
      state.goods_received_note.subTransactionActivity =
        action.payload?.subTransactionActivity;
    },
    addSelectedItemList(state, action) {
      state.purchase_order.itemList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPurchaseOrder.pending, (state, action) => {
        state.purchase_order.status = true;
      })
      .addCase(getPurchaseOrder.fulfilled, (state, action) => {
        state.purchase_order.data = action.payload.data;
        state.purchase_order.pagination.page = action.payload.page;
        state.purchase_order.pagination.per_page = action.payload.per_page;
        state.purchase_order.pagination.total = action.payload.total;
        state.purchase_order.status = false;
      })
      .addCase(getServicePO.pending, (state, action) => {
        state.purchase_order.service_po.status = true;
      })
      .addCase(getServicePO.fulfilled, (state, action) => {
        state.purchase_order.service_po.data = action.payload.data;
        state.purchase_order.service_po.pagination.page = action.payload.page;
        state.purchase_order.service_po.pagination.per_page = action.payload.per_page;
        state.purchase_order.service_po.pagination.total = action.payload.total;
        state.purchase_order.service_po.status = false;
      })
      .addCase(getSchedulingAgreement.pending, (state, action) => {
        state.purchase_order.scheduling_agreement.status = true;
      })
      .addCase(getSchedulingAgreement.fulfilled, (state, action) => {
        state.purchase_order.scheduling_agreement.data = action.payload.data;
        state.purchase_order.scheduling_agreement.pagination.page = action.payload.page;
        state.purchase_order.scheduling_agreement.pagination.per_page = action.payload.per_page;
        state.purchase_order.scheduling_agreement.pagination.total = action.payload.total;
        state.purchase_order.scheduling_agreement.status = false;
      })
      .addCase(getASN.pending, (state, action) => {
        state.asn.status = true;
      })
      .addCase(getASN.fulfilled, (state, action) => {
        state.asn.data = action.payload.data;
        state.asn.pagination.page = action.payload.page;
        state.asn.pagination.per_page = action.payload.per_page;
        state.asn.pagination.total = action.payload.total;
        state.asn.status = false;
      })
      .addCase(getGoodsReceivedNote.pending, (state, action) => {
        state.goods_received_note.status = true;
      })
      .addCase(getGoodsReceivedNote.fulfilled, (state, action) => {
        state.goods_received_note.data = action.payload.data;
        state.goods_received_note.pagination.page = action.payload.page;
        state.goods_received_note.pagination.per_page = action.payload.per_page;
        state.goods_received_note.pagination.total = action.payload.total;
        state.goods_received_note.status = false;
      })
      .addCase(getInvoiceList.pending, (state, action) => {
        state.invoice.status = true;
      })
      .addCase(getInvoiceList.fulfilled, (state, action) => {
        state.invoice.data = action.payload.data;
        state.invoice.pagination.page = action.payload.page;
        state.invoice.pagination.per_page = action.payload.per_page;
        state.invoice.pagination.total = action.payload.total;
        state.invoice.status = false;
      })
      .addCase(getSesList.pending, (state, action) => {
        state.ses.status = true;
      })
      .addCase(getSesList.fulfilled, (state, action) => {
        state.ses.data = action.payload.data;
        state.ses.pagination.page = action.payload.page;
        state.ses.pagination.per_page = action.payload.per_page;
        state.ses.pagination.total = action.payload.total;
        state.ses.status = false;
      })
  },
});

export const { addUser } = purchasingSlice.actions;
export default purchasingSlice.reducer;

export const {
  addPoDetailNumber,
  addPoSelectedDetails,
  addASNDetailNumber,
  addPurchaseDocType,
  workflowPO,
  addSubTransactionActivity,
  addGRNDetailNumber,
  addInvoiceDetailNumber,
  addSesDetailNumber,
  addSelectedItemList,
} = purchasingSlice.actions;

export const getPurchaseOrder = createAsyncThunk("getPurchaseOrder", async (param) => {
    console.log(">> loaded getPurchaseOrder");
    const res = await ItemService.getPurchaseOrder(param);
    return res;
  }
);
export const getServicePO = createAsyncThunk("getServicePO", async (param) => {
  console.log(">> loaded getServicePO");
  const res = await ItemService.getPurchaseOrder(param);
  return res;
}
);
export const getSchedulingAgreement = createAsyncThunk("getSchedulingAgreement", async (param) => {
  console.log(">> loaded getSchedulingAgreement");
  const res = await ItemService.getPurchaseOrder(param);
  return res;
}
);
export const getGoodsReceivedNote = createAsyncThunk("getGoodsReceivedNote", async (param) => {
    console.log(">> loaded getGoodsReceivedNote");
    const res = await ItemService.getGoodsReceivedNote(param);
    return res;
  }
);
export const getASN = createAsyncThunk("getASN", async (param) => {
  console.log(">> loaded getASN");
  const res = await ItemService.getASN(param);
  return res;
});

export const getInvoiceList = createAsyncThunk("getInvoiceList", async (param) => {
    console.log(">> loaded getInvoiceList");
    const res = await ItemService.getInvoiceList(param);
    return res;
});

export const getSesList = createAsyncThunk("getSesList", async (param) => {
    console.log(">> loaded getSesList");
    const res = await ItemService.getSesList(param);
    return res;
});
