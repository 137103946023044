// src/appDataUtils.js

import { getLoginUserData, getUsersListData} from './redux/feature/userSlice';
import { getRfqQuotation, getPurchaseRequisition, getQuotation } from './redux/feature/supplier_app/sourcingSlice';
// import { getSupplierList} from './redux/feature/supplier_app/supplierSlice';
import { getPurchaseOrder, getGoodsReceivedNote, getASN, getInvoiceList, getSesList, getSchedulingAgreement, getServicePO } from './redux/feature/supplier_app/purchasingSlice';
import { getGatepassList} from './redux/feature/supplier_app/gatepassSlice';
import { getMenuOnUserRole } from './redux/feature/supplier_app/menuSlice';
import { getConstantVariable } from './redux/feature/supplier_app/commonSlice';
import { getSingleSourcingList } from './redux/feature/supplier_app/singleSourcingSlice';

export const initializeAppData = (dispatch, defaultAppCode) => {
    if (defaultAppCode !== '') {
        // console.log(">> AppDataUtils >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", defaultAppCode);

        // Dispatch actions for setting menu and constants
        const menuPayload = { application: defaultAppCode };
        dispatch(getMenuOnUserRole(menuPayload));
        dispatch(getConstantVariable({}));
        dispatch(getLoginUserData({}));
        dispatch(getUsersListData(menuPayload));

        // Check for specific user data and dispatch additional actions if needed
        if (defaultAppCode === 'SUPPLIER') {
            const materialPO = { queryName: "getMaterialPO", module: "PO" };
            const servicePO = { queryName: "getServicePO", module: "PO" };
            const schedulingAgreement = { queryName: "getSchedulingAgreement", module: "PO" };

            dispatch(getRfqQuotation({}));
            dispatch(getPurchaseRequisition({}));
            dispatch(getQuotation({}));
            dispatch(getPurchaseOrder(materialPO));
            dispatch(getServicePO(servicePO));
            dispatch(getSchedulingAgreement(schedulingAgreement));
            dispatch(getASN({}));
            dispatch(getGoodsReceivedNote({}));
            dispatch(getInvoiceList({}));
            dispatch(getSesList({}));
            dispatch(getGatepassList({}));
            dispatch(getSingleSourcingList({}));

        } else if(defaultAppCode === 'CUSTOMER'){
            
        }

    }
};
