import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { ComboboxField } from "../formElements/ComboboxField";

const TableDropdownMenuModal = React.forwardRef((props, ref) => {
  const [getDropdownData, setDropdownData] = useState("");
  const [formInputs, setFormInputs] = useState({});
  const [selectedValue, setFinalSelectedValue] = useState({});
  const [propsLoaded, setPropsLoaded] = useState(true);

  useEffect(() => {
    if (propsLoaded) {
      setFormInputs(props?.modaldata);
      setPropsLoaded(true);
    }

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modaldata]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    setFinalSelectedValue((formInputs) => ({
      // [getDropdownData?.textField]: getDropdownData?.name,
      // [getDropdownData?.textCode]: getDropdownData?.code || "",
      // [getDropdownData?.textDesc]: getDropdownData?.description || "",
      "index": getDropdownData?.index,
      "name": getDropdownData?.textField,
      "value": getDropdownData?.name,
      "code" :getDropdownData?.textCode,
      "codeValue": getDropdownData?.code || "",
      "desc": getDropdownData?.textDesc,
      "descValue": getDropdownData?.description || "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const handleSave = () => {
    let obj ={
      // index:formInputs.fieldIndex,
      // name:formInputs.fieldName,
      // value:formInputs.fieldValue
      ...selectedValue
    }
    props.onsuccess(obj);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xs"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select your option
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <Row>
          <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
            <ComboboxField
              index={formInputs?.index}
              label={formInputs?.label}
              placeholder=""
              data={formInputs?.data}
              id={formInputs?.id}
              iconClassName="dropdownIcon"
              name={formInputs?.name}
              code={formInputs?.code}
              description={formInputs?.description}
              setValue={formInputs?.projectName}
              getvalue={setDropdownData}
              required={formInputs?.required}
              // error={validation.country}
            />
          </Col>
        </Row>
        <Row className="justify-end mt-4">
          <Col xs={4} md={3} lg={3} className="commLeftRightPadding">
            <button
              type="button"
              onClick={() => props.onHide()}
              className="btn btn-danger w-full justify-center rounded-md"
            >
              Cancel
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="commLeftRightPadding">
            <button
              type="button"
              onClick={() => handleSave()}
              className="btn btn-primary w-full justify-center rounded-md"
            >
              Save
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default TableDropdownMenuModal;
