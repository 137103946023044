import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import GHGScope1CommonFillter from "../../../Components/Filter/GHGScope1/GHGScope1CommonFillter";
import DataTable from "react-data-table-component";
import { InputField } from "../../../Components/formElements/InputField";

const CreateGHGScop1 = () => {
  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  /* fuels */
  const fuelsData = [
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "0.44423",
      amount: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "1.15623",
      amount: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "1.55709",
      amount: "",
      KgCO2e: "--",
    },
  ];
  const fuelsColumns = [
    {
      name: "",
      cell: (row) => <></>,
      width: "10px",
    },
    {
      name: "Profile",
      cell: (row) => <>{row.profile}</>,
      width: "150px",
    },
    {
      name: "Type",
      cell: (row) => <>{row.type}</>,
      width: "150px",
    },
    {
      name: "Fuel",
      cell: (row) => <>{row.fuel}</>,
      width: "100px",
    },
    {
      name: "Unit",
      cell: (row) => (
        <div className="mt-2">
          <InputField
            // className="inputBox"
            label=""
            name="unit"
            id="unit"
            placeholder=""
            // value={formInputs.unit || ""}
            // onChange={onInputChange}
            // disabled={getDisabledStatus}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "factors",
      cell: (row) => <>{row.factors}</>,
      width: "150px",
    },
    {
      name: "KgCO2e",
      cell: (row) => <>{row.KgCO2e}</>,
      width: "150px",
    },
  ];

  /* bioenergy */
  const bioenergyData = [
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "0.00901",
      amount: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "0.16751",
      amount: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "Gaseous Fuels",
      type: "Gaseous Fuels",
      fuel: "CNG",
      unit: "litres",
      factors: "0.16751",
      amount: "",
      KgCO2e: "--",
    },
  ];
  const bioenergyColumns = [
    {
      name: "",
      cell: (row) => <></>,
      width: "10px",
    },
    {
      name: "Profile",
      cell: (row) => <>{row.profile}</>,
      width: "150px",
    },
    {
      name: "Type",
      cell: (row) => <>{row.type}</>,
      width: "150px",
    },
    {
      name: "Fuel",
      cell: (row) => <>{row.fuel}</>,
      width: "100px",
    },
    {
      name: "Unit",
      cell: (row) => (
        <div className="mt-2">
          <InputField
            // className="inputBox"
            label=""
            name="unit"
            id="unit"
            placeholder=""
            // value={formInputs.unit || ""}
            // onChange={onInputChange}
            // disabled={getDisabledStatus}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "factors",
      cell: (row) => <>{row.factors}</>,
      width: "150px",
    },
    {
      name: "KgCO2e",
      cell: (row) => <>{row.KgCO2e}</>,
      width: "150px",
    },
  ];

  /* refrigerants */
  const refrigerantsData = [
    {
      id: "1",
      emission: "Carbon Dioxide",
      unit: "kg",
      factors: "1.000",
      amount: "",
      KgCO2e: "0.00",
    },
    {
      id: "1",
      emission: "Carbon Dioxide",
      unit: "kg",
      factors: "25.000 ",
      amount: "",
      KgCO2e: "0.00",
    },
    {
      id: "1",
      emission: "Carbon Dioxide",
      unit: "kg",
      factors: "298.000",
      amount: "",
      KgCO2e: "0.00",
    },
  ];
  const refrigerantsColumns = [
    {
      name: "",
      cell: (row) => <></>,
      width: "10px",
    },
    {
      name: "Emission",
      cell: (row) => <>{row.emission}</>,
      width: "150px",
    },
    {
      name: "Unit",
      cell: (row) => <>{row.unit}</>,
      width: "150px",
    },
    {
      name: "Factors",
      cell: (row) => <>{row.factors}</>,
      width: "170px",
    },
    {
      name: "Amount",
      cell: (row) => (
        <div className="mt-2">
          <InputField
            // className="inputBox"
            label=""
            name="unit"
            id="unit"
            placeholder=""
            // value={formInputs.unit || ""}
            // onChange={onInputChange}
            // disabled={getDisabledStatus}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "KgCO2e",
      cell: (row) => <>{row.KgCO2e}</>,
      width: "150px",
    },
  ];

  /* Passenger vehicles */
  const passengerVehiclesData = [
    {
      id: "1",
      profile: "PV",
      level1: "Passenger Vehicles",
      level2: "Cars (By Size)",
      level3: "Small Car",
      fuel: "Plug-in Hybrid Electric Vehicle",
      unit: "km",
      factorFuel: "0.022",
      factorEV: "0.087",
      distance: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "PV",
      level1: "Passenger Vehicles",
      level2: "Cars (By Size)",
      level3: "Small Car",
      fuel: "Plug-in Hybrid Electric Vehicle",
      unit: "km",
      factorFuel: "0.022",
      factorEV: "0.087",
      distance: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "PV",
      level1: "Passenger Vehicles",
      level2: "Cars (By Size)",
      level3: "Small Car",
      fuel: "Plug-in Hybrid Electric Vehicle",
      unit: "km",
      factorFuel: "0.022",
      factorEV: "0.087",
      distance: "",
      KgCO2e: "--",
    },
  ];
  const passengerVehiclesColumns = [
    {
      name: "",
      cell: (row) => <></>,
      width: "10px",
    },
    {
      name: "Profile",
      cell: (row) => <>{row.profile}</>,
      width: "150px",
    },
    {
      name: "Level1",
      cell: (row) => <>{row.level1}</>,
      width: "150px",
    },
    {
      name: "Level2",
      cell: (row) => <>{row.level2}</>,
      width: "170px",
    },
    {
      name: "Level3",
      cell: (row) => <>{row.level3}</>,
      width: "150px",
    },
    {
      name: "Fuel",
      cell: (row) => <>{row.Fuel}</>,
      width: "150px",
    },
    {
      name: "Unit",
      cell: (row) => <>{row.unit}</>,
      width: "150px",
    },
    {
      name: "Factor Fuel",
      cell: (row) => <>{row.factorFuel}</>,
      width: "150px",
    },
    {
      name: "Factor EV",
      cell: (row) => <>{row.factorEV}</>,
      width: "150px",
    },
    {
      name: "Distance (Km)",
      cell: (row) => (
        <div className="mt-2">
          <InputField
            // className="inputBox"
            label=""
            name="unit"
            id="unit"
            placeholder=""
            // value={formInputs.unit || ""}
            // onChange={onInputChange}
            // disabled={getDisabledStatus}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "KgCO2e",
      cell: (row) => <>{row.KgCO2e}</>,
      width: "150px",
    },
  ];

  /*Delivery vehicles */
  const deliveryVehiclesData = [
    {
      id: "1",
      profile: "DV",
      level1: "Delivery  Vehicles",
      level2: "Vans",
      level3: "Class I (up to 1.305 tonnes)",
      fuel: "Battery Electric Vehicle",
      unit: "km",
      factorFuel: "0.000",
      factorEV: "0.104",
      distance: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "DV",
      level1: "Delivery  Vehicles",
      level2: "Vans",
      level3: "Class II (1.305 to 1.74 tonnes)",
      fuel: "Battery Electric Vehicle",
      unit: "km",
      factorFuel: "0.000",
      factorEV: "0.144",
      distance: "",
      KgCO2e: "--",
    },
    {
      id: "1",
      profile: "DV",
      level1: "Delivery  Vehicles",
      level2: "Vans",
      level3: "Class III (1.74 to 3.5 tonnes)",
      fuel: "Battery Electric Vehicle",
      unit: "km",
      factorFuel: "0.000",
      factorEV: "0.201",
      distance: "",
      KgCO2e: "--",
    },
  ];
  const deliveryVehiclesColumns = [
    {
      name: "",
      cell: (row) => <></>,
      width: "10px",
    },
    {
      name: "Profile",
      cell: (row) => <>{row.profile}</>,
      width: "150px",
    },
    {
      name: "Level1",
      cell: (row) => <>{row.level1}</>,
      width: "150px",
    },
    {
      name: "Level2",
      cell: (row) => <>{row.level2}</>,
      width: "170px",
    },
    {
      name: "Level3",
      cell: (row) => <>{row.level3}</>,
      width: "150px",
    },
    {
      name: "Fuel",
      cell: (row) => <>{row.Fuel}</>,
      width: "150px",
    },
    {
      name: "Unit",
      cell: (row) => <>{row.unit}</>,
      width: "150px",
    },
    {
      name: "Factor Fuel",
      cell: (row) => <>{row.factorFuel}</>,
      width: "150px",
    },
    {
      name: "Factor EV",
      cell: (row) => <>{row.factorEV}</>,
      width: "150px",
    },
    {
      name: "Unit",
      cell: (row) => <>{row.unit}</>,
      width: "150px",
    },
    {
      name: "Distance (Km)",
      cell: (row) => (
        <div className="mt-2">
          <InputField
            // className="inputBox"
            label=""
            name="unit"
            id="unit"
            placeholder=""
            // value={formInputs.unit || ""}
            // onChange={onInputChange}
            // disabled={getDisabledStatus}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "KgCO2e",
      cell: (row) => <>{row.KgCO2e}</>,
      width: "150px",
    },
  ];
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">
                  GreenHouse Gas Emmission Calculator
                </h2>
                <p className="subText">Scope-1</p>
              </Col>
              <hr />
            </Row>

            {/*Scope -1 Score  -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SS</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Scope -1 Score</span>
                      <span className="subHeaderTitle">
                        Details of GHG Scope- 1 Score total in kg CO2e
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Fuels"
                          name="Fuels"
                          id="Fuels"
                          placeholder=""
                          // value={formInputs.singleSourceType || ""}
                          // onChange={onInputChange}
                          // disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bioenergy "
                          name="Bioenergy "
                          id="Bioenergy "
                          placeholder=""
                          // value={formInputs.singleSourceType || ""}
                          // onChange={onInputChange}
                          // disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery vehicles "
                          name="Deliveryvehicles "
                          id="Deliveryvehicles"
                          placeholder=""
                          // value={formInputs.singleSourceType || ""}
                          // onChange={onInputChange}
                          // disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Refrigerants  "
                          name="Refrigerants  "
                          id="Refrigerants "
                          placeholder=""
                          // value={formInputs.singleSourceType || ""}
                          // onChange={onInputChange}
                          // disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*  Fuels -2*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">FU</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Fuels</span>
                      <span className="subHeaderTitle">Fuels</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={fuelsColumns}
                          data={fuelsData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/*  Bioenergy -3*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Bioenergy</span>
                      <span className="subHeaderTitle">
                        Please enter the amount
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={bioenergyColumns}
                          data={bioenergyData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Refrigerants -4*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">FE</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Refrigerants</span>
                      <span className="subHeaderTitle">
                        Please enter the amount
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={refrigerantsColumns}
                          data={refrigerantsData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Passenger vehicles -5*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PE</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Passenger vehicles</span>
                      <span className="subHeaderTitle">
                        Please enter the total distance for each type of fuel
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={passengerVehiclesColumns}
                          data={passengerVehiclesData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Delivery vehicles -6*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DV</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Delivery vehicles</span>
                      <span className="subHeaderTitle">
                        Please enter the total distance for each type of fuel
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <GHGScope1CommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                        className="commTopButtonRightLeftPadding"
                      >
                        <DataTable
                          // title=""
                          columns={deliveryVehiclesColumns}
                          data={deliveryVehiclesData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Delivery vehicles -6*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <div className="accordion-body">
                  <Row className="py-2">
                    <Col
                      xs={12}
                      md={{ span: 2, offset: 6 }}
                      lg={{ span: 2, offset: 6 }}
                      className="commTopButtonRightLeftPadding"
                    >
                      <button className="btnTable">Save as Draft</button>
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                      lg={2}
                      className="commTopButtonRightLeftPadding"
                    >
                      <button className="btnTable">Submit</button>
                    </Col>
                    <Col
                      xs={12}
                      md={2}
                      lg={2}
                      className="commTopButtonRightLeftPadding"
                    >
                      <button className="btnTable">Excel Downolad</button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CreateGHGScop1;
