import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

const CommonConfirmationModal = React.forwardRef((props, ref) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="md"
      ref={ref}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-exclamation-triangle-fill"></i> Alert
          {/*           <p className='subText'>Confirm the below given statement</p>
           */}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row>
            <Col
              xs={12}
              md={12}
              lg={12}
              className="commLeftRightPadding text-left mt-2"
            >
              <h5>{props.commonconfirmationmsg}</h5>
            </Col>
          </Row>

          <Row className="justify-end">
            {props.commonconfirmationdata.map((data, index) => (
              <div key={index}>
                <Col
                  xs={4}
                  md={3}
                  lg={3}
                  key={index}
                  className="commLeftRightPadding"
                >
                  <button
                    type="button"
                    onClick={data.name === "No" ? props.onHide : props.onYes}
                    className={`btn ${data.className} "mt-4 w-full justify-center rounded-md`}
                  >
                    {data.name}
                  </button>
                </Col>
              </div>
            ))}

            {/* <Col xs={6} md={3} lg={3} className='commLeftRightPadding'> */}
            {/* <button type="button" onClick={handleSubmit} className="btn btn-danger mt-4 w-full justify-center rounded-md"><i className="bi bi-trash3"></i> No</button> */}
            {/* <button type="button" onClick={props.onHide} className="btn btn-danger mt-4 w-full justify-center rounded-md"><i className="bi bi-trash3"></i> {props.commonConfirmationData[1].name}</button>
            </Col> */}
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CommonConfirmationModal;
