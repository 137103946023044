import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { validateRequired } from "../../../../common/Validations";

import ItemService from "../../../../services/supplier_appCommonService";
//import { getCategoryListData } from "../../../redux/feature/userSlice";

//Redux
// import { useDispatch } from "react-redux"; //useDispatch
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
// import SupplierConstants from "../../../../common/SupplierConstants";
import CustomerConstants from "../../../../common/CustomerConstants";

const PlantDetails = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Plant Master");

  let plantDetailsData = useSelector(
    (state) => state?.user?.plantList?.plantDetails
  );

  const [isCardVisible, setCardVisible] = useState(true);
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const [formInputs, setFormInputs] = useState({
    companyCode: "",
    plantCode: "",
    plantDescription: "",
    address: "",
    name1: "",
    name2: "",
    city: "",
    country: "",
    currency: "",
    language: "",
    street: "",
    postalCode: "",
    googleMapLocation: "",
  });
  const modalData = {
    title: "Plant Updated",
    successMsg: "",
  };
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getFailureModal] = useState(failureModalData);

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  /* Edit Button */
  const handleEdit = () => {
    setEditMode(false);
  };
  /* Edit Button */
  const handleEditPlant = async () => {
    let requiredFields = [];
    if (
      !validateRequired(formInputs?.companyCode) ||
      !validateRequired(formInputs?.plantCode) ||
      !validateRequired(formInputs?.plantDescription) ||
      !validateRequired(formInputs?.address) ||
      !validateRequired(formInputs?.name1) ||
      !validateRequired(formInputs?.name2) ||
      !validateRequired(formInputs?.city) ||
      !validateRequired(formInputs?.country) ||
      !validateRequired(formInputs?.currency) ||
      !validateRequired(formInputs?.language) ||
      !validateRequired(formInputs?.street) ||
      !validateRequired(formInputs?.plantCode) ||
      !validateRequired(formInputs?.googleMapLocation)
    ) {
      requiredFields.push("All fields are required..!");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    setDisabled(true);
    await ItemService.updatePlant(formInputs).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        //dispatch(getMaterialListData()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    if (plantDetailsData) {
      setFormInputs(plantDetailsData);
    } else {
      navigate(CustomerConstants.CUSTOMER_V1_PLANT_MASTER_DETAILS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Plant</h2>
            <p className="subText">Plant Master Details</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PD</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">Create Plant</span>
                      <span className="subHeaderTitle">Create</span>
                    </Col>

                    <Col xs={4} md={2} lg={2} className="text-right">
                      {editMode && (
                        <span>
                          <button className="btnTable" onClick={handleEdit}>
                            Edit
                          </button>
                        </span>
                      )}
                      {!editMode && (
                        <span>
                          <button
                            className="btnTable"
                            disabled={disabled}
                            onClick={handleEditPlant}
                          >
                            Update Plant
                          </button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      
                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          name="companyCode"
                          id="companyCode"
                          extraLabel=""
                          placeholder="Company Code"
                          value={formInputs.companyCode || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant Code"
                          name="plantCode"
                          id="plantCode"
                          extraLabel=""
                          placeholder="Plant Code"
                          value={formInputs.plantCode || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                        >
                        <TextAreaField
                          className="inputBox"
                          label="Plant Description"
                          type="text"
                          name="plantDescription"
                          id="plantDescription"
                          placeholder="Reason"
                          value={formInputs.plantDescription || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                       >
                        <InputField
                          className="inputBox"
                          label="Address"
                          name="address"
                          id="address"
                          extraLabel=""
                          placeholder="Address"
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Name1"
                          name="name1"
                          id="name1"
                          extraLabel=""
                          placeholder="Name1"
                          value={formInputs.name1 || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Name2"
                          name="name2"
                          id="name2"
                          extraLabel=""
                          placeholder="Name2"
                          value={formInputs.name2 || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          id="city"
                          extraLabel=""
                          placeholder="City"
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Country"
                          name="country"
                          id="country"
                          extraLabel=""
                          placeholder="Country"
                          value={formInputs.country || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          id="currency"
                          extraLabel=""
                          placeholder="Currency"
                          value={formInputs.currency || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Language"
                          name="language"
                          id="language"
                          extraLabel=""
                          placeholder="Language"
                          value={formInputs.language || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Street"
                          name="street"
                          id="street"
                          extraLabel=""
                          placeholder="Street"
                          value={formInputs.street || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          extraLabel=""
                          placeholder="postalCode"
                          value={formInputs.postalCode || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Google Map Location"
                          name="googleMapLocation"
                          id="googleMapLocation"
                          extraLabel=""
                          placeholder="Google Map Location"
                          value={formInputs.googleMapLocation || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={CustomerConstants.CUSTOMER_V1_PLANT_MASTER_DETAILS}
      />
    </main>
  );
};

export default PlantDetails;
