import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";

//API
import ItemService from "../../../services/supplier_appCommonService";

//Redux
import { useDispatch } from "react-redux";
import { getGatepassList } from "../../../redux/feature/supplier_app/gatepassSlice";

//Components
import Layout from "../../../Layout/Layout";
import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import Constants from "../../../common/SupplierConstants";
import GatePassCommonFilter from "../../../Components/Filter/GatePass/GatePassCommonFilter";
import { capitalize, validateRequired } from "../../../common/Validations";
import SuccessRegisterModal from "../../../Components/SuccessRegisterModal";
import FailureModal from "../../../Components/Modal/supplier_app/FailureModal";

const GatepassList = () => {
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Gatepass");
  localStorage.setItem("submenu", "Create Gatepass");

  // let asn_data = useSelector((state) => state?.purchasing?.asn?.data); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const modalData = {
    title: "Gatepass Creation",
    successMsg: "",
  };

  const [getShipment, setShipment] = useState();
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);

  const [getASNNumberData, setASNNumberData] = useState("");
  const [getSelectedASN, setSelectedASN] = useState("");
  const [getASNItemData, setASNItemData] = useState([]);
  const [loading, setLoading] = useState(false);

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };
  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  const [formInputs, setFormInputs] = useState({
    _id: "",
    asnNumber: "",
    gpStatusHeader: "Gatepass Created",
    documentType: "GP",
    transactionActivity: "GP",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    asnDate: "",
    despatchNr: "",
    despatchDate: "",
    vendorCode: "",
    vendorName: "",
    shippingType: "",
    asnRemark: "",
    asnCount: "1",
    purchasingDocumentValue: "",
    deliveredValue: "",
    deliveredStatus: "",
    invoicedValue: "",
    invoicedStatus: "",
    paidValue: "",
    currency: "",
    vendorInvoiceNo: "",
    vendorInvoiceDate: "",
    vendorBasicValue: "",
    vendorTaxValue: "",
    vendorOtherChargesValue: "",
    vendorTotalInvoiceValue: "",
    vendorInvoiceCurrency: "",
    vechicleNumber: "",
    vechicleInDate: "",
    vechicleInTime: "",
    vechicleOutDate: "",
    vehicleOutTime: "",

    items: [
      {
        gpItemNr: "",
        asnItemNr: "",
        materialCode: "",
        materialDescription: "",
        asnQuantity: "",
        currency: "",
        orderUnitOfMeasure: "",
        gpStatusItem: "Gatepass Created",
        supplierBatchNumber: "",
        plant: "",
        storeLocation: "",
        purchasingDocumentNr: "",
        purchasingDocumentItemNr: "",
        grossWeight: "",
        asnDeletionIndicator: "",
        cancellationRemarks: "",
        cancelledBy: "",
        cancelledOn: "",
        cancelledTime: "",
      },
    ],
  });
  const [disabled, setDisabled] = useState(false);

  const loadInitialPageData = async () => {
    let obj = {};
    await ItemService.getAsnNumForGatepass(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        setASNNumberData(item.data);
        // setData(item?.data?.items);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };

  // Load ASN Data to Dropdown
  useEffect(() => {
    loadInitialPageData();
    // let asnData = [];
    // if (asn_data.length > 0) {
    //   asn_data?.map((item, index) =>
    //     asnData.push({
    //       id: index + 1,
    //       name: item.asnNumber,
    //     })
    //   );
    //   setASNNumberData(asnData);
    // }
  }, []);

  //Selected ASN
  useEffect(() => {
    // console.log(">>Selected ASN ", getSelectedASN);

    if (getSelectedASN.name) {
      setLoading(true);
      let filteredData = getASNNumberData.filter(
        (item) => item.asnNumber === getSelectedASN.name
      );
      // console.log(">> ", filteredData);
      let loadItemData = filteredData[0]?.items;
      const updateditemData = loadItemData?.map((obj, index) => ({
        ...obj,
        gpItemNr: index + 1,
        gpStatusItem: "Gatepass Created",
      }));

      setASNItemData(updateditemData);

      let shipmentData = filteredData[0][filteredData[0]?.shippingType];
      let obj = {
        [filteredData[0]?.shippingType]: shipmentData,
      };
      setSelectedShipment(filteredData[0]?.shippingType);
      setShipment(obj);
      setLoading(false);

      let formDataVal = filteredData[0];
      const { _id, ...formDataValUpdated } = formDataVal;
      setFormInputs(formDataValUpdated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedASN]);

  const columnsItem = [
    {
      name: "Gatepass Item",
      selector: (row) => row.gpItemNr,
      sortable: true,
      width: "135px",
    },
    {
      name: "ASN Item",
      selector: (row) => row.asnItemNr,
      sortable: true,
      width: "110px",
    },
    {
      name: "PO Number",
      selector: (row) => row.purchasingDocumentNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "PO Item",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "100px",
    },
    // {
    //   name: "PO Number",
    //   cell: (row) => (
    //     <button
    //       className="btnInfo"
    //       onClick={() => detailedPage(row.purchasingDocumentNr)}
    //     >
    //       {row.purchasingDocumentNr}{" "}
    //     </button>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Item Status",
      selector: (row) => row.asnStatusItem,
      cell: (row) => (
        <>
          {row.asnStatusItem === "In Transit" ? (
            <span className="bg-table-status bg-table-progress-status">
              {row.asnStatusItem}
            </span>
          ) : row.asnStatusItem === "ASN Cancelled" ? (
            <span className="bg-table-status bg-table-danger-status">
              {row.asnStatusItem}
            </span>
          ) : row.asnStatusItem === "Gatepass Created" ? (
            <span className="bg-table-status bg-table-success-status">
              {row.asnStatusItem}
            </span>
          ) : (
            row.asnStatusItem
          )}
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Material",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "180px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "180px",
    },
    {
      name: "Order Quantity",
      selector: (row) => row.asnQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "180px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "180px",
    },
  ];

  // Sample Data
  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  // SAVE
  const handleSave = async () => {
    let requiredFields = [];

    if (!validateRequired(formInputs?.asnNumber)) {
      requiredFields.push("*Please select ASN Number");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    if (
      !validateRequired(formInputs?.vechicleInDate) ||
      !validateRequired(formInputs?.vechicleInTime) ||
      !validateRequired(formInputs?.vechicleOutDate) ||
      !validateRequired(formInputs?.vehicleOutTime)
    ) {
      requiredFields.push("*Please Enter Vehicle In Date and Out Date");
      requiredFields.push("*Please Enter Vehicle In Time and Out Time");
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }

    formInputs.items = getASNItemData;
    formInputs.gpStatusHeader = "Gatepass Created";

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    let obj = {
      data: formInputs,
    };
    setDisabled(true);
    await ItemService.getGatepassCreate(obj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        dispatch(getGatepassList()); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  /* radio buttons */
  const [selectedShipment, setSelectedShipment] = useState("");

  /* raddio buttons input fields */
  const renderShipmentDetails = () => {
    switch (selectedShipment) {
      case "road":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Road Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Truck No"
                    name="truckNo"
                    id="truckNo"
                    value={getShipment?.road?.truckNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter/Courier"
                    name="transporterCourier"
                    id="transporterCourier"
                    value={getShipment?.road?.transporterCourier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.road?.dateOfDispatch}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR No"
                    name="lrNo"
                    id="lrNo"
                    value={getShipment?.road?.lrNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR Date"
                    name="lrDate"
                    id="lrDate"
                    value={getShipment?.road?.lrDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Delivery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.road?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="EWaybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.road?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.road?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan No"
                    name="challanNo"
                    id="challanNo"
                    value={getShipment?.road?.challanNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan Date"
                    name="challanDate"
                    id="challanDate"
                    value={getShipment?.road?.challanDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.road?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.road?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Loading"
                    name="placeOfLoading"
                    id="placeOfLoading"
                    value={getShipment?.road?.placeOfLoading}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Delivery"
                    name="placeOfDelivery"
                    id="placeOfDelivery"
                    value={getShipment?.road?.placeOfDelivery}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.road?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Name"
                    name="driverName"
                    id="driverName"
                    value={getShipment?.road?.driverName}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Number"
                    name="driverNumber"
                    id="driverNumber"
                    value={getShipment?.road?.driverNumber}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "air":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Air Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.air?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB No"
                    name="mawbNo"
                    id="mawbNo"
                    value={getShipment?.air?.mawbNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB Date"
                    name="mawbDate"
                    type="date"
                    id="mawbDate"
                    value={getShipment?.air?.mawbDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Shipment"
                    name="airportOfShipment"
                    id="airportOfShipment"
                    value={getShipment?.air?.airportOfShipment}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Arrival"
                    name="airportOfArrival"
                    id="airportOfArrival"
                    value={getShipment?.air?.airportOfArrival}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter Name"
                    name="transporterName"
                    id="transporterName"
                    value={getShipment?.air?.transporterName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.air?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Deilevery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.air?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.air?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier"
                    name="courier"
                    id="courier"
                    value={getShipment?.air?.courier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry No"
                    name="billOfEntryNo"
                    id="billOfEntryNo"
                    value={getShipment?.air?.billOfEntryNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry Date"
                    name="billOfEntryDate"
                    id="billOfEntryDate"
                    value={getShipment?.air?.billOfEntryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB No"
                    name="hawbNo"
                    id="hawbNo"
                    value={getShipment?.air?.hawbNo}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB Date"
                    name="hawbDate"
                    id="hawbDate"
                    value={getShipment?.air?.hawbDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="NoofPackages"
                    id="noOfPackages"
                    value={getShipment?.air?.noOfPackages}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.air?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "courier":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Courier Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.courier?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier Name"
                    name="courierName"
                    id="courierName"
                    value={getShipment?.courier?.courierName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket No"
                    name="docketNo"
                    id="docketNo"
                    value={getShipment?.courier?.docketNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket Date"
                    name="docketDate"
                    type="date"
                    id="docketDate"
                    value={getShipment?.courier?.docketDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.courier?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Arrival Date"
                    name="expectedArrivalDate"
                    type="date"
                    id="expectedArrivalDate"
                    value={getShipment?.courier?.expectedArrivalDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.courier?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.courier?.weightOfConsignment}
                    // type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.courier?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "ship":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Ship Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Lading"
                    name="billOfLading"
                    id="billOfLading"
                    value={getShipment?.ship?.billOfLading}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Ladin Date"
                    name="billOfLadinDate"
                    id="billOfLadinDate"
                    value={getShipment?.ship?.billOfLadinDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Shippine Line"
                    name="shippineLine"
                    id="shippineLine"
                    value={getShipment?.ship?.shippineLine}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Internal Reference Number"
                    name="internalReferenceNumber"
                    id="internalReferenceNumber"
                    value={getShipment?.ship?.internalReferenceNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Booking Number"
                    name="bookingNumber"
                    id="bookingNumber"
                    value={getShipment?.ship?.bookingNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    type="date"
                    id="dateOfDispatch"
                    value={getShipment?.ship?.dateOfDispatch}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Gatepass Createion</h2>
            <p className="subText">Gatepass Createion Details</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">GP</span>
                    </Col>
                    <Col xs={6} md={10} lg={10}>
                      <span className="headerTitle">Gatepass Creation</span>
                      <span className="subHeaderTitle">Header Information</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <GatePassCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="ASN Number"
                          placeholder="Please select the ASN Number"
                          data={getASNNumberData}
                          id="asnNumber"
                          iconClassName="dropdownIcon"
                          name="asnNumber"
                          setValue={getSelectedASN?.name || []}
                          getvalue={setSelectedASN}
                          className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Shipping Type"
                          name="shippingType"
                          id="shippingType"
                          placeholder=""
                          value={capitalize(formInputs?.shippingType) || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          name="vendorName"
                          placeholder=""
                          value={formInputs?.vendorName || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Number"
                          name="vendorInvoiceNo"
                          placeholder=""
                          value={formInputs?.vendorInvoiceNo || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Date"
                          name="vendorInvoiceDate"
                          placeholder=""
                          value={formInputs?.vendorInvoiceDate || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Amount"
                          name="vendorTotalInvoiceValue"
                          placeholder=""
                          value={formInputs?.vendorTotalInvoiceValue || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Currency"
                          name="vendorInvoiceCurrency"
                          placeholder=""
                          value={formInputs?.vendorInvoiceCurrency || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Vechicle Number"
                          className="inputBox"
                          id="vechicleNumber"
                          name="vechicleNumber"
                          value={formInputs?.vechicleNumber || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Vechicle In Date"
                          type="date"
                          className="inputBox"
                          id="vechicleInDate"
                          name="vechicleInDate"
                          value={formInputs?.vechicleInDate || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Vechicle In Time"
                          type="time"
                          id="vechicleInTime"
                          className="inputBox"
                          name="vechicleInTime"
                          value={formInputs?.vechicleInTime || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Vechicle Out Date"
                          id="vechicleOutDate"
                          type="date"
                          className="inputBox"
                          name="vechicleOutDate"
                          value={formInputs?.vechicleOutDate || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Vechicle Out Time"
                          type="time"
                          id="vehicleOutTime"
                          className="inputBox"
                          name="vehicleOutTime"
                          value={formInputs?.vehicleOutTime || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Driver Name"
                          className="inputBox"
                          id="driverName"
                          name="driverName"
                          value={formInputs?.driverName || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          label="Driver Mobile Number"
                          className="inputBox"
                          data={dropdownOption}
                          id="driverNumber"
                          name="driverNumber"
                          value={formInputs?.driverNumber || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Radio buttons */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MS</span>
                    </Col>

                    <Col xs={8} md={8} lg={4}>
                      <span className="headerTitle">Mode of Shipment</span>
                      <span className="subHeaderTitle">Shipment Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div>{renderShipmentDetails()}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">GP</span>
                    </Col>
                    <Col xs={6} md={8} lg={8}>
                      <span className="headerTitle">Gatepass Item</span>
                      <span className="subHeaderTitle">Item Information</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {/* Add Item Table*/}
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columnsItem}
                          data={getASNItemData}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="pageFooter">
            <Row className="rowDirection">
              <Col xs={12} md={2} lg={2} className="">
                <button
                  className="btn w-full"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.GATEPASS_GATEPASS_LIST}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default GatepassList;
