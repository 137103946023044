/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/customer_appCommonService";

const initialState = {
    data:[],
    category_selection:{
        data:[],
        status:true,
    },
    selectedCategory:[],
    status:true,
    customerID:'',
    customerWorkflow:{},
    accountGroup:'',
    pagination:{
        page:"",
        per_page:"",
        total:""
    }
};

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        addCategorySelectedData(state, action){
          state.selectedCategory = action.payload || [];
        },
        addcustomerID(state, action){
            state.customerID = action.payload
        },
        workflow(state, action){
            state.customerWorkflow = action.payload
        },
        addAccountGroup(state, action){
            state.accountGroup = action.payload
        }
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getCustomerList.pending, (state,action)=>{
            state.status = true;
        })
        .addCase(getCustomerList.fulfilled, (state,action)=>{
            state.data = action.payload.data;
            state.pagination.page = action.payload.page;
            state.pagination.per_page = action.payload.per_page;
            state.pagination.total = action.payload.total;
            state.status = false;
        })
        .addCase(getCustomerCategorySelection.fulfilled, (state,action)=>{
            state.category_selection.data = action.payload;
            state.status = false;
        })
    }
  });

export const { addCategorySelectedData, addcustomerID, addAccountGroup, workflow } = customerSlice.actions;
export default customerSlice.reducer;

export const getCustomerList = createAsyncThunk('getCustomerList', async (param) =>{
    console.log(">> loaded getCustomerList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
})
export const getCustomerCategorySelection = createAsyncThunk('getCustomerCategorySelection', async () =>{
    console.log(">> loaded getCustomerCategorySelection");
    const res = await ItemService.getCustomerCategorySelection();
    return res.data;
})
