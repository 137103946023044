import React, { useState } from 'react';
// import toast from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout  from '../../../Layout/Layout';
import DataTable from "react-data-table-component";
import AuctionCommonFilter from '../../../Components/Filter/Aucton/AuctionCommonFilter';


const ReverseAuction =() =>{
  localStorage.setItem('menu','Auction');
  localStorage.setItem('submenu','Reverse Auction');
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
   /* Auction data */
   const AuctionListData = [
    {
      Profile: "Profile",
      AuctionNumber: "Auction Number",
      RFQNumber: "RFQ Number",
      AuctionStatus: "Auction Status",
      Collaboration: "Collaboration",
      CreatedBy: "Created By",
      TentativeStartTimeUAE: "Tentative Start Time (UAE)",
      TentativeEndTimeUAE: "Tentative End Time (UAE)",
      RemainingTimeToStart: "Remaining Time To Start",
      LiveAuction: "Live Auction",
      AuctionTitle: "Auction Title",
      AuctionDescripton: "Auction Description"
  },
  
  ];
  const AuctionListColumns =[
    {
      name: "icon",
      cell: (row) => (
          <>
              
          </>
      ),
      width: "150px",
  },
    {
      name: "Profile",
      cell: (row) => (
          <>
              <span>{row.Profile}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Auction Number",
      cell: (row) => (
          <>
              <span>{row.AuctionNumber}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "RFQ Number",
      cell: (row) => (
          <>
              <span>{row.RFQNumber}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Auction Status",
      cell: (row) => (
          <>
              <span>{row.AuctionStatus}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Collaboration",
      cell: (row) => (
          <>
              <span>{row.Collaboration}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Created By",
      cell: (row) => (
          <>
              <span>{row.CreatedBy}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Tentative Start Time (UAE)",
      cell: (row) => (
          <>
              <span>{row.TentativeStartTimeUAE}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Tentative End Time (UAE)",
      cell: (row) => (
          <>
              <span>{row.TentativeEndTimeUAE}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Remaining Time To Start",
      cell: (row) => (
          <>
              <span>{row.RemainingTimeToStart}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Live Auction",
      cell: (row) => (
          <>
              <span>{row.LiveAuction}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Auction Title",
      cell: (row) => (
          <>
              <span>{row.AuctionTitle}</span>
          </>
      ),
      width: "150px",
  },
  {
      name: "Auction Description",
      cell: (row) => (
          <>
              <span>{row.AuctionDescription}</span>
          </>
      ),
      width: "150px",
  },
  
  ]

  return(
    <main className="dashboard main" id="main"> 
      <Layout/>
        <Row className="justify-center subContent">
          <Col xs={12} md={12}  className="rightSide">
              
            <div className="rightSideInner timesheet">
              <h2 className='mainTitle'>Auction</h2>
              <p className='subText'>Auction List</p>
              <hr/>

              {/* Auction List table */}
              <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AU</span>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                      <span className="headerTitle">
                      Auction 
                      </span>
                      <span className="subHeaderTitle">
                        Auction List
                      </span>
                    </Col>
                    <Col xs={12} md={6} lg={1}>
                    <button class="btnTable">Create Auction</button>
                    </Col>
                    <Col xs={12} md={6} lg={1}>
                    <button class="btnTable">Copy Auction</button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <AuctionCommonFilter/>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                 
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={AuctionListColumns}
                          data={AuctionListData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            </div>   
          </Col>
        </Row>
    </main>
  )
}

export default ReverseAuction;