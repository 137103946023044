import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import POCollabrationCommonFillter from "../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import { InputField } from "../../../Components/formElements/InputField";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Constants from "../../../common/SupplierConstants";

const SingleSourceDetails = () => {
  const navigate = useNavigate();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "");

  let singleSourcingData = useSelector((state) => state?.singleSourcing.data); // singelsourcing data  from redux

  const singleSourcingNumber = useSelector(
    (state) => state.singleSourcing.singleSourceNr
  ); // singleSourceRequestNr data  from redux
  const [getSingleSourcingdata, setSingleSourcingdata] = useState();

  const [getDisabledStatus] = useState(true);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  useEffect(() => {
    const loadPageInitialData = async () => {
      if (singleSourcingNumber) {
        let filteredData = singleSourcingData?.filter(
          (item) => item.singleSourceRequestNr === singleSourcingNumber
        );
        setSingleSourcingdata(filteredData[0]);
      } else {
        navigate(Constants.SINGLESOURCING_SINGLE_SOURCING_LIST);
      }
    };
    loadPageInitialData();
  }, [singleSourcingNumber, singleSourcingData, navigate]);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle"> Single Source Detail</h2>
                <p className="subText"> Single Source Detail</p>
              </Col>
              <hr />
            </Row>

            {/* Advance Payment Request details  -1*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Single Source Header</span>
                      <span className="subHeaderTitle">
                        Single Source Header
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Single Source Form"
                          name="singleSourceType"
                          id="singleSourceType"
                          placeholder=""
                          value={getSingleSourcingdata?.singleSourceType || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Department"
                          id="requesterDepartment"
                          name="requesterDepartment"
                          placeholder=""
                          value={
                            getSingleSourcingdata?.requesterDepartment || ""
                          }
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="VP/Chief Officer"
                          id="lineVP"
                          name="lineVP"
                          placeholder=""
                          value={getSingleSourcingdata?.lineVP || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SSF Number"
                          id="ssRequestNr"
                          name="ssRequestNr"
                          placeholder=""
                          value={getSingleSourcingdata?.ssRequestNr || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Designation"
                          id="requesterDesignation"
                          name="requesterDesignation"
                          placeholder=""
                          value={
                            getSingleSourcingdata?.requesterDesignation || ""
                          }
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Reference PR"
                          id="purchaseReqNr"
                          name="purchaseReqNr"
                          placeholder=""
                          value={getSingleSourcingdata?.purchaseReqNr || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Employee ID"
                          id="requestedBy"
                          name="requestedBy"
                          placeholder=""
                          value={getSingleSourcingdata?.requestedBy || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="End User"
                          id="sSEndUser"
                          name="sSEndUser"
                          placeholder=""
                          value={getSingleSourcingdata?.sSEndUser || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PR Value (AED)"
                          id="purchaseReqValue"
                          name="purchaseReqValue"
                          placeholder=""
                          value={getSingleSourcingdata?.purchaseReqValue || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Employee Name"
                          id="requester"
                          name="requester"
                          placeholder=""
                          value={getSingleSourcingdata?.requester || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Manager Type"
                          id="managertype"
                          name="managertype"
                          placeholder=""
                          value={getSingleSourcingdata?.managertype || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="OEM Supplier"
                          name="OEMSupplier"
                          id="OEMSupplier"
                          placeholder=""
                          value={getSingleSourcingdata?.OEMSupplier || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          id="ssStatusHeader"
                          name="ssStatusHeader"
                          placeholder=""
                          value={getSingleSourcingdata?.ssStatusHeader || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Line Manager"
                          id="lineManager"
                          name="lineManager"
                          placeholder=""
                          value={getSingleSourcingdata?.lineManager || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="OEM Supplier"
                          id="OEMSupplier"
                          name="OEMSupplier"
                          placeholder=""
                          value={getSingleSourcingdata?.OEMSupplier || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Category Assignment   -2*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Category Assignment</span>
                      <span className="subHeaderTitle">
                        Details of Category Assignment
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SCM Buyer"
                          name="purchaseReqBuyer"
                          id="purchaseReqBuyer"
                          placeholder=""
                          value={getSingleSourcingdata?.purchaseReqBuyer || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SCM Category"
                          name="scmCategory"
                          id="scmCategory"
                          placeholder=""
                          value={getSingleSourcingdata?.scmCategory || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Procurement Manager"
                          name="categoryManager"
                          id="categoryManager"
                          placeholder=""
                          type="date"
                          value={getSingleSourcingdata?.categoryManager || ""}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Other Details</span>
                      <span className="subHeaderTitle">Other Details</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Reason for Single Source"
                          id="reasonforSingleSource"
                          name="reasonforSingleSource"
                          placeholder=""
                          value={
                            getSingleSourcingdata?.reasonforSingleSource || ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Justify the Goods or Services required in detail"
                          id="JustifytheGoods"
                          name="JustifytheGoods"
                          placeholder=""
                          value={getSingleSourcingdata?.JustifytheGoods || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Benefit or Specialization from Supplier"
                          id="Benefit"
                          name="Benefit"
                          placeholder=""
                          value={getSingleSourcingdata?.Benefit || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SUPPORTING DOCUMENT"
                          id="SUPPORTINGDOCUMENT"
                          name="SUPPORTINGDOCUMENT"
                          placeholder=""
                          type="file"
                          value={
                            getSingleSourcingdata?.SUPPORTINGDOCUMENT || ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SingleSourceDetails;
