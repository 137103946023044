import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    info:{
        applications:[],
        defaultAppCode:''
    }
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        addAppInfo(state,action){
            state.info = action.payload.data
        },
        updateAppCode(state,action){
            state.info.defaultAppCode = action.payload
        },
    },
  });

export const { addAppInfo, updateAppCode } = appSlice.actions;
export default appSlice.reducer;
