import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Row, Col, Container } from "react-bootstrap";
import "./customer_app_css/AppLandingPage.scss"; // Import the CSS file for styling
import Header from "../Layout/Header";
import SupplierConstantsList from "../common/SupplierConstants";
import CustomerConstantsList from "../common/CustomerConstants";

import { useNavigate } from "react-router-dom";

//Redux
import { useDispatch } from "react-redux"; //useDispatch
import { updateAppCode } from "../redux/feature/appSlice";
import { initializeAppData } from "../AppDataUtils";

//Serivce
import ItemService from "../services/supplier_appCommonService";

const AppLandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getAppData, setAppData] = useState([]);

  const handleApplications = (code) => {
    console.log(">> handleApplications", code);
    dispatch(updateAppCode(code));

    localStorage.setItem("application", code);

    // Calling Application Data
    initializeAppData(dispatch, code);

    if (code === "ALL") {
      navigate(SupplierConstantsList.APP_HOME);
    } else if (code === "SUPPLIER") {
      navigate(SupplierConstantsList.HOME_INTRO);
    } else if (code === "CUSTOMER") {
      navigate(CustomerConstantsList.HOME_INTRO);
    } else {
      console.log(">> Not Configured ..!");
    }
  };

  const getApplicationMaster = async () => {
    let obj = {};
    await ItemService.getApplicationMaster(obj).then((item) => {
      // console.log(">> item! getApplicationMaster", item);
      if (item?.status === 200) {
        setAppData(item?.data[0].appsName);
      } else {
        setAppData([]);
        console.log(">> Error: Loading getApplicationMaster");
      }
    });
  };

  //Load Page Data
  useEffect(() => {
    getApplicationMaster();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main appLandingPage">
      <Header />
      <div className="container appInnerBlock">
        <Container
          fluid
          className="d-flex justify-content-center align-items-center vh-100"
        >
          <Row className="justify-content-center subContent">
            {getAppData.map(
              (card, index) =>
                card.appStatus && (
                  <Col
                    key={index}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    className="mb-4 commLeftRightPadding"
                  >
                    <motion.div
                      className="cardApp p-3 text-white d-flex align-items-center"
                      style={{
                        backgroundColor: card.color,
                        borderRadius: "8px",
                      }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2, duration: 0.5 }}
                      onClick={() => handleApplications(card.appCode)}
                    >
                      {/* Icon or Image on the left */}
                      <div className="icon-container me-4">
                        <img src={card.icon} alt="image1" className="mt-1" />
                      </div>

                      {/* Text Content */}
                      <div>
                        <h3>{card.appName}</h3>
                        <p>{card.description}</p>
                      </div>
                    </motion.div>
                  </Col>
                )
            )}
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default AppLandingPage;
