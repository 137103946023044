import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { formatTimeDifference } from "../../../../common/Validations";

const ShowStatus = React.forwardRef((props, ref) => {
  const [loadData, setLoadData] = useState(
    props?.rowdatamodal?.workflowActionLogs || props?.rowdatamodal
  );

  useEffect(() => {
    // console.log(">> rowdatamodal", props?.rowdatamodal);
    setLoadData(props?.rowdatamodal);

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="lg"
      ref={ref}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-info-square"></i> {props.modeltitle} of{" "}
          {loadData?.length > 0 ? loadData[0]?.businessComponentInstanceId : ""}
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="dashboard">
        <form className="">
          <Row>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <div className="card">
                <div className="card-body">
                  <div className="activity">
                    {loadData?.length > 0 &&
                      loadData.map((item, index) => (
                        <div key={index}>
                          <div className="activity-item d-flex mb-2">
                            <div className="activite-label">
                              {formatTimeDifference(item.updatedAt)} &nbsp;
                            </div>
                            {item.currentAction === "Created" ? (
                              <i className="bi bi-pencil-square activity-badge align-self-start fs-4"></i>
                            ) : item.currentAction === "Pending" ? (
                              <i className="bi bi-hourglass-split activity-badge align-self-start fs-4"></i>
                            ) : item.currentAction === "Approve" ? (
                              <i className="bi bi-check-circle-fill activity-badge align-self-start fs-4"></i>
                            ) : item.currentAction === "Submit" ? (
                              <i className="bi bi-send-fill activity-badge align-self-start fs-4"></i>
                            ) : item.currentAction === "Reject" ? (
                              <i className="bi bi-x-circle-fill activity-badge align-self-start fs-4"></i>
                            ) : item.currentAction === "Completed" ? (
                              <i className="bi bi-flag-fill activity-badge align-self-start fs-4"></i>
                            ) : (
                              "-"
                            )}
                            
                            <div className="activity-content">
                              {item.currentAction === "Created" ? (
                                <span className="bg-status bg-new-workflow">
                                  {item.currentAction}
                                </span>
                              ) : item.currentAction === "Pending" ? (
                                <span className="bg-status bg-pending-workflow">
                                  {item.currentAction}
                                </span>
                              ) : item.currentAction === "Approve" ? (
                                <span className="bg-status bg-success-workflow">
                                  {item.currentAction}
                                </span>
                              ) : item.currentAction === "Submit" ? (
                                <span className="bg-status bg-submit-workflow">
                                  {item.currentAction}
                                </span>
                              ) : item.currentAction === "Reject" ? (
                                <span className="bg-status bg-danger-workflow">
                                  {item.currentAction}
                                </span>
                              ) : item.currentAction === "Completed" ? (
                                <span className="bg-status bg-complete-workflow">
                                  {item.currentAction}
                                </span>
                              ) : (
                                "-"
                              )}

                              {item?.approveresInfo?.map((item1, index1) => (
                                <div key={index1} className="mt-2">
                                  <span className="fw-bold">
                                    {item1.userId} -{" "}
                                    {item1.firstName
                                      ? item1.firstName
                                      : item1.organisationName}
                                  </span>
                                  <p>{item1.email}</p>
                                  {item?.remark && (
                                    <p className="workflow-remark">
                                      {item.remark}{" "}
                                    </p>
                                  )}

                                  {/* <p><Moment format="YYYY/MM/DD HH:MM:SS">{item.updatedAt}</Moment></p> */}
                                  {/* <p className="mt-1">{formatTimeDifference(item.updatedAt)}</p> */}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-end">
            {/* <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col> */}
            {/* <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
              >
                Save
              </button>
            </Col> */}
            {/* <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col> */}
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default ShowStatus;
