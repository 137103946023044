// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-thumbnails {
    display: flex;
    flex-wrap: wrap;
    /* gap: 10px; */
  }
  
  .file-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes content to top and buttons to bottom */
    align-items: center;
    width: 250px; /* Adjust size as needed */
    height: 200px; /* Adjust size as needed */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 13px;
    margin-left: 12px;
    margin-bottom: 14px;
  }
  
  .thumbnail-image {
    width: 200px;
    height: 162px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .thumbnail-icon {
    font-size: 60px;
    color: #666;
  }
  
  .file-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
  }
  
  .action-button {
    font-size: 14px;
    text-decoration: none;
    color: #007bff;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .action-button:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/FileViewer/FileThumbnail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B,EAAE,gDAAgD;IAChF,mBAAmB;IACnB,YAAY,EAAE,0BAA0B;IACxC,aAAa,EAAE,0BAA0B;IACzC,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,0CAA0C;IAC1C,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".file-thumbnails {\n    display: flex;\n    flex-wrap: wrap;\n    /* gap: 10px; */\n  }\n  \n  .file-thumbnail {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between; /* Pushes content to top and buttons to bottom */\n    align-items: center;\n    width: 250px; /* Adjust size as needed */\n    height: 200px; /* Adjust size as needed */\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #fff;\n    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    margin-top: 13px;\n    margin-left: 12px;\n    margin-bottom: 14px;\n  }\n  \n  .thumbnail-image {\n    width: 200px;\n    height: 162px;\n    object-fit: cover;\n    border-radius: 4px;\n  }\n  \n  .thumbnail-icon {\n    font-size: 60px;\n    color: #666;\n  }\n  \n  .file-actions {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 5px;\n  }\n  \n  .action-button {\n    font-size: 14px;\n    text-decoration: none;\n    color: #007bff;\n    display: flex;\n    align-items: center;\n    gap: 4px;\n  }\n  \n  .action-button:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
