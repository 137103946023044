// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sri-venkateshwara .deleteIconAccordion {
  margin-top: -40px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/customer_app/Customer_V2/Style/customer_v2.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR","sourcesContent":[".sri-venkateshwara{\n    .deleteIconAccordion{\n        margin-top: -40px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
