import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/CustomerConstants";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import DataTable from "react-data-table-component";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { addCompletedInquiryList } from "../../../../redux/feature/customer_app/preSalesSlice";
// import Moment from "react-moment";
// import { currencyFormat, formatDateTime } from "../../../../common/Validations";

const InquiryDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Pre Sales");
  localStorage.setItem("submenu", "Inquiry");

  let inquiryID = useSelector((state) => state?.preSales?.inquiryID); // User Details from Store

  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [isCardVisible, setCardVisible] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const [getDisabledStatus, setDisabledStatus] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const loadFirstLevelData = async (page) => {
    // console.log(">> inquiryID", inquiryID);
    if (inquiryID) {
      let obj = {
        documentNumber: inquiryID,
      };
      await ItemService.getInquiryDetail(obj).then((item) => {
        if (item?.status === 200) {
          // console.log(">> item", item);
          setDisabledStatus(true);
          setFormInputs(item?.data[0]);
        } else {
          setFormInputs([]);
          console.log(">> Error: Loading getCategory");
        }
      });
    } else {
      navigate(Constants.PRESALES_INQUIRY_LIST);
    }
  };

  useEffect(() => {
    loadFirstLevelData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Image",
      width: "80px",
      cell: (row) => (
        <img
          src="assets/img/profile-img.png"
          alt="Profile"
          className="rounded-circle profileImageCss"
          style={{ width: "40px" }}
        />
      ),
    },

    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Material Group",
      selector: (row) => row.materialGroup,
      sortable: true,
      width: "180px",
    },
    {
      name: "Inquiry Quantity",
      selector: (row) => row.inquiryQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Plant",
      selector: (row) => row.plant,
      sortable: true,
      width: "200px",
    },
  ];

  const createQuotation = () => {
    dispatch(addCompletedInquiryList(formInputs));
    navigate(Constants.PRESALES_CUSTOMER_CREATE_QUOTATION);
  };

  // Onclick on Table Header Button
  const handleAcknowledgeClick = async () => {
    let obj = {
      inquiryNr: formInputs.inquiryNr,
    };

    // if (getSelectedData.length > 0) {
    await ItemService.acknowledgeInquiry(obj).then((item) => {
      if (item?.status === 200) {
        toast.success(item?.message || "Inquiry Acknowledged successfully", {
          duration: 3000,
          position: "top-right",
        });

        setDisabled(true);
        loadFirstLevelData();
       
      } else {
        console.log("Inquiry Acknowledged Not successfully");
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Inquiry Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {inquiryID}
              </span>
              <span>
                {formInputs?.inqHeaderstatus === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : formInputs?.inqHeaderstatus === "In Progress" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : formInputs?.inqHeaderstatus === "New" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : formInputs?.inqHeaderstatus === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : formInputs?.inqHeaderstatus === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : formInputs?.inqHeaderstatus === "Deactivated" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.inqHeaderstatus}
                  </span>
                ) : (
                  formInputs?.inqHeaderstatus
                )}
              </span>
            </h2>
            <p className="subText">Inquiry Information </p>
            <hr />

            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HR</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle">
                        Inquiry Basic Information
                      </span>
                    </Col>
                    {formInputs?.inqHeaderstatus === "Acknowledged" && (
                      <Col xs={1} md={2} lg={2} className="">
                        <button className="btnTable" onClick={createQuotation}>
                          <i className="bi bi-cart-check"></i> Create Quotation
                        </button>
                      </Col>
                    )}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inquiry No"
                          name="inquiryNr"
                          extraLabel=""
                          placeholder=""
                          value={formInputs.inquiryNr || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          name="inqHeaderstatus"
                          placeholder=""
                          value={formInputs.inqHeaderstatus || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Sold to Party"
                          type="text"
                          name="soldToParty"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Ship to Party"
                          name="shipToParty"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Order Type"
                          name="orderType"
                          placeholder=""
                          value={formInputs.salesType || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Request Delivery Date"
                          name="requestDeliveryDate"
                          placeholder=""
                          value={formInputs.reqestDeliveryDate || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          placeholder=""
                          value={formInputs.paymentTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          placeholder=""
                          value={formInputs.incoTerms || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Code"
                          name="customerCode"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={formInputs.customerName || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Sales Organization"
                          name="salesOrg"
                          placeholder=""
                          value={formInputs.salesOrg || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Disrtibution Channel"
                          name="disrtibutionChannel"
                          placeholder=""
                          value={formInputs.disrtibutionChannel || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Division"
                          name="division"
                          placeholder=""
                          value={formInputs.division || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Remark"
                          type="text"
                          name="inquiryRemark"
                          id="inquiryRemark"
                          placeholder=""
                          value={formInputs?.inquiryRemark || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="initiator By"
                          name="initiatedBy"
                          placeholder=""
                          value={formInputs.initiatedBy || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">Line Item / Products</span>
                      <span className="subHeaderTitle">
                        Line Item / Products Details
                      </span>
                    </Col>
                    {formInputs?.inqHeaderstatus === "New" && (
                      <Col xs={1} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={() => handleAcknowledgeClick()}
                          disabled={disabled}
                        >
                          Acknowledged
                        </button>
                      </Col>
                    )}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={formInputs?.items}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={purchaseOrderTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default InquiryDetailPage;
