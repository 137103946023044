
import React, { useState } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


//Redux
// import { useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
// import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
// import Constants from "../../../common/SupplierConstants";

const CreatePlant = () => {
  // localStorage.setItem("menu", "Admin");
  // localStorage.setItem("submenu", "Create Plant");

  const [isCardVisible, setCardVisible] = useState(true);

  const [formInputs, setFormInputs] = useState({
    companyCode: "",
    plantCode: "",
    plantDescription: "",
    address: "",
    name1: "",
    name2: "",
    city: "",
    country: "",
    currency: "",
    language: "",
    street: "",
    postalCode: "",
    googleMapLocation: "",
  });


  
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  // const modalData = {
  //     title: "Plant Updated",
  //     successMsg: "",
  //   };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  console.log(">> formInputs", formInputs);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Plant List</h2>
            <p className="subText">Create Plant</p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                          }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Create Plant</span>
                      <span className="subHeaderTitle">Create Plant</span>
                    </Col>

                    <Col xs={1} md={1} lg={1} className="text-right">
                      <span>
                        <button className="btnTable">Create Plant</button>
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${isCardVisible ? "visible" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                    <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          name="companyCode"
                          id="companyCode"
                          extraLabel=""
                          placeholder="Company Code"
                          value={formInputs.companyCode || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Plant Code"
                          name="plantCode"
                          id="plantCode"
                          extraLabel=""
                          placeholder="Plant Code"
                          value={formInputs.plantCode || ""}
                          onChange={onInputChange}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                        >
                        <TextAreaField
                          className="inputBox"
                          label="Plant Description"
                          type="text"
                          name="plantDescription"
                          id="plantDescription"
                          placeholder="Reason"
                          value={formInputs.plantDescription || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                       >
                        <InputField
                          className="inputBox"
                          label="Address"
                          name="address"
                          id="address"
                          extraLabel=""
                          placeholder="Address"
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Name1"
                          name="name1"
                          id="name1"
                          extraLabel=""
                          placeholder="Name1"
                          value={formInputs.name1 || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Name2"
                          name="name2"
                          id="name2"
                          extraLabel=""
                          placeholder="Name2"
                          value={formInputs.name2 || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          id="city"
                          extraLabel=""
                          placeholder="City"
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Country"
                          name="country"
                          id="country"
                          extraLabel=""
                          placeholder="Country"
                          value={formInputs.country || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          id="currency"
                          extraLabel=""
                          placeholder="Currency"
                          value={formInputs.currency || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Language"
                          name="language"
                          id="language"
                          extraLabel=""
                          placeholder="Language"
                          value={formInputs.language || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Street"
                          name="street"
                          id="street"
                          extraLabel=""
                          placeholder="Street"
                          value={formInputs.street || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          extraLabel=""
                          placeholder="postalCode"
                          value={formInputs.postalCode || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Google Map Location"
                          name="googleMapLocation"
                          id="googleMapLocation"
                          extraLabel=""
                          placeholder="Google Map Location"
                          value={formInputs.googleMapLocation || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      


                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      
    </main>
  );
};

export default CreatePlant;

