import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

// import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

//React Primeng
import "primeflex/primeflex.css";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { Toaster } from "react-hot-toast";
// import { createRoot } from "react-dom/client";
// const container = document.getElementById("root");
// const root = createRoot(container); // Create a root for React 18
// root.render(
//   <>
//     <Toaster />
//     <App />
//   </>
// );

import ReactDOM from "react-dom";
ReactDOM.render(
  <>
    <Toaster />
    <React.StrictMode>
      <App />
      {/* root.render(<App />); */}
    </React.StrictMode>
  </>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Toaster />
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//serviceWorker.unregister();
serviceWorker.register();
