/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { InputField } from "../../../formElements/InputField";
import { ComboboxField } from "../../../formElements/ComboboxField";
import Constants from "../../../../common/SupplierConstants";

const AuctionContactInformation = React.forwardRef((props, ref) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [description, setAccessData] = useState("");
  
  let dropdownOption = [
    {
      id: "1",
      name: "200-Spares and Operations Maintenance",
    },
  ];

  const [formInputs, setFormInputs] = useState({
    name: "",
    email:"",
    description: "",
  });

  const handleSubmit = async () => {
    if (!formInputs.name.trim() || // Check if name is empty or only contains whitespace
    !formInputs.email.trim() || // Check if email is empty or only contains whitespace
    !formInputs.description || // Check if description is empty
    (typeof formInputs.description === 'object' && !formInputs.description.name)) {
      setErrorMsg("* Fields are mandatory");
      return;
    } else {
      setErrorMsg("");
    }

    const data = {
      name: formInputs.name,
      email:formInputs.email,
      description: formInputs.description.name || formInputs.description,
    };
    // console.log(">> data ", data);
    props.hederTextData(data);  
    props.onHide();      
  };

  useEffect(() => {
    setFormInputs({
      name:"",
      email: "",
      description: "",
    });

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  useEffect(() => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      description: description,
    }));
  }, [description]);

  return (
    <Modal
      // {...props}
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      dialogClassName="modal-90w"
      //ref={ref}
      // animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="bi bi-plus-lg"></i> Header text
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <form className="space-y-4">
          <Row className="d-flex">
            
            
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <InputField
                type="text"
                className="inputBox"
                label="Buyer Name"
                name="name"
                // placeholder=""
                value={formInputs.name || ""}
                onChange={onInputChange}
              />
            </Col>
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <InputField
                type="text"
                className="inputBox"
                label="Buyer Email"
                name="email"
                // placeholder=""
                value={formInputs.email || ""}
                onChange={onInputChange}
              />
            </Col>
            <Col xs={12} md={12} lg={4} className="commLeftRightPadding">
              <ComboboxField
                label="Buyer Contact Number"
                placeholder="Description"
                data={dropdownOption}
                id="description"
                iconClassName="dropdownIcon"
                name="description"
                setValue={formInputs.description || ""}
                getvalue={setAccessData}
                // getvalue={(value) => setFormInputs((formInputs) => ({ ...formInputs, description: value }))}
                className="dropdownOption"
              />
            </Col>
          </Row>

          <Row className="justify-end">
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={2} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
                disabled={disabled}
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              <p className="errorMsg"> {errorMsg} </p>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default AuctionContactInformation;
