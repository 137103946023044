import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import { InputField } from "../../../../Components/formElements/InputField";
import toast from "react-hot-toast";
//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { formatDate, getTodayDate } from "../../../../common/Validations";

const PreDispatchModal = React.forwardRef((props, ref) => {
  let preDispatchCol = [
    {
      name: "Sl No",
      selector: (row) => row.local_id || "--",
      cell: (row) => <>{row.local_id}</>,
      sortable: true,
      width: "130px",
    },
    // {
    //   name: "Order QTY loads",
    //   selector: (row) => row.disptachQtyLoad || "--",
    //   cell: (row) => (
    //     <>
    //       {" "}
    //       <InputField
    //         className="inputBox mt-1"
    //         // label="Please Enter the Remarks*"
    //         name="disptachQtyLoad"
    //         placeholder=""
    //         // type="date"
    //         value={"1"}
    //         disabled={true}
    //         onChange={(event) => handleInputChange(row.local_id, event)}
    //       />
    //     </>
    //   ),
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Disptach QTY(MT)",
      selector: (row) => row.disptachQty || "--",
      cell: (row) => (
        <>
          {" "}
          <InputField
            className="inputBox mt-1"
            // label="Please Enter the Remarks*"
            name="disptachQty"
            placeholder=""
            value={row.disptachQty}
            disabled={false}
            onChange={(event) => handleInputChange(row.local_id, event)}
            onBlur={(event) =>
              handleInputChangeDisptachQty(row.local_id, event)
            }
          />
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Invoice",
      selector: (row) => row.invoiceNumber || "--",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            // label="Please Enter the Remarks*"
            name="invoiceNumber"
            placeholder=""
            value={row.invoiceNumber}
            onChange={(event) => handleInputChange(row.local_id, event)}
            disabled={false}
          />
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "MDP",
      selector: (row) => row.mDPNumber || "--",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            // label="Please Enter the Remarks*"
            name="mDPNumber"
            placeholder=""
            value={row.mDPNumber}
            onChange={(event) => handleInputChange(row.local_id, event)}
            disabled={false}
          />
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Vehical",
      selector: (row) => row.vehicalNumber || "--",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            // label="Please Enter the Remarks*"
            name="vehicalNumber"
            placeholder=""
            value={row.vehicalNumber}
            onChange={(event) => handleInputChange(row.local_id, event)}
            disabled={false}
          />
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Order",
      selector: (row) => row.documentNumber || "--",
      cell: (row) => (
        <>
          <p className="m-0 p-0 ">{row.documentNumber} </p>
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Project",
      selector: (row) => row.projectName || "--",
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 fw-bold ">{row.projectName} </p>
            <p className="m-0 p-0 ">{row.customerName} </p>
          </div>
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "Product",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "110px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold ">{row.materialDescription} </p>
          <p className="m-0 p-0 ">{row.materialCode} </p>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => row.createdDatecreatedDate || "--",
      cell: (row) => (
        <>
          <p className="m-0 p-0 ">{formatDate(row.createdDatecreatedDate)} </p>
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Disptach Date",
      selector: (row) => row.dispatchDate || "--",
      cell: (row) => (
        <>
          {/* <p className="m-0 p-0 ">{row.dispatchDate} </p> */}
          <InputField
            className="inputBox mt-1"
            // label="Please Enter the Remarks*"
            name="dispatchDate"
            type="date"
            placeholder=""
            value={row.dispatchDate || getTodayDate("")}
            disabled={false}
            onChange={(event) => handleInputChange(row.local_id, event)}
          />
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Zone",
      selector: (row) => row.dispatchZone || "--",
      cell: (row) => (
        <>
          {" "}
          <p className="m-0 p-0 ">{row.dispatchZone} </p>
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Delete",
      selector: (row) => row.local_id || "--",
      sortable: true,
      cell: (row) => (
        <button onClick={() => removeOrderSchedulingRow(row.local_id)}>
          <i className="bi bi-trash text-danger fs-4" />
        </button>
      ),
      width: "120px",
    },
  ];

  const [repeatedObjects, setRepeatedObjects] = useState([]);

  useEffect(() => {
    console.log(">> props asdasd", props);

    if (props.preDistachdata) {
      const repeatCount = Number(props.preDistachdata?.balOrderQuantityLoad);
      const generatedData = Array.from({ length: repeatCount }, (_, index) => ({
        ...props.preDistachdata,
        local_id: index + 1, // Assign serial number
        disptachQtyLoad: 1, // Default empty values
        disptachQty: "",
        invoiceNumber: "",
        mDPNumber: "",
        vehicalNumber: "",
        dispatchStatus: "InTransit",
        salesOfficerCode:props.preDistachdata.createdBy,
        salesOfficerName:props.preDistachdata.createdByName
      }));
      setRepeatedObjects(generatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preDistachdata]); // Run only when `preDistachdata` changes

  const [getPreDispatchCol] = useState(preDispatchCol);

  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (localId, event) => {
    const { name, value } = event.target;
    setRepeatedObjects((prevData) =>
      prevData.map((item) =>
        item.local_id === localId ? { ...item, [name]: value } : item
      )
    );
  };

  const handleInputChangeDisptachQty = (localId, event) => {
    const { name, value } = event.target;

    if (value >= 25 && value <= 50) {
      setRepeatedObjects((prevData) =>
        prevData.map((item) =>
          item.local_id === localId ? { ...item, [name]: value } : item
        )
      );
    } else {
      toast.error("Value Should be Greater than 25 Less than 50 ! ", {
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const removeOrderSchedulingRow = (localId) => {
    setRepeatedObjects((prevData) =>
      prevData.filter((item) => item.local_id !== localId)
    );
  };

  const checkDispatchQty = (item) => {
    const dispatchQty = Number(item.disptachQty); // Convert to number

    if (isNaN(dispatchQty)) {
      // console.log(`Invalid disptachQty value: ${item.disptachQty}`);
      toast.error(`Invalid disptachQty value: ${item.disptachQty}`, {
        duration: 4000,
        position: "top-right",
      });
      return false;
    }

    if (dispatchQty >= 25 && dispatchQty <= 50) {
      return true;
    } else {
      // console.log(`disptachQty ${dispatchQty} is NOT within the range 25 to 50.`);
      toast.error(
        `disptachQty ${dispatchQty} is NOT within the range 25 to 50.`,
        {
          duration: 4000,
          position: "top-right",
        }
      );
      return false;
    }
  };

  const handleSave = async () => {
    // console.log(">> repeatedObjects", repeatedObjects);
    let finalobjToSave = [];
    const filteredData = repeatedObjects.filter(
      (item) => item.disptachQty !== ""
    );

    // console.log(">> filteredData", filteredData);

    // filteredData.forEach((item) => {
    //   checkDispatchQty(item);
    // });

    if (filteredData.length === 0) {
      toast.error(`Add atleast one disptach Qty`, {
        duration: 4000,
        position: "top-right",
      });
      return false;
    }

    // Process each item in the data array
    for (let i = 0; i < filteredData.length; i++) {
      const shouldContinue = checkDispatchQty(filteredData[i]);
      if (!shouldContinue) {
        console.log(
          "Stopping further processing due to a record not meeting the condition."
        );
        // break; // Stop the loop
      }else{
        
        finalobjToSave.push(filteredData[i])
      }
    }
    // console.log(">> finalobjToSave", finalobjToSave);
    const obj = {
      data: finalobjToSave,
    };

    if (filteredData.length === 0) {
      toast.error("Please Update One Disptach Qty to Save ", {
        duration: 3000,
        position: "top-right",
      });
      return;
    }

    // console.log(">>objOrderSheduling", obj);
    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    setDisabled(true);
    await ItemService.addDispatch(obj).then((item) => {
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
    toast.dismiss(toastId);
    props.onHide();
  };
  
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Pre Dispatch
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col
            xs={12}
            md={{ span: 2, offset: 10 }}
            lg={{ span: 2, offset: 10 }}
            className="headerButtonAlign"
          >
            <button
              className="btnTable"
              onClick={handleSave}
              disabled={disabled}
            >
              Save
            </button>
          </Col>
        </Row>
        <div className="accordion-body mt-2">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <DataTable
                title=""
                columns={getPreDispatchCol}
                data={repeatedObjects}
                // progressPending={loading}
                // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                // pagination
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                // selectableRows
                // onSelectedRowsChange={handleChange}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default PreDispatchModal;
