export let colData = [
  {
    name: "PO_REPORT",
    columns: [
      {
        name: "Document",
        selector: (row) => row.local_id || "--",
        sortable: true,
        width: "100px",
      },
      {
        name: "Purchasing DocumentNr",
        selector: (row) => row.purchasingDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.purchasingDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "200px",
      },
      {
        name: "Quantity ",
        selector: (row) => row.purchaseQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "200px",
      },
      {
        name: "Deviation Remarks", // input
        selector: (row) => row.deviationRemark || "--",
        width: "200px",
      },
    ],
  },
  {
    name: "ASN_REPORT",
    columns: [
      {
        name: "Document",
        selector: (row) => row.local_id || "--",
        sortable: true,
        width: "180px",
      },
      {
        name: "Purchasing DocumentNr",
        selector: (row) => row.purchasingDocumentNr || "--",
        sortable: true,
        width: "110px",
      },
      {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "200px",
      },
      {
        name: "Quantity ",
        selector: (row) => row.purchaseQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "200px",
      },
      {
        name: "Deviation Remarks", // input
        selector: (row) => row.deviationRemark || "--",
        width: "200px",
      },
    ],
  },
];
