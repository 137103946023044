/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { useNavigate } from "react-router-dom";

//Componentd
import Loader from "../../../Components/Loader";
import { reportInfo } from "../../../redux/feature/reportSlice";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

const SupplierReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Report");
  localStorage.setItem("submenu", "-");

  let reportItems = useSelector((state) => state?.common?.data?.supplier_report); // take it from Store

  const handleClick = (obj) => {
    dispatch(reportInfo(obj));
    navigate(obj.hrefLink);
  };

  return (
    <main className="dashboard main homePage" id="main">
      <Layout />
      <Row className="homeScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">Supplier Reports</h2>
            <p className="subText">Complete Details of the Reports</p>
            <hr />

            <Row>
              <Loader isLoading={!reportItems} className={"text-center"} />
              {reportItems && reportItems?.length > 0
                ? reportItems?.map((value, index) => {
                    return (
                      <Col
                        xs={12}
                        md={4}
                        className="commLeftRightPadding"
                        key={index}
                      >
                        <div
                          className="card info-card sales-card"
                          onClick={() => handleClick(value)}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                <i className={value.icon}></i>
                              </div>
                              <div className="ps-3">
                                <h6>{value.name}</h6>
                                <span
                                  className="text-muted small pt-2"
                                  style={{ fontWeight: "600" }}
                                >
                                  {value.description}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SupplierReports;
