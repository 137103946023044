import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import toast from "react-hot-toast";

import { InputField } from "../InputField";
import { ComboboxField } from "../ComboboxField";
import { TextAreaField } from "../TextAreaField";
import {
  addDynamicFormDataTECH,
  addDynamicFormDataCOMM,
  saveDynamicForm,
} from "../../../redux/feature/supplier_app/sourcingSlice";

// Redux
import { useDispatch, useSelector } from "react-redux";

const GenerateForm = (props) => {
  const dispatch = useDispatch();

  // let dynamicFormDataStatus = useSelector(
  //   (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataStatus
  // ); // purchase_requisition from Store
  let dynamicFormDataCOMM = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataCOMM
  ); // purchase_requisition from Store
  let dynamicFormDataTECH = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.dynamicFormDataTECH
  ); // purchase_requisition from Store

  const dropdownOption = [
    {
      id: "text",
      name: "Text",
    },
    {
      id: "number",
      name: "Number",
    },
    {
      id: "select",
      name: "Select",
    },
    {
      id: "radio",
      name: "Radio",
    },
    {
      id: "checkbox",
      name: "Checkbox",
    },
    {
      id: "textarea",
      name: "Textarea",
    },
  ];

  const [getDropdownData, setDropdownData] = useState("");
  const [getFinalJsonFormData, setFinalJsonFormData] = useState([]);

  const [formData, setFormData] = useState({
    componentId: "",
    mandatory: false,
    label: "",
    type: "text",
    serialNo: "",
    options: [{ value: "", isDefault: false }], // Array to hold options
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">>Dropdown", getDropdownData);
    if (getDropdownData?.name) {
      setFormData((formData) => ({
        ...formData,
        [getDropdownData?.textField]: getDropdownData?.id,
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        [getDropdownData?.textField]: getDropdownData?.id,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...formData.options];
    updatedOptions[index].id = index + 1;
    updatedOptions[index].name = value;
    updatedOptions[index].value = value;

    setFormData((prevData) => ({
      ...prevData,
      options: updatedOptions,
    }));
  };

  const handleDefaultChange = (index) => {
    const updatedOptions = formData.options.map((option, i) => ({
      ...option,
      isDefault: i === index,
    }));
    setFormData((prevData) => ({
      ...prevData,
      options: updatedOptions,
    }));
  };

  const handleAddOption = () => {
    setFormData((prevData) => ({
      ...prevData,
      options: [...prevData.options, { value: "", isDefault: false }],
    }));
  };

  const handleSave = () => {
    if (getFinalJsonFormData.length > 0) {
      if (props?.formType === "COMM") {
        dispatch(addDynamicFormDataCOMM(getFinalJsonFormData));
      } else if (props?.formType === "TECH") {
        dispatch(addDynamicFormDataTECH(getFinalJsonFormData));
      }

      dispatch(saveDynamicForm(true));
      props.saveconfirm();
    } else {
      toast.error("Please add atleast one record !!", {
        duration: 4000,
        position: "top-right",
      });
    }
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = formData.options.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      options: updatedOptions,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.label === "" || formData.type === undefined) {
      toast.error("Please fill the required field !!", {
        duration: 4000,
        position: "top-right",
      });
      return;
    }

    if (
      formData.type === "select" ||
      formData.type === "radio" ||
      formData.type === "checkbox"
    ) {
      if (formData?.options.length > 0) {
        if (formData?.options[0]?.value === "") {
          toast.error("Add atleast one item !!", {
            duration: 4000,
            position: "top-right",
          });
          return;
        }
      } else {
        toast.error("Add atleast one item !!", {
          duration: 4000,
          position: "top-right",
        });
        return;
      }
    }

    const jsonData = {
      componentId:"question_" + Number(getFinalJsonFormData.length + 1),
      mandatory: formData.mandatory,
      question: {
        label: formData.label,
      },
      answerDefinition: {
        type: formData.type,
        options: formData.options,
      },
      serialNo: Number(getFinalJsonFormData.length + 1),
    };

    setFinalJsonFormData((prevData) => [...prevData, jsonData]);
  };

  const handleRemoveFormBuild = (serialNo) => {
    const updatedOptions = getFinalJsonFormData.filter(
      (item) => item.serialNo !== serialNo
    );
    let updateJson = updatedOptions.map((item, index) => ({
      ...item,
      serialNo: index + 1,
      componentId: `${item.answerDefinition.type}_${index + 1}`,
    }));
    setFinalJsonFormData(updateJson);
    if (props?.formType === "COMM") {
      dispatch(addDynamicFormDataCOMM(updateJson));
    } else if (props?.formType === "TECH") {
      dispatch(addDynamicFormDataTECH(updateJson));
    }
  };

  // Render Questions
  const RenderInputField = ({ questionData }) => {
    const { componentId, mandatory, question, answerDefinition, serialNo } =
      questionData;

    switch (answerDefinition?.type) {
      case "textarea":
        return (
          <div className="formBuildSection">
            <Col xs={11} md={11} lg={11} className="commLeftRightPadding">
              <TextAreaField
                label={serialNo + ". " + question?.label}
                id={componentId}
                name={componentId}
                className="inputBox"
                placeholder=""
                // value={getRemarks}
                // onChange={handleInputChange}
                required={mandatory}
              />
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      case "select":
        return (
          <div className="formBuildSection">
            <Col
              xs={11}
              md={11}
              lg={11}
              className="commTopButtonRightLeftPadding"
            >
              <ComboboxField
                label={serialNo + ". " + question?.label}
                placeholder=""
                data={answerDefinition?.options}
                id={componentId}
                iconClassName="dropdownIcon"
                name={componentId}
                code={componentId}
                required={mandatory}
                // setValue={formData.type || ""}
                // getvalue={setDropdownData}
              />
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      case "radio":
        return (
          <div className="formBuildSection">
            <Col
              xs={11}
              md={11}
              lg={11}
              className="commTopButtonRightLeftPadding"
            >
              <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                {serialNo + ". " + question?.label} {mandatory && "*"}
              </label>

              {answerDefinition?.options.map((option, index) => {
                return (
                  <div key={index} className="block">
                    <input
                      type="radio"
                      className="form-check-input fs-5 ml-3"
                      id={`${componentId}-${index}`}
                      name={componentId}
                      // value={option?.value}
                      required={mandatory}
                    />
                    <span className="ml-3">{option?.value} </span>
                  </div>
                );
              })}
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      case "checkbox":
        return (
          <div className="formBuildSection">
            <Col
              xs={11}
              md={11}
              lg={11}
              className="commTopButtonRightLeftPadding"
            >
              <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                {serialNo + ". " + question?.label} {mandatory && "*"}
              </label>

              {answerDefinition?.options.map((option, index) => {
                return (
                  <div key={index} className="block">
                    <input
                      type="checkbox"
                      className="form-check-input fs-5 ml-3"
                      id={`${componentId}-${index}`}
                      name={componentId}
                      // value={option?.value}
                      required={mandatory}
                    />
                    <span className="ml-3">{option?.value} </span>
                  </div>
                );
              })}
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      case "text":
        return (
          <div className="formBuildSection">
            <Col
              xs={11}
              md={11}
              lg={11}
              className="commTopButtonRightLeftPadding block"
            >
              <InputField
                label={serialNo + ". " + question?.label}
                type="text"
                className="inputBox"
                id={componentId}
                name={componentId}
                placeholder=""
                // value={option.value}
                // onChange={(e) =>
                //   handleOptionChange(index, e.target.value)
                // }
                required={mandatory}
              />
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      case "number":
        return (
          <div className="formBuildSection">
            <Col
              xs={11}
              md={11}
              lg={11}
              className="commTopButtonRightLeftPadding block"
            >
              <InputField
                label={serialNo + ". " + question?.label}
                type="number"
                className="inputBox"
                id={componentId}
                name={componentId}
                placeholder=""
                // value={option.value}
                // onChange={(e) =>
                //   handleOptionChange(index, e.target.value)
                // }
                required={mandatory}
              />
            </Col>
            <Col
              xs={1}
              md={1}
              lg={1}
              className="commTopButtonRightLeftPadding mt-4"
            >
              <i
                onClick={() => handleRemoveFormBuild(serialNo)}
                className="bi bi-trash close-btn"
              ></i>
            </Col>
          </div>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (props?.formType === "COMM") {
      setFinalJsonFormData(dynamicFormDataCOMM);
    } else if (props?.formType === "TECH") {
      setFinalJsonFormData(dynamicFormDataTECH);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <form>
      <Row className="pt-2 pb-4">
        <Col xs={12} md={6} lg={6}>
          <h3>Build Questionnaire</h3>
          <Row>
            <Col
              xs={12}
              md={12}
              lg={12}
              className="commTopButtonRightLeftPadding"
            >
              <InputField
                type="text"
                className="inputBox"
                label="Label"
                id="label"
                name="label"
                placeholder=""
                value={formData.label || ""}
                onChange={handleChange}
                required={true}
              />
            </Col>

            <Col
              xs={12}
              md={12}
              lg={12}
              className="commTopButtonRightLeftPadding"
            >
              <ComboboxField
                label="Type"
                placeholder=""
                data={dropdownOption}
                id="type"
                iconClassName="dropdownIcon"
                name="type"
                code="type"
                setValue={formData.type || ""}
                getvalue={setDropdownData}
                required={true}
              />
            </Col>
            <Col
              xs={12}
              md={12}
              lg={12}
              className="commTopButtonRightLeftPadding"
            >
              <label
                htmlFor="mandatory"
                className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion"
              >
                Mandatory:
              </label>
              <input
                type="checkbox"
                className="form-check-input fs-5 ml-3"
                name="mandatory"
                id="mandatory"
                checked={formData.mandatory}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {formData.type === "select" ||
          formData.type === "radio" ||
          formData.type === "checkbox" ? (
            <Row>
              <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion mt-4">
                Answer Definition:
              </label>

              {formData.options.map((option, index) => (
                <div key={index}>
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className="commTopButtonRightLeftPadding"
                  >
                    <Row>
                      <Col
                        xs={1}
                        md={1}
                        lg={1}
                        className="commTopButtonRightLeftPadding"
                      >
                        <input
                          type="radio"
                          className="form-check-input fs-4 mr-3 mt-2"
                          name="defaultOption"
                          id="defaultOption"
                          checked={option.isDefault}
                          onChange={() => handleDefaultChange(index)}
                        />
                      </Col>
                      <Col
                        xs={2}
                        md={9}
                        lg={9}
                        className="commTopButtonRightLeftPadding block"
                      >
                        <InputField
                          type="text"
                          className="inputBox"
                          label=""
                          id="options"
                          name="options"
                          placeholder=""
                          value={option.value}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding mt-2"
                      >
                        <i
                          onClick={() => handleRemoveOption(index)}
                          className="bi bi-trash close-btn"
                        ></i>
                      </Col>
                    </Row>
                  </Col>
                </div>
              ))}

              <Col xs={12} md={3} lg={3} className="commLeftRightPadding">
                <p className="addMore" onClick={handleAddOption}>
                  <i className="bi bi-plus addMore"></i> Add More
                </p>
              </Col>
            </Row>
          ) : null}

          <Row className="justify-end">
            <Col xs={12} md={4} lg={4} className="commLeftRightPadding">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary w-full justify-center rounded-md"
              >
                Generate Form
              </button>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} lg={6}>
          <Row>
            <Col xs={12} md={10} lg={10}>
              <h3>Preview Questionnaire</h3>
            </Col>
            <Col xs={12} md={2} lg={2}>
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary w-full justify-center rounded-md mb-2"
              >
                Save Form
              </button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              {getFinalJsonFormData &&
                getFinalJsonFormData.length > 0 &&
                getFinalJsonFormData.map((formJsonData, index) => {
                  return (
                    <RenderInputField questionData={formJsonData} key={index} />
                  );
                })}
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
};

export default GenerateForm;
