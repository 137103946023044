
import React, { useState,useEffect } from 'react'   
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// import { useLocation } from 'react-router-dom';  

import SupplierConstantsList from './common/SupplierConstants';
import CustomerConstantsList from './common/CustomerConstants';

import Page404 from './Pages/404Page';
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';
import Company from './Pages/Settings/Company';
import Profile from './Pages/Settings/Profile';

// APP Dashboard
import AppLandingPage from './Pages/AppLandingPage';

// Supplier_App START -----------------------------------------------------------------------------------------------------------------
import HOME from './Pages/supplier_app';
import HOME_INTRO from './Pages/supplier_app/HomeIntro/HomeIntro';
import DASHBOARD from './Pages/supplier_app/Dashboard';
import INBOX from './Pages/supplier_app/Inbox';
import OUTBOX from './Pages/supplier_app/Outbox'; 
// SUPPLIER    
import SELF_REGISTRATION from './Pages/SelfRegistration';
import SUPPILER_REGISTRATION from './Pages/supplier_app/Supplier/SupplierMaster/SupplierRegistration';
import SUPPILER_LIST from './Pages/supplier_app/Supplier/SupplierMaster/SupplierList';
import SUPPILER_MASS_UPLOAD from './Pages/supplier_app/Supplier/SupplierMassUpload';
import SUPPLIER_MASTER_CREATE from './Pages/supplier_app/Supplier/SupplierMaster/SupplierMasterCreate';
import SUPPLIER_DETAILED_PAGE_CREATE from './Pages/supplier_app/Supplier/SupplierMaster/SupplierDetailedPage';
import SUPPLIER_DETAILED_PAGE_WORKFLOW from './Pages/supplier_app/Supplier/SupplierMaster/SupplierDetailedPageWorkflow';
// SOURCING
import SOURCING_PURCHASE_REQUISITION_LIST from './Pages/supplier_app/Sourcing/PR/PurchaseRequisitionList';
import SOURCING_VENDOR_REQUEST_FOR_QUOTATION from './Pages/supplier_app/Sourcing/Supplier/VendorRequestForQuotationList';
import SOURCING_VENDOR_RFQ_DETAILS from './Pages/supplier_app/Sourcing/Supplier/VendorRFQDetails'
import SOURCING_VENDOR_QUOTATION_LIST from './Pages/supplier_app/Sourcing/Supplier/VendorQuotationList';
import SOURCING_BUYER_REQUEST_FOR_QUOTATION from './Pages/supplier_app/Sourcing/Buyer/BuyerRequestForQuotationList';
import SOURCING_BUYER_RFQ_DETAILS from './Pages/supplier_app/Sourcing/Buyer/BuyerRFQDetails'
import SOURCING_BUYER_QUOTATION_LIST from './Pages/supplier_app/Sourcing/Buyer/BuyerQuotationList';
import SOURCING_CREATE_RFQ from './Pages/supplier_app/Sourcing/Buyer/CreateRFQ';
import SOURCING_CREATE_MANUAL_RFQ from './Pages/supplier_app/Sourcing/Buyer/CreateManualRFQ';
import SOURCING_CREATE_QUOTATION from './Pages/supplier_app/Sourcing/Supplier/CreateQuotation';
import SOURCING_QUOTATION_DETAILS from './Pages/supplier_app/Sourcing/Supplier/QuotationDetailPage';
import SOURCING_TECH_EVALUATION_REQUEST_FOR_QUOTATION from './Pages/supplier_app/Sourcing/TechEvaluation/TechEvaluationRFQList';
import SOURCING_TECH_EVALUATION_DETAILS from './Pages/supplier_app/Sourcing/TechEvaluation/TechEvaluationRFQDetails';
// SOURCING
import SOURCING_PURCHASE_REQUISITION_CREATE from './Pages/supplier_app/Sourcing/PR/CreatePR';
import SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE from './Pages/supplier_app/Sourcing/PR/PurchaseRequisitionDetailedPage';
import SOURCING_COMMERCIAL_EVALUATION from './Pages/supplier_app/Sourcing/CommEvaluation/CommercialEvaluation'
import SOURCING_TECHNICAL_EVALUATION from './Pages/supplier_app/Sourcing/TechEvaluation/TechnicalEvaluation'
import SOURCING_PURCHASE_ORDER_CREATION from './Pages/supplier_app/Sourcing/CreatePO'
// POCOLLABRATION
import POCOLLABRATION_PURCHASE_ORDER_LIST from './Pages/supplier_app/POCollabration/PO/PurchaseOrderList';
import POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST from './Pages/supplier_app/POCollabration/PO/ServicePurchaseOrderList';
import POCOLLABRATION_PURCHASE_ORDER_CREATION from './Pages/supplier_app/POCollabration/PO/CreatePO';
import POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE from './Pages/supplier_app/POCollabration/PO/PurchaseOrderDetailPage';
import POCOLLABRATION_SERVICE_ENTRY_SHEET  from './Pages/supplier_app/POCollabration/SES/ServiceEntrySheetList';
import POCOLLABRATION_INVOICE  from './Pages/supplier_app/POCollabration/INVOICE/InvoiceList';
import POCOLLABRATION_INVOICE_DETIAILS  from './Pages/supplier_app/POCollabration/INVOICE/InvoiceDetails';
import POCOLLABRATION_PAYMENT_LIST  from './Pages/supplier_app/POCollabration/PAYMENT/PaymentList';
import POCOLLABRATION_ASN from './Pages/supplier_app/POCollabration/ASN/ASNList';
import POCOLLABRATION_ASN_DETAIL_PAGE from './Pages/supplier_app/POCollabration/ASN/ASNDetailPage';
import POCOLLABRATION_ASN_CREATION from './Pages/supplier_app/POCollabration/ASN/CreateASN'
import POCOLLABRATION_SA_ASN_CREATION from './Pages/supplier_app/POCollabration/ASN/CreateSAASN'
import POCOLLABRATION_GRN_CREATION from './Pages/supplier_app/POCollabration/GRN/CreateGRN'
import POCOLLABRATION_GRN_DETAILS from './Pages/supplier_app/POCollabration/GRN/GRNDetails'
import POCOLLABRATION_GOODS_RECEIVED_NOTE  from './Pages/supplier_app/POCollabration/GRN/GoodsReceivedNoteList';
import POCOLLABRATION_SES_CREATION from './Pages/supplier_app/POCollabration/SES/CreateSES'
import POCOLLABRATION_INVOICE_CREATION from './Pages/supplier_app/POCollabration/INVOICE/CreateInvoice'
import POCOLLABRATION_SCHEDULING_AGREEMENT from './Pages/supplier_app/POCollabration/PO/SchedulingAgreement';
import POCOLLABRATION_SERVICE_ENTRY_DETAILS from './Pages/supplier_app/POCollabration/SES/ServiceEntrySheetDetail';
// SAPREPROCESS
import SAPREPROCESS_GRN_REPROCESS  from './Pages/supplier_app/SAPReprocess/GRNReprocess';
import SAPREPROCESS_SES_REPROCESS from './Pages/supplier_app/SAPReprocess/SESReprocess';
import SAPREPROCESS_INVOICE_REPROCESS from './Pages/supplier_app/SAPReprocess/InvoiceReprocess';
// ADMIN_MANAGE
import  ADMIN_MANAGE_USER_LIST  from './Pages/supplier_app/Admin/ManageUserList';
import  ADMIN_MANAGE_ROLE from './Pages/supplier_app/Admin/ManageRole';
import  ADMIN_MANAGE_ADD_USER from './Pages/supplier_app/Admin/ManageAddUser';
import  ADMIN_MANAGE_EDIT_USER from './Pages/supplier_app/Admin/ManageEditUser';
import  ADMIN_MANAGE_USER_LIST_DETAILS from './Pages/supplier_app/Admin/ManageUserDetails';
// AUCTION_REVERSE_AUCTION
import AUCTION_REVERSE_AUCTION_LIST from './Pages/supplier_app/Auction/ReverseAuction';
import AUCTION_REVERSE_AUCTION_CREATION from './Pages/supplier_app/Auction/CreateAuction';
import AUCTION_REVERSE_AUCTION_HISTORY from './Pages/supplier_app/Auction/ReverseAuctionHistory';
import AUCTION_FORWARD_AUCTION_LIST from './Pages/supplier_app/Auction/ForwardAuction';
import AUCTION_FORWARD_AUCTION_HISTORY from './Pages/supplier_app/Auction/ForwardAuctionHistory';
// CONTRACTMANAGEMENT_CONTRACT
import CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST from './Pages/supplier_app/ContractManagement/ContractList';
import CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST from './Pages/supplier_app/ContractManagement/ContractReviewer';
import CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST from './Pages/supplier_app/ContractManagement/ContractDrafttoFinal';
import CONTRACTMANAGEMENT_MONITORING_LIST from './Pages/supplier_app/ContractManagement/ContractMonitoring';
// GATEPASS
import GATEPASS_GATEPASS_LIST from './Pages/supplier_app/GatePass/GatepassList';
import GATEPASS_GATEPASS_CREATE from './Pages/supplier_app/GatePass/GatepassCreation';
import GATEPASS_GATEPASS_DETAIL_PAGE from './Pages/supplier_app/GatePass/GatepassDetailPage';
// USER_MANUAL
import USER_MANUAL_BUYER_MANUAL from './Pages/supplier_app/UserManual/BuyerManual';
import USER_MANUAL_SUPPLIER_MANUAL from './Pages/supplier_app/UserManual/SupplierManual';
// DELEGATION
import DELEGATION_TASK from './Pages/supplier_app/Delegation/TaskDelegation';
import DELEGATION_USER from './Pages/supplier_app/Delegation/UserDelegation';
//SINGLE SOURCING
import SINGLESOURCING_SINGLE_SOURCING_CREATION from './Pages/supplier_app/SingleSourcing/CreateSingleSource';
import SINGLESOURCING_SINGLE_SOURCING_LIST from './Pages/supplier_app/SingleSourcing/SingleSourceList';
import SINGLESOURCING_SINGLE_SOURCING_DETAILS from './Pages/supplier_app/SingleSourcing/SingleSourceDetails';
//ADVANCE_PAYMENT_ADVANCE
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestCreate';
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestList';
import ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS from './Pages/supplier_app/AdvancePayment/AdvancePaymentRequestDetails';
//GHG - GREEN HOUS GAS
import GHG_SCOPE1_CREATION from './Pages/supplier_app/GHG/CreateGHGScop1';
//REPORTS
import SUPPLIER_REPORTS from './Pages/supplier_app/Report/SupplierReports';
import SUPPLIER_REPORT_DETAILS from './Pages/supplier_app/Report/ReportsDetail/SupplierReportsDetails';

// Supplier_App END -----------------------------------------------------------------------------------------------------------------


// Customer_App START -----------------------------------------------------------------------------------------------------------------

// import CUSTOMER_HOME from './Pages/customer_app';
import CUSTOMER_HOME_INTRO from './Pages/customer_app/HomeIntro/HomeIntro';
import CUSTOMER_DASHBOARD from './Pages/customer_app/Dashboard';
import CUSTOMER_INBOX from './Pages/customer_app/Inbox';
import CUSTOMER_OUTBOX from './Pages/customer_app/Outbox'; 

// CUSTOMER
// import CUSTOMER_SELF_REGISTRATION from './Pages/customer_app/Customer/CustomerMaster/CustomerList';
import CUSTOMER_REGISTRATION from './Pages/customer_app/Customer/CustomerMaster/CustomerMasterCreate';
import CUSTOMER_LIST from './Pages/customer_app/Customer/CustomerMaster/CustomerList';
import CUSTOMER_MASS_UPLOAD from './Pages/customer_app/Customer/CustomerMaster/CustomerList';
import CUSTOMER_MASTER_CREATE from './Pages/customer_app/Customer/CustomerMaster/CustomerMasterCreate';
import CUSTOMER_DETAILED_PAGE_CREATE from './Pages/customer_app/Customer/CustomerMaster/CustomerDetailedPage';
import CUSTOMER_DETAILED_PAGE_WORKFLOW from './Pages/customer_app/Customer/CustomerMaster/CustomerDetailedPageWorkflow';

// PRESALES
import PRESALES_INQUIRY_CREATION from './Pages/customer_app/Customer/PreSales/CreateInquiry';
import PRESALES_INQUIRY_LIST from './Pages/customer_app/Customer/PreSales/InquiryList';
import PRESALES_INQUIRY_DETAILS from './Pages/customer_app/Customer/PreSales/InquiryDetailPage';
import PRESALES_CUSTOMER_CREATE_QUOTATION from './Pages/customer_app/Customer/PreSales/CreateQuotation';
import PRESALES_CUSTOMER_QUOTATION_DETAILS from './Pages/customer_app/Customer/PreSales/QuotationDetailPage';
import PRESALES_CUSTOMER_QUOTATION_LIST from './Pages/customer_app/Customer/PreSales/QuotationList';
import PRESALES_CUSTOMER_QUOTATION_CREATE_PO from './Pages/customer_app/Customer/PreSales/CreatePOQuotation';

// SALES

 import  SALES_PURCHASE_ORDER_LIST from './Pages/customer_app/Customer/Sales/CustPurchaseOrderList'; 
 import  SALES_SALES_ORDER_LIST from './Pages/customer_app/Customer/Sales/SalesOrderList';
 import  SALES_PURCHASE_ORDER_DETAILS from './Pages/customer_app/Customer/Sales/CustPurchaseOrderDetails'; 
 import  SALES_SALES_ORDER_DETAILS from './Pages/customer_app/Customer/Sales/SalesOrderDetails'; 
 import  SALES_DELIVERY_CREATION from './Pages/customer_app/Customer/Sales/CreateDelivery'; 
 import  SALES_DELIVERY_LIST from './Pages/customer_app/Customer/Sales/DeliveryList'; 
 import  SALES_DELIVERY_DETAILS from './Pages/customer_app/Customer/Sales/DeliveryDetails'; 


// CATALOG
import PRODUCT_CATALOG_LIST from './Pages/customer_app/ProductCatalog/Catalog';
import PRODUCT_CATALOG_DETAILS from './Pages/customer_app/ProductCatalog/CatalogDetails';
import PRODUCT_CATALOG_MASTER from './Pages/customer_app/ProductCatalog/CatalogMaster';


//USER MANUAL
import USER_MANUAL_CUSTOMER_MANUAL from './Pages/customer_app/Customer/UserManual/CustomerManual';
import USER_MANUAL_MANAGER_MANUAL from './Pages/customer_app/Customer/UserManual/ManagerManual';

//REPORTS
import CUSTOMER_REPORTS from './Pages/customer_app/Report/CustomerReports';
import CUSTOMER_REPORT_DETAILS from './Pages/customer_app/Report/ReportsDetail/CustomerReportsDetails';

// Customer_App END -----------------------------------------------------------------------------------------------------------------



import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { initializeAppData } from './AppDataUtils';

// Redux
import { addAppInfo, updateAppCode } from './redux/feature/appSlice';
import usePageTracking from './usePageTracking';

// Assume this is your authentication check function
const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token;
};

const PrivateRoute = ({ children }) => {
    // const location = useLocation();
    // console.log(">> AppRouters >> user isAuthenticated >> " , isAuthenticated());

    // let check = checkHrefLink(menuData, location.pathname);
    // console.log(">> check", check);
    // console.log(">> location.pathname", location.pathname);
    // if(check){
    //     return isAuthenticated() ? children : <Navigate to="/login" />;
    // }else{

    //     return isAuthenticated() ? <Navigate to="/home" /> : <Navigate to="/login" />;
    // }    
    usePageTracking();
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

// const checkHrefLink = (arr, url) => {
//     for (let item of arr) {
//         if (item.hrefLink === url) {
//             return true;
//         }
//         if (item.children) {
//             const foundInChildren = checkHrefLink(item.children, url);
//             if (foundInChildren) return true;
//         }
//     }
//     return false;
// };

function AppRouters() {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    
    const [getUserData, setUserData] = useState([]);

    useEffect(() => {
        
        changeLanguage("en"); // kannada, tamil, en, ae
        console.log(">> lang:", t('msg_name'));
        let localData = localStorage.getItem('userData');
        let userData={}
        if(localData){
            userData = JSON.parse(localData);
            dispatch(addAppInfo(userData));  
            setUserData(userData)           
        }  
        
    // eslint-disable-next-line          
    },[]);

    useEffect(() => {
        if (getUserData?.data) {
            // console.log(">> App Router >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", getUserData);            
            let appCode = localStorage.getItem('application');            
            if(appCode === "SUPPLIER"){
                initializeAppData(dispatch, appCode);
                dispatch(updateAppCode(appCode));
            }else if(appCode === "CUSTOMER"){
                initializeAppData(dispatch, appCode);
                dispatch(updateAppCode(appCode));
            }else{
                initializeAppData(dispatch, appCode);
            }
        }

     // eslint-disable-next-line          
    },[dispatch, getUserData]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return(
        <>  
            {/* <Page404/> */}
            <Routes>                                      
                {/* Public route */}
                <Route exact path='*' element={<Page404 />}></Route>
                <Route exact path='/' element={<Login />}></Route>      
                <Route exact path={SupplierConstantsList.LOGIN} element={<Login/>}></Route>                
                <Route exact path={SupplierConstantsList.REGISTER} element={<Register/>}></Route>    
                {/* Private route */}
                
                <Route  exact path={SupplierConstantsList.COMPANY}  element={ <PrivateRoute>  <Company/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.PROFILE}  element={ <PrivateRoute> <Profile/>  </PrivateRoute>  } />

                {/* ----------------------------------  Supplier START  ---------------------------------- */}                
                <Route  exact path={SupplierConstantsList.SELF_REGISTRATION}  element={ <SELF_REGISTRATION/>  } />
                <Route  exact path={SupplierConstantsList.SUPPLIER_MASTER_CREATE}  element={ <SUPPLIER_MASTER_CREATE/> } />
                {/* Common */}
                <Route  exact path={SupplierConstantsList.APP_HOME}  element={ <PrivateRoute> <AppLandingPage/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.HOME}  element={ <PrivateRoute> <HOME/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.HOME_INTRO}  element={ <PrivateRoute> <HOME_INTRO/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.DASHBOARD}  element={ <PrivateRoute> <DASHBOARD/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.INBOX}  element={ <PrivateRoute> <INBOX/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.OUTBOX}  element={ <PrivateRoute> <OUTBOX/>  </PrivateRoute>  } />
                {/* SUPPILER */}
                <Route  exact path={SupplierConstantsList.SUPPILER_REGISTRATION}  element={ <PrivateRoute> <SUPPILER_REGISTRATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SUPPILER_LIST}  element={ <PrivateRoute> <SUPPILER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SUPPILER_MASS_UPLOAD}  element={ <PrivateRoute> <SUPPILER_MASS_UPLOAD/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SUPPLIER_DETAILED_PAGE_CREATE}  element={ <PrivateRoute> <SUPPLIER_DETAILED_PAGE_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SUPPLIER_DETAILED_PAGE_WORKFLOW}  element={ <PrivateRoute> <SUPPLIER_DETAILED_PAGE_WORKFLOW/>  </PrivateRoute>  } />
                {/* SOURCING */}
                <Route  exact path={SupplierConstantsList.SOURCING_PURCHASE_REQUISITION_LIST}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_PURCHASE_REQUISITION_CREATE}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE}  element={ <PrivateRoute> <SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE/>  </PrivateRoute>  } />
                {/* SOURCING */}
                <Route  exact path={SupplierConstantsList.SOURCING_VENDOR_REQUEST_FOR_QUOTATION}  element={ <PrivateRoute> <SOURCING_VENDOR_REQUEST_FOR_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_VENDOR_QUOTATION_LIST}  element={ <PrivateRoute> <SOURCING_VENDOR_QUOTATION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_VENDOR_RFQ_DETAILS}  element={ <PrivateRoute> <SOURCING_VENDOR_RFQ_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_CREATE_QUOTATION}  element={ <PrivateRoute> <SOURCING_CREATE_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_QUOTATION_DETAILS}  element={ <PrivateRoute> <SOURCING_QUOTATION_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_BUYER_REQUEST_FOR_QUOTATION}  element={ <PrivateRoute> <SOURCING_BUYER_REQUEST_FOR_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_BUYER_QUOTATION_LIST}  element={ <PrivateRoute> <SOURCING_BUYER_QUOTATION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_BUYER_RFQ_DETAILS}  element={ <PrivateRoute> <SOURCING_BUYER_RFQ_DETAILS/>  </PrivateRoute>  } />      
                <Route  exact path={SupplierConstantsList.SOURCING_CREATE_RFQ}  element={ <PrivateRoute> <SOURCING_CREATE_RFQ/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_CREATE_MANUAL_RFQ}  element={ <PrivateRoute> <SOURCING_CREATE_MANUAL_RFQ/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_TECH_EVALUATION_REQUEST_FOR_QUOTATION}  element={ <PrivateRoute> <SOURCING_TECH_EVALUATION_REQUEST_FOR_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_TECH_EVALUATION_DETAILS}  element={ <PrivateRoute> <SOURCING_TECH_EVALUATION_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_COMMERCIAL_EVALUATION}  element={ <PrivateRoute> <SOURCING_COMMERCIAL_EVALUATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_TECHNICAL_EVALUATION}  element={ <PrivateRoute> <SOURCING_TECHNICAL_EVALUATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SOURCING_PURCHASE_ORDER_CREATION}  element={ <PrivateRoute> <SOURCING_PURCHASE_ORDER_CREATION/>  </PrivateRoute>  } />
                {/* POCOLLABRATION */}
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST/>  </PrivateRoute>  } />      
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_PURCHASE_ORDER_LIST}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_LIST/>  </PrivateRoute>  } />  
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_PURCHASE_ORDER_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_CREATION/>  </PrivateRoute>  } />      
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_GOODS_RECEIVED_NOTE}  element={ <PrivateRoute> <POCOLLABRATION_GOODS_RECEIVED_NOTE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SERVICE_ENTRY_SHEET}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_ENTRY_SHEET/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_INVOICE}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_INVOICE_DETIAILS}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE_DETIAILS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_PAYMENT_LIST}  element={ <PrivateRoute> <POCOLLABRATION_PAYMENT_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE}  element={ <PrivateRoute> <POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_ASN}  element={ <PrivateRoute> <POCOLLABRATION_ASN/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_ASN_DETAIL_PAGE}  element={ <PrivateRoute> <POCOLLABRATION_ASN_DETAIL_PAGE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_ASN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_ASN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SA_ASN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_SA_ASN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_GRN_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_GRN_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_GRN_DETAILS}  element={ <PrivateRoute> <POCOLLABRATION_GRN_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SES_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_SES_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_INVOICE_CREATION}  element={ <PrivateRoute> <POCOLLABRATION_INVOICE_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SCHEDULING_AGREEMENT}  element={ <PrivateRoute> <POCOLLABRATION_SCHEDULING_AGREEMENT/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.POCOLLABRATION_SERVICE_ENTRY_DETAILS}  element={ <PrivateRoute> <POCOLLABRATION_SERVICE_ENTRY_DETAILS/>  </PrivateRoute>  } />
                {/* SAPREPROCESS */}
                <Route  exact path={SupplierConstantsList.SAPREPROCESS_GRN_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_GRN_REPROCESS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SAPREPROCESS_SES_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_SES_REPROCESS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SAPREPROCESS_INVOICE_REPROCESS}  element={ <PrivateRoute> <SAPREPROCESS_INVOICE_REPROCESS/>  </PrivateRoute>  } />
                {/* ADMIN_MANAGE */}
                <Route  exact path={SupplierConstantsList.ADMIN_MANAGE_ROLE}  element={ <PrivateRoute> <ADMIN_MANAGE_ROLE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADMIN_MANAGE_USER_LIST}  element={ <PrivateRoute> <ADMIN_MANAGE_USER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADMIN_MANAGE_ADD_USER}  element={ <PrivateRoute> <ADMIN_MANAGE_ADD_USER/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADMIN_MANAGE_EDIT_USER}  element={ <PrivateRoute> <ADMIN_MANAGE_EDIT_USER/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADMIN_MANAGE_USER_LIST_DETAILS}  element={ <PrivateRoute> <ADMIN_MANAGE_USER_LIST_DETAILS/>  </PrivateRoute>  } />
                {/* AUCTION_REVERSE */}
                <Route  exact path={SupplierConstantsList.AUCTION_REVERSE_AUCTION_LIST}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.AUCTION_REVERSE_AUCTION_CREATION}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.AUCTION_REVERSE_AUCTION_HISTORY}  element={ <PrivateRoute> <AUCTION_REVERSE_AUCTION_HISTORY/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.AUCTION_FORWARD_AUCTION_LIST}  element={ <PrivateRoute> <AUCTION_FORWARD_AUCTION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.AUCTION_FORWARD_AUCTION_HISTORY}  element={ <PrivateRoute> <AUCTION_FORWARD_AUCTION_HISTORY/>  </PrivateRoute>  } />
                {/* CONTRACT_MANAGEMENT_CONTRACT */}
                <Route  exact path={SupplierConstantsList.CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.CONTRACTMANAGEMENT_MONITORING_LIST}  element={ <PrivateRoute> <CONTRACTMANAGEMENT_MONITORING_LIST/>  </PrivateRoute>  } />
                {/* GATEPASS */}
                <Route  exact path={SupplierConstantsList.GATEPASS_GATEPASS_LIST}  element={ <PrivateRoute> <GATEPASS_GATEPASS_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.GATEPASS_GATEPASS_CREATE}  element={ <PrivateRoute> <GATEPASS_GATEPASS_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.GATEPASS_GATEPASS_DETAIL_PAGE}  element={ <PrivateRoute> <GATEPASS_GATEPASS_DETAIL_PAGE/>  </PrivateRoute>  } />
                {/* USER_MANUAL */}
                <Route  exact path={SupplierConstantsList.USER_MANUAL_BUYER_MANUAL}  element={ <PrivateRoute> <USER_MANUAL_BUYER_MANUAL/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.USER_MANUAL_SUPPLIER_MANUAL}  element={ <PrivateRoute> <USER_MANUAL_SUPPLIER_MANUAL/>  </PrivateRoute>  } />
                {/* DELEGATION */}
                <Route  exact path={SupplierConstantsList.DELEGATION_TASK}  element={ <PrivateRoute> <DELEGATION_TASK/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.DELEGATION_USER}  element={ <PrivateRoute> <DELEGATION_USER/>  </PrivateRoute>  } />
                {/* SINGLE_SOURCING */}
                <Route  exact path={SupplierConstantsList.SINGLESOURCING_SINGLE_SOURCING_CREATION}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SINGLESOURCING_SINGLE_SOURCING_LIST}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SINGLESOURCING_SINGLE_SOURCING_DETAILS}  element={ <PrivateRoute> <SINGLESOURCING_SINGLE_SOURCING_DETAILS/>  </PrivateRoute>  } />
                {/* ADVANCE_PAYMENT */}
                <Route  exact path={SupplierConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS}  element={ <PrivateRoute> <ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS/>  </PrivateRoute>  } />
                {/* GHG_SCOPE1 */}
                <Route  exact path={SupplierConstantsList.GHG_SCOPE1_CREATION}  element={ <PrivateRoute> <GHG_SCOPE1_CREATION/>  </PrivateRoute>  } />
                {/* Reports */}
                <Route  exact path={SupplierConstantsList.SUPPLIER_REPORTS}  element={ <PrivateRoute> <SUPPLIER_REPORTS/>  </PrivateRoute>  } />
                <Route  exact path={SupplierConstantsList.SUPPLIER_REPORT_DETAILS}  element={ <PrivateRoute> <SUPPLIER_REPORT_DETAILS/>  </PrivateRoute>  } />
                
                {/* ----------------------------------  Supplier END  ---------------------------------- */}


                {/* ----------------------------------  Customer START  ---------------------------------- */}

                {/* Common */}
                {/* <Route  exact path={CustomerConstantsList.APP_HOME}  element={ <PrivateRoute> <AppLandingPage/>  </PrivateRoute>  } /> */}
                {/* <Route  exact path={CustomerConstantsList.HOME}  element={ <PrivateRoute> <HOME/>  </PrivateRoute>  } /> */}
                <Route  exact path={CustomerConstantsList.HOME_INTRO}  element={ <PrivateRoute> <CUSTOMER_HOME_INTRO/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.DASHBOARD}  element={ <PrivateRoute> <CUSTOMER_DASHBOARD/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.INBOX}  element={ <PrivateRoute> <CUSTOMER_INBOX/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.OUTBOX}  element={ <PrivateRoute> <CUSTOMER_OUTBOX/>  </PrivateRoute>  } />
                 
                {/* CUSTOMER */}
                <Route  exact path={CustomerConstantsList.CUSTOMER_REGISTRATION}  element={ <PrivateRoute> <CUSTOMER_REGISTRATION/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_LIST}  element={ <PrivateRoute> <CUSTOMER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_MASTER_CREATE}  element={ <PrivateRoute> <CUSTOMER_MASTER_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_MASS_UPLOAD}  element={ <PrivateRoute> <CUSTOMER_MASS_UPLOAD/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_DETAILED_PAGE_CREATE}  element={ <PrivateRoute> <CUSTOMER_DETAILED_PAGE_CREATE/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_DETAILED_PAGE_WORKFLOW}  element={ <PrivateRoute> <CUSTOMER_DETAILED_PAGE_WORKFLOW/>  </PrivateRoute>  } />

                {/* PRESALES */}
                <Route  exact path={CustomerConstantsList.PRESALES_INQUIRY_CREATION}  element={ <PrivateRoute> <PRESALES_INQUIRY_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRESALES_INQUIRY_LIST}  element={ <PrivateRoute> <PRESALES_INQUIRY_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRESALES_INQUIRY_DETAILS}  element={ <PrivateRoute> <PRESALES_INQUIRY_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={CustomerConstantsList.PRESALES_CUSTOMER_CREATE_QUOTATION}  element={ <PrivateRoute> <PRESALES_CUSTOMER_CREATE_QUOTATION/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRESALES_CUSTOMER_QUOTATION_DETAILS}  element={ <PrivateRoute> <PRESALES_CUSTOMER_QUOTATION_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRESALES_CUSTOMER_QUOTATION_LIST}  element={ <PrivateRoute> <PRESALES_CUSTOMER_QUOTATION_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRESALES_CUSTOMER_QUOTATION_CREATE_PO}  element={ <PrivateRoute> <PRESALES_CUSTOMER_QUOTATION_CREATE_PO/>  </PrivateRoute>  } />
                
                {/* SALES*/}
                <Route  exact path={CustomerConstantsList.SALES_PURCHASE_ORDER_LIST}  element={ <PrivateRoute> < SALES_PURCHASE_ORDER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.SALES_SALES_ORDER_LIST}  element={ <PrivateRoute> < SALES_SALES_ORDER_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.SALES_PURCHASE_ORDER_DETAILS}  element={ <PrivateRoute> < SALES_PURCHASE_ORDER_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.SALES_SALES_ORDER_DETAILS}  element={ <PrivateRoute> < SALES_SALES_ORDER_DETAILS/>  </PrivateRoute>  } />

                <Route  exact path={CustomerConstantsList.SALES_DELIVERY_CREATION}  element={ <PrivateRoute> < SALES_DELIVERY_CREATION/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.SALES_DELIVERY_LIST}  element={ <PrivateRoute> < SALES_DELIVERY_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.SALES_DELIVERY_DETAILS}  element={ <PrivateRoute> < SALES_DELIVERY_DETAILS/>  </PrivateRoute>  } />

                {/* catalog */}
                <Route  exact path={CustomerConstantsList.PRODUCT_CATALOG_LIST}  element={ <PrivateRoute> <PRODUCT_CATALOG_LIST/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRODUCT_CATALOG_DETAILS}  element={ <PrivateRoute> <PRODUCT_CATALOG_DETAILS/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.PRODUCT_CATALOG_MASTER}  element={ <PrivateRoute> <PRODUCT_CATALOG_MASTER/>  </PrivateRoute>  } />


                {/* userManual */}
                <Route  exact path={CustomerConstantsList.USER_MANUAL_CUSTOMER_MANUAL}  element={ <PrivateRoute><USER_MANUAL_CUSTOMER_MANUAL/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.USER_MANUAL_MANAGER_MANUAL}  element={ <PrivateRoute> <USER_MANUAL_MANAGER_MANUAL/>  </PrivateRoute>  } />	

                {/* Reports */}
                <Route  exact path={CustomerConstantsList.CUSTOMER_REPORTS}  element={ <PrivateRoute> <CUSTOMER_REPORTS/>  </PrivateRoute>  } />
                <Route  exact path={CustomerConstantsList.CUSTOMER_REPORT_DETAILS}  element={ <PrivateRoute> <CUSTOMER_REPORT_DETAILS/>  </PrivateRoute>  } />
                 {/* ----------------------------------  Customer END  ---------------------------------- */}

            </Routes>  
            {/* <Footer/> */}
        </>
    );
}


export default AppRouters;