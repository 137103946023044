import React, {
  useEffect,
  useMemo,
  useState /* useEffect, useMemo, useState */,
} from "react";

//Redux
import { useSelector } from "react-redux"; //useDispatch

const PrintCostingSheet = React.forwardRef((props, ref) => {

  const appConfig = useSelector((state) => state?.app?.appConfig); // Application Details from Store
  
  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "4px",
  };
  const printbody = {
    display: "flex",
    flexDirection: "column",
    margin: "10px",
  };
  const { data } = props;
  const firstLocation = Array.isArray(data?.projectDetails?.getLocations)
    ? data.projectDetails.getLocations[0]
    : {};

  const projectLocation = firstLocation?.projectLocation || "";
  const plantAddress = firstLocation?.plantAddress || "";
  const distance = firstLocation?.distance || "";

  const items = data?.items || [];
  const termsConditions = useMemo(
    () => data?.termsConditions || [],
    [data?.termsConditions]
  );

  const [getTermsAndCondition, setTermsAndCondition] = useState([]);

  useEffect(() => {
    const filtered = termsConditions.filter((item) => item.status === true);
    setTermsAndCondition(filtered);
  }, [termsConditions]);

  return (
    <div ref={ref} style={printbody} className="m-4">
      <table style={tableStyle}>
        <tr style={tableStyle}>
          <td colSpan="4">
            <div className="d-flex  justify-content-center align-items-center">
              <img
                src="./assets/img/BharthiCementLogo/BharthiCementLogo.png"
                alt="Bharthi_Cement_logo"
              />
              <h4>{appConfig?.customerName}</h4>
              <img
                src="./assets/img/BharthiCementLogo/vicatLogo.png"
                alt="vicatlogo"
              />
            </div>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            SO NAME ::
          </td>

          <td style={tableStyle}> {data.SalesOfficerName}</td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="3" style={{ textAlign: "center" }}>
            <h4>Costing Sheet</h4>
          </td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Customer Name ::
          </td>
          <td style={tableStyle}>{data.organisationName}</td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Site Location ::
          </td>
          <td style={tableStyle}>{projectLocation}</td>
        </tr>
        <tr style={tableStyle}>
          <td colSpan="2" style={tableStyle}>
            Plant Location ::
          </td>
          <td style={tableStyle}> {plantAddress}</td>
        </tr>
        <tr style={tableStyle}>
          <td style={tableStyle}>Kilometer ::</td>
          <td style={tableStyle}></td>
          <td style={tableStyle}>{distance}</td>
        </tr>
        <tr style={tableStyle}>
          <td style={{ ...tableStyle, width: "20%" }}>Transport Charges::</td>
          <td style={tableStyle}></td>
          <td style={tableStyle}>{"3.43"}</td>
        </tr>
      </table>
      <table
        style={{ marginTop: "16px", width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr style={tableStyle}>
            <th style={tableStyle}>SL No</th>
            <th style={tableStyle}>Material</th>
            <th style={tableStyle}>Quote Price</th>
            <th style={tableStyle}>Freight</th>
            <th style={tableStyle}>Toll</th>
            <th style={tableStyle}>Route No</th>
            <th style={tableStyle}>Commission</th>
            <th style={tableStyle}>Ex-Plant Rate</th>
          </tr>
        </thead>
        <tbody>
          {items?.map((item) => (
            <tr key={item.local_id} style={tableStyle}>
              <td style={tableStyle} className="text-end">
                {item.local_id}
              </td>
              <td style={tableStyle}>{item.materialCode}</td>
              <td style={tableStyle} className="text-end">
                {item.quotePrice}
              </td>
              <td style={tableStyle} className="text-end">
                {item.freightRate}
              </td>
              <td style={tableStyle} className="text-end">
                {item.tollRate}
              </td>
              <td style={tableStyle} className="text-end">
                {item.routeNumber}
              </td>

              <td style={tableStyle} className="text-end">
                {item.commissionRate}
              </td>

              <td style={tableStyle} className="text-end">
                {item.explantRate}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <h4>Observations/Feedback</h4>
        <div dangerouslySetInnerHTML={{ __html: data?.observationsFeedback }} />
      </div>
      <div className="mt-4">
        <h2>Terms and Conditions</h2>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          {getTermsAndCondition.map((items, i) => (
            <li key={i}>{items.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default PrintCostingSheet;
