import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import DataTable from "react-data-table-component";
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import {
 /*  addQuotationID, */
  getPostDispatchDocList,
} from "../../../../redux/feature/customer_app/customerV1Slice";
import { SearchField } from "../../../../Components/formElements/SearchField";
// import { formatDateTime } from "../../../../common/Validations";
// import { TableStatusField } from "../../../../Components/formElements/TableStatusField";
// import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import { useNavigate } from "react-router-dom";
import CustomerConstants from "../../../../common/CustomerConstants";
// import { workflow } from "../../../../redux/feature/customer_app/customerSlice";

const CreditPolicyRequestList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Customer Sales");
  localStorage.setItem("submenu", "");

  let tollListData = useSelector(
    (state) => state?.customerV1?.postDispatchDoc
  ); // User Details from Store
  let tollListDataTable = useSelector(
    (state) => state?.customerV1?.postDispatchDoc.pagination
  ); // User Details from Store

  //workflow
//   const [modalShowStatus, setModalShowStatus] = useState(false);
//   const [sendDataModal, setSendDataModal] = useState("");
//   const [modelTitle, setModelTitle] = useState("");

  const [data, setData] = useState();
  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  /* Modal Function */
//   const showStatusInfo = (rowData) => {
//     setModalShowStatus(true);
//     setSendDataModal(rowData);
//     setModelTitle("Status Information");
//   };
//   const hideModalStatus = () => {
//     setModalShowStatus(false);
//   };

//   const detailedPage = (quotationID, workflowActionLogs) => {
//     dispatch(addQuotationID(quotationID));
//     dispatch(workflow(workflowActionLogs));
//     navigate(CustomerConstants.CUSTOMER_V1_QUOTATION_DETAILS);
//   };
  const userListColInfo = [
    {
      name: "slNo",
      selector: (row) => row.slNo,
      cell: (row) => (
        <>
          {/* <button
            className="btnInfo"
            // onClick={() =>
            //   detailedPage(row.slNo)
            // }
          >
            {row.slNo || "-"}{" "}
          </button> */}
          <p className="m-0 p-0 pt-1">{row.slNo} </p>
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Route Name",
      selector: (row) => row.routeName,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.routeName} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Toll Rates",
      selector: (row) => row.tollRates,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.tollRates} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "No Entry Time From AM",
      selector: (row) => row.noEntryTimeFromAm,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.noEntryTimeFromAm} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "No Entry Time To AM",
      selector: (row) => row.noEntryTimeToAm,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.noEntryTimeToAm} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "No Entry Time From PM",
      selector: (row) => row.noEntryTimeFromPm,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.noEntryTimeFromPm} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "No Entry Time From PM",
      selector: (row) => row.noEntryTimeFromPm,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.noEntryTimeFromPm} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "No Entry Time To PM",
      selector: (row) => row.noEntryTimeToPm,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.noEntryTimeToPm} </p>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const loadInitialSuuplierData = async () => {
    setData(tollListData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      ueryName: "GetTollMasterList",
      module: "toll",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getPostDispatchDocList(obj));
    setData(tollListData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "GetTollMasterList",
      module: "toll",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getPostDispatchDocList(obj));
    setData(tollListData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "GetTollMasterList",
      module: "toll",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getPostDispatchDocList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tollListData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "GetTollMasterList",
      module: "toll",
      page: 1,
      per_page: 10,
    };
    dispatch(getPostDispatchDocList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateCreateToll = () => {
    navigate(CustomerConstants.CUSTOMER_V1_TOLL_MASTER_CREATION);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Toll List</h2>
                <p className="subText">Toll List</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />
            <Col
              xs={4}
              md={{ offset: 10, span: 2 }}
              lg={{ offset: 10, span: 2 }}
              className="mb-2"
            >
              <button className="btn w-full" onClick={navigateCreateToll}>
                Create Toll
              </button>
            </Col>
            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TL</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle"> Toll list</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => introVideoShow()}
                      >
                        Order Booking
                      </button>
                    </Col> */}
                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={tollListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={tollListDataTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      /> */}
    </main>
  );
};

export default CreditPolicyRequestList;
