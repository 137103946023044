import React from 'react';
import Leftside from './Leftside'; // Adjust the import path as needed
import Header from './Header';     // Adjust the import path as needed
import { Outlet } from 'react-router-dom'; 

const Layout = () => {    
    return (
        <div>
            <div id="wrapper">
                <Leftside />
                <Header />
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;