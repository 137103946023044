import React, { useState, useEffect, useCallback } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import ItemService from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import PageLevelRiverseTimer from "../../../../Components/PageLevelRiverseTimer";
// import Constants from "../../../common/Constants";
// import CreateOtherChanges from "../../../Components/Modal/supplier_app/CreateQuotation/CreateOtherChanges";
// import PriceShedule from "../../../Components/Modal/supplier_app/CreateQuotation/PriceShedule";
// import DynamicForm from "../../../Components/Modal/supplier_app/CreateQuotation/DynamicForm";
import SourcingCommonFilter from "../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import toast from "react-hot-toast";
import HeaderText from "../../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import { formatDateTime, formatPriceValue, getCurrentDate } from "../../../../common/Validations";
import { getQuotation } from "../../../../redux/feature/supplier_app/sourcingSlice";
import moment from "moment";

const CreateQuotation = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  let rfxNr = useSelector(
    (state) => state?.sourcing?.quotation?.rfxNr
  ); /* rfxNr from store*/

  let userData = useSelector((state) => state?.user); // User Details from Store

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);

  const [subTotalValue, setSubTotalValue] = useState(0);
  const [itemTableData, setItemTableData] = useState([]);
  const [timerStatus, setTimerStatus] = useState(false);
  const [finalTimerValue, setFinalTimerValue] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [getDropdownData, setDropdownData] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  // const [loading, setLoading] = useState(false);
  const modalData = {
    title: "Quotation Creation",
    successMsg: "",
  };

  const [disabled, setDisabled] = useState(false);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);

  const [formInputs, setFormInputs] = useState({
    rfxNr: "",
    quotationSubmittedBy: "",
    qtnSubmittedID: "",
    vendorCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxDescription: "",
    rfxVersion: "",
    rfxTitle: "",
    openingrfxType: "",
    productType: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    qtnPaymentTerms: "",
    qtnBidderCurrency: "",
    technicalQuoteArticle: "",
    qtnPricingNotes: "",
    unpricedCommercia: "",
    lQuoteArticle: "",
    quotationNotes: "",
    commercialQuoteArticle: "",
    quotationStatus: "",
    buyerResponsible: "",
    companyAddress: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCateDate: "",
    openingTime: "",
    closingDate: "",
    closingTime: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    openingDateTime: "",
    closingDateTime: "",
    rfxTypeDesc: "",
    productTypeDesc: "",
    rfxbidTypeDesc: "",
    rfxNoOfEvaluationTypeDesc: "",
    rfxCurrencyCode: "",
    rfxCurrencyDesc: "",
    technicalApprovalTypeDesc: "",
    companyAddressDesc: "",
    plantDesc: "",
    billingAddressDesc: "",
    unpricedCommercialQuoteArticle: "",
    pricingNotes: "",
    qtnIncoTerms: "",
    qtnVersion: "V1",
    quotationReference: "",
    acceptanceTermsConditions: "",

    items: [
      {
        qtnItem: 1,
        nstatusItem: "",
        qtnQuantity: 10,
        qtnQuantityUnit: "",
        purchaseDocumentNr: "",
        purchaseDocumentItem: "",
        quotationNr: "",
      },
    ],
    formCOMM: [],
    formTECH: [],
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const [formComm, setFormComm] = useState([]);
  const [formTech, setFormTech] = useState([]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  // handleInputChange
  const handleInputChange = useCallback((obj, value, type) => {
    if (type === "formCOMM" || obj.textDesc === "formCOMM") {
      let data = {
        question_no: obj.textField || obj.componentId,
        question_name: obj.textCode || obj.question.label,
        question_value: obj.value || value,
      };

      setFormComm((prevFormComm) => {
        const updatedFormComm = prevFormComm.filter(
          (item) => item.question_no !== data.question_no
        );
        return [...updatedFormComm, data];
      });
    } else if (type === "formTECH" || obj.textDesc === "formTECH") {
      let data = {
        question_no: obj.textField || obj.componentId,
        question_name: obj.textCode || obj.question.label,
        question_value: obj.value || value,
      };

      setFormTech((prevFormTech) => {
        const updatedFormTech = prevFormTech.filter(
          (item) => item.question_no !== data.question_no
        );
        return [...updatedFormTech, data];
      });
    }
  }, []);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getINCOTerms, setINCOTerms] = useState([]);
  const [getcurrency, setcurrency] = useState([]);
  const [getpaymentTerm, setpaymentTerm] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState("Yes");

  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  // Function to handle text changes
  const handleInputChangeTable = (id, value, qtnQuantity) => {
      let totalPriceVal = Number(qtnQuantity) * Number(value);

      const updatedData = itemTableData.map((item) => {
        return item.local_id === id
          ? {
              ...item,
              quotationUnitPrice: value,
              qtnitemValue: totalPriceVal,
            }
          : item;
      });

      setItemTableData(updatedData);
      getTotalCount(updatedData);
  };

  // Function to handle text changes
  const handleDeliveryLeadTime = (id, value) => {
      const updatedData = itemTableData.map((item) => {
        return item.local_id === id
          ? {
              ...item,
              deliveryLeadTime:value
            }
          : item;
      });
      setItemTableData(updatedData);
  };

  const handleQtnQuantityTable = (id, value, qtnQuantity) => {
    if (Number(qtnQuantity) >= Number(value)) {
      const updatedData = itemTableData.map((item) => {
        return item.local_id === id
          ? {
              ...item,
              qtnQuantity: value,
            }
          : item;
      });

      setItemTableData(updatedData);
    } else {
      // console.log("Do Nothing");
    }
  };

  // Get Total for SubTotal Pricing Schedule
  const getTotalCount = (data) => {
    const getTotal = data.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.qtnitemValue || 0),
      0
    );
    setSubTotalValue(getTotal);
  };

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Document Item Nr",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    // {
    //   name: "Material/Service ",
    //   selector: (row) => row.materialCode || "--",
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Description",
    //   selector: (row) => row.materialDescription || "--",
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Quantity ",
      selector: (row) => row.purchaseQuantity || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Quantity ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="qtnQuantity"
            name="qtnQuantity"
            value={row.qtnQuantity || 0}
            onChange={(e) =>
              handleQtnQuantityTable(
                row.local_id,
                e.target.value,
                row.purchaseQuantity
              )
            }
          />
        </>
      ),
      width: "160px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "100px",
    },
    {
      name: "Unit Price ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="quotationUnitPrice"
            name="quotationUnitPrice"
            value={row.quotationUnitPrice || 0}
            onChange={(e) =>
              handleInputChangeTable(
                row.local_id,
                e.target.value,
                row.qtnQuantity
              )
            }
          />
        </>
      ),
      width: "180px",
    },
    {
      name: "Item Value ", // calculation
      selector: (row) => formatPriceValue(row.qtnitemValue),
      width: "200px",
    },
    {
      name: "Delivery Lead Time (Days)", // input
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="deliveryLeadTime2"
            name="deliveryLeadTime"
            value={row.deliveryLeadTime || 0}
            onChange={(e) =>
              handleDeliveryLeadTime(
                row.local_id,
                e.target.value
              )
            }
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Service Start Date", // input
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceStartDate3"
            name="serviceStartDate"
            value={row.serviceStartDate || getCurrentDate("")}
          />
        </>
      ),
      width: "200px",
    },

    {
      name: "Service End Date", // input
      selector: (row) => row.serviceEndDate || "--",
      cell: (row) => (
        <>
          <InputField
            type="date"
            className="inputBox mt-1"
            id="serviceEndDate4"
            name="serviceEndDate"
            value={row.serviceEndDate || getCurrentDate("")}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation", // input yes/NO
      selector: (row) => row.deviation || "--",
      cell: (row) => (
        <>
          <ComboboxField
            placeholder=""
            data={dropdownOption}
            id="deviation"
            name="deviation"
            value={row.deviation || 0}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation Remarks", // input
      selector: (row) => row.deviationRemark || "--",
      cell: (row) => (
        <>
          <TextAreaField
            className="inputBox mt-1"
            id="deviation"
            name="deviation"
            value={row.deviation}
          />
        </>
      ),
      width: "200px",
    },
  ];

  // Other Charges
  const [getOtherChargesValue, setOtherChargesValue] = useState(0);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);

  /* Other Charges selected data */
  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);
  const handleHeaderTextSelectedData = (data) => {
    setHeaderTextSelectedData(data);
  };

  useEffect(() => {
    const getTotal = getHeaderTextSelectedData.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue?.documentText || 0),
      0
    );
    setOtherChargesValue(getTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeaderTextSelectedData]);

  /* Create Other Charges Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
    // {
    //   name: "Delete",
    //   cell: (row, index) => (
    //     <>
    //       <span>
    //         <i
    //           onClick={() => handleDelete(index)}
    //           className="bi bi-trash text-danger fs-3"
    //         ></i>
    //       </span>
    //     </>
    //   ),
    //   width: "10rem",
    // },
  ];

  const loadInitialPageData = async () => {
    // setLoading(true);
    if (rfxNr) {
      let obj = {
        documentNumber: rfxNr,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getRfxDocumentDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          const updatedItemData = item?.data?.items.map((obj, index) => ({
            ...obj,
            qtnItem: index + 1,
            local_id: index + 1,
            qtnQuantity: obj.purchaseQuantity,
          }));
          // let res = checkTimeDifference(, closingDateTime);

          setItemTableData(updatedItemData);
          setFormInputs(item.data);
          updateTimerValue(item?.data?.closingDateTime);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      // setLoading(false);
    } else {
      window.history.back();
    }
  };

  const updateTimerValue = (closingDateTime) => {
    let timerData = {};
    const openingMoment = moment(); // This is the current date-time
    const closingMoment = moment(closingDateTime, "YYYY-MM-DD HH:mm");
    const diffInMilliseconds = closingMoment.diff(openingMoment);
    const duration = moment.duration(diffInMilliseconds);

    // If the time is already past, return 0 and set timeExpired flag
    if (diffInMilliseconds <= 0) {
      setFinalTimerValue({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      setTimerStatus(true);
    } else {
      setTimerStatus(false);
      // Extract days, hours, minutes, and seconds correctly
      timerData.days = Math.floor(duration.asDays()) || 0;
      timerData.hours = duration.hours() || 0; // Remaining hours after days
      timerData.minutes = duration.minutes() || 0; // Remaining minutes after hours
      timerData.seconds = duration.seconds() || 0; // Remaining seconds after minutes
      setFinalTimerValue(timerData);
    }
  };

  useEffect(() => {
    loadInitialPageData();
    getMasterDataFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxNr]);

  //Load Master Data
  const getMasterDataFunc = async () => {
    // 1

    let obj4 = {
      codeType: "INCOTerms",
    };
    await ItemService.getMasterData(obj4).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setINCOTerms(item?.data);
      } else {
        setINCOTerms([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj5 = {
      codeType: "currency",
    };
    await ItemService.getMasterData(obj5).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setcurrency(item?.data);
      } else {
        setcurrency([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
    let obj6 = {
      codeType: "paymentTerm",
    };
    await ItemService.getMasterData(obj6).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setpaymentTerm(item?.data);
      } else {
        setpaymentTerm([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  // SAVE
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }
    // console.log(formInputs);
    formInputs.vendorCode = userData?.data?.userId;
    formInputs.qtnVersion = "V0";
    formInputs.quotationStatus = "Submitted";
    formInputs.quotationSubmittedBy = userData?.data?.firstName;
    formInputs.qtnSubmittedID = userData?.data?.userId;
    formInputs.othersCharge = getHeaderTextSelectedData;
    formInputs.qtnSubTotalLineItem = subTotalValue;
    formInputs.qtnSubTotalOtherCharges = getOtherChargesValue;
    formInputs.qtnTotal = subTotalValue + getOtherChargesValue;
    formInputs.acceptanceTermsConditions = selectedShipment;

    const userEnteredItemTableData = itemTableData.filter(
      (item) => item.purchaseQuantity != null || item.purchaseQuantity > 0
    );
    formInputs.items = userEnteredItemTableData;
    formInputs.formCOMM = formComm;
    formInputs.formTECH = formTech;

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addQuotation(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };
  // Get Dropdown data select
  useEffect(() => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      [getDropdownData?.textField]: getDropdownData?.name,
      [getDropdownData?.textCode]: getDropdownData?.code,
      [getDropdownData?.textDesc]: getDropdownData?.description,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  const handleShipmentChange = (event) => {
    setSelectedShipment(event.target.value);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Quotation</h2>
            <p className="subText">Fil Quotatoin Details</p>
            <hr />

            {/*Remaining Time for submission  */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={12} md={5} lg={5} className="">
                      <p className="headerTitle fs-4">
                        Remaining Time for submission
                      </p>
                    </Col>
                    <Col xs={12} md={7} lg={7} className="flex justify-end">
                      <img
                        src="assets/img/stopwatch.png"
                        alt="stopwatch"
                        style={{ width: "60px", height: "58px" }}
                        className="mr-6 mt-1"
                      />
                      <PageLevelRiverseTimer timerData={finalTimerValue} />
                    </Col>
                  </Row>
                </h2>
              </div>
            </div>
            
            {/*quotation Header */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Address"
                          name="companyAddressDesc"
                          id="companyAddressDesc7"
                          placeholder=""
                          value={formInputs?.companyAddressDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ ID"
                          name="rfxNr"
                          id="rfxNr8"
                          placeholder=""
                          value={formInputs?.rfxNr}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationNumber9"
                          className="inputBox"
                          label="Quotation Number"
                          name="firstName"
                          placeholder=""
                          value={""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivery Address"
                          name="billingAddressDesc"
                          id="billingAddressDesc"
                          value={formInputs?.billingAddressDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="rfqVersion"
                          className="inputBox"
                          label="RFQ Version"
                          name="rfxVersion"
                          placeholder=""
                          value={formInputs?.rfxVersion}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationCreatedOn"
                          className="inputBox"
                          label="Quotation Created Date"
                          name="quotationCreatedOn"
                          placeholder=""
                          value={getCurrentDate("default")}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Billing Address"
                          name="billingAddressDesc"
                          id="billingAddressDesc"
                          value={formInputs?.billingAddressDesc}
                          // onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Type"
                          name="rfxbidTypeDesc"
                          id="rfxbidTypeDesc"
                          value={formInputs?.rfxbidTypeDesc}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Quotation Version"
                          name="QtnVersion"
                          id="QtnVersion"
                          placeholder=""
                          value={" v1"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Responsible"
                          name="buyerResponsible"
                          id="buyerResponsible"
                          value={formInputs?.buyerResponsible}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Product Type"
                          name="productType"
                          id="productType17"
                          value={formInputs?.productType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmittedBy"
                          className="inputBox"
                          label="Quotation Submitted By"
                          name="quotationSubmittedBy"
                          placeholder=""
                          value={" "}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Buyer Contact"
                          name="buyerConact"
                          id="buyerConact"
                          value={formInputs?.buyerConact}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Number Of Evaluation"
                          name="rfxNoOfEvaluationType"
                          id="rfxNoOfEvaluationType"
                          value={formInputs?.rfxNoOfEvaluationType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmissionDate"
                          className="inputBox"
                          label="Quotation Submitted On"
                          name="quotationSubmissionDate"
                          placeholder=""
                          value={formInputs?.quotationSubmissionDate}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Bid Type"
                          name="rfxbidType"
                          id="rfxbidType"
                          value={formInputs?.rfxbidType}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="RFQ Currency"
                          name="rfxCurrency"
                          id="rfxCurrency"
                          value={formInputs?.rfxCurrency}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationStatus"
                          className="inputBox"
                          label="Quotation Status"
                          name="quotationStatus"
                          placeholder=""
                          value={"New"}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Quotation overview */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QO</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Overview</span>
                      <span className="subHeaderTitle">Quotation Overview</span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Title:"
                          name="rfxTitle"
                          id="rfxTitle"
                          value={formInputs?.rfxTitle}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Opening Date:"
                          name="openingDateTime"
                          id="openingDateTime"
                          value={formatDateTime(formInputs?.openingDateTime)}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Instructions Attchements:"
                          name="rfxCurrency"
                          id="rfxCurrency27"
                          value={formInputs?.rfxCurrency}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Description:"
                          name="rfxDescription"
                          id="rfxDescription"
                          value={formInputs?.rfxDescription}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Closing Date:"
                          name="closingDateTime"
                          id="closingDateTime"
                          value={formatDateTime(formInputs?.closingDateTime)}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Quotation Header Info */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header Info</span>
                      <span className="subHeaderTitle">
                        Quotation Header Information
                      </span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="icv29"
                          className="inputBox"
                          label="ICV Value"
                          name="ICV Value"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationReference"
                          className="inputBox"
                          label="Quotation Reference"
                          name="quotationReference"
                          placeholder=""
                          value={formInputs.quotationReference}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmissionDate"
                          className="inputBox"
                          type="date"
                          label="Quotation Date"
                          name="quotationSubmissionDate"
                          placeholder=""
                          value={formInputs.quotationSubmissionDate}
                          onChange={onInputChange}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="ICVValidity32"
                          className="inputBox"
                          label="ICV Validity"
                          name="description"
                          placeholder=""
                          value={formInputs.firstName || ""}
                          onChange={onInputChange}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment term"
                          placeholder=""
                          data={getpaymentTerm}
                          id="qtnPaymentTerms"
                          iconClassName="dropdownIcon"
                          name="qtnPaymentTerms"
                          code="qtnPaymentTermsCode"
                          description="qtnPaymentTermsDesc"
                          setValue={formInputs.qtnPaymentTerms}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Inco Terms"
                          placeholder=""
                          data={getINCOTerms}
                          id="qtnIncoTerms"
                          iconClassName="dropdownIcon"
                          name="qtnIncoTerms"
                          code="qtnIncoTermsCode"
                          description="qtnIncoTermsDesc"
                          setValue={formInputs.qtnIncoTerms}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Bid Currency"
                          placeholder=""
                          data={getcurrency}
                          id="qtnBidderCurrency"
                          iconClassName="dropdownIcon"
                          name="qtnBidderCurrency"
                          code="qtnBidderCurrencyCode"
                          description="qtnBidderCurrencyDesc"
                          setValue={formInputs.qtnBidderCurrency}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="ICV CERTIFICATE"
                          placeholder=""
                          data={dropdownOption}
                          id="paymentterm"
                          iconClassName="dropdownIcon"
                          name="paymentterm"
                          setValue={formInputs.paymentterm || ""}
                          getvalue={setDropdownData}
                          // className="dropdownOption"
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">
                          Milestone / Scheduled payments
                        </p>
                        <span>No</span>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="technicalQuoteArticle"
                          className="inputBox"
                          label="TECHNICAL PROPOSAL"
                          type="file"
                          name="technicalQuoteArticle"
                          placeholder=""
                          value={formInputs.technicalQuoteArticle || ""}
                          onChange={onInputChange}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Pricing Notes"
                          type="text"
                          name="qtnPricingNotes"
                          id="qtnPricingNotes"
                          placeholder=""
                          value={formInputs.qtnPricingNotes || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="unpricedCommercialQuoteArticle"
                          className="inputBox"
                          label="UNPRICED COMMERCIAL PROPOSAL"
                          type="file"
                          name="unpricedCommercialQuoteArticle"
                          placeholder=""
                          value={
                            formInputs.unpricedCommercialQuoteArticle || ""
                          }
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <p className="fw-medium text-dark">
                          Acceptance to Terms & Conditions
                        </p>
                        <div>
                          <input
                            type="radio"
                            name="gender"
                            value="Yes"
                            className="form-check-input me-2"
                            checked={selectedShipment === "Yes"}
                            onChange={handleShipmentChange}
                          />
                          <span className="me-2">Yes</span>
                          <input
                            type="radio"
                            name="gender"
                            value="No"
                            className="form-check-input me-2"
                            checked={selectedShipment === "No"}
                            onChange={handleShipmentChange}
                          />
                          <span className="me-2">No</span>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Quotation Notes"
                          type="text"
                          name="quotationNotes"
                          id="quotationNotes"
                          placeholder=""
                          value={formInputs.quotationNotes || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="commercialQuoteArticle"
                          className="inputBox"
                          label="COMMERIAL PROPOSAL"
                          type="file"
                          name="commercialQuoteArticle"
                          placeholder=""
                          value={formInputs.commercialQuoteArticle}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Price Shedule / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>

                    <Col xs={12} md={6} lg={6} className="headerButtonAlign">
                      {/* <Col xs={4} md={2} lg={2} className="">
                        <button className="btnTable">Download Template</button>
                      </Col>
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={() => {
                            alert("asd");
                          }}
                        >
                          Upload Date
                        </button>
                      </Col> */}
                      {/* <Col xs={1} md={1} lg={1} className="">
                        <SourcingCommonFilter />
                      </Col> */}
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PRLineItemInitialCol}
                          data={itemTableData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* other Charges table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={6} lg={6}>
                      <span className="headerTitle">Other Charges</span>
                      <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span>
                    </Col>

                    <Col xs={12} md={4} lg={4} className="headerButtonAlign">
                      <Col xs={4} md={2} lg={2} className="">
                        <button
                          className="btnTable"
                          onClick={showsetHeaderText}
                        >
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </Col>
                      {/* <Col xs={1} md={1} lg={1} className="">
                        <SourcingCommonFilter />
                      </Col> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={HeaderTextColumns}
                          data={getHeaderTextSelectedData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Pricing Summary */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PS</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">Pricing Summary</span>
                      <span className="subHeaderTitle">Pricing Summary </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                  <Row className="my-4 ms-3 ">
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium">
                        SubTotal Pricing Schedule / Line Items (A){" "}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        id="subTotalValue36"
                        className="inputBox"
                        name="subTotalValue"
                        placeholder=""
                        value={subTotalValue}
                        disabled={getDisabledStatus}
                      />
                      <p className="ms-4 fw-medium">
                        {" "}
                        {formInputs?.rfxCurrency}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <p className="fw-medium"> SubTotal Other Charges (B)</p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <InputField
                        id="getOtherChargesValue37"
                        className="inputBox"
                        name="getOtherChargesValue"
                        placeholder=""
                        value={getOtherChargesValue}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium">
                        Quotation Gross Total (C = A + B)
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        id="Closing38"
                        className="inputBox"
                        /* label="Closing Date:" */
                        name="firstName"
                        placeholder=""
                        value={subTotalValue + getOtherChargesValue}
                        onChange={onInputChange}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* COMM - Spares & Operation Maintenance 08*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form: COMM- Spares & Operation Maintenance
                      </span>
                      {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {formInputs?.formCOMM?.length > 0 &&
                        formInputs?.formCOMM?.map((formJsonData, index) => {
                          const {
                            componentId,
                            mandatory,
                            question,
                            answerDefinition,
                            serialNo,
                          } = formJsonData;
                          const formType = "formCOMM";
                          switch (answerDefinition?.type) {
                            case "textarea":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commLeftRightPadding"
                                >
                                  <TextAreaField
                                    label={serialNo + ". " + question?.label}
                                    id={componentId}
                                    name={componentId}
                                    className="inputBox"
                                    placeholder=""
                                    // value={getRemarks}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                    required={mandatory}
                                  />
                                </Col>
                              );
                            case "select":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label={serialNo + ". " + question?.label}
                                    placeholder=""
                                    data={answerDefinition?.options}
                                    id={componentId}
                                    iconClassName="dropdownIcon"
                                    name={componentId}
                                    code={question?.label}
                                    description={formType}
                                    required={mandatory}
                                    getvalue={handleInputChange}
                                    // setValue={formData.type || ""}
                                    // getvalue={setDropdownData}
                                  />
                                </Col>
                              );
                            case "radio":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                                    {serialNo + ". " + question?.label}{" "}
                                    {mandatory && "*"}
                                  </label>

                                  {answerDefinition?.options.map(
                                    (option, index) => {
                                      return (
                                        <div key={index} className="block">
                                          <input
                                            type="radio"
                                            className="form-check-input fs-5 ml-3"
                                            id={`${componentId}-${index}`}
                                            name={componentId}
                                            value={option?.value}
                                            required={mandatory}
                                            onChange={(e) =>
                                              handleInputChange(
                                                formJsonData,
                                                e.target.value,
                                                formType
                                              )
                                            }
                                          />
                                          <span className="ml-3">
                                            {option?.value}{" "}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </Col>
                              );
                            case "checkbox":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                                    {serialNo + ". " + question?.label}{" "}
                                    {mandatory && "*"}
                                  </label>

                                  {answerDefinition?.options.map(
                                    (option, index) => {
                                      return (
                                        <div key={index} className="block">
                                          <input
                                            type="checkbox"
                                            className="form-check-input fs-5 ml-3"
                                            id={`${componentId}-${index}`}
                                            name={componentId}
                                            value={option?.value}
                                            required={mandatory}
                                            onChange={(e) =>
                                              handleInputChange(
                                                formJsonData,
                                                e.target.value,
                                                formType
                                              )
                                            }
                                          />
                                          <span className="ml-3">
                                            {option?.value}{" "}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </Col>
                              );
                            case "text":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding block"
                                >
                                  <InputField
                                    label={serialNo + ". " + question?.label}
                                    type="text"
                                    className="inputBox"
                                    id={componentId}
                                    name={componentId}
                                    placeholder=""
                                    // value={option.value}
                                    required={mandatory}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                  />
                                </Col>
                              );
                            case "number":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding block"
                                >
                                  <InputField
                                    label={serialNo + ". " + question?.label}
                                    type="number"
                                    className="inputBox"
                                    id={componentId}
                                    name={componentId}
                                    placeholder=""
                                    required={mandatory}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                  />
                                </Col>
                              );
                            default:
                              return "";
                          }
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Form :TECH - Spares & Operation Maintenance09*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible9
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility9}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">TS</span>
                    </Col>

                    <Col xs={4} md={8} lg={7}>
                      <span className="headerTitle">
                        Form : TECH - Spares & Operation Maintenance
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible9 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="commTopButtonRightLeftPadding">
                      {formInputs?.formTECH?.length > 0 &&
                        formInputs?.formTECH?.map((formJsonData, index) => {
                          const {
                            componentId,
                            mandatory,
                            question,
                            answerDefinition,
                            serialNo,
                          } = formJsonData;
                          const formType = "formTECH";
                          switch (answerDefinition?.type) {
                            case "textarea":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commLeftRightPadding"
                                >
                                  <TextAreaField
                                    label={serialNo + ". " + question?.label}
                                    id={componentId}
                                    name={componentId}
                                    className="inputBox"
                                    placeholder=""
                                    // value={getRemarks}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                    required={mandatory}
                                  />
                                </Col>
                              );
                            case "select":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <ComboboxField
                                    label={serialNo + ". " + question?.label}
                                    placeholder=""
                                    data={answerDefinition?.options}
                                    id={componentId}
                                    iconClassName="dropdownIcon"
                                    name={componentId}
                                    code={question?.label}
                                    description={formType}
                                    required={mandatory}
                                    getvalue={handleInputChange}
                                    // setValue={formData.type || ""}
                                    // getvalue={setDropdownData}
                                  />
                                </Col>
                              );
                            case "radio":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                                    {serialNo + ". " + question?.label}{" "}
                                    {mandatory && "*"}
                                  </label>

                                  {answerDefinition?.options.map(
                                    (option, index) => {
                                      return (
                                        <div key={index} className="block">
                                          <input
                                            type="radio"
                                            className="form-check-input fs-5 ml-3"
                                            id={`${componentId}-${index}`}
                                            name={componentId}
                                            value={option?.value}
                                            required={mandatory}
                                            onChange={(e) =>
                                              handleInputChange(
                                                formJsonData,
                                                e.target.value,
                                                formType
                                              )
                                            }
                                          />
                                          <span className="ml-3">
                                            {option?.value}{" "}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </Col>
                              );
                            case "checkbox":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion w-100">
                                    {serialNo + ". " + question?.label}{" "}
                                    {mandatory && "*"}
                                  </label>

                                  {answerDefinition?.options.map(
                                    (option, index) => {
                                      return (
                                        <div key={index} className="block">
                                          <input
                                            type="checkbox"
                                            className="form-check-input fs-5 ml-3"
                                            id={`${componentId}-${index}`}
                                            name={componentId}
                                            value={option?.value}
                                            required={mandatory}
                                            onChange={(e) =>
                                              handleInputChange(
                                                formJsonData,
                                                e.target.value,
                                                formType
                                              )
                                            }
                                          />
                                          <span className="ml-3">
                                            {option?.value}{" "}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </Col>
                              );
                            case "text":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding block"
                                >
                                  <InputField
                                    label={serialNo + ". " + question?.label}
                                    type="text"
                                    className="inputBox"
                                    id={componentId}
                                    name={componentId}
                                    placeholder=""
                                    // value={option.value}
                                    required={mandatory}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                  />
                                </Col>
                              );
                            case "number":
                              return (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  className="commTopButtonRightLeftPadding block"
                                >
                                  <InputField
                                    label={serialNo + ". " + question?.label}
                                    type="number"
                                    className="inputBox"
                                    id={componentId}
                                    name={componentId}
                                    placeholder=""
                                    required={mandatory}
                                    onChange={(e) =>
                                      handleInputChange(
                                        formJsonData,
                                        e.target.value,
                                        formType
                                      )
                                    }
                                  />
                                </Col>
                              );
                            default:
                              return "";
                          }
                        })}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div className="pageFooter">
        <Row className="rowDirection">
          {!timerStatus && (
            <>
              <Col
                xs={12}
                md={2}
                lg={2}
                className="commTopButtonRightLeftPadding"
              >
                <button
                  className="btn btnTable w-full"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
              <Col
                xs={12}
                md={2}
                lg={2}
                className="commTopButtonRightLeftPadding"
              >
                <button
                  className="btn btnTable w-full"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Modify
                </button>
              </Col>
              {/* <Col xs={12} md={2} lg={2} className="commTopButtonRightLeftPadding">
            <button
              className="btn btnTable w-full"
              //  onClick={handleModify}
            >
              <i className="bi bi-pencil-square"></i> Modify
            </button>
          </Col> */}
            </>
          )}
        </Row>
      </div>

      {/* <CreateOtherChanges
        show={otherchangesmodelshow}
        onHide={hideOtherChangesModel}
        onData={handleDataFromCreateOtherChanges}
      /> */}
      <HeaderText
        index={0}
        headername="Add Other Charges"
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hedertextdata={handleHeaderTextSelectedData}
        showcurrentdata={getHeaderTextSelectedData}
        masterdata="POHeaderTextID"
        type="Number"
      />

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_VENDOR_QUOTATION_LIST}
      />
    </main>
  );
};

export default CreateQuotation;
