/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/customer_appCommonService";

const initialState = {
  order_scheduling: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  costingSheet: {
    costingSheetID: "",
    quotationID: "",
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  postDispatchDoc: {
    postDispatchDocID: "",
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  creditPolicy:{
    addCreditPolicyID:""
  },
  customerReg: {
    customerID: "",
    info: {},
  },
  kycExistingCustomer:{

  }
};

export const customerV1Slice = createSlice({
  name: "customerV1",
  initialState,
  reducers: {
    addcustomerID(state, action) {
      state.customerID = action.payload;
    },
    addCostingSheetID(state, action) {
      state.costingSheet.costingSheetID = action.payload;
    },
    addQuotationID(state, action) {
      state.costingSheet.quotationID = action.payload;
    },
    addCreditPolicyID(state, action) {
      state.creditPolicy.addCreditPolicyID = action.payload;
    },
    saveCustomerRegData(state, action) {
      state.customerReg.info = action.payload;
    },
    kycExistingCustomer(state, action) {
      state.kycExistingCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderSchedulingList.pending, (state, action) => {
        state.order_scheduling.status = true;
      })
      .addCase(getOrderSchedulingList.fulfilled, (state, action) => {
        state.order_scheduling.data = action.payload.data;
        state.order_scheduling.pagination.page = action.payload.page;
        state.order_scheduling.pagination.per_page = action.payload.per_page;
        state.order_scheduling.pagination.total = action.payload.total;
        state.order_scheduling.status = false;
      })
      .addCase(getPostDispatchDocList.pending, (state, action) => {
        state.postDispatchDoc.status = true;
      })
      .addCase(getPostDispatchDocList.fulfilled, (state, action) => {
        state.postDispatchDoc.data = action.payload.data;
        state.postDispatchDoc.pagination.page = action.payload.page;
        state.postDispatchDoc.pagination.per_page = action.payload.per_page;
        state.postDispatchDoc.pagination.total = action.payload.total;
        state.postDispatchDoc.status = false;
      })
      .addCase(getCostingSheetList.pending, (state, action) => {
        state.costingSheet.status = true;
      })
      .addCase(getCostingSheetList.fulfilled, (state, action) => {
        state.costingSheet.data = action.payload.data;
        state.costingSheet.pagination.page = action.payload.page;
        state.costingSheet.pagination.per_page = action.payload.per_page;
        state.costingSheet.pagination.total = action.payload.total;
        state.costingSheet.status = false;
      });
  },
});

export const {
  addcustomerID,
  addCostingSheetID,
  addQuotationID,
  addCreditPolicyID,
  saveCustomerRegData,
  kycExistingCustomer,
} = customerV1Slice.actions;
export default customerV1Slice.reducer;

export const getOrderSchedulingList = createAsyncThunk(
  "getOrderSchedulingList",
  async (param) => {
    console.log(">> loaded getOrderSchedulingList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getPostDispatchDocList = createAsyncThunk(
  "getPostDispatchDocList",
  async (param) => {
    console.log(">> loaded getPostDispatchDocList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getCostingSheetList = createAsyncThunk(
  "getCostingSheetList",
  async (param) => {
    console.log(">> loaded getCostingSheetList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);


