import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/customer_appCommonService";
// import ItemServiceSupplier from "../../../../services/supplier_appCommonService";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch
// import { getSupplierCategorySelection } from "../../../../redux/feature/supplier_app/supplierSlice";

//Components
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { ComboboxFieldSubArray } from "../../../../Components/formElements/ComboboxFieldSubArray";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/SupplierConstants";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import {
  validateName,
  validateRequired,
  validPostalCode,
} from "../../../../common/Validations";
import CustomerConstants from "../../../../common/CustomerConstants";
import toast from "react-hot-toast";
import GoogleMapComponent from "../Common/GoogleMapComponent";
import FileUploadComponent from "../../../../Components/formElements/FileUploadComponent";
import { saveCustomerRegData } from "../../../../redux/feature/customer_app/customerV1Slice";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
  GET_MAP_DISTANCE: "Please Click the Get Distance & Time button",
};

const CustomerRegistrationLoginV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer");
  localStorage.setItem("submenu", "Customer List");

  let userData = useSelector((state) => state?.user?.data);

  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];
  let routNumberDropdownOption = [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
  ];
  let productDocumentAttachment = [
    {
      id: "1",
      name: "Stage1",
    },
    {
      id: "2",
      name: "Stage2",
    },
    {
      id: "3",
      name: "Stage3",
    },
    {
      id: "4",
      name: "Stage4",
    },
    {
      id: "5",
      name: "Stage5",
    },

    {
      id: "6",
      name: "Google Pin location",
    },
    {
      id: "7",
      name: "Project Photos",
    },
    {
      id: "8",
      name: "Blueprints or Layout Plans",
    },
    {
      id: "9",
      name: "Project Document",
    },
    {
      id: "10",
      name: "Others",
    },
  ];
  const SalesOfficerData = [
    { id: 1, code: "30055", name: "V.Jaywant" },
    { id: 2, code: "30054", name: "B.K.Rangaswamy" },
    { id: 3, code: "30061", name: "T.Ramireddy" },
    { id: 4, code: "30060", name: "T.Sreekanth Reddy" },
    { id: 5, code: "30031", name: "P.Sony Pradeep Kumar" },
    { id: 6, code: "30062", name: "KK.Jamuna" },
  ];

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  // let requiredFields = [];

  const [getDropdownData, setDropdownData] = useState("");
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  // const [isCardVisible7, setCardVisible7] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);

  const [getFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);
  const [getcontactPosition, setcontactPosition] = useState([]);
  const [getTitle, setTitle] = useState([]);
  const [nextId, setNextId] = useState(1); // Start IDs from 1

  const [getcustomerContactPerson, setcustomerContactPerson] = useState([
    {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    },
  ]);
  const [getDocumentTypeData, setDocumentTypeData] = useState([
    {
      id: Date.now(),
      name: "Document 1",
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "29AADCN1012B1Z6",
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const [getProjectContactPerson, setProjectContactPerson] = useState([
    {
      id: Date.now(),
      projectContactPosition: "",
      projectContactTitle: "",
      projectContactName: "",
      projectContactNumber: "",
      projectContactEmail: "",
    },
  ]);
  const [getdocumentType, setdocumentType] = useState([]);

  const [getState, setState] = useState([]);
  const [getCountry, setCountry] = useState([]);

  const [validation, setValidation] = useState({
    organisationName: "",
    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
  });

  // ADD new projects
  const newProject = {
    projectCode: 1,
    projectCodeDesc: "PROJECT_1",
    projectName: "",
    projectAddress: "",
    projectAddress2: "",
    projectCity: "",
    projectCountry: "IN - India",
    projectState: "IN - Karanataka",
    projectCityPostalCode: "",
    getProjectContactPerson: [
      {
        id: Date.now(), // Unique ID for the first contact person
        projectContactPosition: "",
        projectContactTitle: "",
        projectContactName: "",
        projectContactNumber: "",
        projectContactEmail: "",
      },
    ],
    getProjectCertifications: [
      {
        id: Date.now(),
        name: "Document 1",
        documentType: "",
        certificateCode: "29AADCN1012B1Z6",
        stageDocument: "",
      },
    ],
    getLocations: [],
  };

  const [getProjectDetails, setProjectDetails] = useState([newProject]);
  const [getRouteDetails, setRouteDetails] = useState([]);

  // console.log(">>getRouteDetails",getRouteDetails)
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  // const toggleCardVisibility5 = () => {
  //   setCardVisible5(!isCardVisible5);
  // };
  // const toggleCardVisibility6 = () => {
  //   setCardVisible6(!isCardVisible6);
  // };
  // const toggleCardVisibility7 = () => {
  //   setCardVisible7(!isCardVisible7);
  // };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  // const toggleCardVisibility9 = () => {
  //   setCardVisible9(!isCardVisible9);
  // };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
    validateName(name === "organisationName" ? value : "")
      ? (errors.organisationName = "")
      : (errors.organisationName = message.VALID_CUSTOMER_NAME);
    validPostalCode(name === "cityPostalCode" ? value : "")
      ? (errors.cityPostalCode = "")
      : (errors.cityPostalCode = message.VALID_POSTAL_CODE);

    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  const [formInputs, setFormInputs] = useState({
    createdBy: userData?.userId || "",
    organisationName: "",
    address: "",
    address2: "",
    city: "",
    country: "IN - India",
    state: "IN - Karnataka",
    cityPostalCode: "",

    version: "v0",
    status: "Invited",
    category: "VENDOR",

    routeNumber: "",
    commission: "Yes",
    commissionRate: "",
    salesOfficerName: "",

    plantAddress:
      "Bharathi Rock Products India PVT Ltd Sy No.9, Kanivenarayanapura(V), Muddenahalli Post,Chikkabalapura-562101",
    projectLocation: "",

    customerTypeCode: "C03",
    customerTypeDesc: "Domestic",
  });
  const [disabled, setDisabled] = useState(false);
  // console.log(">>formInputs", formInputs);
  useEffect(() => {
    // dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getMasterData();
  }, [dispatch]);

  useEffect(() => {
    // console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.codeType === "country") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      getStateData(getDropdownData?.code);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    setValidation((prev) => ({
      ...prev,
      [getDropdownData?.textField]: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // console.log(">> formInputs", formInputs);

  // SAVE
  const handleSave = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.organisationName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        organisationName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.address)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        address: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.address2)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        address2: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.city)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        city: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.cityPostalCode)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        cityPostalCode: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    // if (!validateRequired(formInputs.distance)) {
    //   requiredFields.push(`${message.GET_MAP_DISTANCE}`);
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   // setValidation((prev) => ({
    //   //   ...prev,
    //   //   cityPostalCode: message.FILL_REQUIRED_FIELD,
    //   // }));
    //   return;
    // }
    if (!validateRequired(formInputs.salesOfficerName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        salesOfficerName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }

    if (!validateRequired(formInputs.routeNumber)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        routeNumber: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }

    formInputs.firstName = formInputs.organisationName;
    formInputs.contactDetails = getcustomerContactPerson;
    formInputs.certifications = getDocumentTypeData;
    formInputs.projectDetails = getProjectDetails;
    // formInputs.projectContactDetails = getProjectContactPerson;
    // formInputs.projectCertifications = getProjectCertifications;
    formInputs.routeDetails = getRouteDetails;

    const obj = {
      data: formInputs,
    };
    await ItemService.addCustomerV1(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        toast.success(item?.message || "added successfully", {
          duration: 2000,
          position: "top-right",
        });

        dispatch(saveCustomerRegData(item?.data));
        navigate(CustomerConstants.CUSTOMER_V1_COSTING_SHEET_LOGIN);
      } else {
        // setErrorMsg('*'+ item?.message);
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
      setDisabled(false);
    });
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "title",
    };
    await ItemService.getMasterDataSelf(obj).then((item) => {
      // console.log(">> item! title", item);
      if (item?.status === 200) {
        setTitle(item?.data);
      } else {
        setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj1 = {
      codeType: "documentType",
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setdocumentType(item?.data);
      } else {
        setdocumentType([]);
        console.log(">> Error: Loading getMasterData");
      }
    });

    let obj2 = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterDataSelf(obj2).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        setcontactPosition(item?.data);
      } else {
        setcontactPosition([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj3 = {
      codeType: "country",
    };
    await ItemService.getMasterDataSelf(obj3).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };
  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  const navigateToLogin = () => {
    navigate(Constants.LOGIN);
  };

  /* ---------------------Customer Contact Person -------- */

  const deleteCustomerContactPerosn = (id) => {
    const updatedData1 = getcustomerContactPerson.filter(
      (doc) => doc.id !== id
    );
    setcustomerContactPerson(updatedData1);
  };
  const addCustomerCantactPerson = () => {
    const newDocument = {
      id: Date.now(),
      contactPosition: "",
      contactTitle: "",
      contactName: "",
      contactNumber: "",
      contactEmail: "",
    };
    setcustomerContactPerson((prevData) => [...prevData, newDocument]);
  };
  const handleChangeCustomerContacts = (id, fieldName, value) => {
    setcustomerContactPerson((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------Document Type  03rd block-------- */

  const deleteDocumentType = (id) => {
    const updatedData1 = getDocumentTypeData.filter((doc) => doc.id !== id);
    setDocumentTypeData(updatedData1);
  };
  const addDocumentType = () => {
    const newDocument = {
      id: Date.now(),
      name: `Document ${getDocumentTypeData.length + 1}`,
      documentType: "",
      documentNumber: "",
      issueDate: "",
      issuingAuthority: "",
      certificateCode: "",
    };
    setDocumentTypeData((prevData) => [...prevData, newDocument]);
  };
  const handleInputChange = (id, fieldName, value) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === id ? { ...doc, [fieldName]: value } : doc
      )
    );
  };

  /* ---------------------project details block-------- */
  const addProjectDetails = () => {
    newProject.projectCode = nextId + 1;
    newProject.projectCodeDesc = "PROJECT_" + (nextId + 1);
    setProjectDetails((prevData) => [...prevData, newProject]);
    setNextId((prevId) => prevId + 1);
  };

  const removeProjectDetails = (indexToRemove) => {
    setProjectDetails(
      getProjectDetails.filter((_, idx) => idx !== indexToRemove)
    );
  };

  const handleProjectInputChange = (value, index, fieldName) => {
    // console.log(">>handleProjectInputChange", { index, fieldName, value });

    if (index === undefined || value === undefined) return;

    // Update the specific project detail
    setProjectDetails((prevDetails) =>
      prevDetails.map((item, idx) =>
        idx === index ? { ...item, [fieldName]: value } : item
      )
    );
  };

  /* --------------------- Contact Person Inside Project Details -------- */

  //Add Projects
  const addProjectCantactPersonNew = (projectIndex) => {
    const newContactPerson = {
      id: Date.now(), // Unique ID for each contact person
      projectContactPosition: "",
      projectContactTitle: "",
      projectContactName: "",
      projectContactNumber: "",
      projectContactEmail: "",
    };

    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: [
          ...updatedProjectDetails[projectIndex].getProjectContactPerson,
          newContactPerson,
        ],
      };
      return updatedProjectDetails;
    });
  };

  //Delete Project
  const deleteProjectContactPerosnNew = (projectIndex, id) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.filter((contact) => contact.id !== id),
      };
      return updatedProjectDetails;
    });
  };

  //Project Contact Inputs
  const handleChangeProjectContactsNew = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value } // Update the specific field
              : contact // Keep other contacts unchanged
        ),
      };
      return updatedProjectDetails;
    });
  };

  //Dropdown
  const handleChangeProjectContactsNewDropdown = (
    projectIndex,
    contactId,
    fieldName,
    value
  ) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Update the specific contact person's field
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectContactPerson: updatedProjectDetails[
          projectIndex
        ].getProjectContactPerson.map(
          (contact) =>
            contact.id === contactId
              ? { ...contact, [fieldName]: value.name } // Update the field with the full object
              : contact // Keep other contacts unchanged
        ),
      };

      return updatedProjectDetails;
    });
  };

  /* ---------------------project Certifications  -------- */

  const deleteProjectCertifications = (projectIndex, certificationId) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Filter out the certification with the specified id
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: updatedProjectDetails[
          projectIndex
        ].getProjectCertifications.filter(
          (certification) => certification.id !== certificationId
        ),
      };

      return updatedProjectDetails;
    });
  };

  const addProjectCertifications = (projectIndex) => {
    const newCertification = {
      id: Date.now(), // Unique ID for the new certification
      name: "Document 1",
      documentType: "",
      certificateCode: "29AADCN1012B1Z6",
      stageDocument: "",
    };

    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Add the new certification to the getProjectCertifications array
      updatedProjectDetails[projectIndex] = {
        ...updatedProjectDetails[projectIndex],
        getProjectCertifications: [
          ...updatedProjectDetails[projectIndex].getProjectCertifications,
          newCertification,
        ],
      };

      return updatedProjectDetails;
    });
  };

  /* ---------------------route and addition details  -------- */
  const routeTableCol = [
    {
      name: "Sl.No",
      selector: (row) => row.slNo || "--",
      cell: (row) => <>{row.slNo} </>,
      width: "150px",
    },
    {
      name: "RouteNr",
      selector: (row) => row.routeNumber || "--",
      cell: (row) => <>{row.routeNumber} </>,
      width: "220px",
    },
    {
      name: "Route",
      selector: (row) => row.routeDescription || "--",
      cell: (row) => <>{row.routeDescription} </>,
      width: "220px",
    },
    {
      name: "Toll",
      selector: (row) => row.tollNumber || "--",
      cell: (row) => <>{row.tollNumber} </>,
      width: "150px",
    },
    {
      name: "Rate",
      selector: (row) => row.tollRates || "--",
      cell: (row) => <>{row.tollRates} </>,
      width: "220px",
    },
    {
      name: "From",
      selector: (row) => row.noEntryTimeFromAm || "--",
      cell: (row) => <>{row.noEntryTimeFromAm} </>,
      width: "220px",
    },
    {
      name: "To",
      selector: (row) => row.noEntryTimeToAm || "--",
      cell: (row) => <>{row.noEntryTimeToAm} </>,
      width: "220px",
    },
    {
      name: "From",
      selector: (row) => row.noEntryTimeFromPm || "--",
      cell: (row) => <>{row.noEntryTimeFromPm} </>,
      width: "220px",
    },
    {
      name: "To",
      selector: (row) => row.noEntryTimeToPm || "--",
      cell: (row) => <>{row.noEntryTimeToPm} </>,
      width: "220px",
    },
  ];
  const routeTableData = [
    {
      slNo: 1,
      routeNumber: 1,
      routeDescription: "Yalahanka",
      tollNumber: 750,
      createdAt: "2023-03-22T13:11:59.098Z",
      tollRates: 25,
      noEntryTimeFromAm: "8:00 AM",
      noEntryTimeToAm: "11:00 AM",
      noEntryTimeFromPm: "3:30 PM",
      noEntryTimeToPm: "8:00 PM",
    },
    {
      slNo: 2,
      routeNumber: 2,
      routeDescription: "Melkote	",
      tollNumber: 180,
      createdAt: "2023-03-22T13:11:59.098Z",
      tollRates: 6,
      noEntryTimeFromAm: "8:00 AM",
      noEntryTimeToAm: "11:00 AM",
      noEntryTimeFromPm: "3:30 PM",
      noEntryTimeToPm: "8:00 PM",
    },
    {
      slNo: 3,
      routeNumber: 2,
      routeDescription: "Doddabalapura",
      tollNumber: 180,
      createdAt: "2023-03-22T13:11:59.098Z",

      tollRates: 6,
      noEntryTimeFromAm: "8:00 AM",
      noEntryTimeToAm: "11:00 AM",
      noEntryTimeFromPm: "3:30 PM",
      noEntryTimeToPm: "8:00 PM",
    },
    {
      slNo: 4,
      routeNumber: 2,
      routeDescription: "Doddabalapura	",
      tollNumber: 360,
      createdAt: "2023-03-22T13:11:59.098Z",

      tollRates: 12,
      noEntryTimeFromAm: "8:00 AM",
      noEntryTimeToAm: "11:00 AM",
      noEntryTimeFromPm: "3:30 PM",
      noEntryTimeToPm: "8:00 PM",
    },
    {
      slNo: 5,
      routeNumber: 2,
      routeDescription: "Budigere",
      tollNumber: 395,
      createdAt: "2023-03-22T13:11:59.098Z",

      tollRates: 13,
      noEntryTimeFromAm: "8:00 AM",
      noEntryTimeToAm: "11:00 AM",
      noEntryTimeFromPm: "3:30 PM",
      noEntryTimeToPm: "8:00 PM",
    },
    {
      slNo: 6,
      routeNumber: 3,
      routeDescription: "Free zone",
      tollNumber: 0,

      createdAt: "2023-03-22T13:11:59.098Z",
      tollRates: 0,
      noEntryTimeFromAm: "00:00AM",
      noEntryTimeToAm: "00:00AM",
      noEntryTimeFromPm: "00:00PM",
      noEntryTimeToPm: "00:00PM",
    },
  ];
  useEffect(() => {
    if (formInputs.routeNumber) {
      const route1TableData = [
        {
          slNo: 1,
          routeNumber: 1,
          routeDescription: "Yalahanka",
          tollNumber: 750,
          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 25,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
      ];
      const route2TableData = [
        {
          slNo: 1,
          routeNumber: 2,
          routeDescription: "Melkote	",
          tollNumber: 180,
          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 6,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 2,
          routeNumber: 2,
          routeDescription: "Doddabalapura",
          tollNumber: 180,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 6,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 3,
          routeNumber: 2,
          routeDescription: "Doddabalapura	",
          tollNumber: 360,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 12,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
        {
          slNo: 4,
          routeNumber: 2,
          routeDescription: "Budigere",
          tollNumber: 395,
          createdAt: "2023-03-22T13:11:59.098Z",

          tollRates: 13,
          noEntryTimeFromAm: "8:00 AM",
          noEntryTimeToAm: "11:00 AM",
          noEntryTimeFromPm: "3:30 PM",
          noEntryTimeToPm: "8:00 PM",
        },
      ];
      const route3TableData = [
        {
          slNo: 1,
          routeNumber: 3,
          routeDescription: "Free zone",
          tollNumber: 0,

          createdAt: "2023-03-22T13:11:59.098Z",
          tollRates: 0,
          noEntryTimeFromAm: "00:00AM",
          noEntryTimeToAm: "00:00AM",
          noEntryTimeFromPm: "00:00PM",
          noEntryTimeToPm: "00:00PM",
        },
      ];

      const newRouteDetails =
        formInputs.routeNumber === "1"
          ? route1TableData
          : formInputs.routeNumber === "2"
          ? route2TableData
          : formInputs.routeNumber === "3"
          ? route3TableData
          : "";

      setRouteDetails((prevRouteDetails) =>
        prevRouteDetails !== newRouteDetails
          ? newRouteDetails
          : prevRouteDetails
      );
    }
  }, [formInputs.routeNumber]);

  const onSuccessUploadDocumentType = (data) => {
    setDocumentTypeData((prevData) =>
      prevData.map((doc) =>
        doc.id === data.fieldId
          ? { ...doc, [data.fieldName]: data.attachmentId }
          : doc
      )
    );
  };

  const onHandleMapData = (data) => {
    setProjectDetails((prevData) => {
      const updatedProjectDetails = [...prevData];

      // Update the getLocations array with the new data
      updatedProjectDetails[data?.projectIndex] = {
        ...updatedProjectDetails[data?.projectIndex],
        getLocations: [
          ...updatedProjectDetails[data?.projectIndex].getLocations,
          {
            distance: data.distance,
            duration: data.duration,
            fromToCoords: data.fromToCoords,
            plantAddress: data.plantAddress,
            projectLocation: data.projectLocation,
          },
        ],
      };

      return updatedProjectDetails;
    });
  };

//document upload for projects
const onSuccessUploadProjectDocumentType = (
  projectIndex,
  docId,
  fieldName,
  attachmentId
) => {
  setProjectDetails((prevData) => {
    const updatedProjectDetails = [...prevData];
    updatedProjectDetails[projectIndex] = {
      ...updatedProjectDetails[projectIndex],
      getProjectCertifications: updatedProjectDetails[
        projectIndex
      ].getProjectCertifications.map((cert) =>
        cert.id === docId ? { ...cert, [fieldName]: attachmentId } : cert
      ),
    };

    return updatedProjectDetails;
  });
};

  return (
    <main>
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Customer Registration</h2>
            <p className="subText">
              Register as a new Customer or Sub-Contractor with our Company
            </p>
            <hr />
            <Row className="mb-4">
              <Col xs={12} md={2} lg={2} className="text-left">
                <button className="btnBack" onClick={() => navigateToLogin()}>
                  <i className="bi bi-arrow-return-left"></i> Go Back Login
                </button>
              </Col>
              {/* Next Button*/}
              <Col
                xs={12}
                md={{ span: 2, offset: 8 }}
                lg={{ span: 2, offset: 8 }}
                className="text-left"
              >
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Generate Costing Sheet
                </button>
              </Col>
            </Row>
            {/* customer details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Customer </span>
                      <span className="subHeaderTitle">Customer Details</span>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          extraLabel="As per the trade license"
                          name="organisationName"
                          placeholder=""
                          value={formInputs.organisationName || ""}
                          onChange={onInputChange}
                          required={true}
                          error={validation.organisationName}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label=" Address Lines 1 / Street"
                          name="address"
                          placeholder=""
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                          error={validation.address}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Address Line 2"
                          name="address2"
                          placeholder=""
                          value={formInputs.address2 || ""}
                          onChange={onInputChange}
                          error={validation.address2}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          placeholder=""
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                          error={validation.city}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Country"
                          placeholder=""
                          data={getCountry}
                          id="country"
                          iconClassName="dropdownIcon"
                          name="country"
                          code="countryCode"
                          description="countryDesc"
                          setValue={formInputs.country}
                          getvalue={setDropdownData}
                          required={true}
                          // error={validation.country}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="State"
                          placeholder=""
                          data={getState}
                          id="state"
                          iconClassName="dropdownIcon"
                          name="state"
                          code="stateCode"
                          description="stateDesc"
                          setValue={formInputs.state}
                          getvalue={setDropdownData}
                          required={true}
                          // error={validation.state}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code/PO Box"
                          name="cityPostalCode"
                          placeholder=""
                          value={formInputs.cityPostalCode}
                          onChange={onInputChange}
                          error={validation.cityPostalCode}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sales Officer Name"
                          extraLabel=""
                          name="salesOfficerName"
                          code="salesOfficerCode"
                          description="salesOfficerDesc"
                          data={SalesOfficerData}
                          id="salesOfficerName"
                          iconClassName="dropdownIcon"
                          setValue={formInputs.salesOfficerName}
                          error={validation.salesOfficerName}
                          getvalue={setDropdownData}
                          required={true}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/*Customer Contact Person / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CP</span>
                    </Col>
                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Contact Person</span>
                      <span className="subHeaderTitle">
                        Customer Contact Person
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button
                          className="btnTable"
                          onClick={addCustomerCantactPerson}
                        >
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={12}>
                                <div className="accordion-body">
                                  <div className="d-flex-column justify-content-center">
                                    <Row className="pt-4 d-none d-lg-flex ">
                                      <Col xs={12} md={2} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Job Title
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Title
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Person
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Contact Number
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Email-ID
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex">
                                          Action
                                        </p>
                                      </Col>
                                    </Row>
                                    {getcustomerContactPerson.map(
                                      (doc, index) => (
                                        <Row key={doc.id}>
                                          <Col xs={12}>
                                            <hr className="mx-4 my-0 text-color-black" />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <ComboboxField
                                              label=""
                                              placeholder="Job Title"
                                              data={getcontactPosition}
                                              id="contactPosition"
                                              iconClassName="dropdownIcon"
                                              name="contactPosition"
                                              code="contactPositionCode"
                                              description="contactPositionDesc"
                                              setValue={doc?.contactPosition}
                                              getvalue={setDropdownData}
                                              index={doc.id}
                                              onChangeValue={
                                                handleChangeCustomerContacts
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <ComboboxField
                                              label=""
                                              placeholder="Contact Title"
                                              data={getTitle}
                                              id="contactTitle"
                                              iconClassName="dropdownIcon"
                                              name="contactTitle"
                                              code="contactTitleCode"
                                              description="contactTitleDesc"
                                              setValue={doc?.contactTitle}
                                              getvalue={setDropdownData}
                                              index={doc.id}
                                              onChangeValue={
                                                handleChangeCustomerContacts
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              name="contactName"
                                              placeholder="Contact Person"
                                              value={doc.contactName || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactName",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              name="contactNumber"
                                              placeholder="Contact Number"
                                              value={doc.contactNumber || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactNumber",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={2}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <InputField
                                              className="inputBox mt-1"
                                              // label="Please Enter the Remarks*"
                                              name="contactEmail"
                                              placeholder="Email"
                                              value={doc.contactEmail || ""}
                                              onChange={(e) =>
                                                handleChangeCustomerContacts(
                                                  doc.id,
                                                  "contactEmail",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>

                                          <Col
                                            xs={12}
                                            md={4}
                                            lg={1}
                                            className="commTopButtonRightLeftPadding"
                                          >
                                            <i
                                              onClick={() =>
                                                deleteCustomerContactPerosn(
                                                  doc.id
                                                )
                                              }
                                              className="bi bi-trash text-danger fs-4"
                                            />
                                          </Col>
                                        </Row>
                                      )
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* customer Doucment Type */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DT</span>
                    </Col>
                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Doucment Type</span>
                      <span className="subHeaderTitle">Doucment Type</span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button className="btnTable" onClick={addDocumentType}>
                          <i className="bi bi-plus-lg"></i> Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={12}>
                                <div className="accordion-body">
                                  <div className="d-flex-column justify-content-center">
                                    <Row className="pt-4 d-none d-lg-flex ">
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Document Type
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Document Number
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Issue Date
                                        </p>
                                      </Col>
                                      {/* <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Issuing Authority
                                        </p>
                                      </Col> */}
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex ms-4">
                                          Attachments
                                        </p>
                                      </Col>
                                      <Col xs={12} md={4} lg={2}>
                                        <p className="fs-6 fw-medium d-flex justify-content-center ms-4">
                                          Action
                                        </p>
                                      </Col>
                                    </Row>
                                    {getDocumentTypeData.map((doc, index) => (
                                      <Row key={doc.id}>
                                        <Col xs={12}>
                                          <hr className="mx-4 my-0 text-color-black" />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <ComboboxField
                                            label=""
                                            placeholder="Document type"
                                            data={getdocumentType}
                                            id="documentType"
                                            iconClassName="dropdownIcon"
                                            name="documentType"
                                            code="documentTypeCode"
                                            description="documentTypeDesc"
                                            setValue={doc?.documentType}
                                            getvalue={setDropdownData}
                                            index={doc.id}
                                            onChangeValue={handleInputChange}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            placeholder="Document Number"
                                            value={doc.documentNumber}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "documentNumber",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            type="date"
                                            value={doc.issueDate}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "issueDate",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        {/* <Col
                                          xs={12}
                                          md={4}
                                          lg={2}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <InputField
                                            className="inputBox"
                                            placeholder="Issuing Authority"
                                            value={doc.issuingAuthority}
                                            onChange={(e) =>
                                              handleInputChange(
                                                doc.id,
                                                "issuingAuthority",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col> */}
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={3}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <FileUploadComponent
                                            id={doc.id}
                                            name="iSOCertification"
                                            label=""
                                            allowedTypes={[
                                              "image/png",
                                              "image/jpeg",
                                              "application/pdf",
                                            ]}
                                            multiple={true} // Change to `false` for single file selection
                                            required={true}
                                            onSuccessUpload={
                                              onSuccessUploadDocumentType
                                            }
                                            value={doc.iSOCertification || ""}
                                          />
                                        </Col>
                                        <Col
                                          xs={12}
                                          md={4}
                                          lg={1}
                                          className="commTopButtonRightLeftPadding"
                                        >
                                          <i
                                            onClick={() =>
                                              deleteDocumentType(doc.id)
                                            }
                                            className="bi bi-trash text-danger fs-4"
                                          />
                                        </Col>
                                      </Row>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Project Details*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PD</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Project Details </span>
                      <span className="subHeaderTitle">Project Details</span>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      <div className="d-flex justify-content-end  p-2 me-3">
                        <button
                          className="btnTable"
                          onClick={() => addProjectDetails()}
                        >
                          <i className="bi bi-plus-lg"></i> Add Project
                        </button>
                      </div>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    {getProjectDetails &&
                      getProjectDetails?.map((item, index) => (
                        <div key={index}>
                          <Row className="pt-2 pb-4">
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <h5 className="bg-table-status bg-table-new-status mt-3 p-3">
                                Project Details {index + 1}
                              </h5>
                              <span
                                className="deleteIconAccordion"
                                onClick={() => removeProjectDetails(index)}
                              >
                                {" "}
                                <i className="bi bi-x-circle"></i>{" "}
                              </span>
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Project Name"
                                extraLabel="As per the trade license"
                                name="projectName"
                                value={item.projectName || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectName"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Address Lines 1 / Street"
                                extraLabel="As per the trade license"
                                name="projectAddress"
                                value={item.projectAddress || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectAddress"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Address Line 2"
                                extraLabel="As per the trade license"
                                name="projectAddress2"
                                value={item.projectAddress2 || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectAddress2"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="City"
                                extraLabel="As per the trade license"
                                name="projectCity"
                                value={item.projectCity || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectCity"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Country"
                                placeholder=""
                                data={getCountry}
                                id="projectCountry"
                                iconClassName="dropdownIcon"
                                name="projectCountry"
                                code="projectCountryCode"
                                description="projectCountryDesc"
                                setValue={item?.projectCountry}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "projectCountry"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="State"
                                placeholder=""
                                data={getState}
                                id="projectState"
                                iconClassName="dropdownIcon"
                                name="projectState"
                                code="projectStateCode"
                                description="projectStateDesc"
                                setValue={item?.projectState}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "projectState"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Postal Code/PO Box"
                                extraLabel="As per the trade license"
                                name="projectCityPostalCode"
                                value={item.projectCityPostalCode || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "projectCityPostalCode"
                                  )
                                }
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Route Number"
                                placeholder=""
                                data={routNumberDropdownOption}
                                id="routeNumber"
                                iconClassName="dropdownIcon"
                                name="routeNumber"
                                code="routeNumberCode"
                                description="routeNumberDesc"
                                setValue={item?.routeNumber}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "routeNumber"
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <ComboboxField
                                label="Commission"
                                placeholder=""
                                data={dropdownOption}
                                id="commission"
                                iconClassName="dropdownIcon"
                                name="commission"
                                code="commissionCode"
                                description="commissionDesc"
                                setValue={item?.commission}
                                getvalue={setDropdownData}
                                index={item.id}
                                onChangeValue={(value) =>
                                  handleProjectInputChange(
                                    value,
                                    index,
                                    "commission"
                                  )
                                }
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={4}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Commission Amount"
                                name="commissionRate"
                                placeholder=""
                                // value={formInputs.commissionRate || ""}
                                // onChange={onInputChange}
                                // error={validation.commissionRate}
                                required={true}
                                value={item.commissionRate || ""}
                                onChange={(e) =>
                                  handleProjectInputChange(
                                    e.target.value,
                                    index,
                                    "commissionRate"
                                  )
                                }
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-1">
                                        Project Contacts
                                      </h5>
                                    </Col>

                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <Row className="pt-4 d-none d-lg-flex ">
                                            <Col xs={12} md={2} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Job Title
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Title
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Person
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Contact Number
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Email-ID
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={2}>
                                              <p className="fs-6 fw-medium d-flex">
                                                Action
                                              </p>
                                            </Col>
                                          </Row>
                                          {item?.getProjectContactPerson.map(
                                            (doc, itemIndex) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Job Title"
                                                    data={getcontactPosition}
                                                    id="projectContactPosition"
                                                    name="projectContactPosition"
                                                    code="projectContactPositionCode"
                                                    description="projectContactPositionDesc"
                                                    setValue={
                                                      doc.projectContactPosition
                                                    }
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      contactId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Contact Title"
                                                    data={getTitle}
                                                    id="projectContactTitle"
                                                    iconClassName="dropdownIcon"
                                                    name="projectContactTitle"
                                                    code="projectContactTitleCode"
                                                    description="projectContactTitleDesc"
                                                    setValue={
                                                      doc.projectContactTitle
                                                    }
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      contactId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactName"
                                                    placeholder="Contact Person"
                                                    value={
                                                      doc.projectContactName ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactName",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactNumber"
                                                    placeholder="Contact Number"
                                                    value={
                                                      doc.projectContactNumber ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactNumber",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <InputField
                                                    className="inputBox mt-1"
                                                    name="projectContactEmail"
                                                    placeholder="Email"
                                                    value={
                                                      doc.projectContactEmail ||
                                                      ""
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeProjectContactsNew(
                                                        index,
                                                        doc.id,
                                                        "projectContactEmail",
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex">
                                                    {item
                                                      ?.getProjectContactPerson
                                                      .length !== 1 && (
                                                      <i
                                                        onClick={() =>
                                                          deleteProjectContactPerosnNew(
                                                            index,
                                                            doc.id
                                                          )
                                                        }
                                                        className="bi bi-trash text-danger fs-4"
                                                      />
                                                    )}

                                                    {itemIndex ===
                                                      item
                                                        ?.getProjectContactPerson
                                                        .length -
                                                        1 && (
                                                      <button
                                                        className="btnTable width-40 ml-6 mt-1"
                                                        onClick={() =>
                                                          addProjectCantactPersonNew(
                                                            index
                                                          )
                                                        } // Fixed: Pass as a callback
                                                      >
                                                        <i className="bi bi-plus-lg"></i>{" "}
                                                        Add
                                                      </button>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-4">
                                        Project Stages
                                      </h5>
                                    </Col>
                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <Row className="pt-4 d-none d-lg-flex ">
                                            <Col xs={12} md={2} lg={2}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Stages
                                              </p>
                                            </Col>
                                            <Col xs={12} md={4} lg={3}>
                                              <p className="fs-6 fw-medium d-flex ms-4">
                                                Attachments
                                              </p>
                                            </Col>

                                            <Col xs={12} md={4} lg={3}>
                                              <p className="fs-6 fw-medium d-flex">
                                                Action
                                              </p>
                                            </Col>
                                          </Row>
                                          {item.getProjectCertifications.map(
                                            (doc, itemIndex) => (
                                              <Row key={doc.id}>
                                                <Col xs={12}>
                                                  <hr className="mx-4 my-0 text-color-black" />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <ComboboxFieldSubArray
                                                    label=""
                                                    placeholder="Stages"
                                                    data={
                                                      productDocumentAttachment
                                                    }
                                                    id="documentType"
                                                    iconClassName="dropdownIcon"
                                                    name="documentType"
                                                    code="documentTypeCode"
                                                    description="documentTypeDesc"
                                                    setValue={doc.documentType}
                                                    index={index} // Pass projectIndex instead of doc.id
                                                    onChangeValue={(
                                                      projectIndex,
                                                      fileId,
                                                      fieldName,
                                                      value
                                                    ) =>
                                                      handleChangeProjectContactsNewDropdown(
                                                        projectIndex,
                                                        doc.id,
                                                        fieldName,
                                                        value
                                                      )
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={3}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <FileUploadComponent
                                                    id={doc.id}
                                                    name="stageDocument"
                                                    label=""
                                                    allowedTypes={[
                                                      "image/png",
                                                      "image/jpeg",
                                                      "application/pdf",
                                                    ]}
                                                    multiple={true} // Change to `false` for single file selection
                                                    required={true}
                                                    onSuccessUpload={(data) =>
                                                      onSuccessUploadProjectDocumentType(
                                                        index,
                                                        doc.id,
                                                        data.fieldName,
                                                        data.attachmentId
                                                      )
                                                    }
                                                    value={
                                                      doc.stageDocument || ""
                                                    }
                                                  />
                                                </Col>

                                                <Col
                                                  xs={12}
                                                  md={4}
                                                  lg={2}
                                                  className="commTopButtonRightLeftPadding"
                                                >
                                                  <div className="d-flex">
                                                    {item
                                                      ?.getProjectCertifications
                                                      .length !== 1 && (
                                                      <i
                                                        onClick={() =>
                                                          deleteProjectCertifications(
                                                            index,
                                                            doc.id
                                                          )
                                                        }
                                                        className="bi bi-trash text-danger fs-4"
                                                      />
                                                    )}

                                                    {itemIndex ===
                                                      item
                                                        ?.getProjectCertifications
                                                        .length -
                                                        1 && (
                                                      <button
                                                        className="btnTable width-40 ml-6 mt-1"
                                                        onClick={() =>
                                                          addProjectCertifications(
                                                            index
                                                          )
                                                        } // Fixed: Pass as a callback
                                                      >
                                                        <i className="bi bi-plus-lg"></i>{" "}
                                                        Add
                                                      </button>
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xs={12}
                              md={12}
                              lg={12}
                              className="commTopButtonRightLeftPadding"
                            >
                              <div>
                                <div className="d-flex-column justify-content-center">
                                  <Row>
                                    <Col xs={12} md={5} lg={5}>
                                      <h5 className="bg-table-status bg-table-warning-status mt-4">
                                        Locations
                                      </h5>
                                    </Col>
                                    <Col xs={12}>
                                      <div className="accordion-body">
                                        <div className="d-flex-column justify-content-center">
                                          <GoogleMapComponent
                                            projectIndex={index}
                                            getmapdata={onHandleMapData}
                                            passmapdata={false}
                                            editstatus={false}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <hr />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* Route details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible8
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility8}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AD</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Route Detail</span>
                      <span className="subHeaderTitle">Route Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible8 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <DataTable
                        title=""
                        columns={routeTableCol}
                        data={routeTableData}
                        // progressPending={loading}
                        // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        // pagination
                        // paginationServer
                        // paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // onChangePage={handlePageChange}
                        // selectableRows
                        // onSelectedRowsChange={handleChange}
                      />
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col
          xs={12}
          md={{ span: 2, offset: 10 }}
          lg={{ span: 2, offset: 10 }}
          className="text-left"
        >
          <button className="btnTable" onClick={handleSave} disabled={disabled}>
            <i className="bi bi-save"></i> Generate Costing Sheet
          </button>
        </Col>
      </Row>
      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CustomerRegistrationLoginV2;
